<h4>Edit {{ data.propertyDisplayName }}</h4>
<div>
    <div class="old">
        <label for="oldValue">Current Label</label>
        <span id="oldValue">{{ data.oldValue }}</span>
    </div>

    <mat-form-field>
        <mat-label>New Label</mat-label>
        <input matInput [(ngModel)]="newValue" />
    </mat-form-field>

    <div class="action-buttons">
        <button mat-raised-button color="cor-default" (click)="dialogRef.close()">Cancel</button>
        <button mat-raised-button color="cor-primary" [disabled]="!newValue.trim()" (click)="onDialogClose()">Save</button>
    </div>
</div>