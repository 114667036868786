<h5 mat-dialog-title style="font-weight: 600;">{{isFromInAddMode ? 'Add' : 'Edit'}} Container Weight</h5>
<mat-dialog-content style="height: 370px;">

    <form [formGroup]="containerWeightsForm" (ngSubmit)="onSubmit()">
        <div class="p-grid formgrid">
            <!-- Container Name -->
            <div class="p-col-12 p-field">
                <div class="p-fluid">
                    <label class="p-text-bold" for="containerNameTxt">Container Name</label>
                    <input pInputText formControlName="containerName" id="containerNameTxt" type="text"
                        maxlength="100" />
                </div>
                <div [ngClass]="{'p-error': fc.containerName.invalid}"
                    *ngIf="(isFormSubmitted || fc.containerName.touched) && fc.containerName.errors">
                    <small *ngIf="fc.containerName.errors?.required">
                        Field is required.
                    </small>
                </div>
            </div>

            <!-- Plastic Weight  -->
            <div class="p-col-12 p-field">
                <div class="p-fluid">
                    <label class="p-text-bold" for="plasticWeightTxt">Plastic weight (kg)</label>
                    <input pInputText formControlName="plasticWeight" id="plasticWeightTxt" type="text" maxlength="11" />
                </div>
                <div [ngClass]="{'p-error': fc.plasticWeight.invalid}"
                    *ngIf="(isFormSubmitted || fc.plasticWeight.touched) && fc.plasticWeight.errors">
                    <small *ngIf="fc.plasticWeight.errors?.required">
                        Field is required.
                        <br>
                    </small>
                    <small *ngIf="fc.plasticWeight.errors?.pattern">
                        Value should be greater than 0 and can have upto 7 digits after decimal point. No special
                        characters are allowed
                    </small>
                </div>
            </div>

            <!-- Container Weight  -->
            <div class="p-col-12 p-field">
                <div class="p-fluid">
                    <label class="p-text-bold" for="containerWeightTxt">Container weight (kg)</label>
                    <input pInputText formControlName="containerWeight" id="containerWeightTxt" type="text"
                        maxlength="11" />
                </div>
                <div [ngClass]="{'p-error': fc.containerWeight.invalid}"
                    *ngIf="(isFormSubmitted || fc.containerWeight.touched) && fc.containerWeight.errors">
                    <small *ngIf="fc.containerWeight.errors?.maxlength">
                        Maximum length is 11 characters.
                        <br>
                    </small>
                    <small *ngIf="fc.containerWeight.errors?.pattern">
                        Value should be greater than 0 and can have upto 7 digits after decimal point. No special
                        characters are allowed.
                    </small>
                </div>
            </div>
            <div *ngIf="fc.containerWeight.touched" style="margin-top: -17px;padding-left:8px"
                [ngClass]="{'p-error': containerWeightsForm.errors?.invalidContainerWeight}">
                <small *ngIf="containerWeightsForm.errors?.invalidContainerWeight">
                    Container weight should not be less than Plastic weight.
                </small>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions style="float: right;">
    <button mat-raised-button mat-dialog-close color="cor-default">Cancel</button>
    <button mat-raised-button color="cor-primary" class="pl-4" [disabled]="containerWeightsForm.invalid"
        (click)="onSubmit()">{{isFromInAddMode ? 'Save' : 'Update'}}</button>
</mat-dialog-actions>