import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from './../../components/error-modal/error-modal.component';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorDialogService {

    public isDialogOpen: Boolean = false;

    constructor(public dialog: MatDialog) { }

    openDialog(data): any {
        if (this.isDialogOpen)
            return false;

        this.isDialogOpen = true;
        const dialogRef = this.dialog.open(ErrorModalComponent, {
            width: '600px',
            data: data
        });

        dialogRef.afterClosed().subscribe(result => {
            this.isDialogOpen = false;
        });
    }
}