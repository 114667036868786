import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MessageService } from '../../../shared/services/message-queue.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { EditStationModalComponent } from '../edit-station-modal/edit-station-modal.component';

@Component({
  selector: 'app-manage-station-actions',
  templateUrl: './manage-station-actions.component.html',
  styleUrls: ['./manage-station-actions.component.scss'],
})
export class ManageStationActionsComponent implements ICellRendererAngularComp {
  private params: any;

  constructor(
    private dialog: MatDialog,
    private readonly messageService: MessageService,
    private toastr: ResponseHandlingService
  ) { }

  onEditStation() {
    this.dialog
      .open(EditStationModalComponent, { width: '414px', data: this.params.data })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({ data: 'edit', stationId: this.params.data.stationID, reference: dialogResult });
        }
      });
  }

  onDeleteStation() {
    const confirmDialog = new ConfirmDialogModel(
      'Confirm Action',
      `This record will be deleted. Are you sure?`,
      'OK',
      false,
      true,
      'Cancel'
    );
    this.dialog
      .open(ConfirmDialogComponent, { maxWidth: '400px', data: confirmDialog })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({ data: 'delete', stationId: this.params.data.stationID, reference: this.params.data });
        }
      });
  }

  canBeDeleted(): boolean {
    return this.params.data.allowDelete;
  }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
