import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'label-info-button-cell',
  template: ``,
  styles: [],
})

export class LabelInfoButtonRenderer implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
  }

  click() {
    this.gridApi.dispatchEvent({ type: 'labelInfoButtonClicked', value: this.params.data });
  }

  refresh(): boolean {
    return false;
  }
}