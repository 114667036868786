import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({name: 'standardDateFormat'})
export class DateFormatPipe implements PipeTransform {
    transform(date: string): string {
        if (!date) {
            return '';
        }

        return formatDate(date, 'd-MMM-yyyy', 'en-US');
    }
}