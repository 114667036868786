import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GridApi } from 'ag-grid-community';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { Order } from 'src/app/models/order-models';
import { OrderService } from 'src/app/services/order-service/order-service';
import { RequestNumberClickableTextCellRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/request-number-clickable-text-renderer.component';

@Component({
  selector: 'app-view-other-requests-modal',
  templateUrl: './view-other-requests-modal.component.html',
  styleUrls: ['./view-other-requests-modal.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class ViewOtherRequestsModalComponent implements OnInit {

  columnDefs;
  rowData;
  private earmarkGridApi: GridApi;

  frameworkComponents = {
    requestNumberClickableText: RequestNumberClickableTextCellRenderer
  }

  constructor(public dialogRef: MatDialogRef<ViewOtherRequestsModalComponent>, @Inject(MAT_DIALOG_DATA) public data, private orderService: OrderService, private weightFormatKGPipe: WeightFormatKGPipe) {
    this.setColumnDefs();
    this.setRowData();
  }

  ngOnInit(): void {
  }

  setColumnDefs() {
    const that = this;
    this.columnDefs = [
      {
        headerName: 'Request',
        field: 'requestNumber',
        cellRenderer: 'requestNumberClickableText'
      },
      {
        headerName: 'R', field: 'useReservation', sortable: false,
        suppressSizeToFit: false,
        minWidth: 45,
        maxWidth: 45,
        valueGetter: (params) => {
          if (params.data !== undefined && params.data.useReservation) {
            return params.data.useReservation === true ? 'R' : '';
          }
        }
      },
      {
        headerName: 'Request Type',
        field: 'requestTypeDesc'
      },
      {
        headerName: 'Amount',
        field: 'amountTotalConverted',
        valueGetter: function (params) {
          if (params.data !== undefined && that.data.orderItem.unitOfMeasureDesc) {
            return that.weightFormatKGPipe.transform(params.data.amountTotalConverted) + ' ' + that.data.orderItem.unitOfMeasureDesc;
          }
        }
      },
      {
        headerName: 'Destination',
        field: 'stateProvince',
        valueGetter: (params) => {
          if (!params) { return ''; }
          let val = '';
          if (params.data.city) {
            val += params.data.city;
          }
          if (params.data.stateProvince) {
            val += ' ' + params.data.stateProvince;
          }
          if (params.data.countryName) {
            val += ' ' + params.data.countryName;
          }
          return val;
        }
      },
      {
        headerName: 'Status',
        field: 'orderStatusDescription',
      },
      {
        headerName: 'Selected Lot',
        field: 'lotNumber',
      },
      {
        headerName: 'Earmarked',
        field: 'hasEarmarks',
        valueGetter: (params) => {
          if (!params) { return '' }
          return params.data.hasEarmarks ? 'Yes' : 'No'
        }
      },
    ];
  }

  setRowData() {
    this.rowData = this.data.rowData;
  }

  onEarmarkGridReady(params) {
    this.earmarkGridApi = params.api;
    this.earmarkGridApi.addEventListener('requestNumberClicked', (e) => {
      this.openRequestInNewTab(e);
    })
  }

  openRequestInNewTab(e) {
    this.orderService.getOrderByRequestNumber(e.value.requestNumber).subscribe((res: Order) => {
      const currentUrl = window.location.href;
      const urlArray = currentUrl.split('/');
      urlArray.pop();
      urlArray.push(res.orderID);
      const newUrl = urlArray.join('/');
      window.open(newUrl);
    });
  }


  earmarkResizeGrid(params) {
    this.earmarkGridApi = params.api;
    this.earmarkGridApi.sizeColumnsToFit();
  }

}
