<div class="flex-grid">
    <div class="col">
        <h4>Manage Compliance Labels</h4>
    </div>
</div>
<div class="flex-grid" style="max-width:1000px;justify-content: space-between;">
    <p-autoComplete #autocomplete placeholder="Enter material name" [(ngModel)]="option" [suggestions]="options"
        (onSelect)="onSelect($event)" (onFocus)='onFocus()' [field]='selectedOptionDesc' showEmptyMessage="true"
        [emptyMessage]="'no matches'" (completeMethod)="changed($event)" [forceSelection]="true">
        <ng-template let-option pTemplate="item">
            <div>{{option.itemDesc}}</div>
        </ng-template>
    </p-autoComplete>
    <label>{{option?.itemDesc}}</label>
    <button mat-raised-button color="cor-primary" id="add-btn" *ngIf="canAddLabel" (click)="onAddLabelTemplate()">
        Add New Label Template
    </button>
</div>
<div class="flex-grid">
    <div class="col">
        <ag-grid-angular #grid *ngIf="!isInitialLoad" style="width:100%;max-width: 1000px;margin-bottom: 20px"
            class="ag-theme-alpine" domLayout="autoHeight" suppressHorizontalScroll="true" [rowData]="rowData"
            [columnDefs]="columnDefs" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [frameworkComponents]='frameworkComponents' [tooltipShowDelay]="1500" (gridReady)="onGridReady($event)"
            (gridSizeChanged)="resizeGrid($event)">
        </ag-grid-angular>
    </div>
</div>