import { Component, ElementRef, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { sortOrdersByUpdatedDate } from '../../helpers/filter-helpers';
import { Order, OrderListRequest, RequestTypes } from 'src/app/models/order-models';
import { OrderService } from 'src/app/services/order-service/order-service';
import { DecantService } from 'src/app/services/decant-service/decant-service';
import { DecantStation } from 'src/app/models/decant-models';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { BaseComponent } from 'src/app/base-component';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { OrderItemLotResponse, OrderItemModel } from 'src/app/models/order-item-model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';


interface OrderDecantingDisplay {
  orderID: string;
  isOnHold: boolean;
  requestNumber: string;
  requestTypeID: number;
  isOnSiteOrder: boolean;
  isOnSiteText: string;
  containers: Array<RowDecantingDisplay>;
}

interface RowDecantingDisplay {
  containerLabelFmt: string;
  assignedLotNumber: string;
  numberOfContainers: number;
  totalAmountRequested: number;
  extraPPERequired: boolean;
  unitOfMeasureDesc: string;
  isDesiccant: boolean;
  isCold: boolean;
  isNoDecantContainer: boolean
}

@Component({
  selector: 'app-decant',
  templateUrl: './decant.component.html',
  styleUrls: ['./decant.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class DecantComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('picksheet') private picksheetRef: ElementRef;
  private subscriptions = new Subscription();
  public decantOrdersLoaded: boolean;
  public decantOrders: Order[];
  public orders: Order[];
  public decantStations: DecantStation[];
  public stations: DecantStation[] = [];
  public selectedStation: DecantStation;
  public picksheetInput: string;
  public RequestTypes = RequestTypes;
  public selectedVal: string;
  selectedStationIsGlp: boolean = false;
  displayOrders: OrderDecantingDisplay[] = [];


  constructor(private orderService: OrderService, private router: Router,
    private decantService: DecantService, private loadingService: LoadingService, private responseHandler: ResponseHandlingService
    , private weightFormatKGPipe: WeightFormatKGPipe, public dialog: MatDialog) {
    super();
    this.picksheetInput = "";
    if (localStorage.getItem("station")) {
      this.selectedStation = JSON.parse(localStorage.getItem("station"));
      const defaultWarehouseID = JSON.parse(localStorage.getItem("defaultWarehouseID"));
      if(this.selectedStation && this.selectedStation?.warehouseID !== defaultWarehouseID) {
        this.selectedStation = null;
        localStorage.setItem("station", null);
      }
    };
    const decantOrdersRequest: OrderListRequest = { orderStatusID: 6 }; // Decanting Orders
    this.subscriptions.add(this.orderService
      .retrieveList(decantOrdersRequest).subscribe(
        data => {
          let orderRequests = [];
          data.forEach(order => orderRequests.push(this.orderService.getOrderDetail(order.orderID)));
          if (orderRequests.length === 0) {
            this.setUpEmptyPage();
          } else {
            this.loadingService.show('Please wait...');
          }
          forkJoin(orderRequests).subscribe((orders: Order[]) => {
            this.loadingService.clearMessage();
            const sortedOrders = sortOrdersByUpdatedDate(orders);
            this.decantOrders = sortedOrders.reverse();
            this.orders = sortedOrders.reverse();
            this.decantOrdersLoaded = true;
            this.buildDisplayData();
            if (localStorage.getItem("typeSelection")) {
              this.selectedVal = JSON.parse(localStorage.getItem("typeSelection"));
              this.onValChange(this.selectedVal);
            } else {
              this.selectedVal = RequestTypes.P2P.toString();
            }
          }, err => this.loadingService.clearMessage())
        }, err => this.loadingService.clearMessage()
      ));
    this.decantService.getDecantStations().subscribe((res: DecantStation[]) => {
      this.decantStations = res;

      const localStation: DecantStation = JSON.parse(localStorage.getItem("station"));
      if (localStation) {
        this.selectedStation = this.decantStations.find(s => s.stationID === localStation.stationID);
        this.selectedStationIsGlp = this.selectedStation?.isGLP ? true : false;
      }
    }

    );
  }

  private setUpEmptyPage(): void {
    this.orders = [];
    this.decantOrders = [];
    this.decantOrdersLoaded = true;
    if (localStorage.getItem("typeSelection")) {
      this.selectedVal = JSON.parse(localStorage.getItem("typeSelection"));
      this.onValChange(this.selectedVal);
    } else {
      this.selectedVal = RequestTypes.P2P.toString();
    }
  }

  buildDisplayData(): void {
    const _orders: OrderDecantingDisplay[] = [];
    for (const order of this.decantOrders) {
      _orders.push({
        orderID: order.orderID,
        isOnHold: order.isOnHold,
        requestNumber: order.requestNumber,
        requestTypeID: order.requestTypeID,
        isOnSiteOrder: order?.isOnSiteOrder,
        isOnSiteText: order?.isOnSiteText,
        containers: []
      });
      for (const item of order.items) {
        for (const container of item.containers) {
          const currentOrder = _orders.find(o => o.orderID === order.orderID);
          const matchingContainer = currentOrder.containers.find(d => d.containerLabelFmt === item.containerLabelFmt && d.assignedLotNumber === container.assignedLotNumber);
          if (matchingContainer) {
            matchingContainer.numberOfContainers++;
            matchingContainer.totalAmountRequested += container.requestedAmount;
          } else {
            let lotLocationObj: OrderItemLotResponse;
            if (order.requestTypeID === RequestTypes.Stock) {
              lotLocationObj = item.stockLots?.find(l => l.lotNumber === container.assignedLotNumber);
            } else if (order.requestTypeID === RequestTypes.GLP) {
              lotLocationObj = item.glpLots?.find(l => l.lotNumber === container.assignedLotNumber);
            }
            const isCold = lotLocationObj?.locations?.some(l => l.toLowerCase().includes('refrigerated') || l.toLowerCase().includes('freezer'));
            const isDesiccant = lotLocationObj?.locations?.some(l => l.toLowerCase().includes('desiccant'));
            currentOrder.containers.push({
              containerLabelFmt: item.containerLabelFmt,
              assignedLotNumber: container.assignedLotNumber,
              numberOfContainers: 1,
              totalAmountRequested: container.requestedAmount,
              extraPPERequired: item.extraPPERequired,
              unitOfMeasureDesc: item.unitOfMeasureDesc,
              isDesiccant,
              isCold,
              isNoDecantContainer: !!container.noDecantInventoryItemID
            });
          }
        }
      }
    }
    this.displayOrders = _orders;
    document.getElementById('picksheetField').focus();
  }

  getTotalAmountRequestedForItem(item: OrderItemModel): string {
    let totalAmount = 0;
    if (item.containers.length) {
      item.containers.forEach(container => {
        totalAmount += container.requestedAmount;
      });
    }
    return this.weightFormatKGPipe.transform(totalAmount) + ' ' + item.unitOfMeasureDesc;
  }

  sumOrderContainers(order: OrderDecantingDisplay): number {
    let sum = 0;
    order.containers.forEach(c => sum += c.numberOfContainers);
    return sum;
  }

  onStationChange(station: DecantStation): void {
    if (station) {
      this.selectedStationIsGlp = station.isGLP ? true : false;
      localStorage.setItem('station', JSON.stringify(station));
      this.selectedStation = JSON.parse(localStorage.getItem("station"));
      setTimeout(() => {
        document.getElementById('picksheetField').focus();
      }, 100);
    } else {
      this.selectedStation = null;
    }
  }

  onPicksheetInput(event) {
    const input = event.target.value.toLowerCase();
    if (input.length > 0) {
      setTimeout(() => {
        this.scanRequestIdCheck(input);
      }, 100);
    }
  }

  scanRequestIdCheck(input: string) {
    if (input.length === 36) {
      if (this.verifyGuid(input) && this.isValidOrderID(input)) {
        this.decantService.passNavigation('decant');
        this.router.navigateByUrl(`decant/request/${input}`);

        // EAS Story 25000 - no longer validating GLP vs. Stock station.
        //If GLP Station is selected and Stock Request ID is scanned then show confirmation modal
        // if (this.selectedStationIsGlp && this.isStockRequestScanned(input)) {
        //   this.GLPConform(input);
        // }
        //If Stock Station is selected and GLP request scanned display error toaster
        // else if (!this.selectedStationIsGlp && this.isGlpRequestScanned(input)) {
        //   this.responseHandler.showError("GLP Requests cannot be decanted using Stock stations");
        //   document.getElementById('picksheetField').focus();
        //   (document.getElementById('picksheetField') as any).value = '';
        // } else {
        // }
      } else {
        this.responseHandler.showError("Request ID Invalid");
        this.picksheetInput = "";
        document.getElementById('picksheetField').focus();
      }
    }
    else {
      if (this.picksheetInput.length > 0) {
        this.responseHandler.showError("Request ID Invalid");
      }
      this.picksheetInput = "";
    }
  }

  clearField(event) {
    if (event.target.id === "picksheetField") {
      this.picksheetInput = "";
    }
  }

  verifyGuid(input: string): boolean {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(input);
  }

  handleOrderClicked(order: Order) {
    if (this.selectedStation) {
      //this.router.navigate([`decant/request/${order.orderID}`]);
      this.scanRequestIdCheck(order.orderID);
    } else {
      this.responseHandler.showError("Please select warehouse and station before selecting a request.");
    }
  }

  isValidOrderID(orderID: string) {
    var orderMatch = this.decantOrders.find(order => order.orderID === orderID.toLowerCase());
    return orderMatch && orderMatch.orderID === orderID.toLowerCase();
  }
  isValidStockOrderID(orderID: string) {
    var orderMatch = this.decantOrders.find(order => order.orderID === orderID && order.requestTypeID === Number(RequestTypes.GLP));
    return orderMatch && orderMatch.orderID === orderID;
  }

  isGlpRequestScanned(orderID: string) {
    var orderMatch = this.decantOrders.find(order => order.orderID === orderID && order.requestTypeID === Number(RequestTypes.GLP));
    return orderMatch && orderMatch.orderID === orderID;
  }

  isStockRequestScanned(orderID: string) {
    var orderMatch = this.decantOrders.find(order => order.orderID === orderID && order.requestTypeID === Number(RequestTypes.Stock));
    return orderMatch && orderMatch.orderID === orderID;
  }

  GLPConform(input: any): void {
    const dialogData =
      new ConfirmDialogModel("Confirm Stock Decant",
        `This is a Stock Request and you're at a GLP station.  Do you wish to proceed?`,
        "Proceed", false);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "600px",
      disableClose: true,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.decantService.passNavigation('decant');
        this.router.navigateByUrl(`decant/request/${input}`);
      }
      else {
        (document.getElementById('picksheetField') as HTMLInputElement).value = '';
        document.getElementById('picksheetField').focus();
      }
    });
  }

  onValChange(val: string) {
    localStorage.setItem('typeSelection', JSON.stringify(val));
    if (val === '3') {
      this.decantOrders = this.orders;
    } else {
      this.decantOrders = this.orders.filter(order => order.requestTypeID === Number(val));
    }
    this.selectedVal = val;
    this.buildDisplayData();

  }

  ngOnInit(): void {
    // this.selectedVal ='option1';
  }

  ngAfterViewInit(): void {
    if (this.selectedStation) {
      this.picksheetRef.nativeElement.value = '';
      this.picksheetRef.nativeElement.focus();
    }
  }
}
