<span class="pill" [ngClass]="
type == 'Cold' ? 'cold' :
type == 'Desiccant' ? 'desiccant' :
type == 'GLP' ? 'glp' :
type == 'OnHold' ? 'on-hold' :
type == 'Checked' ? 'green-check' :
type == 'Warning' ? 'yellow-warning' :
type == 'IndyReserved' ? 'indy-reserved' :
type == 'SgsReserved' ? 'sgs-reserved' :
type == 'OnSiteActive' ? 'onsite-active' :
type == 'Master' ? 'master' :
type == 'M' ? 'm' :
type == 'tag' ? 'tag' :
type == 'REACHCompliantRequested' ? 'reach-compliant-requested' :
type == 'ExtraPPERequired' ? 'extra-ppe-required' :
type == 'NoDecant' ? 'no-decant' :''">{{text}}</span>