import { Injectable } from '@angular/core';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Configuration } from 'src/app/configuration';

@Injectable({
  providedIn: 'root'
})
export class ManageStationService {

  constructor(private readonly http: HttpClient, private readonly responseHandler: ResponseHandlingService) {
  }

  getStations(): Observable<any[]> {
    var URL = `${Configuration.REST_API_URL}/Stations`;
    return this.http.get<any[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to get list of stations at this time, please resubmit."]]), false)));
  }

  deleteStation(stationId: number): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/Stations/${stationId}`;
    return this.http.delete<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to delete the station at this time, please resubmit."]]), false)));
  }

  createStation(station: any): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/Stations`;
    return this.http.post<any>(URL, station).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable save stations at this time, please resubmit."]]), false)));
  }

  editStation(stationId: number, station: any): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/Stations/${stationId}`;
    return this.http.put<any>(URL, station).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable save stations at this time, please resubmit."]]), false)));
  }
}
