<div style="width: 900px;">
    <h5 class="p-mb-3">Split Request</h5>
    <div class="p-field p-mt-2">
        <div class="p-col">
            <p class="message">Select containers to move. Only unfilled containers are shown</p>
            <label>{{order?.requestNumber}} </label>
        </div>
        <div class="p-grid">
            <div class="p-col">
                <ag-grid-angular #oldGrid id="myGrid" style="width:100%;height: 180px;overflow: auto;"
                    class="ag-theme-alpine" suppressHorizontalScroll="true" [context]="spcontext" tooltipShowDelay="0"
                    [frameworkComponents]="frameworkComponents" [suppressRowClickSelection]="true" [rowData]="rowData"
                    [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" rowSelection="multiple"
                    [gridOptions]='gridOptions' (gridSizeChanged)="resizeGrid($event)"
                    [tooltipShowDelay]="tooltipShowDelay" [defaultColDef]="defaultColDef"
                    (selectionChanged)='onSelectionChanged($event)'>
                </ag-grid-angular>
            </div>
        </div>
        <div class="p-col">
            <label>New</label>
        </div>
        <div class="p-grid">
            <div class="p-col">
                <ag-grid-angular #newGrid id="myGrid" [frameworkComponents]="frameworkComponents"
                    style="width:100%;height: 200px;overflow: auto; " suppressHorizontalScroll="true"
                    class="ag-theme-alpine" [defaultColDef]="defaultOrderColDef" [tooltipShowDelay]="tooltipShowDelay"
                    tooltipShowDelay="0" suppressCellSelection="true" [suppressRowClickSelection]="true"
                    [rowData]="newOrderrowData" [columnDefs]="columnDefNewOrders"
                    (gridReady)="neworderonGridReady($event)" rowSelection="multiple" [gridOptions]='gridOptions'
                    (gridSizeChanged)="neworderresizeGrid($event)" (selectionChanged)='onSelectionChanged($event)'>
                </ag-grid-angular>
            </div>
        </div>
    </div>
    <div class="actions flex-end" style="float: right;">
        <label class="radiobutton" *ngFor="let radiobutton of orderStatuses">
            <input type="radio" name="selectedOrderStatus" (click)="orderStatus.option = radiobutton"
                [checked]="radiobutton == orderStatus.option">{{radiobutton}}
        </label>
        <button mat-raised-button color="cor-default" (click)="cancel()">Cancel</button>
        <button mat-raised-button color="cor-primary" [disabled]="newOrderrowData.length < 1"
            (click)="requestTransferModal()">Split Request</button>
    </div>
</div>