import { EnvironmentDefinition } from "../../models/environment-models";

export class Environments {

    public static environments: EnvironmentDefinition[] = [
        {
            name: 'LOCAL',
            hosts: ['localhost'],
            //ShellAPIPath: 'https://localhost:44316',
            ShellAPIPath: 'https://alchemist-api-dev.se.research.corteva.com',
            ShellUIPath: 'http://localhost:4200',
            clientID: 'b1d494fc-fa9f-41fe-a1e7-2e0a9a4a4326',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            appInsightsKey: 'daf10a5a-6541-4db5-a534-8b82353308e9',
            readScope:'api://gssalchemist-nonprod/Api.Read',
            writeScope:'api://gssalchemist-nonprod/Api.Write'
        },
        {
            name: 'DEV',
            hosts: ['dev'],
            ShellAPIPath: 'https://alchemist-api-dev.se.research.corteva.com',
            ShellUIPath: 'https://alchemist-dev.se.research.corteva.com',
            clientID: 'b1d494fc-fa9f-41fe-a1e7-2e0a9a4a4326',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            appInsightsKey: 'daf10a5a-6541-4db5-a534-8b82353308e9',
            readScope:'api://gssalchemist-nonprod/Api.Read',
            writeScope:'api://gssalchemist-nonprod/Api.Write'            
        },
        {
            name: 'QA',
            hosts: ['qa'],
            ShellAPIPath: 'https://alchemist-api-qa.se.research.corteva.com',
            ShellUIPath: 'https://alchemist-qa.se.research.corteva.com',
            clientID: 'b1d494fc-fa9f-41fe-a1e7-2e0a9a4a4326',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            appInsightsKey: '27507dae-be64-49a5-805c-d17ee173a0e0',
            readScope:'api://gssalchemist-nonprod/Api.Read',
            writeScope:'api://gssalchemist-nonprod/Api.Write'
        },
        {
            name: 'UAT',
            hosts: ['uat'],
            ShellAPIPath: 'https://alchemist-api-uat.se.research.corteva.com',
            ShellUIPath: 'https://alchemist-uat.se.research.corteva.com',
            clientID: 'b1d494fc-fa9f-41fe-a1e7-2e0a9a4a4326',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            appInsightsKey: '667e1dd9-5ef0-4544-a9c2-1b2d9311ef9d',
            readScope:'api://gssalchemist-nonprod/Api.Read',
            writeScope:'api://gssalchemist-nonprod/Api.Write'            
        },
        {
            name: 'PROD',
            hosts: ['gssalchemist.azurewebsites.net', 'alchemist.se.research.corteva.com'],
            ShellAPIPath: 'https://alchemist-api.se.research.corteva.com',
            ShellUIPath: 'https://alchemist.se.research.corteva.com',
            clientID: 'a5e53ea6-0ada-43b4-ad3d-f0242483967c',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            appInsightsKey: '92342d46-8b6d-4c88-a5d0-5a10cc76ec0f',
            readScope:'api://gssalchemist/Api.Read',
            writeScope:'api://gssalchemist/Api.Write'            
        },
    ];
}