import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { Configuration } from '../../configuration';
import { P2PFilterData } from '../models/p2p-filter-models';
import { OnHoldReason, P2PAttachmentResponse, P2PCountry, P2PDocumentResponse, P2PItemUpdateRequest, P2PListRequest, P2PListResponse, P2PPackageTrackingLog, P2PResponse, P2PUpdateRequest } from '../models/peer.models';
import { Tags } from 'src/app/models/tag';

@Injectable({ providedIn: 'root' })
export class PeerToPeerService {
  readonly rootURL = `${Configuration.REST_API_URL}/P2P/`;
  readonly rootBareURL = `${Configuration.REST_API_URL}/`;

  constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {

  }

  getCurrentP2PList(request: Partial<P2PListRequest>): Observable<P2PListResponse[]> {
    var URL = this.rootURL + 'List';
    return this.http.post<P2PListResponse[]>(URL, request)
      .pipe(
        map(r => r),
        catchError(err => this.responseHandler.handleError(
          err,
          new Map([[400, "Unable to get P2P the data."]]
          ), false)
        )
      );
  }

  getTags(): Observable<Tags[]> {
    var URL = this.rootBareURL + `Tags`;
    return this.http
      .get<Tags[]>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getP2PFilters(): Observable<P2PFilterData> {
    var URL = this.rootURL + `Filters`;
    return this.http.get<P2PFilterData>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getById(requestId: string): Observable<P2PResponse> {
    var URL = this.rootURL + `${requestId}`;
    return this.http.get<P2PResponse>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getCountries(): Observable<P2PCountry[]> {
    var URL = `${this.rootURL}Countries`;
    return this.http.get<P2PCountry[]>(URL)
      .pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  printAddressLabel(requestID: string, stationID?: string) {
    let URL;
    if (stationID) URL = this.rootURL + `${requestID}/PrintAddressLabel/${stationID}`;
    else URL = this.rootURL + `${requestID}/PrintAddressLabel`;
    return this.http.post<any>(URL, null).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getCustomInvoice(requestID: string) {
    var URL = this.rootURL + `${requestID}/CustomsInvoice`;
    return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getOnHoldReasons(): Observable<OnHoldReason[]> {
    var URL = this.rootBareURL + 'HoldReason';
    return this.http.get<OnHoldReason[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getPackingListContent(requestID: string) {
    var URL = this.rootURL + `${requestID}/PackingList`;
    return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  patchP2P(p2pReqestId: string, request: Partial<P2PUpdateRequest>): Observable<P2PResponse> {
    var URL = this.rootURL + `${p2pReqestId}`;
    return this.http.patch<P2PResponse>(URL, request).pipe(catchError(err => this.responseHandler.handleError(err)));
  }
  createPackage(requestID: string, payload): Observable<any> {
    var URL = this.rootURL + `package/${requestID}`;
    return this.http.post<any>(URL, payload).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  deletePackage(packageID: string): Observable<any> {
    var URL = this.rootURL + `package/${packageID}`;
    return this.http.delete<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getShippers(): Observable<any> {
    var URL = this.rootBareURL + `shipper`;
    return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  updateP2PItem(requestItemId: string, model: Partial<P2PItemUpdateRequest>): Observable<P2PResponse> {
    var URL = this.rootURL + `/Item/${requestItemId}`;
    return this.http.patch<P2PResponse>(URL, model).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  addAttachment(requestId: string, documentTypeId: number, languageId: number, files: any, requestItemId?: string, comments?: string) {
    var URL = this.rootURL + `Attachment`;
    let formData: FormData = new FormData();

    for (let file of files) {
      formData.append('file[]', file);
      formData.append('RequestId', requestId);
      formData.append('RequestItemId', requestItemId);
      formData.append('DocumentTypeID', documentTypeId.toString());
      formData.append('languageID', languageId.toString());
      formData.append('Comments', comments);
    }

    return this.http.post<P2PAttachmentResponse>(URL, formData).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  updateAttachment(attachmentId: string, documentTypeId: number, comments?: string, languageId?: number) {
    var URL = this.rootURL + `Attachment/${attachmentId}`;
    return this.http.put(URL, { documentTypeID: documentTypeId, languageID: languageId, comments: comments }).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  removeAttachment(attachmentId: string) {
    var URL = this.rootURL + `Attachment/${attachmentId}`;
    return this.http.delete(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getAttachment(attachmentId: string) {
    var URL = this.rootURL + `Attachment/${attachmentId}`;
    return this.http.get<P2PAttachmentResponse>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getDocuments(itemId: string) {
    var URL = this.rootURL + `Item/${itemId}/documents`;
    return this.http.get<P2PDocumentResponse[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  attachDocument(itemId: string, documentId: string) {
    var URL = this.rootURL + `Item/${itemId}/documents/${documentId}`;
    return this.http.post<P2PDocumentResponse>(URL, null).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getP2PPackageLogs(packageID: any): Observable<P2PPackageTrackingLog[]> {
    var URL = this.rootURL + 'Package/' + packageID + '/Tracking';
    return this.http.get<P2PPackageTrackingLog[]>(URL)
      .pipe(
        map(r => r),
        catchError(err => this.responseHandler.handleError(
          err,
          new Map([[400, "Unable to get P2P package logs."]]
          ), false)
        )
      );
  }

}
