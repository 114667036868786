<div class="p-grid">
    <div class="p-col">
        <h5>Search</h5>
    </div>
    <div class="alignRight p-col">
        <a (click)="onClickOfPreviousSessions()">Previous Receiving Sessions</a>
    </div>
</div>
<form id="searchForm" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="p-d-flex p-flex-column search-form">
        <div class="p-as-justify">
            <div class="p-d-flex p-flex-column p-flex-md-row p-flex-sm-column p-jc-between">
                <div class="p-d-flex p-flex-column" formArrayName="repos">
                    <ng-container *ngFor="let repo of repos.controls; index as i" [formGroupName]="i">
                        <div class="p-field-radiobutton p-mr-5">
                            <p-inputSwitch formControlName="selected" name="selected" ngDefaultControl
                                [inputId]="'rbtn_material' + repo.get('id').value" styleClass="p-mt-2">
                            </p-inputSwitch>
                            <label [attr.for]="'rbtn_material' + repo.get('id').value">{{ repo.get('name').value
                                }}</label>
                        </div>
                    </ng-container>
                </div>
                <div class="p-d-flex p-flex-column p-field p-justify-center input-search p-mb-0 p-ml-md-5 p-ml-sm-0">

                    <div class="p-d-flex p-flex-column">
                        <span class="p-float-label">
                            <p-dropdown id="warehouses" formControlName="warehouse" placeholder=" " autofocus [style]="{'width':'100%'}"
                            [(ngModel)]="selectedWarehouse" [options]="warehousesRes" optionLabel="itemDesc" class="warehouses-dropdown"
                            panelStyleClass="station-selection-minWidthOverride" (onChange)="onWareHouseChange($event.value)">
                        </p-dropdown>
                            <label id="lblWarehouse" for="material">Select a warehouse</label>
                        </span>             
                    </div>
                   
                    <div class="p-d-flex p-flex-column" style="margin-top: 25px;">
                        <span class="p-float-label">
                            <input id="txtMaterial" type="text" maxlength="100" formControlName="searchKeyWord" pInputText />
                            <label id="lblMaterial" for="material">Scan barcode or enter material name to search</label>
                        </span>
                
                        <small id="lblMaterialMinlength"
                            [ngClass]="{'p-error': searchForm.get('searchKeyWord').invalid && searchForm.get('searchKeyWord').errors?.minlength}">
                            Enter minimum 3 characters to perform a search</small>
                        <div class="p-field-checkbox p-mb-0 p-mt-2">
                            <p-checkbox formControlName="isTsnSeach" binary="true" inputId="chkTsnSeach"></p-checkbox>
                            <label for="chkTsnSeach" [ngClass]="{'disabled-label':searchForm.get('isTsnSeach').disabled}">TSN
                                Search</label>
                        </div>
                    </div>
                    <p-messages id="msgContainer" key="searchkeyword"></p-messages>
                </div>
                </div>
        </div>
        <div class="p-text-right">
            <app-navigate>
                <div class="p-text-right p-col">
                    <button id="btnSearchMaterial" class="p-mb-2 p-as-right search-button" pButton pRipple type="submit"
                        [disabled]="searchForm.invalid || $loading" label="Search">
                        <span *ngIf="$loading" class="p-button-icon-right ng-star-inserted pi pi-spin pi-spinner"
                            aria-hidden="true"></span>
                    </button>
                </div>
            </app-navigate>
        </div>
    </div>
</form>