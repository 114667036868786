import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-sampleuses-modal',
  templateUrl: './edit-sampleuses-modal.component.html',
  styleUrls: ['./edit-sampleuses-modal.component.scss']
})
export class EditSampleusesModalComponent implements OnInit {
  public sampleUseForm: FormGroup;
  public buttonLabel: string;

  constructor( public dialogRef: MatDialogRef<EditSampleusesModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.buttonLabel = "Edit";
  }

  ngOnInit(): void {
    this.buildForm();
    this.initData();
  }

  initData() {
    if (this.data == null) {
      var data = {
        sampleUseID: 0,
        name: "",
      };

      this.data = data;
      this.buttonLabel = "Add New";
    }

    this.patchForm();
  }

  onSubmit() {
    this.dialogRef.close(this.sampleUseForm.value);
  }

  private patchForm() {
    this.sampleUseForm.patchValue({
      sampleUseID: this.data.sampleUseID,
      name: this.data.name
    });
  }

  private buildForm() {
    this.sampleUseForm = new FormGroup({
      sampleUseID: new FormControl(null),
      name: new FormControl(null, [Validators.required])
    });
  }
}

