import { orderBy, sumBy } from 'lodash';
import { ColDef, GridApi } from 'ag-grid-community';
import {
  Component,
  Input,
  AfterViewInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { NInventoryItems } from 'src/app/models/inventory-models';
import { StorageLocationChangeComponent } from '../storage-location-change/storage-location-change.component';
import { MessageService } from 'src/app/shared/services/message-queue.service';
import { Subscription } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';

@Component({
  selector: 'app-inventory-item',
  templateUrl: './inventory-item.component.html',
  styleUrls: ['./inventory-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryItemComponent implements AfterViewInit, OnDestroy {
  private gridApi!: GridApi;

  @Input() itemId: number;
  @Input() inventoryItems: NInventoryItems[] = [];

  totalWeight: number;
  frameworkComponents: any;
  subscriptions: Subscription;
  items: NInventoryItems[] = [];

  constructor(private readonly messageService: MessageService) {
    this.subscriptions = new Subscription();
    this.frameworkComponents = {
      storageLocationRendered: StorageLocationChangeComponent,
    };
  }

  columnDefs: ColDef[] = [
    { headerName: 'Lot Number', field: 'lotNumber' },
    { headerName: 'Container ID', field: 'inventoryItemID' },
    {
      headerName: 'Weight',
      field: 'amountKg',
      cellRenderer: (params) => {
        return parseFloat(params.value!.toFixed(7))?.toString() + ' kg';
      },
    },
    { headerName: 'Storage Location', field: 'fullLocationName' },
    {
      headerName: 'Edit',
      cellRenderer: 'storageLocationRendered',
      cellClass: 'button-edit',
      cellRendererParams: { data: this },
    },
  ];

  ngAfterViewInit(): void {

    this.items = this.inventoryItems;

    this.subscriptions.add(
      this.messageService.message.subscribe((modelData) => {
        const index = this.items.findIndex(
          (i) => i.inventoryItemID == modelData.reference.inventoryItemID
        );
        if (index != -1) {
          let newRowData = this.items.map((row, i) => {
            if (i == index) {
              return { ...row, fullLocationName: modelData.data.locationLabel, locationID: modelData.data.data.locations };
            }
            return row;
          });
          this.items = newRowData;
          this.gridApi.setRowData(this.items)
        }
      })
    );

    this.items = orderBy(
      this.items,
      ['lotNumber', 'amountKg'],
      ['asc', 'asc']
    );
    this.totalWeight = sumBy(this.items, 'amountKg').toFixed(7);
  }

  ngOnDestroy(): void { }

  onResizeGrid(params) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }
}
