import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Shipper } from '../../models/shipper';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Configuration } from '../../configuration';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';

@Injectable({
  providedIn: 'root',
})
export class ShipperService {
  constructor(
    private http: HttpClient,
    private responseHandler: ResponseHandlingService
  ) {}

  getShipper(shipperId: any): Observable<Shipper> {
    var URL = Configuration.REST_API_URL + `/Shipper/${shipperId}`;
    return this.http.get<Shipper>(URL).pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getShippers(): Observable<Shipper[]> {
    var URL = Configuration.REST_API_URL + `/Shipper`;
    return this.http.get<Shipper[]>(URL).pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  editShippers(shipper: Shipper): Observable<Shipper> {
    var URL = `${Configuration.REST_API_URL}/Shipper`;
    return this.http.post<Shipper>(URL,shipper).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to save shipper."]]), false)));
  }

  deleteShipper(shipperId: number): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/Shipper/${shipperId}`;
    return this.http.delete<any>(URL).pipe(
      catchError(err => {
        this.responseHandler.showMessage("Unable to delete shipper.",'error', "Error",3000, false);
        return of(null);
      })
    );
  }
}
