<h5 class="p-mb-3">{{buttonLabel}} Label Printer</h5>

<form [formGroup]="printerLabelForm" (submit)="onSubmit()">
    <div class="p-grid p-mt-3 p-mb-4">
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Warehouse:</div>
                <p-dropdown id="warehouses" placeholder="Select a warehouse" formControlName="warehouseId"
                    [options]="warehousesRes" optionLabel="itemDesc" optionValue="itemID" class="warehouses-dropdown"
                    [style]="{ minWidth: '100%', width: '100%' }" autoWidth="false" [styleClass]="printerLabelForm.controls['warehouseId'].invalid && isSubmitClicked
       ? 'border-red-500' : '' ">
                </p-dropdown>
                <div *ngIf="printerLabelForm.controls['warehouseId'].invalid && isSubmitClicked">
                    <small style="margin-left: 5px; color: red; font-size: 12px;"
                        *ngIf="printerLabelForm.controls.warehouseId.errors?.required">
                        Field required.
                    </small>
                </div>
            </div>
        </div>
        <div class="p-col-6">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Display Name:</div>
                <input formControlName="displayName" pInputText type="text" style="width: 500px;"
                    [class.error]="fc['displayName'].invalid && isSubmitClicked" maxlength="80" />
                <div *ngIf="printerLabelForm.controls['displayName'].invalid && isSubmitClicked">
                    <small style="margin-left: 5px; color: red; font-size: 12px;"
                        *ngIf="printerLabelForm.controls.displayName.errors?.required">
                        Field required.
                    </small>
                </div>
            </div>
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Bartender Name:</div>
                <input formControlName="bartenderName" pInputText type="text" style="width: 500px;"
                    [class.error]="fc['bartenderName'].invalid && isSubmitClicked" maxlength="80" />
                <div *ngIf="printerLabelForm.controls['bartenderName'].invalid && isSubmitClicked">
                    <small style="margin-left: 5px; color: red; font-size: 12px;"
                        *ngIf="printerLabelForm.controls.bartenderName.errors?.required">
                        Field required.
                    </small>
                </div>
            </div>
            <div style="margin-bottom: 10px;">
                <div>DNS Name:</div>
                <input formControlName="dnsName" pInputText type="text" style="width: 500px;" maxlength="40" />
            </div>
        </div>
        <div class="p-col-6">
            <div style="margin-bottom: 10px;">
                <div>Stock Height (inches):</div>
                <input formControlName="labelStockHeight" pInputText type="text" numeric decimals="2" />
                <div
                    *ngIf="printerLabelForm.controls['labelStockHeight'].invalid && (printerLabelForm.controls['labelStockHeight'].dirty || printerLabelForm.controls['labelStockHeight'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;"
                        *ngIf="printerLabelForm.controls.labelStockHeight.errors?.min">
                        Value greater than 0.
                    </small>
                </div>
            </div>
            <div style="margin-bottom: 10px;">
                <div>Stock Width (inches):</div>
                <input formControlName="labelStockWidth" pInputText type="text" numeric decimals="2" />
                <div
                    *ngIf="printerLabelForm.controls['labelStockWidth'].invalid && (printerLabelForm.controls['labelStockWidth'].dirty || printerLabelForm.controls['labelStockWidth'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;"
                        *ngIf="printerLabelForm.controls.labelStockWidth.errors?.min">
                        Value greater than 0.
                    </small>
                </div>
            </div>
            <div style="margin-bottom: 10px;">
                <div>Printer Model:</div>
                <input formControlName="printerModel" pInputText type="text" />
            </div>
        </div>
        <div class="p-col-12">
            <div>Description:</div>
            <textarea rows="6" cols="47" formControlName="description" style="width: 500px;"
                [ngClass]="printerLabelForm.controls.description.hasError('maxlength') ? 'error' : ''"></textarea>
            <div
                *ngIf="printerLabelForm.controls['description'].invalid && (printerLabelForm.controls['description'].dirty || printerLabelForm.controls['description'].touched)">
                <small style="margin-left: 5px; color: red; font-size: 12px;"
                    *ngIf="printerLabelForm.controls.description.hasError('maxlength')">
                    Value can not exceed 200 characters
                </small>
            </div>
        </div>
        <div class="p-col-12">
            <span>Is Receiving Printer?</span>
            <mat-slide-toggle [style]="{'margin-left':'10px'}" formControlName="isReceivingPrinter">
            </mat-slide-toggle>
        </div>
        <mat-dialog-actions style="float: right" class="actions">
            <button style="margin-right: 15px;" mat-raised-button color="cor-default" mat-dialog-close>Cancel</button>
            <button mat-raised-button color="cor-primary" type="submit">Save</button>
        </mat-dialog-actions>
    </div>
</form>