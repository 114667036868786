<div class="flex-grid" style="margin-bottom:5px;margin-top:5px;">
    <div class="col" *ngIf="showPageSizes">
        Records per page
        <select (change)="selectChangeHandler($event)">
        <option value="10">10</option>
        <option value="25" selected="true">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
    <div class="col" style="text-align:right;">

        <span class="label"></span>
        <span class="value" style="font-weight: bold;" id="lbRowsDetail">
            {{ (paginatorModel.totalPages == 0) ? 0 : (((paginatorModel.currentPage * paginatorModel.pageSize) - paginatorModel.pageSize) + 1)}} to {{ (paginatorModel.currentPage * paginatorModel.pageSize) < paginatorModel.totalRows ? (paginatorModel.currentPage * paginatorModel.pageSize) : paginatorModel.totalRows }}
        </span>
    

        <span class="label">&nbsp;&nbsp;of &nbsp;</span>
        <span class="value" style="font-weight: bold;" id="lbTotalRows">{{ paginatorModel.totalRows }}</span>
        &nbsp;&nbsp; &nbsp;
     
        <button mat-icon-button color="cor-default" [disabled]="paginatorModel.leftArrowsDisabled" (click)="onBtFirst()">
            <i class="pi pi-angle-double-left"></i>
        </button>
        <button mat-icon-button color="cor-default" [disabled]="paginatorModel.leftArrowsDisabled" (click)="onBtPrevious()">
            <i class="pi pi-angle-left"></i>
        </button>
        
        <span class="label">&nbsp;&nbsp;Page:&nbsp;&nbsp;</span>
        <span class="value" style="font-weight: bold;" id="lbCurrentPage">{{ (paginatorModel.totalPages == 0) ? 0 : paginatorModel.currentPage }}</span>
        
        <span class="label"> &nbsp;of &nbsp;</span>
        <span class="value" style="font-weight: bold;" id="lbTotalPages">{{ paginatorModel.totalPages }}</span>
        &nbsp;&nbsp;
        <button mat-icon-button color="cor-default" [disabled]="paginatorModel.rightArrowsDisabled" (click)="onBtNext()">
            <i class="pi pi-angle-right"></i>
        </button>
        <button mat-icon-button color="cor-default" [disabled]="paginatorModel.rightArrowsDisabled" (click)="onBtLast()">
            <i class="pi pi-angle-double-right"></i>
        </button>
      </div>
</div>