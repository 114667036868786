import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'chemicalList' })
export class ChemicalListPipe implements PipeTransform {
  transform(chemicals: string): string {
    if (chemicals !== undefined && chemicals !== null && chemicals.length) {
      return chemicals.replaceAll('##', ', ');
    } else {
      return '';
    }
  }
}