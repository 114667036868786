import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { OrderItemAttachment, ViewAttachmentsModalData } from 'src/app/models/attachment-models';
import { DecantStation, Scale } from 'src/app/models/decant-models';
import { DocumentType } from 'src/app/models/document-type-model';
import { InventoryItem } from 'src/app/models/inventory-models';
import { OrderItemContainerModel } from 'src/app/models/order-item-container-model';
import { OrderItemModel } from 'src/app/models/order-item-model';
import { Order, RequestTypes } from 'src/app/models/order-models';
import { DecantService } from 'src/app/services/decant-service/decant-service';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { OrderItemAttachmentService } from 'src/app/services/order-item-attachments-service/order-tem-attachments.service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { PillRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/pill.render.component';
import { DisposeContainerConfirmModal, DisposeContainerConfirmModalComponent, IDisposeContainerConfirmModalResult } from '../dispose-container-confirm-modal/dispose-container-confirm-modal.component';
import { ScaleControlComponent } from '../scale-control/scale-control.component';
import { ScalePollerComponent } from '../scale-poller/scale-poller.component';
import { SpecialInstructionsDialogComponent } from '../special-instructions-dialog/special-instructions-dialog.component';
import { ViewAttachmentsModalComponent } from '../view-attachments-modal/view-attachments-modal.component';
import { CompleteDecantContainerDialogData, CompleteDecantContainerModalComponent } from './complete-decant-container-modal/complete-decant-container-modal.component';

@Component({
  selector: 'app-decant-container-details',
  templateUrl: './decant-container-details.component.html',
  styleUrls: ['./decant-container-details.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class DecantContainerDetailsComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild(ScalePollerComponent) child: ScalePollerComponent;
  @ViewChild(ScaleControlComponent) secondChild: ScaleControlComponent;
  @Input() order: Order;
  @Input() selectedStation: DecantStation;
  @Input() containerId: number;
  @Output() captureComplete: EventEmitter<any> = new EventEmitter();
  @Output() captureDecanting: EventEmitter<any> = new EventEmitter();
  @Output() getStationDetails: EventEmitter<any> = new EventEmitter();

  private subscriptions = new Subscription();
  private gridApi;
  private destroy$ = new Subject();

  public requestTypes = RequestTypes;

  public selectedStationOption: string;
  public selectedStationOptionName: string;
  public warehouseName: string;

  public orderItemAttachments: OrderItemAttachment[];
  public previousStationID: string;
  public selectedInventoryItem: InventoryItem;
  public selectedContainer: OrderItemContainerModel;
  public selectedOrderItem: OrderItemModel;
  public decantOrdersLoaded: boolean;
  public decantStations: DecantStation[];
  public filteredStationScales: Scale[];
  public currentContainerNumber: number;
  public totalContainerNeeded: number;
  public scalesReady: boolean;
  public columnDefs: any;
  public rowData: any;
  public scaleInfo$: Observable<DecantStation>;
  public scaleInfo: DecantStation;
  public weightCaptured: boolean;
  public printSuccess: boolean;
  public disableDispose: boolean = false;
  public documentTypes: DocumentType[];
  frameworkComponents: any;
  rowHeight = 50;

  sortedContainersFromMatchingLot: OrderItemContainerModel[] = [];
  currentContainerIndex = 0;
  scaleMode = "Hybrid"
  public decantingContainerNumberForDisplay: number;
  constructor(private router: Router, private decantService: DecantService,
    private inventoryService: InventoryService, private responseHandler: ResponseHandlingService,
    private orderItemAttachmentService: OrderItemAttachmentService,
    private orderService: OrderService, private loader: LoadingService,
    private dialog: MatDialog, private weightFormatKGPipe: WeightFormatKGPipe) {
    this.frameworkComponents = {
      pillRenderer: PillRenderer
    };

    this.columnDefs = [
      { headerName: 'Request #', field: 'requestID' },
      {
        headerName: 'Chemical',
        field: 'containerLabelFmt',
        tooltipValueGetter: (params) => params.data.containerLabelFmt,
        width: 520,
      },
      { headerName: 'Container', field: 'noDecantInventoryItemID' },
      { headerName: 'Lot Number', field: 'lotNumber' },
      { headerName: 'Amount Requested', field: 'amountNeeded' },
      { headerName: 'Containers Needed', field: 'containerQty' },
      { headerName: 'Container Number', field: 'containerNumber' },
      { headerName: 'Special Instructions', cellRenderer: 'pillRenderer' }
    ];

  }

  ngOnInit() {
    this.rowData = [];
    this.selectedStationOption = this.selectedStation.stationID;
    this.subscriptions.add(this.decantService
      .getDecantStations().subscribe(
        data => {
          this.decantStations = data;
          if (this.decantStations && this.decantStations.length > 0) {
            this.setupStationSelect();
          }
        }
      ));
    this.subscriptions.add(this.inventoryService
      .getInventoryItem(this.containerId).subscribe(
        data => {
          this.selectedInventoryItem = data;
          this.performSetUp(this.order);
          if (this.order?.specialInstructions || this.selectedOrderItem?.specialInstructions)
            this.showSpecialInstructionsConfirmDialog();
        }
      ));
    this.orderItemAttachmentService.documentTypes$.pipe(take(1)).subscribe((res: DocumentType[]) => {
      this.documentTypes = res;
    });
    this.selectedStationOptionName = this.selectedStation.name;
    this.warehouseName = this.selectedStation.warehouseName;
  }

  ngAfterContentInit() {
    this.scalesReady = true;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.subscriptions) {
      setTimeout(() => this.subscriptions.unsubscribe(), 2000);
    }
  }

  getOrderItemAttachments() {
    this.subscriptions.add(this.orderItemAttachmentService
      .getAttachmentListByOrderItemID(this.selectedOrderItem.orderItemID)
      .subscribe(
        (attachments: OrderItemAttachment[]) => {
          this.orderItemAttachments = attachments;
        }
      ));
  }

  showSpecialInstructionsConfirmDialog() {
    const dialogRef = this.dialog.open(SpecialInstructionsDialogComponent, {
      minWidth: "400px",
      maxWidth: "600px",
      disableClose: true,
      data: {
        order: this.order,
        selectedOrderItem: this.selectedOrderItem
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
    });
  }

  performSetUp(order: Order) {
    const lstContainers = [];
    order.items.forEach(item => lstContainers.push(...item.containers));

    let containersMatchingLot = [];
    containersMatchingLot = lstContainers.filter(container =>
      container.noDecantInventoryItemID === this.selectedInventoryItem?.itemID &&
      container.assignedLotNumber === this.selectedInventoryItem.lotNumber &&
      container.assignedInventoryCatalogID === this.selectedInventoryItem.catalogRecordID
    );

    if (!containersMatchingLot?.length)
      containersMatchingLot = lstContainers.filter(container =>
        container.noDecantInventoryItemID == null &&
        container.assignedLotNumber === this.selectedInventoryItem.lotNumber &&
        container.assignedInventoryCatalogID === this.selectedInventoryItem.catalogRecordID
      );

    let selectedContainerWithOrderItemID = containersMatchingLot[0].orderItemID;
    this.selectedOrderItem = order.items.find(i => i.orderItemID === selectedContainerWithOrderItemID);

    const totalMatchingContainers = containersMatchingLot.length;
    const totalUnfilledMatchingContainers = containersMatchingLot.filter(c => !c.decanted).length;
    if (!this.decantingContainerNumberForDisplay) {
      this.decantingContainerNumberForDisplay = totalMatchingContainers - totalUnfilledMatchingContainers + 1;
    }

    if (totalMatchingContainers && !this.currentContainerNumber) {

      let containers = [];
      containers = containersMatchingLot.filter(container =>
        container.noDecantInventoryItemID === this.selectedInventoryItem?.itemID &&
        container.assignedLotNumber === this.selectedInventoryItem.lotNumber &&
        container.assignedInventoryCatalogID === this.selectedInventoryItem.catalogRecordID);

      if (!containers?.length)
        containers = containersMatchingLot.filter(container => !container.noDecantInventoryItemID);

      this.sortedContainersFromMatchingLot = this.sortContainers(containers);
      if (this.sortedContainersFromMatchingLot?.length) {
        this.currentContainerNumber = this.sortedContainersFromMatchingLot[0].containerNumber;
        this.selectedContainer = this.sortedContainersFromMatchingLot[0];
      }
    }

    if (!this.totalContainerNeeded && this.totalContainerNeeded != 0) {
      this.totalContainerNeeded = containersMatchingLot.length;
    }
    if (this.selectedOrderItem)
      this.getOrderItemAttachments();

    this.createRowData();
  }

  getAmountRequested() {
    return `${this.weightFormatKGPipe.transform(this.selectedContainer.requestedAmount)} ${this.selectedOrderItem.unitOfMeasureDesc}`;
  }

  createRowData() {
    let rowData = [];
    rowData.push({
      requestID: this.order.requestNumber,
      containerLabelFmt: this.selectedOrderItem.containerLabelFmt,
      containerQty: this.sortedContainersFromMatchingLot.length,
      amountNeeded: `${this.weightFormatKGPipe.transform(this.selectedContainer.requestedAmount)} ${this.selectedOrderItem.unitOfMeasureDesc}`,
      actualAmount: this.selectedContainer.actualAmount,
      noDecantInventoryItemID: this.selectedContainer.noDecantInventoryItemID,
      lotNumber: this.selectedInventoryItem.lotNumber,
      complete: '',
      containerNumber: `${this.decantingContainerNumberForDisplay} of ${this.sortedContainersFromMatchingLot.length}`,
      extraPPERequired: this.selectedOrderItem?.extraPPERequired,
      isCold: this.selectedInventoryItem.locationName?.toLowerCase().includes('refrigerated') || this.selectedInventoryItem.locationName?.toLowerCase().includes('freezer'),
      isDesiccant: this.selectedInventoryItem?.locationName?.toLowerCase().includes('desiccant'),
      isNoDecantContainer: !!this.selectedContainer?.noDecantInventoryItemID
    });
    if (rowData.some(r => r.isDesiccant) && rowData.some(r => r.isCold) && rowData.some(r => r.extraPPERequired)) {
      this.rowHeight += 60;
    }
    else if (
      (rowData.some(r => r.isDesiccant) && rowData.some(r => r.isCold))
      || (rowData.some(r => r.isDesiccant) && rowData.some(r => r.extraPPERequired))
      || (rowData.some(r => r.extraPPERequired) && rowData.some(r => r.isCold))
    ) {
      this.rowHeight += 20;
    }
    this.rowData = [...rowData]
  }

  sortContainers(containers: any) {
    var result = containers.sort((item1, item2) => {
      if (item1.actualAmount === item2.actualAmount) {
        if (item1.containerNumber < item2.containerNumber) {
          return -1;
        } else {
          return 1;
        }
      }
      else if (item1.actualAmount < item2.actualAmount) {
        return -1
      }
      else
        return 1;
    }
    );
    return result
  }

  verifyGuid(input: string): boolean {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(input);
  }

  setupStationSelect() {
    if (this.selectedStationOption) {
      this.previousStationID = this.selectedStationOption;
    }

    this.filteredStationScales = this.getSelectedStationScales(this.selectedStationOption);
  }

  getSelectedDecantStation(stationId: string) {
    return this.decantStations.find((station) => station.stationID === stationId);
  }

  getSelectedStationScales(stationId: string) {
    const station = this.getSelectedDecantStation(stationId);
    return station ? station.scales : [];
  }

  handleScaleUpdate(event) {
    this.scaleInfo$ = event;
    this.scaleInfo$.pipe(take(1)).subscribe((res: DecantStation) => {
      this.handleDisposeParentContainerButton(res)
    });
  }

  handleDisposeParentContainerButton(station: DecantStation) {
    this.disableDispose = station.scales.some(scale => scale.weightKG >= this.selectedContainer.amount) || this.weightCaptured || this.selectedContainer.noDecantInventoryItemID !== null;
  }

  handleWeightCaptured(event) {
    this.disableDispose = true;
    this.weightCaptured = true;
  }

  onDisposeParentContainerClick() {
    const dialogData = new DisposeContainerConfirmModal(
      "Dispose Parent Container",
      "The Container will be permanently removed from inventory.",
      "Confirm", true);

    const dialogRef = this.dialog.open(DisposeContainerConfirmModalComponent, {
      width: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult: IDisposeContainerConfirmModalResult) => {
      if (dialogResult.isConfirm && dialogResult.inventoryItemNumber) {
        if (dialogResult.inventoryItemNumber != null && dialogResult.inventoryItemNumber.length > 0) {
          const input = Number.parseInt(dialogResult.inventoryItemNumber);
          if (isNaN(input)) {
            this.responseHandler.showError("Container ID Invalid");
          } else {
            if (input === this.containerId) {
              this.responseHandler.showError('A new parent container must be scanned');
              return;
            }
            this.loader.show();
            this.inventoryService.getInventoryItem(input).subscribe((newParentContainer: InventoryItem) => {
              if (this.selectedContainer.assignedLotNumber === newParentContainer.lotNumber && (this.selectedContainer.assignedInventoryCatalogID === newParentContainer.catalogRecordID)) {
                // Ensure that the requested type matches Stock or GLP
                if (this.order.requestTypeID === RequestTypes.GLP && !newParentContainer.fullLocationName.toLowerCase().includes('glp')
                  || (this.order.requestTypeID === RequestTypes.Stock && newParentContainer.fullLocationName.toLowerCase().includes('glp'))) {
                  const lotType = this.order.requestTypeDesc;
                  this.responseHandler.showError(`Need ${lotType} lot for request`);
                  return;
                }
                // After confirming that the parent container exists and is a match, we then dispose the old parent container before assigning the new one
                this.inventoryService.disposeInventoryItems([{
                  "inventoryItemID": this.containerId,
                  "disposalReasonID": parseInt(dialogResult.disposalReason),
                  "comment": dialogResult.comments
                }]).subscribe(data => {
                  this.responseHandler.showSuccess("Container disposed successfully. New lot matches request.");
                  this.selectedInventoryItem = newParentContainer;
                  this.containerId = input;
                }, error => {
                  this.responseHandler.showError("An error occurred while disposing the parent container");
                }, () => {
                  this.loader.clearMessage();
                });
              }
              else {
                this.responseHandler.showError("This container does not match the selected lot");
              }
            }, () => {
              this.loader.clearMessage();

            }
            );
          }
        }
      }
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;

    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  getStation() {
    this.getStationDetails.emit();
  }

  onCompleteClick() {
    if (this.selectedContainer?.noDecantInventoryItemID) {
      this.inventoryService.disposeInventoryItems([{
        "inventoryItemID": this.containerId,
        "disposalReasonID": 311
      }]).subscribe(data => {
        this.captureComplete.emit();
      }, error => {
        this.responseHandler.showError("An error occurred while disposing the parent container");
      });
      return;
    }
    const dialogData: CompleteDecantContainerDialogData = {
      totalContainerCount: this.sortedContainersFromMatchingLot.length,
      currentContainerCount: this.decantingContainerNumberForDisplay
    }
    const dialogRef = this.dialog.open(CompleteDecantContainerModalComponent, {
      width: '600px',
      data: dialogData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this.weightCaptured = false;
      if (result && result === 'next') {
        this.weightCaptured = false;
        this.disableDispose = false;
        this.decantingContainerNumberForDisplay++;
        this.currentContainerIndex++;
        this.selectedContainer = this.sortedContainersFromMatchingLot[this.currentContainerIndex];
        this.createRowData();
        this.secondChild.resetCaptureButton();
      } else if (result && result === 'reweigh') {
        // Navigate to reweigh
        this.captureDecanting.emit({
          selectedInventoryItem: this.selectedInventoryItem,
          selectedContainer: this.selectedContainer
        });
      }
      this.printSuccess = false
    });
  }

  onPrintLabel() {
    this.orderService.printLabel(this.selectedContainer.orderItemContainerID, this.selectedStation.stationID).subscribe(response => {
      this.printSuccess = true;
      this.responseHandler.showSuccess('Label sent to printer', 5000);
    },
      error => {
        this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000);
      });
  }

  onPrintGLPLabel() {
    this.orderService.printGLPLabel(this.selectedContainer.orderItemContainerID, this.selectedStation.stationID).subscribe(response => {
      this.printSuccess = true;
      this.responseHandler.showSuccess('Label sent to printer', 5000);
    },
      error => {
        this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000);
      });
  }

  onPrintSmallVialLabel() {
    this.orderService.printSmallVialLabel(this.selectedContainer.orderItemContainerID, this.selectedStation.stationID).subscribe(response => {
      this.printSuccess = true;
      this.responseHandler.showSuccess('Label sent to printer', 5000);
    },
      error => {
        this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000);
      });
  }

  onPrintSmallBarcodeLabel() {
    this.orderService.printSmallBarcodeLabel(this.selectedContainer.orderItemContainerID, this.selectedStation.stationID).subscribe(response => {
      this.printSuccess = true;
      this.responseHandler.showSuccess('Label sent to printer', 5000);
    },
      error => {
        this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000);
      });
  }

  openViewAttachments(): void {
    const data: ViewAttachmentsModalData = {
      orderAttachments: this.order.attachments,
      orderItems: this.order.items,
      requestNumber: this.order.requestNumber,
      documentTypes: this.documentTypes,
    }
    this.dialog.open(ViewAttachmentsModalComponent, {
      height: '600px',
      width: '700px',
      data,
    });
  }
}
