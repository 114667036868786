<h5>Unassign Containers</h5>

<ag-grid-angular style="width:100%; height: 250px; overflow: auto; margin-top: 20px;margin-bottom: 20px"
    class="ag-theme-alpine" suppressHorizontalScroll="true" [rowData]="rowData" [columnDefs]="columnDefs"
    [frameworkComponents]="frameworkComponents">
</ag-grid-angular>

<div class="actions">
    <button mat-raised-button color="cor-default" (click)="cancel()">Cancel</button>
    <button id="unassign" mat-raised-button color="cor-primary" (click)="unassign()">Unassign</button>
</div>