import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SortEvent } from 'primeng/api';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { Table } from 'primeng/table';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { DateValidator } from 'src/app/core/validators/date.validator';
import { DateCompareOperatorType, DateCompareValidator } from 'src/app/core/validators/dependent-field.validator';
import { MaxDateValidator } from 'src/app/core/validators/max-date.validator';
import { User } from 'src/app/models/security-models';
import { MsalAuthService } from 'src/app/services/auth-service/auth-service.service';
import { RoleService } from 'src/app/services/role-service/role-service.service';
import { LookupValuesModel } from '../models/lookup.model';
import { ConditionalOperatorType, FilterRequest, OperatorType, SearchSortingRequest } from '../models/session-search-request.model';
import { SessionModel, SessionViewModel, StatusType } from '../models/session.model';
import { receivingConstants } from '../receiving.constants';
import { ReceivingService } from '../services/receiving.service';


@Component({
  selector: 'app-previous-sessions',
  templateUrl: './previous-sessions.component.html',
  styleUrls: ['./previous-sessions.component.scss']
})
export class PreviousSessionsComponent implements OnInit {
  searchForm: FormGroup = undefined;
  statusTypes: LookupValuesModel[] = [
    { id: StatusType.Draft, description: 'Draft' },
    { id: StatusType.Completed, description: 'Completed' },
    { id: StatusType.Defect, description: 'Defect' },
    { id: StatusType.LargeDecant, description: 'LargeDecant' }
  ];
  defectReasons: LookupValuesModel[] = [];
  receivers: User[] = [];
  sessions: SessionModel[] = [];
  isLoadingData = false;
  lastFilterRequest: FilterRequest;
  statusTypeEnum = StatusType;
  defaultSortingRequest: SearchSortingRequest[] = [
    {
      sortOrder: 2,
      sortProperty: 'Created'
    },
    {
      sortOrder: 2,
      sortProperty: 'Updated'
    }];
  lastSortingRequest: SearchSortingRequest[] = [];
  totalRecords = 0;
  @ViewChild('sessionTable') sessionTable: Table;
  constructor(private receivingService: ReceivingService, private loadingService: LoadingService, private roleService: RoleService, private authService: MsalAuthService) {

    this.receivingService.getLookupValues(`${receivingConstants.LookupTypes.DefectReasons}`).then(data => {
      this.defectReasons = this.receivingService.loadLookupValues(`${receivingConstants.LookupTypes.DefectReasons}`, data);
    });
  }

  ngOnInit(): void {
    this.loadingService.show();
    this.searchForm = this.initForm();
    this.roleService.getUsersByRole(receivingConstants.DefaultReceiverRoleName)
      .subscribe(role => {
        if (role) {
          this.receivers = role.users;
          const loggedInUserId = this.authService.userInfo.id;
          const defaultReceiver = this.receivers.find(x => x.adUserID == loggedInUserId);
          if (defaultReceiver) {
            this.searchForm.get('Receiver').setValue(defaultReceiver.userID);
          }
        }
        this.loadingService.clearMessage();
        this.loadDefaultSession();
      })
    //lets reset the scroll to top on page refresh
    window.scrollTo(0, 0);
  }

  initForm(): FormGroup {
    return new FormGroup({
      MaterialName: new FormControl(''),
      StatusType: new FormControl(),
      Receiver: new FormControl(),
      ReceivedStartDate: new FormControl('', [MaxDateValidator(receivingConstants.DateFormat, new Date()), DateCompareValidator(DateCompareOperatorType.LessThanEqual, 'ReceivedEndDate')]),
      ReceivedEndDate: new FormControl('', [MaxDateValidator(receivingConstants.DateFormat, new Date()), DateCompareValidator(DateCompareOperatorType.GreaterThanEqual, 'ReceivedStartDate')]),
      SessionContainerId: new FormControl('', [
        Validators.maxLength(18),
        Validators.min(1),
        Validators.pattern("^[0-9]*$")]),
      LotNumber: new FormControl(''),
      defectReasonId: new FormControl(),
    });
  }

  resetForm() {
    this.searchForm.reset();
  }
  onSubmit() {
    if (this.searchForm.valid) {
      this.sessionTable.reset();
      this.loadDefaultSession();
    }
  }

  loadDefaultSession() {
    this.resetSession();
    this.totalRecords = 0;
    this.lastSortingRequest = this.defaultSortingRequest;
    let filterRequest = this.getFilterRequestFromFormValues();
    this.getSessions(filterRequest);
  }

  getSessions(filterRequest: FilterRequest) {
    this.isLoadingData = true;
    this.loadingService.show();
    this.receivingService.searchSessions(filterRequest).subscribe(data => {
      if (!data) return;
      this.lastFilterRequest = filterRequest;
      if (data.TotalPages > this.lastFilterRequest.pageNumber) this.lastFilterRequest.pageNumber += 1;
      if (data.items.length > 0) this.sessions.push(...data.items);
      this.loadingService.clearMessage();
      this.isLoadingData = false;
      this.totalRecords = data.TotalCount;
    });
  }

  loadMoreSessions() {
    if (this.isLoadingData === true || !this.lastFilterRequest) return true;
    this.getSessions(this.lastFilterRequest);
  }

  getFilterRequestFromFormValues(): FilterRequest {
    let filterRequest: FilterRequest =
    {
      pageNumber: 0,
      pageSize: receivingConstants.DefaultGridPageSize,
      filters: [],
      sortOrders: this.lastSortingRequest
    }

    const formValues = this.searchForm.value;
    if (formValues.MaterialName && formValues.MaterialName.length > 0) {
      filterRequest.filters.push({ propertyName: 'MaterialName', value: formValues.MaterialName, operatorType: OperatorType.Contains, conditionalOperatorType: ConditionalOperatorType.And, innerFilters: [] });
    }

    if (formValues.StatusType !== null) {
      filterRequest.filters.push({ propertyName: 'StatusType', value: formValues.StatusType, operatorType: OperatorType.Equal, conditionalOperatorType: ConditionalOperatorType.And, innerFilters: [] });
    }

    if (formValues.defectReasonId !== null) {
      filterRequest.filters.push({ propertyName: 'LastDefectReasonId', value: formValues.defectReasonId, operatorType: OperatorType.Equal, conditionalOperatorType: ConditionalOperatorType.And, innerFilters: [] });
    }

    if (formValues.SessionContainerId && +formValues.SessionContainerId > 0) {
      filterRequest.filters.push({ propertyName: 'SessionContainerWeights[CatalogInventoryItemId]', value: formValues.SessionContainerId, operatorType: OperatorType.Equal, conditionalOperatorType: ConditionalOperatorType.And, innerFilters: [] });
    }

    if (formValues.LotNumber && formValues.LotNumber.length > 0) {
      filterRequest.filters.push({ propertyName: 'ManufacturerLotId', value: formValues.LotNumber, operatorType: OperatorType.Equal, conditionalOperatorType: ConditionalOperatorType.And, innerFilters: [] });
    }

    let startDate: Date;
    let endDate: Date;
    let formattedReceivedStartDate: string;
    let formattedReceivedEndDate: string;
    if (formValues.ReceivedStartDate instanceof Date) {
      startDate = (formValues.ReceivedStartDate as Date);
      formattedReceivedStartDate = startDate.toLocaleDateString();
    }

    if (formValues.ReceivedEndDate instanceof Date) {
      endDate = (formValues.ReceivedEndDate as Date);
      const endDateValue = new Date(endDate.toLocaleDateString());
      endDateValue.setDate(endDateValue.getDate() + 1);
      formattedReceivedEndDate = endDateValue.toLocaleDateString();
    }

    if (startDate && endDate) {
      filterRequest.filters.push({
        propertyName: 'Created', value: formattedReceivedStartDate, value2: formattedReceivedEndDate, operatorType: OperatorType.Between, conditionalOperatorType: ConditionalOperatorType.And, innerFilters: [
          { propertyName: 'Updated', value: formattedReceivedStartDate, value2: formattedReceivedEndDate, operatorType: OperatorType.Between, conditionalOperatorType: ConditionalOperatorType.Or, innerFilters: [] }
        ]
      });
    } else if (startDate) {
      filterRequest.filters.push({
        propertyName: 'Created', value: formattedReceivedStartDate, operatorType: OperatorType.GreaterThanEqual, conditionalOperatorType: ConditionalOperatorType.And, innerFilters: [
          { propertyName: 'Updated', value: formattedReceivedStartDate, operatorType: OperatorType.GreaterThanEqual, conditionalOperatorType: ConditionalOperatorType.Or, innerFilters: [] }
        ]
      });
    } else if (endDate) {
      filterRequest.filters.push({
        propertyName: 'Created', value: formattedReceivedEndDate, operatorType: OperatorType.LessThan, conditionalOperatorType: ConditionalOperatorType.And, innerFilters: [
          { propertyName: 'Updated', value: formattedReceivedEndDate, operatorType: OperatorType.LessThan, conditionalOperatorType: ConditionalOperatorType.Or, innerFilters: [] }
        ]
      });
    }

    if (formValues.Receiver && +formValues.Receiver > 0) {
      filterRequest.filters.push({
        propertyName: 'CreatedById', value: formValues.Receiver, operatorType: OperatorType.Equal, conditionalOperatorType: ConditionalOperatorType.And, innerFilters:
          [
            { propertyName: 'UpdatedById', value: formValues.Receiver, operatorType: OperatorType.Equal, conditionalOperatorType: ConditionalOperatorType.Or, innerFilters: [] }
          ]
      });
    }

    return filterRequest;
  }

  onGridSortIconClicked(event: SortEvent) {
    if (this.isLoadingData === true || !this.lastFilterRequest) return;
    if (event.multiSortMeta && event.multiSortMeta.length > 0) {
      this.lastSortingRequest = [];
      event.multiSortMeta.forEach(sortItem => {
        const sortOrder = sortItem.order > 0 ? 1 : 2;
        if (sortItem.field === 'date') {
          this.lastSortingRequest.push(...[{
            sortOrder: sortOrder,
            sortProperty: 'Created'
          },
          {
            sortOrder: sortOrder,
            sortProperty: 'Updated'
          }])
        } else {
          this.lastSortingRequest.push({
            sortOrder: sortOrder,
            sortProperty: sortItem.field
          });
        }

      });
      this.lastFilterRequest.pageNumber = 0;
      this.resetSession();
      this.lastFilterRequest.sortOrders = this.lastSortingRequest;
      this.getSessions(this.lastFilterRequest);
    }
  }

  resetSession() {
    this.sessions = [];
  }

  onFromDateBlur() {
    this.searchForm.get('ReceivedEndDate').updateValueAndValidity();
  }

  onToDateBlur() {
    this.searchForm.get('ReceivedStartDate').updateValueAndValidity();
  }
}
