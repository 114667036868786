import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../configuration';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { WarehouseManagerOptions, WarehouseModel } from '../../models/warehouse-model';

@Injectable({ providedIn: 'root' })
export class WarehouseManagerService {

  constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
  }

  saveWarehouse(wareshouseModel: WarehouseModel) {
    const headers = { 'content-type': 'application/json' }
    var URL = Configuration.REST_API_URL + '/warehouse';
    return this.http.post<any>(URL, JSON.stringify(wareshouseModel), { 'headers': headers }).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getWarehouseByID(warehouseID: number) {
    var URL = Configuration.REST_API_URL + `/warehouse/${warehouseID}`;
    return this.http.get<WarehouseModel>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getWarehouseList() {
    var URL = Configuration.REST_API_URL + `/warehouse`;
    return this.http.get<WarehouseManagerOptions[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }
}