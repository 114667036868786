import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { removeOrderByID, sortOrdersByNeedByDate } from '../../helpers/filter-helpers';
import { Order } from '../../models/order-models';
import { OrderListRequest } from '../../models/order-models';
import { OrderFilterModel } from '../../models/filter-models';
import { PdfService } from '../../services/pdf-service/pdf-service';
import { OrderService } from '../../services/order-service/order-service';
import { UserService } from '../../services/user-service/user-service.service';
import { OrderFiltersComponent } from '../order-filters/order-filters.component';
import { ResponseHandlingService } from '../../services/response-handling-service/response-handling-service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/base-component';
import { AgGridCustomPaginatorComponent } from '../../core/modules/ag-grid/components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';
import { finalize, flatMap } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';

@Component({
  selector: 'app-order-warehouse',
  templateUrl: './order-warehouse.component.html',
  styleUrls: ['./order-warehouse.component.scss']
})
export class OrderWarehouseComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(AgGridCustomPaginatorComponent) child: AgGridCustomPaginatorComponent;

  @ViewChild('appFilterOrder', { static: true }) appFilterOrderInstance: OrderFiltersComponent;
  public warehouseQueueOrders: Order[];
  public previousFilterModel: OrderFilterModel;
  public filterModel: OrderFilterModel;
  public warehouseQueueOrdersLoaded: boolean;
  public disableClearFilters: boolean = true;

  private subscriptions = new Subscription();
  public hasFilterModelChanged: boolean = true;

  constructor(private orderService: OrderService, private loadingService: LoadingService, private router: Router, private userService: UserService, private responseHandler: ResponseHandlingService, private pdfService: PdfService, public dialog: MatDialog) {
    super();
    this.warehouseQueueOrdersLoaded = false;

    const currentUser = JSON.parse(localStorage.getItem("authUserInfo"));

  }

  setFilterModel() {
    if (this.router.url && localStorage.getItem(this.router.url)) {
      this.filterModel = JSON.parse(localStorage.getItem(this.router.url)).filterModel;

    }
  }

  handleFilterUpdate(event) {
    this.hasFilterModelChanged = false;
    this.filterModel = event.filterModel;
    this.handleClearFilters();
  }

  handleClearFilters() {
    var data = { orderStatusID: [4] };
    if (this.filterModel) {

      var countries = this.filterModel.countries.map(country => country.itemID);
      if (countries && countries.length > 0)
        data['countryIDs'] = countries;

      if (this.filterModel.requestType.itemID > 0)
        data['requestTypeID'] = this.filterModel.requestType.itemID;

      var requestors = this.filterModel.requestors.map(requestor => requestor.itemDesc);
      if (requestors && requestors.length > 0)
        data['requestorNames'] = requestors;

      var tags = this.filterModel.tags.map(tag => tag.itemID);
      if (tags && tags.length > 0)
        data['tagIDs'] = tags;

      if (this.filterModel.shipByDateRange) {
        data['shipByStartDate'] = this.getFormattedDate(this.filterModel.shipByDateRange[0]);
        data['shipByEndDate'] = this.getFormattedDate(this.filterModel.shipByDateRange[1])
      }

      if (this.filterModel.updatedDateRange) {
        data['updatedStartDate'] = this.getFormattedDate(this.filterModel.updatedDateRange[0]);
        data['updatedEndDate'] = this.getFormattedDate(this.filterModel.updatedDateRange[1])
      }

      var regions = this.filterModel.regions.map(region => region.itemID);
      if (regions && regions.length > 0)
        data['regionIDs'] = regions;

      var regions = this.filterModel.coordinators.map(region => region.itemID);
      if (regions && regions.length > 0)
        data['coordinatorIDs'] = regions;

      var statuses = this.filterModel.orderStatuses.map(s => s.itemID);
      if (statuses && statuses.length > 0)
        data['status'] = statuses;

      if (this.filterModel.chemical) {
        data['chemicalsRequested'] = this.filterModel.chemical;
      }
      if (this.filterModel.requestNumber) {
        data['requestNumber'] = this.filterModel.requestNumber;
      }
      if (this.filterModel.lotNumber) {
        data['lotNumber'] = this.filterModel.lotNumber;
      }
    }

    this.disableClearFilters = !(Object.keys(data)?.length > 1);
  }

  searchButtonClicked(): void {
    this.appFilterOrderInstance.saveFilterDetails();
    this.filterOrders();
  }

  filterOrders() {
    this.hasFilterModelChanged = true;
    this.saveFilterDetails();

    var data = { orderStatuses: [4] };
    if (this.filterModel) {

      var countries = this.filterModel.countries.map(country => country.itemID);
      if (countries && countries.length > 0)
        data['countryIDs'] = countries;

      if (this.filterModel.requestType.itemID > 0)
        data['requestTypeID'] = this.filterModel.requestType.itemID;

      var requestors = this.filterModel.requestors.map(requestor => requestor.itemDesc);
      if (requestors && requestors.length > 0)
        data['requestorNames'] = requestors;

      var tags = this.filterModel.tags.map(tag => tag.itemID);
      if (tags && tags.length > 0)
        data['tagIDs'] = tags;

      if (this.filterModel.shipByDateRange) {
        data['shipByStartDate'] = this.getFormattedDate(this.filterModel.shipByDateRange[0]);
        data['shipByEndDate'] = this.getFormattedDate(this.filterModel.shipByDateRange[1])
      }

      if (this.filterModel.updatedDateRange) {
        data['updatedStartDate'] = this.getFormattedDate(this.filterModel.updatedDateRange[0]);
        data['updatedEndDate'] = this.getFormattedDate(this.filterModel.updatedDateRange[1])
      }

      var regions = this.filterModel.regions.map(region => region.itemID);
      if (regions && regions.length > 0)
        data['regionIDs'] = regions;

      var regions = this.filterModel.coordinators.map(region => region.itemID);
      if (regions && regions.length > 0)
        data['coordinatorIDs'] = regions;

      var statuses = this.filterModel.orderStatuses.map(s => s.itemID);
      if (statuses && statuses.length > 0)
        data['orderStatuses'] = statuses;

      if (this.filterModel.chemical) {
        data['chemicalsRequested'] = this.filterModel.chemical;
      }
      if (this.filterModel.requestNumber) {
        data['requestNumber'] = this.filterModel.requestNumber;
      }
      if (this.filterModel.lotNumber) {
        data['lotNumber'] = this.filterModel.lotNumber;
      }
    }

    this.loadingService.show("Loading ...");
    this.subscriptions.add(this.orderService
      .getOrdersByFilterCriteria(data)
      .pipe(finalize(() => this.loadingService.clearMessage()))
      .subscribe(data => {
        const sortedOrders = sortOrdersByNeedByDate(data);
        this.warehouseQueueOrders = sortedOrders;
        this.warehouseQueueOrdersLoaded = true;
      }
      ));
  }

  handleOrderMoved(order: Order) {
    this.confirmMoveToPick(order);
  }

  confirmMoveToPick(order: Order): void {
    // Need to get Order Details so that the PDF has access to the order items
    this.orderService.getOrderDetail(order.orderID).pipe(flatMap((o: Order) => this.pdfService.generatePdfPicklist(o))).subscribe();
    const dialogData =
      new ConfirmDialogModel("Confirm Picklist Printed",
        `Please confirm that the picklist has been printed for request ${order.requestNumber}. After confirming, this request will be moved to status Pick.`,
        "Confirm", false);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      disableClose: true,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.moveToPick(order);
      }
    });
  }

  moveToPick(order: Order): void {
    this.subscriptions.add(this.orderService
      .updateOrderStatus(order.orderID, 5).subscribe(
        data => {
          this.warehouseQueueOrders = removeOrderByID(this.warehouseQueueOrders, order.orderID);
          this.filterOrders();
          this.responseHandler.showSuccess(`Successfully moved request ${order.requestNumber} to Pick.`);
        }
      )
    );
  }

  coordinatorSelectionChanged(event) {
    this.filterOrders();
  }

  saveFilterDetails() {
    if (!this.router.url) return;
    localStorage.setItem(this.router.url, JSON.stringify({
      filterModel: this.appFilterOrderInstance?.filterModel,
      enabledFilters: this.appFilterOrderInstance?.enabledFilters
    }));
  }

  getFormattedDate(date) {
    var params = date.toLocaleDateString().split('/');
    return [`${params[2]}`, `0${params[0]}`.substr(-2), `0${params[1]}`.substr(-2)].join('-');
  }

  ngOnInit(): void {
    this.setFilterModel();
    setTimeout(() => {
      this.filterOrders();
    }, 500);

  }

  onClearFilters() {
    this.appFilterOrderInstance.clearAllFilters();
    this.hasFilterModelChanged = false;
    this.disableClearFilters = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

