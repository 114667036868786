<div class="p-grid p-mt-3 p-mb-4">
    <div class="p-col-12">
        <label for="tags">Show Filters: </label>
        <p-multiSelect [options]="filters" [(ngModel)]="enabledFilters" (onChange)="disableRemovedFilters($event.value)"
            optionLabel="label" selectedItemsLabel="{0} Filters Selected">
        </p-multiSelect>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Country')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="tags">Warehouse: </label>
            </div>
            <div class="p-col">
                <p-multiSelect placeholder="All Warehouses" defaultLabel="All Warehouses"
                    [options]="filterData?.warehouses" [(ngModel)]="filterModel.warehouses" [filter]="true" optionLabel="itemDesc"
                    (onChange)="emitFilterUpdate('Warehouse')" selectedItemsLabel="{0} Filters Selected">
                </p-multiSelect>
                <button mat-icon-button color="cor-default" [ngClass]="filterModel.warehouses?.length ? 'filterEnabled':''"
                    (click)="resetFilter('Warehouse')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Updated Date Range')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="updatedDateRange">Updated Date Range: </label>
            </div>
            <div class="p-col">
                <p-calendar placeholder="Any Date" (onClose)='onDateClose($event)'
                    [(ngModel)]="filterModel.updatedDateRange" selectionMode="range" inputId="updatedRange"
                    (onChange)="emitFilterUpdate('Updated Date Range')"
                    (onSelect)="handleDateRangeSelection('Updated Date Range')">
                    <p-header style="display: flex;justify-content: center;font-weight: 600;">Please select 2 dates
                    </p-header>
                </p-calendar>
                <button mat-icon-button color="cor-default"
                    [ngClass]="filterModel.updatedDateRange ? 'filterEnabled':''"
                    (click)="resetFilter('Updated Date Range')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Request Type')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="requestTypes">Request Type: </label>
            </div>
            <div class="p-col">
                <p-dropdown placeholder="All Request Types" [options]="filterData?.requestTypes"
                    [(ngModel)]="filterModel.requestType" [filter]="true" optionLabel="itemDesc"
                    (onChange)="emitFilterUpdate('Request Type')"></p-dropdown>
                <button mat-icon-button color="cor-default"
                    [ngClass]="filterModel.requestType?.itemID ? 'filterEnabled':''"
                    (click)="resetFilter('Request Type')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Ship By Date Range')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="shipByDateRange">Ship By Date Range: </label>
            </div>
            <div class="p-col">
                <p-calendar placeholder="Any Date" (onClose)='onDateClose($event)'
                    [(ngModel)]="filterModel.shipByDateRange" selectionMode="range" inputId="needByRange"
                    (onChange)="emitFilterUpdate('Ship By Date Range')"
                    (onSelect)="handleDateRangeSelection('Ship By Date Range')">
                    <p-header style="display: flex;justify-content: center;font-weight: 600;">Please select 2 dates
                    </p-header>
                </p-calendar>
                <button mat-icon-button color="cor-default" [ngClass]="filterModel.shipByDateRange ? 'filterEnabled':''"
                    (click)="resetFilter('Ship By Date Range')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Country')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="countries">Country: </label>
            </div>
            <div class="p-col">
                <p-multiSelect placeholder="All Countries" defaultLabel="All Countries"
                    [options]="filterData?.countries" [(ngModel)]="filterModel.countries" [filter]="true"
                    optionLabel="itemDesc" (onChange)="emitFilterUpdate('Country')"
                    selectedItemsLabel="{0} Filters Selected">
                </p-multiSelect>
                <button mat-icon-button color="cor-default"
                    [ngClass]="filterModel.countries?.length ? 'filterEnabled':''" (click)="resetFilter('Country')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Hold Reasons')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="requestor">Hold Reason: </label>
            </div>
            <div class="p-col">
                <p-multiSelect placeholder="All Hold Reasons" defaultLabel="All Hold Reasons"
                    [options]="filterData?.holdReasons" [(ngModel)]="filterModel.holdReasons" [filter]="true"
                    optionLabel="itemDesc" (onChange)="emitFilterUpdate('Hold Reasons')"
                    selectedItemsLabel="{0} Filters Selected">
                </p-multiSelect>
                <button mat-icon-button color="cor-default"
                    [ngClass]="filterModel.holdReasons.length ? 'filterEnabled':''"
                    (click)="resetFilter('Hold Reasons')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Request ID')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="requestNumber">Request ID: </label>
            </div>
            <div class="p-col">
                <input type="text" pInputText [(ngModel)]="filterModel.requestNumber" placeholder="Search Request ID"
                    (ngModelChange)="this.requestNumberFilterUpdate.next($event)">
                <button mat-icon-button color="cor-default" [ngClass]="filterModel.requestNumber ? 'filterEnabled':''"
                    (click)="resetFilter('Request Number')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Coordinator')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="requestor">Coordinator: </label>
            </div>
            <div class="p-col">
                <p-multiSelect placeholder="All Coordinators" defaultLabel="All Coordinators"
                    [options]="filterData?.coordinators" [(ngModel)]="filterModel.coordinators" [filter]="true"
                    optionLabel="itemDesc" (onChange)="emitFilterUpdate('Coordinator')"
                    selectedItemsLabel="{0} Filters Selected">
                </p-multiSelect>
                <button mat-icon-button color="cor-default"
                    [ngClass]="filterModel.coordinators?.length? 'filterEnabled':''"
                    (click)="resetFilter('Coordinator')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Region')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="requestor">Region: </label>
            </div>
            <div class="p-col">
                <p-multiSelect placeholder="All Regions" defaultLabel="All Regions" [options]="filterData?.regions"
                    [(ngModel)]="filterModel.regions" [filter]="true" optionLabel="itemDesc"
                    (onChange)="emitFilterUpdate('Region')" selectedItemsLabel="{0} Filters Selected">
                </p-multiSelect>
                <button mat-icon-button color="cor-default" [ngClass]="filterModel.regions?.length ? 'filterEnabled':''"
                    (click)="resetFilter('Region')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Status')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="Status">Status: </label>
            </div>
            <div class="p-col">
                <p-multiSelect placeholder="All Statuses" defaultLabel="All Statuses"
                    [options]="filterData?.orderStatuses" [(ngModel)]="filterModel.orderStatuses" [filter]="true"
                    optionLabel="itemDesc" (onChange)="emitFilterUpdate('Status')"
                    selectedItemsLabel="{0} Filters Selected">
                </p-multiSelect>
                <button mat-icon-button color="cor-default"
                    [ngClass]="filterModel.orderStatuses?.length ? 'filterEnabled':''" (click)="resetFilter('Status')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Lot Number')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="lotnumber">Lot Number: </label>
            </div>
            <div class="p-col">
                <input type="text" pInputText [(ngModel)]="filterModel.lotNumber" placeholder="Search Lot Number"
                    (ngModelChange)="this.lotNumberFilterUpdate.next($event)">
                <button mat-icon-button color="cor-default" [ngClass]="filterModel.lotNumber ? 'filterEnabled':''"
                    (click)="resetFilter('Lot Number')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Reserved')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="reserved">Reserved: </label>
            </div>
            <div class="p-col">
                <p-dropdown id="Reserved" placeholder="Select Reserved" [(ngModel)]="filterModel.reserved"
                    [options]="filterData?.reserved" (onChange)="emitFilterUpdate('Reserved')" optionLabel="itemDesc">
                </p-dropdown>
                <button mat-icon-button color="cor-default"
                    [ngClass]="filterModel.reserved.itemID > 0 ? 'filterEnabled':''" (click)="resetFilter('Reserved')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Material Requested')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="requestor">Material Requested: </label>
            </div>
            <div class="p-col">
                <input type="text" pInputText [(ngModel)]="filterModel.chemical" placeholder="Search Materials"
                    (ngModelChange)="this.chemicalFilterUpdate.next($event)">
                <button mat-icon-button color="cor-default" [ngClass]="filterModel.chemical ? 'filterEnabled':''"
                    (click)="resetFilter('Material Requested')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Tags')">
        <div class="p-grid">
            <div class="p-col-4"><label for="tags">Tags: </label></div>
            <div class="p-col">
                <p-multiSelect placeholder="Any Tags" defaultLabel="Any Tags" [options]="filterData?.tags"
                    [(ngModel)]="filterModel.tags" [filter]="true" optionLabel="itemDesc"
                    (onChange)="emitFilterUpdate('Tags')" selectedItemsLabel="{0} Filters Selected">
                </p-multiSelect>
                <button mat-icon-button color="cor-default" [ngClass]="filterModel.tags?.length ? 'filterEnabled':''"
                    (click)="resetFilter('Tags')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Requestor Name')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="requestor">Requestor Name: </label>
            </div>
            <div class="p-col">
                <p-multiSelect placeholder="All Requestors" defaultLabel="All Requestors"
                    [options]="filterData?.requestors" [(ngModel)]="filterModel.requestors" [filter]="true"
                    optionLabel="itemDesc" (onChange)="emitFilterUpdate('Requestor Name')"
                    selectedItemsLabel="{0} Filters Selected">
                </p-multiSelect>
                <button mat-icon-button color="cor-default"
                    [ngClass]="filterModel.requestors?.length ? 'filterEnabled':''"
                    (click)="resetFilter('Requestor Name')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [hidden]="!isFilterEnabled('Tracking Number')">
        <div class="p-grid">
            <div class="p-col-4">
                <label for="requestor">Tracking Number: </label>
            </div>
            <div class="p-col">
                <input type="text" pInputText [(ngModel)]="filterModel.trackingNumber" placeholder="Tracking Number"
                    (ngModelChange)="this.chemicalFilterUpdate.next($event)">
                <button mat-icon-button color="cor-default" [ngClass]="filterModel.trackingNumber ? 'filterEnabled':''"
                    (click)="resetFilter('Tracking Number')">
                    <mat-icon>backspace</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>