<h4>Manage GLP/Stock Barcode Printer Assignments</h4>
<p>A printer assignment combines a work station, a label design, and a printer to enable silent printing of GLP/stock barcode labels.</p>
<div class="p-grid p-mt-4">
    <div class="p-col-12">
        <div class="p-grid">
            <div class="p-col-6">
                <form [formGroup]="serachCriteriaForm">
                    <input formControlName="searchCriteria" type="text" pInputText placeholder="Search.." />
                    <small class="p-m-2" *ngIf="filteredResultsCount > 0">{{filteredResultsCount}} results found</small>
                </form>
            </div>
            <div class="p-col-6" style="display: flex; justify-content: end;">
                <button mat-raised-button color="cor-default" type="button" color="cor-primary"
                    style="margin-right: 20px;" (click)="createAssignment()">Add New Assignment</button>
            </div>
        </div>
    </div>
</div>

<ag-grid-angular #grid style="width:100%; height: calc(100vh - 415px); overflow-y: auto;" class="ag-theme-alpine"
    domLayout="autoHeight" [rowData]="printerAssignments" [columnDefs]="columnDefs"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate" [tooltipShowDelay]="1500" (gridReady)="onGridReady($event)"
    (gridSizeChanged)="resizeGrid($event)" [frameworkComponents]='frameworkComponents'>
</ag-grid-angular>