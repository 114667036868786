<div class="flex-grid">
  <div class="col">
    <h4>Scale Test Utility</h4>
  </div>
</div>

<app-warehouse-station (selectedStation)="onStationChange($event)"></app-warehouse-station>

<div *ngIf="station" style="margin-top: 40px;">
  <div *ngIf="scalesReady && selectedContainer">
    <div>
      <app-scale-control [decantStation]="station" [selectedContainer]="selectedContainer"
        [selectedInventoryItem]="selectedInventoryItem" [isReweighMode]="false" [unitOfMeasure]="'kg'"
        (weightCaptured)="handleWeightCaptured($event)" [isTestMode]="true">
      </app-scale-control>
    </div>
    <button class="large" mat-raised-button (click)="getStation()" color="cor-primary" style="margin-top: -30px;">Get
      Scale Weights</button>
  </div>
</div>
