import { MatDialog } from '@angular/material/dialog';
import { PrinterAssignmentsService } from '../../services/printer-assignments/printer-assignments.service';
import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { MessageService } from '../../shared/services/message-queue.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PrinterAssignment } from 'src/app/models/printer-assignments';
import { PrinterAssignmentsModalComponent } from '../printer-assignments-modal/printer-assignments-modal.component';
import { DeletePrinterAssignmentComponent } from './delete-printer-assignment/delete-printer-assignment.component';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-printerassignments',
  templateUrl: './printer-assignments.component.html',
  styleUrls: ['./printer-assignments.component.scss']
})
export class PrinterAssignmentsComponent implements OnInit {
  serachCriteriaForm: FormGroup;
  private gridApi;
  public columnDefs: any;
  public frameworkComponents: any;
  public gridOptions: GridOptions;
  public subscriptions: Subscription;
  public printerAssignments: PrinterAssignment[];
  public printerAssignmentsBackup: PrinterAssignment[];
  public filteredResultsCount: number;
  public overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">No records to display.</span>';

  constructor(
    private readonly printerAssignmentsService: PrinterAssignmentsService,
    private readonly messageService: MessageService,
    private dialog: MatDialog,
    private toastr: ResponseHandlingService,
    private loader: LoadingService) {
    this.subscriptions = new Subscription();
    this.frameworkComponents = {
      DeletePrinterAssignmentsRenderer: DeletePrinterAssignmentComponent,
    };

    this.columnDefs = [
      { minWidth : 100, headerName: 'Station', field: 'stationName', sortable: true },
      { minWidth : 100, headerName: 'Label Design Name', field: 'labelDesignName', sortable: true },
      { minWidth : 100, headerName: 'Printer Name', field: 'labelPrinterName', sortable: true },
      {
        minWidth : 100, cellRenderer: 'DeletePrinterAssignmentsRenderer', cellClass: ['button-edit', 'display-end'], cellRendererParams: { data: this },
      }
    ];
  }

  ngOnInit(): void {
    this.fillGrid();
    this.buildForm();
    this.messageService.message.subscribe({
      next: resp => {
        if (resp.data) {
          this.loader.show();
          this.printerAssignmentsService.deletePrinterAssignment(resp.reference.labelPrinterAssignmentID).subscribe(
            {
              next: () => {
                this.toastr.showSuccess('Printer Assignment is deleted.')
                this.fillGrid();
              },
              error: () => this.toastr.showError('An error occured. Try again later.'),
              complete: () => this.loader.clearMessage()
            }
          )
        }
      }
    })

  }

  private buildForm(): void {
    this.serachCriteriaForm = new FormGroup({
      searchCriteria: new FormControl(''),
    });

    this.serachCriteriaForm.controls["searchCriteria"].valueChanges.subscribe(value => {
      this.filteredResultsCount = 0;
      this.filterResults(value.toString());
    })
  }

  filterResults(value: string) {
    this.printerAssignments = this.printerAssignmentsBackup;
    if (value.length >= 2) {
      this.printerAssignments = this.printerAssignments.filter(x => x.stationName.toLowerCase().toString().includes(value.toLowerCase()) || x.labelPrinterName.toLowerCase().toString().includes(value.toLowerCase()));
      this.filteredResultsCount = this.printerAssignments.length;
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  public fillGrid() {
    this.printerAssignmentsService
      .getPrinterAssignments()
      .pipe(take(1))
      .subscribe((printerAssignmentsResponse) => {
        this.printerAssignments = printerAssignmentsResponse;
        this.printerAssignmentsBackup = printerAssignmentsResponse;
      });
  }

  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  createAssignment(): void {
    this.dialog.open(PrinterAssignmentsModalComponent, {
      height: '415px',
      width: '500px',
      data: {
        printerAssignments: {
          printerAssignments: this.printerAssignments,
        }
      },
      disableClose: true
    }).afterClosed().subscribe((res: boolean) => {
      if (!res) { return; }
      this.fillGrid();
    });
  }

}
