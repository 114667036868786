import { Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { switchMap, take, tap } from 'rxjs/operators'
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { TriggerService } from '../../core/services/trigger.services'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LockableComponent } from 'src/app/models/has-unsaved-data';
import { DestinationGuideOptions } from 'src/app/models/destination-guide-options';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { DestinationGuideService } from '../../shared/services/destination-guide-service';
import { DestinationGuide, SelectedShippers } from 'src/app/models/destination-guide';
import { ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { S } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-destination-guide-info',
  templateUrl: './destination-guide-info.component.html',
  styleUrls: ['./destination-guide-info.component.scss'],
})
export class DestinationGuideInfoComponent
  implements OnInit, OnDestroy, LockableComponent {
  allowRedirect: boolean;
  subscriptions: Subscription;
  public destinationGuideForm: FormGroup;
  selectedShippers: SelectedShippers[] = [];
  destinationGuideModel: DestinationGuideOptions;

  constructor(
    private messageService: MessageService,
    public dialog: MatDialog,
    private router: Router,
    private triggerService: TriggerService,
    private loader: LoadingService,
    private destinationGuideService: DestinationGuideService
  ) { }

  @HostListener('window:beforeunload', ['$event'])
  canLeavePage(event: any) {
    event.preventDefault();
    event.returnValue = false;
  }

  ngOnInit(): void {
    this.init();
  }

  async onSubmit(form: FormGroup): Promise<boolean> {
    if (form.valid) {
      this.loader.show();
      form.patchValue({
        shippers: this.selectedShippers.filter(sh => sh.isSelected).map((sh) => sh.shipperId),
      });
      await this.destinationGuideService
        .saveDestinationGuide(form.value)
        .toPromise()
        .then(() => {
          this.loader.clearMessage();
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'All the changes have been saved.',
          });
        });

      this.destinationGuideForm.markAsPristine();
      return true;
    } else {
      this.updateFormValidators();
      return false;
    }
  }

  canDeactivate(): boolean {
    return this.destinationGuideForm.pristine;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  resetForm() {
    var country = this.destinationGuideForm.get('countryID').value;
    this.destinationGuideForm.patchValue({
      countryID: country,
    });

    this.messageService.add({
      severity: 'info',
      summary: 'Info',
      detail: 'All the changes have been undone.',
    });
  }

  private init() {
    this.buildForm();
    this.subscriptions = new Subscription();

    this.subscriptions.add(
      this.triggerService.message$
        .pipe(
          switchMap(async (messageData) => {
            if (messageData.route?.trim().length > 0 && messageData.confirm) {
              this.onSubmit(this.destinationGuideForm).then((isSuccessed) => {
                if (isSuccessed) {
                  this.router.navigate([`/${messageData.route}`], {
                    state: { saved: 'true' },
                  });
                }
              });
            }
            return of(messageData);
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.destinationGuideForm
        .get('countryID')
        .valueChanges.pipe(
          tap(() => this.loader.show()),
          switchMap((countryId) =>
            this.destinationGuideService.getDestinationGuide(countryId)
          )
        )
        .subscribe((formData) => {
          this.enableControls();
          this.patchForm(formData);
          this.updateFormValidators();

          this.syncShippers(formData);
          this.loader.clearMessage();
        })
    );

    this.destinationGuideService
      .getDestinationGuideOptions()
      .pipe(
        tap(() => this.loader.show()),
        take(1)
      )
      .subscribe((options) => {
        this.destinationGuideModel = options;
        this.loader.clearMessage();
      });
  }

  onReorder() {
    this.destinationGuideForm.markAsDirty();
  }

  onSelectionChange() {
    this.destinationGuideForm.markAsDirty();
  }

  private syncShippers(formData: DestinationGuide) {
    var shippers: SelectedShippers[] = [];

    formData.shippers?.forEach((shipper, index) => {
      shippers.push({
        isSelected: true,
        shipperId: shipper,
        shipperName: formData.shipperNames[index],
      });
    });

    var intersecShippers = this.destinationGuideModel.shippers.filter(
      (sh) => !formData.shippers?.includes(sh.shipperID)
    );

    intersecShippers?.forEach((shipper, index) => {
      shippers.push({
        isSelected: false,
        shipperId: shipper.shipperID,
        shipperName: shipper.shipperName,
      });
    });

    this.selectedShippers = shippers;
  }

  buildForm() {
    this.destinationGuideForm = new FormGroup({
      countryID: new FormControl(null, Validators.required),
      languageOptionID: new FormControl(null, [
        Validators.min(1),
        Validators.required,
      ]),
      secondaryLanguageOptionID: new FormControl(null),
      requiredDocumentOptionID: new FormControl(null, [
        Validators.min(1),
        Validators.required,
      ]),
      countrySpecificLabeling: new FormControl(),
      broker: new FormControl(),
      alternateBroker: new FormControl(),
      importer: new FormControl(),
      contact: new FormControl(),
      shipmentApprovals: new FormControl(),
      notes: new FormControl(),
      greenLightOptionID: new FormControl(),
      incotermsOptionID: new FormControl(),
      labelInfoOptions: new FormControl(),
      sdsInfoOptions: new FormControl(),
      specialDocuments: new FormControl(),
      shippers: new FormControl(),
      exportTierOptionID: new FormControl(),
      regionName: new FormControl(),
      singleUsePlasticTaxOptionID: new FormControl(0)
    });

    this.disableControls();
  }

  enableControls() {
    this.destinationGuideForm.controls['languageOptionID'].enable();
    this.destinationGuideForm.controls['secondaryLanguageOptionID'].enable();
    this.destinationGuideForm.controls['requiredDocumentOptionID'].enable();
    this.destinationGuideForm.controls['countrySpecificLabeling'].enable();
    this.destinationGuideForm.controls['broker'].enable();
    this.destinationGuideForm.controls['alternateBroker'].enable();
    this.destinationGuideForm.controls['importer'].enable();
    this.destinationGuideForm.controls['contact'].enable();
    this.destinationGuideForm.controls['shipmentApprovals'].enable();
    this.destinationGuideForm.controls['notes'].enable();
    this.destinationGuideForm.controls['greenLightOptionID'].enable();
    this.destinationGuideForm.controls['labelInfoOptions'].enable();
    this.destinationGuideForm.controls['sdsInfoOptions'].enable();
    this.destinationGuideForm.controls['specialDocuments'].enable();
    this.destinationGuideForm.controls['exportTierOptionID'].enable();
    this.destinationGuideForm.controls['regionName'].enable();
    this.destinationGuideForm.controls['singleUsePlasticTaxOptionID'].enable();
  }

  disableControls() {
    this.destinationGuideForm.controls['languageOptionID'].disable();
    this.destinationGuideForm.controls['secondaryLanguageOptionID'].disable();
    this.destinationGuideForm.controls['requiredDocumentOptionID'].disable();
    this.destinationGuideForm.controls['countrySpecificLabeling'].disable();
    this.destinationGuideForm.controls['broker'].disable();
    this.destinationGuideForm.controls['alternateBroker'].disable();
    this.destinationGuideForm.controls['importer'].disable();
    this.destinationGuideForm.controls['contact'].disable();
    this.destinationGuideForm.controls['shipmentApprovals'].disable();
    this.destinationGuideForm.controls['notes'].disable();
    this.destinationGuideForm.controls['greenLightOptionID'].disable();
    this.destinationGuideForm.controls['labelInfoOptions'].disable();
    this.destinationGuideForm.controls['sdsInfoOptions'].disable();
    this.destinationGuideForm.controls['specialDocuments'].disable();
    this.destinationGuideForm.controls['exportTierOptionID'].disable();
    this.destinationGuideForm.controls['regionName'].disable();
    this.destinationGuideForm.controls['singleUsePlasticTaxOptionID'].disable();
  }

  private patchForm(formData: DestinationGuide) {
    this.destinationGuideForm.patchValue({
      languageOptionID: formData.languageOptionID,
      secondaryLanguageOptionID: formData.secondaryLanguageOptionID,
      requiredDocumentOptionID: formData.requiredDocumentOptionID,
      countrySpecificLabeling: formData.countrySpecificLabeling,
      broker: formData.broker,
      alternateBroker: formData.alternateBroker,
      importer: formData.importer,
      contact: formData.contact,
      shipmentApprovals: formData.shipmentApprovals,
      notes: formData.notes,
      greenLightOptionID: formData.greenLightOptionID,
      labelInfoOptions: formData.labelInfoOptions,
      incotermsOptionID: formData.incotermsOptionID ?? this.destinationGuideModel.incoterms.find(dg => dg.isDefault)?.optionID,
      sdsInfoOptions: formData.sdsInfoOptions,
      specialDocuments: formData.specialDocuments,
      exportTierOptionID: formData.exportTierOptionID ?? 1,
      regionName: formData.regionName,
      singleUsePlasticTaxOptionID: formData.singleUsePlasticTaxOptionID ?? this.destinationGuideModel.singleUsePlasticTaxOptions.find(dg => dg.isDefault)?.optionID
    });
  }

  updateFormValidators() {
    this.destinationGuideForm.markAsPristine();
    this.destinationGuideForm.markAllAsTouched();
    this.destinationGuideForm.updateValueAndValidity({
      onlySelf: false,
      emitEvent: false,
    });
  }
}
