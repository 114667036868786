import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { Configuration } from '../../configuration';
import { InventoryItem, NItemSearch, RetrieveCatalogItemsResponse, RetrieveCatalogItemWithConversionResponse } from '../../models/inventory-models';
import { DisposalReason } from '../../models/disposal-reason-model';
import { LocationModel } from 'src/app/receiving/models/location.model';

@Injectable()
export class InventoryService {
    readonly rootURL = Configuration.REST_API_URL;

    constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
    }

    getInventoryDetails(inventoryCatalogID: number) {
        var URL = this.rootURL + `/Inventory/Catalog/${inventoryCatalogID}`;
        return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getInventoryCatalogItems(criteria: string) {
        var URL = this.rootURL + `/Inventory/CatalogSearch/${criteria}`;
        return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getInventoryDetailsWithLotNumber(inventoryCatalogID: number, lotNumber: string) {
        var URL = this.rootURL + `/Inventory/Catalog/${inventoryCatalogID}/Lots/${lotNumber}`;
        return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getInventoryItem(inventoryItemID: number): Observable<InventoryItem> {
        var URL = this.rootURL + `/Inventory/Items/${inventoryItemID}`;
        return this.http.get<InventoryItem>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    disposeInventoryItems(payload: any[]): Observable<InventoryItem[]> {
        let requestBody = { requests: payload }
        var URL = this.rootURL + `/Inventory/Items/Dispose`;
        return this.http.post<InventoryItem[]>(URL, requestBody).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    updateInventoryItem(itemID: number, amount: number) {
        var URL = this.rootURL + `/Inventory/Items/${itemID}`;
        const body = JSON.stringify({ 'amount': amount });
        return this.http.put<any>(URL, body).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    updateDensity(catalogID: number, density: number) {
        console.log(catalogID, density);
        var URL = this.rootURL + `/Inventory/Catalog/${catalogID}`;
        const body = JSON.stringify({ 'density': density });
        return this.http.put<any>(URL, body).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    updateInventoryItemLocations(itemID: number, locationId: number, printerId: string) {
        var URL = this.rootURL + `/Inventory/Items/${itemID}/Location/${locationId}/${printerId}`;
        return this.http.put<any>(URL, {});
    }

    updateInventoryItemForReweigh(itemID: number, amount: number) {
        var URL = this.rootURL + `/Inventory/Items/${itemID}/Reweigh`;
        const body = JSON.stringify({ 'amount': amount });
        return this.http.put<any>(URL, body).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getDisposalReasons(): Observable<DisposalReason[]> {
        var URL = this.rootURL + `​/Inventory/DisposalReasons`;
        return this.http.get<DisposalReason[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getCatalogItemWithConversion(inventoryCatalogID: number, requestedUOM: string): Observable<RetrieveCatalogItemWithConversionResponse> {
        var URL = this.rootURL + '/Inventory/Catalog/' + inventoryCatalogID + '/' + requestedUOM;
        return this.http.get<RetrieveCatalogItemWithConversionResponse>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getCatalogItemByWarehouse(warehouseID: any, catalogID: number, requestedUOM: string): Observable<RetrieveCatalogItemWithConversionResponse> {
        var URL = this.rootURL + '/Inventory/CatalogByWarehouse/' + warehouseID + '/' + catalogID + '/' + requestedUOM;
        return this.http.get<RetrieveCatalogItemWithConversionResponse>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getCatalogItems(inventoryCatalogID: number, requestedUOM: string): Observable<RetrieveCatalogItemsResponse[]> {
        var URL = this.rootURL + '/Inventory/Catalog/' + inventoryCatalogID + '/Items/' + requestedUOM;
        return this.http.get<RetrieveCatalogItemsResponse[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getCatalogItemsByWarehouse(warehouseID: any, catalogID: number, requestedUOM: string): Observable<RetrieveCatalogItemsResponse[]> {
        var URL = this.rootURL + '/Inventory/ItemsByWarehouse/' + warehouseID + '/' + catalogID + '/' + requestedUOM;
        return this.http.get<RetrieveCatalogItemsResponse[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getItemSearch(searchCriteria: string): Observable<NItemSearch[]> {
        var URL = this.rootURL + '/Inventory/ItemSearch/' + searchCriteria;
        return this.http.get<NItemSearch[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getLocations(): Observable<LocationModel[]> {
        var URL = this.rootURL + '/Inventory/Locations';
        return this.http.get<LocationModel[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    printLocationBarcodeLabels(locationIDs: number[], stationID: string): Observable<any> {
        let requestBody = { locationIDs, stationID };
        var URL = this.rootURL + '/Inventory/PrintLocationBarcodeLabels';
        return this.http.post<InventoryItem>(URL, requestBody).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getDisposalLogs(inventoryCatalogID: number) {
        var URL = this.rootURL + `/Inventory/Catalog/${inventoryCatalogID}/DisposalLogs`;
        return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }
}