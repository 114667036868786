<div class="flex-grid">
    <div class="col">
        <h4>Manage Shared Documents</h4>
    </div>
</div>
<div class="flex-grid p-pt-4" style="max-width:1000px;justify-content: space-between;">
    <p-autoComplete #autocomplete placeholder="Enter material name" [(ngModel)]="option" [suggestions]="options"
        (onSelect)="onSelect($event)" (onFocus)='onFocus()' [field]='selectedOptionDesc' showEmptyMessage="true"
        [emptyMessage]="'no matches'" (completeMethod)="changed($event)" [forceSelection]="true">
        <ng-template let-option pTemplate="item">
            <div>{{option.itemDesc}}</div>
        </ng-template>
    </p-autoComplete>
    <label>{{option?.itemDesc}}</label>
    <button mat-raised-button color="cor-primary" id="upload-btn" *ngIf="canUpload" (click)="onUpload()">
        Upload
    </button>
</div>
<div class="flex-grid p-pt-4">
    <div class="col">
        <ag-grid-angular #grid style="width:100%;max-width: 1000px;margin-bottom: 20px" domLayout="autoHeight"
            class="ag-theme-alpine" tooltipShowDelay="0" suppressCellSelection="true" suppressRowClickSelection="true"
            suppressHorizontalScroll="true" [rowData]="rowData" [columnDefs]="columnDefs" [gridOptions]='gridOptions'
            (gridReady)="gridReady($event)" [frameworkComponents]="frameworkComponents"
            (gridSizeChanged)="resizeGrid($event)">
        </ag-grid-angular>
    </div>
</div>