import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { SampleUseModel } from '../../models/sampleuse.model';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Configuration } from '../../configuration';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';

@Injectable({
  providedIn: 'root',
})
export class SampleUsesService {
  constructor(
    private http: HttpClient,
    private responseHandler: ResponseHandlingService
  ) {}

  getSampleUses(): Observable<SampleUseModel[]> {
    var URL = Configuration.REST_API_URL + `/SampleUse`;
    return this.http.get<SampleUseModel[]>(URL).pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  editSamplesUse(sampleUse: SampleUseModel): Observable<SampleUseModel> {
    var URL = `${Configuration.REST_API_URL}/SampleUse`;
    return this.http.post<SampleUseModel>(URL,sampleUse).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to save sample."]]), false)));
  }

  deleteSampleUSe(sampleUseId: number): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/SampleUse/${sampleUseId}`;
    return this.http.delete<any>(URL).pipe(
      catchError(err => {
        this.responseHandler.showMessage("Unable to delete sample.",'error', "Error",3000, false);
        return of(null);
      })
    );
  }
}
