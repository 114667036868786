<div class="container">
    <h5>Upload Document</h5>
    <hr />
    <span class="message" style="margin-top: 20px;">{{data?.materialName}}</span>
    <div class="add-icon" style="margin-top: 20px;"><i (click)="fileUpload.click()" style="cursor: pointer;"
            class="pi pi-plus-circle p-mr-2"></i><span (click)="fileUpload.click()" style="cursor: pointer;">Add
            File</span></div>
    <input hidden #fileUpload type="file"
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        (change)="onFileUpload($event)">
    <p style="margin-top: 10px;">{{uploadedFile?.name}}</p>
    <div class="content" style="margin-top: 30px;">
        <table class="p-mt-2">
            <tr>
                <td><label for="type" class="p-pr-3">Type</label></td>
                <td>
                    <p-dropdown id="type" placeholder="Select Type" [(ngModel)]="selectedType" [options]="documentTypes"
                        optionLabel="name" scrollHeight="100px" panelStyleClass='attachment-modal-minWidthOverride'
                        [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
            </tr>
            <tr class="p-mt-2">
                <td style="padding-top: 10px;"><label for="language" class="p-pr-3">Language</label></td>
                <td style="padding-top: 10px;">
                    <p-dropdown id="language" scrollHeight="100px" placeholder="Select Language" [(ngModel)]="selectedLanguage"
                        [options]="languages" optionLabel="name" panelStyleClass='attachment-modal-minWidthOverride'
                        [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
            </tr>
        </table>
    </div>
    <div class="actions" style="margin-top: 50px;">
        <button mat-raised-button class="p-mr-4" color="cor-default" (click)="cancel()">Cancel</button>
        <button mat-raised-button color="cor-primary"
            [disabled]="!(uploadedFile && selectedLanguage && selectedType)"
            (click)="save()">Save</button>
    </div>
</div>