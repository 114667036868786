import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { DecantStation } from 'src/app/models/decant-models';
import { InventoryItem } from 'src/app/models/inventory-models';
import { OrderItemContainerModel } from 'src/app/models/order-item-container-model';
import { Order } from 'src/app/models/order-models';
import { DecantService } from 'src/app/services/decant-service/decant-service';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { DisposeContainerConfirmModal, DisposeContainerConfirmModalComponent, IDisposeContainerConfirmModalResult } from '../dispose-container-confirm-modal/dispose-container-confirm-modal.component';
import { ScaleControlComponent } from '../scale-control/scale-control.component';

@Component({
  selector: 'app-decanting-capture',
  templateUrl: './decanting-capture.component.html',
  styleUrls: ['./decanting-capture.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class DecantingCaptureComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild('scaleControl') scaleControlInstance: ScaleControlComponent;
  @Input() order: Order;
  @Input() selectedInventoryItem: InventoryItem;
  @Input() selectedStation: DecantStation;
  @Input() public selectedContainer: OrderItemContainerModel;
  @Input() containerId: number;
  @Input() public unitOfMeasure: string;
  @Output() captureComplete: EventEmitter<any> = new EventEmitter();
  @Output() getStationDetails: EventEmitter<any> = new EventEmitter();

  private subscriptions = new Subscription();

  public columnDefs: any;
  public rowData: any;
  public scaleInfo: DecantStation;

  private gridApi;
  public scalesReady: boolean;
  public weightCaptured: boolean;
  public decantStation: DecantStation;

  constructor(private inventoryService: InventoryService, private responseHandler: ResponseHandlingService,
    private dialog: MatDialog, private decantService: DecantService, private weightFormatKGPipe: WeightFormatKGPipe) {
    this.columnDefs = [
      { headerName: 'Lot Number', field: 'lotNumber' },
      { headerName: 'Chemical', field: 'catalogDescription' },
      { headerName: 'Container', field: 'noDecantInventoryItemID' },
      { headerName: 'Previous Inventory Amount', field: 'remainingAmount' },
      { headerName: 'Previous Total Weight', field: 'prevTotalWeight' }
    ];
  }

  ngOnInit() {
    this.rowData = [];
    this.createRowData();
  }

  ngAfterContentInit() {
    this.scalesReady = true;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      setTimeout(() => this.subscriptions.unsubscribe(), 2000);
    }
  }

  getStation() {
    this.getStationDetails.emit();
  }

  private formatQuantity(quantity: number): string {
    return `${this.weightFormatKGPipe.transform(this.formatAmount(quantity))} ${quantity < 1 ? 'g' : 'kg'}`
  }

  formatAmount(amount: number): number {
    if (amount < 1) {
      return amount * 1000;
    }
    return amount
  }

  createRowData() {
    const { lotNumber, catalogDescription, noDecantInventoryItemID, remainingAmount, tareWeightKG } = this.selectedInventoryItem;
    this.rowData.push({ lotNumber, catalogDescription, noDecantInventoryItemID, remainingAmount: this.formatQuantity(remainingAmount), prevTotalWeight: this.formatQuantity(remainingAmount + (tareWeightKG != null ? tareWeightKG : 0)) });
  }

  onComplete() {
    this.captureComplete.emit();
  }

  handleWeightCaptured(event) {
    this.weightCaptured = true;
  }

  onDisposeContainer() {
    const dialogData = new DisposeContainerConfirmModal(
      "Dispose Container",
      "The Container will be permanently removed from inventory.",
      "Confirm", true, true);

    const dialogRef = this.dialog.open(DisposeContainerConfirmModalComponent, {
      width: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult: IDisposeContainerConfirmModalResult) => {
      if (dialogResult.isConfirm) {
        this.subscriptions.add(this.inventoryService
          .disposeInventoryItems([{
            "inventoryItemID": this.selectedInventoryItem.itemID,
            "disposalReasonID": dialogResult.disposalReason,
            "comment": dialogResult.comments
          }]).subscribe(
            data => {
              this.responseHandler.showSuccess(`Successfully disposed container`);
              setTimeout(() => {
                this.captureComplete.emit();
              }, 2000);
            }, () => {
              this.responseHandler.showError("An error occurred while disposing the container");
            }
          ));
      }
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;

    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

}
