<div class="container" (blur)="onSave()">
    <div class="inline-control">
        <textarea rows="8" #textArea [autofocus]='!isEditing' [(ngModel)]='Comments' [readonly]="!isEditing"
            (change)="onChange($event)" [disabled]='disableTextEdit'></textarea>
        <div class="flex-end">
            <button class="edit" *ngIf='!isEditing && !readOnly && !disableTextEdit' (click)='onEdit()'>Edit </button>
        </div>
    </div>
    <div class="inline-action" *ngIf='isEditing'>
        <button mat-raised-button style="margin-right: 2px;" [disabled]="disableSave" color="cor-primary"
            (mousedown)='onSave()'>Save</button>
        <button mat-raised-button color="cor-default" (mousedown)='onCancel()'>Cancel</button>
    </div>
</div>