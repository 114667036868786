<!-- <input  type="number" [disabled]="ismoveAll"
    [(ngModel)]="value" step="1" style="min-width:5em !important;width: 100px;" (input)="onKeyDown($event)" pInputText /> -->

    <mat-form-field>
        <button 
          mat-button 
          mat-icon-button 
          matPrefix 
          class="incbtncolor"
          aria-label="Remove" 
          [color]="getColor()" 
          (click)="incrementValue(-_step)" 
          [disabled]="shouldDisableDecrement(_value)">
          <mat-icon>remove</mat-icon>
        </button>
        <input style="text-align: center;" matInput  #input  type="number" [(ngModel)]="_value" (focusout)="focusout($event)"  (keyup)="onKeyUp($event)" (focus)="setColor('primary')" (blur)="setColor('default')" />
        <button 
          mat-button 
          mat-icon-button 
          matSuffix
          class="incbtncolor" 
          aria-label="Add" 
          [color]="getColor()" 
          (click)="incrementValue(_step)" 
          [disabled]="shouldDisableIncrement(_value)">
          <mat-icon>add</mat-icon>
        </button>
      </mat-form-field>