<h5 class="p-mb-3">Unexpected Server Error</h5>

<mat-dialog-content>
    <form [formGroup]="errorRequestForm">
        <div class="p-grid">
            <div class="p-col-12 p-field">
                <div class="p-fluid">
                    <label>An unexpected server error was detected and we apologize for the issue.
                        To help the support team diagnose the issue, please provide a brief explanation of what you were doing at the time
                        the error happened. </label>
                    <textarea [rows]="7" [cols]="40" pInputTextarea autoResize="autoResize"
                        formControlName="comments" id="notes" style="margin-top: 10px;"></textarea>
                </div>
                <div class="p-error" *ngIf="fc.comments.errors">
                    <small *ngIf="fc.comments.errors?.required">
                        Field is required
                    </small>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions style="float: right; min-height: 80px;">
    <button mat-raised-button color="cor-default" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="cor-primary" (click)="submitToSupport()"
        [disabled]="errorRequestForm.invalid || fc.comments.value.length === 0">Submit to Support</button>
</mat-dialog-actions>