<div class="flex-grid">
    <div class="col">
        <h4>Edit Material Density</h4>
        <p>This tool is used to edit a material's density stored in Vertere, which is used for liquid-to-solid conversions.</p>
    </div>
</div>

<div class="container">
    <form [formGroup]="searchCriteriaForm" (ngSubmit)="onSubmit()">
        <input formControlName="searchCriteria" type="text" pInputText placeholder="Search for material name or lot number" />
        <button class="submit p-mr-2" mat-raised-button color="cor-primary" type="submit">Search</button>
    </form>
</div>
<app-ag-grid-custom-paginator (paginatorCommand)="handlePaginatorCommand($event)" [paginatorModel]="paginatorModel" [showPageSizes]="true"></app-ag-grid-custom-paginator>

<ag-grid-angular style="width:100%; height: calc(100vh - 532px);" class="ag-theme-alpine" rowModelType="infinite" domLayout="normal"
    tooltipShowDelay="0" suppressCellSelection="true" alwaysShowVerticalScroll="true" [pagination]="true"
    [suppressPaginationPanel]="true" [paginationPageSize]="25" [cacheOverflowSize]="2"
    [maxConcurrentDatasourceRequests]="2" [infiniteInitialRowCount]="1" [columnDefs]="columnDefs" [cacheBlockSize]="100"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [frameworkComponents]="frameworkComponents" (cellValueChanged)="setDataSource($event)"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="onResizeGrid($event)"
    (sortChanged)="updateCurrentPage()">
</ag-grid-angular>

<app-ag-grid-custom-paginator (paginatorCommand)="handlePaginatorCommand($event)" [paginatorModel]="paginatorModel" [showPageSizes]="false"></app-ag-grid-custom-paginator>
