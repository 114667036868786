import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { OrderHistory } from 'src/app/models/order-history.model';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-view-history-modal',
  templateUrl: './view-history-modal.component.html',
  styleUrls: ['./view-history-modal.component.scss']
})
export class ViewHistoryModalComponent implements OnInit {

  title: string;
  orderHistoryData: OrderHistory[];

  constructor(public dialogRef: MatDialogRef<ViewHistoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderHistoryModalModel) {
    this.title = data.title;
    this.orderHistoryData = data.data;
  }

  ngOnInit(): void {
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  formatDate(timestamp) {
    return formatDate(new Date(timestamp), 'd-MMM-yyyy h:mm a', 'en-US') + ' ET'
  }

}


export class OrderHistoryModalModel {
  constructor(public title: string, public data: OrderHistory[]) {
  }
}
