import { ContainerWeightValidator } from './container-weight.validator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContainerWeights } from './../../models/container-weights';
import { Component, Inject, OnInit } from '@angular/core';
import { ContainerWeightsService } from '../../services/container-weights/container-weights.service';
import { ResponseHandlingService } from '../../services/response-handling-service/response-handling-service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-container-weights-modal',
  templateUrl: './container-weights-modal.component.html',
  styleUrls: ['./container-weights-modal.component.scss']
})

export class ContainerWeightsModalComponent implements OnInit {
  containerWeightData: any;
  containerWeightsForm: FormGroup;
  isFormSubmitted = false;
  isFromInAddMode = true;

  constructor(private dialogRef: MatDialogRef<ContainerWeightsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly containerWeightService: ContainerWeightsService,
    private toastr: ResponseHandlingService,
    private containerWeightValidator: ContainerWeightValidator) {
      this.containerWeightData = this.data;
  }

  get fc() {
    return this.containerWeightsForm.controls;
  }

  get fv() {
    return this.containerWeightsForm.value;
  }

  onCancel() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.initContainerWeightForm();
    if(this.containerWeightData && this.containerWeightData.containerWeightID > 0){
      this.isFromInAddMode = false;
      this.populateForm();
    }
  }

  populateForm(){
    this.containerWeightsForm.setValue({containerName: this.containerWeightData.name, containerWeight: this.containerWeightData.weight, plasticWeight: this.containerWeightData.plasticWeight});
  }

  initContainerWeightForm() {
    this.containerWeightsForm = new FormGroup({
      containerName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      containerWeight: new FormControl('', [Validators.maxLength(11), Validators.pattern("(\\d*)(\\.\\d{0,7})?$")]),
      plasticWeight: new FormControl('', [Validators.required, Validators.maxLength(11), Validators.pattern("(\\d*)(\\.\\d{0,7})?$")])
    },
    {
      validators: [this.containerWeightValidator.ValidateContainerWeight()],
      updateOn: 'blur',
    })
  };

  onSubmit() {
    this.isFormSubmitted = true;
    if (this.containerWeightsForm.valid) {
      const containerweight: ContainerWeights = {
        name: this.fv.containerName,
        weight: this.fv.containerWeight,
        plasticWeight: this.fv.plasticWeight,
        containerWeightID: 0
      }
      if(this.isFromInAddMode){
        this.containerWeightService.addContainerWeight(containerweight)
        .subscribe(data => {
          this.toastr.showSuccess("Container has been successfully added.");
          this.dialogRef.close(true);
        },
          error => {
            this.toastr.showError("Failed to add Container. " + error);
            this.dialogRef.close(false);
        });
      }
      else{
        containerweight.containerWeightID = this.containerWeightData.containerWeightID;
        this.containerWeightService.updateContainerWeight(containerweight)
        .subscribe(data => {
          this.toastr.showSuccess("Container has been successfully updated.");
          this.dialogRef.close(true);
        },
          error => {
            this.toastr.showError("Failed to update Container. " + error);
            this.dialogRef.close(false);
        });
      }
    }
  }
}
