import { CancelOrder, CancelReason, HoldReason, OnHoldReason, Request } from './../../models/order-models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OrderFilterData } from '../../models/filter-models';
import { OrderListRequest, Order, OrderTransferRequest, OrderItemEarmarksByCatalogIDResponse, OrderHoldRequest, UpdateOrderHoldNotesRequest, OrderContentsResponse, OrderPackage, PackageAssignmentRequest, OrderPackageRequest, UpdateRequestedContainerLabelRequest } from '../../models/order-models';
import { UpdateContainerRequest } from '../../models/update-container-model';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { Configuration } from 'src/app/configuration';
import { OrderAttachment } from 'src/app/models/attachment-models';
import { OrderHistory } from 'src/app/models/order-history.model';
import { OrderItemContainerAssignmentResponse, OrderItemContainerAssignRequest, OrderItemContainerChangeRequest } from 'src/app/models/order-item-container-model';
import { DestinationGuide } from '../../models/destination-guide'
import { ShipperRankingModel } from 'src/app/models/shipper-ranks';
import { ContainerWeights } from 'src/app/models/container-weights';

@Injectable()
export class OrderService {
  private _cancelReasons: BehaviorSubject<CancelReason[]> = new BehaviorSubject<
    CancelReason[]
  >([]);
  public cancelReasons$ = this._cancelReasons.asObservable();
  readonly rootURL = Configuration.REST_API_URL;

  constructor(
    private http: HttpClient,
    private responseHandler: ResponseHandlingService
  ) { }

  saveContainerWeights(orderID: any, containerWeights: ContainerWeights[]) {
    var URL = Configuration.REST_API_URL + `/ContainerWeights/ByOrder/${orderID}`;
    return this.http.post<ContainerWeights>(URL, containerWeights).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  retrieveList(
    orderListRequest: OrderListRequest
  ): Observable<Order[]> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(orderListRequest);
    var URL = this.rootURL + '/Orders/List';
    return this.http
      .post<Order[]>(URL, body, { headers: headers })
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  searchRequests(requestNumber: any): Observable<Request[]> {
    const headers = { 'content-type': 'application/json' };
    var URL =
      this.rootURL + '/Orders/SearchRequests?requestNumber=' + requestNumber;
    return this.http
      .get<Request[]>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getOrderDetail(orderID: string): Observable<Order> {
    var URL = this.rootURL + `/Orders/${orderID}`;
    return this.http
      .get<Order>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getOnHoldReasons(): Observable<OnHoldReason[]> {
    var URL = this.rootURL + '/HoldReason';
    return this.http
      .get<OnHoldReason[]>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getHoldReasonList(): Observable<HoldReason[]> {
    var URL = this.rootURL + '/HoldReason';
    return this.http
      .get<HoldReason[]>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  deleteHoldReason(reasonID: number): Observable<any> {
    const url = this.rootURL + `/HoldReason/${reasonID}`;
    return this.http.delete(url);
  }

  addOrEditHoldReason(payload): Observable<any> {
    const url = this.rootURL + '/HoldReason';
    return this.http.post(url, payload);
  }

  getOrderDetailWithOrderContainers(
    orderID: string,
    includeOrderContainers: boolean
  ): Observable<Order> {
    var URL =
      this.rootURL +
      `/Orders/${orderID}/OrderContainers/${includeOrderContainers}`;
    return this.http
      .get<Order>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getOrderByRequestNumber(requestNumber: string): Observable<Order> {
    var URL = this.rootURL + `/Orders/RequestNumber/${requestNumber}`;
    return this.http
      .get<Order>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getTags(orderID: string): Observable<any> {
    var URL = this.rootURL + `/Tags`;
    return this.http
      .get<any>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getOrderTagCarrierDetails(orderID: string): Observable<any> {
    return forkJoin([
      this.getOrderDetail(orderID),
      this.getTags(orderID),
      this.getShippers(),
    ]).pipe(
      map(
        (result) => {
          return {
            orderDetails: result[0],
            tagDetails: result[1],
            carriers: result[2],
          };
        },
        catchError((err) => this.responseHandler.handleError(err))
      )
    );
  }

  assignOrder(orderID: string, userID: number): Observable<any> {

    const body = JSON.stringify({
      assignOrderFlag: true,
      assignOrderToUserID: userID,
    });

    var URL = this.rootURL + `/Orders/${orderID}`;
    return this.http
      .patch<any>(URL, body)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  changeOrderStatus(orderId: string, statusId: number, resonToChange: string, overrideStatusRules: boolean): Observable<any> {

    const body = JSON.stringify({
      setOrderStatusFlag: true,
      orderStatusID: statusId,
      orderStatusComment: resonToChange,
      overrideStatusRules: overrideStatusRules
    });

    console.log(body);

    var URL = this.rootURL + `/Orders/${orderId}`;
    return this.http
      .patch<any>(URL, body)
      .pipe(catchError((err) => this.responseHandler.showAPIError(err?.message)));
  }

  saveTags(orderID: string, tags: any): Observable<any> {

    const body = JSON.stringify({
      setOrderTagsFlag: true,
      tags: tags.tags,
    });

    var URL = this.rootURL + `/Orders/${orderID}`;
    return this.http
      .patch<any>(URL, body)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getOrderFilters(): Observable<OrderFilterData> {
    var URL = this.rootURL + `/Orders/Filters`;
    return this.http
      .get<any>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getOrdersByFilterCriteria(filters) {
    var URL = this.rootURL + `/Orders/List`;
    return this.http
      .post<any>(URL, filters)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  updateOrderStatus(orderID: string, orderStatusID: number) {

    const body = JSON.stringify({
      setOrderStatusFlag: true,
      orderStatusID: orderStatusID,
    });

    var URL = this.rootURL + `/Orders/${orderID}`;
    return this.http
      .patch<any>(URL, body)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  updateWareHouseStation(orderID: string, warehouseStationID: number, reasonToChange: string) {

    const body = JSON.stringify({
      setWarehouse: true,
      newWarehouseID: warehouseStationID,
      warehouseChangeReason: reasonToChange,
    });

    var URL = this.rootURL + `/Orders/${orderID}`;
    return this.http
      .patch<any>(URL, body)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  requestorEmailSuppress(
    orderID: string,
    setRequestorEmailFlag: boolean,
    suppressRequestorEmail: boolean
  ): Observable<any> {
    const body = JSON.stringify({
      setRequestorEmailFlag: setRequestorEmailFlag,
      suppressRequestorEmail: suppressRequestorEmail,
    });

    var URL = this.rootURL + `/Orders/${orderID}`;
    return this.http.patch<any>(URL, body);
  }

  getDestinationGuide(countryId: number): Observable<DestinationGuide> {
    var URL = this.rootURL + `/DestinationGuide/${countryId}`;
    return this.http.get<any>(URL).pipe(
      switchMap((data) => {
        let shipperRankings: ShipperRankingModel[] = new Array(data?.shippers?.length);
        data.shippers?.forEach((rank, index) => {
          shipperRankings.push({ rankId: index + 1, shipperName: data?.shipperNames?.[index] });
        });

        data.shipperRankings = shipperRankings.filter(value => JSON.stringify(value) !== '{}');
        return of(data);
      }),
      catchError((err) => this.responseHandler.handleError(err))
    );
  }

  assignInventory(itemID, body) {
    var URL = this.rootURL + `/Orders/OrderItems/${itemID}/AssignInventory`;
    return this.http
      .put<any>(URL, body)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  updateContainer(containerID: string, request: UpdateContainerRequest) {
    var URL =
      this.rootURL + `/Orders/OrderItemContainers/${containerID}/Actuals`;
    const body = JSON.stringify(request);
    return this.http.put<any>(URL, body);
    //.pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  printLabel(containerID: string, stationID?: string) {
    var URL =
      this.rootURL + `/Orders/OrderItemContainers/${containerID}/PrintLabel`;
    if (stationID) {
      URL += `/${stationID}`;
    }
    return this.http
      .post<any>(URL, null)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  printSmallVialLabel(containerID: string, stationID?: string) {
    var URL =
      this.rootURL +
      `/Orders/OrderItemContainers/${containerID}/PrintSmallVialLabel`;
    if (stationID) {
      URL += `/${stationID}`;
    }
    return this.http
      .post<any>(URL, null)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  printSmallBarcodeLabel(containerID: string, stationID?: string) {
    var URL = this.rootURL +
      `/Orders/OrderItemContainers/${containerID}/PrintSmallBarcodeLabel`;
    if (stationID) {
      URL += `/${stationID}`;
    }
    return this.http
      .post<any>(URL, null)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  printGLPLabel(containerID: string, stationID?: string) {
    var URL =
      this.rootURL + `/Orders/OrderItemContainers/${containerID}/PrintGLPLabel`;
    if (stationID) {
      URL += `/${stationID}`;
    }
    return this.http
      .post<any>(URL, null)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  printGLPFlagLabel(itemID) {
    var URL = this.rootURL + `/Orders/OrderItems/${itemID}/PrintGLPFlagLabel`;
    return this.http
      .post<any>(URL, null)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  printGLPFlagLabels(order: Order, stationID?: string) {
    var URL = this.rootURL + `/Orders/${order.orderID}/PrintGLPFlagLabels`;
    if (stationID) {
      URL += `/${stationID}`;
    }
    return this.http
      .post<any>(URL, null)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  printShippingLabelWithStation(
    orderPackageID: string,
    stationID: string
  ): Observable<null> {
    var URL =
      this.rootURL +
      `/Orders/PrintShippingLabel/${orderPackageID}/${stationID}`;
    return this.http
      .post<null>(URL, null)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  updateContainerToShipped(containerID: string) {
    var URL = this.rootURL + `/Orders/OrderItemContainers/${containerID}/Ship`;
    return this.http
      .put<any>(URL, {})
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getOrderAttachment(attachmentID: number): Observable<OrderAttachment> {
    const url = this.rootURL + `/Orders/OrderAttachments/${attachmentID}`;
    return this.http.get<OrderAttachment>(url);
  }

  createOrderAttachment(
    files: any,
    orderID: number,
    documentTypeID: number,
    languageID: number,
    comments: string
  ): Observable<OrderAttachment> {
    const url = this.rootURL + `/Orders/${orderID}/Attachments`;
    let formData: FormData = new FormData();
    for (let file of files) {
      formData.append('file[]', file);
      formData.append('documentTypeID', documentTypeID.toString());
      formData.append('languageID', languageID.toString());
      formData.append('comments', comments);
    }
    return this.http.post<OrderAttachment>(url, formData);
  }

  updateOrderAttachment(id: number, payload) {
    const url = this.rootURL + `/Orders/OrderAttachments/${id}`;
    return this.http.put(url, payload);
  }

  deleteOrderAttachment(id: number) {
    const url = this.rootURL + `/Orders/OrderAttachments/${id}`;
    return this.http.delete(url);
  }

  getShippers() {
    var URL = this.rootURL + `/Shipper`;
    return this.http
      .get<any>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getPackages(orderID: string): Observable<OrderPackage[]> {
    var URL = this.rootURL + `/Orders/${orderID}/Packages`;
    return this.http
      .get<OrderPackage[]>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getPackageTrackingDetails(orderPackageID: string): Observable<any[]> {
    var URL = this.rootURL + `/Orders/Packages/${orderPackageID}/Tracking`;
    return this.http
      .get<any[]>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  createOrderPackage(
    orderID: any,
    payload: Partial<OrderPackageRequest>
  ): Observable<OrderPackage> {
    var URL = this.rootURL + `/Orders/${orderID}/Packages`;
    return this.http
      .post<OrderPackage>(URL, payload)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  deletePackage(packageID: string): Observable<any> {
    var URL = this.rootURL + `/Orders/Packages/${packageID}`;
    return this.http
      .delete(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  cancelOrder(orderID: string, payload: CancelOrder): Observable<any> {
    var URL = this.rootURL + `/Orders/${orderID}/Cancel`;
    return this.http
      .post(URL, payload)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getCancellationReasons(): Observable<CancelReason[]> {
    if (this._cancelReasons.getValue().length == 0) {
      var URL = this.rootURL + `/CancelReason`;
      return this.http.get<CancelReason[]>(URL).pipe(
        map((x) => {
          this.setCancelReasons(x);
          return x;
        })
      );
    } else {
      return this.cancelReasons$;
    }
  }

  setCancelReasons(cancelReasons: CancelReason[]): void {
    this._cancelReasons.next(cancelReasons);
  }

  updateOrderPackage(
    packageID: string,
    payload: OrderPackageRequest
  ): Observable<OrderPackage> {
    var URL = this.rootURL + `/Orders/Packages/${packageID}`;
    return this.http
      .put<OrderPackage>(URL, payload)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  addContainerToPackage(
    packageID: string,
    payload: PackageAssignmentRequest
  ): Observable<any> {
    var URL = this.rootURL + `/Orders/Packages/${packageID}/Add`;
    return this.http
      .put<any>(URL, payload)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  removeContainerFromPackage(
    packageID: string,
    payload: PackageAssignmentRequest
  ): Observable<any> {
    var URL = this.rootURL + `/Orders/Packages/${packageID}/Remove`;
    return this.http
      .put<any>(URL, payload)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  updateOrderWithTransferRequest(orderID: string, payload: OrderTransferRequest): Observable<string> {
    const body = JSON.stringify({
      setCoordinatorNotes: true,
      coordinatorNotes: payload.coordinatorNotes,
    });

    const url = this.rootURL + `/Orders/${orderID}`;
    return this.http.patch<string>(url, body);
  }

  getEarmarksByCatalogID(
    inventoryCatalogID: number,
    requestedUOM: string
  ): Observable<OrderItemEarmarksByCatalogIDResponse[]> {
    const url =
      this.rootURL +
      `/Orders/InventoryAssignments/${inventoryCatalogID}/${requestedUOM}`;
    return this.http
      .get<OrderItemEarmarksByCatalogIDResponse[]>(url)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getPackingListContent(orderID: any) {
    var URL = this.rootURL + `/Orders/${orderID}/PackingList`;
    return this.http
      .get<any>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getTestSubstanceTrackingContent(orderID: any) {
    var URL = this.rootURL + `/Orders/${orderID}/TestSubstanceTracking`;
    return this.http
      .get<any>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getCustomerInvoice(orderID: any, usePrimaryBroker: boolean, useBasic: boolean) {
    var URL = this.rootURL + `/Orders/${orderID}/CustomsInvoice/${usePrimaryBroker}/${useBasic}`;
    return this.http
      .get<any>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getSubstanceDistribution(orderID: any) {
    var URL = this.rootURL + `/Orders/${orderID}/TestSubstanceDistribution`;
    return this.http
      .get<any>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getOrderContents(orderID: any): Observable<any[]> {
    var URL = this.rootURL + `/Orders/${orderID}/OrderContents`;
    return this.http
      .get<any[]>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  toggleHold(orderID: string, data: OrderHoldRequest): Observable<string> {

    const body = JSON.stringify({
      setHoldStatus: true,
      holdNotes: data.holdNotes,
      placeOnHold: data.placeOnHold,
      holdReasonIDs: data.holdReasonIDs
    });

    const url = this.rootURL + `/Orders/${orderID}`;
    return this.http
      .patch<string>(url, body)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  updateHoldNotes(
    orderID: string,
    data: UpdateOrderHoldNotesRequest
  ): Observable<string> {
    const url = this.rootURL + `/Orders/${orderID}/UpdateHoldNotes`;
    return this.http
      .put<string>(url, data)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getOrderHistory(orderID: string): Observable<OrderHistory[]> {
    var URL = this.rootURL + `/Orders/${orderID}/History`;
    return this.http
      .get<OrderHistory[]>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  createSplitOrder(orderID: any, payload: any, requestedOrderStatus = null) {
    var URL = this.rootURL + `/Orders/${orderID}/Split`;
    if (requestedOrderStatus) {
      URL += `/${requestedOrderStatus}`;
    }
    return this.http.post<any>(URL, payload);
    //.pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  // Order Item Container Manipulation

  createOrderItemContainers(
    payload: OrderItemContainerChangeRequest
  ): Observable<null> {
    var URL = this.rootURL + `/Orders/OrderItemContainers`;
    return this.http.post<null>(URL, payload);
  }

  updateOrderItemContainers(
    payload: OrderItemContainerChangeRequest
  ): Observable<null> {
    var URL = this.rootURL + `/Orders/OrderItemContainers/Assign`;
    return this.http.post<null>(URL, payload);
  }

  deleteOrderItemContainers(
    payload: OrderItemContainerChangeRequest
  ): Observable<null> {
    var URL = this.rootURL + `/Orders/OrderItemContainers/Delete`;
    return this.http.post<null>(URL, payload);
  }

  assignOrderItemContainers(payload: OrderItemContainerAssignRequest[]) {
    var URL = this.rootURL + `/Orders/OrderItemContainers/Assign`;
    return this.http.post<null>(URL, payload);
  }

  unAssignOrderItemContainers(payload: OrderItemContainerAssignRequest) {
    var URL = this.rootURL + `/Orders/OrderItemContainers/Unassign`;
    return this.http.post<null>(URL, payload);
  }

  getOrderItemContainerAssignments(
    orderItemID: string
  ): Observable<Array<OrderItemContainerAssignmentResponse>> {
    var URL =
      this.rootURL + `/Orders/OrderItems/${orderItemID}/ContainerAssignments`;
    return this.http.get<Array<OrderItemContainerAssignmentResponse>>(URL);
  }

  updateRequestedContainerLabel(
    itemID: string,
    payload: UpdateRequestedContainerLabelRequest
  ): Observable<string> {
    var URL =
      this.rootURL + `/Orders/OrderItems/${itemID}/RequestedContainerLabel`;
    return this.http
      .put<string>(URL, payload)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  retrieveCOA(itemID: any) {
    var URL = this.rootURL + `/Orders/OrderItems/${itemID}/COA`;
    return this.http
      .get<any>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  getDocuments(itemID: any) {
    var URL = this.rootURL + `/Orders/OrderItems/${itemID}/Documents`;
    return this.http
      .get<any>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  viewDocument(documentID: any) {
    var URL = this.rootURL + `/Orders/OrderItems/Documents/${documentID}`;
    return this.http
      .get<any>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  createNoDecant(itemID: any, payload: any): Observable<Order> {
    var URL = this.rootURL + `/Orders/OrderItems/${itemID}/NoDecant`;
    return this.http
      .post<Order>(URL, payload)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  shareDocument(itemID, documentID) {
    var URL = this.rootURL + `/Orders/OrderItems/${itemID}/Documents/${documentID}/Share`;
    return this.http
      .put<any>(URL, {})
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

  hideDocument(documentID) {
    var URL = this.rootURL + `/Orders/OrderItems/Documents/${documentID}/Hide`;
    return this.http
      .put<any>(URL, {})
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }
}