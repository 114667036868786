<res-layout class="res-layout-font">
	<res-heading>
		<span res-heading-title> <a [routerLink]="['']" resNavItem title=""><img res-heading-logo
					src="assets/alchemistLogo.png" width="26" height="39" />
				<h4 style="color:black">Alchemist</h4><i class="envName">{{envName=='PROD'?'':envName}}</i>
			</a>
		</span>
	</res-heading>
	<res-nav-horizontal [compact]="true">
		<res-nav-group id="p2pMenu"
			[hidden]="!this.permissionService.isCurrentP2P && !this.permissionService.isCompleteP2PSearch" title='P2P'>
			<a id="currentP2p" [routerLink]="['p2p/current']" resNavItem title="Current P2P"
				test="!this.permissionService.isCurrentP2P"
				[ngClass]="!this.permissionService.isCurrentP2P ? 'hidden':''">Current P2P</a>
			<a id="completeP2p" [routerLink]="['p2p/complete-search']" resNavItem title="Complete P2P Search"
				test="!this.permissionService.isCompleteP2PSearch"
				[ngClass]="!this.permissionService.isCompleteP2PSearch ? 'hidden':''">Complete P2P Search</a>

		</res-nav-group>
		<res-nav-group id="requestCoordinationMenu" [hidden]="!(this.permissionService.isRequestCoordinator || this.permissionService.isRequestCoordinatorPrep 
			|| this.permissionService.isRequestCoordinatorWarehouse || this.permissionService.isRequestCoordinatorProcessed || this.permissionService.isRequestCoordinatorCompleted
			|| this.permissionService.isRequestCoordinatorReprint)" title='Request Coordination'>
			<a id="newRequest" [routerLink]="['requests/new']" resNavItem
				[ngClass]="!this.permissionService.isRequestCoordinator ? 'hidden':''" title="New Requests">New
				Requests</a>
			<a id="requestPrep" [routerLink]="['requests/prep']" resNavItem
				[ngClass]="!this.permissionService.isRequestCoordinatorPrep ? 'hidden':''" title="Request Prep">Request
				Prep</a>
			<a id="processedRequests" [routerLink]="['requests/processed']" resNavItem
				[ngClass]="!this.permissionService.isRequestCoordinatorProcessed ? 'hidden':''"
				title="Processed Requests">Processed
				Requests</a>
			<a id="completedRequests" [routerLink]="['requests/complete']" resNavItem
				[ngClass]="!this.permissionService.isRequestCoordinatorCompleted ? 'hidden':''"
				title="Complete Requests">Completed Requests</a>
			<a id="reprintingRequests" [routerLink]="['requests/reprint']" resNavItem
				[ngClass]="!this.permissionService.isRequestCoordinatorReprint ? 'hidden':''"
				title="Processed Requests">Reprinting
				Request Details</a>
		</res-nav-group>
		<res-nav-group id="receivingMenu"
			[hidden]="!(this.permissionService.isReceiving || this.permissionService.isInventory || this.permissionService.canEditDensity)"
			title='Receiving'>
			<a id="receive" [routerLink]="['receiving']" resNavItem title="Receive"
				test="!this.permissionService.isReceiving"
				[ngClass]="!this.permissionService.isReceiving ? 'hidden':''">Receive</a>
			<a id="inventory" [routerLink]="['inventory']" resNavItem title="Inventory"
				test="!this.permissionService.isInventory"
				[ngClass]="!this.permissionService.isInventory ? 'hidden':''">Update Inventory Locations</a>
			<a id="editDensity" [routerLink]="['editdensity']" resNavItem title="Edit Density"
				test="!this.permissionService.canEditDensity"
				[ngClass]="!this.permissionService.canEditDensity ? 'hidden':''">Edit Material Density</a>
		</res-nav-group>
		<res-nav-group id="warehousingMenu" title='Warehousing'
			[hidden]="!(this.permissionService.isWarehousingDispose || this.permissionService.isWarehousingPicking)">
			<a id="stocking" class='hidden' [routerLink]="['']" resNavItem title="Stocking">Stocking</a>
			<a id="picking" [routerLink]="['warehousing/picking']"
				[ngClass]="!this.permissionService.isWarehousingPicking ? 'hidden':''" resNavItem
				title="Picking">Picking</a>
			<a id="dispose" [routerLink]="['warehousing/dispose']"
				[ngClass]="!this.permissionService.isWarehousingDispose ? 'hidden':''" class="ml-0" resNavItem
				title="Dispose">Dispose</a>
			<a id="locationbarcode" [routerLink]="['warehousing/locationbarcodelabels']"
				[ngClass]="!this.permissionService.isInventory ? 'hidden':''" class="ml-0" resNavItem
				title="Location Barcode Labels">Location Barcode Labels</a>
			<a id="disposallog" [routerLink]="['warehousing/disposallogs']"
				[ngClass]="!this.permissionService.isWarehousingDispose ? 'hidden':''" class="ml-0" resNavItem
				title="Location Barcode Labels">Disposal Log</a>
		</res-nav-group>

		<res-nav-group id="decantingMenu"
			[hidden]="!(this.permissionService.isDecant || this.permissionService.isDecantWarehousing)"
			title='Decanting'>
			<a id="decant" [routerLink]="['decant']" resNavItem
				[ngClass]="!this.permissionService.isDecant ? 'hidden':''" title="Decant">Decant</a>
			<a id="reweighParent" [routerLink]="['decant/parentweight']" resNavItem
				[ngClass]="!this.permissionService.isDecantWarehousing ? 'hidden':''"
				title="Reweigh Parent Container">Reweigh Parent
				Container</a>
		</res-nav-group>
		<res-nav-group id="packingMenu" [hidden]="!(this.permissionService.isPacking)" title='Packing & Shipping'>
			<a id="packing" [routerLink]="['packing']" resNavItem
				[ngClass]="!this.permissionService.isPacking ? 'hidden':''" title="Packing">Packing</a>
		</res-nav-group>
		<res-nav-group id="administrationMenu"
			[hidden]="!(this.permissionService.isAdministrator || this.permissionService.isAdministratorScaleTest || this.permissionService.canManageLabels || this.permissionService.canManageDestinationGuide)"
			title='Administration'>
			<a id="cancellationreasons" [routerLink]="['/cancellationreasons']"
				[ngClass]="!this.permissionService.canManageCancellationReasons ? 'hidden':''" resNavItem
				title="Manage Cancellation Reasons">Cancellation Reasons</a>
			<a id="manageLabels" [routerLink]="['/labels']"
				[ngClass]="!this.permissionService.canManageLabels ? 'hidden':''" resNavItem
				title="Manage Compliance Labels">Compliance Labels</a>
			<a id="containerweights" [routerLink]="['/containerweights']" resNavItem
				title="Manage Container Weights, including plastic weights"
				[ngClass]="!this.permissionService.canManagerContainerWeights ? 'hidden':''">Container Weights</a>
			<a id="destinationGuide" [routerLink]="['destinationguide']"
				[ngClass]="!this.permissionService.canManageDestinationGuide ? 'hidden':''" resNavItem
				title="Manage Destination Guides shown for each country">Destination Guides</a>
			<a id="destinationGuide" [routerLink]="['destinationguideoptions']"
				[ngClass]="!this.permissionService.canManageDestinationGuide ? 'hidden':''" resNavItem
				title="Manage the options shown in destination guide editor">Destination Guide Options</a>
			<a id="manageLabels" [routerLink]="['/labelprinters']"
				[ngClass]="!this.permissionService.canManageLabelPrinters ? 'hidden':''" resNavItem
				title="The system tracks each printer used for GLP/stock barcode label printing">GLP/Stock Barcode Label
				Printers</a>
			<a id="labelDesigns" [routerLink]="['labelDesigns']"
				[ngClass]="!this.permissionService.canManageLabelPrinters ? 'hidden':''" resNavItem
				title="Each GLP/stock barcode label has a design record, corresponding to a Bartender file">GLP/Stock
				Barcode Label Designs </a>
			<a id="printerassignments" [routerLink]="['/printerassignments']" resNavItem
				title="A printer assignment combines a label design, a label printer, and a station to enable silent printing"
				[ngClass]="!this.permissionService.canManageLabelPrinters ? 'hidden':''">GLP/Stock Barcode Printer
				Assignments</a>
			<a id="holdreasons" [routerLink]="['/holdreasons']" resNavItem
				title="Manage hold reasons for standard and peer-to-peer orders"
				[ngClass]="!this.permissionService.canManageHoldReasons ? 'hidden':''">Hold Reasons</a>
			<a id="manageRoles" [routerLink]="['/manageroles']"
				[ngClass]="!this.permissionService.isAdministrator ? 'hidden':''" resNavItem
				title="Allows an administrator to manage roles">Roles</a>
			<a id="sampleuse" [routerLink]="['/sampleUses']" resNavItem title="Manage Sample Uses"
				[ngClass]="!this.permissionService.canManageSampleUses ? 'hidden':''">Sample Uses</a>
			<a id="managescales" [routerLink]="['scales']"
				[ngClass]="!this.permissionService.canManageScales ? 'hidden':''" resNavItem
				title="Allows an administrator to manage scales">Scales</a>
			<a id="scaleTest" [routerLink]="['scaletest']"
				[ngClass]="!this.permissionService.isAdministratorScaleTest ? 'hidden':''" resNavItem
				title="Allows an administrator to test the scales at a station">Scale Test Utility</a>
			<a id="sharedDocuments" [routerLink]="['/documents']"
				[ngClass]="!this.permissionService.canManageLabels ? 'hidden':''" resNavItem
				title="Shared documents are stored by product source/number">Shared Documents</a>
			<a id="manageShippers" [routerLink]="['manageShippers']"
				[ngClass]="!this.permissionService.canManageShippers ? 'hidden':''" resNavItem
				title="Manage Shippers">Shippers </a>
			<a id="manageStations" [routerLink]="['/managestations']"
				[ngClass]="!this.permissionService.canManageStations ? 'hidden':''" resNavItem
				title="Allows an administrator to manage stations">Stations</a>
			<a id="manageUser" [routerLink]="['manageUser']"
				[ngClass]="!this.permissionService.isAdministrator ? 'hidden':''" resNavItem
				title="Assign users to role(s) in order to access system functions">Users</a>
			<a id="userAndRoles" [routerLink]="['usersandroles']"
				[ngClass]="!this.permissionService.isAdministrator ? 'hidden':''" resNavItem
				title="Assign users to role(s) in order to access system functions">Users and Roles</a>
			<a id="destinationGuide" [routerLink]="['managewarehouses']"
				[ngClass]="!this.permissionService.canManageGSSWarehouse ? 'hidden':''" resNavItem
				title="Manage warehouses used by GSS">Warehouses</a>
		</res-nav-group>
		<p-autoComplete #autocomplete [hidden]="!(this.permissionService.canPerformSearch)" class="search-requests"
			placeholder="Go to request" [(ngModel)]="order" [suggestions]="requestList" (onSelect)="onSelect($event)"
			[field]='selectedRequest' showEmptyMessage="true" [emptyMessage]="'no matches'" [forceSelection]="true">
			<ng-template let-order pTemplate="item">
				<div class="order-item">
					<div>{{order.requestNumber}}</div>
				</div>
			</ng-template>
		</p-autoComplete>
	</res-nav-horizontal>
	<res-body>
		<app-loading></app-loading>
		<router-outlet></router-outlet>
	</res-body>
	<res-footer class="footer" version="">
		<div res-footer-content class="p-d-flex">
			<div class="p-mr-2 res-footer-content">
				<ul>
					<li>
						<strong> <a href="https://facts.research.corteva.com" target="_blank">FACTS - Formulation
								Repository</a></strong>
					</li>
					<li>
						<strong> <a href="https://cp-compoundutilities.phibred.com" target="_blank">Compound Utilities -
								Repository</a></strong>
					</li>
					<li>
						<strong> <a href="https://forecasting-prod.azurewebsites.net/#/procurement" target="_blank">GSS
								Procurement</a></strong>
					</li>
					<li>
						<strong> <a href="https://www.3eonline.com" target="_blank">Search for SDS</a></strong>
					</li>
				</ul>
			</div>
			<div class="p-mr-2 res-footer-content">
				<ul>
					<li>
						<strong>
							<a href="https://confluence.research.corteva.com/display/CPKB/Alchemist+LIMS"
								target="_blank">Confluence - FAQ and Help</a>
						</strong>
					</li>
					<li>
						<strong>
							<a href="https://jira.research.corteva.com/servicedesk/customer/portal/32/create/509"
								target="_blank">JIRA ticket for missing material or supplier</a>
						</strong>
					</li>
					<li>
						<strong>
							<a href="https://jira.research.corteva.com/servicedesk/customer/portal/32/create/508"
								target="_blank">JIRA ticket for application issues</a>
						</strong>
					</li>
					<li>
						<strong>
							<a href="https://jira.research.corteva.com/servicedesk/customer/portal/32"
								target="_blank">JIRA ticket for other support</a>
						</strong>
					</li>
				</ul>
			</div>
			<div class="p-mr-2 res-footer-content">
				<ul>
					<li>
						<strong>
							<a href="https://race.phibred.com/Home/Index" target="_blank">RACE</a>
						</strong>
					</li>
					<li>
						<strong>
							<a href="https://app.powerbi.com.mcas.ms/groups/31fa0694-355b-41b5-9668-75106c30c3ef/reports/ffa0dba3-7df3-4ebb-9f76-848c347e6165/ReportSection710aefb0c089709342b3"
								target="_blank">GSS Reporting</a>
						</strong>
					</li>
				</ul>
			</div>
		</div>

		<div res-footer-application-details>
			<ul>
				<li [ngClass]="this.versionNumber == null ? 'hidden':''">
					Version {{versionNumber}}
				</li>
				<li>
					Developed by Team Hermes
				</li>
				<li>
					Internal Use Only
				</li>
			</ul>
		</div>
	</res-footer>
</res-layout>
<p-toast></p-toast>