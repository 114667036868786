import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http';

import { User, AdUser, LoginUserPermission } from '../../models/security-models';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { Configuration } from 'src/app/configuration';

@Injectable()
export class UserService {
    readonly rootURL = Configuration.REST_API_URL;

    constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
    }

    getUsers(): Observable<User[]> {
        var URL = this.rootURL + '/Users';
        return this.http.get<User[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getUser(userID: number): Observable<User> {
        var URL = this.rootURL + `/Users/${userID}`;
        return this.http.get<User>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    login(): Observable<LoginUserPermission> {
        var URL = this.rootURL + '/Users/Login';
        return this.http.get<LoginUserPermission>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getADGroupUsers(): Observable<AdUser[]> {
        var URL = this.rootURL + '/Users/GetADGroupUsers';
        return this.http.get<AdUser[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getUsersByPermission(permissionID: number): Observable<User[]> {
        var URL = this.rootURL + `/Users/ByPermission/${permissionID}`;
        return this.http.get<User[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    assignRoles(data): Observable<User[]> {
        var URL = this.rootURL + '/Users';
        return this.http.post<User[]>(URL,data).pipe(catchError(err => this.responseHandler.handleError(err)));
    }
}