import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LabelDesign } from 'src/app/models/label-design.model';
import { LabelDesignService } from 'src/app/services/label-design-service/label-design-service';
import { LabelDesignModalComponent } from '../label-design-modal/label-design-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EditButtonRenderer } from 'src/app/core/modules/ag-grid/components/ag-grid-cell-renderers/edit-button-renderer.component';

@Component({
  selector: 'app-label-design',
  templateUrl: './label-design.component.html',
  styleUrls: ['./label-design.component.scss']
})
export class LabelDesignComponent implements OnInit, OnDestroy {
  rowData: LabelDesign[];
  columnDefs: any;
  list: any[] = [];
  private gridApi: any;
  private subscriptions = new Subscription();
  constructor(private labelDesignService: LabelDesignService, private dialog: MatDialog,) { }
  overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">No Label  </span>';
  frameworkComponents = {
    editButtonRenderer: EditButtonRenderer
  };

  ngOnInit(): void {
    this.initData();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    
    params.api.addEventListener('editButtonClicked', (e) => {
      this.editLabelDesign(e.value)
    });

    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  initData() {
    this.columnDefs = [
      { headerName: 'Name', field: 'name' },
      { headerName: 'Description', field: 'description' },
      { headerName: 'Bartender  Filename', field: 'bartenderFilename' },
      {
        cellRenderer: 'editButtonRenderer', minWidth: 132, maxWidth: 132, width: 132
      }
    ];
    this.getLabelsDesigns();
  }

  getLabelsDesigns() {
    this.subscriptions.add(this.labelDesignService.getLabelDesign().subscribe(
      data => {
        this.rowData = data;
      }
    ));
  }

  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  editLabelDesign(data) {
    this.dialog.open(LabelDesignModalComponent, { data: data })
      .afterClosed().subscribe((res) => {
        if (res) this.getLabelsDesigns();
      })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
