import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BreadCrumbFlow } from '../models/bread-crumb-flow.model';

export interface IBreadCrumbService {
  connect(): Observable<BreadCrumbFlow[]>;
  destroy();
}

@Injectable()
export class BreadCrumbService implements IBreadCrumbService {
  private routerSubscription: Subscription;
  private $breabCrumbSubject = new BehaviorSubject<BreadCrumbFlow[]>([]);
  breadCrumbSubscription = this.$breabCrumbSubject.asObservable();

  constructor(
    @Inject('flow') private flows: BreadCrumbFlow[],
    private router: Router,
    activatedRoute: ActivatedRoute) {
    //this.fixUrl(flows);
    let url = activatedRoute['_routerState'].snapshot.url;
    let breadCrumbs = this.buildBreadCrumbFromFlow(url);
    if (breadCrumbs !== null && breadCrumbs !== undefined) {
      this.notifySubscribers(breadCrumbs);
    }
  }

  connect(): Observable<BreadCrumbFlow[]> {
    this.routerSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        let breadCrumbs = this.buildBreadCrumbFromFlow(event.url);
        if (breadCrumbs) {
          this.notifySubscribers(breadCrumbs);
        }
      });
    return this.breadCrumbSubscription;
  }

  destroy() {
  }

  public notifySubscribers(breadCrumbs: BreadCrumbFlow[]) {
    var result = [...breadCrumbs];
    this.$breabCrumbSubject.next(result);
  }

  private buildBreadCrumbFromFlow(url: string): BreadCrumbFlow[] {
    for (let index = 0; index < this.flows.length; index++) {
      const flow = this.flows[index];
      var currentStep = this.getBreadCrumbFlowFromUrl(url, flow);
      if (currentStep) {
        var parentStep = this.getBreadCrumbFlowParent(currentStep, flow);
        var flowIndex = parentStep.children.findIndex((x: any) => x.routerLink == currentStep.routerLink);
        if (flowIndex > -1) {
          let result = parentStep.children.slice(0, flowIndex + 1) as BreadCrumbFlow[];
          return result;
        }
      }
    }

  }

  private getBreadCrumbFlowFromUrl(url: string, breadCrumbFlow?: BreadCrumbFlow): BreadCrumbFlow {
    if (breadCrumbFlow) {
      if (breadCrumbFlow.routerLink == url) {
        return breadCrumbFlow;
      } else if (breadCrumbFlow.children && breadCrumbFlow.children.length > 0) {
        for (let index = 0; index < breadCrumbFlow.children.length; index++) {
          const element = breadCrumbFlow.children[index];
          var childFlow = this.getBreadCrumbFlowFromUrl(url, element);
          if (childFlow) {
            return childFlow;
          }
        }
      }
    }
  }

  private getBreadCrumbFlowParent(breadCrumbFlow: BreadCrumbFlow, parentBreadCrumbFlow: BreadCrumbFlow): BreadCrumbFlow {
    if (parentBreadCrumbFlow) {
      if (parentBreadCrumbFlow.routerLink == breadCrumbFlow.routerLink) return breadCrumbFlow; //both flow passed are same
      else if (parentBreadCrumbFlow.children && parentBreadCrumbFlow.children.length > 0) {
        for (let index = 0; index < parentBreadCrumbFlow.children.length; index++) {
          const element = parentBreadCrumbFlow.children[index];
          var childFlow = this.getBreadCrumbFlowParent(breadCrumbFlow, element);
          //meaning now we now who is the parent
          if (childFlow) {
            return parentBreadCrumbFlow;
          }
        }
      }
    }
  }
}