<div class="container" style="height: 400px; display: grid;">

    <h5>Print Labels</h5>
    <span>
        <pre style="font-family: sans-serif;">Material Name: {{labelInfoData?.requestedContainerLabel}}</pre>
        <pre *ngIf="labelInfoData.isGLPRequest;else hTemplate"
            style="font-family: sans-serif;">Net Contents: {{formatDecimal(labelInfoData.netContents)}} {{labelInfoData?.unitOfMeasureDesc}}    Lot ID: {{labelInfoData.lotNumber}}    DOM: {{formatDate(labelInfoData.manufactureDate)}}    Recert. Date: {{formatDate(labelInfoData.recertificationDate)}}</pre>

        <ng-template #hTemplate>
            <pre
                style="font-family: sans-serif;">Net Contents: {{formatDecimal(labelInfoData.netContents)}} {{labelInfoData?.unitOfMeasureDesc}}    Lot: {{labelInfoData.manufacturerLotNumber}}    DOM: {{formatDate(labelInfoData.manufactureDate)}}    Exp Date: {{formatDate(labelInfoData.expirationDate)}}</pre>
        </ng-template>
        <div class="doublespacer"></div>
    </span>
    <mat-dialog-content>
        <div>
            <table class="p-mt-2">
                <tr style="font-weight: bold;">
                    <th>Type</th>
                    <th>Size</th>
                    <th>Language</th>
                    <th>File Name</th>
                    <th></th>
                </tr>
                <ng-container *ngIf="labels?.length">
                    <tr *ngFor="let label of labels">
                        <td>{{label.typeName}} </td>
                        <td>{{label.sizeName}} </td>
                        <td> {{label.languageName}}</td>
                        <td>{{label.filename}} </td>
                        <td> <button class="download-btn" mat-raised-button color="cor-primary"
                                (click)="onDownload(label)">Download</button></td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="!labels?.length">
                    <pre style="font-family: sans-serif;">No data found</pre>
                </ng-container>
            </table>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: flex-end; margin-top: 10px;">
        <button mat-raised-button color="cor-primary" (click)="onClose()">Close</button>
    </mat-dialog-actions>
</div>