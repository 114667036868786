import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestinationGuideOptionsType } from 'src/app/models/destination-guide-options';
import { DestinationGuideOptionsService } from 'src/app/services/destination-guide-option-service/destination-guide-options.service';

@Component({
  selector: 'app-edit-destination-guide-options-modal',
  templateUrl: './edit-destination-guide-options-modal.component.html',
  styleUrls: ['./edit-destination-guide-options-modal.component.scss'],
})
export class EditDestinationGuideOptionsModalComponent implements OnInit {
  public optionForm: FormGroup;
  public selectedOption: DestinationGuideOptionsType;
  public optionsList: DestinationGuideOptionsType[];
  public buttonLabel: string;

  constructor(
    private readonly destinationGuideOptionsService: DestinationGuideOptionsService,
    public dialogRef: MatDialogRef<EditDestinationGuideOptionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.buttonLabel = 'Edit';
  }

  ngOnInit(): void {
    this.buildForm();
    this.initData();
    this.getOptionTypeList();
  }

  initData() {
    if (this.data == null) {
      var data = {
        optionId: null,
        optionTypeId: null,
        name: null,
        sortOrder: null,
        isDefault: false,
      };

      this.data = data;
      this.buttonLabel = 'Add New';
    }
  }

  onSubmit() {
      this.dialogRef.close(this.optionForm.value);
  }

  getOptionTypeList() {
    this.destinationGuideOptionsService.getDestionationGuideOptionTypes().subscribe({
      next: (res) => {
        this.optionsList = res;
        this.selectedOption = this.optionsList.find(
          (s) => s.optionTypeID == this.data.optionTypeID
        );
        this.patchForm();
      },
    });
  }

  private patchForm() {
    this.optionForm.patchValue({
      optionId: this.data.optionID,
      optionTypeId: this.data.optionTypeID,
      name: this.data.name,
      sortOrder: this.data.sortOrder,
      isDefault: this.data.isDefault,
    });
  }

  private buildForm() {
    this.optionForm = new FormGroup({
      optionId: new FormControl(null),
      optionTypeId: new FormControl(null, Validators.required),
      name: new FormControl(null, [
        Validators.maxLength(255),
        Validators.required,
      ]),
      sortOrder: new FormControl(null, Validators.min(1)),
      isDefault: new FormControl(false),
    });
  }
}
