import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridApi, GridOptions } from 'ag-grid-community';
import { valueFormatter } from 'src/app/helpers/grid-helpers';
import { Order, RequestTypes } from 'src/app/models/order-models';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { OrderService } from 'src/app/services/order-service/order-service';

@Component({
  selector: 'app-no-decant-modal',
  templateUrl: './no-decant-modal.component.html',
  styleUrls: ['./no-decant-modal.component.scss']
})
export class NoDecantModalComponent implements OnInit {

  private gridApi: GridApi;
  noDecantGridOptions: GridOptions = {
    getRowStyle: (params) => {
      if(params?.data?.fullLocationName.toLowerCase().includes('quarantine')){
        return {'color': 'red'};
      }
      return null;
    }
  };
  serachCriteriaForm: FormGroup;

  public columnDefinitions;

  public rowData = [];
  public rowDataBackup = [];

  targetAmountInKg = 0;
  targetAmountRemaining = 0;
  selectedTotalAmount = 0;

  disableSave: boolean = true;

  constructor(public dialogRef: MatDialogRef<NoDecantModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private orderService: OrderService, private inventoryService: InventoryService) {
    this.setColumnDefs();
  }

  ngOnInit(): void {
    this.buildForm();
    this.calculateTargetAmountRemaining();
    this.inventoryService.getCatalogItemsByWarehouse(this.data.order.warehouseID, this.data.orderItem.inventoryCatalogID,
      this.data.orderItem.unitOfMeasureDesc).subscribe(response => {
        if (response?.length) {
          this.rowData = this._sortBindData(response);
          this.rowDataBackup = this._sortBindData(response);
        }
        else this.rowData = [];
      })
  }

  private _sortBindData(data) {

    var selectedGlps = data
      .filter(ls => ls.lotNumber === this.data.orderItem.requestedLotNumber
        && ls.fullLocationName.includes('GLP'));

    var selectedStocks = data
      .filter(ls => ls.lotNumber === this.data.orderItem.requestedLotNumber
        && ls.fullLocationName.includes('Stock'));

    data = data.filter(ls => ls.lotNumber != this.data.orderItem.requestedLotNumber);

    var glp = data.filter(ls => ls.fullLocationName.includes('GLP'));
    glp?.forEach(item => item['expRecertDate'] = item.recertificationDate ? item.recertificationDate :
      (item.expirationDate ? item.expirationDate : null));
    glp = glp?.sort((lot1, lot2) => {
      if (lot1.expRecertDate === null) {
        return 1;
      }
      else if (lot2.expRecertDate === null) {
        return -1;
      }
      else {
        const dateOne = new Date(lot1.expRecertDate);
        const dateTwo = new Date(lot2.expRecertDate);
        return dateOne.getTime() - dateTwo.getTime();
      }
    });

    var stock = data.filter(ls => ls.fullLocationName.includes('Stock'));
    stock?.forEach(item => item['expRecertDate'] = item.recertificationDate ? item.recertificationDate :
      (item.expirationDate ? item.expirationDate : null));
    stock = stock?.sort((lot1, lot2) => {
      if (lot1.expRecertDate === null) {
        return 1;
      }
      else if (lot2.expRecertDate === null) {
        return -1;
      }
      else {
        const dateOne = new Date(lot1.expRecertDate?.split('T')[0]);
        const dateTwo = new Date(lot2.expRecertDate?.split('T')[0]);
        return dateOne.getTime() - dateTwo.getTime();
      }
    });

    data = [];

    if (this.data.order.requestTypeID === Number(RequestTypes.Stock)) {
      this.insertMatchesItems(selectedStocks, stock, selectedGlps, glp);
      stock.push(...glp);
      data = stock;
    }
    else if (this.data.order.requestTypeID === Number(RequestTypes.GLP)) {
      this.insertMatchesItems(selectedStocks, stock, selectedGlps, glp);
      glp.push(...stock);
      data = glp;
    }

    let result = [];
    let stockData = data.filter(d => new RegExp("\\bstock\\b").test(d.fullLocationName.toLowerCase()));
    let glpData = data.filter(d => new RegExp("\\bglp\\b").test(d.fullLocationName.toLowerCase()));

    stockData = this._sortData(stockData) || [];
    glpData = this._sortData(glpData) || [];
    if (this.data.order.requestTypeID === Number(RequestTypes.Stock)) {
      result = stockData.concat(glpData);
    }
    else if (this.data.order.requestTypeID === Number(RequestTypes.GLP)) {
      result = glpData.concat(stockData);
    }

    return result;
  }

  private _sortByAmount(data) {
    data = data?.sort((lot1, lot2) => {
      if (lot2.remainingAmount > lot1.remainingAmount) {
        return 1;
      }
      else if (lot2.remainingAmount < lot1.remainingAmount) {
        return -1;
      }
      else {
        return lot2.remainingAmount - lot1.remainingAmount;
      }
    });
  }

  private _sortData(data) {
    let groupedData = data.reduce(
      (result, item) => ({
        ...result,
        [item['lotNumber']]: [
          ...(result[item['lotNumber']] || []),
          item,
        ],
      }),
      {},
    );

    const reversedKeys = Object.keys(groupedData);
    reversedKeys?.forEach(key => {
      this._sortByAmount(groupedData[key]);
    });

    let sortedData = []
    reversedKeys?.forEach(key => {
      sortedData = sortedData.concat((groupedData[key]));
    });

    return sortedData;
  }

  private insertMatchesItems(selectedStocks: any[], stock: any[], selectedGlps: any[], glp: any[]) {
    selectedStocks.forEach(selectedStock => {
      if (selectedStock)
        stock.unshift(selectedStock);
    });

    selectedGlps.forEach(selectedGlp => {
      if (selectedGlp)
        glp.unshift(selectedGlp);
    });
  }

  private setColumnDefs() {
    this.columnDefinitions = [
      {
        headerName: 'Select',
        checkboxSelection: true,
        width: 100,
        cellStyle: params => {
          if ((params.data.isNoDecant) ||
            (this.data.order.requestTypeID === Number(RequestTypes.GLP) &&
              params.data?.fullLocationName.toLowerCase().includes('stock')) ||
            (this.data.order.requestTypeID === Number(RequestTypes.Stock) &&
              params.data?.fullLocationName.toLowerCase().includes('glp'))) {
            return { 'display': 'none', 'pointer-events': 'none', opacity: '0.4' }
          }
          else if (params.data.remainingAmount > this.targetAmountRemaining)
            return { 'display': 'none', 'pointer-events': 'none', opacity: '0.2' }
          else
            return '';
        }
      },
      {
        headerName: 'Lot Number',
        field: 'lotNumber',
        sortable: true,
        unSortIcon: true,
        width: 150
      },
      {
        headerName: 'Container Number',
        field: 'itemID',
        sortable: true,
        unSortIcon: true,
        width: 150
      },
      {
        headerComponentParams: (params) => {
          let template = `<span class="custom-header">Exp Date/<b style="font-weight: bold">Recert Date</b></span>`
          return { template }
        },
        headerTooltip: 'Exp Date/Recert Date',
        field: 'expRecertDate',
        cellRenderer: (params) => {
          if (params.data['expRecertDate'])
            params.data['expRecertDate'] = formatDate(params.data['expRecertDate'], 'd-MMM-yyyy', 'en-US');

          if (params.data && params.data.recertificationDate) {
            return `<b class="bold">${formatDate(params.data.recertificationDate, 'd-MMM-yyyy', 'en-US')}</b>`;
          }
          else if (params.data && params.data.expirationDate) {
            return formatDate(params.data.expirationDate, 'd-MMM-yyyy', 'en-US');
          }
          else return '--';
        },
        tooltipValueGetter: (params) => {
          if (params.data['expRecertDate'])
            params.data['expRecertDate'] = formatDate(params.data['expRecertDate'], 'd-MMM-yyyy', 'en-US');

          if (params.data && params.data.recertificationDate) {
            return `${formatDate(params.data.recertificationDate, 'd-MMM-yyyy', 'en-US')}`;
          }
          else if (params.data && params.data.expirationDate) {
            return formatDate(params.data.expirationDate, 'd-MMM-yyyy', 'en-US');
          }
          else return '--';
        },
        flex: 1
      },
      {
        headerName: 'Storage Location',
        field: 'storageLocation',
        flex: 1,
        valueGetter: (params) => {
          params.data['storageLocation'] = params?.data?.fullLocationName.toLowerCase().includes('glp') ? 'GLP' : 'Stock';
          return params.data['storageLocation'];
        }
      },
      {
        headerName: 'Amount',
        field: 'remainingAmount',
        flex: 1,
        valueGetter: (params) => {
          if (!params.data?.remainingAmount) return '';
          return `${valueFormatter(params.data.remainingAmount)} ${this.data?.orderItem?.unitOfMeasureDesc}`;
        }
      }
    ];
  }

  private buildForm(): void {
    this.serachCriteriaForm = new FormGroup({
      searchCriteria: new FormControl(''),
    });

    this.serachCriteriaForm.controls["searchCriteria"].valueChanges.subscribe(value => {
      this.filterResults(value.toString());
    })
  }

  filterResults(value: string) {
    this.rowData = this.rowDataBackup;
    if (value.length >= 2) {
      this.rowData = this.rowData.filter(x => x.itemID.toLowerCase().toString().includes(value.toLowerCase()) || x.lotNumber.toLowerCase().toString().includes(value.toLowerCase()));
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  private calculateTargetAmountRemaining() {
    if (!this.data.orderItem || this.data.orderItem.containers.length === 0) { return; }
    this.data.orderItem.containers.filter(r => !r.isDecanted && !r.assignedLotNumber).forEach(row => {
      this.targetAmountRemaining += row.requestedAmount;
      this.targetAmountInKg += row.amount;
    });
    this.targetAmountRemaining = parseFloat(this.targetAmountRemaining.toFixed(7));
  }

  public formatAmount(amount) {
    if (amount < 1) {
      return amount * 1000;
    }
    return amount
  }

  cancel() {
    this.dialogRef.close(false);
  }

  onSelectionChanged(event) {
    let _selectedTotalAmount = 0;
    if (this.gridApi.getSelectedRows()?.length)
      this.gridApi.getSelectedRows()?.forEach(row => {
        _selectedTotalAmount += row.remainingAmount;
        this.selectedTotalAmount = parseFloat(_selectedTotalAmount.toFixed(7));
      })
    else
      this.selectedTotalAmount = parseFloat(_selectedTotalAmount.toFixed(7));

    if (this.selectedTotalAmount > 0 && this.selectedTotalAmount <= parseFloat(this.targetAmountInKg?.toFixed(7)))
      this.disableSave = false;
    else
      this.disableSave = true;
  }

  save() {
    this.orderService.createNoDecant(this.data.orderItem.orderItemID,
      { inventoryItemIDs: this.gridApi.getSelectedRows()?.map(r => r.itemID) }).subscribe(response => {
        this.dialogRef.close(true);
      })
  }

}
