import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {  ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { MessageService } from '../../../shared/services/message-queue.service';
import { ContainerWeightsModalComponent } from '../../container-weights-modal/container-weights-modal.component';

@Component({
  selector: 'app-edit-container-weight',
  templateUrl: './edit-container-weight.component.html',
  styleUrls: ['./edit-container-weight.component.scss']
})
export class EditContainerWeightComponent implements ICellRendererAngularComp {

  private params: any;
  constructor(private dialog: MatDialog,
    private readonly messageService: MessageService) { }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  async onEdit(){
    this.dialog.open(ContainerWeightsModalComponent, {
      height: '510px',
      width: '500px',
      data: this.params.data,
      disableClose: true
    }).afterClosed().subscribe((res: boolean) => {
      if (!res) { return; }
      this.messageService.message.next({});
    });
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}
}
