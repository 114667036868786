<div class="flex-grid">
    <div class="col">
        <h4>Update Inventory Locations</h4>
    </div>
</div>
<div class="container" style="margin-top: 20px;">
    <form [formGroup]="serachCriteriaForm" (ngSubmit)="onSubmit()">
        <input formControlName="searchCriteria" type="text" pInputText placeholder="Search for material name or lot number" />
        <button class="submit p-mr-2" mat-raised-button color="cor-primary" type="submit">Search</button>
    </form>
</div>
<div class="p-grid">
    <div class="p-col-12">
        <p-accordion>
            <p-accordionTab *ngFor="let item of itemSearch" [header]="item.catalogDescription">
                <app-inventory-item [inventoryItems]="item.inventoryItems"></app-inventory-item>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>