<app-pageheader></app-pageheader>
<div class="p-grid p-mt-md-2">
    <div class="p-col-12 p-md-12">
        <p-fileUpload #fileInput name="file" multiple="multiple" (onSelect)="onFileSelect($event)"
            [showUploadButton]="false" cancelLabel="Remove All" (onClear)="onClear($event)" [showCancelButton]="true"
            customUpload="true" accept="image/*,.csv,.doc,.docx,.pdf,.xlsx,.jpg,.msg,.ppt,.pptx">
            <ng-template pTemplate="content" let-files>
                <div class="draganddroparea">Drag and drop file here</div>
            </ng-template>
            <ng-template pTemplate="file" let-files>
                <div class="p-grid">
                    <div class="p-col-12 p-md-3 p-sm-12">
                        <label class="p-required">
                            Document Type<span class="asterisk">*</span>:
                        </label>
                        <p-dropdown required [options]="documentTypes" (onChange)="onChange(files.name,$event.value)"
                            placeholder="Select Document Type" optionLabel="name" optionValue="documentTypeID">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-5 p-sm-12 p-text-center">
                        {{files.name}}
                    </div>
                    <div class="p-col-12 p-md-2 p-sm-12 p-text-center">
                        {{niceBytes(files.size) }}
                    </div>
                    <div class="p-col-12 p-md-2 p-sm-12 p-text-center">
                        <i class="pi pi-trash product-category-icon remove-button"
                            (click)="remove($event, files,files.name)"></i>
                    </div>
                </div>
            </ng-template>

        </p-fileUpload>
    </div>

</div>
<app-navigate>
    <div class="p-col p-mb-12 p-text-right">
        <div class="p-d-flex p-jc-end">
            <div class="p-pr-md-2">
                <button (click)="onGLPReceiptClicked()" label="GLP Receipt" pButton pRipple type="button"
                    [disabled]="receivingSession?.isGLP != true"> </button>
            </div>
            <div class="">
                <button id=" btnNoDocumenttoUpload" class="p-button-secondary" (click)="onNoDocumentUpload()"
                    label="No Document to Upload" pButton pRipple type="submit"> </button>
            </div>
            <div class="p-pl-md-2">
                <button id="btnAccept" [disabled]="!showSubmitButton()" label="Submit" pButton pRipple type="button"
                    (click)="onSubmitButtonClick()" icon="pi pi-angle-right" iconPos="right"> </button>
            </div>
        </div>
    </div>
</app-navigate>
<p-confirmDialog key="upload" header="Alert" [style]="{width: '50vw'}" icon="pi pi-exclamation-triangle">
</p-confirmDialog>