import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../core/modules/navigate/services/navigation.service';

@Component({
  selector: 'app-receiving',
  templateUrl: './receiving.component.html',
  styleUrls: ['./receiving.component.scss']
})
export class ReceivingComponent implements OnInit {

  constructor(private navService: NavigationService) { 
    
  }

  ngOnInit(): void {
  }

}
