import { GridOptions } from 'ag-grid-community';
import { Component, OnInit } from '@angular/core';
import { sortData } from 'src/app/helpers/grid-helpers';
import { map, switchMap, take, finalize } from 'rxjs/operators';
import { SampleUsesService } from '../../services/sampleuses-service/sampleuses.service';
import { PaginatorModel } from 'src/app/core/modules/ag-grid/components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';
import { MessageService } from 'src/app/shared/services/message-queue.service';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { SampleUseModel } from 'src/app/models/sampleuse.model';
import { ManageSampleusesActionsComponent } from './manage-sampleuses-actions/manage-sampleuses-actions.component';
import { EditSampleusesModalComponent } from './edit-sampleuses-modal/edit-sampleuses-modal.component';

@Component({
  selector: 'app-manage-sampleuses',
  templateUrl: './manage-sampleuses.component.html',
  styleUrls: ['./manage-sampleuses.component.scss']
})
export class ManageSampleusesComponent implements OnInit {

  private gridApi;
  private sampleUSe: SampleUseModel[];

  public gridOptions: GridOptions;
  public disableClearFilters: boolean = true;
  public paginatorModel: PaginatorModel;
  public hasFilterModelChanged: boolean = true;
  public frameworkComponents: any;
  public overlayNoRowsTemplate: string =
    '<span class="ag-overlay-loading-center">No sample uses found.</span>';

  constructor(
    private dialog: MatDialog,
    private readonly messageService: MessageService,
    private loadingService: LoadingService,
    private readonly sampleUsesService: SampleUsesService
  ) {
    this.frameworkComponents = {
      manageShippersActions: ManageSampleusesActionsComponent,
    };
  }

  columnDefs = [
    {
      headerName: 'Sample Use',
      field: 'name',
      headerTooltip: 'Sample Use',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.name;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      width: 100,
      cellRenderer: 'manageShippersActions',
      cellClass: ['button-edit', 'display-end'],
      cellRendererParams: { data: this },
    },
  ];

  ngOnInit(): void {
    this.paginatorModel = {
      totalRows: 0,
      totalPages: 0,
      pageSize: 25,
      currentPage: 1,
      leftArrowsDisabled: true,
      rightArrowsDisabled: true,
    };
    this.gridOptions = {};

    this.messageService.message
      .pipe(
        switchMap((data) => {
          if (data.data=='delete') {
            this.loadingService.show("Loading Sample Use...");
            return this.sampleUsesService.deleteSampleUSe(
              data.reference.sampleUseID
            );
          }
          else if (data.data=='edit') {
            this.loadingService.show("Loading Sample Use...");
            return this.sampleUsesService.editSamplesUse(
              {
                ...data.reference
              }
            );
          }
        }),
        switchMap(() => {
          return this.sampleUsesService.getSampleUses();
        }),
        map((sampleUses) => {
          this.bindGridData(sampleUses);
        })
      )
      .subscribe(()=>{
        this.loadingService.clearMessage();
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    this.loadingService.show("Loading Sample Uses...");
    this.sampleUsesService
      .getSampleUses()
      .pipe(
        take(1),
        map((sampleUses) => {
          this.bindGridData(sampleUses);
        }),
        finalize(() => {
          this.loadingService.clearMessage();
        })
      )
      .subscribe();
  }

  onResizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  updateCurrentPage(): void {
    this.paginatorModel.currentPage = 1;
    this.paginatorModel.leftArrowsDisabled = true;
    if (this.paginatorModel.totalPages > 1) {
      this.paginatorModel.rightArrowsDisabled = false;
    }
  }

  handlePaginatorCommand(event: number): void {
    switch (event) {
      case 1:
        this.gridApi.paginationGoToFirstPage();
        break;
      case 2:
        this.gridApi.paginationGoToPreviousPage();
        break;
      case 3:
        this.gridApi.paginationGoToNextPage();
        break;
      case 4:
        this.gridApi.paginationGoToLastPage();
        break;
      default:
        this.gridApi.paginationSetPageSize(event);
        this.gridApi.paginationGoToFirstPage();
        break;
    }
  }

  setDataSource(returnedData) {
    this.gridApi.purgeInfiniteCache();
    this.bindGridData(returnedData);
  }

  onAddSampleUse(){
    this.dialog
      .open(EditSampleusesModalComponent, { width: '414px', data: null })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.loadingService.show("Loading Sample Uses...");
          this.sampleUsesService.editSamplesUse(
            {
              ...dialogResult
            }).pipe(
            switchMap(() => {
              return this.sampleUsesService.getSampleUses();
            }),
            map((sampleUses) => {
              this.bindGridData(sampleUses);
            })
          ).subscribe(_=>{
            this.loadingService.clearMessage();
          });
        }
      });
  }

  private bindGridData(data): void {
    var dataSource = {
      rowCount: null,
      getRows: function (params) {
        setTimeout(function () {
          var dataAfterSorting = sortData(params.sortModel, data);
          var rowsThisPage = dataAfterSorting.slice(
            params.startRow,
            params.endRow
          );
          var lastRow = dataAfterSorting.length;
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      },
    };

    this.paginatorModel.totalRows = data.length;
    this.paginatorModel.totalPages = Math.ceil(
      data.length / this.paginatorModel.pageSize
    );

    if (this.paginatorModel.totalRows == 0) {
      this.gridApi.setDatasource(dataSource);
      this.gridApi.showNoRowsOverlay();
    } else {
      this.updateCurrentPage();
      this.gridApi.hideOverlay();
      this.gridApi.setDatasource(dataSource);
    }
  }
}
