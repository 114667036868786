import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { MessageService } from '../../../shared/services/message-queue.service';
import { CancellationReasonsModalComponent } from '../../cancellation-reasons-modal/cancellation-reasons-modal.component';
import { of } from 'rxjs';
import { tap, switchMap, catchError, finalize } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { CancellationReasonsService } from 'src/app/services/cancellation-reasons/cancellation-reasons.service';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';

@Component({
  selector: 'app-edit-cancellation-reasons',
  templateUrl: './edit-cancellation-reasons.component.html'
})

export class EditCancellationReasonsComponent implements ICellRendererAngularComp {

  private params: any;
  constructor(private dialog: MatDialog,
    private readonly messageService: MessageService,
    private cancellationReasonsService: CancellationReasonsService,
    private toastr: ResponseHandlingService,
    private loadingService: LoadingService) { }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  async onEdit() {
    this.dialog.open(CancellationReasonsModalComponent, {
      height: '410px',
      width: '750px',
      data: this.params.data,
      disableClose: true
    }).afterClosed().subscribe((res: boolean) => {
      if (!res) { return; }
      this.messageService.message.next({});
    });
  }

  canBeDeleted(): boolean {
    return this.params.data.allowDelete;
  }

  async onDelete() {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '325px',
        height: '180px',
        data: {
          title: 'Delete',
          message:
            'Are you sure you want to delete this item? This action cannot be undone.',
          useDangerConfirm: true,
          cancelButtonLabel: 'Cancel',
          confirmButtonLabel: 'Delete',
          showCancel: true,
        },
      })
      .afterClosed().pipe(
        tap((a) => {
          console.log(a)
        }),
        switchMap(isSuccess => {
          if (isSuccess) {
            this.loadingService.show('Deleting...');
            return this.cancellationReasonsService.deleteCancellationReason(this.params.data.reasonID);
          }
          else
            return of(null);
        }),
        catchError(err => {
          this.loadingService.clearMessage();
          this.toastr.showError('Could not delete cancellation reason');
          return (err);
        }),
        switchMap(err => {
          if (err && err == 'Cancellation reason deleted successfully.') {
            this.toastr.showSuccess('Cancellation reason deleted successfully.');
          }

          this.messageService.message.next({});
          return of(err);
        }),
        finalize(() => this.loadingService.clearMessage())
      ).subscribe();
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void { }
}