<div [class]="styleClass" [ngStyle]="style" [ngClass]="'p-breadcrumb p-component'" style="display: flex; justify-content: space-between;">
    <ul>
        <li [class]="home.styleClass" [ngClass]="{'p-breadcrumb-home': true, 'p-disabled':home.disabled}"
            [ngStyle]="home.style" *ngIf="home" pTooltip [tooltipOptions]="home.tooltipOptions">
            <a *ngIf="!home.routerLink" [attr.aria-label]="homeAriaLabel" [href]="home.url ? home.url : null"
                class="p-menuitem-link" (click)="itemClick($event, home)" [attr.target]="home.target"
                [attr.title]="home.title" [attr.id]="home.id" [attr.tabindex]="home.disabled ? null : '0'">
                <span *ngIf="home.icon" class="p-menuitem-icon" [ngClass]="home.icon||'pi pi-home'"></span>
                <ng-container *ngIf="home.label">
                    <span *ngIf="home.escape !== false; else htmlHomeLabel"
                        class="p-menuitem-text">{{home.label}}</span>
                    <ng-template #htmlHomeLabel><span class="p-menuitem-text" [innerHTML]="home.label"></span>
                    </ng-template>
                </ng-container>
            </a>
            <a *ngIf="home.routerLink" [routerLink]="home.routerLink" [attr.aria-label]="homeAriaLabel"
                [queryParams]="home.queryParams" [routerLinkActive]="'p-menuitem-link-active'"
                [routerLinkActiveOptions]="home.routerLinkActiveOptions||{exact:false}" class="p-menuitem-link"
                (click)="itemClick($event, home)" [attr.target]="home.target" [attr.title]="home.title"
                [attr.id]="home.id" [attr.tabindex]="home.disabled ? null : '0'" [fragment]="home.fragment"
                [queryParamsHandling]="home.queryParamsHandling" [preserveFragment]="home.preserveFragment"
                [skipLocationChange]="home.skipLocationChange" [replaceUrl]="home.replaceUrl" [state]="home.state">
                <span *ngIf="home.icon" class="p-menuitem-icon" [ngClass]="home.icon||'pi pi-home'"></span>
                <ng-container *ngIf="home.label">
                    <span *ngIf="home.escape !== false; else htmlHomeRouteLabel"
                        class="p-menuitem-text">{{home.label}}</span>
                    <ng-template #htmlHomeRouteLabel><span class="p-menuitem-text" [innerHTML]="home.label"></span>
                    </ng-template>
                </ng-container>
            </a>
        </li>
        <li class="p-breadcrumb-chevron pi pi-chevron-right" *ngIf="model&&home"></li>
        <ng-template ngFor let-item let-index="index" let-end="last" [ngForOf]="model">
            <li [class]="item.styleClass" [ngStyle]="item.style" [ngClass]="{'p-disabled':item.disabled}">
                <a *ngIf="!item.routerLink" class="p-menuitem-link" (click)="itemClick($event, item)" pTooltip
                    [tooltipOptions]="item.tooltipOptions" [attr.target]="item.target" [attr.title]="item.title"
                    [attr.id]="item.id" [attr.tabindex]="item.disabled ? null : '0'" [routerLink]="item.url">
                    <span *ngIf="item.icon" class="p-menuitem-icon" [ngClass]="item.icon"></span>
                    <ng-container *ngIf="item.label">
                        <span *ngIf="item.escape !== false; else htmlLabel"
                            class="p-menuitem-text">{{item.label}}</span>
                        <ng-template #htmlLabel><span class="p-menuitem-text" [innerHTML]="item.label"></span>
                        </ng-template>
                    </ng-container>
                </a>
                <!-- <a *ngIf="item.routerLink" [routerLink]="item.routerLink" [queryParams]="item.queryParams"
                    [routerLinkActive]="'p-menuitem-link-active'"
                    [routerLinkActiveOptions]="item.routerLinkActiveOptions||{exact:false}" class="p-menuitem-link"
                    (click)="itemClick($event, item)" [attr.target]="item.target" [attr.title]="item.title"
                    [attr.id]="item.id" [attr.tabindex]="item.disabled ? null : '0'" [fragment]="item.fragment"
                    [queryParamsHandling]="item.queryParamsHandling" [preserveFragment]="item.preserveFragment"
                    [skipLocationChange]="item.skipLocationChange" [replaceUrl]="item.replaceUrl" [state]="item.state">
                    <span *ngIf="item.icon" class="p-menuitem-icon" [ngClass]="item.icon"></span>
                    <ng-container *ngIf="item.label">
                        <span *ngIf="item.escape !== false; else htmlRouteLabel"
                            class="p-menuitem-text">{{item.label}}</span>
                        <ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="item.label"></span>
                        </ng-template>
                    </ng-container>
                </a> -->
            </li>
            <li class="p-breadcrumb-chevron pi pi-chevron-right" *ngIf="model&&home&&index<model.length-1"></li>
        </ng-template>
    </ul>
    <ul>
        <li *ngIf="breadcrumbMetadata">Selected Warehouse: <span style="font-weight: bold;">{{breadcrumbMetadata.itemDesc}}</span></li>
    </ul>
</div>