import { Injectable } from '@angular/core';
import { AddressViewModel } from '../../models/address.model';
import { MaterialModel, MaterialViewModel } from '../../models/material.model';
import { ReceivingStateViewModel, SessionModel } from '../../models/session.model';
import { SupplierModel, SupplierViewModel } from '../../models/supplier.model';
import { SDSConsumer } from '../../models/view-models/status-watcher.model';
import { ValidationViewModel } from '../../models/view-models/validation-view.model';
import { DataEntryViewModel } from '../../models/view-models/dataentry-view.model';
import { IMaterialDataAdapter } from './material-data-adapter';

@Injectable()
export class MaterialDataAdapterService {
  constructor(private dataConverter: IMaterialDataAdapter) {

  }

  toMaterialViewModel(model: MaterialModel): Partial<MaterialViewModel> {
    return this.dataConverter.toMaterialViewModel(model);
  }

  toSupplierViewModel(model: MaterialModel): SupplierViewModel {
    return this.dataConverter.toSupplierViewModel(model);
  }

  toSupplierViewModelFromLot(model: MaterialModel): SupplierViewModel {
    return this.dataConverter.toSupplierViewModelFromLot(model);
  }

  toAddressViewModel(model: MaterialModel): AddressViewModel {
    return this.dataConverter.toAddressViewModel(model);
  }

  loadSuppliers(model: MaterialModel): Promise<SupplierModel[]> {
    return this.dataConverter.loadSuppliers(model);
  }

  ToMaterialViewModelFromLot(model: MaterialViewModel): MaterialViewModel {
    return this.dataConverter.ToMaterialViewModelFromLot(model);
  }

  getSDSData(model: MaterialViewModel, statusWatcher: SDSConsumer): Promise<MaterialViewModel> {
    return this.dataConverter.getSDSData(model, statusWatcher);
  }

  toValidationViewModel(model: ReceivingStateViewModel): ValidationViewModel {
    return this.dataConverter.toValidationViewModel(model);
  }

  toDataEntryViewModel(model: ReceivingStateViewModel): DataEntryViewModel {
    return this.dataConverter.toDataEntryViewModel(model);
  }

  getMaterial(model: MaterialViewModel): Promise<MaterialViewModel> {
    return this.dataConverter.getMaterial(model);
  }

  getSessionToMaterialModel(model: SessionModel): Partial<MaterialViewModel> {
    return this.dataConverter.getSessionToMaterialModel(model);
  }

}
