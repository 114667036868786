<div class="flex-grid">
    <div class="col">
        <h4>Manage Users and Roles</h4>
    </div>
</div>
<div class="flex-grid" style="max-width:1200px;">
    <label for="roleSelectList">Role</label>
    <p-dropdown id="roleSelectList" [options]="roleSelectList" [(ngModel)]="selectedRole" (onChange)="roleSelectChange()"></p-dropdown>
    <a class='add-user' (click)="addUserToRole()">Add User to a Role</a>   
</div>
<div class="flex-grid">
    <div class="col">
        <app-role-grid [selectedRole]="selectedRole" [rowData]="rowData" (userRemoved)="handleUserRemoved($event)"></app-role-grid>
    </div>
</div>
