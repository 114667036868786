import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { MessageService } from '../../../shared/services/message-queue.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-delete-printer-assignment',
  templateUrl: './delete-printer-assignment.component.html',
  styleUrls: ['./delete-printer-assignment.component.scss']
})
export class DeletePrinterAssignmentComponent implements ICellRendererAngularComp {

  private params: any;
  constructor(private dialog: MatDialog,
    private readonly messageService: MessageService) { }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void { }

  ngOnInit(): void {
  }

  onDeleteAssignment() {
    const confirmDialog = new ConfirmDialogModel(
      'Confirm Action',
      `This will remove the label/printer assignment. Are you sure?`,
      'OK',
      false,
      true,
      'Cancel'
    );
    this.dialog
      .open(ConfirmDialogComponent, { maxWidth: '400px', data: confirmDialog })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({ data: true, reference: this.params.data });
        }
      });
  }

}
