import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { ManageRolesService } from 'src/app/services/manage-roles-service/manage-roles.service';
import { PermissionService } from 'src/app/services/permission-service/permission-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';

@Component({
  selector: 'app-add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss'],
})
export class AddEditRoleComponent implements OnInit {
  public rolesForm: FormGroup;
  public buttonLabel: string;
  public permissions: any[] = [];
  private roleID: number;
  private roleData: any;
  sourcePermissions: any[] = [];
  targetPermissions: any[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private rolesService: ManageRolesService,
    private permissionService: PermissionService,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private manageRolesService: ManageRolesService,
    private responseHandler: ResponseHandlingService,
    private router: Router
  ) {
    this.roleID = this.activatedRoute.snapshot.params.id || 0;
    this.buttonLabel = "Edit";
  }

  ngOnInit(): void {
    this.buildForm();
    this.initData();
  }

  initData() {
    if (!this.roleID) {
      var data = {
        roleName: null,
        description: null
      };
      this.roleData = data;
      this.buttonLabel = "Add New";
      this.loadingService.show("Loading...");
      this.permissionService.getPermissions().subscribe(res => {
        this.sourcePermissions = res;
        this.cdr.markForCheck();
        this.patchForm();
      }, null, () => {
        this.loadingService.clearMessage()
      })
    }
    else {
      this.loadingService.show("Loading...");
      forkJoin([this.rolesService.getRolesByID(this.roleID), this.permissionService.getPermissions()]).subscribe(res => {
        this.roleData = res[0];
        this.sourcePermissions = res[1].filter(p => !this.roleData.assignedPermissions.some(a => a.permissionID === p.permissionID));
        this.cdr.markForCheck();
        this.patchForm();
      }, null, () => {
        this.loadingService.clearMessage()
      })
    }
  }

  private patchForm() {
    this.rolesForm.patchValue({
      roleName: this.roleData?.roleName,
      description: this.roleData?.description
    });
    if (!this.roleID) {
      this.targetPermissions = []
    } else {
      this.targetPermissions = this.roleData.assignedPermissions;
    }
  }

  onSubmit() {
    let result = {
      roleID: this.roleData.roleID,
      roleName: this.rolesForm.controls.roleName.value,
      description: this.rolesForm.controls.description.value,
      permissions: this.targetPermissions.map(p => p.permissionID)
    }
    this.loadingService.show("Loading...");
    this.manageRolesService.createRoles(result).subscribe(res => {
      this.responseHandler.showSuccess(`${this.roleID ? 'Role updated successfully' : 'Created new role successfully'}`);
      this.router.navigate(['manageroles']);
    }, null, () => {
      this.loadingService.clearMessage();
    })
  }

  onCancel() {
    this.router.navigate(['manageroles'])
  }

  private buildForm() {
    this.rolesForm = new FormGroup({
      roleName: new FormControl(null, [Validators.required, Validators.maxLength(80)]),
      description: new FormControl(null, [Validators.maxLength(255)]),
      permissions: new FormArray([]),
    });
  }

}
