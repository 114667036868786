import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-print-small-barcode-label',
  templateUrl: './print-small-barcode-label.component.html',
  styleUrls: ['./print-small-barcode-label.component.scss']
})
export class PrintSmallBarcodeLabelComponent implements ICellRendererAngularComp {

  public params: any;
  private gridApi: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
  }

  print() {
    this.gridApi.dispatchEvent({ type: 'printSmallBarcodelLabelButtonClicked', value: this.params.data });
  }

  refresh(): boolean {
    return false;
  }
}