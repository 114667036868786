import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { LabelPrinter } from 'src/app/models/label-printer';
import { DecantService } from 'src/app/services/decant-service/decant-service';
import { PrinterAssignmentsService } from 'src/app/services/printer-assignments/printer-assignments.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { PrinterAssignment } from 'src/app/models/printer-assignments';
import { LabelDesign } from 'src/app/models/label-design';
import { DecantStation } from 'src/app/models/decant-models';

@Component({
  selector: 'app-printer-assignments-modal',
  templateUrl: './printer-assignments-modal.component.html',
  styleUrls: ['./printer-assignments-modal.component.scss']
})
export class PrinterAssignmentsModalComponent implements OnInit {

  printerAssignmentForm: FormGroup;
  labelDesigns: LabelDesign[];
  stations: DecantStation[];
  labelPrinters: LabelPrinter[];

  constructor(
    private printerAssignmentsService: PrinterAssignmentsService,
    private decantService: DecantService,
    private toastr: ResponseHandlingService,
    private dialogRef: MatDialogRef<PrinterAssignmentsModalComponent>,
    private dialog: MatDialog,
    private loader: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.buildForm();
    this.getData();
  }

  buildForm() {
    this.printerAssignmentForm = new FormGroup({
      labelDesign: new FormControl(null, Validators.required),
      station: new FormControl(null),
      printer: new FormControl(null, Validators.required)
    });
  }

  getData() {
    const multipleApiSources = [
      this.printerAssignmentsService.getLabelDesigns(),
      this.decantService.getDecantStations(),
      this.printerAssignmentsService.getLabelPrinters()
    ];

    forkJoin(multipleApiSources).subscribe((resp: any) => {
      this.labelDesigns = resp[0];
      this.stations = resp[1];
      this.labelPrinters = resp[2];
    },
      () => this.toastr.showError('An error occured. Try again later')
    );
  }

  get fc() {
    return this.printerAssignmentForm.controls;
  }

  get fv() {
    return this.printerAssignmentForm.value;
  }

  cancel() {
    if (this.printerAssignmentForm.pristine) {
      this.dialogRef.close();
    } else {
      const dialogData = new ConfirmDialogModel("Unsaved Data", `There are changes that are not saved.  Do you wish to proceed?`, "Cancel", false, true, "Discard Changes");
      this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        disableClose: true,
        data: dialogData
      }).afterClosed().subscribe(dialogResult => {
        if (!dialogResult)
          this.dialogRef.close();
      });
    }
  }

  save() {
    this.loader.show();

    //check if the assignment already exists
    const existingAssignments = this.data.printerAssignments.printerAssignments;
    const assignmentExists = existingAssignments.some(x => x.labelDesignID == this.printerAssignmentForm.value.labelDesign.labelDesignID
      && x.stationID == this.printerAssignmentForm.value.station?.stationID);
    if(assignmentExists) {
      if (!this.printerAssignmentForm.value.station)
        this.toastr.showError("Default printer already assigned for this label design")
      else
        this.toastr.showError("Printer already assigned for the selected design and station.")
      this.loader.clearMessage();
      return;
    }

    const payload: PrinterAssignment = {
      labelPrinterAssignmentID: 0,
      labelDesignID: this.printerAssignmentForm.value.labelDesign.labelDesignID,
      stationID: this.printerAssignmentForm.value.station?.stationID,
      labelPrinterID: this.printerAssignmentForm.value.printer.labelPrinterID,
      labelDesignName: this.printerAssignmentForm.value.labelDesign.name,
      stationName: this.printerAssignmentForm.value.station?.name,
      labelPrinterName: this.printerAssignmentForm.value.printer.displayName
    };
    this.printerAssignmentsService.addPrinterAssignment(payload).subscribe({
      next: () => {
        this.toastr.showSuccess('New assignment added successfully');
        this.loader.clearMessage();
        this.dialogRef.close(true);
      },
      error: (err) => {
        if (err.error.Title == "Duplicate assignment found." && err.error.Status == 400) {
          this.toastr.showError("Duplicate assignment found.")
        } else {
          this.toastr.showError("An error occurred. Please try again later!");
          this.printerAssignmentForm.markAsDirty();
        }
        this.loader.clearMessage();
      }
    });
  }
}
