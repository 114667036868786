import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignButtonRenderer } from './components/ag-grid-cell-renderers/assign-button-renderer.component';
import { PrintButtonRenderer } from './components/ag-grid-cell-renderers/print-button-renderer.component';
import { PrintGLPLabelButtonRenderer } from './components/ag-grid-cell-renderers/print-glp-label-button-renderer.component';
import { PrintAddressLabelButtonRenderer } from './components/ag-grid-cell-renderers/print-address-label-button-renderer.component';
import { SelectLotButtonRenderer } from './components/ag-grid-cell-renderers/select-lot-button-renderer.component';
import { EditButtonRenderer } from './components/ag-grid-cell-renderers/edit-button-renderer.component';
import { ChoosenLotsActionRenderer } from './components/ag-grid-cell-renderers/choosen-lots-action-renderer.component';
import { DeleteButtonRenderer } from './components/ag-grid-cell-renderers/delete-button-renderer.component';
import { MoveButtonRenderer } from './components/ag-grid-cell-renderers/move-button-renderer.component';
import { RequestNumberClickableTextCellRenderer } from './components/ag-grid-cell-renderers/request-number-clickable-text-renderer.component';
import { CustomTooltip } from './components/ag-grid-cell-renderers/CustomTooltip.component';
import { CancelButtonRenderer } from './components/ag-grid-cell-renderers/cancel.button.render.component';
import { CheckBoxEditorRenderer } from './components/ag-grid-cell-renderers/checkbox.editor.renderer.component';
import { PillRenderer } from './components/ag-grid-cell-renderers/pill.render.component';
import { ContainerNumberDropdownRenderer } from './components/ag-grid-cell-renderers/container-number-dropdown-renderer.component';
import { InputCellRenderer } from './components/ag-grid-cell-renderers/input-cell-renderer.component';
import { AgGridCustomPaginatorComponent } from './components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';
import { LabelInfoButtonRenderer } from './components/ag-grid-cell-renderers/label-info-button-renderer.component';
import { PrintVialLabelButtonRenderer } from './components/ag-grid-cell-renderers/print-vial-label-button-renderer/print-vial-label-button-renderer.component';
import { PrintLabelButtonRendererComponent } from './components/ag-grid-cell-renderers/print-label-button-renderer/print-label-button-renderer.component';
import { AgGridNumericEditorComponent } from './components/ag-grid-numeric-editor/ag-grid-numeric-editor.component';
import { PackageDropdownRenderer } from './components/ag-grid-cell-renderers/package-dropdown-renderer.component';
import { AgGridLotSelectionNumericEditorComponent } from './components/ag-grid-lot-selection-numeric-editor/ag-grid-lot-selection-numeric-editor.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { FieldsetModule } from 'primeng/fieldset';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { FileUploadModule } from 'primeng/fileupload';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from '../../../shared/shared.module';
import { PrintSmallBarcodeLabelComponent } from './components/ag-grid-cell-renderers/print-small-barcode-label/print-small-barcode-label.component';

@NgModule({
  declarations: [
    AssignButtonRenderer,
    PrintButtonRenderer,
    PrintGLPLabelButtonRenderer,
    PrintAddressLabelButtonRenderer,
    SelectLotButtonRenderer,
    EditButtonRenderer,
    ChoosenLotsActionRenderer,
    DeleteButtonRenderer,
    MoveButtonRenderer,
    RequestNumberClickableTextCellRenderer,
    CheckBoxEditorRenderer,
    AgGridNumericEditorComponent,
    CancelButtonRenderer,
    PillRenderer,
    ContainerNumberDropdownRenderer,
    InputCellRenderer,
    PackageDropdownRenderer,
    PrintVialLabelButtonRenderer,
    AgGridLotSelectionNumericEditorComponent,
    PrintLabelButtonRendererComponent,
    AgGridCustomPaginatorComponent,
    PrintSmallBarcodeLabelComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AgGridModule.withComponents([AssignButtonRenderer, LabelInfoButtonRenderer, PrintButtonRenderer, PrintAddressLabelButtonRenderer, SelectLotButtonRenderer, ChoosenLotsActionRenderer, DeleteButtonRenderer, PrintGLPLabelButtonRenderer, AgGridNumericEditorComponent, AgGridLotSelectionNumericEditorComponent, MoveButtonRenderer, ContainerNumberDropdownRenderer, InputCellRenderer, PackageDropdownRenderer, RequestNumberClickableTextCellRenderer, CustomTooltip]),
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTooltipModule,
    MatIconModule,
    MatExpansionModule,
    MatButtonToggleModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    DialogModule,
    MultiSelectModule,
    FieldsetModule,
    AccordionModule,
    FileUploadModule,
    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    ToastModule,
    TooltipModule,
    AutoCompleteModule,
    SharedModule,
  ],
  exports: [
    AssignButtonRenderer,
    PrintButtonRenderer,
    PrintGLPLabelButtonRenderer,
    PrintAddressLabelButtonRenderer,
    SelectLotButtonRenderer,
    ChoosenLotsActionRenderer,
    DeleteButtonRenderer,
    MoveButtonRenderer,
    RequestNumberClickableTextCellRenderer,
    CheckBoxEditorRenderer,
    AgGridNumericEditorComponent,
    CancelButtonRenderer,
    PillRenderer,
    ContainerNumberDropdownRenderer,
    InputCellRenderer,
    PackageDropdownRenderer,
    PrintVialLabelButtonRenderer,
    AgGridLotSelectionNumericEditorComponent,
    PrintLabelButtonRendererComponent,
    AgGridCustomPaginatorComponent,
    AgGridModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTooltipModule,
    MatIconModule,
    MatExpansionModule,
    MatButtonToggleModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    DialogModule,
    MultiSelectModule,
    FieldsetModule,
    AccordionModule,
    FileUploadModule,
    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    ToastModule,
    TooltipModule,
    AutoCompleteModule,
  ]
})
export class SharedAgGridModule { }
