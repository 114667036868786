<div class="flex-grid">
    <div class="col">
        <h4>Request Coordination - Completed Requests</h4>
    </div>
</div>

<app-order-filters #appFilterOrder (modelChange)="handleFilterUpdate($event)"></app-order-filters>

<div class="spacer"></div>
<div class="p-grid p-justify-center p-mt-2">
    <button id="search-request-order-complete" class="submit p-mr-2" mat-raised-button color="cor-primary" [disabled]='hasFilterModelChanged'
        (click)='searchButtonClicked()'>Search Requests</button>
    <button id="clear-filters-order-complete" class="submit" mat-raised-button color="cor-primary" [disabled]="disableClearFilters"
        (click)='onClearFilters()'>Clear Filters</button>
</div>

<app-ag-grid-custom-paginator (paginatorCommand)="handlePaginatorCommand($event)" [paginatorModel]="paginatorModel"
    [showPageSizes]="true"></app-ag-grid-custom-paginator>

<ag-grid-angular *ngIf="isLoaded" style="width:100%; height:540px;" class="ag-theme-alpine" rowModelType="infinite" domLayout="normal"
    tooltipShowDelay="0" suppressCellSelection="true" alwaysShowVerticalScroll="true" [pagination]="true"
    [suppressPaginationPanel]="true" [paginationPageSize]="25" [cacheOverflowSize]="2"
    [maxConcurrentDatasourceRequests]="2" [infiniteInitialRowCount]="1" [columnDefs]="columnDefs" [cacheBlockSize]="100"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [frameworkComponents]="frameworkComponents" (cellValueChanged)="setDataSource($event)"
    (cellClicked)="handleCellClicked($event)"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="resizeGrid($event)"
    (sortChanged)="updateCurrentPage()">
</ag-grid-angular>

<app-ag-grid-custom-paginator (paginatorCommand)="handlePaginatorCommand($event)" [paginatorModel]="paginatorModel"
    [showPageSizes]="false"></app-ag-grid-custom-paginator>