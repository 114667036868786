import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { AUTH_SERVICE_TOKEN } from '@corteva-research/ngx-components-core';

import { MsalAuthService } from '../auth-service/auth-service.service';
import { PermissionService } from '../permission-service/permission-service';

@Injectable({
  providedIn: 'root'
})

export class RouteGuardService implements CanActivate {

  constructor(@Inject(AUTH_SERVICE_TOKEN) public auth: MsalAuthService, public router: Router, public permissionService: PermissionService) { }

  public canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const expectedPermission = route.data.expectedPermission;
    if (!this.auth.isLoggedIn()) {
      const url = 'home';
      const tree: UrlTree = this.router.parseUrl(url);
      return tree;
    }
    else if (!this.permissionService.validateUserPermission(expectedPermission)) {
      const url = 'unauthorized';
      const tree: UrlTree = this.router.parseUrl(url);
      return tree;
    }
    return true;
  }
}