import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { SessionModel } from '../../models/session.model';
import { ReceivingStateService } from '../../services/receiving-state.service';

@Component({
  selector: 'app-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.scss']
})
export class PageheaderComponent implements OnInit {
  receivingSession: SessionModel;
  private stateSubscription: Subscription;
  @Input() displayMaterialName:boolean = true;

  constructor(private receivingStateService: ReceivingStateService) {
  }

  ngOnInit() {
    this.stateSubscription = this.receivingStateService.getReceivingSession().subscribe(async session => {
      if (session!== null || session !== undefined) {
        this.receivingSession = session;
      }
    });
  }
}
