import { Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
// import { take } from 'rxjs/operators';
import { DecantStation, Scale } from 'src/app/models/decant-models';
import { InventoryItem } from 'src/app/models/inventory-models';
import { OrderItemContainerModel } from 'src/app/models/order-item-container-model';
import { UpdateContainerRequest } from 'src/app/models/update-container-model';
// import { SignalREventType } from 'src/app/receiving/models/signalR.model';
// import { UpdateWeightRequest } from 'src/app/receiving/models/weighing.model';
// import { SignalRScaleServiceSubscription } from 'src/app/receiving/services/signalr-subscription.service';
// import { HUB_SUBS_SERVICE_TOKEN, SignalRService } from 'src/app/receiving/services/signalr.service';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
// import { StationService } from 'src/app/services/station/station.service';

@Component({
  selector: 'app-scale-control',
  templateUrl: './scale-control.component.html',
  styleUrls: ['./scale-control.component.scss']
})
export class ScaleControlComponent implements OnInit, OnDestroy, OnChanges {

  @Input() mode: string;
  @Input() decantStation: DecantStation;
  @Input() selectedContainer: OrderItemContainerModel;
  @Input() isReweighMode: boolean;
  @Input() selectedInventoryItem: InventoryItem;
  @Input() unitOfMeasure: string;
  @Output() weightCaptured: EventEmitter<boolean> = new EventEmitter();
  @Input() isTestMode: boolean = false;
  isCaptureClick: boolean = false;

  private subscriptions = new Subscription();

  public uom: string;
  public readyToDisplay: boolean;
  public overageAmount: number;
  public isFirstVisit: boolean = true;
  public reweighReady: boolean = false;
  // private signalRServiceSubscription: Subscription;

  constructor(private orderService: OrderService,
    // private stationService: StationService,
    // private signalRService: SignalRService,
    private inventoryService: InventoryService,
    // private ngZone: NgZone,
    private responseHandler: ResponseHandlingService) {
  }

  ngOnInit(): void {
    this.readyToDisplay = true;
    this.overageAmount = (this.selectedContainer?.amount * 0.03) + this.selectedContainer?.amount;

    // this.signalRServiceSubscription = this.signalRService.events.subscribe(event => {
    //   if (event.type == SignalREventType.UpdateWeight) {
    //     const updateWeightRequest: UpdateWeightRequest = event.data;
    //     const updatedScale = this.decantStation.scales.find(scale => scale.scaleID === updateWeightRequest.scaleId);
    //     if (updatedScale !== undefined && updatedScale !== null) {
    //       console.log("Reading from scale for scale is ", updateWeightRequest.scaleId, " with weight in KG is ", (+updateWeightRequest.weightKG).toFixed(7));
    //       this.ngZone.run(() => {
    //         updatedScale.weightKG = updateWeightRequest.weightKG;
    //       });
    //     }
    //   } else {
    //     console.log(`Event from signalRService: event.type:${event.type}, event.data: ${event.data}`);
    //   }
    // });
    // this.signalRService.startConnection();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.decantStation) {
      let weightSum = 0;
      this.decantStation.scales.forEach(scale => {
        weightSum += scale.weightKG;
      });
      this.isFirstVisit = (this.isFirstVisit && weightSum === 0) ?? false;
      this.reweighReady = ((this.isFirstVisit && weightSum === 0) || (!this.isFirstVisit)) ? true : false;
    }

    if (changes.selectedContainer) {
      this.overageAmount = (this.selectedContainer?.amount * 0.03) + this.selectedContainer?.amount;
    }
  }

  ngOnDestroy(): void {
    // this.signalRService.stopConnection();
    // if (this.signalRServiceSubscription) { this.signalRServiceSubscription.unsubscribe(); }
    this.subscriptions.unsubscribe();
  }

  // getStation() {
  //   this.stationService.retrieveStation(this.decantStation.stationID).pipe(take(1)).subscribe(station => {
  //     this.decantStation = station;
  //   });
  // }

  isCaptureWeightDisabled(scale: Scale): boolean {
    // Need to round all the numbers to a consistent number of decimal places
    // before determining if the capture button should be available. In watching
    // the scale data, there are cases in which we are getting a ridiculous number
    // of decimal places from the scale that could be causing the math to not work out.
    var roundingFactor: number = Math.pow(10, scale.decimalPlaces);
    var roundedWeight: number = Math.round(scale.weightKG * roundingFactor) / roundingFactor;
    var roundedContainerWeight: number = Math.round(this.selectedContainer?.amount * roundingFactor) / roundingFactor;
    var roundedOverageAmount: number = Math.round(this.overageAmount * roundingFactor) / roundingFactor;

    return (roundedWeight < roundedContainerWeight) || (roundedWeight > roundedOverageAmount) || this.isCaptureClick;
  }

  resetCaptureButton(): void {
    this.isCaptureClick = false;
  }

  onCaptureClick(scale: Scale) {
    this.isCaptureClick = true;
    if (this.isReweighMode) {
      this.subscriptions.add(this.inventoryService.updateInventoryItemForReweigh(this.selectedInventoryItem.itemID, scale.weightKG)
        .subscribe(result => {
          if (this.mode == 'Standalone') {
            this.isFirstVisit = true;
            this.reweighReady = false;
          }
          this.isCaptureClick = false;
          this.weightCaptured.emit(true);
          this.responseHandler.showSuccess(`Successfully Captured Weight`);
        },
          error => {
            this.isCaptureClick = false;
            this.responseHandler.showError(`Error: Bad Request. Weight: (` + scale.weightKG + `)`);
          }));
    } else {
      var request: UpdateContainerRequest = {
        actualInventoryCatalogID: this.selectedInventoryItem.catalogRecordID,
        actualLotNumber: this.selectedInventoryItem.lotNumber,
        actualAmount: scale.weightKG,
        actualExpirationDate: this.selectedInventoryItem.expirationDate,
        parentContainerID: this.selectedInventoryItem.itemID,
        actualReceivedDate: new Date(this.selectedInventoryItem.postDate),
        locationID: this.selectedInventoryItem.locationID,
        locationName: this.selectedInventoryItem.fullLocationName,
        actualManufacturerLotNumber: this.selectedInventoryItem.manufacturerLotNumber
      };
      this.subscriptions.add(this.orderService.updateContainer(this.selectedContainer.orderItemContainerID, request)
        .subscribe(result => {
          this.isCaptureClick = true;
          this.weightCaptured.emit(true);
          this.responseHandler.showSuccess(`Successfully Captured Weight`);
        },
          error => {
            this.isCaptureClick = false;
            this.responseHandler.showError(`Error: Bad Request`);
          }));
    }
  }

  formatAmount(amount: number) {
    if (amount < 1) {
      return amount * 1000;
    }
    return amount
  }
}
