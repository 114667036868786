import { Warehouse } from 'src/app/models/decant-models';
import { LocationTreeModel } from "src/app/models/locations-tree-model";
import { LocationModel } from 'src/app/receiving/models/location.model';
import { MessageService } from '../../services/message-queue.service';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-locations-tree-view',
  templateUrl: './locations-tree-view.component.html',
  styleUrls: ['./locations-tree-view.component.scss'],
  providers: [InventoryService],
})
export class LocationsTreeViewComponent implements OnInit, OnChanges {

  locations: LocationModel[];
  locationTree: LocationTreeModel[];
  selectedTree: LocationTreeModel[];

  @Input() selectedWarehouse: Warehouse;
  @Output() onLocationSelected: EventEmitter<any> = new EventEmitter();

  constructor(private readonly inventoryService: InventoryService, private readonly messageServiceQueue: MessageService) {
    this.locationTree = [];
  }

  ngOnInit(): void {
    this.init(this.selectedWarehouse);

    this.messageServiceQueue.message.subscribe((message)=>{
      if(message.action == 'clear'){
        this.selectedTree = [];
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedWarehouse){
      this.init(this.selectedWarehouse);
    }
  }

  onNodeChange() {
    this.onLocationSelected.emit(this.selectedTree);
  }

  private init(selectedWarehouse: Warehouse) {

    this.locations = [];
    this.locationTree = [];
    this.selectedTree = [];

    this.inventoryService
    .getLocations()
    .toPromise()
    .then((locations) => {
      this.locations = locations;
      locations
        .filter((l) => l.parentLocationID == 0 && l.warehouseID == selectedWarehouse?.itemID)
        .map((l) => {
          this.locationTree.push({
            key: l.locationID,
            label: l.locationName,
            data: l.locationName,
            icon: '',
            children: []
          } as LocationTreeModel);
        });

        this.locationTree.forEach((lTree) => {
          this.buildTree(lTree);
        });
    });
  }

  private buildTree(lTree: LocationTreeModel) {

    var children = this.locations.filter((l) => l.parentLocationID == lTree.key);

    children.forEach((lTreeChild) => {

      var child = {
        key: lTreeChild.locationID,
        label: lTreeChild.locationName,
        data: lTreeChild.locationName,
        icon: '',
        children: []
      } as LocationTreeModel;

      lTree.children.push(child);

      this.buildTree(child);
    });
  }

}
