import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [ScrollToTopComponent],
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    TooltipModule
  ],
  exports: [ScrollToTopComponent]
})
export class ScrollToTopModule { }
