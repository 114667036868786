<h5 class="p-mb-3">{{buttonLabel}} Option</h5>

<form [formGroup]="optionForm" (submit)="onSubmit()">
    <div class="p-grid p-mt-3 p-mb-4">
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Option Type:</div>
                <p-dropdown id="warehouses" placeholder="Select a option type" formControlName="optionTypeId" [options]="optionsList"
                    optionLabel="name" optionValue="optionTypeID" [style]="{ minWidth: '100%', width: '100%' }" autoWidth="false">
                </p-dropdown>
                <div *ngIf="optionForm.controls['optionTypeId'].invalid && (optionForm.controls['optionTypeId'].dirty || optionForm.controls['optionTypeId'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="optionForm.controls.optionTypeId.errors?.required">
                        Field required.
                    </small>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold">Option Name:</div>
                <input formControlName="name" pInputText type="text" style="width: 100%;"/>
                <div *ngIf="optionForm.controls['name'].invalid && (optionForm.controls['name'].dirty || optionForm.controls['name'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="optionForm.controls.name.errors?.required">
                        Field required.
                    </small>
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="optionForm.controls.name.hasError('maxlength')">
                        Value can not exceed 255 characters
                    </small>
                </div>
            </div>
        </div>
        <div class="p-col-12">        
            <div style="margin-bottom: 10px;">
                <div>Sort Order:</div>
                <input formControlName="sortOrder" pInputText type="text" numeric decimals="0" style="width: 100%;"/>
                <div *ngIf="optionForm.controls['sortOrder'].invalid && (optionForm.controls['sortOrder'].dirty || optionForm.controls['sortOrder'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="optionForm.controls.sortOrder.errors?.min">
                        Value greater than 0.
                    </small>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <span>Is Default ?</span>
            <mat-slide-toggle [style]="{'margin-left':'10px'}" formControlName="isDefault">
            </mat-slide-toggle>
        </div>     
        <mat-dialog-actions style="float: right" class="actions">
            <button style="margin-right: 15px;" mat-raised-button color="cor-default" mat-dialog-close>Cancel</button>
            <button mat-raised-button color="cor-primary" type="submit"
                [disabled]="!optionForm.valid">Save</button>
        </mat-dialog-actions>
    </div>
</form>