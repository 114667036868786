import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LockableComponent } from '../../models/has-unsaved-data';
import { TriggerService } from '../../core/services/trigger.services'
import { Router, ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/confirm-dialog/confirm-dialog.component';

@Injectable({ providedIn: 'root' })
export class CanDeativateGuardService implements CanDeactivate<any>  {

  dialogData = new ConfirmDialogModel("Unsaved Data", `There are changes that are not saved.  Do you wish to proceed?`, "Save Changes", false, true, "Discard Changes");

  constructor(private triggerService: TriggerService, public router: Router, public dialog: MatDialog) { }

  canDeactivate(component: LockableComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Observable<boolean> | boolean {

    if (this.router.getCurrentNavigation().extras.state ?? false)
      return of(true)
    
    if (component.canDeactivate && !component.canDeactivate()) {

      const dialogRef = this.dialog.open(ConfirmDialogComponent, { maxWidth: "400px", 
      disableClose: true, data: this.dialogData });
      return dialogRef.afterClosed().pipe(
        switchMap(response => {
          if (response !== undefined) {
            if (response) {
              this.triggerService.sendConfirmation({
                route: nextState.url,
                confirm: true,
              });
              return of(response);
            }
            else{
              return of(!response);
            }
          }
        })
      )
    }
    else{
      return of(true);
    }
  }
}