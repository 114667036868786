import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Order } from 'src/app/models/order-models';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';

@Component({
  selector: 'app-confirm-moving-order-modal',
  templateUrl: './confirm-moving-order-modal.component.html',
  styleUrls: ['./confirm-moving-order-modal.component.scss']
})
export class ConfirmMovingOrderModalComponent implements OnInit {

 
  transferNotes = '';
  order: Order;
  requestNumber: string;
  constructor(private toastr: ResponseHandlingService,
    public dialogRef: MatDialogRef<ConfirmMovingOrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.order = this.data?.order;
     }

  ngOnInit(): void {
    if(this.order)
    {
      if(this.order.requestNumber.includes('-2'))
      {
       this.requestNumber = this.order.requestNumber.replace('-2','')+'-#';
      } else{
       this.requestNumber = this.order.requestNumber+'-#';

      }
    }
  }
  
  confirm(): void {
   // this.toastr.showSuccess('Split Request added successfully'); 
    this.dialogRef.close(this.transferNotes);
  }

  cancel(): void {
    this.dialogRef.close();
  }


}
