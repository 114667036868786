import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-lot-check',
  templateUrl: './select-lot-check.component.html',
  styleUrls: ['./select-lot-check.component.scss']
})
export class SelectLotCheckComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SelectLotCheckComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
  }

}
