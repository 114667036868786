
export interface PrintStatusModel {
    currentLabel: number,
    totalLabels: number
    printState:PrintState
}

export enum PrintState {
    Iddle,
    Starting,
    InProgress,
    Finished,
    Aborted
}