import { of } from 'rxjs';
import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderItemModel } from 'src/app/models/order-item-model';
import { OrderItemAttachmentService } from 'src/app/services/order-item-attachments-service/order-tem-attachments.service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ActionRendererComponent } from './action-renderer/action-renderer.component';
import { GridOptions } from 'ag-grid-community';
import { ReceivingService } from 'src/app/receiving/services/receiving.service';
import { documentTypes } from 'src/app/receiving/models/docupload.model';
import { AttachmentService } from 'src/app/core/modules/attachments/services/attachment.service';
import { Language } from 'src/app/models/language.model';
import { HideShowComponent } from './hide-show/hide-show.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';

@Component({
  selector: 'app-stored-docs-modal',
  templateUrl: './stored-docs-modal.component.html',
  styleUrls: ['./stored-docs-modal.component.scss'],
})
export class StoredDocsModalComponent implements OnInit {
  defaultDocumentType: documentTypes = {
    documentTypeID: 0,
    name: 'All documents',
  };
  defaultLanguage: Language = {
    languageID: 0,
    name: 'All languages',
    isDefault: true,
    sortOrder: 0,
  };
  documentTypes: documentTypes[];
  selectedDocumentType: documentTypes;
  languages: Language[];
  selectedLanguage: Language;
  rowDataBack: any[] = [];
  rowData: any[] = [];
  columnDefs: any[] = [
    {
      headerName: 'Document Type',
      field: 'documentTypeName',
      width: 230,
      headerTooltip: 'Document Type',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.documentTypeName) {
          return params.data.documentTypeName;
        }
      },
      sortable: true,
    },
    {
      headerName: 'Language',
      field: 'languageName',
      headerTooltip: 'Language',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.languageName) {
          return params.data.languageName;
        }
      },
      sortable: true,
    },
    {
      headerName: 'Lot Number',
      field: 'productLotNumber',
      headerTooltip: 'Lot Number',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.productLotNumber) {
          return params.data.productLotNumber;
        }
      },
      sortable: true,
    },
    {
      headerName: 'File Name',
      field: 'originalFilename',
      width: 260,
      headerTooltip: 'File Name',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.originalFilename) {
          return params.data.originalFilename;
        }
      },
      sortable: true,
    },
    {
      headerName: 'Upload Date',
      field: 'created',
      valueGetter: (params) => {
        if (params.data == undefined || !params.data.created) {
          return '--';
        }
        return formatDate(params.data.created, 'd-MMM-yyyy', 'en-US');
      },
      headerTooltip: 'Upload Date',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.created) {
          return formatDate(params.data.created, 'd-MMM-yyyy', 'en-US');
        }
      },
      sortable: true,
    },
    {
      field: 'action',
      width: 320,
      headerName: '',
      cellRenderer: 'actionRenderer',
    },
    {
      field: 'action',
      width: 320,
      headerName: '',
      cellRenderer: 'hideShow',
    },
  ];
  orderItem: OrderItemModel;
  private gridApi;
  gridOptions = {
    getRowStyle: (params) => {
      if(!params.data.allowShareHide){
        return {'font-weight': 'bold'};
      }
      return null;
    }
  };
  frameworkComponents = {
    actionRenderer: ActionRendererComponent,
    hideShow: HideShowComponent,
  };

  constructor(
    public dialogRef: MatDialogRef<StoredDocsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderItemAttachmentService: OrderItemAttachmentService,
    private responseHandler: ResponseHandlingService,
    private dialog: MatDialog,
    private orderService: OrderService,
    private receivingService: ReceivingService,
    private attachmentService: AttachmentService,
    private toastr: ResponseHandlingService,
    private loadingService: LoadingService
  ) {
    this.orderItem = this.data.orderItem;
  }

  ngOnInit(): void {
    this.getData();
    this.receivingService.getDocumentTypes().then((resp) => {
      if (resp.length > 0) {
        resp.unshift(this.defaultDocumentType);
      }
      this.documentTypes = resp || [];
      this.selectedDocumentType = this.defaultDocumentType;
    });
    this.attachmentService.retrieveLanguages().subscribe((resp) => {
      if (resp.length > 0) {
        resp.unshift(this.defaultLanguage);
      }
      this.languages = resp || [];
      this.languages = [
        ...new Map(
          this.languages.map((item) => [item['languageID'], item])
        ).values(),
      ];
      this.selectedLanguage = this.defaultLanguage;
    });
  }

  getData() {
    this.orderService
      .getDocuments(this.orderItem.orderItemID)
      .subscribe((response) => {
        this.rowDataBack = response || [];
        this.rowData = response || [];
      });
  }

  onFilter() {
    this.rowData = this.filterResults();
  }

  filterResults(): any[] {
    if (
      this.selectedLanguage.languageID > 0 &&
      this.selectedDocumentType.documentTypeID > 0
    ) {
      return this.rowDataBack.filter(
        (x) =>
          x.documentTypeID == this.selectedDocumentType.documentTypeID &&
          x.languageID == this.selectedLanguage.languageID
      );
    }

    if (this.selectedDocumentType.documentTypeID > 0) {
      return this.rowDataBack.filter(
        (x) => x.documentTypeID == this.selectedDocumentType.documentTypeID
      );
    }

    if (this.selectedLanguage.languageID > 0) {
      return this.rowDataBack.filter(
        (x) => x.languageID == this.selectedLanguage.languageID
      );
    }
    return this.rowDataBack;
  }

  resetData() {
    this.rowData = this.rowDataBack;
    this.selectedDocumentType = this.defaultDocumentType;
    this.selectedLanguage = this.defaultLanguage;
  }

  resizeGrid(params) {
    params.api.sizeColumnsToFit();
  }

  gridReady(params) {
    this.gridApi = params.api;
    params.api.addEventListener('downloadEvent', (e) => {
      this.onDownload(e);
    });
    params.api.addEventListener('attachEvent', (e) => {
      this.attachToOrder(e);
    });
    params.api.addEventListener('shareEvent', (e) => {
      this.dialog
        .open(ConfirmDialogComponent, {
          width: '325px',
          height: '180px',
          data: {
            title: '',
            message:
              'Sharing this document will make it available for all users. Are you sure?',
            useDangerConfirm: false,
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Confirm',
            showCancel: true,
          },
        })
        .afterClosed()
        .pipe(
          switchMap((isSuccess) => {
            if (isSuccess) {
              this.orderService.shareDocument(this.orderItem.orderItemID, e.value.documentID).subscribe((resp) => {
                this.loadingService.show('Sharing...');
                this.toastr.showSuccess('Document successfully shared');
                this.getData();
                return of(true);
              },error => {
                this.loadingService.clearMessage();
                this.toastr.showError('Could not Share document');
              });
            } else return of(null);
          }),
          catchError((err) => {
            this.loadingService.clearMessage();
            this.toastr.showError('Could not shared document');
            return err;
          }),
          switchMap((err) => {
            if (err && err == 'Container deleted successfully.') {
              this.toastr.showSuccess('Document successfully shared');
            }
            return of(err);
          }),
          finalize(() => this.loadingService.clearMessage())
        )
        .subscribe();
    });
    params.api.addEventListener('hideEvent', (e) => {
      this.dialog
        .open(ConfirmDialogComponent, {
          width: '325px',
          height: '180px',
          data: {
            title: '',
            message:
              'You are hiding this document permanently. Are you sure?',
            useDangerConfirm: false,
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Confirm',
            showCancel: true,
          },
        })
        .afterClosed()
        .pipe(
          switchMap((isSuccess) => {
            if (isSuccess) {
              this.orderService.hideDocument(e.value.documentID).subscribe((resp) => {
                this.loadingService.show('Hiding...');
                this.toastr.showSuccess('Document successfully hidden');
                this.rowData = this.rowData.filter(_ => _.documentID !== e.value.documentID);
              },error => {
                this.loadingService.clearMessage();
                this.toastr.showError('Could not hide document');
              });
            } else return of(null);
          }),
          catchError((err) => {
            this.loadingService.clearMessage();
            this.toastr.showError('Could not hide document');
            return err;
          }),
          switchMap((err) => {
            if (err && err == 'Container deleted successfully.') {
              this.toastr.showSuccess('Document successfully hidden');
            }
            return of(err);
          }),
          finalize(() => this.loadingService.clearMessage())
        )
        .subscribe();
    });
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  onDownload(e) {
    this.orderService
      .viewDocument(e.value.data.documentID)
      .subscribe((response) => {
        if (!response.base64FileContents) {
          this.responseHandler.showInfo('File contents not found');
          return;
        }
        var link = document.createElement('a');
        link.href = `data:application/octet-stream;base64,${response.base64FileContents}`;
        link.download = response.originalFilename;
        link.click();
      });
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  attachToOrder(e) {
    let fileContents;
    if (e.value.data.originalFilename.split('.').pop() === 'docx') {
      fileContents = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${e.value.data.base64FileContents}`;
    } else if (e.value.data.originalFilename.split('.').pop() === 'pdf') {
      fileContents = `data:application/pdf;base64,${e.value.data.base64FileContents}`;
    }

    this.orderItemAttachmentService
      .attachDocument(this.orderItem.orderItemID, e.value.data.documentID)
      .subscribe(
        (result) => {
          this.responseHandler.showSuccess(
            `File "${e.value.data.originalFilename}" attached successfully`,
            5000
          );
          if (!result)
            this.responseHandler.showError(
              `Failed to fetch order item attachment details`,
              5000
            );
        },
        (error) => {
          this.responseHandler.showError(error.message, 5000);
        }
      );
  }

  close() {
    this.dialogRef.close();
  }
}
