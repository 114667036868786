import { AddressViewModel } from '../../models/address.model';
import { ExternalServiceType } from '../../models/enumerations';
import { MaterialModel, MaterialViewModel } from '../../models/material.model';
import { ReceivingStateViewModel, SessionModel } from '../../models/session.model';
import { SupplierModel, SupplierViewModel } from '../../models/supplier.model';
import { DataEntryViewModel } from '../../models/view-models/dataentry-view.model';
import { SDSConsumer } from '../../models/view-models/status-watcher.model';
import { ValidationViewModel } from '../../models/view-models/validation-view.model';
import { receivingConstants } from '../../receiving.constants';
import { ReceivingStateService } from '../receiving-state.service';
import { ReceivingService } from '../receiving.service';
import { IMaterialDataAdapter } from './material-data-adapter';
export class CoformulantDataAdapter implements IMaterialDataAdapter {

  constructor(private receivingService: ReceivingService, private receivingStateService: ReceivingStateService) {

  }
  toDataEntryViewModel(model: ReceivingStateViewModel): DataEntryViewModel {
    return {
      disablePeroxideForming: !!(model.material?.metadata?.IsPeroxideForming && model.material.peroxideForming),
      yearsToExpiration: receivingConstants.DefaultYearsToExpiration
    };
  }

  toSupplierViewModelFromLot(model: MaterialModel): SupplierViewModel {
    return this.toSupplierViewModel(model);
  }

  toValidationViewModel(model: ReceivingStateViewModel): ValidationViewModel {
    return {
      materialName: model.material?.materialName,
      alternateName: model.material?.alternateName,
      lotNumber: model.material?.lotNumber,
      isGLP: false,  //by default
      SDS: model.material?.SDS,
      COA: model.material?.COA,
      checkForSDS: true,  //by default
      shouldDisableGLPControl: true,  //by default
      supplier: model.supplier?.name,
      isLoading: false,
      address: model.address,
      sdsModel: {
        elink: '',
        properties: {}
      }
    }
  }

  ToMaterialViewModelFromLot(model: MaterialViewModel): MaterialViewModel {
    model.COA = false;
    model.SDS = false;
    model.checkForSDS = true;
    return model;
  }

  toAddressViewModel(model: MaterialModel): AddressViewModel {
    throw new Error('Method not implemented.');
  }
  toSupplierViewModel(model: MaterialModel): SupplierViewModel {
    throw new Error('Method not implemented.');
  }
  toMaterialViewModel(model: MaterialModel): MaterialViewModel {
    return null;
  }
  loadSuppliers(model: MaterialModel): Promise<SupplierModel[]> {
    return this.receivingService.getSuppliersByMaterial(model.materialId, ExternalServiceType.Coformulant);
  }

  getSDSData(model: MaterialViewModel, sdsWatcher: SDSConsumer): Promise<MaterialViewModel> {
    if (model.checkForSDS === true) {
      sdsWatcher.isLoading = true;
      return this.receivingService.getSDS(model.materialId, ExternalServiceType.Coformulant)
        .then(sds => {
          if (sds && sds.elink && sds.elink.length > 0) {
            const density = sds.properties?.density;
            model.sdsModel = sds;
            model.density = density?.value;
            model.specificGravity = sds.properties?.specificGravity;
            model.unitOfMeasureId = density?.units;
            model.SDS = true;
            sdsWatcher.SDS = true;
          }
          return Promise.resolve(model as MaterialViewModel);
        }).finally(() => {
          sdsWatcher.isLoading = false;
        });
    } else {
      return Promise.resolve(model as MaterialViewModel);
    }
  }

  getMaterial(model: MaterialViewModel): Promise<MaterialViewModel> {
    return this.receivingService.getMaterial(model.materialId, ExternalServiceType.Coformulant)
      .then(material => {
        if (material) {
          model.peroxideForming = Boolean(material.metadata?.IsPeroxideForming);
          model.metadata = material.metadata;
        }
        return Promise.resolve(model as MaterialViewModel);
      }).finally(() => {
      });
  }

  getSessionToMaterialModel(model: SessionModel): Partial<MaterialViewModel> {
    const result = (model as Partial<MaterialViewModel>);
    return result;
  }
}