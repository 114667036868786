import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService } from 'src/app/services/label-service/label-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';

@Component({
  selector: 'app-add-label',
  templateUrl: './add-label.component.html',
  styleUrls: ['./add-label.component.scss']
})
export class AddLabelComponent implements OnInit {

  types: any[] = [];
  languages: any[] = [];
  labelSizes: any[] = [];
  selectedType: any;
  selectedLanguage: any;
  selectedLabelSize: any;
  uploadedFile: File;
  isValidExtension: boolean;

  constructor(public dialogRef: MatDialogRef<AddLabelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private labelService: LabelService,
    private responseHandlingService: ResponseHandlingService) { }

  ngOnInit(): void {
    this.labelService.getLabelOptions().subscribe(response => {
      if (response) {
        this.languages = response.languages ? response.languages : [];
        this.labelSizes = response.sizes ? response.sizes : [];
        this.types = response.types ? response.types : [];
      }
    })
  }

  onFileUpload(event) {
    this.uploadedFile = event.target.files[0];
    const fileName = this.uploadedFile.name.split('.');
    if(fileName.length > 1) {
      const extension = fileName[fileName.length - 1];
      this.isValidExtension = extension == 'doc' || extension == 'docx';
    }
    else{
      this.isValidExtension = false;
    }
    
  }

  onLanguageShow(): void {
    this.dialogRef.updateSize('400px','500px');
  }

  onLanguageHide(): void {
    this.dialogRef.updateSize('400px','370px');
  }

  cancel() {
    this.dialogRef.close();
  }


  save() {
    if (this.data.rowData.some(x => x.typeID == this.selectedType.typeID
      && x.sizeID == this.selectedLabelSize.sizeID
      && x.languageID === this.selectedLanguage.languageID)) {
      this.responseHandlingService.showError('This Language/Type/Size combination already exists.  Please select another, or delete the existing before saving a new file.');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.uploadedFile);
    reader.onload = () => {
      const base64File = reader.result?.toString().split('base64,')[1];
      const payload = {
        "inventoryCatalogID": this.data.inventoryCatalogID,
        "typeID": this.selectedType.typeID,
        "sizeID": this.selectedLabelSize.sizeID,
        "languageID": this.selectedLanguage.languageID,
        "filename": this.uploadedFile.name,
        "base64FileContents": base64File
      }

      this.labelService.createLabel(payload).subscribe(result => {
        this.responseHandlingService.showSuccess("Created label successfully", 3000, 'Success');
        this.dialogRef.close(result);
      })
    }
  }
}
