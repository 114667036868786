import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { GridApi, GridOptions } from 'ag-grid-community';
import { OrderItemModel } from 'src/app/models/order-item-model';
import { Order, OrderItemEarmarksByCatalogIDResponse, OrderStatuses } from 'src/app/models/order-models';
import { OrderService } from 'src/app/services/order-service/order-service';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { PaginatorModel } from '../../core/modules/ag-grid/components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';
import { OrderItemAttachmentsComponent } from '../attachments/attachments.component';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { Subscription } from 'rxjs';
import { AttachmentEntity, CreateOrderItemAttachmentRequest, OrderItemAttachment, UpdateOrderItemAttachmentRequest } from 'src/app/models/attachment-models';
import { OrderItemAttachmentService } from 'src/app/services/order-item-attachments-service/order-tem-attachments.service';
import { ConfirmationService } from 'primeng/api';
import { AgGridAngular } from 'ag-grid-angular';
import { DownloadAttachmentService } from 'src/app/services/download-attachment-service/download-attachment.service';
import { Reservation, ReservationTypes, RetrieveCatalogItemWithConversionResponse } from 'src/app/models/inventory-models';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';

@Component({
  selector: 'app-order-item-detail',
  templateUrl: './order-item-detail.component.html',
  styleUrls: ['./order-item-detail.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class OrderItemDetailComponent implements OnInit, OnDestroy {

  public reservationColumnDefs: any;
  public reservationRowData: any[] = [];

  public earmarkColumnDefs: any;
  public earmarkRowData: any[] = [];

  public inventoryDetails: RetrieveCatalogItemWithConversionResponse;

  private subscriptions = new Subscription();
  private gridApi: GridApi;
  private reservationGridApi: GridApi;
  private earmarkGridApi: GridApi;
  public gridOptions: GridOptions;
  public paginatorModel: PaginatorModel;
  public isLoaded: boolean;
  public selectedNodes: any[] = [];
  public totalAmount = 0;
  public numberOfContainers = 0;
  public requestedLotNumber: any;
  private _orderItem: OrderItemModel;
  public columnDefs: any;
  public isInventorySaved: boolean = false;
  public disableSave: boolean = true;
  public rowData: any[] = [];
  public loggedInUser: any;
  public isLoginUserCoordinator: boolean = false;

  @ViewChild('grid') grid: AgGridAngular;

  @Output() saveChanges: EventEmitter<any> = new EventEmitter();

  private _order: Order;
  @Input()
  public set order(value: Order) {
    this._order = value;
  }
  public get order(): Order {
    return this._order;
  }

  @Input()
  public set orderItem(value: OrderItemModel) {
    this._orderItem = value;
    this.attachmentEntity = {
      id: value.orderItemID,
      name: value.containerLabelFmt,
      attachments: value.attachments ? value.attachments : [],
    }
    if (value) {
      this.isInventorySaved = value?.inventoryAssignments?.length > 0;
      if (this.order?.orderStatusID === OrderStatuses.Complete || this.order?.orderStatusID === OrderStatuses.Shipping)
        this.setContainersDataToGrid();
      else
        this.setInventoryDataToGrid();
      this.getEarmarkData();
      this.calculateTotalAmount(this._orderItem.containers);
      this.calculateNumberOfContainers(this._orderItem.containers);
      this.setRequestedLotNumber();
      this.getReservationInfo();
    }
  }
  public get orderItem(): OrderItemModel {
    return this._orderItem;
  }

  attachmentEntity: AttachmentEntity;

  @ViewChild('itemAttachments') itemAttachmentsInstance: OrderItemAttachmentsComponent;

  constructor(public dialog: MatDialog, private orderService: OrderService,
    private orderItemAttachmentService: OrderItemAttachmentService, private confirmationService: ConfirmationService,
    private responseHandler: ResponseHandlingService, private inventoryService: InventoryService,
    private downloadAttachmentService: DownloadAttachmentService, private weightFormatKGPipe: WeightFormatKGPipe) {
  }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(localStorage.getItem("authUserInfo"));
    this.isLoginUserCoordinator = this.loggedInUser.givenName === this.order?.assignedToFirstName && this.loggedInUser.surName === this.order?.assignedToLastName;

    this.setColumnDefs();
    this.setEarmarkColumnDefs();

    this.reservationColumnDefs = [
      {
        headerName: 'Indy Physical Inventory',
        field: 'indyPhysicalInventory',
        valueFormatter: params => `${parseFloat(params.data.indyPhysicalInventory.toFixed(7))?.toString()} ${this.orderItem?.unitOfMeasureDesc}`
      },
      {
        headerName: 'Total Reserved',
        field: 'totalReserved',
        valueFormatter: params => `${parseFloat(params.data.totalReserved.toFixed(7))?.toString()} ${this.orderItem?.unitOfMeasureDesc}`
      },
      {
        headerName: 'Indy Reserved',
        field: 'indyReserved',
        valueFormatter: params => `${parseFloat(params.data.indyReserved.toFixed(7))?.toString()} ${this.orderItem?.unitOfMeasureDesc}`
      },
      {
        headerName: 'SGS Reserved',
        field: 'SGSReserved',
        valueFormatter: params => `${parseFloat(params.data.SGSReserved.toFixed(7))?.toString()} ${this.orderItem?.unitOfMeasureDesc}`
      },
    ];
  }

  setEarmarkColumnDefs() {
    const that = this;
    this.earmarkColumnDefs = [
      {
        headerName: 'Request',
        field: 'requestNumber',
      },
      {
        headerName: 'Amount',
        field: 'amountTotalConverted',
        valueGetter: function (params) {
          if (params.data !== undefined && that.orderItem.unitOfMeasureDesc) {
            return that.weightFormatKGPipe.transform(params.data.amountTotalConverted) + ' ' + that.orderItem.unitOfMeasureDesc;
          }
        }
      },
      {
        headerName: 'Destination',
        field: 'stateProvince',
        valueGetter: (params) => {
          if (!params) { return ''; }
          let val = '';
          if (params.data.city) {
            val += params.data.city;
          }
          if (params.data.stateProvince) {
            val += ' ' + params.data.stateProvince;
          }
          if (params.data.countryName) {
            val += ' ' + params.data.countryName;
          }
          return val;
        }
      },
      {
        headerName: 'Status',
        field: 'orderStatusDescription',
      },
      {
        headerName: 'Selected Lot',
        field: 'lotNumber',
      },
      {
        headerName: 'Earmarked',
        field: 'hasEarmarks',
        valueGetter: (params) => {
          if (!params) { return '' }
          return params.data.hasEarmarks ? 'Yes' : 'No'
        }
      },
    ];
  }

  setColumnDefs() {
    var unitOfMeasure = this.orderItem.unitOfMeasureDesc;
    this.columnDefs = [
      {
        headerName: 'Exp. Date', field: 'expirationDate',
        valueGetter: (params) => { if (params.data !== undefined && params.data.expirationDate) { return formatDate(params.data.expirationDate, 'd-MMM-yyyy', 'en-US') } },
        tooltipValueGetter: (params) => {
          if (params.data !== undefined && params.data.expirationDate) {
            return formatDate(params.data.expirationDate, 'd-MMM-yyyy', 'en-US');
          }
        },
      },
      {
        headerName: 'Indy Reserved Amount', field: '',
        cellRenderer: (params) => {
          let indyAmount = 0;
          this.inventoryDetails.reservations.forEach((reservation) => {
            if (reservation.reservationTypeID === ReservationTypes.IndyReserved && params.data.lotNumber == reservation.lotNumber) {
              indyAmount += reservation.reservationAmountConverted;
            }
          })
          return indyAmount > 0 ? `${indyAmount.toFixed(7)?.toString()} ${this.orderItem?.unitOfMeasureDesc}` : indyAmount;
        }
      },
      {
        headerName: 'Unit for this Request', field: 'unitForRequest',
        cellRenderer: (params) => {
          const filteredItem = this.orderItem.inventoryAssignments.filter(e => e.lotNumber === params.data.lotNumber);
          const input = document.createElement('input');
          input.type = 'checkbox';
          if (filteredItem && filteredItem.length > 0) {
            input.checked = true;
            this.selectedNodes.push(params.data);
          }
          else
            input.checked = false;
          input.addEventListener('click', (event) => {
            input.checked != input.checked;
            this.onRowSelected(input.checked, params.data);
          });

          if (this.order && (this.order['orderStatusID'] == OrderStatuses.Pending ||
            this.order['orderStatusID'] == OrderStatuses.Ready ||
            this.order['orderStatusID'] == OrderStatuses.Warehouse))
            input.disabled = false;
          else if (this.order && (this.order.orderStatusID == OrderStatuses.Shipping ||
            this.order.orderStatusID == OrderStatuses.Complete)) {
            input.checked = true;
            input.disabled = true;
          }
          else input.disabled = true;

          return input;
        }
      }
    ]
    if (this.order.orderStatusID === OrderStatuses.Shipping || this.order.orderStatusID === OrderStatuses.Complete) {
      this.columnDefs.splice(0, 0, { headerName: 'Lot #', field: 'actualLotNumber' });
      const that = this;
      this.columnDefs.splice(1, 0, {
        headerName: 'Amount',
        field: 'requestedAmount',
        valueGetter: function (params) {
          if (params.data !== undefined && unitOfMeasure) {
            return that.weightFormatKGPipe.transform(params.data.requestedAmount) + ' ' + unitOfMeasure;
          }
        }
      })
    } else {
      const that = this;
      this.columnDefs.splice(0, 0, { headerName: 'Lot #', field: 'lotNumber' });
      this.columnDefs.splice(1, 0, {
        headerName: 'Amount', field: 'remainingAmountConverted', valueGetter: function (params) {
          if (params.data !== undefined && unitOfMeasure) {
            return that.weightFormatKGPipe.transform(params.data.remainingAmountConverted) + ' ' + unitOfMeasure;
          }
        }
      });
      this.columnDefs.push({
        headerName: 'Amount Earmarked', field: 'earmarkedAmountConverted', valueGetter: function (params) {
          if (params.data !== undefined && unitOfMeasure) {
            return that.weightFormatKGPipe.transform(params.data.earmarkedAmountConverted) + ' ' + unitOfMeasure;
          }
        }
      });
    }

    this.columnDefs.push({
      headerName: 'Storage Locations', field: 'storageLocations', valueGetter: function (params) {
        if (params.data && params.data.storageLocations && params.data.storageLocations.length > 0) {
          return params.data.storageLocations.join(',');
        }
      }
    });
    this.columnDefs.push({
      headerName: 'Reach Compliant', field: 'isReachApproved', valueGetter: (params) => {
        if (!params) { return '' }
        return params.data.isReachCompliant ? 'Yes' : 'No'
      }
    })
  }

  setContainersDataToGrid() {
    this.orderItem.containers.forEach(c => {
      let foundRow = this.rowData.find(r => r['actualLotNumber'] === c['actualLotNumber']);
      if (foundRow) {
        foundRow['requestedAmount'] += c["requestedAmount"];
      } else {
        this.rowData.push(c);
      }
    });
  }

  refreshData() {
    this.gridApi.setRowData(this.rowData);
    this.reservationGridApi.setRowData(this.reservationRowData);
  }

  onRowSelected(isChecked, data) {
    this.isInventorySaved = false;
    const filteredItem = this.selectedNodes.filter(n => n.lotNumber === data.lotNumber);

    if (filteredItem && filteredItem.length > 0 && !isChecked)
      this.selectedNodes = this.selectedNodes.filter(n => n.lotNumber != data.lotNumber);
    else if (filteredItem && filteredItem.length == 0 && isChecked)
      this.selectedNodes.push(data);

    if (this.canSave) this.disableSave = false;
    else this.disableSave = true;

    this.saveChanges.emit();
  }

  get canSave() {
    if (this.totalAmount == 0) return false;

    let totalAmount = 0;
    if (this.selectedNodes && this.selectedNodes.length > 0) {
      this.selectedNodes.forEach(node => {
        totalAmount += node.remainingAmountConverted;
      })
    }
    if (totalAmount >= this.totalAmount) return true;
    else return false;
  }

  getPayload() {
    return this.selectedNodes.map(n => {
      return {
        "inventoryCatalogID": this.orderItem?.inventoryCatalogID,
        "lotNumber": n.lotNumber?.toString()
      }
    })
  }

  onSelectionChanged(event) {
  }

  onPrintGLPFlagLabel() {
    this.orderService.printGLPFlagLabel(this.orderItem.orderItemID).subscribe(response => {
      this.responseHandler.showSuccess('Label sent to printer', 5000);
    },
      error => {
        this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000);
      });
  }

  get canDisableFlagLabel(): boolean {
    if (this.order.requestTypeID == 2 && this.orderItem.inventoryAssignments
      && this.orderItem.inventoryAssignments.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  onSave() {
    this.subscriptions.add(this.orderService.assignInventory(this.orderItem.orderItemID, this.getPayload())
      .subscribe(result => {
        this.isInventorySaved = true;
        this.disableSave = true;
        this.saveChanges.emit();
        this.responseHandler.showSuccess(`Successfully Assigned Inventory`);
      }));
  }

  calculateNumberOfContainers(containers) {
    if (!containers || !containers.length) this.numberOfContainers = 0;
    this.numberOfContainers = containers.length;
  }

  setRequestedLotNumber() {
    this.requestedLotNumber = !this._orderItem.requestedLotNumber ? 'Any' : this._orderItem.requestedLotNumber;
  }

  calculateTotalAmount(containers) {
    containers?.forEach(element => {
      this.totalAmount += element.requestedAmount;
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  onReservationGridReady(params) {
    this.reservationGridApi = params.api;
  }

  onEarmarkGridReady(params) {
    this.earmarkGridApi = params.api;
  }

  setInventoryDataToGrid() {
    if (!this.orderItem.inventoryCatalogID) {
      this.orderItem.inventoryDetails = {};
      this.orderItem.inventoryDetails.lots = [];
      return;
    }
  }

  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  reservationResizeGrid(params) {
    this.reservationGridApi = params.api;
    this.reservationGridApi.sizeColumnsToFit();
  }

  earmarkResizeGrid(params) {
    this.earmarkGridApi = params.api;
    this.earmarkGridApi.sizeColumnsToFit();
  }

  onOpen() {
    this.itemAttachmentsInstance.clearMessages();
  }

  get isInventorySelectionDisabled() {
    if (!this.order) return;
    const orderStatus = this.order['orderStatusID'];
    return orderStatus === OrderStatuses.Decant || orderStatus === OrderStatuses.Shipping
      || orderStatus === OrderStatuses.Complete
  }

  createOrderItemAttachment(val: CreateOrderItemAttachmentRequest) {
    this.orderItemAttachmentService.saveRequestItemAttachment(val.uploadedFiles, this.orderItem.orderItemID, val.documentTypeID, val.comments, val.languageID).subscribe((result) => {
      this.responseHandler.showSuccess(`File ${val.uploadedFiles[0].name} Uploaded successfully`, 5000);
      // we should updated the api to return the created and createdByID
      if (result) {
        this.attachmentEntity.attachments.push(result);
      }
      else
        this.responseHandler.showError(`Failed to fetch order item attachment details`, 5000);
    },
      error => {
        this.responseHandler.showError(error.message, 5000);
      });
  }

  updateOrderItemAttachment(val: UpdateOrderItemAttachmentRequest) {
    this.orderItemAttachmentService.updateOrderItemAttachment(val.orderItemAttachmentID, val).subscribe((result: OrderItemAttachment) => {
      const index = this.attachmentEntity.attachments.findIndex(a => a.orderItemAttachmentID === result.orderItemAttachmentID);
      this.attachmentEntity.attachments.splice(index, 1);
      this.attachmentEntity.attachments.push(result);
      this.attachmentEntity.attachments.sort((a, b) => a.filename > b.filename ? 1 : -1);
    },
      error => {
        this.confirmationService.close();
        this.responseHandler.showError(error.message, 5000);
      },
      () => {
        this.responseHandler.showSuccess(`Attachment ${val.filename} updated successfully`, 5000);
      });
  }

  removeOrderItemAttachment(val) {
    this.orderItemAttachmentService.deleteAttachment(val.attachment.orderItemAttachmentID).subscribe(res => {
      const index = this.attachmentEntity.attachments.findIndex(a => a.orderItemAttachmentID === val.attachment.orderItemAttachmentID);
      this.attachmentEntity.attachments.splice(index, 1);
    },
      error => this.responseHandler.showError(error.message, 5000),
      () => this.responseHandler.showSuccess(`Attachment ${val.attachment.filename} successfully removed`, 5000));
  }

  downloadAttachment(val: OrderItemAttachment): void {
    this.orderItemAttachmentService.getOrderItemAttachmentList(val.orderItemAttachmentID).subscribe(response => {
      this.downloadAttachmentService.download(val.filename, response.fileContents);
    },
      error => {
        this.responseHandler.showError(error.message, 5000);
      });
  }

  getReservationInfo() {
    if (!this.orderItem.inventoryCatalogID) { return; }
    this.subscriptions.add(this.inventoryService
      .getCatalogItemWithConversion(this.orderItem.inventoryCatalogID,
        this.orderItem.unitOfMeasureDesc).subscribe(data => {
          if (data) {
            let totalReserved = 0, indyReserved = 0, SGSReserved = 0;
            if (data.reservations) {
              data.reservations.forEach((reservation: Reservation) => {
                if (reservation.reservationTypeID === ReservationTypes.IndyReserved) {
                  indyReserved += reservation.reservationAmountConverted;
                  totalReserved += reservation.reservationAmountConverted;
                }
                else if (reservation.reservationTypeID === ReservationTypes.SGSReserved) {
                  SGSReserved += reservation.reservationAmountConverted;
                  totalReserved += reservation.reservationAmountConverted;
                }
              })
              const reservationRowData = [{
                indyPhysicalInventory: data.remainingAmountConverted,
                totalReserved,
                indyReserved,
                SGSReserved
              }];

              this.reservationRowData = [...reservationRowData]
            }
          }

          this.subscriptions.add(this.inventoryService.getCatalogItemWithConversion(this.orderItem.inventoryCatalogID,
            this.orderItem.unitOfMeasureDesc).subscribe(data => {
              this.inventoryDetails = data;
              if (this.order.orderStatusID !== OrderStatuses.Shipping && this.order.orderStatusID !== OrderStatuses.Complete) {
                this.rowData = data.lots;
              }
            }));

        }));
  }

  getEarmarkData() {
    if (!this.orderItem.inventoryCatalogID) { return; }
    this.orderService.getEarmarksByCatalogID(this.orderItem.inventoryCatalogID, this.orderItem.unitOfMeasureDesc).subscribe((res: OrderItemEarmarksByCatalogIDResponse[]) => {
      this.earmarkRowData = res;
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
