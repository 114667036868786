import { Injectable } from '@angular/core';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Configuration } from 'src/app/configuration';

@Injectable({
  providedIn: 'root'
})
export class ManageRolesService {

  constructor(private readonly http: HttpClient, private readonly responseHandler: ResponseHandlingService) {
  }

  getRoles(): Observable<any[]> {
    var URL = `${Configuration.REST_API_URL}/Roles/List/true`;
    return this.http.get<any[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to get list of roles at this time, please resubmit."]]), false)));
  }

  getRolesByID(id: number): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/Roles/${id}`;
    return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to get list of roles at this time, please resubmit."]]), false)));
  }

  deleteRoles(roleId: number): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/Roles/${roleId}`;
    return this.http.delete<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to delete the role at this time, please resubmit."]]), false)));
  }

  createRoles(role: any): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/Roles`;
    return this.http.post<any>(URL, role).pipe(catchError(err => {
      if (err?.error?.Title?.includes('Duplicate')) {
        return this.responseHandler.handleError(err, new Map([[err.status, err?.error?.Title]]), false);
      } else {
        return this.responseHandler.handleError(err, new Map([[err.status, "Unable save role at this time, please resubmit."]]), false);
      }
    }));
  }

  editRoles(roleId: number, role: any): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/Roles/${roleId}`;
    return this.http.put<any>(URL, role).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable save roles at this time, please resubmit."]]), false)));
  }
}
