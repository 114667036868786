export enum FeatureType {
    EnableGLPReservations = 1,
    EnablesendingOrderUpdatestoCPSS = 2,
    P2Prequirecustomsvalue = 3,
    ReservedOrderItemrequiresForecastItemID = 4,
    AzureServiceBusOrderUpdates = 5,
    AzureServiceBusP2PUpdates = 6,
    AzureServiceBusReceivingUpdates = 7,
    BionexusIntegration = 8,
    UseMultipleWarehouses = 9,
    IsSGSAvailable = 10
}
