<div class="res-loading-overlay" [ngClass]="{'hidden': !message, 'visible': !!message}">
    <ng-container *ngIf="imageSrc; else defaultLoaderTmpl">
        <img [src]="imageSrc" />
    </ng-container>
    <div>
        <div class="res-loading-overlay-mesage">
            {{ message }}
        </div>
        <div *ngIf="secondaryMessage" class="res-timeout-mesage">
            {{ secondaryMessage }} <a href="#" (click)="callActionCallBack($event)"> {{ actionMessage }} </a>
        </div>    
    </div>
</div>

<ng-template #defaultLoaderTmpl>
    <svg *ngIf="!imageSrc" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 100 100"
        enable-background="new 0 0 0 0" xml:space="preserve">
        <circle fill="none" stroke="#0072ce" stroke-width="5" cx="50" cy="50" r="44" />
        <circle fill="#0072ce" stroke="#4d4d4d" stroke-width="3" cx="8" cy="56" r="8">
            <animateTransform attributeName="transform" dur="1.25s" type="rotate" from="0 50 48" to="360 50 52"
                repeatCount="indefinite" />
        </circle>
    </svg>
</ng-template>