import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Configuration } from '../../configuration';
import { Order } from 'src/app/models/order-models';
import { Observable, ReplaySubject } from 'rxjs';
import { UpdateOrderItemAttachmentRequest } from 'src/app/models/attachment-models';
import { OrderItemAttachment } from 'src/app/models/attachment-models';
import { DocumentType } from 'src/app/models/document-type-model';

@Injectable()
export class OrderItemAttachmentService {

    private _documentTypes: ReplaySubject<DocumentType[]> = new ReplaySubject();
    public documentTypes$ = this._documentTypes.asObservable();

    constructor(private _http: HttpClient) { }


    public saveRequestItemAttachment(files: any[], orderItemID: any, documentTypeID: number, comments: string, languageId: number): any {
        let url = Configuration.REST_API_URL + "/Orders/OrderItems/" + orderItemID + "/Attachments"
        let formData: FormData = new FormData();
        for (let file of files) {
            formData.append('file[]', file);
            formData.append('documentTypeID', documentTypeID.toString());
            formData.append('comments', comments);
            formData.append('languageID', languageId.toString());
        }
        return this._http.post(url, formData);
    }

    public attachDocument(orderItemID: any, documentID: number): any {
        let url = Configuration.REST_API_URL + "/Orders/OrderItems/" + orderItemID + "/Documents/" + documentID
        return this._http.post(url, null);
    }

    public updateOrderItemAttachment(orderAttachmentId: number, payload: UpdateOrderItemAttachmentRequest): Observable<OrderItemAttachment> {
        return this._http.put<OrderItemAttachment>(Configuration.REST_API_URL + "/Orders/OrderItemAttachments/" + orderAttachmentId, payload);
    }

    //-------------------------------------------------
    // To get attached file with request.
    //-------------------------------------------------
    public getOrderItemAttachmentList(orderAttachmentID: any): any {

        let url = Configuration.REST_API_URL + "/Orders/OrderItemAttachments/" + orderAttachmentID;
        return this._http.get(url);
    }

    //-------------------------------------------------
    // To get attached file with orderItemId.
    //-------------------------------------------------
    public getAttachmentListByOrderItemID(orderItemID: any): any {

        let url = Configuration.REST_API_URL + "/Orders/OrderItems/" + orderItemID + "/Attachments/";
        return this._http.get(url);
    }

    public retrieveDocumentTypes(): Observable<DocumentType[]> {
        const url = Configuration.REST_API_URL + '/Orders/DocumentTypes';
        return this._http.get<DocumentType[]>(url);
    }

    public setDocumentTypes(documentTypes: DocumentType[]): void {
        this._documentTypes.next(documentTypes);
    }


    //------------------------------
    // To delete attachment from request
    //-------------------------------
    public deleteAttachment(orderItemAttachmentID: number): any {

        let url = Configuration.REST_API_URL + "/Orders/OrderItemAttachments/" + orderItemAttachmentID;
        return this._http.delete(url);
    }
}

