<h4 class="title">
    Add User to Role
</h4>

<div mat-dialog-content id="userRoleModal">
    <label for="usernameField">Name/Email ⃰</label>
    <p-autoComplete type="search" [(ngModel)]="user" id="usernameField" [suggestions]="filteredUsers"
        (completeMethod)="filterUsers($event)" field="typeAheadResult" [style]="{'width':'100%'}"
        [inputStyle]="{'width':'100%'}" styleClass="myStyle" placeholder="Search for a User"
        (onSelect)="resetRoleDropdown()" (onClear)="disableRoleDropdown()" emptyMessage="No matches found"
        minLength="2">
    </p-autoComplete>
    <div class="spacer"></div>
    <label for="roleDropdown">Role ⃰</label>
    <p-dropdown id="roleList" [options]="roleSelectList" appendTo="body" [(ngModel)]="selectedRole" [style]="{'width':'100%'}"        
        styleClass="roleDropdown" placeholder="Select a Role" [disabled]="dropdownDisabled"
        (onShow)="onRoleItemsShow()" (onChange)="enableAddButton()"></p-dropdown>
    <p><small>Note: Users must be in Active Directory group "GSS_Alchemist" to be found here.</small></p>

</div>

<div mat-dialog-actions class='action-panel'>
    <button mat-raised-button color="cor-default" (click)="onCancel()">Cancel</button>
    <button id="add-user-role" mat-raised-button color="cor-primary" (click)="addUserToRole()">Add</button>
</div>