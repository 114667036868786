import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ShipperService } from 'src/app/services/shippers-service/shipper.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-manage-shipper-actions',
  templateUrl: './manage-shipper-actions.component.html',
  styleUrls: ['./manage-shipper-actions.component.scss']
})
export class ManageShipperActionsComponent implements ICellRendererAngularComp {
  private params: any;

  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly shipperService: ShipperService

  ) {}

  onEditShipper(){
    this.router.navigate(['manageshippers', this.params.data.shipperID]);
  }
  
  onDeleteShipper() {
    const confirmDialog = new ConfirmDialogModel(
      'Confirm Action',
      `This record will be deleted. Are you sure?`,
      'OK',
      false,
      true,
      'Cancel'
    );
    this.dialog
      .open(ConfirmDialogComponent, { maxWidth: '400px', data: confirmDialog })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.shipperService.deleteShipper(
            this.params.data.shipperID
          ).pipe(
            take(1)
          ).subscribe(()=>{
            location.reload();
          });
        }
      });
  }

  canBeDeleted(): boolean {
    return this.params.data.allowDelete;
  }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  
}
