import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-label-info-modal',
  templateUrl: './label-info-modal.component.html',
  styleUrls: ['./label-info-modal.component.scss']
})
export class LabelInfoModalComponent {

  title: string = 'Label Information';
  labelInfoData: any;

  constructor(private dialogRef: MatDialogRef<LabelInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private datePipe: DatePipe) {
    this.labelInfoData = data;
  }

  public formatDate(value): string {
    if (!value) 
      return '';

    return this.datePipe.transform(value, 'd-MMM-yyyy');
  }

  public formatDecimal(value): string {
    if (!value) 
      return '';

    return parseFloat(value.toFixed(7))?.toString();
  }

  public onCopyToClipboard(id: string) {
    var table = document.getElementById(id);
    var range = document.createRange();
    range.selectNode(table);
    var sel = window.getSelection()
    sel.removeAllRanges();
    range.selectNodeContents(table);
    sel.addRange(range);
    document.execCommand('copy');
  }

  close() {
    this.dialogRef.close();
  }

}
