import { OrderItemAttachment } from './attachment-models';
import { OrderItemContainerAssignmentResponse, OrderItemContainerModel } from './order-item-container-model';

export class OrderItemModel {
    orderID: string
    orderItemID: string
    requestedLotNumber: string
    coaChecked: any
    containerLabel: any
    forecastURL?: string
    containerLabelFmt: string;
    requestedContainerLabel: string;
    vertereCatalogDescription: string;
    containers: OrderItemContainerModel[]
    lots: OrderItemLotResponse[]
    stockLots?: OrderItemLotResponse[]
    glpLots?: OrderItemLotResponse[]
    isUserDefinedContainerLabel: boolean
    lotNumber: any
    materialID: any
    priorityID: number
    priorityName: string
    readyToBeShipped: boolean
    sendInvoiceBefore: boolean
    shipByDate: string
    unitOfMeasureDesc: string
    unitOfMeasureID: number
    inventoryCatalogID: number
    specialInstructions: string
    shippingNotes: string
    shippingConditions: string
    itemRanges: string[]
    selectedInventoryCatalogID: number
    selectedLotNumber: number
    selectedInventoryLocations: any[] = []
    inventoryDetails: any
    attachments: OrderItemAttachment[] = []
    inventoryAssignments: InventoryAssignment[]
    isReachCompliant: boolean;
    extraPPERequired: boolean;
    allContainersAssigned = false;
    reservationTypeID: any;
    reservationTypeDesc: string;
    requestedProductName?: string;
    exportTariffCode: string;
    importTariffCode: string;
    eccn: string;
    orderContainers?: OrderItemContainerAssignmentResponse[];
    vertereProductNumber: string;
    vertereProductSourceSystem: string;
    itemURLFormat: string;
    itemURL: string;
    lotURLFormat: string;
    requiresPermit: boolean;
    reservationWarehouseID: number;
    forecastItemID: number;
    forecastStatus: string;
}

export class OrderItemLotResponse {
    lotNumber?: string;
    locations?: string[];
    itemRanges?: string[];
}

export class InventoryAssignment {
    assignmentID: string
    orderItemID: string
    inventoryCatalogID: number
    chemicalTypeName: string
    lotNumber: string
}
