import { Component, OnDestroy, Input, EventEmitter, Output, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { SelectItem } from 'primeng/api';

import { Role, UserRole, AdUser } from '../../models/security-models';

import { RoleService } from 'src/app/services/role-service/role-service.service';
import { ResponseHandlingService } from '../../services/response-handling-service/response-handling-service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-role-modal',
  templateUrl: './user-role-modal.component.html',
  styleUrls: ['./user-role-modal.component.scss']
})

export class UserRoleModalComponent implements OnDestroy {
  @Output() userAdded: EventEmitter<UserRole> = new EventEmitter();
  users: AdUser[];
  userRoleList: any;
  selectedRole: Role;
  filteredUsers: any;
  roles: Role[];
  availableRoles: Role[];
  spresp: any;
  roleSelectList: SelectItem[];
  user: AdUser;
  displayModal: boolean;
  dropdownDisabled: boolean;
  addButtonDisabled: boolean;
  autoCompleteStyle: any;

  private subscriptions = new Subscription();

  constructor(private dialogRef: MatDialogRef<UserRoleModalComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private roleService: RoleService, private responseHandler: ResponseHandlingService) {
    this.intialize();
    this.subscriptions.add(this.roleService.getAvailableRoles().subscribe(
      data => {
        this.roles = data;
      }
    ));
    this.users = this.data.users;
    this.userRoleList = this.data.userRoleList;
  }

  intialize(): void {
    this.user = { adUserID: null, email: null, userID: null, firstName: null, lastName: null };
    this.dropdownDisabled = true;
    this.addButtonDisabled = true;
    this.selectedRole = null;
    this.displayModal = true;
  }

  onCancel() {
    this.dialogRef.close();
  }

  filterUsers(event) {
    let filtered: AdUser[] = [];
    let query = event.query.toLowerCase();
    filtered = this.users.filter(s => s.email.toLowerCase().includes(query) || s.lastName.toLowerCase().includes(query) || s.firstName.toLowerCase().includes(query));
    this.filteredUsers = filtered.map(
      obj => ({ ...obj, typeAheadResult: `${obj.firstName} ${obj.lastName} (${obj.email})` })
    );
  }

  updateRoleList(roles: Role[], adUserID: string): SelectItem[] {
    var selectList: SelectItem[] = [];
    for (const role of roles) {
      for (const userRole of this.userRoleList) {
        if (userRole.roleID === role.roleID) {
          let users = userRole.users.filter(user => user.adUserID === adUserID);
          if (users && users.length === 0) {
            selectList.push({ label: role.roleName, value: role });
          }
        };
      }
    }
    return selectList;
  }

  onRoleItemsShow() {
    setTimeout(() => {
      const roleDropdown = <any>document.querySelector(".roleDropdown");
      if (roleDropdown) {
        (<any>document.querySelector(".p-dropdown-panel")).style.width = roleDropdown.offsetWidth + "px";
      }
    });
  }

  enableAddButton() {
    this.addButtonDisabled = false;
  }

  resetRoleDropdown() {
    this.selectedRole = null;
    this.dropdownDisabled = false;
    this.roleSelectList = this.updateRoleList(this.roles, this.user.adUserID);
  }

  disableRoleDropdown() {
    this.selectedRole = null;
    this.dropdownDisabled = true;
    this.addButtonDisabled = true;
  }

  addUserToRole() {
    if (!this.user || !this.user.adUserID) {
      this.responseHandler.showError("A valid user selection is required");
      return;
    }
    if (!this.selectedRole || !this.selectedRole.roleID) {
      this.responseHandler.showError("A valid role selection is required");
      return;
    }
    var postdata: UserRole;
    postdata = {
      userRoleID: 0,
      adUserID: this.user.adUserID,
      roleID: this.selectedRole.roleID
    }
    this.roleService
      .addUserToRole(postdata)
      .subscribe(data => {
        if (data) {
          this.responseHandler.showSuccess(`Successfully added user ${this.user.email} to role ${this.selectedRole.roleName}.`);
          this.userAdded.emit({ userRoleID: 0, userID: data.userID, roleID: data.roleID });
          this.dialogRef.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
