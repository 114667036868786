import { Language } from 'src/app/models/language.model';
import { AttachmentService } from 'src/app/core/modules/attachments/services/attachment.service';
import { Component, OnInit, OnDestroy, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MsalAuthService } from '../app/services/auth-service/auth-service.service';
import { UserService } from "../app/services/user-service/user-service.service";
import { Subscription } from 'rxjs';
import { OnAuthStatusUpdate, AuthStatus, AUTH_SERVICE_TOKEN } from '@corteva-research/ngx-components-core'
import { PermissionService } from './services/permission-service/permission-service';
import { Configuration } from './configuration';
import { LoadingService } from './core/modules/loading/services/loading.service';
import { ConfigurationService } from './services/configuration-service/configuration.service';

import { debounceTime, take, switchMap, filter } from 'rxjs/operators';
import { DocumentType } from './models/document-type-model';
import { OrderService } from './services/order-service/order-service';
import { Order, Request } from './models/order-models';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AutoComplete } from 'primeng/autocomplete';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy, OnAuthStatusUpdate, AfterViewInit {
  title: string = 'gss-angular-template-ui';
  loggedIn: boolean = false;
  username: string;
  envName = Configuration.CURRENT_ENVIRONMENT_NAME.toUpperCase();
  versionNumber: string = null;

  private subscriptions = new Subscription();

  order: Order;
  requestList: Request[];
  selectedRequestNumber: string;
  counter = 0;

  @ViewChild('autocomplete') autocomplete: AutoComplete;

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) private authService: MsalAuthService,
    private userService: UserService,
    public permissionService: PermissionService,
    private loadingService: LoadingService,
    private configurationService: ConfigurationService,
    private orderService: OrderService,
    private router: Router,
    private attachmentService: AttachmentService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.checkAccount();
    this.authService.subscribeToAuthStatusChanges(this);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe(data => {
          this.titleService.setTitle(`${data.title ? data.title + ' | GSS Alchemist' : 'GSS Alchemist'}`);
        })
      });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  ngAfterViewInit(): void {

    this.autocomplete.completeMethod.pipe(
      debounceTime(1000),
      switchMap((result) => { return this.orderService.searchRequests(result.query) })
    )
      .subscribe(data => {
        if (data && data.length) {
          data.sort((a, b) => a.requestNumber > b.requestNumber ? 1 : -1);
          this.requestList = data;
        }
        else
          this.requestList = [];
      })
  }

  onSelect(request: Request) {

    this.order = null;
    this.requestList = [];
    this.autocomplete.writeValue('');
    this.autocomplete.value = '';
    this.autocomplete.inputFieldValue = '';

    if (request.requestType === 'O') {
      this.router.navigate([`requests/${request.requestID}`]);
    }
    else {
      this.router.navigate([`p2p/${request.requestID}`]);
    }
    if (this.router.url.includes('requests'))
      setTimeout(() => {
        window.location.reload();
      }, 100);
  }

  selectedRequest(request: Request) {
    return request?.requestNumber;
  }

  resOnAuthStatusUpdate(authStatus: AuthStatus): void {
    switch (authStatus) {
      case AuthStatus.Authenticated:
        this.loadingService.show('Please wait while we set things up...');
        this.configurationService.getVersion().subscribe((res: string) => {
          this.versionNumber = res;
        });
        if (this.counter == 0) {
          this.counter++;
          this.subscriptions.add(
            this.userService.login().subscribe(
              data => {
                localStorage.setItem("userPermissions", JSON.stringify(data.permissions));
                localStorage.setItem("defaultWarehouseID", JSON.stringify(data.defaultWarehouseID));
                this.permissionService.updateUserPermissions();
                if (!this.router.url.includes('requests'))
                  this.loadingService.clearMessage();
                //moved this here once user is authenticated
                this.attachmentService.retrieveDocumentTypes().pipe(take(1)).subscribe((res: DocumentType[]) => {
                  localStorage.setItem('documentTypes', JSON.stringify(res));
                  this.attachmentService.setDocumentTypes(res);
                });
                this.attachmentService.retrieveLanguages().pipe(take(1)).subscribe((res:
                  Language[]) => {
                  localStorage.setItem('languages', JSON.stringify(res));
                  this.attachmentService.setLanguages(res);
                })
              }
            )
          )
        }
        break;
      case AuthStatus.Authenticating:
        break;
      case AuthStatus.Unauthenticated:
        localStorage.removeItem("userPermissions");
        break;
    }
  }

  checkAccount() {
    this.loggedIn = !!this.authService.isLoggedIn();
  }

  ngOnDestroy() {
    window.addEventListener("beforeunload", function () {
      localStorage.clear();
    });
    this.subscriptions.unsubscribe();
  }
}
