<div class="container">
    <h5>Attachments</h5>
    <span class="text">View attachments for {{ data?.requestNumber }}.</span>
    <div class="content">
        <h6 *ngIf="data && data?.orderAttachments && data?.orderAttachments.length > 0">Request</h6>
        <hr *ngIf="data?.orderAttachments && data.orderAttachments.length > 0" />
        <div class="attachment" *ngFor="let attachment of data?.orderAttachments">
            <div class="clickable" (click)="downloadOrderAttachment(attachment)">
                <i class="pi pi-download download-file-link"></i>
                <span class="text p-pl-2" style="font-weight: bold !important;">{{ attachment.filename }}</span>
            </div>
            <span class="text block" style="color: #aaa;"> {{ getDocumentType(attachment.documentTypeID) }} - {{
                attachment.languageName
                }}</span>
            <span class="text block" style="color: #aaa;">{{ attachment.comments }}</span>
            <hr />
        </div>
        <div *ngFor="let orderItem of data?.orderItems">
            <h6 *ngIf="orderItem.attachments && orderItem.attachments.length > 0">{{ orderItem.containerLabelFmt }}</h6>
            <hr *ngIf="orderItem.attachments && orderItem.attachments.length > 0" />
            <div class="attachment" *ngFor="let attachment of orderItem.attachments"
                (click)="downloadOrderItemAttachment(attachment)">
                <div>
                    <i class="pi pi-download download-file-link"></i>
                    <span class="text p-pl-2" style="font-weight: bold !important;">{{ attachment.filename }}</span>
                </div>
                <span class="text block" style="color: #aaa;"> {{ getDocumentType(attachment.documentTypeID) }} - {{
                    attachment.languageName
                    }}</span>
                <span class="text block" style="color: #aaa;">{{ attachment.comments }}</span>
                <hr />
            </div>
        </div>
    </div>
    <div class="actions">
        <button class="close" mat-raised-button color="cor-primary" (click)="close()">Close</button>
    </div>
</div>