import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MessageService } from '../../../shared/services/message-queue.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { EditDensityModalComponent } from '../edit-density-modal/edit-density-modal.component';

@Component({
  selector: 'app-edit-density-actions',
  templateUrl: './edit-density-actions.component.html',
  styleUrls: ['./edit-density-actions.component.scss']
})
export class EditDensityActionsComponent implements ICellRendererAngularComp {
  private params: any;

  constructor(
    private dialog: MatDialog,
    private readonly messageService: MessageService,
    private toastr: ResponseHandlingService
  ) { }

  onEditMaterial() {
    this.dialog
      .open(EditDensityModalComponent, { width: '600px', data: this.params.data })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({ data: 'editDensity', reference: dialogResult });
        }
      });
  }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}