<div>
    <h5 class="p-mb-3">Documents to Create</h5>
    <div class="p-grid p-p-2" style="justify-content: space-between;" *ngFor='let document of documents'>
        <p class="message p-mr-3">{{document.name}}</p>
        <button mat-raised-button color="cor-primary" [disabled]="canDisable(document)"
            (click)='onGenerateDocument(document)'>Generate Document</button>
    </div>
    <div class="actions flex-end p-mt-3">
        <button mat-raised-button color="cor-default" (click)="cancel()">Close</button>
    </div>
</div>