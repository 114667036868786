<h5>
    Attach to order
</h5>

<div class="doublespacer"></div>
<hr />

<p class="mat-body" style="border: 1px solid #bbb;" [ngClass]="pdfSrc ? 'pdf-styles':''">
    <pdf-viewer *ngIf="pdfSrc;else noPreview" [src]="pdfSrc" [render-text]="true" [original-size]="false">
    </pdf-viewer>
    <ng-template #noPreview>
        No preview available
    </ng-template>
</p>

<div mat-dialog-actions class="p-pb-3" align="end">
    <button class="close" mat-raised-button color="cor-primary" (click)="close()">Close</button>
    <button class="p-pl-2" style="width: 78px;" mat-raised-button color="cor-primary"
        (click)="onDownload()">Download</button>
    <button class="p-pl-2" style="width: 128px;" mat-raised-button color="cor-primary" (click)="attachToOrder()">Attach
        to Request</button>
</div>