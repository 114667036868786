import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Configuration } from '../../../app/configuration';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { FilterOption } from 'src/app/models/filter-models';

@Injectable({
  providedIn: 'root'
})
export class WareHouseService {

  readonly rootURL = Configuration.REST_API_URL;

  constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
  }

  getWarehouses(): Observable<any[]> {
    var URL = this.rootURL + `/warehouse`;
    return this.http.get<any[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  retrieveList(): Observable<FilterOption[]> {
    var URL = this.rootURL + `/warehouse/List`;
    return this.http.get<FilterOption[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  retrieveById(wareHouseId: number): Observable<FilterOption[]> {
    var URL = this.rootURL + `/warehouse/${wareHouseId}`;
    return this.http.get<FilterOption[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }
}