<div class="container">
    <h5>Add Printer Assignment</h5>
    <div class="content">
        <form [formGroup]="printerAssignmentForm">
            <div class="p-field p-fluid p-mb-2 p-mt-">
                <label class="p-text-bold" for="labelDesign">Label Design</label>
                <p-dropdown id="labelDesign" appendTo="body" placeholder="Select a label design"
                    formControlName="labelDesign" [options]="labelDesigns" optionLabel="name" [style]="{'width':'100%'}"
                    [filter]="true" filterBy="name" [showClear]="true">
                </p-dropdown>
            </div>
            <div [ngClass]="{'p-error': fc.labelDesign.invalid}"
                *ngIf="fc.labelDesign.touched && fc.labelDesign.errors">
                <small *ngIf="fc.labelDesign.errors?.required">
                    Field is required.
                </small>
            </div>
            <div class="p-field p-fluid p-mb-2">
                <label class="p-text-bold" for="station">Station</label>
                <p-dropdown id="station" appendTo="body" placeholder="Select a station" formControlName="station"
                    [options]="stations" optionLabel="name" [style]="{'width':'100%'}" [filter]="true" filterBy="name"
                    [showClear]="true">
                </p-dropdown>
            </div>
            <div class="p-field p-fluid p-mb-2">
                <label class="p-text-bold" for="printer">Label Printer</label>
                <p-dropdown id="printer" appendTo="body" placeholder="Select a label printer" formControlName="printer"
                    [options]="labelPrinters" optionLabel="displayName" [style]="{'width':'100%'}" [filter]="true"
                    filterBy="displayName" [showClear]="true">
                </p-dropdown>
            </div>
            <div [ngClass]="{'p-error': fc.printer.invalid}" *ngIf="fc.printer.touched && fc.printer.errors">
                <small *ngIf="fc.printer.errors?.required">
                    Field is required.
                </small>
            </div>
        </form>
    </div>
    <div class="p-text-right p-mt-4">
        <button mat-raised-button color="cor-default" (click)="cancel()" class="p-mr-2">Cancel</button>
        <button mat-raised-button color="cor-primary" [disabled]="printerAssignmentForm.invalid"
            (click)="save()">Save</button>
    </div>
</div>