import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-action-renderer',
  templateUrl: './action-renderer.component.html',
  styleUrls: ['./action-renderer.component.scss']
})
export class ActionRendererComponent implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;
  
  value: string = 'Share';
  stateOptions: any[] = [{label: 'Share', value: 'Share'}, {label: 'Hide', value: 'Hide'}];


  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
  }

  onAttach() {
    this.gridApi.dispatchEvent({ type: 'attachEvent', value: this.params });
  }

  onDownload() {
    this.gridApi.dispatchEvent({ type: 'downloadEvent', value: this.params });
  }

  refresh(): boolean {
    return false;
  }

}
