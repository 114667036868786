export interface FilterRequest {
    pageSize: number;
    pageNumber: number;
    sortOrders: SearchSortingRequest[];
    filters: SearchFilter[];
}

export interface SearchSortingRequest {
    sortOrder: SortOrder;
    sortProperty: string;
}

export enum SortOrder {
    ASC = 1,
    DESC = 2
}

export enum OperatorType {
    Equal = 1,
    StartsWith = 2,
    EndsWith = 3,
    Contains = 4,
    GreaterThan = 5,
    LessThan = 6,
    GreaterThanEqual = 7,
    LessThanEqual = 8,
    NotEqual = 9,
    NotLike = 10,
    IsNull = 11,
    IsNullOrWhiteSpace = 12,
    IsNotNullNorWhiteSpace = 13,
    Between = 14,
    In = 15,
    IsNotNull = 16,
    IsEmpty = 17,
    IsNotEmpty = 18
}

export interface BaseSearchFilter {
    propertyName: string;
    value: any;
    value2?: any;
    operatorType: OperatorType;
}

export interface SearchFilter extends BaseSearchFilter {
    conditionalOperatorType: ConditionalOperatorType | null;
    innerFilters: SearchFilter[];
}

export enum ConditionalOperatorType {
    And = 1,
    Or = 2
}

export interface PreviousSessionModel {
    MaterialName: string;
    Status: number;
    Receiver: number;
    ReceivedDate: string;
    ReceivingSessionId: number;
    LotNumber: string;
}