<p-toast position="top-right"></p-toast>

<div style="display: flex;" *ngIf="hasPermission">
    <button mat-raised-button color="cor-primary" (click)="openAttachmentDialog()">
        Add Attachment
    </button>
    <button mat-raised-button color="cor-primary p-ml-2" *ngIf="showSharedDocuments" (click)="onAddSharedDocuments()">
        Add Shared Documents
    </button>
    <button class="p-ml-2" mat-raised-button color="cor-primary" *ngIf="!disableAttachment" (click)="onStoredDocuments($event)">Documents</button>
    <div class="p-pl-2" style="display: flex;" *ngIf="!disableAttachment">
        <button mat-raised-button color="cor-primary" [disabled]="canDisableCOA" (click)="onRetrieveCOA()">
            Retrieve COA
        </button>
        <p class="p-ml-2" [innerHTML]="coaStatus" [ngStyle]="{'color' : !orderItem?.coaChecked ? 'red' : ''}"
            style="font-size: 12px;font-style: italic;">
        </p>
    </div>
</div>
<div class="p-grid attachment-column-headers">
    <span class="p-col-4">File Name</span>
    <span class="p-col-1">Document Type</span>
    <span class="p-col-1">Language</span>
    <span class="p-col-4">Notes</span>
</div>

<div *ngIf="!editOrderItemModel.attachments || editOrderItemModel.attachments.length === 0" class="empty-message">
    No attachments.
</div>


<div class="p-col-12 attached-file-list-container" *ngIf='editOrderItemModel.attachments.length > 0'>
    <div class="p-grid attached-files-list" *ngFor="let attachment of editOrderItemModel.attachments;let index = index">

        <div class="p-col-4 clickable" (click)="downloadAttachment(attachment)">
            <i class="pi pi-download download-file-link"></i>
            {{attachment.filename}} <span class="badge new-file-text badge-success"
                *ngIf="attachment.orderItemAttachmentID > orderItemAttachmentId">New</span>
        </div>
        <div class="p-col-1">
            <span>{{ getDocumentType(attachment.documentTypeID) }}</span>
        </div>
        <div class="p-col-1">
            <span>{{ attachment.languageName }}</span>
        </div>
        <div class="p-col-4 comment">
            <span>{{ attachment.comments }}</span>
        </div>
        <div class="p-col-2 actions" *ngIf="hasPermission">
            <button icon="pi pi-user-edit" [attr.id]='index' pButton mat-raised-button type="button" color="cor-primary"
                label="Edit" [disabled]='attachment.isLocked' (click)="openAttachmentDialog(attachment)"></button>
            <button icon="pi pi-trash" [attr.id]='index' pButton mat-raised-button type="button" class="p-button-danger"
                label="Remove" [disabled]='attachment.isLocked' (click)="removeAttachment(entity,attachment)"></button>
        </div>
    </div>
</div>

<!-- confirmation dialog box to remove chemical from summary page -->
<p-confirmDialog #confirmDialog class="confirmation-dialog" header="Confirmation" icon="pi pi-exclamation-triangle"
    appendTo="body" [closable]="false">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="OK" (click)="confirmDialog.accept()"></button>
        <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="confirmDialog.reject()"></button>
    </p-footer>
</p-confirmDialog>