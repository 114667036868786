import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'selection-cell',
  template: `<p-dropdown appendTo="body" class='selection-cell-widthOverride' [options]="params.data.orderItemContainerIDs" [(ngModel)]="params.data.selectedContainer" optionLabel="containerNumber"></p-dropdown>`,
  styles: [],
})

export class ContainerNumberDropdownRenderer implements ICellRendererAngularComp {
  public params: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}