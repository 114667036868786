import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadAttachmentService {

  constructor() { }

  public download(fileName: string, fileContents: any) {
    var blob = this.dataURItoBlob(fileContents)
    var a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
  }

  private dataURItoBlob(dataURI) {
    var byteString = atob(dataURI);
    var mimeString = 'image/png';
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }
}
