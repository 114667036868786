import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { Configuration } from '../../configuration';

@Injectable()
export class ConfigurationService {
    readonly rootURL = Configuration.REST_API_URL;

    constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
    }

    getVersion(): Observable<string> {
        var URL = this.rootURL + `/Configuration/Version`;
        return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }
}