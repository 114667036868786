<h4>Decanting Container Detail</h4>
<div class="p-grid" style="margin-top:30px">
    <div class="p-col-2" style="margin-top: 10px;border-style: solid;border-width: 2px 0px 2px 2px;margin-left: 10px;">
        <table style="width:100%">
            <tr style="display:block; padding-bottom: 20px;">
                <td>Request #:</td>
            </tr>
            <tr style="display:block; padding-bottom: 20px;">
                <td>Material:</td>
            </tr>
            <tr style="display:block; padding-bottom: 20px;">
                <td>Container:</td>
            </tr>
            <tr style="display:block; padding-bottom: 20px;">
                <td>Lot Number:</td>
            </tr>
            <tr style="display:block; padding-bottom: 20px;">
                <td>Amount Requested:</td>
            </tr>
            <tr style="display:block; padding-bottom: 20px;">
                <td>Containers Needed:</td>
            </tr>
            <tr style="display:block; padding-bottom: 20px;">
                <td>Current Container:</td>
            </tr>
        </table>
    </div>
    <div class="p-col-2"
        style="font-weight: bold;margin-right: 35px;border: solid;margin-top: 10px;border-width: 2px 2px 2px 0px;">
        <table style="width:100%">
            <tr style="display:block; padding-bottom: 20px;">
                <td>{{order?.requestNumber ? order.requestNumber : '-'}}</td>
            </tr>
            <tr style="display:block; padding-bottom: 20px;">
                <td>{{selectedOrderItem?.containerLabelFmt ? selectedOrderItem.containerLabelFmt : '-'}}</td>
            </tr>
            <tr style="display:block; padding-bottom: 20px;">
                <td>{{selectedContainer?.noDecantInventoryItemID ? selectedContainer?.noDecantInventoryItemID :
                    this.selectedInventoryItem.itemID}}</td>
            </tr>
            <tr style="display:block; padding-bottom: 20px;">
                <td>{{selectedInventoryItem?.lotNumber ? selectedInventoryItem.lotNumber : '-'}}</td>
            </tr>
            <tr style="display:block; padding-bottom: 20px;">
                <td>{{getAmountRequested()}}</td>
            </tr>
            <tr style="display:block; padding-bottom: 20px;">
                <td>{{sortedContainersFromMatchingLot?.length ? sortedContainersFromMatchingLot.length : '-'}}</td>
            </tr>
            <tr style="display:block; padding-bottom: 20px;">
                <td>{{decantingContainerNumberForDisplay}} of {{sortedContainersFromMatchingLot.length}}</td>
            </tr>
        </table>
    </div>
    <div class="p-col-2" style="margin-right: 35px;">
        <div class="p-mb-2">
            <button mat-raised-button color="cor-primary" (click)='onPrintLabel()' [disabled]="!weightCaptured"
                style="width: 240px;" id="btn-print-container-label">Print
                Container Label</button>
        </div>
        <div class="p-mb-2">
            <button mat-raised-button color="cor-primary" (click)='onPrintSmallVialLabel()' [disabled]="!weightCaptured"
                style="width: 240px;" id="btn-print-small-vial-label">Print Small
                Vial Label</button>
        </div>
        <div class="p-mb-2">
            <button mat-raised-button color="cor-primary" (click)='onPrintSmallBarcodeLabel()'
                [disabled]="!weightCaptured" style="width: 240px;" id="btn-print-small-barcode-label">Print Small
                Barcode Label</button>
        </div>
        <div class="p-mb-2">
            <button mat-raised-button color="cor-primary" (click)='onPrintGLPLabel()'
                [disabled]="!weightCaptured || order.requestTypeID !== requestTypes.GLP" style="width: 240px;"
                id="btn-print-glp-label">Print GLP
                Label</button>
        </div>
        <div class="p-mb-2">
            <button [disabled]="!printSuccess" mat-raised-button color="cor-primary" (click)="onCompleteClick()"
                style="width: 240px;" id="btn-complete-container">Complete
                Container</button>
        </div>
        <div class="p-mb-2">
            <button mat-raised-button color="cor-danger" [disabled]="disableDispose"
                (click)="onDisposeParentContainerClick()" style="width: 240px;" id="btn-dispose-container">Dispose
                Parent Container</button>
        </div>
    </div>
    <div class="p-col">
        <div>
            <label class="header-wrapper" for="stationSelectList">Warehouse: </label>
            <label class="header-wrapper">{{warehouseName}}</label> <br>
            <label class="header-wrapper" for="stationSelectList">Station: </label>
            <label class="header-wrapper">{{selectedStationOptionName}}</label>
        </div>

        <div *ngIf="selectedStation">
            <div *ngIf="scalesReady && selectedContainer">
              <div>
                <app-scale-control [decantStation]="selectedStation" [selectedContainer]="selectedContainer"
                    [selectedInventoryItem]="selectedInventoryItem" [isReweighMode]="false"
                    [unitOfMeasure]="selectedOrderItem.unitOfMeasureDesc" [mode]='scaleMode'
                    (weightCaptured)="handleWeightCaptured($event)">
                </app-scale-control>
              </div>
              <button class="large" mat-raised-button (click)="getStation()" color="cor-primary" style="margin-top: -30px;">Get
                Scale Weights</button>
            </div>
        </div>
    </div>

</div>
<div class="p-grid" style="margin-top: 10px;">
    <div class="p-col-12 p-mb-4">
        <div class="attachments">
            <button mat-raised-button color="cor-primary" (click)="openViewAttachments()"
                [disabled]="(!orderItemAttachments || orderItemAttachments.length === 0) && (!order?.attachments || order?.attachments.length === 0)">
                View Attachments
            </button>
            <p *ngIf="(!orderItemAttachments || orderItemAttachments.length === 0) && (!order?.attachments || order?.attachments.length === 0)"
                class="empty-message">
                No Documents to Print
            </p>
        </div>
    </div>
</div>
<div class="p-grid">
    <div class="p-col-12">
        <div class="border instr-box">
            <span class="special-instr">Special Instructions</span>
            <ul>
                <div *ngIf='order?.specialInstructions'>
                    <li>Request</li>
                    <div class="p-p-2">
                        <p [ngClass]="order?.specialInstructions ? 'highlight-yellow':''">
                            {{order?.specialInstructions}}</p>
                    </div>
                </div>
                <div *ngIf='selectedOrderItem?.specialInstructions'>
                    <li>{{selectedOrderItem?.containerLabelFmt}} </li>
                    <div class="p-p-2">
                        <p class="highlight-yellow">
                            {{selectedOrderItem?.specialInstructions}}</p>
                    </div>
                </div>
                <div *ngIf='!order?.specialInstructions && !selectedOrderItem?.specialInstructions' class="p-p-2">
                    <p>No Instructions</p>
                </div>
            </ul>
        </div>
    </div>
</div>
