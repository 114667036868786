import { Component, OnDestroy, OnInit } from '@angular/core';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ExternalServiceType } from '../models/enumerations';
import { MaterialViewModel } from '../models/material.model';
import { SupplierViewModel } from '../models/supplier.model';
import { ReceivingStateService } from '../services/receiving-state.service';
import { ReceivingService } from '../services/receiving.service';
import { Router } from "@angular/router"
import { NavigationService } from 'src/app/core/modules/navigate/services/navigation.service';
import { receivingConstants } from '../receiving.constants';
import { Subscription } from 'rxjs';
import { AddressViewModel } from '../models/address.model';
import { dataAdapterServiceFactory } from '../services/data-adapters/material-data-adapter.service-provider';
import { MaterialDataAdapterService } from '../services/data-adapters/material-data-adapter.service';
import { LookupModel } from '../models/lookup.model';
@Component({
  selector: 'receiving-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  materialTypeCompound = ExternalServiceType.Compound;
  materialTypeBionexus = ExternalServiceType.Bionexus;
  materialList: MaterialViewModel[];
  selectedMaterial: Partial<MaterialViewModel>;
  selectedSupplier: Partial<SupplierViewModel>;
  supplierAddressErr: boolean = false;
  dataAdapter: MaterialDataAdapterService;
  private materialListSubscription: Subscription;
  private selectedMaterialSubscription: Subscription;

  constructor(
    private receivingService: ReceivingService,
    private router: Router,
    private toastr: ResponseHandlingService,
    private receivingStateService: ReceivingStateService,
    private navService: NavigationService) { }

  async ngOnInit() {
    this.navService.setBackButton({ visible: true, routerLink: receivingConstants.Routes.Search });

    this.materialListSubscription = this.receivingStateService.getMaterialList().subscribe(async materialList => {
      this.materialList = materialList as MaterialViewModel[];
      if (this.materialList === undefined || this.materialList === null || this.materialList.length === 0) {
        this.router.navigate([receivingConstants.Routes.Search]);
        return;
      }

      if (this.materialList.length > 0) {
        this.dataAdapter = dataAdapterServiceFactory({ materialType: this.materialList[0].materialTypeId }, this.receivingService, this.receivingStateService);
      }

      if (this.materialList.length == 1) {
        this.setMaterialAndLoadSuppliers(this.materialList[0]);
      }

    });

    this.backButtonListener();
  }

  onSupplierSelect(event: any, materialIndex: number, supplier: SupplierViewModel) {
    let material = this.materialList[materialIndex];
    if (supplier.isSelected === true) {
      supplier.isSelected = false;
      this.selectedSupplier = undefined;
      this.unSelectAddress(supplier);
    } else {
      this.unSelectAllMaterialSuppliers(this.selectedMaterial);
      this.selectSupplier(supplier, material);
    }

    this.selectedMaterial.selectedSupplier = this.selectedSupplier as SupplierViewModel;
  }

  OnContinueClicked() {

    if (this.selectedMaterial == undefined) {
      this.toastr.showWarning('Please select a Material before continuing', null, 'Material Needed');
      return;
    }

    if (this.selectedSupplier == undefined) {
      this.toastr.showWarning(`Please select a Supplier for ${this.selectedMaterial.materialName}`, null, 'Supplier Needed');
      return;
    }

    if (this.selectedAddress == undefined) {
      this.toastr.showWarning(`Please select a address for supplier (${this.selectedSupplier.name})`, null, 'Address Needed');
      return;
    }

    this.selectedMaterial = this.dataAdapter.ToMaterialViewModelFromLot(this.selectedMaterial as MaterialViewModel);

    this.receivingStateService.setReceivingStateModel({ material: this.selectedMaterial, supplier: this.selectedSupplier, address: this.selectedAddress });
    this.router.navigate([receivingConstants.Routes.Validate]);
  }

  async onMaterialTabOpen(event): Promise<void> {
    if (this.selectedMaterial) {
      this.unSelectAllMaterialSuppliers(this.selectedMaterial);
    }
    let material = this.materialList[event.index];
    return await this.setMaterialAndLoadSuppliers(material);
  }

  onMaterialTabClose() {
    if (this.selectedMaterial) {
      this.unSelectAllMaterialSuppliers(this.selectedMaterial);
      this.selectedMaterial = undefined;
    }
  }

  async getSuppliers(material: MaterialViewModel): Promise<SupplierViewModel[]> {


    material.$isSupplierLoading = true;
    this.supplierAddressErr = false;
    return this.dataAdapter.loadSuppliers(material).then(
      (suppliers) => {
        material.suppliers = suppliers as SupplierViewModel[];

        return Promise.resolve(suppliers as SupplierViewModel[]);
      },
      () => {
        this.supplierAddressErr = true;
        return Promise.resolve([]);
      }
    ).finally(() => {
      material.$isSupplierLoading = false;
    });
  }

  onAddressSelect(event: any, address: AddressViewModel, supplier: SupplierViewModel, materialIndex: number) {
    this.selectAddress(event.checked, address, supplier, materialIndex);
  }

  ngOnDestroy(): void {
    this.materialListSubscription.unsubscribe();
    this.selectedMaterialSubscription.unsubscribe();
    this.selectedMaterial = undefined;
    this.selectedSupplier = undefined;
  }

  private selectSupplier(supplier: SupplierViewModel, material: MaterialViewModel) {
    material.supplierId = supplier.supplierId;
    supplier.isSelected = true;
    this.selectedSupplier = supplier;
    if (this.selectedSupplier.address && this.selectedSupplier.address.length == 1) {
      const address = this.selectedSupplier.address[0] as AddressViewModel;
      address.isSelected = true;
      supplier.selectedAddress = address;
    }
  }

  private unSelectAllMaterialSuppliers(material: Partial<MaterialViewModel>) {

    if (material === undefined || material === null) {
      return;
    } else {
      if (material.suppliers && material.suppliers.length > 0) {
        material.suppliers.forEach(supplier => {
          supplier.isSelected = false;
          this.unSelectAddress(supplier);
        });
      }
    }
    this.selectedSupplier = undefined;
  }

  private setMaterial(material: Partial<MaterialViewModel>) {

    if (this.selectedMaterial !== undefined) {
      this.selectedMaterial.isSelected = false;
    }
    this.selectedMaterial = material;
    this.selectedMaterial.isSelected = true;
  }

  private unSelectAddress(supplier: SupplierViewModel) {
    if (supplier?.address?.length > 1) {
      for (let addressIndex = 0; addressIndex < supplier.address.length; addressIndex++) {
        const addressModel = supplier.address[addressIndex] as AddressViewModel;
        addressModel.isSelected = false;
      }
    }
    supplier.selectedAddress = undefined;
  }

  private selectAddress(isChecked: boolean, address: AddressViewModel, supplier: SupplierViewModel, materialIndex: number) {
    this.unSelectAddress(supplier);
    let material = this.materialList[materialIndex];
    this.unSelectAllMaterialSuppliers(material);
    if (isChecked === true) {
      address.isSelected = true;
      this.setMaterial(material);
      this.selectSupplier(supplier, material);
      supplier.selectedAddress = address;
    }
  }

  private async setMaterialAndLoadSuppliers(material: MaterialViewModel) {
    this.setMaterial(material);
    if (!material.suppliers) {
      await this.getSuppliers(material);
    }
  }

  private backButtonListener() {
    //In case when user gets back to this page using back button then we can retail the old selection
    this.selectedMaterialSubscription = this.receivingStateService.getReceivingStateModel().subscribe(receivingStateModel => {
      if (receivingStateModel !== undefined && receivingStateModel !== null) {
        this.setMaterial(receivingStateModel.material);
        this.selectedSupplier = receivingStateModel.supplier;
        this.selectedSupplier.selectedAddress = receivingStateModel.address;
        setTimeout(() => {
          if (this.selectedMaterial) {
            let el = document.getElementById('tabMaterial_' + this.selectedMaterial.materialId);
            if (el) {
              el.scrollIntoView();
            }
          }
        }, 100);
      }
    });
  }

  get selectedAddress(): AddressViewModel {
    return this.selectedSupplier?.selectedAddress as AddressViewModel;
  }
}
