import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MessageService } from '../../../shared/services/message-queue.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { EditSampleusesModalComponent } from '../edit-sampleuses-modal/edit-sampleuses-modal.component';

@Component({
  selector: 'app-manage-sampleuses-actions',
  templateUrl: './manage-sampleuses-actions.component.html',
  styleUrls: ['./manage-sampleuses-actions.component.scss']
})
export class ManageSampleusesActionsComponent implements ICellRendererAngularComp {
  private params: any;

  constructor(
    private dialog: MatDialog,
    private readonly messageService: MessageService
  ) {}

  onEditSampleUse(){
    this.dialog
      .open(EditSampleusesModalComponent, { width: '414px', data: this.params.data })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({data:'edit', reference:dialogResult});
        }
      });
  }
  
  onDeleteSampleUse() {
    const confirmDialog = new ConfirmDialogModel(
      'Confirm Action',
      `This record will be deleted. Are you sure?`,
      'OK',
      false,
      true,
      'Cancel'
    );
    this.dialog
      .open(ConfirmDialogComponent, { maxWidth: '400px', data: confirmDialog })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({data:'delete', reference:this.params.data});
        }
      });
  }

  canBeDeleted(): boolean {
    return this.params.data.allowDelete;
  }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  
}

