import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridOptions } from 'ag-grid-community';
import { MessageService } from 'src/app/shared/services/message-queue.service';
import { PaginatorModel } from 'src/app/core/modules/ag-grid/components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { LabelPrinterModel } from 'src/app/receiving/models/label-printer-model';
import { ManageStationService } from 'src/app/services/manage-station-service/manage-station.service';
import { ManageStationActionsComponent } from './manage-station-actions/manage-station-actions.component';
import { sortData } from 'src/app/helpers/grid-helpers';
import { map, switchMap, take, finalize } from 'rxjs/operators';
import { EditStationModalComponent } from './edit-station-modal/edit-station-modal.component';

@Component({
  selector: 'app-manage-stations',
  templateUrl: './manage-stations.component.html',
  styleUrls: ['./manage-stations.component.scss']
})
export class ManageStationsComponent implements OnInit {
  private gridApi;
  private stations: LabelPrinterModel[];

  public gridOptions: GridOptions;
  public disableClearFilters: boolean = true;
  public paginatorModel: PaginatorModel;
  public hasFilterModelChanged: boolean = true;
  public frameworkComponents: any;
  public overlayNoRowsTemplate: string =
    '<span class="ag-overlay-loading-center">No stations found.</span>';

  constructor(
    private dialog: MatDialog,
    private readonly messageService: MessageService,
    private loadingService: LoadingService,
    private readonly manageStationService: ManageStationService
  ) {
    this.frameworkComponents = {
      deletePrinterRendered: ManageStationActionsComponent,
    };
  }

  columnDefs = [
    {
      headerName: 'Warehouse Name',
      field: 'warehouseName',
      headerTooltip: 'Warehouse Name',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.warehouseName;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Station Name',
      field: 'name',
      headerTooltip: 'Station Name',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.name;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Computer Name',
      field: 'machineName',
      headerTooltip: 'Computer Name',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.machineName;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      width: 100,
      cellRenderer: 'deletePrinterRendered',
      cellClass: ['button-edit', 'display-end'],
      cellRendererParams: { data: this },
    },
  ];

  ngOnInit(): void {
    this.paginatorModel = {
      totalRows: 0,
      totalPages: 0,
      pageSize: 25,
      currentPage: 1,
      leftArrowsDisabled: true,
      rightArrowsDisabled: true,
    };
    this.gridOptions = {};

    this.messageService.message
      .pipe(
        switchMap((data) => {
          if (data.data == 'delete') {
            this.loadingService.show("Loading Stations...");
            return this.manageStationService.deleteStation(
              data.stationId
            );
          }
          else if (data.data == 'edit') {
            this.loadingService.show("Loading Stations...");
            return this.manageStationService.createStation({ ...data.reference, stationID: data.stationId });
          }
        }),
        switchMap(() => {
          return this.manageStationService.getStations();
        }),
        map((stations) => {
          this.bindGridData(stations);
        })
      )
      .subscribe(() => {
        this.loadingService.clearMessage();
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    this.loadingService.show("Loading Stations...");
    this.manageStationService
      .getStations()
      .pipe(
        take(1),
        map((stations) => {
          this.bindGridData(stations);
        }),
        finalize(() => {
          this.loadingService.clearMessage();
        })
      )
      .subscribe();
  }

  onResizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  updateCurrentPage(): void {
    this.paginatorModel.currentPage = 1;
    this.paginatorModel.leftArrowsDisabled = true;
    if (this.paginatorModel.totalPages > 1) {
      this.paginatorModel.rightArrowsDisabled = false;
    }
  }

  handlePaginatorCommand(event: number): void {
    switch (event) {
      case 1:
        this.gridApi.paginationGoToFirstPage();
        break;
      case 2:
        this.gridApi.paginationGoToPreviousPage();
        break;
      case 3:
        this.gridApi.paginationGoToNextPage();
        break;
      case 4:
        this.gridApi.paginationGoToLastPage();
        break;
      default:
        this.gridApi.paginationSetPageSize(event);
        this.gridApi.paginationGoToFirstPage();
        break;
    }
  }

  setDataSource(returnedData) {
    this.gridApi.purgeInfiniteCache();
    this.bindGridData(returnedData);
  }

  onAddStation() {
    this.dialog
      .open(EditStationModalComponent, { width: '414px', data: null })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.loadingService.show("Loading Stations...");
          this.manageStationService.createStation(dialogResult).pipe(
            switchMap(() => {
              return this.manageStationService.getStations();
            }),
            map((stations) => {
              this.bindGridData(stations);
            })
          ).subscribe(_ => {
            this.loadingService.clearMessage();
          });
        }
      });
  }

  private bindGridData(data): void {
    var dataSource = {
      rowCount: null,
      getRows: function (params) {
        setTimeout(function () {
          var dataAfterSorting = sortData(params.sortModel, data);
          var rowsThisPage = dataAfterSorting.slice(
            params.startRow,
            params.endRow
          );
          var lastRow = dataAfterSorting.length;
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      },
    };

    this.paginatorModel.totalRows = data.length;
    this.paginatorModel.totalPages = Math.ceil(
      data.length / this.paginatorModel.pageSize
    );

    if (this.paginatorModel.totalRows == 0) {
      this.gridApi.setDatasource(dataSource);
      this.gridApi.showNoRowsOverlay();
    } else {
      this.updateCurrentPage();
      this.gridApi.hideOverlay();
      this.gridApi.setDatasource(dataSource);
    }
  }
}
