import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LookupModel } from '../models/lookup.model';
import { MaterialModel, MaterialViewModel } from '../models/material.model';
import { ReceivingStateViewModel, SessionModel } from '../models/session.model';
@Injectable({
  providedIn: 'root'
})
export class ReceivingStateService {
  private materialListSource = new BehaviorSubject<MaterialModel[]>([]);
  private receivingStateSource = new BehaviorSubject<ReceivingStateViewModel>(null);
  private receivingSession = new BehaviorSubject<SessionModel>(null);
  private countriesStateSource = new BehaviorSubject<LookupModel[]>(null);
  private materialListSubscription = this.materialListSource.asObservable();
  private receivingStateSubscription = this.receivingStateSource.asObservable();
  private receivingSessionSubscription = this.receivingSession.asObservable();
  private countriesSubscription = this.countriesStateSource.asObservable();

  constructor() { }

  public setMaterialList(materials: MaterialModel[]) {
    this.materialListSource.next(materials);
  }

  public setReceivingStateModel(receivingStateModel: ReceivingStateViewModel) {
    this.receivingStateSource.next(receivingStateModel);
  }

  public setReceivingSession(session: SessionModel) {
    this.receivingSession.next(session);
  }
  
  public setCountries(CountrieslookupModel: LookupModel[]) {
    this.countriesStateSource.next(CountrieslookupModel);
  }

  public resetReceivingState() {
    this.materialListSource.next([]);
    this.receivingStateSource.next(null);
    this.receivingSession.next(null);
  }

  //Return the Last list of Materials fetched from the api
  public getReceivingStateModel(): Observable<ReceivingStateViewModel> {
    return this.receivingStateSubscription;
  }

  //Return the active Material
  public getMaterialList(): Observable<MaterialModel[]> {
    return this.materialListSubscription;
  }

  //Return the active Receiving Session
  public getReceivingSession(): Observable<SessionModel> {
    return this.receivingSessionSubscription;
  }

   //Return the Countries
   public getCountries(): Observable<LookupModel[]> {
    return this.countriesSubscription;
  }

  public updateMaterialModel(materialModel: MaterialViewModel) {
    if (this.receivingStateSource.value) this.receivingStateSource.value.material = materialModel;
  }

}

@Injectable()
export class MockReceivingStateService {
  private materialListSource = new BehaviorSubject<MaterialModel[]>([]);
  materialListSubscription = this.materialListSource.asObservable();

  private materialSelected = new BehaviorSubject<Partial<MaterialViewModel>>({
    materialId: '100', materialName: 'selectedMaterial', materialTypeId: 1, alternateName: 'selectedMaterial',
    SDS: false,
    COA: false,
    metadata: {},
    supplierId: "1",
    isGLP: false,
    GLP: "0",
    lotNumber: "Test"
  });
  materialSelectedSubscription = this.materialSelected.asObservable();

  constructor() { }

  public setMaterialList(materials: MaterialModel[]) {
    this.materialListSource.next(materials);
  }

  public setMaterial(materialSelected: MaterialViewModel): void {
    this.materialSelected.next(materialSelected);
  }
}