import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../configuration';
import { ContainerWeights } from '../../models/container-weights';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';

@Injectable({ providedIn: 'root' })
export class ContainerWeightsService {

    constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
    }

    getContainerWeights() {
        var URL = Configuration.REST_API_URL + `/ContainerWeights`;
        return this.http.get<ContainerWeights[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getContainerWeightsByID(orderID: any) {
        var URL = Configuration.REST_API_URL + `/ContainerWeights/ByOrder/${orderID}`;
        return this.http.get<ContainerWeights[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    deleteContainerWeights(containerId: number) {
        var URL = Configuration.REST_API_URL + `/ContainerWeights/${containerId}`;
        return this.http.delete<ContainerWeights[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    addContainerWeight(containerWeight: ContainerWeights) {
        var URL = Configuration.REST_API_URL + `/ContainerWeights`;
        return this.http.post<ContainerWeights>(URL, containerWeight).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    saveContainerWeights(orderID: any, containerWeights: ContainerWeights[]) {
        var URL = Configuration.REST_API_URL + `/ContainerWeights/ByOrder/${orderID}`;
        return this.http.post<ContainerWeights>(URL, containerWeights).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    updateContainerWeight(containerWeight: ContainerWeights) {
        var URL = Configuration.REST_API_URL + `/ContainerWeights/${containerWeight.containerWeightID}`;
        return this.http.put<ContainerWeights>(URL, containerWeight).pipe(catchError(err => this.responseHandler.handleError(err)));
    }
}