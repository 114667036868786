<h5 class="p-mb-3">Change Warehouse</h5>

<form [formGroup]="warehouseStationForm">
    <div style="margin-bottom: 10px;">
        <div>New Warehouse: </div>
        <p-dropdown id="warehouses" placeholder="Select a warehouse" formControlName="warehousectrl"
            [(ngModel)]="selectedWarehouse" [options]="warehousesRes" optionLabel="itemDesc" class="warehouses-dropdown"
            panelStyleClass="station-selection-minWidthOverride">
        </p-dropdown>
    </div>
    <div>
        <textarea rows="6" cols="50" formControlName="reasonToChange" placeholder="Reason for Warehouse Change"></textarea>
    </div>
</form>

<mat-dialog-actions style="float: right; min-height: 80px;">
    <button mat-raised-button style="margin-right: 10px;" color="cor-primary" [disabled]="!warehouseStationForm.valid" (click)="onMove()">Move Order</button>
    <button mat-raised-button color="cor-default"mat-dialog-close (click)="onClose()">Cancel</button>
</mat-dialog-actions>