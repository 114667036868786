<ag-grid-angular
    #grid
    style="width: 100%; height: 350px;"
    class="ag-theme-alpine"
    [rowData]="items"
    [columnDefs]="columnDefs"
    [enableColResize]="true"
    (gridSizeChanged)="onResizeGrid($event)"
    (gridReady)="onGridReady($event)"
    (enableCellChangeFlash)="true"
    [frameworkComponents]='frameworkComponents'>
</ag-grid-angular>
<div class="total-weight">
    <span>Total Weight: {{totalWeight}} kg</span>
</div>