import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorDialogService } from '../error-dialog-service/error-dialog.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public errorDialogService: ErrorDialogService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
          //this.errorDialogService.openDialog({ request: request, status: '500' });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 500) {
          let data = { error: error };
          this.errorDialogService.openDialog(data);
        }
        return throwError(error);
      }));
  }
}