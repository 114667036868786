import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'text-inline-edit',
  templateUrl: './text-inline-edit.component.html',
  styleUrls: ['./text-inline-edit.component.scss']
})
export class TextInlineEditComponent implements OnInit, OnChanges {

  private _comments: string;
  @Input()
  public get Comments(): string {
    return this._comments;
  }
  public set Comments(v: string) {
    this._comments = v;
  }

  @Input()
  readOnly: boolean = false;

  @Input()
  disableSave: boolean = false;

  @Input()
  isEditing: boolean = false;

  @Output()
  saveNotes: EventEmitter<any> = new EventEmitter();

  @Output()
  editEmitter: EventEmitter<any> = new EventEmitter();

  @Output()
  cancelEmitter: EventEmitter<any> = new EventEmitter();

  @ViewChild('textArea')
  textArea: ElementRef;

  @Input()
  disableTextEdit: boolean = false;

  showEdit: boolean = false;
  backedNotes: string = "";

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.Comments && changes.Comments.currentValue)
      this.backedNotes = JSON.parse(JSON.stringify(changes.Comments.currentValue));
  }

  ngOnInit(): void {
  }

  public onEdit() {
    this.editEmitter.emit();
    this.isEditing = true;
  }

  onChange(event) {
  }

  public onSave() {
    if (!this.isEditing) return;
    this.isEditing = false;
    this.backedNotes = JSON.parse(JSON.stringify(this.Comments));
    this.saveNotes.emit(this.Comments);
  }

  onCancel() {
    this.isEditing = false;
    this.Comments = JSON.parse(JSON.stringify(this.backedNotes));
    this.cancelEmitter.emit();
  }

}
