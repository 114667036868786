<div style="height: 88%">
    <h5 class="p-mb-3">Destination Guide Information</h5>
    <div class='page-wrapper'>
        <div class='row'>
            <div class='column'>
                <div class='header-column'>
                    Destination Country Name
                </div>
            </div>
            <div class='column'>
                <div class='green-column'>
                    {{data.destinationGuideData.countryName}}
                </div>
            </div>
            <div class='column'>
                <div class='header-column'>

                </div>
            </div>
            <div class='column'>
                <div class='green-column'>

                </div>
            </div>
        </div>
        <div class='row'>
            <div class='column'>
                <div class='header-column'>
                    Region
                </div>
            </div>
            <div class='column'>
                <div class='green-column'>
                    {{data.destinationGuideData.regionName}}
                </div>
            </div>         
            <div class='column'>
                <div class='header-column'>
                    Tier Status
                </div>
            </div>
            <div class='column'>
                <div class='green-column'>
                    {{data.destinationGuideData.exportTierOptionText}}
                </div>
            </div>
        </div>
        <div class='row'>
            <div class='column'>
                <div class='header-column'>
                    Language
                </div>
            </div>
            <div class='column'>
                <div class='green-column'>
                    {{data.destinationGuideData.languageOptionText}}
                </div>
            </div>
            <div class='column'>
                <div class='header-column'>
                    Secondary Language
                </div>
            </div>
            <div class='column'>
                <div class='green-column'>
                    {{data.destinationGuideData.secondaryLanguageOptionText}}
                </div>
            </div>
        </div>
        <div class='row'>
            <div class='column'>
                <div class='header-column'>
                    SDS Info
                </div>
            </div>
            <div class='column'>
                <div class='green-column'>
                    <ul *ngFor="let sdsInfo of data.destinationGuideData.sdsInfoText">
                        {{sdsInfo}}
                    </ul>
                </div>
            </div>
            <div class='column'>
                <div class='header-column'>
                    Required Documents
                </div>
            </div>
            <div class='column'>
                <div class='green-column'>
                    {{data.destinationGuideData.requiredDocumentOptionText}}
                </div>
            </div>
        </div>
        <div class='row'>
            <div class='column'>
                <div class='header-column'>
                    Special Documents
                </div>
            </div>
            <div class='column'>
                <div class='green-column'>
                    <ul *ngFor="let specialDocument of data.destinationGuideData.specialDocumentsText">
                        {{specialDocument}}
                    </ul>
                </div>
            </div>
            <div class='column'>
                <div class='header-column'>
                    Carrier
                </div>
            </div>
            <div class='column'>
                <div class='green-column'>
                    <ul *ngFor="let shipper of data.destinationGuideData.shipperRankings">
                        {{shipper?.rankId}} - {{shipper?.shipperName}}
                    </ul>
                </div>
            </div>
        </div>
        <div class='row'>
            <div class='column'>
                <div class='header-column'>
                    Label Info
                </div>
            </div>
            <div class='column'>
                <div class='green-column'>
                    <ul *ngFor="let labelInfo of data.destinationGuideData.labelInfoText">
                        {{labelInfo}}
                    </ul>
                </div>
            </div>
            <div class='column'>
                <div class='header-column'>
                    Green light
                </div>
            </div>
            <div class='column'>
                <div class='green-column'>
                    {{data.destinationGuideData.greenLightOptionText}}
                </div>
            </div>
        </div>
        <div class='row'>
            <div class='column'>
                <div class='header-column'>
                    Country Specific Labels
                </div>
            </div>
            <div class='column'>
                <div class='green-column'>
                    <mat-card-content [innerText]="data.destinationGuideData.countrySpecificLabeling"></mat-card-content>
                </div>
            </div>
        </div>
        <div class='column'>
            <div class='header-column'>
                Single Use Plastic Tax
            </div>
        </div>
        <div class='column'>
            <div class='green-column'>
                <mat-card-content [innerText]="data.destinationGuideData.singleUsePlasticTaxText"></mat-card-content>
            </div>
        </div>
        <div class='column'>
            <div class='header-column'>
                Broker
            </div>
        </div>
        <div class='column'>
            <div class='green-column'>
                <mat-card-content [innerText]="data.destinationGuideData.broker"></mat-card-content>
            </div>
        </div>
        <div class='column'>
            <div class='header-column'>
                Alternate Broker
            </div>
        </div>
        <div class='column'>
            <div class='green-column'>
                <mat-card-content [innerText]="data.destinationGuideData.alternateBroker"></mat-card-content>
            </div>
        </div>
        <div class='column'>
            <div class='header-column'>
                Importer
            </div>
        </div>
        <div class='column'>
            <div class='green-column'>
                <mat-card-content [innerText]="data.destinationGuideData.importer"></mat-card-content>
            </div>
        </div>
        <div class='column'>
            <div class='header-column'>
                Contact
            </div>
        </div>
        <div class='column'>
            <div class='green-column'>
                <mat-card-content [innerText]="data.destinationGuideData.contact"></mat-card-content>
            </div>
        </div>
        <div class='column'>
            <div class='header-column'>
                Notes
            </div>
        </div>
        <div class='column'>
            <div class='green-column'>
                <mat-card-content [innerText]="data.destinationGuideData.notes"></mat-card-content>
            </div>
        </div>
    </div>
</div>
<div class="actions flex-end p-mt-3">
    <button mat-raised-button color="cor-default" (click)="onClose()" color="cor-primary">Close</button>
</div>