import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatPipe } from './date-format-pipe';
import { WeightFormatKGPipe } from './weight-format-kg-pipe';


@NgModule({
  declarations: [DateFormatPipe, WeightFormatKGPipe],
  imports: [
    CommonModule
  ],
  exports: [DateFormatPipe, WeightFormatKGPipe]
})
export class AppDirectiveModule { }
