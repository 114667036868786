import { Resource } from './shared-models';
import { OrderItemModel } from './order-item-model';
import { OrderAttachment } from './attachment-models';
import { OrderItemContainerModel } from './order-item-container-model';

export interface OrderListRequest {
    orderStatusID?: number;
    orderStatuses?: number[];
    countryID?: number;
    updatedStartDate?: any;
    updatedEndDate?: any;
    isOnHold?: boolean;
    requestNumber?: any;
    trackingNymber?: string;
    includeItems?: boolean;
}

export interface OrderListResponse {
    orders: Order[],
    pageSize: number,
    pageNumber: number,
    totalItems: number
}

export interface Request {
    requestID: string,
    requestNumber: string,
    requestType: 'O' | 'P'
}

export interface Order extends Resource {
    orderID: string;
    orderStatusID: number;
    orderStatus: string;
    orderStatusDesc: string;
    assignedToUserID: number;
    assignedToFirstName: string;
    assignedToLastName: string;
    assignedToFullName: string;
    requestID: number;
    requestNumber: string;
    requestTypeID: number;
    requestTypeDesc: string;
    requestorName: string;
    requestorPhone: string;
    requestorPhoneType: string;
    requestorEmail: string;
    recipientName: string;
    recipientOrganizationName: string;
    items: OrderItemModel[];
    isOnSiteOrder?: boolean;
    isOnSiteText?: string;
    shipByDate: string;
    priorityName: string;
    daysUntilDue: number;
    numberOfItems: number;
    countryName: string;
    chemicalsRequested: string | string[];
    created: string;
    tags: any[];
    specialInstructions: string;
    attachments: OrderAttachment[];
    useReservation?: boolean;
    coordinatorNotes?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    countryID?: number;
    stateProvince?: string;
    postalCode?: string;
    primaryPhone?: string;
    email?: string;
    isOnHold?: boolean;
    holdNotes?: string;
    holdReasonIDs?: number[];
    holdReasons?: string;
    showStatusForOnHold?: boolean;
    relatedOrders?: RelatedOrdersResponse[];
    isRequestorEmailSuppressed?: boolean;
    isStaged?: boolean;
    warehouseID: number;
    warehouseName: string;
}
class RelatedOrdersResponse {
    orderID: string;
    requestNumber: string;
}

export enum OrderStatuses {
    New = 1,
    Pending = 2,
    Ready = 3,
    Warehouse = 4,
    Pick = 5,
    Decant = 6,
    Shipping = 7,
    Complete = 8,
    SGSRequested = 9,
    SGSComplete = 10,
    Cancelled = 11
}

export enum P2POrderStatuses {
    New = 1,
    Pending = 2,
    Complete = 3,
    Cancelled = 4
}

export interface CancelOrder {
    cancelReasonID: number,
    comment: string
}

export interface CancelReason {
    reasonID: number,
    name: string,
    requireComment: boolean
}

export interface OnHoldReason {
    reasonID: number,
    name: string,
    requireComment: boolean
}

export interface HoldReason {
    reasonID: number,
    name: string,
    requireComment: boolean,
    allowDelete: boolean
}

export enum OrderStatusDescriptions {
    New = 'New',
    Pending = 'Pending',
    Ready = 'Ready',
    Warehouse = 'Warehouse',
    Pick = 'Pick',
    Decant = 'Decant',
    Shipping = 'Shipping',
    Complete = 'Complete',
    SGSRequested = 'SGS Requested',
}

export enum RequestTypes {
    Stock = 1,
    GLP = 2,
    P2P = 3,
}

export enum DocumentTypes {
    COA = 5,
    ExportDocument = 3,
    GLPReceipt = 8,
    Invoice = 1,
    Label = 9,
    Other = 6,
    PackingSlip = 4,
    Report = 7,
    SDS = 2
}

export interface OrderTransferRequest {
    coordinatorNotes?: string;
}

export interface OrderItemEarmarksByCatalogIDResponse {
    orderItemID: string;
    amountTotal: number;
    amountTotalConverted: number;
    hasEarmarks: number;
    orderStatusDescription: string;
    requestNumber?: string;
    city?: string;
    stateProvince?: string;
    countryName?: string;
    lotNumber?: string;
    requestTypeDesc?: string;
    useReservation: boolean;
}

export interface HoldModalData {
    isOnHold: boolean;
    holdReason?: OnHoldReason;
    holdNotes?: string;
    holdReasonID?: number;
}

export interface EditPropertyModalData {
    propertyDisplayName: string;
    oldValue: string;
}

export interface OrderHoldRequest {
    placeOnHold: boolean;
    holdNotes?: string;
    holdReasonIDs: number[];
}

export interface UpdateOrderHoldNotesRequest {
    holdNotes: string;
}


export interface OrderContentsResponse {
    orderItemID: string;
    requestedContainerLabel: string;
    requestedLotNumber: string;
    requestedAmount: number;
    requestedAmountAndMeasure: string;
    reservationTypeID: any;
    reservationTypeDesc: string;
    unitOfMeasureDesc: string;
    unitOfMeasureID: number;
    isDecantedInt: number;
    isDecanted?: boolean;
    isAssignedInt: number;
    isAssigned?: boolean;
    numberOfContainers?: number;
    totalnumberOfContainers?: number;
    allContainers: boolean;
    numbertoMove: number;
    moveSome: boolean;
    moveAll?: boolean;
    isMoveDisable: boolean;
    pendingNoOfContainers: number;
}

export interface OrderPackage {
    orderPackageID: string;
    orderID: string;
    shipperID: number;
    isMaster: boolean;
    trackingNumber?: string;
    shipperName?: string;
    trackingURLFormat?: string;
    trackingURL?: string;
    packageCode?: string;
    shippingConditions?: string;
    containers?: OrderItemContainerModel[];
}

export interface PackageAssignmentRequest {
    orderItemContainerID: string;
}

export interface OrderPackageRequest {
    shipperID: number;
    isMaster: boolean;
    trackingNumber?: string;
}

export interface UpdateRequestedContainerLabelRequest {
    requestedContainerLabel: string;
}