import { Order } from '../models/order-models';

export function removeOrderByID(orders: Order[], orderID: string) {
    return orders.filter(order => order.orderID !== orderID);
} 

export function sortOrdersByNeedByDate(orders: Order[]) {
    return orders.sort(function(a,b){
        if (a.shipByDate === null) {
            return 1;
        }
        else if (b.shipByDate === null) {
            return -1;
        }
        else {
            const dateOne = new Date(a.shipByDate);
            const dateTwo = new Date(b.shipByDate);
            return dateOne.getTime() - dateTwo.getTime() ;
        }
    });
}

export function sortOrdersByUpdatedDate(orders: Order[]) {
    return orders.sort(function(a,b){
        if (a.updated === null) {
            return 1;
        }
        else if (b.updated === null) {
            return -1;
        }
        else {
            const dateOne = new Date(a.updated);
            const dateTwo = new Date(b.updated);
            return dateTwo.getTime() - dateOne.getTime();
        }
    });
}

export function sortOrdersByShipDate(orders: Order[]) {
    return orders.sort(function(a,b){
        if (a.shipByDate === null) {
            return -1;
        }
        else if (b.shipByDate === null) {
            return 1;
        }
        else {
            const dateOne = new Date(a.shipByDate);
            const dateTwo = new Date(b.shipByDate);
            return  dateOne.getTime() - dateTwo.getTime();
        }
    });
}

