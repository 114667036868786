import { Component } from '@angular/core';
import { MessageService } from '../../../shared/services/message-queue.service';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { HoldReasonModalComponent } from '../hold-reason-modal/hold-reason-modal.component';

@Component({
  selector: 'app-manage-hold-reason-actions',
  templateUrl: './manage-hold-reason-actions.component.html',
  styleUrls: ['./manage-hold-reason-actions.component.scss']
})
export class ManageHoldReasonActionsComponent implements ICellRendererAngularComp {

  private params: any;
  constructor(private dialog: MatDialog,
    private readonly messageService: MessageService) { }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void { }

  ngOnInit(): void { }

  onEditHoldReason() {
    this.dialog
      .open(HoldReasonModalComponent, { width: '414px', data: this.params.data })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({ data: 'edit', reference: dialogResult });
        }
      });
  }

  onDeleteHoldReason() {
    const confirmDialog = new ConfirmDialogModel(
      'Confirm Action',
      `This reason will be deleted. Are you sure?`,
      'OK',
      false,
      true,
      'Cancel'
    );
    this.dialog
      .open(ConfirmDialogComponent, { maxWidth: '400px', data: confirmDialog })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({ data: 'delete', reference: this.params.data });
        }
      });
  }

  canBeDeleted(): boolean {
    return this.params.data.allowDelete;
  }

}
