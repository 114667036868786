import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Order } from 'src/app/models/order-models';
import { ChangeStatusModalComponent } from './change-status-modal/change-status-modal.component';

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.scss'],
})
export class ChangeStatusComponent implements OnInit {
  @Input() order: Order;
  @Output() onStatusChange: EventEmitter<any> = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  onChangeStatus(): void {
    this.dialog
      .open(ChangeStatusModalComponent, {
        width: '1000px',
        height: '430px',
        data: { order: this.order },
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        if (res) {
          this.onStatusChange.emit(res);
        }
      })
  }
}
