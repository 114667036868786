import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { Configuration } from '../../configuration';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LabelDesignService {
  readonly rootURL = Configuration.REST_API_URL;

  constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
  }
 
  updateLabelDesign(payload) {
    var URL = this.rootURL + `/LabelDesign`;
    return this.http.post<any>(URL, payload).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  deleteLabelDesign(labelID: number): Observable<Response> {
    var URL = this.rootURL + `/Label/${labelID}`;
    return this.http.delete<Response>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getLabelDesign() {
    var URL = this.rootURL + `/LabelDesign`;
    return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getLabelDesignById(labelID: number) {
    var URL = this.rootURL + `/LabelDesign/${labelID}`;
    return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

}