import { OrderItemModel } from 'src/app/models/order-item-model';
import { CancelOrder, OnHoldReason } from './../../models/order-models';
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { of, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/base-component';
import { AttachmentEntity, CreateOrderAttachmentRequest, OrderAttachment, UpdateOrderAttachmentRequest } from 'src/app/models/attachment-models';
import { User } from 'src/app/models/security-models';
import { Tag } from 'src/app/models/tag';
import { DownloadAttachmentService } from 'src/app/services/download-attachment-service/download-attachment.service';
import { PdfService } from 'src/app/services/pdf-service/pdf-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { UserService } from 'src/app/services/user-service/user-service.service';
import { Order, OrderHoldRequest, OrderPackage, OrderPackageRequest, OrderStatusDescriptions, OrderStatuses, RequestTypes } from '../../models/order-models';
import { OrderService } from '../../services/order-service/order-service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { HoldModalComponent } from '../hold-modal/hold-modal.component';
import { OrderAssignModalComponent, OrderAssignModalModel } from '../order-assign-modal/order-assign-modal.component';
import { OrderItemDetailsComponent } from '../order-item-details/order-item-details.component';
import { RequestDocumentsModalComponent } from '../request-documents-modal/request-documents-modal.component';
import { RequestTransferModalComponent } from '../request-transfer-modal/request-transfer-modal.component';
import { OrderHistoryModalModel, ViewHistoryModalComponent } from '../view-history-modal/view-history-modal.component';
import { SplitRequestModalComponent } from '../split-request-modal/split-request-modal.component';
import { ShippingInfoModalComponent } from 'src/app/packing-and-shipping/shipping-info-modal/shipping-info-modal.component';
import { PermissionService } from 'src/app/services/permission-service/permission-service';
import { OrderItemAttachmentsComponent } from '../attachments/attachments.component';
import { ConfirmCancelOrderModalComponent } from '../confirm-cancel-order-modal/confirm-cancel-order-modal.component';
import { catchError, take } from 'rxjs/operators';
import { TextInlineEditComponent } from '../text-inline-edit/text-inline-edit.component';
import { MultiSelect } from 'primeng/multiselect';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { OrderItemContainerModel } from 'src/app/models/order-item-container-model';
import { ContainerWeightsService } from 'src/app/services/container-weights/container-weights.service';
import { TrackingDetailsModalComponent } from 'src/app/components/tracking-details-modal/tracking-details-modal.component';
import { ContainerWeights } from 'src/app/models/container-weights';
import { DestinationGuide } from 'src/app/models/destination-guide';
import { FeatureService } from '../../services/feature-switch-service/feature.service';
import { ChangeWarehouseStationComponent } from 'src/app/shared/components/change-warehouse-station/change-warehouse-station.component';
import { FeatureType } from '../../models/feature-type.enum';
import { DestinationGuideInfoModalComponent } from '../../shared/components/destination-guide-info-modal/destination-guide-info-modal.component';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent extends BaseComponent implements OnDestroy {

  private subscriptions = new Subscription();
  order: Order;
  uuid: any;
  public reasons: OnHoldReason[] = [];
  public selectedReason: any[];
  public prevSelectedReason: any;
  disableReasons: boolean = true;
  tags: any[] = [];
  suggestions: Tag[] = [];
  orderStatus = '';
  public selectedCoordinator: any;
  coordinators: User[];
  coordinatorSelectList: SelectItem[];
  isAdvanceDisabled: boolean = true;
  public requestCoordinators: User[];
  carrier: any;
  carriers: any[];
  packages: any[];
  trackingNumbers: string;
  isMasterPackage = false;
  loggedInUser: any;
  isLoginUserCoordinator = false;
  isAllExpanded = false;
  canEditCoordinatorNotes: boolean = false;
  cancelOrder: CancelOrder;
  isSuppressed = false;
  isSGSavailable: boolean = false;
  useMultipleWarehouses: boolean = false;
  weights: ContainerWeights[] = [];
  destinationGuideData: DestinationGuide;

  attachmentEntity: AttachmentEntity;
  warehouseName: string;

  @ViewChild('textInlineEl') textInlineEl: TextInlineEditComponent;
  @ViewChild('orderItemDetails') orderItemDetailsInstance: OrderItemDetailsComponent;
  @ViewChild('onHoldAccordion') onHoldAccordion: ElementRef;
  @ViewChild('itemAttachments') itemAttachments: OrderItemAttachmentsComponent;
  @ViewChild('reasonSelect') reasonSelect: MultiSelect;

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private userService: UserService, private orderService: OrderService,
    private confirmationService: ConfirmationService, private responseHandler: ResponseHandlingService, private router: Router,
    private pdfService: PdfService, private downloadAttachmentService: DownloadAttachmentService,
    private containerWeightService: ContainerWeightsService, public permissionService: PermissionService,
    private loadingService: LoadingService,
    public readonly featureSwitchService: FeatureService) {

    super();
    this.uuid = this.route.snapshot.paramMap.get("id");
    this.loggedInUser = JSON.parse(localStorage.getItem("authUserInfo"));
    this.subscriptions.add(this.orderService.getOrderTagCarrierDetails(this.uuid)
      .subscribe(data => {
        this.order = data.orderDetails;

        this.setIsContainerAssignedValue(this.order.items);
        if (!this.filterContainers(this.order)) {
          this.loadingService.show('Loading data..', true, `Taking too long?`, 'click to close', this.loaderCallback.bind(this));
        }
        this.order?.coordinatorNotes ? localStorage.setItem("coordinatorNotes", this.order?.coordinatorNotes) : localStorage.setItem("coordinatorNotes", "");
        this.carriers = data.carriers;
        this.carriers.sort((a, b) => a.shipperName > b.shipperName ? 1 : -1);
        this.attachmentEntity = {
          id: this.order.orderID,
          name: this.order.requestNumber,
          attachments: this.order.attachments ? this.order.attachments : [],
        }
        this.orderStatus = this.order['orderStatusDesc']
        this.tags = data.orderDetails.tags;
        this.suggestions = data.tagDetails;
        this.canDisableSaveAndAdvance();
        this.coordinatorSelectList = [];
        this.getReasons();
        this.getPackages();
        this.isSuppressed = this.order?.isRequestorEmailSuppressed ?? false;
        this.canEditCoordinatorNotes = this.permissionService.validateUserPermission(451);
        this.isLoginUserCoordinator = this.loggedInUser.givenName === this.order?.assignedToFirstName && this.loggedInUser.surName === this.order?.assignedToLastName;
        this.subscriptions.add(this.userService.getUsersByPermission(400).subscribe(
          data => {
            if (data) {
              this.requestCoordinators = data;
            }
          }
        ));

        this.subscriptions.add(this.containerWeightService
          .getContainerWeightsByID(this.uuid)
          .pipe(take(1))
          .subscribe((weightsResponse) => {
            this.weights = weightsResponse;
            this.orderService.getDestinationGuide(this.order.countryID).subscribe(destinationGuideData => {
              this.destinationGuideData = destinationGuideData;
              this.disableSendToPicking = this.disableSendToPickingButton();
            });
          }));
      }
      ));

    if (localStorage.getItem("feature") === null) {
      this.featureSwitchService.retrieveFeatures().subscribe(resp => {
        localStorage.setItem('feature', JSON.stringify(resp));
        this.useMultipleWarehouses = resp.some((feature) => feature.featureID == FeatureType.UseMultipleWarehouses && feature.isActive);
        this.isSGSavailable = resp.some((feature) => feature.featureID == FeatureType.IsSGSAvailable && feature.isActive);
      });
    } else {
      const feature = JSON.parse(localStorage.getItem("feature"));
      this.useMultipleWarehouses = feature.some((feature: { featureID: FeatureType; isActive: any; }) => feature.featureID == FeatureType.UseMultipleWarehouses && feature.isActive);
      this.isSGSavailable = feature.some((feature) => feature.featureID == FeatureType.IsSGSAvailable && feature.isActive);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
  }

  setIsContainerAssignedValue(orderItems: OrderItemModel[]) {
    orderItems.forEach(_ => {
      _.containers.forEach(x => {
        x.isContainerAssigned = x.assignedLotNumber ? true : false;
      })
    })
  }
  getReasons() {
    this.orderService.getOnHoldReasons().subscribe(response => {
      this.reasons = response;
      this.selectedReason = [...new Set(this.order.holdReasonIDs)];
    })
  }

  getPackages(): void {
    this.orderService.getPackages(this.order.orderID).subscribe((res: OrderPackage[]) => {
      if (res) {
        this.packages = res.sort((a, b) => a.isMaster ? -1 : 1);
      }
    });
  }

  onCarrierChange(): void {
    if (!this.carrier.trackingURLFormat) {
      this.trackingNumbers = null;
    }
  }

  deletePackage(orderPackage: OrderPackage): void {
    const confirmDialog = new ConfirmDialogModel("Delete Package",
      `Confirm that you would like to delete package ${orderPackage.packageCode}.`,
      "Confirm", true);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      disableClose: true, data: confirmDialog
    })
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.orderService.deletePackage(orderPackage.orderPackageID).subscribe(() => {
            this.getPackages();
          });
        }
      });
  }

  onTrackDetails(orderPackage: OrderPackage): void {
  
    this.orderService
    .getPackageTrackingDetails(orderPackage.orderPackageID)
    .pipe(
      take(1),
    ).subscribe((events)=>{

      const data  = {
        data: events,
        package: orderPackage
      };

      this.dialog.open(TrackingDetailsModalComponent, { width: '1050px', height: '500px', data: data })
      .afterClosed()
      .subscribe((res: OrderPackageRequest) => {
        if (!res) { return; }
        
      });
    });
  }

  onEditShipping(orderPackage: OrderPackage): void {
    const data = {
      packageGrouping: orderPackage,
      carriers: this.carriers,
      isMaster: orderPackage.isMaster
    };
    this.dialog.open(ShippingInfoModalComponent, { width: '550px', height: '350px', data }).afterClosed().subscribe((res: OrderPackageRequest) => {
      if (!res) { return; }
      this.orderService.updateOrderPackage(orderPackage.orderPackageID, res).subscribe((updatedPackage: OrderPackage) => {
        this.responseHandler.showSuccess('Successfully updated shipping information');
        this.getPackages();
      });
    });
  }

  onOpenTracking(param, event?) {
    if (param.trackingNumber) {
      let url = param.trackingURLFormat.replace('{0}', param.trackingNumber);
      window.open(url, '_blank');
    }
    if (event) {
      event.stopPropagation();
    }
  }

  onSGSCompleted() {
    const dialogData =
      new ConfirmDialogModel("SGS Complete",
        `Confirm that Request has been completed by SGS.  This will Complete the Request`,
        "Confirm", false);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      disableClose: true,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.setOrderToComplete();
      }
    });
  }

  saveNotes(event) {
    if (event === localStorage.getItem("coordinatorNotes")) return;
    this.subscriptions.add(this.orderService.updateOrderWithTransferRequest(this.order.orderID, {
      "coordinatorNotes": event
    }).subscribe(response => {
      this.responseHandler.showSuccess(`Successfully saved coordinator notes for the request ${this.order.requestNumber}.`);
      this.order.coordinatorNotes = event;
    }));
  }

  onEdit() {
    this.disableReasons = false;
  }

  onCancel() {
    this.reasonSelect.hide();
    this.disableReasons = true;
    this.selectedReason = JSON.parse(JSON.stringify(this.prevSelectedReason));
  }

  saveHoldNotes(holdNotes: string): void {
    this.disableReasons = true;
    this.reasonSelect.hide();
    const request: OrderHoldRequest = {
      placeOnHold: true,
      holdReasonIDs: this.selectedReason,
      holdNotes: holdNotes,
    }
    this.orderService.toggleHold(this.order.orderID, request).subscribe(res => {
      this.order.holdNotes = holdNotes;
      this.order.holdReasonIDs = this.selectedReason;
      this.order.holdReasons = this.reasons.filter(r => (this.selectedReason).includes(r.reasonID))?.map(x => x.name)?.join(', ');
      this.prevSelectedReason = JSON.parse(JSON.stringify(this.selectedReason));
      this.responseHandler.showSuccess(`Successfully saved hold notes for the request ${this.order.requestNumber}.`);
    })
  }

  onTransferToIndy() {
    this.subscriptions.add(this.orderService.updateOrderStatus(this.order.orderID, 2).subscribe(response => {
      this.responseHandler.showSuccess(`Successfully moved request ${this.order.requestNumber} to Pending.`);
      this.order.orderStatus = this.orderStatus = 'Pending';
      this.order.orderStatusID = 2;
      this.orderItemDetailsInstance.resetOrderItemDetailButtons();
    }));
  }

  onAddPackage() {
    if (!this.trackingNumbers && this.carrier?.trackingURLFormat) { return; };

    let payload: OrderPackageRequest = {
      "shipperID": this.carrier.shipperID,
      "trackingNumber": this.trackingNumbers,
      isMaster: this.isMasterPackage
    }
    this.subscriptions.add(this.orderService.createOrderPackage(this.order.orderID, payload).subscribe(response => {
      this.responseHandler.showSuccess(`Successfully saved package for the request ${this.order.requestNumber}.`);
      this.trackingNumbers = '';
      this.carrier = '';
      this.isMasterPackage = false;
      this.getPackages();
    }));
  }

  setOrderToComplete() {
    this.orderService.updateOrderStatus(this.order.orderID, OrderStatuses.SGSComplete).subscribe(response => {
      this.responseHandler.showSuccess(`Successfully moved request ${this.order.requestNumber} to SGS Complete.`);
      this.order.orderStatus = this.orderStatus = 'SGS Complete';
      this.order.orderStatusID = OrderStatuses.SGSComplete;
    })
  }

  get attachmentPanelTitle(): string {
    if (this.order?.attachments && this.order?.attachments?.length > 0)
      return `Attachments (${this.order?.attachments?.length})`;
    else
      return `Attachments`;
  }
  get OrderStatuses(): typeof OrderStatuses {
    return OrderStatuses;
  }

  get disableSave(): boolean {
    let _disable = true;

    const requireCommentReasonIDs = this.reasons.filter(x => x.requireComment)?.map(x => x.reasonID);
    const requireComment = requireCommentReasonIDs?.some(id => this.selectedReason.includes(id));

    if ((this.selectedReason && !requireComment) || (requireComment && this.textInlineEl?.Comments)) {
      _disable = false;
    }

    return _disable;
  }

  onTagsSaved(event) {
    var tags = this.tags.map(tag => tag.tagName);
    this.orderService.saveTags(this.uuid, { tags: tags }).subscribe(result => {
      this.responseHandler.showSuccess(`Successfully saved tags`);
    },
      err => {
        this.responseHandler.showError(`Failed to saved tags`);
      })
  }

  onSendToPicking() {
    if (!this.order) return;
    const orderStatus = this.order['orderStatusID'];

    if (orderStatus === OrderStatuses.Ready || orderStatus === OrderStatuses.Warehouse) return;

    this.subscriptions.add(this.orderService
      .updateOrderStatus(this.order.orderID, 5).subscribe(
        data => {
          this.responseHandler.showSuccess(`Successfully moved request ${this.order.requestNumber} to picking.`);
          this.router.navigate(['../prep'], { relativeTo: this.route });
        }
      )
    );
  }

  onInventorySelection(event) {
    this.isAdvanceDisabled = event;
  }

  onPrintPicklist() {
    this.orderService.getOrderDetail(this.uuid).subscribe(order => {
      this.pdfService.generatePdfPicklist(order);
    })
  }

  canDisableSaveAndAdvance() {
    if (!this.order) return;
    const orderStatus = this.order['orderStatusID'];
    this.isAdvanceDisabled = orderStatus === OrderStatuses.New || orderStatus === OrderStatuses.Pending || orderStatus === OrderStatuses.Ready || orderStatus === OrderStatuses.Warehouse || orderStatus === OrderStatuses.Pick
      || orderStatus === OrderStatuses.Shipping || orderStatus === OrderStatuses.Decant || orderStatus === OrderStatuses.Complete
  }

  get saveAndAdvanceLabel() {
    if (!this.order) return;
    const orderStatus = this.order['orderStatusID'];
    return orderStatus === OrderStatuses.Ready || orderStatus === OrderStatuses.Warehouse ? 'Save' : 'Advance';
  }

  get orderStatuses(): typeof OrderStatuses {
    return OrderStatuses;
  }

  onViewHistory() {
    this.orderService.getOrderHistory(this.order.orderID).subscribe(response => {
      let dialogData = new OrderHistoryModalModel(`Request History`, response);
      const dialogRef = this.dialog.open(ViewHistoryModalComponent, {
        minWidth: "300px",
        maxWidth: '50vw',
        disableClose: true,
        data: dialogData
      });
    });
  }

  reassignCoordinatorModal() {
    let dialogData;

    if (this.order?.assignedToFirstName && this.order?.assignedToFirstName !== '') {
      dialogData = new OrderAssignModalModel(`Reassign Coordinator`, '', this.requestCoordinators, this.selectedCoordinator);
    }
    else {
      dialogData = new OrderAssignModalModel(`Assign Coordinator`,
        `Select a Request Coordinator for request ${this.order.requestNumber}.`,
        this.requestCoordinators,
        `Assigning a coordinator will update the request status to Pending and move it to the Request Prep screen.`);
    }

    const dialogRef = this.dialog.open(OrderAssignModalComponent, {
      minWidth: "400px",
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe((res: User) => {
      if (res) {
        // If the order had not been previously assigned, we are re-getting this order in case of stale data
        if (!this.order.assignedToUserID) {
          this.orderService.getOrderDetail(this.order.orderID).subscribe((orderDetailResponse: Order) => {
            if (orderDetailResponse.assignedToUserID) {
              const data: ConfirmDialogModel = {
                title: 'Confirm Re-Assignment', message: `Request is assigned to ${orderDetailResponse.assignedToFirstName} ${orderDetailResponse.assignedToLastName}. Do you want to reassign it to ${res.firstName} ${res.lastName}?`,
                useDangerConfirm: false, confirmButtonLabel: 'Confirm'
              }
              this.dialog.open(ConfirmDialogComponent, {
                disableClose: true, data
              }).afterClosed().subscribe(confirmResponse => {
                if (confirmResponse) {
                  this.assignOrder(res);
                }
              });
            } else {
              this.assignOrder(res);
            }
          });
        } else {
          this.assignOrder(res);
        }
      }
    });
  }

  assignOrder(coordinator: User) {
    this.subscriptions.add(
      this.orderService.assignOrder(this.order.orderID, coordinator.userID).subscribe(
        data => {
          this.order.assignedToFirstName = coordinator.firstName;
          this.order.assignedToLastName = coordinator.lastName;
          this.order.assignedToUserID = coordinator.userID;
          this.isLoginUserCoordinator = this.loggedInUser.givenName === this.order?.assignedToFirstName && this.loggedInUser.surName === this.order?.assignedToLastName;

          this.subscriptions.add(this.orderService.getOrderDetail(this.order.orderID).subscribe((order: any) => {
            this.order.orderStatus = this.orderStatus = order.orderStatusDesc;
            this.order.orderStatusID = order.orderStatusID;
            this.orderItemDetailsInstance.resetOrderItemDetailButtons();
          }));

          this.responseHandler.showSuccess(`Successfully assigned request ${this.order.requestNumber} to coordinator ${coordinator.firstName} ${coordinator.lastName}.`);
        }
      )
    );
  }

  createOrderAttachment(val: CreateOrderAttachmentRequest) {
    this.orderService.createOrderAttachment(val.uploadedFiles, val.orderID, val.documentTypeID, val.languageID, val.comments).subscribe((res: OrderAttachment) => {
      this.order.attachments.push(res);
    })
  }

  updateOrderAttachment(val: UpdateOrderAttachmentRequest) {
    const payload = { comments: val.comments, documentTypeID: val.documentTypeID, languageID: val.languageID };
    this.orderService.updateOrderAttachment(val.orderAttachmentID, payload).subscribe((res: OrderAttachment) => {
      const index = this.order.attachments.findIndex(a => a.orderAttachmentID === res.orderAttachmentID);
      this.order.attachments.splice(index, 1);
      this.order.attachments.push(res);
      this.order.attachments.sort((a, b) => a.filename > b.filename ? 1 : -1);
    },
      error => {
        this.confirmationService.close();
        this.responseHandler.showError(error.message, 5000);
      },
      () => {
        this.responseHandler.showSuccess(`Attachment ${val.filename} updated successfully`, 5000);
      });
  }

  removeOrderAttachment(val) {
    this.orderService.deleteOrderAttachment(val.attachment.orderAttachmentID).subscribe(res => {
      const index = this.order.attachments.findIndex(a => a.orderAttachmentID === val.attachment.orderAttachmentID);
      this.order.attachments.splice(index, 1);
    },
      error => this.responseHandler.showError(error.message, 5000),
      () => this.responseHandler.showSuccess(`Attachment ${val.attachment.filename} successfully removed`, 5000));
  }

  downloadAttachment(val: OrderAttachment): void {
    this.orderService.getOrderAttachment(val.orderAttachmentID).subscribe(response => {
      this.downloadAttachmentService.download(val.filename, response.fileContents);
    },
      error => {
        this.responseHandler.showError(error.message, 5000);
      });
  }

  requestTransferModal(): void {
    this.dialog.open(RequestTransferModalComponent).afterClosed().subscribe((res: boolean) => {
      if (!res) { return; }
      this.orderService.updateOrderStatus(this.order.orderID, OrderStatuses.SGSRequested).subscribe(() => {
        this.order.orderStatus = this.orderStatus = OrderStatusDescriptions.SGSRequested;
        this.order.orderStatusID = OrderStatuses.SGSRequested;
        this.orderItemDetailsInstance.resetOrderItemDetailButtons();
      }, error => this.responseHandler.showError('There was an error submitting the transfer request.'),
        () => this.responseHandler.showSuccess('Successfully submitted the transfer request.'));
    });
  }

  requestDocumentsModal(): void {
    this.dialog.open(RequestDocumentsModalComponent, {
      data: { order: this.order },
      disableClose: true
    }).afterClosed().subscribe((res: string) => {
      if (res == 'Customs Invoice')
        this.orderService.getOrderDetail(this.uuid).subscribe(order => {
          this.orderItemDetailsInstance?.updateOrderItems(order);
        })
    });
  }

  splitRequestModal(): void {
    this.dialog.open(SplitRequestModalComponent, {
      data: { orderId: this.uuid },
      disableClose: true
    }).afterClosed().subscribe((res: string) => {
    });
  }

  packingMaterialsModal(): void {
    this.router.navigate(['/packing/materials/', this.order.orderID]);
  }

  downloadAttachmentFromTitle(event: Event, attachment: OrderAttachment) {
    this.orderService.getOrderAttachment(attachment.orderAttachmentID).subscribe(response => {
      this.downloadAttachmentService.download(attachment.filename, response.fileContents);
    },
      error => {
        this.responseHandler.showError(error.message, 5000);
      });
    event.stopPropagation();
  }

  onReasonChange(event) {
    this.prevSelectedReason = JSON.parse(JSON.stringify(this.selectedReason));
    this.selectedReason = event.value;
  }

  placeHold(): void {
    this.dialog.open(HoldModalComponent, { width: "620px", height: "412px", data: { orderID: this.order.orderID, holdNotes: this.order.holdNotes, isOnHold: this.order?.isOnHold, isplaceOnHold: true, isp2p: false } }).afterClosed().subscribe((res: any) => {
      if (!res) { return; }
      if (res) {
        this.order.isOnHold = res.isOnHold;
        this.order.holdNotes = res.holdNotes;
        this.order.holdReasons = res.holdReasons;
        this.order.holdReasonIDs = this.selectedReason = res.holdReasonID;
      }
    });
  };

  RemoveHold(): void {
    this.dialog.open(HoldModalComponent, { width: "300px", height: "210px", data: { orderID: this.order.orderID, holdNotes: this.order.holdNotes, isOnHold: this.order?.isOnHold, isplaceOnHold: false, isp2p: false } }).afterClosed().subscribe((res: any) => {
      if (!res) { return; }
      if (res) {
        this.order.isOnHold = false;
      }
    });
  };

  scrollToHoldNotes(): void {
    this.onHoldAccordion.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  getTotalNumberOfChemicals(): number {
    let sum = 0;
    this.order.items.forEach(i => {
      if (i.containers) {
        sum += i.containers.length
      }
    });
    return sum;
  }

  get showIndyPill(): boolean {
    if (this.order?.useReservation && this.order?.items.some(i => i.reservationTypeDesc === 'Indy Reserved'))
      return true;
    else
      return false;
  }

  get showSGSPill(): boolean {
    if (this.order?.useReservation && this.order?.items.some(i => i.reservationTypeDesc === 'SGS Reserved'))
      return true;
    else
      return false;
  }

  openSplitRequestInNewTab(orderID: string): void {
    const currentUrl = window.location.href;
    const urlArray = currentUrl.split('/');
    urlArray.pop();
    urlArray.push(orderID);
    const newUrl = urlArray.join('/');
    window.open(newUrl);
  }

  disableTransferToSGSButton(): boolean {
    return (this.order?.orderStatusID !== OrderStatuses.Pending || this.order?.requestTypeID === RequestTypes.GLP);
  }

  disableHoldButton(): boolean {
    return (this.order?.orderStatusID === OrderStatuses.New || this.order?.orderStatusID === OrderStatuses.Complete || this.order?.orderStatusID === OrderStatuses.SGSComplete || this.order?.orderStatusID === OrderStatuses.Cancelled)
      || !this.isLoginUserCoordinator;
  }

  disableDocumentsButton(): boolean {
    return (this.order?.orderStatusID === OrderStatuses.New || this.order?.orderStatusID === OrderStatuses.Complete || this.order?.orderStatusID === OrderStatuses.Cancelled);
  }

  disableAssignButton(): boolean {
    return this.order?.orderStatusID === OrderStatuses.Complete || this.order?.orderStatusID === OrderStatuses.SGSComplete || this.order?.orderStatusID === OrderStatuses.Cancelled;
  }

  disableReprintPicklistButton(): boolean {
    return !this.order.items.every(i => i.containers.every(c => c.assignedLotNumber));
  }

  disableSGSCompleteRequestButton(): boolean {
    return (this.order?.orderStatusID === OrderStatuses.SGSComplete || this.order?.orderStatusID === OrderStatuses.Cancelled);
  }

  disableTransferToIndyButton(): boolean {
    return (this.order?.orderStatusID === OrderStatuses.SGSComplete || this.order?.orderStatusID === OrderStatuses.Cancelled);
  }

  get disableCancelRequest(): boolean {
    return this.order?.orderStatusID === OrderStatuses.Complete ||
      this.order?.orderStatusID === OrderStatuses.SGSComplete ||
      this.order?.orderStatusID === OrderStatuses.Cancelled;
  }

  disableSplitButton(): boolean {
    return (this.order?.orderStatusID === OrderStatuses.New || this.order?.orderStatusID === OrderStatuses.Complete || this.order?.orderStatusID === OrderStatuses.SGSComplete || this.order?.orderStatusID === OrderStatuses.Cancelled || this.order?.orderStatusID === OrderStatuses.Shipping);
  }

  disableSendToPicking: boolean = false;

  disableSendToPickingButton(): boolean {
    let containers = [], _disableSendToPicking = false;
    this.order.items.forEach(item => {
      containers = containers.concat(item.containers);
    });

    if (this.order?.orderStatusID !== OrderStatuses.Pending || containers?.some(c => !c.assignedLotNumber))
      _disableSendToPicking = true;

    if (this.weights?.every(w => !w.quantity) && this.destinationGuideData?.singleUsePlasticTaxText?.toLowerCase() == 'required')
      _disableSendToPicking = true;

    return _disableSendToPicking;
  }

  validateButtons() {
    this.orderService.getOrderDetail(this.order.orderID).subscribe((orderDetailResponse: Order) => {
      this.order.orderStatusID = orderDetailResponse.orderStatusID;
      this.order.items = orderDetailResponse.items;
      this.disableSendToPicking = this.disableSendToPickingButton();
    })
  }

  get disablePrintAllGLPButton(): boolean {
    return (this.order?.orderStatusID == OrderStatuses.Cancelled || this.order?.orderStatusID == OrderStatuses.Complete)
      || this.order?.requestTypeID !== RequestTypes.GLP
      || this.order.items.some(item => !item.allContainersAssigned);
  }

  get showPrintAllGLPButton(): boolean {
    return this.order?.orderStatusID !== OrderStatuses.SGSRequested
      && this.order?.orderStatusID !== OrderStatuses.SGSComplete
      && this.isLoginUserCoordinator;
  }

  get disableCancelOrderButton(): boolean {
    return (this.order?.orderStatusID === OrderStatuses.Complete
      || this.order?.orderStatusID === OrderStatuses.SGSComplete
      || this.order?.orderStatusID === this.orderStatuses.Cancelled);
  }

  onPrintAllGLP() {
    this.orderService.printGLPFlagLabels(this.order).subscribe(() => {
      this.responseHandler.showSuccess('Label sent to printer', 5000);
    },
      error => {
        this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000);
      });
  }

  onCancelOrder(orderID: string) {
    this.dialog.open(ConfirmCancelOrderModalComponent, { width: "500px", height: "392px", data: { orderID: orderID } });
  }

  showHideControl(): boolean {
    return this.order?.orderStatusID !== OrderStatuses.SGSRequested
      && this.order?.orderStatusID !== OrderStatuses.SGSComplete
      && this.order?.orderStatusID !== OrderStatuses.Cancelled
      && this.isLoginUserCoordinator;
  }

  showReprintPicklist(): boolean {
    return this.order?.orderStatusID !== OrderStatuses.SGSRequested && this.order?.orderStatusID !== OrderStatuses.SGSComplete && this.isLoginUserCoordinator;
  }

  showNonSGSButton(): boolean {
    return this.order?.orderStatusID !== OrderStatuses.SGSRequested
      && this.order?.orderStatusID !== OrderStatuses.SGSComplete
      && this.isLoginUserCoordinator;
  }

  showTransferIndyButton(): boolean {
    return (this.order?.orderStatusID === OrderStatuses.SGSRequested
      || this.order?.orderStatusID === OrderStatuses.SGSComplete
    )
      && this.isLoginUserCoordinator;
  }

  requestOverView() {
    this.orderService.getOrderDetailWithOrderContainers(this.uuid, true)
      .subscribe(order => {
        this.pdfService.generatePdfRequestOverview(order);
      });
  }

  expandOrCollapseChemicals(): void {
    this.isAllExpanded = !this.isAllExpanded;
    this.orderItemDetailsInstance.expandOrCollapse(this.isAllExpanded);
  }

  onSuppressEmailChange($event) {
    this.orderService.requestorEmailSuppress(this.order.orderID, true, $event.checked)
      .pipe(
        catchError(err => {
          this.responseHandler.handleError(err)
          this.isSuppressed = !$event.checked;
          return of(!$event.checked);
        })
      ).subscribe();
  }

  requestDestinationGuide() {
    this.orderService.getDestinationGuide(this.order.countryID).subscribe(destinationGuideData => {
      this.dialog.open(DestinationGuideInfoModalComponent, { width: "1000px", height: "500px", data: { destinationGuideData } });
    });
  }

  filterContainers(order: Order): boolean {
    let containers: OrderItemContainerModel[] = [];
    order.items?.forEach(x => {
      x?.containers?.forEach(y => {
        containers.push(y);
      })
    });
    return containers.filter(x => x.assignedLotNumber == null).length > 0;
  }

  loaderCallback = (): void => {
    this.loadingService.clearMessage();
  }

  onStatusChange($event): void {
    this.orderService.changeOrderStatus($event.orderId, $event.statusId, $event.resonToChange, $event.overrideStatusRules)
      .subscribe(res => {
        location.reload();
      });

  }

  onChangeWarehouse() {
    this.dialog.open(ChangeWarehouseStationComponent, {
      data: { warehouseID: this.order.warehouseID },
      disableClose: true
    }).afterClosed().subscribe((res: any) => {
      if (res.value.move) {
        this.orderService.updateWareHouseStation(this.order.orderID, res.value.warehousectrl.itemID, res.value.reasonToChange).pipe(
          take(1),
        ).subscribe(res => {
          location.reload();
        });
      }
    });
  }
}
