import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridOptions } from 'ag-grid-community';
import { OrderItemModel } from 'src/app/models/order-item-model';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ActionRendererComponent } from 'src/app/components/shared-documents/action-renderer/action-renderer.component';
import { formatDate } from '@angular/common';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { Subject, Subscription } from 'rxjs';
import { AutoComplete } from 'primeng/autocomplete';
import { FilterOption } from 'src/app/models/filter-models';
import { debounceTime } from 'rxjs/operators';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { SharedDcumentService } from 'src/app/services/shared-document-service/shared-document-service.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-shared-documents',
  templateUrl: './shared-documents.component.html',
  styleUrls: ['./shared-documents.component.scss']
})
export class SharedDocumentsComponent implements OnInit {

  @ViewChild('grid') grid: AgGridAngular;
  defaultDocumentType: any = { documentTypeID: 0, name: 'All documents' };
  defaultLanguage: any = { languageID: 0, name: 'All languages', isDefault: true, sortOrder: 0 };
  documentTypes: any[];
  selectedDocumentType: any;
  languages: any[];
  selectedLanguage: any;
  rowDataBack: any[] = [];
  rowData: any[] = [];
  option: FilterOption;
  options: FilterOption[];
  modelChanged = new Subject<string>();
  private subscriptions = new Subscription();
  canSkip: boolean = false;
  isInitialLoad: boolean = true;
  gridOptions: GridOptions = <GridOptions>{};
  private gridApi: any;
  @ViewChild('autocomplete') autocomplete: AutoComplete;
  canUpload: boolean = false;

  columnDefs: any[] = [
    {
      headerName: 'Document Type',
      field: 'documentTypeName',
      width: 230,
      headerTooltip: 'Document Type',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.documentTypeName) {
          return params.data.documentTypeName;
        }
      },
      sortable: true
    },
    {
      headerName: 'Language',
      field: 'languageName',
      headerTooltip: 'Language',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.languageName) {
          return params.data.languageName;
        }
      },
      sortable: true
    },
    {
      headerName: 'Upload Date',
      field: 'lastUpdated',
      valueGetter: (params) => {
        if (params.data == undefined || !params.data.lastUpdated) { return '--'; }
        return formatDate(params.data.lastUpdated, 'd-MMM-yyyy', 'en-US');
      },
    },
    {
      headerName: 'File Date',
      field: 'lastUpdated',
      width: 260,
      headerTooltip: 'File Name',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.lastUpdated) {
          return params.data.lastUpdated;
        }
      },
      sortable: true
    },
    {
      field: 'action',
      width: 320,
      headerName: '',
      cellRenderer: 'actionRenderer',
    }
  ]
  orderItem: OrderItemModel;
  frameworkComponents = {
    actionRenderer: ActionRendererComponent
  };

  constructor(private responseHandler: ResponseHandlingService, private sharedDcumentService: SharedDcumentService, private inventoryService: InventoryService, private dialog: MatDialog, private orderService: OrderService) {
    this.modelChanged.pipe(debounceTime(1000)).subscribe((event: any) => {
      this.subscriptions.add(this.inventoryService.getInventoryCatalogItems(event.query)
        .subscribe(response => {
          this.options = response;
        }));
    })
  }

  ngOnInit(): void {
    this.gridOptions = {};
    this.orderService.getDocuments(this.orderItem.orderItemID).subscribe(response => {
      this.rowDataBack = response || [];
      this.rowData = response || [];
      this.sortResults();
    });
  }

  onSelect(event: any) {
    this.canSkip = true;
    this.canUpload = true;
    this.search();
    setTimeout(() => {
      this.autocomplete?.inputEL?.nativeElement?.blur();
      this.canSkip = false;
    }, 100);
  }

  search() {
    if (!this.option) return;
    this.sharedDcumentService.getSharedDocumentList(this.option.itemID).subscribe(response => {
      if (response && response.length) {
        response.forEach(element => {
          element['materialName'] = this.option?.itemDesc;
        });
        this.rowData = response;
      }
      else
        this.rowData = [];
      this.isInitialLoad = false;
    })
  }

  selectedOptionDesc(option: FilterOption) {
    return option?.itemDesc;
  }

  onFocus() {
    if (this.canSkip) return;
    this.autocomplete.inputEL.nativeElement.value = '';
  }

  onFilter() {
    this.rowData = this.filterResults();
    this.sortResults();
  }

  changed(event) {
    this.modelChanged.next(event);
  }

  filterResults(): any[] {
    if (this.selectedLanguage.languageID > 0 && this.selectedDocumentType.documentTypeID > 0) {
      return this.rowDataBack.filter(x => x.documentTypeID == this.selectedDocumentType.documentTypeID && x.languageID == this.selectedLanguage.languageID);
    }

    if (this.selectedDocumentType.documentTypeID > 0) {
      return this.rowDataBack.filter(x => x.documentTypeID == this.selectedDocumentType.documentTypeID);
    }

    if (this.selectedLanguage.languageID > 0) {
      return this.rowDataBack.filter(x => x.languageID == this.selectedLanguage.languageID);
    }
    return this.rowDataBack;
  }

  resetData() {
    this.rowData = this.rowDataBack;
    this.selectedDocumentType = this.defaultDocumentType;
    this.selectedLanguage = this.defaultLanguage;
  }

  sortResults() {
    this.rowData.sort((objA, objB) => new Date(objB.created).getTime() - new Date(objA.created).getTime());
  }

  resizeGrid(params) {
    params.api.sizeColumnsToFit();
  }

  gridReady(params) {
    this.gridApi = params.api;
    params.api.addEventListener('downloadEvent', (e) => {
      this.onDownload(e);
    });
    params.api.addEventListener('deleteEvent', (e) => {
      this.handleDeleteClick(e);
    });
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  onDownload(e) {
    this.sharedDcumentService.viewDocument(e.value.data.sharedDocumentID).subscribe(response => {
      if (!response.base64FileContents) {
        this.responseHandler.showInfo("File contents not found");
        return;
      }
      var link = document.createElement('a');
      link.href = `data:application/octet-stream;base64,${response.base64FileContents}`;
      link.download = response.originalFilename;
      link.click();
    })
  }

  onUpload() {
    let _rowData = [];
    this.grid?.api.forEachNode(node => _rowData.push(node.data))
    this.dialog.open(UploadDocumentComponent, {
      minHeight: '300px',
      width: '400px',
      disableClose: true,
      autoFocus: false,
      data: {
        materialName: this.option?.itemDesc,
        inventoryCatalogID: this.option.itemID,
        rowData: _rowData
      }
    }).afterClosed().subscribe(data => {
      if (data)
        this.gridApi.applyTransaction({ add: [data] });
    })
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  handleDeleteClick(event) {
    this.confirmDeleteLabel(event);
  }

  confirmDeleteLabel(event) {
    const dialogData =
      new ConfirmDialogModel("Confirm",
        `Are you sure you want to delete the selected document?`,
        "Remove", true);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      disableClose: true,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.deleteDocument(event);
      }
    });
  }

  deleteDocument(event) {
    if (!event?.value?.data?.sharedDocumentID) return;
    this.subscriptions.add(this.sharedDcumentService
      .deleteSharedDocument(event.value.data.sharedDocumentID)
      .subscribe(
        data => {
          this.responseHandler.showSuccess(`Successfully deleted document.`);
          this.gridApi.applyTransaction({ remove: [event.value.node.data] });
        }
      ))
  }

  close() {
    //this.dialogRef.close();
  }

}
