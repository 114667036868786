<h5 class="p-mb-3">{{ data.isOnHold ? 'Remove Hold' : 'Place on Hold' }}</h5>
<p *ngIf="data.isOnHold">
    Hold notes will not be retained. Please ensure any notes needing to be saved have been captured by the coordinator
</p>
<div *ngIf="!data.isplaceOnHold">
    <mat-dialog-actions style="float: right; min-height: 80px;">
        <button mat-raised-button color="cor-default" (click)="dialogRef.close()">Cancel</button>
        <button mat-raised-button color="cor-primary" (click)="removeHold()">Confirm</button>
    </mat-dialog-actions>
</div>

<div *ngIf="data.isplaceOnHold">
    <mat-dialog-content>
        <form [formGroup]="onHoldForm">
            <div class="p-grid">
                <div class="p-col-12 p-field">
                    <div class="p-fluid">
                        <label class="p-text-bold" for="ddlReason">Reason</label>
                        <p-multiSelect placeholder="Select Reasons" defaultLabel="Select Reasons"
                            [options]="onHoldReasons" optionLabel="name" optionValue="reasonID"
                            scrollHeight="150px" inputId="ddlReason" formControlName="reason"
                            placeholder="Select Reason" (onChange)="onReasonChange()" [filter]="true"
                            selectedItemsLabel="{0} Reasons Selected">
                        </p-multiSelect>
                    </div>
                    <div class="p-error" *ngIf="isFormSubmitted">
                        <small *ngIf="fc.reason.errors?.required">
                            Field is required
                        </small>
                    </div>
                </div>
                <div class="p-col-12 p-field">
                    <div class="p-fluid">
                        <label class="p-text-bold" for="notes">Notes</label>
                        <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize"
                            formControlName="otherReasonComment" id="notes" (blur)="setErrors()"></textarea>
                    </div>
                    <div class="p-error" *ngIf="isFormSubmitted">
                        <small
                            *ngIf="(fc.otherReasonComment?.touched || fc.otherReasonComment?.dirty) && fc.otherReasonComment.errors?.required">
                            Field is required</small>
                    </div>
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions style="float: right; min-height: 80px;">
        <button mat-raised-button color="cor-default" mat-dialog-close>Close</button>
        <button mat-raised-button color="cor-primary" (click)="placeOnHold()"
            [disabled]="onHoldForm.invalid || (disableButton && fc.otherReasonComment.value.length === 0)">Place
            Hold</button>
    </mat-dialog-actions>
</div>