<h4>Packing Request Details</h4>
<div class="p-grid p-pl-2 p-pt-3">
    <h5>Request ID: {{order?.requestNumber}}</h5>
    <span class="p-ml-2" *ngIf="order?.isOnSiteOrder">
        <app-pill [Text]="order?.isOnSiteText" Type="OnSiteActive"></app-pill>
    </span>
</div>
<h6>Coordinator: {{ order?.assignedToFirstName }} {{order?.assignedToLastName}}</h6>
<h6>Selected warehouse: {{ selectedStation.warehouseName }}</h6>
<h6>Selected station: {{ selectedStation.name }}</h6>

<div class="p-grid">
    <div class="p-col-8">
        <div class="accordion">
            <mat-accordion id="onHoldAccordion" #onHoldAccordion *ngIf="order?.isOnHold">
                <mat-expansion-panel #holdNotesPanel class="p-mt-1" expanded="true">
                    <mat-expansion-panel-header>
                        <div style="display: flex;">
                            <app-pill Text="On Hold" Type="OnHold"></app-pill>
                            <span *ngIf="!holdNotesPanel.expanded" class="ellipsis">
                                <span *ngIf="order?.holdReasons">{{order?.holdReasons}} </span>
                                <span *ngIf="order?.holdNotes">&nbsp;- {{ order?.holdNotes }}</span>
                            </span>
                        </div>
                        <button mat-raised-button color="cor-primary" class="p-mr-3"
                            (click)="$event.stopPropagation();RemoveHold()">Remove
                            Hold</button>
                    </mat-expansion-panel-header>
                    <span>Reason: {{ order?.holdReasons }}</span>
                    <text-inline-edit [Comments]="order?.holdNotes" [readOnly]='true'>
                    </text-inline-edit>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="accordion" *ngIf='order?.coordinatorNotes'>
            <mat-accordion>
                <mat-expansion-panel #coordinatorNotesPanel class="p-mt-1" expanded="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="padding-top: 5px;">Coordinator Notes</mat-panel-title>
                        <span *ngIf="!coordinatorNotesPanel.expanded" style="margin-right:10px" class="ellipsis">{{
                            order?.coordinatorNotes
                            }}</span>
                    </mat-expansion-panel-header>
                    <span [innerHTML]="getCordinatorNotes(order?.coordinatorNotes)">
                    </span>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <!-- Unpacked Containers -->

        <h6>Containers to Pack</h6>
        <ag-grid-angular #unpackedGrid *ngIf="pageLoaded"
            style="width:100%;max-width: 900px;margin-top: 20px;margin-bottom: 20px" class="ag-theme-alpine"
            domLayout="autoHeight" suppressHorizontalScroll="true" [frameworkComponents]='frameworkComponents'
            [rowData]="formattedUnpackagedContainerRows" [rowHeight]='rowHeight' [columnDefs]="columnDefs"
            [tooltipShowDelay]="1500" [overlayNoRowsTemplate]="noRowsUnpackedTemplate"
            (gridSizeChanged)="resizeGrid($event)" (gridReady)="onGridReady($event)">
        </ag-grid-angular>

        <!-- Packing actions -->

        <div class="packing-actions">
            <label class="bold" for="containerField">{{ isUnpacking ? 'Remove' : 'Add'}} Container: </label>
            <input #containerFieldElement id="containerField" type="text" placeholder="Barcode Entry"
                [disabled]="(!isUnpacking && (!currentPackageGrouping?.orderPackageID || formattedUnpackagedContainerRows.length === 0)) || (isUnpacking && packageGroupings.length === 0)"
                [(ngModel)]="containerInput" pInputText (blur)="onContainerInput($event)" (click)="clearField($event)">


            <button *ngIf="pageLoaded" mat-raised-button color="cor-primary" [disabled]="disableUnpack()" class="p-ml-2"
                (click)="isUnpacking = !isUnpacking;">{{ isUnpacking ? 'Pack': 'Unpack' }} Container</button>
        </div>

        <!-- Current Package -->

        <div class="spacer"></div>
        <div *ngIf="pageLoaded">
            <h4>Current Package</h4>
            <p-dropdown panelStyleClass="package-selection-minWidthOverride" placeholder="Select Package"
                [disabled]="packages?.length === 0" [options]="packageGroupings" optionLabel="packageCode"
                [(ngModel)]="currentPackageGrouping" (onChange)="packageChange()">
            </p-dropdown>
            <button *ngIf="pageLoaded" class="p-ml-2" mat-raised-button color="cor-primary"
                (click)="createNewPackage()" id="btn-start-new-package">Start New
                Package</button>
            <div *ngIf="!currentPackageGrouping?.orderPackageID">
                <span>Create or select a package.</span>
            </div>
            <ng-container *ngIf="currentPackageGrouping && currentPackageGrouping.orderPackageID">
                <div class="package-heading">
                    <div class="info">
                        <span class="package-name bold"><i *ngIf="currentPackageGrouping?.orderPackageID"
                                [ngClass]="currentPackageGrouping?.shipperName && currentPackageGrouping?.containers?.length ? 'pi pi-check green' : 'pi pi-exclamation-triangle gold'"></i>
                            {{ currentPackageGrouping?.packageCode }}</span>
                        <div *ngIf="currentPackageGrouping?.shipperName">
                            <span class="bold spacing">Tracking Number:</span>
                            <ng-container *ngIf="currentPackageGrouping?.trackingNumber">
                                <a class="tracking-link" (click)='onOpenTracking(currentPackageGrouping)'>{{
                                    currentPackageGrouping?.shipperName
                                    }}
                                    {{
                                    currentPackageGrouping?.trackingNumber}}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="!currentPackageGrouping?.trackingNumber">
                                <span>{{ currentPackageGrouping?.shipperName }}</span>
                            </ng-container>
                            <span *ngIf="currentPackageGrouping.isMaster">
                                <app-pill Text="Master" Type="Master"></app-pill>
                              </span>
                        </div>
                    </div>
                    <div>
                        <button [disabled]="!currentPackageGrouping?.orderPackageID" mat-raised-button
                            color="cor-primary" (click)="printPackageLabel(currentPackageGrouping)">Print
                            Address Label</button>
                        <button [disabled]="!currentPackageGrouping?.orderPackageID" mat-raised-button
                            color="cor-primary" (click)="openShippingModal(currentPackageGrouping)" id="btn-add-edit-shipping-info">{{
                            currentPackageGrouping?.shipperName ? 'Edit' : 'Add' }} Shipping Info</button>
                        <button [disabled]="!currentPackageGrouping?.orderPackageID" mat-raised-button
                            color="cor-danger" (click)="deletePackage(currentPackageGrouping)">Delete</button>
                    </div>
                </div>
                <ag-grid-angular #currentPackageGrid
                    style="width:100%;max-width: 900px;margin-top: 20px;margin-bottom: 20px" class="ag-theme-alpine"
                    domLayout="autoHeight" suppressHorizontalScroll="true" [frameworkComponents]='frameworkComponents'
                    [rowData]="currentPackageGrouping?.rowData" [gridOptions]="currentPackageGridOptions"
                    [rowHeight]='rowHeight' [columnDefs]="columnDefs" [tooltipShowDelay]="1500"
                    [overlayNoRowsTemplate]="noRowsPackedTemplate" (gridSizeChanged)="resizeGrid($event)"
                    (gridReady)="onGridReady($event)">
                </ag-grid-angular>
            </ng-container>
        </div>
        <div class="spacer"></div>

        <!-- Other Packages -->

        <h4
            *ngIf="packageGroupings && (packageGroupings.length > 1 || (packageGroupings.length === 1 && !currentPackageGrouping?.orderPackageID))">
            Other Packages</h4>
        <div *ngFor="let packageGrouping of packageGroupings">
            <ng-container *ngIf="packageGrouping.orderPackageID !== currentPackageGrouping?.orderPackageID">

                <div class="package-heading">
                    <div class="info">
                        <span class="package-name bold"><i
                                [ngClass]="packageGrouping.shipperName && packageGrouping?.containers?.length ? 'pi pi-check green' : 'pi pi-exclamation-triangle gold'"></i>
                            {{ packageGrouping.packageCode }}</span>
                        <div *ngIf="packageGrouping.shipperName">
                            <span class="bold spacing">Tracking Number:</span>
                            <ng-container *ngIf="packageGrouping.trackingNumber">
                                <a class="tracking-link" (click)='onOpenTracking(packageGrouping)'>{{
                                    packageGrouping.shipperName }} {{
                                    packageGrouping.trackingNumber}}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="!packageGrouping.trackingNumber">
                                <span>{{ packageGrouping.shipperName }}</span>
                            </ng-container>
                            <span *ngIf="packageGrouping.isMaster">
                                <app-pill Text="Master" Type="Master"></app-pill>
                            </span>
                        </div>
                    </div>
                    <div>
                        <button mat-raised-button color="cor-primary" (click)="printPackageLabel(packageGrouping)">Print
                            Address Label</button>
                        <button mat-raised-button color="cor-primary" (click)="openShippingModal(packageGrouping)">{{
                            packageGrouping.shipperName ? 'Edit' : 'Add' }} Shipping Info</button>
                        <button mat-raised-button color="cor-danger"
                            (click)="deletePackage(packageGrouping)">Delete</button>
                    </div>
                </div>
                <ag-grid-angular #packedGrid *ngIf="pageLoaded && packageGroupings && packageGroupings.length > 0"
                    style="width:100%;max-width: 900px;;margin-bottom: 20px" class="ag-theme-alpine"
                    [gridOptions]="otherPackageGridOptions" domLayout="autoHeight" suppressHorizontalScroll="true"
                    [frameworkComponents]='frameworkComponents' [rowData]="packageGrouping.rowData"
                    [rowHeight]='rowHeight' [columnDefs]="columnDefs" [tooltipShowDelay]="1500"
                    [overlayNoRowsTemplate]="noRowsPackedTemplate" (gridSizeChanged)="resizeGrid($event)"
                    (gridReady)="onGridReady($event)">
                </ag-grid-angular>
            </ng-container>
        </div>
    </div>
    <div class="p-col-4">
        <button *ngIf=" order && !order.isOnHold" mat-raised-button class="p-m-1" (mousedown)="onHoldMouseDown()"
            color="cor-primary" (click)="placeHold()">Place on
            Hold</button>
        <button mat-raised-button class="p-m-1" color="cor-primary" (click)="printingComplete = true"
            [disabled]="disablePrintingComplete()" id="btn-printing-complete">Printing Complete</button>
        <div style="display: initial;" matTooltip="Remove Hold to Complete Request"
            [matTooltipDisabled]="!showOnHoldTooltip">
            <button class="completeButton" mat-raised-button class="p-m-1" [ngClass]="order?.isOnHold ? 'border-red':''"
                color="cor-primary" [disabled]="disableCompleteRequest" (click)="onCompleteClick()" id="btn-complete-request">Complete Request
            </button>
        </div>
        <div class="border print-item-box">
            <span class="print-item-content">Documents to Print</span>
            <button mat-raised-button color="cor-primary" (click)="openViewAttachments()"
                [disabled]="(!orderItemAttachments || orderItemAttachments.length === 0) && (!order?.attachments || order?.attachments.length === 0)">
                View Attachments
            </button>
            <p *ngIf="(!orderItemAttachments || orderItemAttachments.length === 0) && (!order?.attachments || order?.attachments.length === 0)"
                class="empty-message">
                No Documents to Print
            </p>
        </div>
        <div class="spacer"></div>
        <div class="border print-item-box">
            <span class="print-item-content">Shipping Information</span>
            <div>
                <div *ngIf="order" class="address">
                    <span>{{ order.recipientName }}</span>
                    <span *ngIf="order?.recipientOrganizationName">{{ order?.recipientOrganizationName }}</span>
                    <span>{{ order.addressLine1 }}</span>
                    <span>{{ order.addressLine2 ? order.addressLine2 : '' }}</span>
                    <span>{{ order.addressLine3 ? order.addressLine3 : '' }}</span>
                    <div>
                        {{ order.city }}, {{ order.stateProvince ? order.stateProvince : '' }} {{ order.postalCode}}
                    </div>
                    <span>{{ order.countryName}}</span>
                </div>
            </div>
        </div>
    </div>
</div>