import { FormControl } from "@angular/forms";
import * as moment from "moment";

export function MaxDateValidator(format = "MM/dd/YYYY", date: Date): any {
    return (control: FormControl): { [key: string]: any } => {
        const val = moment(control.value, format, true);
        const datem = moment(date, format, true);

        if (val.isValid() && val.isAfter(datem)) {
            return { invalidMaxDate: true };
        }

        return null;
    };
}