<h5 class="p-mb-3">{{buttonLabel}} Station</h5>

<form [formGroup]="stationForm" (submit)="onSubmit()">
    <div class="p-grid p-mt-3 p-mb-4">
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Warehouse:</div>
                <p-dropdown id="warehouses" placeholder="Select a warehouse" formControlName="warehouseId"
                    [options]="warehousesRes" optionLabel="itemDesc" optionValue="itemID" class="warehouses-dropdown"
                    [style]="{ minWidth: '100%', width: '100%' }" autoWidth="false">
                </p-dropdown>
                <div
                    *ngIf="stationForm.controls['warehouseId'].invalid && (stationForm.controls['warehouseId'].dirty || stationForm.controls['warehouseId'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;"
                        *ngIf="stationForm.controls.warehouseId.errors?.required">
                        Field required
                    </small>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Station Name:</div>
                <input formControlName="name" pInputText [style]="{ minWidth: '100%', width: '100%' }" type="text" />
                <div
                    *ngIf="stationForm.controls['name'].invalid && (stationForm.controls['name'].dirty || stationForm.controls['name'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;"
                        *ngIf="stationForm.controls.name.errors?.required">
                        Field required.
                    </small><small style="margin-left: 5px; color: red; font-size: 12px;"
                        *ngIf="stationForm.controls.name.errors?.maxlength">
                        Field cannot exceed 40 characters
                    </small>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Computer Name:</div>
                <input formControlName="machineName" [style]="{ minWidth: '100%', width: '100%' }" pInputText
                    type="text" />
                <div
                    *ngIf="stationForm.controls['machineName'].invalid && (stationForm.controls['machineName'].dirty || stationForm.controls['machineName'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;"
                        *ngIf="stationForm.controls.machineName.errors?.maxlength">
                        Field cannot exceed 80 characters
                    </small>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <span>Is GLP?</span>
            <mat-slide-toggle [style]="{'margin-left':'10px'}" formControlName="isGLP">
            </mat-slide-toggle>
        </div>
        <mat-dialog-actions style="float: right" class="actions">
            <button style="margin-right: 15px;" mat-raised-button color="cor-default" mat-dialog-close>Cancel</button>
            <button mat-raised-button color="cor-primary" type="submit" [disabled]="!stationForm.valid">Save</button>
        </mat-dialog-actions>
    </div>
</form>