import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, take, tap } from 'rxjs/operators'
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DestinationGuideOptions } from 'src/app/models/destination-guide-options';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { WarehouseManagerOptions, WarehouseModel } from 'src/app/models/warehouse-model';
import { Country } from 'src/app/models/country';
import { RoleService } from 'src/app/services/role-service/role-service.service';
import { Role } from 'src/app/models/security-models';
import { DestinationGuideService } from 'src/app/shared/services/destination-guide-service';
import { WarehouseManagerService } from 'src/app/services/warehouse-manager-service/warehouse-manager-service';

@Component({
  selector: 'app-edit-warehouse',
  templateUrl: './edit-warehouse.component.html',
  styleUrls: ['./edit-warehouse.component.scss']
})
export class EditWarehouseComponent
  implements OnInit, OnDestroy {
  allowRedirect: boolean;
  subscriptions: Subscription;
  public warehouseManagerForm: FormGroup;
  warehouses: WarehouseManagerOptions[];
  roles: Role[];
  countries: Country[];
  destinationGuideModel: DestinationGuideOptions;
  warehouseID: any;
  selectedWarehouseOption: any;
  isLoading:boolean = true;

  constructor(
    private messageService: MessageService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loader: LoadingService,
    private warehouseManagerService: WarehouseManagerService,
    private destinationGuideService: DestinationGuideService,
    private roleService: RoleService,
    private confirmationService: ConfirmationService
  ) { }

  @HostListener('window:beforeunload', ['$event'])
  canLeavePage(event: any) {
    event.preventDefault();
    event.returnValue = false;
  }

  ngOnInit(): void {
    this.warehouseID = this.activatedRoute.snapshot.paramMap.get('id');
    this.init();
  }

  async onSubmit(form: FormGroup): Promise<boolean> {
    if (form.valid) {
      this.loader.show();
      await this.warehouseManagerService
        .saveWarehouse(form.value)
        .toPromise()
        .then(() => {
          this.loader.clearMessage();
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'All the changes have been saved.',
          });
          setTimeout(() => {
            this.router.navigate(['managewarehouses']);
            // this.loadWarehouses();
          }, 100);
        });

      this.warehouseManagerForm.markAsPristine();
      return true;
    } else {
      this.updateFormValidators();
      return false;
    }
  }

  canDeactivate(): boolean {
    return this.warehouseManagerForm.pristine;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onCancel() {

    if (this.warehouseManagerForm.dirty) {
      this.confirmationService.confirm({
        message: 'You have unsaved changes. Are you sure you wish to leave?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.router.navigate(['managewarehouses']);
        },
      });
    } else {
      this.router.navigate(['managewarehouses']);
    }
  }

  private init() {
    this.buildForm();
    this.subscriptions = new Subscription();

    this.subscriptions.add(
      this.warehouseManagerService.getWarehouseByID(this.warehouseID)
        .subscribe((formData) => {
          this.patchForm(formData);
          this.updateFormValidators();
          this.loader.clearMessage();
        })
    );

    this.destinationGuideService
      .getDestinationGuideOptions()
      .pipe(
        tap(() => this.loader.show()),
        take(1)
      )
      .subscribe((options) => {
        this.destinationGuideModel = options;
        this.loader.clearMessage();
      });

    this.loadWarehouses();

    this.subscriptions.add(this.roleService.getAvailableRoles().subscribe(
      data => {
        this.roles = data;
      }
    ));
  }

  loadWarehouses() {
    this.loader.show();
    this.warehouseManagerService
      .getWarehouseList()
      .pipe(
        tap(() => this.loader.show()),
        take(1)
      )
      .subscribe((options) => {
        this.warehouses = options;
        this.loader.clearMessage();
        this.selectedWarehouseOption = this.warehouseID && (this.warehouses.find(w => w.itemID === +this.warehouseID));
      }, (err) => this.loader.clearMessage());
  }

  onReorder() {
    this.warehouseManagerForm.markAsDirty();
  }

  onSelectionChange() {
    this.warehouseManagerForm.markAsDirty();
  }

  buildForm() {
    this.warehouseManagerForm = new FormGroup({
      countryID: new FormControl(null, Validators.required),
      warehouseID: new FormControl(null),
      companyName: new FormControl(null, [Validators.required, Validators.maxLength(80)]),
      name: new FormControl(null, [Validators.required, Validators.maxLength(80)]),
      addressLine1: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
      addressLine2: new FormControl(null, [Validators.maxLength(255)]),
      addressLine3: new FormControl(null, [Validators.maxLength(255)]),
      city: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      stateProvince: new FormControl(null, [Validators.maxLength(50)]),
      postalCode: new FormControl(null, [Validators.required, Validators.maxLength(10)]),
      relatedRoleID: new FormControl(null, [Validators.required, Validators.maxLength(10)]),
      customsInvoiceExportStatement: new FormControl(),
      sampleOwnerText: new FormControl()
    });
    this.isLoading = false;
  }

  private patchForm(formData: WarehouseModel) {
    this.warehouseManagerForm.patchValue({
      warehouseID: this.warehouseID,
      countryID: formData.countryID,
      companyName: formData.companyName,
      name: formData.name,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      addressLine3: formData.addressLine3,
      city: formData.city,
      stateProvince: formData.stateProvince,
      postalCode: formData.postalCode,
      relatedRoleID: formData.relatedRoleID,
      customsInvoiceExportStatement: formData.customsInvoiceExportStatement,
      sampleOwnerText: formData.sampleOwnerText
    });
  }

  updateFormValidators() {
    this.warehouseManagerForm.markAsPristine();
    this.warehouseManagerForm.markAllAsTouched();
    this.warehouseManagerForm.updateValueAndValidity({
      onlySelf: false,
      emitEvent: false,
    });
  }

  checkRequired(controlName: string) {
    const control = this.warehouseManagerForm.get(controlName)
    return control?.errors?.required && control?.touched;
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  restrictNumeric(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode >= 48 && charCode <= 57)) {
      event.preventDefault();
      return true;
    }
  }

}

