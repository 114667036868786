import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { AgGridLotSelectionNumericEditorComponent } from '../../core/modules/ag-grid/components/ag-grid-lot-selection-numeric-editor/ag-grid-lot-selection-numeric-editor.component';

@Component({
  selector: 'app-unassign-lots-modal',
  templateUrl: './unassign-lots-modal.component.html',
  styleUrls: ['./unassign-lots-modal.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class UnassignLotsModalComponent implements OnInit {

  selectedNumberOfContainers = this.data.numberOfContainers;

  columnDefs;

  rowData = [];

  public frameworkComponents = {
    lotSelectionNumericEditor: AgGridLotSelectionNumericEditorComponent
  }

  constructor(private dialogRef: MatDialogRef<UnassignLotsModalComponent>, @Inject(MAT_DIALOG_DATA) public data, private weightFormatKGPipe: WeightFormatKGPipe) {
    this.createColumnDefs();
    this.createRowData();
  }
  createColumnDefs(): void {
    const that = this;
    this.columnDefs = [
      {
        headerName: 'Lot Number',
        field: 'lotNumber',
      },
      {
        headerName: 'Amount Per Container',
        field: 'amountPerContainer',
        valueGetter: (params) => {
          if (!params) { return '' }
          return that.weightFormatKGPipe.transform(params.data.amountPerContainer) + ' ' + that.data.unitOfMeasureDesc;
        }
      },
      {
        headerName: '# of Containers',
        field: 'numberOfContainers',
        cellRenderer: 'lotSelectionNumericEditor',
        width: 260,
      },
    ];
  }
  createRowData(): void {
    this.rowData = [{
      lotNumber: this.data.lotNumber,
      amountPerContainer: this.data.amountPerContainer,
      numberOfContainers: this.data.numberOfContainers,
      min: 1,
      max: this.data.numberOfContainers,
    }];
  }

  ngOnInit(): void { }

  cancel() {
    this.dialogRef.close();
  }

  unassign() {
    this.dialogRef.close(this.rowData[0].numberOfContainers);
  }

}
