import { BionexusDataAdapter } from './bionexus-data-adapter';
import { Injectable } from '@angular/core';
import { ExternalServiceType } from '../../models/enumerations';
import { ReceivingStateService } from '../receiving-state.service';
import { ReceivingService } from '../receiving.service';
import { CoformulantDataAdapter } from "./coformulant-data-adapter";
import { CompoundDataAdapter } from './compound-data-adapter';
import { FormulationDataAdapter } from './formulation-data-adapter';
import { MaterialDataAdapterService } from './material-data-adapter.service';

@Injectable()
export class MaterialDataAdapterServiceProvider {
  materialType: ExternalServiceType;
}

export const dataAdapterServiceFactory = (materialTypeServiceProvider: MaterialDataAdapterServiceProvider, receivingService: ReceivingService,receivingStateService: ReceivingStateService):MaterialDataAdapterService => {

  switch (materialTypeServiceProvider.materialType) {

    case ExternalServiceType.Coformulant:
      return new MaterialDataAdapterService(new CoformulantDataAdapter(receivingService,receivingStateService));
    case ExternalServiceType.Compound:
      return new MaterialDataAdapterService(new CompoundDataAdapter(receivingService,receivingStateService));
    case ExternalServiceType.Formulation:
      return new MaterialDataAdapterService(new FormulationDataAdapter(receivingService,receivingStateService));
    case ExternalServiceType.Bionexus:
      return new MaterialDataAdapterService(new BionexusDataAdapter(receivingService,receivingStateService));
    default:
      return new MaterialDataAdapterService(new CoformulantDataAdapter(receivingService,receivingStateService));
  }
};

export let DataAdapterServiceProvider =
{
  provide: MaterialDataAdapterService,
  useFactory: dataAdapterServiceFactory,
  deps: [MaterialDataAdapterServiceProvider]
};