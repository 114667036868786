<div *ngIf="decantStep == 0" style="max-width: 1500px">
  <div class="header">
      <h4>Decanting - Request Detail</h4>
  </div>
  <mat-accordion id="onHoldAccordion" #onHoldAccordion *ngIf="order?.isOnHold">
      <mat-expansion-panel #holdNotesPanel class="p-mt-1" expanded="true">
          <mat-expansion-panel-header>
              <div style="display: flex;">
                  <app-pill Text="On Hold" Type="OnHold"></app-pill>
                  <span *ngIf="!holdNotesPanel.expanded" class="ellipsis">
                      <span *ngIf="order?.holdReasons">{{order?.holdReasons}} </span>
                      <span *ngIf="order?.holdNotes"> - {{ order?.holdNotes }}</span>
                  </span>
              </div>
              <button mat-raised-button color="cor-primary" class="p-mr-3"
                  (click)="$event.stopPropagation();RemoveHold()" id="btn-remove-hold">Remove
                  Hold</button>
          </mat-expansion-panel-header>
          <span>Reason: {{ order?.holdReasons }}</span>
          <text-inline-edit [Comments]="order?.holdNotes" [readOnly]='true'>
          </text-inline-edit>
      </mat-expansion-panel>
  </mat-accordion>
  <mat-accordion *ngIf='order?.coordinatorNotes'>
      <mat-expansion-panel #coordinatorNotesPanel class="p-mt-1" expanded="false">
          <mat-expansion-panel-header>
              <mat-panel-title style="padding-top: 5px;">Coordinator Notes</mat-panel-title>
              <span *ngIf="!coordinatorNotesPanel.expanded" class="ellipsis">{{ order?.coordinatorNotes }}</span>
          </mat-expansion-panel-header>
          <span [innerHTML]="getCordinatorNotes(order?.coordinatorNotes)">
          </span>
      </mat-expansion-panel>
  </mat-accordion>
  <div class="spacer"></div>
  <div class="row" style="margin-bottom: 20px;">
      <span class="p-ml-2" *ngIf="order?.isOnSiteOrder">
          <app-pill [Text]="order?.isOnSiteText" Type="OnSiteActive"></app-pill>
      </span>
  </div>
  <div class="p-grid">
      <div class="p-col-2">
          <table style="width:100%">
              <tr style="display:block; padding-bottom: 20px;">
                  <td>Request ID:</td>
              </tr>
              <tr style="display:block; padding-bottom: 20px;">
                  <td>Coordinator:</td>
              </tr>
              <tr style="display:block; padding-bottom: 20px;">
                  <td>Selected Warehouse:</td>
              </tr>
              <tr style="display:block; padding-bottom: 20px;">
                  <td>Selected Station:</td>
              </tr>
          </table>
      </div>
      <div class="p-col-2" style="font-weight:bold">
          <table style="width:100%">
              <tr style="display:block; padding-bottom: 20px;">
                  <td>{{order?.requestNumber ? order.requestNumber : '-'}}</td>
              </tr>
              <tr style="display:block; padding-bottom: 20px;">
                  <td>{{ order?.assignedToFirstName }} {{order?.assignedToLastName}}</td>
              </tr>
              <tr style="display:block; padding-bottom: 20px;">
                  <td>{{selectedStation?.warehouseName ? selectedStation.warehouseName : '-'}}</td>
              </tr>
              <tr style="display:block; padding-bottom: 20px;">
                  <td>{{selectedStation?.name ? selectedStation.name : '-'}}</td>
              </tr>
          </table>
      </div>
      <div class="p-col-2" style="margin-right:55px">
          <div class="p-mb-2">
              <button *ngIf="order && !order.isOnHold" mat-raised-button color="cor-primary" (click)="placeHold()"
                  style="width: 270px;" id="btn-place-on-hold">Place on Hold</button>
          </div>
          <div class="p-mb-2">
              <button [disabled]="!order || !(containersFilled && isGLPRequest)" mat-raised-button class="p-mr-2"
                  color="cor-primary" (click)="printTestSubstanceDistributionCertificate()" style="width: 270px;"
                  id="btn-test-substance-distribution-certification">Test
                  Substance Distribution Certificate</button>
          </div>
          <div class="p-mb-2">
              <button [disabled]="!containersFilled" mat-raised-button color="cor-primary"
                  (click)="confirmMoveToShipping()" style="width: 270px;"
                  id="btn-send-child-containers-to-shipping">Send Child Containers to Shipping</button>
          </div>
      </div>
  </div>
  <label for="barcodeField">Scan Parent Container to Decant</label>
  <input id="barcodeField" type="text" class="p-ml-2" placeholder="Barcode Entry" [(ngModel)]="barcodeInput"
      pInputText (blur)="onBarcodeInput($event)">
  <!--autofocus-->
  <div class="spacer"></div>
  <ag-grid-angular #roleGrid *ngIf="decantOrderLoaded" [rowHeight]='rowHeight'
      [frameworkComponents]="frameworkComponents" style="width:100%;margin-top: 20px;margin-bottom: 20px"
      class="ag-theme-alpine" domLayout="autoHeight" suppressHorizontalScroll="true" [rowData]="rowData"
      [columnDefs]="columnDefs" [tooltipShowDelay]="1500">
  </ag-grid-angular>
</div>

<div *ngIf="decantStep == 1">
  <app-decant-container-details [order]="order" [selectedStation]="selectedStation"
      (captureComplete)="onCaptureComplete()" (captureDecanting)="onCaptureDecanting($event)"
      (getStationDetails)="getStation()" [containerId]="containerId"></app-decant-container-details>
</div>

<div *ngIf="decantStep == 2">
  <app-decanting-capture [order]="order" [selectedInventoryItem]="selectedInventoryItem"
      (captureComplete)="onCaptureComplete()" [selectedStation]="selectedStation"
      [selectedContainer]="selectedContainer" [containerId]="containerId"
      (getStationDetails)="getStation()" [unitOfMeasure]="order.items[0].unitOfMeasureDesc">
  </app-decanting-capture>
</div>
