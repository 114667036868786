<h5>
    Edit Label Design
</h5>

<div class="doublespacer"></div>
<hr />

<div mat-dialog-content>
    <form [formGroup]="Form" (ngSubmit)="onSubmit(Form)">
        <div class="form-control">
            <label for="requestor">Name </label>

            <input class="w-input" type="text" pInputText maxlength="80" formControlName="name">
            <!-- <p [ngClass]="{'p-error': checkRequired('name')}" *ngIf="checkRequired('name')">
                        Field is required
                    </p> -->
        </div>
        <div class="form-control">
            <label for="requestor">Description </label>
            <textarea pInputTextarea rows="4" maxlength="80" formControlName="description" style="width: 100%;"></textarea>
            <!-- <p [ngClass]="{'p-error': checkRequired('name')}" *ngIf="checkRequired('name')">
                        Field is required
                    </p> -->
        </div>
        <div class="form-control">
            <label for="requestor">Bartender Filename </label>

            <input class="w-input" type="text" pInputText maxlength="80" formControlName="bartenderFilename">
            <!-- <p [ngClass]="{'p-error': checkRequired('name')}" *ngIf="checkRequired('name')">
                        Field is required
                    </p> -->
        </div>


    </form>
</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button color="cor-default" (click)="close()">Cancel</button>

    <button mat-raised-button color="cor-primary" [disabled]="Form.invalid" (click)="update()">
        Save
    </button>
</div>