import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderItemModel } from 'src/app/models/order-item-model';
import { Order } from 'src/app/models/order-models';

@Component({
  selector: 'app-shipping-conditions-modal',
  templateUrl: './shipping-conditions-modal.component.html',
  styleUrls: ['./shipping-conditions-modal.component.scss']
})
export class ShippingConditionsModalComponent implements OnInit {

  item: OrderItemModel;

  constructor(private dialogRef: MatDialogRef<ShippingConditionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderItemModel) {
  }

  ngOnInit(): void {
    this.item = this.data;
  }

  close() {
    this.dialogRef.close();
  }

}
