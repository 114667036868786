<h5>No Decant</h5>
<div class="container p-mb-2">
    <form [formGroup]="serachCriteriaForm">
        <input formControlName="searchCriteria" type="text" pInputText placeholder="Search.." />
    </form>
</div>
<ag-grid-angular #grid class="ag-theme-alpine" enableCellTextSelection=true ensureDomOrder=true style="height: 350px; margin-bottom: 12px;"
    suppressHorizontalScroll="true" [rowData]="rowData" [columnDefs]="columnDefinitions" suppressCellSelection="true" [gridOptions]="noDecantGridOptions"
    [suppressRowClickSelection]="true" (selectionChanged)="onSelectionChanged($event)" rowSelection='multiple'
    (gridReady)="onGridReady($event)">
</ag-grid-angular>
<div class="totals">
    <div>
        <label>Total Amount</label>
        <span>{{ selectedTotalAmount | weightKG }} {{ data.orderItem.unitOfMeasureDesc }}</span>
    </div>
    <div>
        <label>Max Amount</label>
        <span>{{ targetAmountRemaining | weightKG }} {{ data.orderItem.unitOfMeasureDesc }}
            ({{ formatAmount(targetAmountInKg) | weightKG }} {{targetAmountInKg < 1 ? 'g' : 'kg' }}) </span>
    </div>
</div>
<div mat-dialog-actions class="p-pb-3" align="end">
    <button mat-raised-button color="cor-default" (click)="cancel()">Cancel</button>
    <button mat-raised-button [disabled]="disableSave" color="cor-primary" class="save" (click)="save()">Save</button>
</div>