import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-print-label-button-renderer',
  templateUrl: './print-label-button-renderer.component.html',
  styleUrls: ['./print-label-button-renderer.component.scss']
})
export class PrintLabelButtonRendererComponent implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
  }

  print() {
    this.gridApi.dispatchEvent({ type: 'printLabelButtonClicked', value: this.params.data });
  }

  refresh(): boolean {
    return false;
  }
}

