import {Component, ElementRef, ViewChild} from '@angular/core';
import {IHeaderAngularComp} from 'ag-grid-angular'
import {IHeaderParams} from 'ag-grid-community';

@Component({
  selector: 'warehouse-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class WarehouseCustomHeaderComponent implements IHeaderAngularComp {
  public params: IHeaderParams;

  public ascSort: string;
  public descSort: string;
  public noSort: string;

  @ViewChild('menuButton', {read: ElementRef}) public menuButton;

  agInit(params: IHeaderParams): void {
      this.params = params;

      params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
      
      this.onSortChanged();
  }

  onMenuClicked() {
      this.params.showColumnMenu(this.menuButton.nativeElement);
  };

  onSortChanged() {
      this.ascSort = this.descSort = this.noSort = 'inactive';
      if (this.params.column.isSortAscending()) {
          this.ascSort = 'active';
      } else if (this.params.column.isSortDescending()) {
          this.descSort = 'active';
      } else {
          this.noSort = 'active';
      }
  }

  onSortRequested(order: string, event: any) {
      this.params.setSort(order, event.shiftKey);
  }
}

