import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ArrayFindItem' })
export class ArrayFindItemPipe implements PipeTransform {
    transform(items: any[], key: string, value: any, propertyname: string): any {
        let result: any;
        if (items && items.length > 0) {
            items.find(x => {
                if (x[key] === value) {
                    result = x[propertyname];
                    return;
                }
            });
        }
        return result;
    }
}