import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'child-cell',
  template: `<button *ngIf="isAssignVisible" id="assign" style='padding-right:15px' mat-raised-button color="cor-primary" (click)="showAssignCoordinatorModal()">Assign</button> <span *ngIf="!isAssignVisible">{{assignedToCoordinator}}</span>`,
  styles: [],
})

export class AssignButtonRenderer implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;
  public isAssignVisible: boolean;
  public assignedToCoordinator: string = '';

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    if (this.params.data) {
      this.assignedToCoordinator = (this.params.data?.assignedToFirstName !== null ? this.params.data?.assignedToFirstName : '') + ' ' + (this.params.data?.assignedToLastName !== null ? this.params.data?.assignedToLastName : '');

      this.isAssignVisible = this.assignedToCoordinator === ' ' && this.params.data.orderStatusDesc == 'New';
    }
    this.gridApi = params.api;
  }

  showAssignCoordinatorModal() {
    this.gridApi.dispatchEvent({ type: 'assignButtonClicked', value: this.params.data });
  }

  refresh(): boolean {
    return false;
  }
}