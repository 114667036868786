export enum SignalREventType {
    Initialized,
    Connected,
    Disconnected,
    UpdateWeight,
    PrintStatus,
    Error,
    ConnectionIdObtained
}

export interface SignalREvent {
    type: SignalREventType,
    data?: any
}