import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Configuration } from '../../configuration';
import { FeatureModel } from '../../models/feature.model';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';

@Injectable({providedIn:'root'})
export class FeatureService {

  readonly rootURL = `${Configuration.REST_API_URL}/Feature/`;
  readonly genericErrorMessage = "Unable to complete your request at this time, please resubmit.";

  constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {

  }

  public retrieveFeatures(): Observable<FeatureModel[]> {
    return this.http.get<FeatureModel[]>(this.rootURL);
  }
}
