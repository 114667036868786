import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-ag-grid-custom-paginator',
  templateUrl: './ag-grid-custom-paginator.component.html',
  styleUrls: ['./ag-grid-custom-paginator.component.scss']
})
export class AgGridCustomPaginatorComponent implements OnInit {

  @Input() paginatorModel: PaginatorModel;
  @Input() showPageSizes: boolean;
  @Output() paginatorCommand = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  onBtFirst() {
    if (this.paginatorModel.totalPages !== 0) {
      this.paginatorModel.currentPage = 1;
      this.paginatorCommand.emit(1);
    }
    this.updateButtonState();
  }

  onBtPrevious() {
    if (this.paginatorModel.totalPages !== 0) {
      if (this.paginatorModel.currentPage !== 1) {
        this.paginatorModel.currentPage--;
        this.paginatorCommand.emit(2);
      }
    }
    this.updateButtonState();
  }

  onBtNext() {
    if (this.paginatorModel.totalPages !== 0) {
      if (this.paginatorModel.currentPage !== this.paginatorModel.totalPages) {
        this.paginatorModel.currentPage++;
        this.paginatorCommand.emit(3);
      }
    }
    this.updateButtonState();
  }
  
  onBtLast() {
    if (this.paginatorModel.totalPages !== 0) {
      this.paginatorModel.currentPage = this.paginatorModel.totalPages; 
      this.paginatorCommand.emit(4);
    }
    this.updateButtonState();
  }

  selectChangeHandler(event: any) {
    if (this.paginatorModel.totalPages !== 0) {
      this.paginatorModel.pageSize = event.target.value;
      this.paginatorModel.currentPage = 1;
      this.paginatorModel.totalPages = Math.ceil(this.paginatorModel.totalRows / this.paginatorModel.pageSize);
      this.paginatorCommand.emit(Number(event.target.value));
    }
    this.updateButtonState();
  }

  updateButtonState() {
    if (this.paginatorModel.totalPages < 2) {
      this.paginatorModel.leftArrowsDisabled = true;
      this.paginatorModel.rightArrowsDisabled = true;
    } else if (this.paginatorModel.currentPage == 1) {
      this.paginatorModel.leftArrowsDisabled = true;
      this.paginatorModel.rightArrowsDisabled = false;
    } else if (this.paginatorModel.currentPage == this.paginatorModel.totalPages) {
      this.paginatorModel.leftArrowsDisabled = false;
      this.paginatorModel.rightArrowsDisabled = true;
    } else {
      this.paginatorModel.rightArrowsDisabled = false;
      this.paginatorModel.leftArrowsDisabled = false;
    }
  }
}

export interface PaginatorModel {
  currentPage?: number;
  pageSize?: number;
  totalRows?: number;
  totalPages?: number;
  leftArrowsDisabled: boolean;
  rightArrowsDisabled: boolean;
}
