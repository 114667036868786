<mat-card class="order-card">
    <mat-card-header class="order-card"
        [ngClass]="{'blueHeader':headerColor == 'blue','redHeader':order?.isOnHold,'yellowHeader':headerColor == 'yellow','lightGreyHeader':headerColor == 'lightGrey','lightBlueHeader':headerColor == 'lightBlue','greyHeader':headerColor == 'grey'}"
        style="border-radius: 5px 5px 0px 0px;">
        <mat-card-title>
            <a [href]="'requests/' + order?.orderID">{{ order?.requestNumber }}</a>
        </mat-card-title>
        <button class="card-header-button" mat-stroked-button *ngIf="headerColor == 'blue'"
            (click)="confirmMoveToWarehouseQueue()">
            Queue for Warehouse
        </button>
        <button mat-stroked-button style="border-color:white;background-color: #0072CE;margin-right: 5px;"
            *ngIf="headerColor == 'grey'" 
            (click)="confirmMoveToPick()">
            Print Picklist
        </button>
        <span style="margin-right: 10px;">
            {{order.useReservation === true ?'R' : '' }}
        </span>
        <span style="margin-right: 10px;">
            {{order?.orderStatusDesc }} 
        </span>
      
    </mat-card-header>

    <mat-card-content>
        <table>
            <tr>
                <th>Requestor</th>
                <th [matTooltip]="order?.requestorName">{{ order?.requestorName }}</th>
            </tr>
            <tr>
                <th>Chemicals Requested</th>
                <th [matTooltip]="order?.chemicalsRequested | chemicalTooltip">{{order?.chemicalsRequested |
                    chemicalList}}</th>
            </tr>
            <tr>
                <th>Ship By Date</th>
                <th>{{ order?.shipByDate | standardDateFormat }}</th>
            </tr>
            <tr>
                <th>Priority</th>
                <th>{{ order?.priorityName }}</th>
            </tr>
            <tr>
                <th>Number of Items</th>
                <th>{{ order?.numberOfItems }}</th>
            </tr>
            <tr>
                <th>Request Type</th>
                <th>{{ order?.requestTypeDesc }}</th>
            </tr>
            <tr>
                <th>Destination Country</th>
                <th [matTooltip]="order?.countryName">{{ order?.countryName }}</th>
            </tr>
            <tr>
                <th>Assigned To</th>
                <th [matTooltip]="order?.assignedToFirstName + ' ' + order?.assignedToLastName">
                    {{ order?.assignedToFirstName }} {{ order?.assignedToLastName }}</th>
            </tr>
        </table>
    </mat-card-content>
</mat-card>