import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { OrderItemAttachment, ViewAttachmentsModalData } from 'src/app/models/attachment-models';
import { OrderItemModel } from 'src/app/models/order-item-model';
import { Order, OrderPackage, OrderPackageRequest, PackageAssignmentRequest } from 'src/app/models/order-models';
import { OrderItemAttachmentService } from 'src/app/services/order-item-attachments-service/order-tem-attachments.service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { DownloadAttachmentService } from 'src/app/services/download-attachment-service/download-attachment.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/base-component';
import { ViewAttachmentsModalComponent } from 'src/app/components/view-attachments-modal/view-attachments-modal.component';
import { flatMap, take } from 'rxjs/operators';
import { DocumentType } from 'src/app/models/document-type-model';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { HoldModalComponent } from 'src/app/components/hold-modal/hold-modal.component';
import { ShippingInfoModalComponent } from '../../shipping-info-modal/shipping-info-modal.component';
import { PillRenderer } from '../../../core/modules/ag-grid/components/ag-grid-cell-renderers/pill.render.component';
import { DecantStation } from 'src/app/models/decant-models';
import { GridOptions } from 'ag-grid-community';
import { ShippingConditionsModalComponent } from 'src/app/components/shipping-conditions-modal/shipping-conditions-modal.component';
import { ShippingNotesModalComponent } from 'src/app/components/shipping-notes-modal/shipping-notes-modal.component';

interface PackageGrouping extends OrderPackage {
  rowData?: any;
  hasContainers?: boolean
}

interface PackageRowData {
  orderItemContainerID: string;
  orderItemID: string;
  requestedContainerLabel: string;
  lotNumber: string;
  containerQty: number,
  containerCode: string;
  amount: number;
  displayAmount: string;
  packed?: boolean;
  specialInstructions?: string;
  isCold: boolean;
  isDesiccant: boolean;
  shippingConditions: string;
}

@Component({
  selector: 'app-packing-detail',
  templateUrl: './packing-detail.component.html',
  styleUrls: ['./packing-detail.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class PackingDetailComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('containerFieldElement') private containerFieldRef: ElementRef;
  @ViewChild('unpackedGrid') unpackedGrid;
  @ViewChild('currentPackageGrid') currentPackageGrid;
  @ViewChildren('packedGrid') packedGrids;

  public columnDefs: any;
  public rawRowData: any = [];
  public consolidatedRowData: any = [];
  public order: Order;
  public pageLoaded = false;
  public readyToComplete: boolean;
  public printingComplete = false;
  public containerInput: string;
  public orderItemAttachments: OrderItemAttachment[] = [];
  private canDefocus: boolean = false;
  public noRowsUnpackedTemplate: string = '<span class="ag-overlay-loading-center">No containers left to pack</span>';
  public noRowsPackedTemplate: string = '<span class="ag-overlay-loading-center">No containers packed</span>';
  public noCurrentPackageTemplate: string = '<span class="ag-overlay-loading-center">Please create a package to add containers to.</span>';
  public documentTypes: DocumentType[];
  public isUnpacking = false;
  public frameworkComponents;
  public selectedStation: DecantStation;
  lastScannedContainerID = '';
  showOnHoldTooltip: boolean = false;
  lastScanned_Unique_Chemical_Lot_Amount = '';
  hasShownShippingConditions: boolean = false;
  hasShownShippingNotes: boolean = false;

  carriers: any[];
  packages: OrderPackage[];
  currentPackageGrouping: PackageGrouping = {
    orderID: null, orderPackageID: null, shipperID: null, rowData: [], containers: [], isMaster: false, hasContainers: false, shippingConditions: ''
  };

  allContainerRows: PackageRowData[] = [];
  formattedUnpackagedContainerRows = [];

  packageGroupings: PackageGrouping[] = [];
  newlyAddedPackageID: string;
  rowHeight = 50;

  currentPackageGridOptions: GridOptions = {
    getRowStyle: (params) => {
      if ((params.data.requestedContainerLabel + (!params.data.lotNumber ? '' : params.data.lotNumber) + (!params.data.amount ? '' : params.data.amount))
        === this.lastScanned_Unique_Chemical_Lot_Amount)
        return { background: 'yellow' }
    },
    rowClassRules: {
      "bold": params => params.data.requestedContainerLabel === 'Containers in Package',
    },
  };

  otherPackageGridOptions: GridOptions = {
    rowClassRules: {
      "bold": params => params.data.requestedContainerLabel === 'Containers in Package',
    },
  };

  constructor(private router: Router, private route: ActivatedRoute, private responseHandler: ResponseHandlingService,
    private orderItemAttachmentService: OrderItemAttachmentService, private downloadAttachmentService: DownloadAttachmentService,
    private orderService: OrderService, public dialog: MatDialog, private weightFormatKGPipe: WeightFormatKGPipe) {

    super();

    this.frameworkComponents = {
      pillRenderer: PillRenderer
    };

    this.columnDefs = [
      { headerName: 'Chemical', field: 'requestedContainerLabel', tooltip: (params) => params.value },
      { headerName: 'Lot Number', field: 'lotNumber' },
      { headerName: 'Number of Containers', field: 'containerQty' },
      { headerName: 'Amount per Container', field: 'displayAmount' },
      { headerName: 'Special Instructions', cellRenderer: 'pillRenderer' },
      { headerName: 'Shipping Conditions', field: 'shippingConditions' },
    ];
  }

  ngOnInit(): void {
    this.selectedStation = JSON.parse(localStorage.getItem("packingStation"));
    if (!this.selectedStation) {
      this.responseHandler.showError("No Station selected. Redirecting to Packing overview.");
      setTimeout(() => this.router.navigateByUrl('packing'), 2000);
      return;
    }

    this.orderItemAttachmentService.documentTypes$.pipe(take(1)).subscribe((res: DocumentType[]) => {
      this.documentTypes = res;
    });
    this.orderService.getShippers().subscribe(data => {
      this.carriers = data;
    });
    let orderID = this.route.snapshot.paramMap.get('id');

    let requests = [];
    requests.push(this.orderService.getOrderDetail(orderID));
    requests.push(this.orderService.getPackages(orderID));

    forkJoin(requests).subscribe((res: Array<any>) => {
      this.order = res[0];
      this.buildContainerRows();
      this.getOrderItemAttachments();
      this.packages = res[1];
      this.constructPackageData();
      this.pageLoaded = true;
      this.showOnHoldTooltip = this.order?.isOnHold;
    });
  }

  ngAfterViewInit(): void {
    this.containerFieldRef?.nativeElement.focus();
  }

  buildContainerRows() {
    this.order.items.forEach(item => {
      item.containers.forEach(container => {
        const isDesiccant = container.locationName?.toLowerCase().includes('desiccant');
        const isCold = container.locationName?.toLowerCase().includes('refrigerated') || container.locationName?.toLowerCase().includes('freezer');
        this.allContainerRows.push({
          orderItemContainerID: container.orderItemContainerID,
          orderItemID: item.orderItemID,
          requestedContainerLabel: item.requestedContainerLabel,
          lotNumber: container.actualLotNumber,
          containerQty: 1,
          containerCode: container.containerCode,
          amount: container.amount,
          displayAmount: `${this.weightFormatKGPipe.transform(container.requestedAmount)} ${item.unitOfMeasureDesc}`,
          packed: !!container.shipped,
          isDesiccant,
          isCold,
          shippingConditions: item.shippingConditions
        });
        if (isDesiccant && isCold) {
          this.rowHeight = 80;
        }
      });
    });
    this.buildFormattedUnpackedGrid();
  }

  buildFormattedUnpackedGrid() {
    const formattedRows: PackageRowData[] = [];
    this.allContainerRows.forEach(row => {
      if (!row.packed) {
        const match = formattedRows.find(r => r.requestedContainerLabel === row.requestedContainerLabel && r.amount === row.amount && r.lotNumber === row.lotNumber);
        if (match) {
          match.containerQty++;
        } else {
          formattedRows.push(Object.assign({}, row));
        }
      }
    });
    this.formattedUnpackagedContainerRows = formattedRows;
  }

  constructPackageData() {
    this.packageGroupings = [];
    this.packages.forEach((p, index) => {
      let data: PackageRowData[] = [];
      if (p.containers && p.containers.length > 0) {
        p.containers.forEach(c => {
          const packageContainerChemicalName = this.order.items.find(i => i.containers.some(con => con.orderItemContainerID === c.orderItemContainerID)).requestedContainerLabel;
          const match = data.find(pc => packageContainerChemicalName === pc.requestedContainerLabel && pc.lotNumber === c.actualLotNumber && pc.amount === c.amount);
          if (match) {
            match.containerQty++;
          } else {
            data.push({
              orderItemContainerID: c.orderItemContainerID,
              orderItemID: this.order.items.find(i => i.orderItemID === c.orderItemID).orderItemID,
              requestedContainerLabel: this.order.items.find(i => i.orderItemID === c.orderItemID).requestedContainerLabel,
              lotNumber: c.actualLotNumber,
              containerQty: 1,
              containerCode: c.containerCode,
              amount: c.amount,
              displayAmount: `${this.weightFormatKGPipe.transform(c.requestedAmount)} ${this.order.items.find(i => i.orderItemID === c.orderItemID).unitOfMeasureDesc}`,
              isDesiccant: c.locationName?.toLowerCase().includes('desiccant'),
              isCold: c.locationName?.toLowerCase().includes('refrigerated') || c.locationName?.toLowerCase().includes('freezer'),
              shippingConditions: this.order.items.find(i => i.orderItemID === c.orderItemID)?.shippingConditions
            });
          }
        });
      }
      if (this.newlyAddedPackageID && this.newlyAddedPackageID === p.orderPackageID) {
        this.currentPackageGrouping = Object.assign({}, p);
        this.currentPackageGrouping.rowData = data;
        this.containerInput = "";
        setTimeout(() => {
          this.containerFieldRef?.nativeElement.focus();
        }, 300);
      }
      let newPackage: PackageGrouping = Object.assign({}, p);

      var footer = {} as PackageRowData;
      footer.requestedContainerLabel = "Containers in Package";
      footer.containerQty = data.reduce(function (sum, current) {
        return sum + current.containerQty;
      }, 0)
      data.push(footer)

      newPackage.rowData = data;
      this.packageGroupings.push(newPackage);
      if (this.currentPackageGrouping?.orderPackageID === newPackage.orderPackageID) {
        this.currentPackageGrouping = newPackage;
      }
    });
    if (this.packages.length === 0) {
      this.isUnpacking = false;
    }
    this.packageGroupings.sort((a, b) => a.isMaster ? -1 : 1);

  }

  packageChange() {
    this.isUnpacking = false;
    this.newlyAddedPackageID = null;
  }

  openShippingModal(packageGrouping: PackageGrouping) {
    this.canDefocus = true;
    const data = {
      packageGrouping,
      carriers: this.carriers,
      isMaster: packageGrouping.isMaster
    }
    this.dialog.open(ShippingInfoModalComponent, { width: '550px', height: '350px', data }).afterClosed().subscribe((res: OrderPackageRequest) => {
      this.canDefocus = false;
      if (!res) { return; }
      this.orderService.updateOrderPackage(packageGrouping.orderPackageID, res).subscribe((updatedPackage: OrderPackage) => {
        this.refreshPackages();
        this.responseHandler.showSuccess('Successfully updated shipping information');
      });
    });
  }

  getOrderItemAttachments() {
    this.order.items.forEach((orderItem: OrderItemModel) => {
      this.orderItemAttachmentService.getAttachmentListByOrderItemID(orderItem.orderItemID)
        .subscribe((attachments: OrderItemAttachment[]) => {
          if (attachments && attachments.length > 0) {
            this.orderItemAttachments = this.orderItemAttachments.concat(attachments);
          }
        });
    });
  }

  public downloadOrderItemAttachment(attachmentId: number, fileName: string) {
    this.orderItemAttachmentService.getOrderItemAttachmentList(attachmentId).subscribe(response => {
      this.downloadAttachmentService.download(fileName, response.fileContents);
    }, error => {
      this.responseHandler.showError(error.message, 5000);
    });
  }

  completeCurrentPackage() {
    this.orderService.getPackages(this.order.orderID).subscribe((res: OrderPackage[]) => {
      this.newlyAddedPackageID = null;
      this.currentPackageGrouping = null;
      this.packages = res;
      this.constructPackageData();
    })
  }

  createNewPackage() {
    const payload: Partial<OrderPackageRequest> = { isMaster: false }
    this.orderService.createOrderPackage(this.order.orderID, payload).subscribe((res: OrderPackage) => {
      this.responseHandler.showSuccess(`Successfully saved package for the request ${this.order.requestNumber}.`);
      this.newlyAddedPackageID = res.orderPackageID;
      this.orderService.getPackages(this.order.orderID).subscribe(res => {
        this.isUnpacking = false;
        this.packages = res;
        this.constructPackageData();
      });
    });
  }

  onOpenTracking(param) {
    let url = param.trackingURLFormat.replace('{0}', param.trackingNumber);
    window.open(url, '_blank');
  }

  onHoldMouseDown() {
    this.canDefocus = true;
  }

  alertedShippingCondiitonsContainers: PackageRowData[] = [];
  alertedShippingNotesContainers: PackageRowData[] = [];

  showShippingConditions(containerCode: string) {
    var container = this.allContainerRows.find(row => row.containerCode.toLowerCase() === containerCode);
    const orderItemModels = this.order.items.filter(item => item.orderItemID === container?.orderItemID && item.shippingConditions)

    if (!(this.alertedShippingCondiitonsContainers?.find(c => c?.orderItemID == container?.orderItemID)) && orderItemModels?.length) {
      this.dialog.open(ShippingConditionsModalComponent, {
        width: '33vw',
        data: orderItemModels[0]
      })
      this.alertedShippingCondiitonsContainers.push(container);
    }
  }


  showShippingNotes(containerCode: string) {
    var container = this.allContainerRows.find(row => row.containerCode.toLowerCase() === containerCode);
    const orderItemModels = this.order.items.filter(item => item.orderItemID === container?.orderItemID && item.shippingNotes)

    if (!(this.alertedShippingNotesContainers?.find(c => c?.orderItemID == container?.orderItemID)) && orderItemModels?.length) {
      this.dialog.open(ShippingNotesModalComponent, {
        width: '33vw',
        data: orderItemModels[0]
      })
      this.alertedShippingNotesContainers.push(container);
    }
  }

  onContainerInput(event) {
    // timeout to allow for text-inline edit/cancel events to fire
    setTimeout(() => {
      if (this.canDefocus) return;
      const input = event.target.value.toLowerCase();
      if (input && input.length === 6) {
        if (this.verifyBarcode(input) && this.isValidContainerCode(input)) {
          this.showShippingConditions(input);
          this.showShippingNotes(input);

          if (this.isUnpacking) {
            this.removeContainerFromPackage(input)
          } else {
            this.addContainerToPackage(input);
          }
        } else {
          this.responseHandler.showError("Invalid Barcode");
        }
      }
      else {
        if (this.containerInput && this.containerInput.length > 0) {
          this.responseHandler.showError("Invalid Barcode");
        }
      }
      this.containerInput = "";
    }, 100);
  }

  onTrackingMouseDown() {
    this.canDefocus = true;
  }

  onTrackingBlur() {
    this.canDefocus = false;
    this.containerFieldRef?.nativeElement.focus();
  }

  verifyBarcode(input: string): boolean {
    return /^[a-zA-Z0-9]{6}$/.test(input);
  }


  isValidContainerCode(containerCode: string) {
    var rowMatch = this.allContainerRows.find(row => row.containerCode.toLowerCase() === containerCode);
    return rowMatch && ((!rowMatch.packed && !this.isUnpacking) || (rowMatch.packed && this.isUnpacking));
  }

  addContainerToPackage(code: string) {
    var container = this.allContainerRows.find(row => row.containerCode.toLowerCase() === code);
    if (container) {
      const payload: PackageAssignmentRequest = { orderItemContainerID: container.orderItemContainerID }
      this.orderService.addContainerToPackage(this.currentPackageGrouping.orderPackageID, payload).subscribe(() => {
        this.lastScannedContainerID = container.orderItemContainerID;
        container.packed = true;
        this.buildFormattedUnpackedGrid();
        this.lastScanned_Unique_Chemical_Lot_Amount = this.order.items.find(i => i.orderItemID === container.orderItemID).requestedContainerLabel
          + (!container.lotNumber ? '' : container.lotNumber)
          + (!container.amount ? '' : container.amount);
        this.refreshPackages();
      });
    }
  }

  refreshPackages() {
    this.orderService.getPackages(this.order.orderID).subscribe((res: OrderPackage[]) => {
      this.packages = res;
      this.constructPackageData();
    });
  }

  deletePackage(packageGrouping: PackageGrouping) {
    const dialogData = new ConfirmDialogModel("Confirm", `Are you sure you want to delete Package ${packageGrouping.packageCode}?`, "Confirm", true);
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      disableClose: true,
      data: dialogData
    }).afterClosed().subscribe(res => {
      if (res) {
        this.orderService.deletePackage(packageGrouping.orderPackageID).pipe(flatMap(() => {
          return this.orderService.getPackages(this.order.orderID);
        })).subscribe((res: OrderPackage[]) => {

          packageGrouping.rowData.forEach(row => {
            this.alertedShippingCondiitonsContainers = this.alertedShippingCondiitonsContainers.filter(c => c.orderItemID != row.orderItemID)
          })

          packageGrouping.rowData.forEach(row => {
            this.alertedShippingNotesContainers = this.alertedShippingNotesContainers.filter(c => c.orderItemID != row.orderItemID)
          })

          packageGrouping.containers.forEach(container => {
            this.allContainerRows.find(r => r.orderItemContainerID === container.orderItemContainerID).packed = false;
          });
          if (this.currentPackageGrouping?.orderPackageID === packageGrouping.orderPackageID) {
            this.currentPackageGrouping = null;
          }
          this.buildFormattedUnpackedGrid();
          this.packages = res;
          this.constructPackageData();
          this.responseHandler.showSuccess('Successfully deleted Package');
        });
      }
    });
  }



  clearField(event) {
    if (event.target.id === "picksheetField") {
      this.containerInput = "";
    }
  }

  onGridReady(params) {
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  };


  resizeGrid(params) {
    this.unpackedGrid.api.sizeColumnsToFit();
    this.unpackedGrid.api.resetRowHeights();
    this.packedGrids.forEach(grid => {
      grid.api.sizeColumnsToFit();
      grid.api.resetRowHeights();
    });
  }

  removeContainerFromPackage(containerCode: string) {
    const foundPackage = this.packages.find(p => p.containers.some(c => c.containerCode.toLowerCase() === containerCode));
    const foundContainer = this.allContainerRows.find(c => c.containerCode.toLowerCase() === containerCode);
    const payload: PackageAssignmentRequest = { orderItemContainerID: foundContainer.orderItemContainerID };
    this.orderService.removeContainerFromPackage(foundPackage.orderPackageID, payload).subscribe(() => {
      this.lastScannedContainerID = '';
      this.lastScanned_Unique_Chemical_Lot_Amount = '';
      this.responseHandler.showSuccess('Successfully removed package from container');
      foundContainer.packed = false;
      this.buildFormattedUnpackedGrid();
      this.refreshPackages();
      if (!this.allContainerRows.some(c => c.packed)) {
        this.isUnpacking = false;
      }
    })
  }

  disableUnpack(): boolean {
    return this.packages?.length === 0 || !this.allContainerRows.some(c => c.packed);
  }

  disablePrintingComplete(): boolean {
    return this.formattedUnpackagedContainerRows.length > 0 || this.packageGroupings.length === 0 || this.packageGroupings.some(pg => pg.containers.length === 0 || !pg.shipperID || (!pg.trackingNumber && !(pg.shipperName?.toLowerCase().includes('on site') || pg.shipperName?.toLowerCase().includes('other'))));
  }

  get disableCompleteRequest(): boolean {
    if (!this.order?.isOnHold && this.printingComplete)
      return false;
    else
      return true;
  }

  getCordinatorNotes(coordinatorNotes) {
    return coordinatorNotes?.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  onCompleteClick(): void {
    const dialogData =
      new ConfirmDialogModel("Confirm Move",
        `Are you sure you want to move request ${this.order.requestNumber} to Complete?`,
        "Move", false, true, "Cancel");
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      height: "160px",
      disableClose: true,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.moveToComplete();
      }
    });
  }

  moveToComplete(): void {
    this.orderService.updateOrderStatus(this.order.orderID, 8).subscribe(data => {
      this.responseHandler.showSuccess(`Successfully moved request ${this.order.requestNumber} to Complete.`);
      setTimeout(() => this.router.navigateByUrl('packing'), 2000);
    });
  }

  printPackageLabel(packageGrouping: PackageGrouping): void {
    this.orderService.printShippingLabelWithStation(packageGrouping.orderPackageID, this.selectedStation.stationID).subscribe(response => {
      this.responseHandler.showSuccess('Address Label sent to printer', 5000);
    }, error => {
      this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000);
    });
  }

  openViewAttachments(): void {
    const data: ViewAttachmentsModalData = {
      orderAttachments: this.order.attachments,
      orderItems: this.order.items,
      requestNumber: this.order.requestNumber,
      documentTypes: this.documentTypes,
    };
    this.dialog.open(ViewAttachmentsModalComponent, {
      height: '600px',
      width: '700px',
      data,
    });
  }
  placeHold(): void {
    this.dialog.open(HoldModalComponent, { width: "500px", height: "412px", data: { orderID: this.order.orderID, holdNotes: this.order.holdNotes, isOnHold: this.order?.isOnHold, isplaceOnHold: true, isp2p: false } }).afterClosed().subscribe((res: any) => {
      this.canDefocus = false;
      this.containerFieldRef?.nativeElement.focus();
      if (!res) { return; }
      if (res) {
        this.order.isOnHold = res.isOnHold;
        this.order.holdNotes = res.holdNotes;
        this.order.holdReasons = res.holdReasons;
        this.showOnHoldTooltip = this.order?.isOnHold;
      }
    });
  };

  RemoveHold(): void {
    this.dialog.open(HoldModalComponent, { width: "300px", height: "210px", data: { orderID: this.order.orderID, holdNotes: this.order.holdNotes, isOnHold: this.order?.isOnHold, isplaceOnHold: false, isp2p: false } }).afterClosed().subscribe((res: any) => {
      this.canDefocus = false;
      this.containerFieldRef?.nativeElement.focus();
      if (!res) { return; }
      if (res) {
        this.order.isOnHold = false;
        this.showOnHoldTooltip = this.order?.isOnHold;
      }
    });
  };

  saveHoldNotes(holdNotes: string): void {
    this.canDefocus = false;
    this.containerFieldRef?.nativeElement.focus();
    this.orderService.updateHoldNotes(this.order.orderID, { holdNotes }).subscribe(res => {
      this.order.holdNotes = holdNotes;
      this.responseHandler.showSuccess(`Successfully saved hold notes for the request ${this.order.requestNumber}.`);
    })
  }

  onEditNotesClick(): void {
    this.canDefocus = true;
  }

  onCancelNotesEdit(): void {
    this.canDefocus = false;
    this.containerFieldRef?.nativeElement.focus();
  }
}
