import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private messageSubject = new ReplaySubject<LoaderMessage>(1);
  private messageDelay = 300;
  private currMsg: LoaderMessage;

  messageTimer: any;

  public clearMessage() {
    this.clearMessageTimerIfSet();
    this.messageSubject.next(null);
  }

  public show(message: string = 'Loading', keepPreviousIfNone: boolean = false, secondary?: string, action?: string, actionCallback?: (() => any)) {
    this.clearMessageTimerIfSet();

    this.currMsg = {
      message: message,
      actionMessage: action,
      secondaryMessage: secondary,
      callback: actionCallback
    };
    this.actionCallback = actionCallback;
    this.messageSubject.next(this.currMsg);
  }

  public onMessageUpdate(): Observable<LoaderMessage> {
    return this.messageSubject.asObservable();
  }

  public setMessageDelay(delay: number) {
    this.messageDelay = delay;
  }

  actionCallback;
  public setSecondaryMessage(msg: string, action?: string, callback?: (() => any)) {
    this.show(this.currMsg.message, false, msg, action, callback);
  }

  private clearMessageTimerIfSet() {
    if (this.messageTimer) {
      clearTimeout(this.messageTimer);
      this.messageTimer = null;
    }
  }
}

export interface LoaderMessage {
  message: string;
  secondaryMessage: string;
  actionMessage: string;
  callback: any
}