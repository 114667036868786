import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'move-button-cell',
    template: `<i class="pi pi-times" (click)="cancel()"></i>`,
    styles: [],
  })
  
 
  export class CancelButtonRenderer implements ICellRendererAngularComp {
    public params: any;
    private gridApi: any;
  
    constructor() { }
  
    agInit(params: any): void {
      this.params = params;
      this.gridApi = params.api;
    }
  
    cancel() {
      this.gridApi.dispatchEvent({ type: 'cancelButtonClicked', value: this.params.data });
    }
  
    refresh(): boolean {
      return false;
    }
  }