<app-pageheader></app-pageheader>
<app-navigate [ngClass]="{'p-d-none': isActionButtonVisible}">
    <div class="p-text-right p-col">
        <button title="saveWeights" (click)="saveWeights()" [ngClass]="{'p-d-none': !receivingSession}"
            [disabled]="enableSaveAndContinue()" pButton pRipple type="button" icon="pi pi-angle-right" iconPos="right"
            label={{buttonText}}></button>
    </div>
</app-navigate>
<div [ngClass]="{'p-d-none': !receivingSession}">
    <div class="p-d-flex p-flex-column p-flex-md-row p-flex-wrap p-p-0 p-mt-3">
        <ng-container *ngFor="let scale of scalesList">
            <div class="p-col p-col-with-margin">
                <p-toggleButton [(ngModel)]="scale.isSelected" [disabled]="disableWeighingPage" id="btnSelect"
                    class="p-d-flex p-d-flex" [ngClass]="{'selected-scale': scale.isSelected }"
                    (onChange)="onScaleSelect($event,scale)" [onLabel]="scale.name" [offLabel]="scale.name"
                    onIcon="pi pi-check-square">
                </p-toggleButton>
            </div>
        </ng-container>
    </div>
    <div class="p-grid p-mt-4">
        <div class="p-col-12">
            <div>
                <span>Density : </span>
                <span class="p-text-bold">{{receivingSession?.density || 1 }} {{unitOfMeasureCollection | ArrayFindItem
                    :
                    'id':receivingSession?.unitOfMeasureId:'description'}}</span>
            </div>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="p-grid p-jc-end" [class.disabled]="disableWeighingPage">
                <div class="p-col-12" ngForm #containerWeighForm="ngForm">
                    <p-card header="Actual Weight" styleClass="weight-card">
                        <ng-template pTemplate="header">
                            <div class="p-grid p-ml-2 p-mr-2">
                                <div class="p-col-6 p-text-left">Expected Weight:
                                    <span class="p-text-bold">
                                        {{ (receivingStateModel?.material?.displaySubmittedWeight !== false) ?
                                        ((receivingSession?.submittedWeight !== null) ?
                                        ((receivingSession?.submittedWeight) + " mg" ) : "N/A"): "" }}
                                    </span>
                                </div>
                                <div class="p-col-6 p-text-right" *ngIf="receivingSession && containerWeights">Current
                                    container:
                                    (<span
                                        class="p-text-bold">{{containerWeights.length}}/{{receivingSession?.noOfContainers}}</span>)
                                </div>
                            </div>
                        </ng-template>
                        <h1>{{actualWeight | number: '1.0-7'}} Kg</h1>
                        <ng-template pTemplate="footer">
                            <div class="p-d-flex p-jc-end">
                                <div class="knownWeight">
                                    <label class="p-text-bold">Known Weight (kg)</label>
                                    <input type="text" #knownweigh pInputText [(ngModel)]="knownWeight" aria-describedby="known-help" #knownWeightInput="ngModel" name="knownWeightInput"
                                        (keyup)="validateKnownWeightAndCalculateTareWeight()" decimalNumber>
                                    <small id="tare-help" class="p-text-right">Only 8 digits after decimal</small>
                                </div>
                                <div class="tareWeight">
                                    <label class="p-text-bold">Tare Weight (kg)
                                        <span class="asterisk">*</span>
                                    </label>
                                    <input type="text" #tareweigh required pInputText (click)="$event.target.select()"
                                        [(ngModel)]="tareWeight" aria-describedby="tare-help" #tareWeightInput="ngModel" name="tareWeightInput"
                                        (keyup)="validateTareWeight()" decimalNumber>
                                    <small id="tare-help" class="p-text-right">Only 8 digits after decimal</small>
                                </div>
                            </div>
                        </ng-template>
                    </p-card>
                </div>
                <div class="p-field p-col p-py-0 p-mb-0">
                    <small id="username-help">Place container on scale and provide tare weight accordingly</small>
                </div>
                <div class="p-col-12" *ngIf="receivingSession">
                    <div class="p-grid p-jc-end p-mr-0">
                        <div class="p-text-right p-mt-2 p-mr-2" [ngClass]="{'p-d-none': !receivingSession}">
                            <button pbutton icon="pi pi-times" iconPos="left" class="p-button-danger"
                                (click)="logDefect()" pButton pRipple type="button" label="Log Defect"
                                [disabled]="allContainersAreWeighted() || !receivingSession.isGLP"
                                id="logDefectButton"></button>
                        </div>
                        <div class="p-text-right p-mt-2 p-ml-2" [ngClass]="{'p-d-none': !receivingSession}"
                            #weightButton><button pbutton icon="pi pi-check" iconPos="right" class="p-button-secondary"
                                (click)="acceptWeight()"
                                [disabled]="allContainersAreWeighted() || containerWeighForm.invalid || isAcceptWeightDisabled" pButton pRipple
                                type="button" label="Accept Weight"></button></div>
                    </div>
                </div>
                <app-navigate class="p-col-12 p-d-md-none">
                    <div class="p-text-right p-col">
                        <button (click)="saveWeights()" *ngIf="receivingSession" [disabled]="enableSaveAndContinue()"
                            pButton pRipple type="button" icon="pi pi-angle-right" iconPos="right"
                            label={{buttonText}}></button>
                    </div>
                </app-navigate>
            </div>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="p-grid p-mr-0 p-ml-1 p-ml-md-3">
                <div class="p-card p-col-12 weight-card p-d-flex p-flex-column" [class.disabled]="disableWeighingPage">
                    <div class="p-mt-2 p-ml-2"><span>Accepted Weights</span></div>
                    <p-orderList [value]="containerWeights" [listStyle]="{'height':'auto'}" [responsive]="true">
                        <ng-template let-weight pTemplate="item">
                            <div class="product-item">
                                <div class="image-container">
                                    <img src="assets/bottle.png" alt="Container" />
                                </div>
                                <div class="product-list-detail">
                                    <div class="p-mb-2 netweight">
                                        <span class=""># {{containerWeights.indexOf(weight) + 1 }}. Net Weight:
                                        </span><span class="netweight-value">{{weight.containerWeight -
                                            weight.tareWeight | number:'.1-8'}} Kg</span>
                                    </div>
                                    <i class="pi pi-tag p-mr-2"></i>
                                    Actual Weight: <span class="p-text-bold">{{weight.containerWeight | number:'.1-7'}}
                                        Kg</span>,
                                    Tare Weight: <span class="p-text-bold">{{weight.tareWeight | number:'.1-8'}}
                                        Kg</span>
                                </div>
                                <div class="product-list-action">
                                    <i class="pi pi-trash product-category-icon"
                                        *ngIf="(weight.catalogInventoryItemId ===0)" (click)="deleteWeight(weight)"></i>
                                </div>
                            </div>
                        </ng-template>
                    </p-orderList>
                </div>
            </div>
        </div>
    </div>
    <app-navigate>
        <div class="p-text-right p-col">
            <button title="saveWeights" #navigate (click)="saveWeights()" [ngClass]="{'p-d-none': !receivingSession}"
                [disabled]="enableSaveAndContinue()" pButton pRipple type="button" icon="pi pi-angle-right"
                iconPos="right" label={{buttonText}}></button>
        </div>
    </app-navigate>
    <p-confirmDialog key="weight-defect" header="Alert" [style]="{width: '50vw'}"
        [breakpoints]="{'960px': '33vw', '640px': '75vw'}" icon="pi pi-exclamation-triangle"></p-confirmDialog>
</div>

<p-dialog [(visible)]="displayDefectDialog" [modal]="true">
    <ng-template pTemplate="header">
        Defect
    </ng-template>
    <div class="p-fluid p-formgrid p-grid p-p-3">
        <div>
            <div class="p-field p-col-12">
                <label class="p-required">
                    <span class="asterisk">*</span>
                    Anticipated weight:
                </label>
                <input type="text" #tareweigh required pInputText (click)="$event.target.select()"
                    [(ngModel)]="defectAnticipatedTareWeight" maxlength="20" id="defectAnticipatedTareWeightInput"
                    name="defectAnticipatedTareWeightInput" #defectAnticipatedTareWeightInput="ngModel">
                <div *ngIf="defectAnticipatedTareWeightInput.invalid && (defectAnticipatedTareWeightInput.dirty || defectAnticipatedTareWeightInput.touched)"
                    class="p-error">
                    <small *ngIf="defectAnticipatedTareWeightInput.errors?.required">
                        Anticipated weight is required.
                    </small>
                    <small *ngIf="defectAnticipatedTareWeightInput.errors?.maxlength">
                        Only 20 characters allowed.
                    </small>
                </div>
            </div>
            <div class="p-field p-col-12">
                <label class="p-required">
                    <span class="asterisk">*</span>
                    Actual weight:
                </label>
                <input type="text" #tareweigh required pInputText (click)="$event.target.select()"
                    [(ngModel)]="defectActualWeight" maxlength="20" id="defectActualWeightInput"
                    name="defectActualWeightInput" #defectActualWeightInput="ngModel">
                <div *ngIf="defectActualWeightInput.invalid && (defectActualWeightInput.dirty || defectActualWeightInput.touched)"
                    class="p-error">
                    <small *ngIf="defectActualWeightInput.errors?.required">
                        Actual weight is required.
                    </small>
                    <small *ngIf="defectActualWeightInput.errors?.maxlength">
                        Only 20 characters allowed.
                    </small>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer" class="p-button-secondary p-px-5 ">
        <button pButton pRipple type="button" label="Cancel" class="p-button-secondary p-px-3 p-m-2"
            (click)="displayDefectDialog = false" id="logDefectPopupCancelButton"></button>
        <button pButton pRipple type="submit" label="Confirm Defect" class="p-text-bold p-px-3 p-m-2"
            (click)="confirmDefect()" id="logDefectPopupOkButton"
            [disabled]="!defectAnticipatedTareWeight || !defectActualWeight"></button>
    </ng-template>
</p-dialog>