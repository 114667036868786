import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderItemModel } from 'src/app/models/order-item-model';
import { Order } from 'src/app/models/order-models';

@Component({
  selector: 'app-special-instructions-dialog',
  templateUrl: './special-instructions-dialog.component.html',
  styleUrls: ['./special-instructions-dialog.component.scss']
})
export class SpecialInstructionsDialogComponent implements OnInit {

  selectedOrderItem: OrderItemModel;
  order: Order;

  constructor(public dialogRef: MatDialogRef<SpecialInstructionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.selectedOrderItem = this.data.selectedOrderItem;
    this.order = this.data.order;
  }

  onConfirm() {
    this.dialogRef.close();
  }

}
