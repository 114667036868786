import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { map, startWith, take, tap } from 'rxjs/operators';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { DecantStation } from 'src/app/models/decant-models';
import { InventoryItem } from 'src/app/models/inventory-models';
import { SignalREventType } from 'src/app/receiving/models/signalR.model';
import { UpdateWeightRequest } from 'src/app/receiving/models/weighing.model';
import { SignalRScaleServiceSubscription } from 'src/app/receiving/services/signalr-subscription.service';
import { HUB_SUBS_SERVICE_TOKEN, SignalRService } from 'src/app/receiving/services/signalr.service';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { StationService } from 'src/app/services/station/station.service';

interface ScaleView {
  currentStation: DecantStation;
  inventoryItem: InventoryItem;
  scaleMode: string
}

@Component({
  selector: 'app-reweigh-parent-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './reweigh-parent-container.component.html',
  styleUrls: ['./reweigh-parent-container.component.scss'],
  providers: [
    {
      provide: HUB_SUBS_SERVICE_TOKEN, useClass: SignalRScaleServiceSubscription
    },
    SignalRService,
    WeightFormatKGPipe
  ]
})
export class ReweighParentContainerComponent implements OnInit, AfterViewInit, OnDestroy {

  private selectedDecantStationSubject = new Subject<DecantStation>();
  selectedDecantStation$ = this.selectedDecantStationSubject.asObservable();

  // signalRServiceWeight$ = this.signalRService.updateWeightEvents$.pipe(
  //   startWith({ scaleId: null, weightKG: null } as UpdateWeightRequest ),
  //   tap(event => console.log('from signalr: ' + event.weightKG)),
  //   map(event => event));

  view$ = combineLatest([
    this.selectedDecantStation$,
    this.signalRService.updateWeightEvents$.pipe(startWith({ scaleId: null, weightKG: null } as UpdateWeightRequest ))
  ])
  .pipe(
    //tap(([, weighEvent]) => console.log(weighEvent.weightKG)),
    map(([decantStation, weighEvent]) => {
      const scales = [...decantStation.scales];
      if(weighEvent && weighEvent.weightKG && weighEvent.scaleId){
        let theScale = scales.find(scale => scale.scaleID === weighEvent.scaleId);
        if(theScale){
          theScale.weightKG = weighEvent.weightKG;
        }
      }
      const view: ScaleView = {
        currentStation: {...decantStation, scales: scales},
        inventoryItem: this.inventoryItem,
        scaleMode: this.scaleMode
      };
      this.changeDetectorRef.detectChanges();
      return view;
    })
  );

  decantStations: DecantStation[];
  selectedDecantStation: DecantStation;
  previousStation: DecantStation;
  public scaleInfo: DecantStation;
  barcodeInput;
  inventoryItem: InventoryItem;
  scaleMode = "Standalone"
  signalRServiceSubscription: Subscription;

  public columnDefs = [{ headerName: 'Lot Number', field: 'lotNumber' }, { headerName: 'Chemical', field: 'catalogDescription' }, { headerName: 'Previous Inventory Amount', field: 'remainingAmount' }, { headerName: 'Previous Total Weight', field: 'prevTotalWeight' }];
  public rowData = [];
  private gridApi;

  constructor(private inventoryService: InventoryService,
    private responseHandler: ResponseHandlingService,
    private weightFormatKGPipe: WeightFormatKGPipe,
    private signalRService: SignalRService,
    private stationService: StationService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef) {
      this.signalRService.startConnection();
  }

  ngOnInit() : void {

  }

  ngAfterViewInit(): void {
    this.signalRService.startConnection();

    document.getElementById('barcodeField').focus();

    if (localStorage.getItem("reweighStation")) {
      let station = JSON.parse(localStorage.getItem("reweighStation"));

      this.selectedDecantStation = station;

      this.selectedDecantStationSubject.next(station);

      const defaultWarehouseID = JSON.parse(localStorage.getItem("defaultWarehouseID"));
      if (this.selectedDecantStation && this.selectedDecantStation?.warehouseID !== defaultWarehouseID) {
        this.selectedDecantStation = null;
        localStorage.setItem("reweighStation", null);
      }
    };
  }

  ngOnDestroy(): void {
    this.signalRService.stopConnection();
    if (this.signalRServiceSubscription) { this.signalRServiceSubscription.unsubscribe(); }
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  resizeGrid(params): void {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  handleWeightCaptured() {
    this.inventoryItem = null;
    this.barcodeInput = '';
    document.getElementById('barcodeField').focus();
  }

  onBarcodeInput(event): void {
    const input = event.target.value;
    if (input != null && input.length > 0) {
      if (isNaN(input)) {
        this.responseHandler.showError("Inventory Item ID Invalid");
        if (this.inventoryItem) {
          this.barcodeInput = this.inventoryItem.itemID;
        } else {
          this.barcodeInput = '';
        }
        document.getElementById('barcodeField').focus();
      } else {
        this.inventoryService.getInventoryItem(input).subscribe((res: InventoryItem) => {
          this.inventoryItem = res;
          this.createRowData();
        }, error => {
          this.barcodeInput = this.inventoryItem ? this.inventoryItem.itemID : '';
          document.getElementById('barcodeField').focus();
        });
      }
    } else {
      if (this.inventoryItem) {
        this.barcodeInput = this.inventoryItem.itemID;
        document.getElementById('barcodeField').focus();
      }
    }
  }

  onStationChange(station: DecantStation): void {
    if (station) {
      this.selectedDecantStationSubject.next(station);

      this.previousStation = this.selectedDecantStation;
      this.scaleInfo = station;
      this.selectedDecantStation = station
      localStorage.setItem('reweighStation', JSON.stringify(station));

      setTimeout(() => {
        document.getElementById('barcodeField').focus();
      }, 300);
    } else {
      this.selectedDecantStation = null;
    }
  }

  getStation() {
    this.stationService.retrieveStation(this.selectedDecantStation.stationID).pipe(take(1)).subscribe(station => {
      this.selectedDecantStation = station;
    });
  }

  private createRowData(): void {
    this.rowData = [{ lotNumber: this.inventoryItem.lotNumber, catalogDescription: this.inventoryItem.catalogDescription, remainingAmount: this.formatQuantity(this.inventoryItem.remainingAmount), prevTotalWeight: this.formatQuantity(this.inventoryItem.remainingAmount + (this.inventoryItem.tareWeightKG != null ? this.inventoryItem.tareWeightKG : 0)) }];
  }

  private formatQuantity(quantity: number): string {
    return `${this.weightFormatKGPipe.transform(this.formatAmount(quantity))} ${quantity < 1 ? 'g' : 'kg'}`
  }

  formatAmount(amount: number): number {
    if (amount < 1) {
      return amount * 1000;
    }
    return amount
  }

  resetInput(): void {
    this.barcodeInput = '';
  }
}
