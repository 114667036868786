import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-disposal-comment-renderer',
  templateUrl: './disposal-comment-renderer.component.html',
  styleUrls: ['./disposal-comment-renderer.component.scss']
})
export class DisposalCommentRendererComponent implements ICellRendererAngularComp {

  public params: any;
  private gridApi: any;
  public value: string;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
    this.value = params.value;
  }
  refresh(): boolean {
    return false;
  }
  commentChange(e: any) {
    this.params.node.setData({ ...this.params.node.data, comments: this.value })
  }
}
