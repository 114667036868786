import { CancellationReasonsService } from './../../services/cancellation-reasons/cancellation-reasons.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { P } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-cancellation-reasons-modal',
  templateUrl: './cancellation-reasons-modal.component.html'
})

export class CancellationReasonsModalComponent implements OnInit {

  public cancellationReasonForm: FormGroup;
  public buttonLabel: string;
  public reason: string = '';
  public reasonID: number;
  public isEditing: boolean;

  constructor(
    public dialogRef: MatDialogRef<CancellationReasonsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loader: LoadingService,
    private toastr: ResponseHandlingService,
    public cancellationReasonsService: CancellationReasonsService,
    private dialog: MatDialog) {
    if (typeof data !== 'undefined' && data) {
      this.buttonLabel = "Edit";
      this.reasonID = this.data.reasonID;
      this.isEditing = true;
    } else {
      this.buttonLabel = "Add";
      this.reasonID = 0;
    }
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.cancellationReasonForm = new FormGroup({
      cancellationReason: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
      message: new FormControl(null, Validators.required)
    });
    if (this.isEditing)
      this.patchForm();
  }

  get fc() {
    return this.cancellationReasonForm.controls;
  }

  get fv() {
    return this.cancellationReasonForm.value;
  }

  saveOrUpdate() {
    this.cancellationReasonsService.addOrUpdateCancellationReason({ reasonID: this.reasonID, name: this.cancellationReasonForm.value.cancellationReason, messageText: this.cancellationReasonForm.value.message })
      .subscribe(resp => {
        this.toastr.showSuccess("Changes saved successfully");
        this.dialogRef.close(true);
      },
        error => {
          this.toastr.showError("Failed to add Cancellation reason. " + error);
          this.dialogRef.close(false);
        });
  }

  cancel() {
    if (this.cancellationReasonForm.pristine) {
      this.dialogRef.close(false);
    } else {
      const dialogData = new ConfirmDialogModel("Unsaved Data", `There are changes that are not saved.  Do you wish to proceed?`, "Cancel", false, true, "Discard Changes");
      this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        disableClose: true,
        data: dialogData
      }).afterClosed().subscribe(dialogResult => {
        if (!dialogResult)
          this.dialogRef.close(false);
      });
    }
  }

  private patchForm() {
    this.cancellationReasonForm.patchValue({
      cancellationReason: this.data?.name,
      message: this.data?.messageText
    });
  }

  messageTexttooltiptext() {
    return `You can use these substitution variables in the message text:
    $RequestNumber$ - request number
    $CancelledBy$ - name of user cancelling request
    $Reason$ - reason name + comments, if provided
    $ReasonName$ - reason name only
    $ReasonComment$ - Comments: <comment>
    $CancelledItems$ - list of cancelled items`;
  }
}