import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
 
@Component({
  selector: 'app-delete-container',
  template: '<span (click)="onClick($event)"><i class="pi pi-trash" style="cursor:pointer;"></i></span>',
  styleUrls: ['./delete-container.component.scss']
})
export class DeleteContainerComponent implements ICellRendererAngularComp {

  params;
  label: string;
  
  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data
      }

      this.params.onClick(params);
    }
  }
}
