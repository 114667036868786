<h5>Package {{ data.packageGrouping.packageCode }} Shipping Information</h5>
<div class="shipping">
    <div>
        <label for="carrier">Carrier</label>
        <p-dropdown id="carrier" placeholder="Select a Carrier" panelStyleClass='package-selection-minWidthOverride'
            [(ngModel)]="selectedShipper" (ngModelChange)="onCarrierChange()" [options]="data.carriers" optionLabel="shipperName">
        </p-dropdown>
    </div>
    <div>
        <label for="tracking-number">Tracking Number</label>
        <input id="tracking-number" [disabled]="!selectedShipper?.trackingURLFormat" [(ngModel)]='newTrackingNumber' placeholder="Enter Tracking No." type="text" pInputText>
    </div>
    <div>
        <label for="master-package">Master</label>
        <input [(ngModel)]="isMaster" id="master-package" type="checkbox" class="checkbox"/>
    </div>
</div>
<div class="actions">
    <button mat-raised-button (click)="cancel()">Cancel</button>
    <button mat-raised-button [disabled]="disableSave()" color="cor-primary" (click)="save()" id="btn-save-shipping-info">Save</button>
</div>