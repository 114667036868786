import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-scale-progress-bar',
  templateUrl: './scale-progress-bar.component.html',
  styleUrls: ['./scale-progress-bar.component.scss']
})
export class ScaleProgressBarComponent implements OnChanges {

  @Input() currentAmount: number;
  @Input() desiredAmount: number;
  @Input() unitOfMeasure: string;
  @Input() isReweighMode: boolean;
  @Input() decimalPlaces: number;
  public progress: number;
  public width: number;
  public total: number;
  public color: string;
  public progressLabel: string;

  constructor() { }

  ngOnChanges(): void {
    if (!this.isReweighMode) {
      this.progress = (this.currentAmount / this.desiredAmount) * 100;
    } else {
      this.progress = 100;
    }

    this.width = (this.progress > 100) ? 100 : this.progress;

    if (this.progress >= 103) {
      this.color = 'red';
    } else if (this.progress < 103 && this.progress >= 100) {
      this.color = 'green';
    } else {
      this.color = 'yellow';
    }
  }

  public formatAmount(amount) {
    if (amount < 1) {
      return amount * 1000;
    }
    return amount
  }
}
