import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { Configuration } from '../../configuration';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SharedDcumentService {
  readonly rootURL = Configuration.REST_API_URL;

  constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
  }

  getSharedDocumentList(inventoryCatalogID: number) {
    var URL = this.rootURL + `/SharedDocument/${inventoryCatalogID}/List`;
    return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getSharedDocumentOptions() {
    var URL = this.rootURL + `/SharedDocument/Options`;
    return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  addSharedDocuments(orderItemID: any) {
    var URL = this.rootURL + `/SharedDocument/${orderItemID}/AddToOrderItem`;
    return this.http.post<any>(URL, null).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  createSharedDocument(payload) {
    var URL = this.rootURL + `/SharedDocument`;
    return this.http.post<any>(URL, payload).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  deleteSharedDocument(documentID: number): Observable<Response> {
    var URL = this.rootURL + `/SharedDocument/${documentID}`;
    return this.http.delete<Response>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getSharedDocument(documentID: number, containerID: string) {
    var URL = this.rootURL + `/SharedDocument/${documentID}/Download/${containerID}`;
    return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getSharedDocumentById(labelID: number) {
    var URL = this.rootURL + `/SharedDocument/${labelID}`;
    return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  viewDocument(documentID: any) {
    var URL = this.rootURL + `/SharedDocument/${documentID}`;
    return this.http
      .get<any>(URL)
      .pipe(catchError((err) => this.responseHandler.handleError(err)));
  }

}