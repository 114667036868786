<ng-container *ngIf="receivingSession; else noSession;">
    <app-pageheader [displayMaterialName]="false"></app-pageheader>
    <div class="card p-pt-4 submit-form" >
        <div class="p-fluid p-mb-6 ">
            <div class="p-field p-grid">
                <label class="large-label p-col-12 p-mb-2 p-md-3 p-mb-md-0">
                    Material ID:
                </label>
                <div class="p-col-12 p-md-9">
                    <span class="large-label">{{receivingSession?.materialName}}</span>
                    <p-tag class="tag" *ngIf="receivingSession?.isGLP" severity="danger" value="GLP" [rounded]="true">
                    </p-tag>
                </div>
            </div>
            <div class="p-field p-grid">
                <label class="large-label p-col-12 p-mb-2 p-md-3 p-mb-md-0">
                    {{receivingSession?.isGLP?'(GLP)':''}} Lot #:
                </label>
                <div class="p-col-12 p-md-9">
                    <span class="large-label">{{receivingSession?.lotNumber}}</span>
                </div>
            </div>
            <div class="p-field p-grid" *ngIf="receivingSession?.reachCompliant">
                <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">REACH Compliant:</label>
                <div class="p-col-12 p-md-9">
                    <i class="pi pi-check"></i>
                </div>
            </div>
            <div class="p-field p-grid">
                <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">VIM Container IDs:</label>
                <!-- <div class="p-col-3">VIM Container IDs: </div> -->
                <div class="p-d-flex p-col-12 p-md-9">
                    <div *ngFor="let containerWeight of containerWeights" class="p-mr-2 p-p2">
                        <p-chip [label]="containerWeight.catalogInventoryItemLabel"></p-chip>
                    </div>
                </div>
            </div>
            <div class="p-field p-grid">
                <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">Expiration Date: </label>
                <div class="p-col-12 p-md-9">
                    <span>{{receivingSession?.expirationDate |
                        date : 'MM/dd/yyyy'}}</span>
                </div>
            </div>
            <div class="p-field p-grid">
                <ng-container *ngTemplateOutlet="printer">
                </ng-container>
            </div>
        </div>
    </div>
    <ng-template let-ddvalue="ddvalue" #printer>
        <div class="p-field p-col-12 p-mb-2 p-md-3 p-mb-md-0">
            <label class="p-required" for="printer">
                Printer:
            </label>
        </div>
        <div class="p-field p-col-12 p-md-9">
            <p-dropdown [filter]="true" filterBy="name" [options]="printers" [appendTo]="ddvalue" optionLabel="displayName"
                optionValue="labelPrinterID" [resetFilterOnHide]="true" [(ngModel)]="selectedPrinterId">
                <ng-template pTemplate="selectedItem" let-printer>
                    <div *ngIf="!printersLoading; else loading">
                        <div>{{printer.displayName}}</div>
                    </div>
                </ng-template>
                <ng-template #loading><i class="pi  pi-spin pi-spinner"></i> </ng-template>
            </p-dropdown>
        </div>
    </ng-template>
    <app-navigate *ngIf="receivingSession">
        <div class="p-text-right p-mb-2">
            <button pButton pRipple type="button" icon="pi pi-print" iconPos="right" label="Print Labels" class="p-mr-4"
                (click)="printLabels()"
                [ngClass]="{'p-button-secondary':labelsPrintedCorrectly, 'p-button-primary':!labelsPrintedCorrectly, 'p-text-bold':!labelsPrintedCorrectly}"></button>
            <button pButton pRipple type="button" icon="pi pi-check" iconPos="right" label="Complete Receipt" [ngClass]="{'hidden':receivingSession?.statusType == StatusType.Completed}"
                [disabled]="!labelsPrintedCorrectly" class="p-text-bold" (click)="onCompleteReceiptClick()"></button>
        </div>
    </app-navigate>
    <p-confirmDialog key="label" header="Alert" [style]="{width: '50vw'}" icon="pi pi-exclamation-triangle">
    </p-confirmDialog>
    <p-dialog [(visible)]="displayPrintLabel" styleClass="dialog-width" [modal]="true" *ngIf="printingForm">
    
        <ng-template pTemplate="header">
            Print Label
        </ng-template>
        <form [formGroup]="printingForm">
            <div class="p-fluid p-formgrid p-grid p-p-3">
                <ng-container *ngTemplateOutlet="printer; context:{ddvalue:'body'}"></ng-container>
                <div class="p-field p-col-12">
                    <div class="p-field-radiobutton p-mt-3">
                        <p-radioButton name="printType" value="0" formControlName="printType" inputId="allLabels">
                        </p-radioButton>
                        <label for="allLabels">All Labels</label>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-3 p-as-end">
                    <div class="p-field-radiobutton ">
                        <p-radioButton name="printType" value="1" formControlName="printType" inputId="custom">
                        </p-radioButton>
                        <label for="custom">Custom</label>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-9">
                    <input id="custom" #custom type="text" pInputText placeholder="e.g. 121-130, 135, 139"
                        formControlName="custom">
                    <div [ngClass]="{'p-error': printingForm.get('custom').invalid}"
                        *ngIf="printingForm.get('custom').touched">
                        <small *ngIf="printingForm.get('custom').errors?.pattern">
                            Container # or range of containers is invalid</small>
                        <small *ngIf="printingForm.get('custom').errors?.required">
                            Container # or range of containers is required</small>
                        <small *ngIf="printingForm.get('custom').errors?.containerRange">
                            Container numbers are not within current session. </small>    
                    </div>                    
                </div>
            </div>
        </form>
        <ng-template pTemplate="footer">
            <button pButton type="button" label="Cancel" class="p-button-secondary p-px-5"
                (click)="closePrintDialog()"></button>
            <button pButton type="submit" label="Print Now" class="p-text-bold p-px-5" (click)="doPrint()"
                [disabled]="printingForm.invalid"></button>
        </ng-template>
    </p-dialog>
</ng-container>
<ng-template #noSession>
    <div *ngIf="isLoading; else notFound">
        Checking Session...
    </div>
    <ng-template #notFound>No session information found</ng-template>
</ng-template>
