import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'move-button-cell',
  template: `<div>
    <div style="margin-bottom: -6px" *ngIf="params.data?.extraPPERequired">
      <span class="p-ml-3">
        <app-pill Text="Extra PPE Required" Type="ExtraPPERequired"></app-pill>
      </span>
    </div>

    <div style="margin-bottom: -6px" *ngIf="params.data?.isCold">
      <span class="p-ml-3">
        <app-pill Text="Cold" Type="Cold"></app-pill>
      </span>
    </div>

    <div style="margin-bottom: -6px" *ngIf="params.data?.isDesiccant">
      <span class="p-ml-3">
        <app-pill Text="Desiccant" Type="Desiccant"></app-pill>
      </span>
    </div>

    <div style="margin-bottom: -6px" *ngIf="params.data?.isNoDecantContainer">
      <span class="p-ml-3">
        <app-pill Text="No Decant" Type="NoDecant"></app-pill>
      </span>
    </div>
  </div>`,
})
export class PillRenderer implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;

  constructor() { }

  refresh(params: any): boolean {
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
  }
}