<h5 style="padding-bottom: 15px">Update Location and Reprint Label</h5>
<form [formGroup]="inventoryLocationForm" (ngSubmit)="onSubmit()">
    <div class="p-grid">
        <div class="p-col-4">
            <label>Lot Number: </label>
        </div>
        <div class="p-col-8">
          <span>{{data.lotNumber}}</span>
        </div>
        <div class="p-col-4">
            <label>Container ID: </label>
        </div>
        <div class="p-col-8">
          <span>{{data.containerId}}</span>
        </div>
        <div class="p-col-4">
            <label>Location: </label>
        </div>
        <div class="p-col-8">
            <p-dropdown placeholder="Select Location" defaultLabel="Select Location" autoWidth="true" [style]="{'width':'100%'}"
                formControlName="locations" [options]="data.locations" optionLabel="fullLocationName" optionValue="locationID">
            </p-dropdown>
        </div>
        <div class="p-col-4">
            <label>Printer: </label>
        </div>
        <div class="p-col-8">
            <p-dropdown placeholder="Select Printer" defaultLabel="Select Printer" [style]="{'width':'100%'}" [autoWidth]="false"
                formControlName="printers" [options]="data.printers" optionLabel="displayName" optionValue="labelPrinterID">
            </p-dropdown>
        </div>
    </div>

    <div class="flex-end">
        <button type="button" style="margin-right: 10px;" mat-raised-button color="cor-default" (click)="onClose()">Cancel</button>
        <button type="submit" mat-raised-button color="cor-default" color="cor-primary" [disabled]="!inventoryLocationForm.valid">Save and Print</button>
    </div>
</form>
