import { CancellationReasons } from './../../models/cancellation-reasons';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { Configuration } from 'src/app/configuration';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CancellationReasonsService {

  constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
  }

  getCancellationReasons() {
      var URL = Configuration.REST_API_URL + `/CancelReason`;
      return this.http.get<CancellationReasons[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  deleteCancellationReason(ID: number) {
      var URL = Configuration.REST_API_URL + `/CancelReason/${ID}`;
      return this.http.delete<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  addOrUpdateCancellationReason(payload) {
      var URL = Configuration.REST_API_URL + `/CancelReason`;
      return this.http.post<CancellationReasons>(URL, payload).pipe(catchError(err => this.responseHandler.handleError(err)));
  }
}