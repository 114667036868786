import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../configuration';
import { DestinationGuideOptionModel } from '../../models/destination-guide-option';
import { ResponseHandlingService } from '../../services/response-handling-service/response-handling-service';
import { DestinationGuideOptionsType } from 'src/app/models/destination-guide-options';

@Injectable({
  providedIn: 'root'
})
export class DestinationGuideOptionsService {

  constructor(private readonly http: HttpClient, private readonly responseHandler: ResponseHandlingService) {
  }

  getDestionationGuideOptions(): Observable<DestinationGuideOptionModel[]> {
    var URL = `${Configuration.REST_API_URL}/DestinationGuide`;
    return this.http.get<DestinationGuideOptionModel[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to get list of options at this time, please resubmit."]]), false)));
  }

  getDestionationGuideOptionTypes(): Observable<DestinationGuideOptionsType[]> {
    var URL = `${Configuration.REST_API_URL}/DestinationGuide/OptionTypes`;
    return this.http.get<DestinationGuideOptionsType[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to get list of options at this time, please resubmit."]]), false)));
  }

  deleteDestionationGuideOption(optionId: number): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/DestinationGuide/Option/${optionId}`;
    return this.http.delete<any>(URL).pipe(
      catchError(err => {
        this.responseHandler.showMessage("Unable to delete this option at this time, please resubmit.",'error', "Error",3000, false);
        return of(null);
      })
    );
  }

  editDestionationGuideOption(option: DestinationGuideOptionModel): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/DestinationGuide/Option`;
    return this.http.post<DestinationGuideOptionModel>(URL,option).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable save options at this time, please resubmit."]]), false)));
  }
}
