import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { finalize, flatMap, take } from 'rxjs/operators';
import { ViewAttachmentsModalData } from 'src/app/models/attachment-models';
import { DocumentType } from 'src/app/models/document-type-model';
import { Order, OrderPackage, RequestTypes } from 'src/app/models/order-models';
import { OrderItemAttachmentService } from 'src/app/services/order-item-attachments-service/order-tem-attachments.service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { PrintButtonRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/print-button-renderer.component';
import { PrintAddressLabelButtonRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/print-address-label-button-renderer.component';
import { ContainerNumberDropdownRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/container-number-dropdown-renderer.component';
import { PackageDropdownRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/package-dropdown-renderer.component';
import { ViewAttachmentsModalComponent } from '../view-attachments-modal/view-attachments-modal.component';
import { OrderItemContainerAssignmentResponse, OrderItemContainerPartial } from 'src/app/models/order-item-container-model';
import { PrintGLPLabelButtonRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/print-glp-label-button-renderer.component';
import { PrintVialLabelButtonRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/print-vial-label-button-renderer/print-vial-label-button-renderer.component';
import { DecantStation } from 'src/app/models/decant-models';
import { DecantService } from 'src/app/services/decant-service/decant-service';
import { PrintLabelButtonRendererComponent } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/print-label-button-renderer/print-label-button-renderer.component';
import { PrintLabelModalComponent } from '../print-label-modal/print-label-modal.component';
import { RetrieveCatalogItemWithConversionResponse } from 'src/app/models/inventory-models';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { RequestDocumentsModalComponent } from '../request-documents-modal/request-documents-modal.component';
import { PrintSmallBarcodeLabelComponent } from 'src/app/core/modules/ag-grid/components/ag-grid-cell-renderers/print-small-barcode-label/print-small-barcode-label.component';

@Component({
  selector: 'app-reprinting',
  templateUrl: './reprinting.component.html',
  styleUrls: ['./reprinting.component.scss']
})
export class ReprintingComponent implements OnInit {

  stations: DecantStation[];
  selectedReprintStation: DecantStation;

  requestNumberInput = '';
  order: Order;
  packages: OrderPackage[];
  public documentTypes: DocumentType[];

  allContainersDecanted = true;

  itemColumnDefs = [
    {
      headerName: 'Chemical',
      minWidth: 200, maxWidth: 200, width: 200,
      field: 'requestedContainerLabel', tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.requestedContainerLabel) {
          return params.data.requestedContainerLabel;
        }
      }
    },
    {
      headerName: 'Container Number', cellRenderer: 'containerDropdownRenderer', field: 'containerNumber', minWidth: 150, maxWidth: 150, width: 150
    },
    {
      cellRenderer: 'printButtonRenderer', minWidth: 200, maxWidth: 200, width: 200
    },
    {
      cellRenderer: 'printGLPLabelButtonRenderer', minWidth: 170, maxWidth: 170, width: 170
    },
    {
      cellRenderer: 'printVialLabelButtonRenderer', minWidth: 210, maxWidth: 210, width: 210
    },
    {
      cellRenderer: 'printSmallBarcodeLabelRenderer', minWidth: 210, maxWidth: 210, width: 210
    },
    {
      cellRenderer: 'printLabelButtonRenderer', minWidth: 200, maxWidth: 200, width: 200
    }
  ];

  packingColumnDefs = [
    {
      headerName: 'Package Code', cellRenderer: 'packageDropDownRenderer', field: 'packageCode',
    },
    {
      headerName: 'Tracking Number', field: 'trackingInfo', cellRenderer: (params) => {
        if (!params.data.trackingInfo) { return ''; }
        return `<a href="${params.data.trackingURL}" target="_blank" style="cursor:pointer;">${params.data.trackingInfo}</a>`
      }
    },
    {
      cellRenderer: 'printAddressLabelButtonRenderer', minWidth: 200, maxWidth: 200, width: 200
    }
  ];

  rowData = [];
  frameworkComponents;
  packageFrameworkComponents;
  gridApi;
  packageGridApi;

  pageLoaded = false;

  packageRowData = [];

  get hasAttachments() {
    if (!this.order) { return false; }
    if (this.order.attachments && this.order.attachments.length > 0) {
      return true;
    } else if (this.order.items && this.order.items.some(i => i.attachments && i.attachments.length > 0)) {
      return true;
    }
    else {
      return false;
    }
  }
  constructor(private orderService: OrderService, private loadingService: LoadingService, private decantService: DecantService, private orderItemAttachmentService: OrderItemAttachmentService, private responseHandler: ResponseHandlingService, public dialog: MatDialog, private inventoryService: InventoryService) {
    this.frameworkComponents = {
      printButtonRenderer: PrintButtonRenderer,
      containerDropdownRenderer: ContainerNumberDropdownRenderer,
      printGLPLabelButtonRenderer: PrintGLPLabelButtonRenderer,
      printVialLabelButtonRenderer: PrintVialLabelButtonRenderer,
      printLabelButtonRenderer: PrintLabelButtonRendererComponent,
      printSmallBarcodeLabelRenderer: PrintSmallBarcodeLabelComponent
    };
    this.packageFrameworkComponents = {
      packageDropDownRenderer: PackageDropdownRenderer,
      printAddressLabelButtonRenderer: PrintAddressLabelButtonRenderer
    };

    if (localStorage.getItem("reprintStation")) {
      this.selectedReprintStation = JSON.parse(localStorage.getItem("reprintStation"));
      const defaultWarehouseID = JSON.parse(localStorage.getItem("defaultWarehouseID"));
      if (this.selectedReprintStation && this.selectedReprintStation?.warehouseID !== defaultWarehouseID) {
        this.selectedReprintStation = null;
        localStorage.setItem("reprintStation", null);
      }
    };

  }

  ngOnInit(): void {
    this.orderItemAttachmentService.documentTypes$.pipe(take(1)).subscribe((res: DocumentType[]) => {
      this.documentTypes = res;
    });
  }

  onRequestIdInput(event: Event, val: string) {
    if (val.trim() === '') {
      this.requestNumberInput = this.order ? this.order.requestNumber : '';
      return;
    }
    if (event.type === 'blur') { return; }
    const input = val.toLowerCase();
    this.loadingService.show("Loading ...");
    this.orderService.getOrderByRequestNumber(input).pipe(
      finalize(() => this.loadingService.clearMessage()),
      flatMap((res: Order) => {
        this.order = res;
        this.createDataSetUp();
        return this.orderService.getPackages(this.order.orderID);
      }))
      .pipe(finalize(() => this.loadingService.clearMessage()))
      .subscribe((res: OrderPackage[]) => {
        this.packages = res;
        this.setUpPackageGrid();
        this.pageLoaded = true;
      }, error => {
        this.requestNumberInput = this.order ? this.order.requestNumber : '';
        document.getElementById('requestID').focus();
      });
  }

  openViewAttachments(): void {
    const data: ViewAttachmentsModalData = {
      orderAttachments: this.order.attachments,
      orderItems: this.order.items,
      requestNumber: this.order.requestNumber,
      documentTypes: this.documentTypes,
    };
    this.dialog.open(ViewAttachmentsModalComponent, {
      height: '600px',
      width: '700px',
      data,
    });
  }

  onStationChange(station: DecantStation): void {
    if (station) {
      localStorage.setItem('reprintStation', JSON.stringify(station));
      this.selectedReprintStation = JSON.parse(localStorage.getItem("reprintStation"));
      setTimeout(() => {
        document.getElementById('requestID').focus();
      }, 100);
    }
  }

  createDataSetUp(): void {
    this.rowData = [];
    this.order.items.forEach(i => {
      const orderItemContainerIDs: any[] = [];
      i.containers.sort((a, b) => a.containerNumber > b.containerNumber ? 1 : -1);

      i.containers.forEach(c => {
        if (c.decanted) {
          orderItemContainerIDs.push({
            containerNumber: c.containerNumber.toString(),
            orderItemContainerID: c.orderItemContainerID,
            amountPerContainer: c.requestedAmount,
            assignedLotNumber: c.assignedLotNumber,
            assignedInventoryCatalogID: c.assignedInventoryCatalogID,
            unitOfMeasureDesc: i.unitOfMeasureDesc,
            inventoryCatalogID: i.inventoryCatalogID,
            requestedContainerLabel: i.requestedContainerLabel
          })
        }
      });
      this.rowData.push([
        {
          requestedContainerLabel: i.requestedContainerLabel,
          orderItemContainerIDs,
          selectedContainer: orderItemContainerIDs[0],
          disableGLPPrint: this.order.requestTypeID !== RequestTypes.GLP,
        }
      ]);
    });
    for (const item of this.order.items) {
      if (!item.containers.every(c => c.decanted)) {
        this.allContainersDecanted = false;
        break;
      }
    }
  }

  setUpPackageGrid(): void {
    if (!this.packages || this.packages?.length === 0) { return; }
    const packageCodes = [];
    this.packages.forEach(p => {
      packageCodes.push({ packageCode: p.packageCode });
    });
    this.packageRowData = [{
      packageCodes,
      trackingInfo: this.packages[0].shipperName && this.packages[0].trackingNumber ? this.packages[0].shipperName + ' ' + this.packages[0].trackingNumber : null,
      selectedPackageCode: packageCodes[0],
      trackingURL: this.packages[0].trackingURL
    }];
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.addEventListener('printButtonClicked', (e) => {
      this.printContainerLabel(e);
    });
    this.gridApi.addEventListener('printGLPLabelButtonClicked', (e) => {
      this.printGLPLabel(e);
    });
    this.gridApi.addEventListener('printVialLabelButtonClicked', (e) => {
      this.printSmallVialLabel(e);
    });
    this.gridApi.addEventListener('printSmallBarcodelLabelButtonClicked', (e) => {
      this.printSmallBarcodeLabel(e);
    });
    this.gridApi.addEventListener('printLabelButtonClicked', (e) => {
      this.printLabel(e);
    });
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  requestDocumentsModal(): void {
    this.dialog.open(RequestDocumentsModalComponent, {
      data: { order: this.order, isReprinting: true },
      disableClose: true
    }).afterClosed().subscribe((res: string) => {
    });
  }

  private printLabel(e) {
    let inventoryDetails = null;
    this.inventoryService.getCatalogItemWithConversion(e.value?.selectedContainer.inventoryCatalogID, e.value?.selectedContainer?.unitOfMeasureDesc)
      .subscribe((res: RetrieveCatalogItemWithConversionResponse) => {
        inventoryDetails = res;
        let inventory = inventoryDetails?.lots.find(l => l.lotNumber === e.value?.selectedContainer.assignedLotNumber);

        const labelInfoData = {
          isGLPRequest: this.order.requestTypeID === RequestTypes.GLP,
          netContents: e.value?.selectedContainer?.amountPerContainer,
          unitOfMeasureDesc: e.value?.selectedContainer?.unitOfMeasureDesc,
          manufacturerLotNumber: inventory?.manufacturerLotNumber,
          lotNumber: e.value?.selectedContainer?.assignedLotNumber,
          manufactureDate: inventory?.manufactureDate,
          expirationDate: inventory?.expirationDate,
          recertificationDate: inventory?.recertificationDate,
          requestNumber: this.order.requestNumber,
          requestedContainerLabel: e.value?.selectedContainer.requestedContainerLabel
        }

        this.dialog.open(PrintLabelModalComponent, {
          minHeight: '200px',
          maxHeight: '450px',
          disableClose: true,
          autoFocus: false,
          data: {
            materialName: inventoryDetails?.description,
            inventoryCatalogID: e.value?.selectedContainer?.assignedInventoryCatalogID,
            labelInfoData: labelInfoData,
            containerId: e.value?.selectedContainer?.orderItemContainerID
          }
        })
      });
  }

  onPackageGridReady(params): void {
    this.packageGridApi = params.api;
    this.packageGridApi.addEventListener('packageChange', (e) => {
      this.setSelectedPackage(e);
    });
    this.packageGridApi.addEventListener('printAddressLabelButtonClicked', (e) => {
      this.printAddressLabel(e);
    });
    this.packageGridApi.sizeColumnsToFit();
    this.packageGridApi.resetRowHeights();
  }

  setSelectedPackage(e) {
    const selectedPackage = this.packages.find(p => p.packageCode === e.value.selectedPackageCode.packageCode);
    this.packageRowData = [
      {
        packageCodes: e.value.packageCodes,
        trackingInfo: selectedPackage.shipperName && selectedPackage.trackingNumber ? selectedPackage.shipperName + ' ' + selectedPackage.trackingNumber : null,
        selectedPackageCode: e.value.selectedPackageCode,
        trackingURL: selectedPackage.trackingURL
      }
    ];
  }

  printAddressLabel(e) {
    const code = e.value.selectedPackageCode.packageCode;
    const orderPackageID = this.packages.find(p => p.packageCode === code).orderPackageID;
    this.orderService.printShippingLabelWithStation(orderPackageID, this.selectedReprintStation.stationID).subscribe(response => {
      this.responseHandler.showSuccess('Address Label sent to printer', 5000);
    }, error => {
      this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000);
    });
  }

  printContainerLabel(e) {
    this.orderService.printLabel(e.value.selectedContainer.orderItemContainerID, this.selectedReprintStation.stationID)
      .subscribe(() => this.responseHandler.showSuccess('Label sent to printer', 5000),
        error => this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000)
      );
  }

  printGLPLabel(e) {
    this.orderService.printGLPLabel(e.value.selectedContainer.orderItemContainerID, this.selectedReprintStation.stationID).subscribe(res => {
      this.responseHandler.showSuccess('Label sent to printer', 5000);
    },
      error => {
        this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000);
      });
  }

  printSmallVialLabel(e) {
    this.orderService.printSmallVialLabel(e.value?.selectedContainer?.orderItemContainerID, this.selectedReprintStation.stationID).subscribe(response => {
      this.responseHandler.showSuccess('Label sent to printer', 5000);
    },
      error => {
        this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000);
      });
  }

  printSmallBarcodeLabel(e) {
    this.orderService.printSmallBarcodeLabel(e.value.selectedContainer.orderItemContainerID, this.selectedReprintStation.stationID).subscribe(response => {
      this.responseHandler.showSuccess('Label sent to printer', 5000);
    },
      error => {
        this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000);
      });
  }
}
