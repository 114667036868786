import { Component, OnInit } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-header-action',
  templateUrl: './header-action.component.html',
  styleUrls: ['./header-action.component.scss']
})
export class HeaderActionComponent implements OnInit, IHeaderAngularComp {

  showLessFlag: boolean = true;
  params: IHeaderParams;

  constructor() { }

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }

  ngOnInit(): void {
  }

  toggle(value: boolean) {
    this.showLessFlag = value;
    this.params.columnApi.setColumnsVisible(['certificationDate', 'manufacturerName', 'manufacturerLotNumber',
      'storageLocations', 'countryOfOrigin', 'isReachApproved', 'glpStatus', 'indyReservedAmount'],
      !value);
    this.params.columnApi.setColumnsVisible(['exp_recert_date', 'remainingAmountConverted', 'netAmountAvailableDisplay'],
      !!value);
    this.params.api.sizeColumnsToFit();
    this.params.api.resetRowHeights();
  }

}
