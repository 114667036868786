import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AddressViewModel } from '../../models/address.model';
import { ExternalServiceType } from '../../models/enumerations';
import { MaterialViewModel } from '../../models/material.model';
import { SupplierModel, SupplierViewModel } from '../../models/supplier.model';
import { receivingConstants } from '../../receiving.constants';
import { MaterialDataAdapterService } from '../../services/data-adapters/material-data-adapter.service';
import { ReceivingStateService } from '../../services/receiving-state.service';
import { ReceivingService } from '../../services/receiving.service';
@Component({
  selector: 'receiving-compound-suppliers',
  templateUrl: './compound-suppliers.component.html',
  styleUrls: ['./compound-suppliers.component.scss']
})
export class CompoundSuppliersComponent implements OnInit {

  @Input() material: MaterialViewModel;
  @Input() dataAdapter: MaterialDataAdapterService;
  @Input() suppliers: SupplierModel[];
  filteredSuppliers: SupplierModel[] = [];
  selectedSupplier: SupplierViewModel;
  constructor(
    private receivingStateService: ReceivingStateService,
    private router: Router,
    private receivingService: ReceivingService) { }

  ngOnInit(): void {
    this.filteredSuppliers = this.suppliers;
    this.receivingStateService.getReceivingStateModel()
      .subscribe(stateModel => {
        if (stateModel && stateModel.supplier) {
          this.selectedSupplier = stateModel.supplier as SupplierViewModel;
        }
      })
  }

  filteredSupplier(event) {
    const query = event.query;
    const filtered = this.suppliers.filter(x => x.name.toLowerCase().indexOf(query.toLowerCase()) == 0);
    this.filteredSuppliers = filtered;
  }

  OnContinueClicked() {
    this.material = this.dataAdapter.ToMaterialViewModelFromLot(this.material as MaterialViewModel);
    this.receivingStateService.setReceivingStateModel({ material: this.material, supplier: this.selectedSupplier, address: { addressId: 1 } as AddressViewModel });
    this.router.navigate([receivingConstants.Routes.Validate]);
  }
}
