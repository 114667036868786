import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridApi } from 'ag-grid-community';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { DeleteButtonRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/delete-button-renderer.component';
import { InputCellRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/input-cell-renderer.component';
import { AgGridLotSelectionNumericEditorComponent } from '../../core/modules/ag-grid/components/ag-grid-lot-selection-numeric-editor/ag-grid-lot-selection-numeric-editor.component';

@Component({
  selector: 'app-edit-containers-modal',
  templateUrl: './edit-containers-modal.component.html',
  styleUrls: ['./edit-containers-modal.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class EditContainersModalComponent implements OnInit {

  private gridApi: GridApi;

  public columnDefinitions;

  public rowData = [];

  public frameworkComponents = {
    inputCellRenderer: InputCellRenderer,
    deleteButtonRenderer: DeleteButtonRenderer,
    lotSelectionNumericEditor: AgGridLotSelectionNumericEditorComponent
  }
  targetAmountInKg = 0;
  targetAmountRemaining = 0;

  constructor(public dialogRef: MatDialogRef<EditContainersModalComponent>, @Inject(MAT_DIALOG_DATA) public data, private weightFormatKGPipe: WeightFormatKGPipe) {
    this.setColumnDefs();
    this.createRowData();
    this.calculateTargetAmountRemaining();
  }

  ngOnInit(): void {
  }

  private setColumnDefs() {
    const that = this;
    this.columnDefinitions = [
      {
        headerName: '# of Containers',
        field: 'numberOfContainers',
        cellRenderer: 'lotSelectionNumericEditor',
        width: 300
      },
      {
        headerName: 'Amount Per Container',
        field: 'amountPerContainer',
        cellRenderer: 'inputCellRenderer'
      },
      {
        headerName: 'Total Amount',
        field: 'amountRequested',
        valueGetter: (params) => {
          if (!params) { return; }
          return that.weightFormatKGPipe.transform(params.data.numberOfContainers * params.data.amountPerContainer, 7) + ' ' + that.data.orderItem.unitOfMeasureDesc;
        }
      },
      {
        cellRenderer: 'deleteButtonRenderer'
      },
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    params.api.addEventListener('inputChangeEvent', (e) => {
      this.handleInputChange(e);
    });
    params.api.addEventListener('deleteButtonClicked', (e) => {
      this.handleDeleteClick(e);
    });
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  handleInputChange(e) {
    this.gridApi.refreshCells();
  }

  handleDeleteClick(e) {
    this.rowData.splice(e.value, 1);
    const _rowData = [];
    this.rowData.forEach(r => _rowData.push(r));
    this.rowData = _rowData;
  }

  private calculateTargetAmountRemaining() {
    if (!this.data.containers || this.data.containers.length === 0) { return; }
    this.data.containers.filter(r => !r.isDecanted && !r.assignedLotNumber).forEach(row => {
      this.targetAmountRemaining += row.requestedAmount * row.numberOfContainers;
      this.targetAmountInKg += row.amount * row.numberOfContainers;
    });
    this.targetAmountRemaining = parseFloat(parseFloat(this.targetAmountRemaining.toFixed(7))?.toString());
  }

  private createRowData() {
    if (!this.data.containers || this.data.containers.length === 0) { return; }
    const _rowData = [];
    this.data.containers.forEach(row => {
      if (row.isDecanted || row.assignedLotNumber) { return; }
      _rowData.push({ numberOfContainers: row.numberOfContainers, amountPerContainer: row.requestedAmount, min: 0, max: 10000000 })
    });
    this.rowData = _rowData;
  }

  calculateRunningTotal(): number {
    let visibleTotal = 0;
    this.rowData.forEach(row => visibleTotal += (row.numberOfContainers * row.amountPerContainer));
    return parseFloat(visibleTotal.toFixed(7));
  }

  public addRow() {
    const _rowData = [];
    this.rowData.forEach(row => _rowData.push(row));
    _rowData.push({ numberOfContainers: 0, amountPerContainer: 0 })
    this.rowData = _rowData;
  }

  public save() {
    const filteredRowData = this.rowData.filter(r => r.numberOfContainers !== 0 && r.amountPerContainer !== 0);
    const _cleanedRowData = []
    filteredRowData.forEach(row => {
      const match = _cleanedRowData.find(r => r.amountPerContainer === row.amountPerContainer);
      if (match) {
        match.numberOfContainers += row.numberOfContainers;
      }
      else {
        _cleanedRowData.push(row);
      }
    });
    this.dialogRef.close(_cleanedRowData);
  }

  public formatAmount(amount) {
    if (amount < 1) {
      return amount * 1000;
    }
    return amount
  }

}
