import { T } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, GridOptions, ValueSetterParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { Order, OrderContentsResponse, OrderStatuses } from 'src/app/models/order-models';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { CancelButtonRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/cancel.button.render.component';
import { CheckBoxEditorRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/checkbox.editor.renderer.component';
import { MoveButtonRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/move-button-renderer.component';
import { AgGridNumericEditorComponent } from '../../core/modules/ag-grid/components/ag-grid-numeric-editor/ag-grid-numeric-editor.component';
import { ConfirmMovingOrderModalComponent } from '../confirm-moving-order-modal/confirm-moving-order-modal.component';

@Component({
  selector: 'app-split-request-modal',
  templateUrl: './split-request-modal.component.html',
  styleUrls: ['./split-request-modal.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class SplitRequestModalComponent implements OnInit {
  public tooltipShowDelay;
  public defaultColDef;
  public defaultOrderColDef;

  public spcontext;
  private subscriptions = new Subscription();
  public columnDefs: any;
  public columnDefNewOrders: any;
  frameworkComponents: any;
  public rowData: any[] = [];

  public newOrderrowData: any[] = [];

  private gridApi: GridApi;
  private newordergridApi: GridApi;

  public gridOptions: GridOptions;
  @ViewChild('oldGrid') oldGrid: AgGridAngular;
  @ViewChild('newGrid') newGrid: AgGridAngular;
  orderStatuses: Array<string>;
  orderStatus = { option: 'Indy' };
  order: Order;
  orderId: string;
  constructor(public dialogRef: MatDialogRef<SplitRequestModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private orderService: OrderService,
    private responseHandler: ResponseHandlingService,
    private dialog: MatDialog, private weightFormatKGPipe: WeightFormatKGPipe) {
    this.tooltipShowDelay = 0;
    this.orderStatuses = ['Indy', 'SGS'];
    this.spcontext = {
      thisComponent: this
    }
    this.orderId = this.data?.orderId;

    this.frameworkComponents = {
      assignButtonRenderer: MoveButtonRenderer,
      agGridNumericEditor: AgGridNumericEditorComponent,
      agGridCancelButton: CancelButtonRenderer,
      gridCheckBoxEditorRenderer: CheckBoxEditorRenderer
      // customTooltip: CustomTooltip
    };

    this.defaultColDef = {
      // editable: true,
      // sortable: true,
      // flex: 1,
      // minWidth: 100,
      // filter: true,
      // resizable: true,
      //tooltipComponent: 'customTooltip',
    };
    this.defaultOrderColDef = {
    }
  }


  ngOnInit(): void {
    this.loadOrderData();

  }
  loadOrderData() {
    this.orderService.getOrderDetail(this.orderId).subscribe(data => {
      this.order = data;
      this.setColumnDefs();
      this.setNewOrderColumnDefs();
      this.loadOrderContents();
    })

  }

  loadOrderContents() {
    this.orderService.getOrderContents(this.order.orderID).subscribe((res: any) => {
      let _rowData = [];
      res.forEach((x, index) => {
        if (!x.isDecanted) {
          _rowData.push({
            id: index,
            orderItemID: x.orderItemID,
            requestedContainerLabel: (x.requestedLotNumber ? x.requestedContainerLabel + 'Lot(' + x.requestedLotNumber + ')' : x.requestedContainerLabel),
            requestedLotNumber: x.requestedLotNumber,
            requestedAmountAndMeasure: `${this.weightFormatKGPipe.transform(x.requestedAmount)} ${x.unitOfMeasureDesc}`,
            requestedAmount: x.requestedAmount,
            reservationTypeID: x.reservationTypeID,
            reservationTypeDesc: x.reservationTypeDesc,
            unitOfMeasureID: x.unitOfMeasureID,
            unitOfMeasureDesc: x.unitOfMeasureDesc,
            isDecantedInt: x.isDecantedInt,
            isDecanted: x.isDecanted,
            isAssignedInt: x.isAssignedInt,
            isAssigned: x.isAssigned,
            numberOfContainers: x.numberOfContainers,
            totalnumberOfContainers: x.numberOfContainers,
            allContainers: false,
            isMoveDisable: false,
            moveSome: false,
            numbertoMove: x.numberOfContainers,
            moveAll: false,
            pendingNoOfContainers: x.numberOfContainers
          });
        }
      });
      this.rowData = this.sortData(_rowData);
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
  setColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'Chemical Name',
        cellRenderer: (params) => {
          if (params.data.isAssigned) {
            return '<span style="vertical-align: -webkit-baseline-middle">' + '<mat-icon class="material-icons" style="color: green !important;">check_circle</mat-icon>' + '</span>' + '&nbsp;' + params.data.requestedContainerLabel;
          } else {
            return params.data.requestedContainerLabel;
          }
        },
        width: 200,
        headerTooltip: 'Chemical Name',
        tooltipValueGetter: (params) => {
          if (params.data !== undefined) {
            return params.data.requestedContainerLabel;
          }
        }, sortable: true
      },
      {
        headerName: 'Amount Per Container',
        headerTooltip: 'Amount Per Container',
        field: 'requestedAmountAndMeasure'
      },
      {
        headerName: 'Containers',
        headerTooltip: 'Containers',
        field: 'numberOfContainers',
        width: 140,
      },
      {
        headerName: 'Number To Move',
        headerTooltip: 'Number To Move',
        cellRenderer: 'agGridNumericEditor',
        width: 250,
        field: 'numbertoMove',
        valueSetter: this.moveContinerValue
      },
      {
        headerName: '',
        field: 'isMoveDisable',
        cellRenderer: 'assignButtonRenderer',
      }

    ]

  }

  setNewOrderColumnDefs() {
    this.columnDefNewOrders = [
      {
        headerName: 'Chemical Name',
        cellRenderer: (params) => {
          if (params.data.isAssigned) {
            return '<span style="vertical-align: -webkit-baseline-middle">' + '<mat-icon class="material-icons" style="color: green !important;">check_circle</mat-icon>' + '</span>' + '&nbsp;' + params.data.requestedContainerLabel;
          } else {
            return params.data.requestedContainerLabel;
          }
        },
        headerTooltip: 'Chemical Name',
        field: 'requestedContainerLabel',
        tooltipValueGetter: (params) => {
          if (params.data !== undefined) {
            return params.data.requestedContainerLabel;
          }
        }, sortable: true

      },
      {
        headerName: 'Amount Per Container',
        headerTooltip: 'Amount Per Container',
        field: 'requestedAmountAndMeasure'

      },
      {
        headerName: 'Containers',
        headerTooltip: 'Containers',
        field: 'numbertoMove'

      }, {
        headerName: 'Cancel', field: '',
        cellRenderer: 'agGridCancelButton',
        headerTooltip: 'Cancel',
      }

    ]

  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.addEventListener('moveButtonClicked', (e) => {
      this.moveBtnClick(e);
    });
  }
  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
  neworderonGridReady(params) {
    this.newordergridApi = params.api;
    this.newordergridApi.addEventListener('cancelButtonClicked', (e) => {
      this.cancelBtnClick(e);
    });
    var sort = [{
      colId: 'requestedContainerLabel',
      sort: 'asc'
    }]
    this.newordergridApi.setSortModel(sort);
  }
  neworderresizeGrid(params) {
    this.newordergridApi = params.api;
    this.newordergridApi.sizeColumnsToFit();
  }
  onSelectionChanged(event) {
  }
  moveBtnClick(data: any) {
    let recordInfo = data.value;
    let _newOrderRowData = JSON.parse(JSON.stringify(this.newOrderrowData));
    let _rowData = JSON.parse(JSON.stringify(this.rowData));
    //move some
    if (!recordInfo.moveAll) {
      let noOfmoving = recordInfo.numbertoMove;

      const kk = _rowData.findIndex(x => x.id == recordInfo.id && x.orderItemID == recordInfo.orderItemID && x.requestedAmount == recordInfo.requestedAmount && x.numberOfContainers == recordInfo.numberOfContainers);
      if (kk != -1) {
        _rowData.splice(kk, 1);
        _rowData.push(recordInfo);
      }


      let recordExit = _newOrderRowData.filter(x => x.id == recordInfo.id
        && x.orderItemID == recordInfo.orderItemID
        && x.requestedAmount == recordInfo.requestedAmount
        && x.totalnumberOfContainers == recordInfo.totalnumberOfContainers);
      let newRecordContainer = recordInfo.numbertoMove;
      if (recordExit.length > 0) {

        let neworderList = [];
        _newOrderRowData.forEach(x => {
          if (x.id == recordInfo.id && x.orderItemID == recordInfo.orderItemID && x.requestedAmount == recordInfo.requestedAmount && x.totalnumberOfContainers == recordInfo.totalnumberOfContainers) {
          } else {
            neworderList.push(x);
          }
        })
        setTimeout(() => {
          _newOrderRowData = [];
          _newOrderRowData = neworderList;
        });

        // this.newordergridApi.updateRowData({ remove:[recordExit[0]] });
        // this.newOrderrowData = this.newOrderrowData.filter(x=>x.orderItemID !== recordInfo.orderItemID&& x.requestedAmount !== recordInfo.requestedAmount && x.totalnumberOfContainers !== recordInfo.totalnumberOfContainers);


        recordExit[0].numbertoMove = Number(recordExit[0].numbertoMove) + Number(recordInfo.numbertoMove);

      }
      else {
        this.newordergridApi.updateRowData({
          add: [{
            "id": recordInfo.id,
            "orderItemID": recordInfo.orderItemID,
            "requestedContainerLabel": recordInfo.requestedContainerLabel,
            "requestedLotNumber": recordInfo.requestedLotNumber,
            "requestedAmount": recordInfo.requestedAmount,
            "requestedAmountAndMeasure": recordInfo.requestedAmountAndMeasure,
            "unitOfMeasureID": recordInfo.unitOfMeasureID,
            "unitOfMeasureDesc": recordInfo.unitOfMeasureDesc,
            "isDecantedInt": recordInfo.isDecantedInt,
            "isDecanted": recordInfo.isDecanted,
            "numberOfContainers": recordInfo.numberOfContainers,
            "totalnumberOfContainers": recordInfo.totalnumberOfContainers,
            "allContainers": true,
            "isMoveDisable": false,
            "moveSome": false,
            "numbertoMove": newRecordContainer,
            "moveAll": false,
            "isAssignedInt": recordInfo.isAssignedInt,
            "isAssigned": recordInfo.isAssigned

          }],
          addIndex: 0
        });
        recordInfo.allContainers = true;
        recordInfo.numbertoMove = newRecordContainer;
        //  recordInfo.numberOfContainers = recordInfo.numberOfContainers - recordInfo.numbertoMove;
        _newOrderRowData.push({
          "id": recordInfo.id,
          "orderItemID": recordInfo.orderItemID,
          "requestedContainerLabel": recordInfo.requestedContainerLabel,
          "requestedLotNumber": recordInfo.requestedLotNumber,
          "requestedAmount": recordInfo.requestedAmount,
          "requestedAmountAndMeasure": recordInfo.requestedAmountAndMeasure,
          "reservationTypeID": recordInfo.reservationTypeID,
          "reservationTypeDesc": recordInfo.reservationTypeDesc,
          "unitOfMeasureID": recordInfo.unitOfMeasureID,
          "unitOfMeasureDesc": recordInfo.unitOfMeasureDesc,
          "isDecantedInt": recordInfo.isDecantedInt,
          "isDecanted": recordInfo.isDecanted,
          "numberOfContainers": recordInfo.numberOfContainers,
          "totalnumberOfContainers": recordInfo.totalnumberOfContainers,
          "allContainers": true,
          "isMoveDisable": false,
          "moveSome": false,
          "numbertoMove": newRecordContainer,
          "moveAll": false,
          "pendingNoOfContainers": recordInfo.pendingNoOfContainers,
          "isAssignedInt": recordInfo.isAssignedInt,
          "isAssigned": recordInfo.isAssigned
        });
      }

      let moveRowData = _rowData.filter(x => x.orderItemID == recordInfo.orderItemID
        && x.id == recordInfo.id
        && x.requestedAmount == recordInfo.requestedAmount
        && x.numberOfContainers == recordInfo.numberOfContainers);
      if (moveRowData.length > 0 && moveRowData[0].numberOfContainers <= noOfmoving) {

        const ii = _rowData.findIndex(x => x.id == recordInfo.id && x.orderItemID == recordInfo.orderItemID && x.requestedAmount == recordInfo.requestedAmount && x.numberOfContainers == recordInfo.numberOfContainers);
        if (ii != -1) {
          // this.rowData.splice(ii,1);
          this.gridApi.updateRowData({ remove: [_rowData.splice(ii, 1)[0]] });
        }
      }
      else {
        let index = _rowData.indexOf(recordInfo);
        this.gridApi.forEachNode((rowNode, index) => {
          if (rowNode.data.id === recordInfo.id && rowNode.data.orderItemID === recordInfo.orderItemID && rowNode.data.requestedAmount == recordInfo.requestedAmount && rowNode.data.numberOfContainers == recordInfo.numberOfContainers) {
            const numofContines = recordInfo.numberOfContainers - noOfmoving;
            if (numofContines == 0) {

              const jj = _rowData.findIndex(x => x.id == recordInfo.id && x.orderItemID == recordInfo.orderItemID && x.requestedAmount == recordInfo.requestedAmount && x.numberOfContainers == recordInfo.numberOfContainers);
              if (jj != -1) {
                _rowData.splice(jj, 1);

              }

            } else {
              rowNode.setDataValue('numbertoMove', 0);
              rowNode.setDataValue('numberOfContainers', numofContines);
            }
          }
        });
      }
    }
    if (recordInfo.moveAll) {
      this.newordergridApi.updateRowData({
        add: [{
          "id": recordInfo.id,
          "orderItemID": recordInfo.orderItemID,
          "requestedContainerLabel": recordInfo.requestedContainerLabel,
          "requestedLotNumber": recordInfo.requestedLotNumber,
          "requestedAmount": recordInfo.requestedAmount,
          "requestedAmountAndMeasure": recordInfo.requestedAmountAndMeasure,
          "unitOfMeasureID": recordInfo.unitOfMeasureID,
          "unitOfMeasureDesc": recordInfo.unitOfMeasureDesc,
          "isDecantedInt": recordInfo.isDecantedInt,
          "isDecanted": recordInfo.isDecanted,
          "numberOfContainers": recordInfo.numberOfContainers,
          "allContainers": true,
          "isMoveDisable": false,
          "moveSome": false,
          "numbertoMove": recordInfo.numberOfContainers,
          "moveAll": false,
          "isAssignedInt": recordInfo.isAssignedInt,
          "isAssigned": recordInfo.isAssigned
        }],
        addIndex: 0
      });
      recordInfo.allContainers = true;
      recordInfo.numberOfContainers = recordInfo.numberOfContainers;
      _newOrderRowData.push(recordInfo);
      _rowData = _rowData.filter(x => x.orderItemID != recordInfo.orderItemID);
    }
    this.rowData = this.sortData(_rowData);
    this.newOrderrowData = this.sortData(_newOrderRowData);
  }

  cancelBtnClick(data: any) {
    let recordInfo = data.value;
    let _rowData = JSON.parse(JSON.stringify(this.rowData));
    let _newOrderRowData = JSON.parse(JSON.stringify(this.newOrderrowData));
    let recordExit = _rowData.filter(x => x.id == recordInfo.id && x.orderItemID == recordInfo.orderItemID
      && x.requestedAmount == recordInfo.requestedAmount
      && x.totalnumberOfContainers == recordInfo.totalnumberOfContainers);
    let mainRecordsContainers = recordInfo.numbertoMove;
    if (recordExit.length > 0) {
      let roworderList = [];
      _rowData.forEach(x => {
        if (x.id == recordInfo.id && x.orderItemID == recordInfo.orderItemID && x.requestedAmount == recordInfo.requestedAmount && x.totalnumberOfContainers == recordInfo.totalnumberOfContainers) {
        } else {
          roworderList.push(x);
        }
      })
      _rowData = [];
      _rowData = roworderList;

      mainRecordsContainers = recordExit[0].numberOfContainers + Number(recordInfo.numbertoMove);
    }

    this.gridApi.updateRowData({
      add: [{
        "id": recordInfo.id,
        "orderItemID": recordInfo.orderItemID,
        "requestedContainerLabel": recordInfo.requestedContainerLabel,
        "requestedLotNumber": recordInfo.requestedLotNumber,
        "requestedAmount": recordInfo.requestedAmount,
        "requestedAmountAndMeasure": recordInfo.requestedAmountAndMeasure,
        "unitOfMeasureID": recordInfo.unitOfMeasureID,
        "unitOfMeasureDesc": recordInfo.unitOfMeasureDesc,
        "isDecantedInt": recordInfo.isDecantedInt,
        "isDecanted": recordInfo.isDecanted,
        "numberOfContainers": mainRecordsContainers,
        "totalnumberOfContainers": recordInfo.totalnumberOfContainers,
        "allContainers": false,
        "isMoveDisable": false,
        "moveSome": false,
        "numbertoMove": mainRecordsContainers,
        "moveAll": false,
        "pendingNoOfContainers": mainRecordsContainers,
        "isAssignedInt": recordInfo.isAssignedInt,
        "isAssigned": recordInfo.isAssigned
      }],
      addIndex: 0
    });

    _rowData.push({
      "id": recordInfo.id,
      "orderItemID": recordInfo.orderItemID,
      "requestedContainerLabel": recordInfo.requestedContainerLabel,
      "requestedLotNumber": recordInfo.requestedLotNumber,
      "requestedAmount": recordInfo.requestedAmount,
      "requestedAmountAndMeasure": recordInfo.requestedAmountAndMeasure,
      "reservationTypeID": recordInfo.reservationTypeID,
      "reservationTypeDesc": recordInfo.reservationTypeDesc,
      "unitOfMeasureID": recordInfo.unitOfMeasureID,
      "unitOfMeasureDesc": recordInfo.unitOfMeasureDesc,
      "isDecantedInt": recordInfo.isDecantedInt,
      "isDecanted": recordInfo.isDecanted,
      "numberOfContainers": mainRecordsContainers,
      "totalnumberOfContainers": recordInfo.totalnumberOfContainers,
      "allContainers": false,
      "isMoveDisable": false,
      "moveSome": false,
      "numbertoMove": mainRecordsContainers,
      "moveAll": false,
      "pendingNoOfContainers": mainRecordsContainers,
      "isAssignedInt": recordInfo.isAssignedInt,
      "isAssigned": recordInfo.isAssigned
    });
    let neworderList = [];
    _newOrderRowData.forEach(x => {
      if (x.id == recordInfo.id && x.orderItemID == recordInfo.orderItemID && x.requestedAmount == recordInfo.requestedAmount && x.totalnumberOfContainers == recordInfo.totalnumberOfContainers) {
      } else {
        neworderList.push(x);
      }
    })
    _newOrderRowData = [];
    _newOrderRowData = neworderList;

    this.rowData = this.sortData(_rowData);
    this.newOrderrowData = this.sortData(_newOrderRowData);
  }

  sortData(data): any[] {
    const _data = JSON.parse(JSON.stringify(data));
    const maxValue = Math.max(..._data.map(x => x.requestedAmount));
    const targetLength = maxValue.toString().split('.')[0].length;
    _data.forEach(r => {
      r['concatColumn'] = +r.isAssigned + r.requestedContainerLabel + r.requestedLotNumber + r.unitOfMeasureDesc +
        r.requestedAmount?.toString().split('.')[0].padStart(targetLength, 0) + r.requestedAmount?.toString().split('.')[1]
    })
    _data.sort((a, b) => a.concatColumn > b.concatColumn ? 1 : -1);
    _data.forEach(element => delete element['concatColumn']);
    return _data;
  }

  createOrder() {
    let splitOrderData: any[] = [];
    this.newOrderrowData.forEach(x => {
      splitOrderData.push({
        "orderItemID": x.orderItemID,
        "requestedAmount": x.requestedAmount,
        "allContainers": false,
        "numberOfContainers": x.numbertoMove,
      });
    })
    //this.newOrderrowData
    this.subscriptions.add(this.orderService.createSplitOrder(this.order.orderID, splitOrderData, this.orderStatus.option == "SGS" ? OrderStatuses.SGSRequested : null)
      .subscribe((response: any) => {

        this.responseHandler.showSuccess(`Success: Request split`);
        this.dialogRef.close();
        window.location.reload();
      },
        error => {
          this.responseHandler.showError(error.error?.Title, 5000);
          // this.responseHandler.showError(`'Error: Cannot move all items/containers to a new request - at least one container must remain in the original request.`);
        }));
  }
  requestTransferModal(): void {
    this.dialog.open(ConfirmMovingOrderModalComponent, {
      data: { order: this.order },
      disableClose: true
    }).afterClosed().subscribe((res: string) => {
      if (res == null) { return; }
      this.createOrder();
    });
  }
  movesomevalueRender(params: ValueSetterParams) {
    const theComponent = params.context.thisComponent;
    params.data.pendingNoOfContainers = params.data.numberOfContainers;
    params.data.numbertoMove = params.data.numberOfContainers;
    params.data.isMoveDisable = params.newValue ? true : false;
    params.data.moveSome = params.newValue;
    params.data.moveAll = false;

    return true;
  }
  moveallValueRender(params: ValueSetterParams) {
    const theComponent = params.context.thisComponent;
    params.data.pendingNoOfContainers = params.data.numberOfContainers;
    params.data.numbertoMove = params.data.numberOfContainers;
    params.data.isMoveDisable = params.newValue ? true : false;
    params.data.moveAll = params.newValue;
    params.data.moveSome = false;
    params.data.numberOfContainers = params.data.numberOfContainers;
    return true;
  }
  moveContinerValue(params: ValueSetterParams) {
    const theComponent = params.context.thisComponent;
    params.data.numbertoMove = params.newValue;

    return true;
  }

}
