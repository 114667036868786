import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MessageService } from '../../../shared/services/message-queue.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AddEditRoleComponent } from '../edit-roles-modal/add-edit-role.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-role-action',
  templateUrl: './manage-roles-action.component.html',
  styleUrls: ['./manage-roles-action.component.scss'],
})
export class ManageRoleActionsComponent implements ICellRendererAngularComp {
  private params: any;

  constructor(
    private dialog: MatDialog,
    private readonly messageService: MessageService,
    private router: Router
  ) { }

  onEditRole() {
    this.router.navigate(['roles', this.params.data.roleID]);
  }

  onDeleteRole() {
    const confirmDialog = new ConfirmDialogModel(
      'Confirm Action',
      `This role will be removed for all users. Are you sure?`,
      'OK',
      false,
      true,
      'Cancel'
    );
    this.dialog
      .open(ConfirmDialogComponent, { maxWidth: '400px', data: confirmDialog })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({ data: 'delete', roleID: this.params.data.roleID, reference: this.params.data });
        }
      });
  }

  canBeDeleted(): boolean {
    return !this.params.data.isLocked;
  }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
