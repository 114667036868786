import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-destination-guide-info-modal',
  templateUrl: './destination-guide-info-modal.component.html',
  styleUrls: ['./destination-guide-info-modal.component.scss']
})
export class DestinationGuideInfoModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DestinationGuideInfoModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
