import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { DecantStation } from '../../models/decant-models';
import { Scale } from '../../models/decant-models';
import { Configuration } from '../../../app/configuration';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';

@Injectable()
export class StationService {
  
    readonly rootURL = Configuration.REST_API_URL;

    constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
    }

    retrieveStation(stationId: string): Observable<DecantStation> {
        var URL = this.rootURL + `/Stations/${stationId}`
        return this.http.get<DecantStation>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    retrieveScale(stationId: string, scaleId: string): Observable<Scale> {
        var URL = this.rootURL + `/Stations/${stationId}`
        return this.http.get<any>(URL)
        .pipe(
            map(station => station.scales.filter(scales=> scales.scaleID === scaleId)[0]),
            catchError(err => this.responseHandler.handleError(err))
        );
    }
    
    getStations(): Observable<DecantStation[]> {
        var URL = this.rootURL + `/Stations`;
        return this.http.get<DecantStation[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }
}