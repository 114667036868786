import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ShipperService } from 'src/app/services/shippers-service/shipper.service';
import { confirmSecretValidator} from '../../../core/validators/confirm-secret.validator'
@Component({
  selector: 'app-edit-shipper',
  templateUrl: './edit-shipper.component.html',
  styleUrls: ['./edit-shipper.component.scss'],
})
export class EditShipperComponent implements OnInit {
  public isValid;
  public shipperID: number;
  public submitted: boolean;
  public buttonLabel: string;
  public shipperForm: FormGroup;
  public hasFormChanged: boolean;
  public secretsMatching: boolean;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly shipperService: ShipperService,
    private confirmationService: ConfirmationService
  ) {
    this.submitted = false;
    this.buttonLabel = 'Edit';
    this.hasFormChanged = false;
    this.secretsMatching = true;
    this.shipperID = this.activatedRoute.snapshot.params.id || 0;
  }

  ngOnInit(): void {
    this.buildForm();
    this.initData();
  }

  initData() {
    if (!this.shipperID) {
      var data = {
        shipperID: 0,
        shipperName: null,
        trackingURLFormat: '',
        sortOrder: null,
      };

      this.patchForm(data);
      this.buttonLabel = 'Add New';

      this.shipperForm.valueChanges.subscribe((data) => {
        this.hasFormChanged = true;
      });
    } else {
      this.shipperService
        .getShipper(this.shipperID)
        .pipe(take(1))
        .subscribe((shipper) => {
          this.patchForm(shipper);

          this.shipperForm.valueChanges.subscribe((data) => {
            this.hasFormChanged = true;
          });
        });
    }
  }

  onSubmit() {
    this.submitted = true;
    this.isValid = this.shipperForm.valid && this.submitted;

    if (this.shipperForm.valid) {
      var shipper = this.shipperForm.value;
      delete shipper['clientSecretConfirm'];
      this.shipperService
        .editShippers({
          ...shipper,
          trackingURLFormat: this.shipperForm.controls[
            'trackingURLFormat'
          ].value
            .replace(/\'/g, '')
            .replace(/\"/g, '')
            .replace(/\</g, '')
            .replace(/\>/g, '')
            .replace(/<\/?[^>]+(>|$)/g, '')
            .replace(/\s/g, ''),
        })
        .pipe(take(1))
        .subscribe(() => {
          this.router.navigate(['manageShippers']);
        });
    } else {
      this.shipperForm.markAllAsTouched();
      this.shipperForm.updateValueAndValidity();
    }
  }

  onCancel() {
    if (this.hasFormChanged) {
      this.confirmationService.confirm({
        message: 'You have unsaved changes. Are you sure you wish to leave?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.router.navigate(['manageShippers']);
        },
      });
    } else {
      this.router.navigate(['manageShippers']);
    }
  }

  private patchForm(data: any) {
    this.shipperForm.patchValue({
      shipperID: data.shipperID,
      shipperName: data.shipperName,
      trackingURLFormat: data.trackingURLFormat,
      baseAPIURL: data.baseAPIURL,
      clientID: data.clientID,
      clientSecret: data.clientSecret,
      clientSecretConfirm: data.clientSecret,
      frequencyInHours: data.frequencyInHours,
      maximumRequestCount: data.maximumRequestCount,
      sortOrder: data.sortOrder,
    });
  }

  private buildForm() {
    this.shipperForm = new FormGroup(
      {
        shipperID: new FormControl(null),
        shipperName: new FormControl(null, [Validators.required]),
        baseAPIURL: new FormControl(null),
        trackingURLFormat: new FormControl(''),
        clientID: new FormControl(null),
        clientSecret: new FormControl(null),
        clientSecretConfirm: new FormControl(null),
        frequencyInHours: new FormControl(null, [
          Validators.min(1),
          Validators.max(24),
        ]),
        maximumRequestCount: new FormControl(null, [Validators.min(1)]),
        sortOrder: new FormControl(null, [
          Validators.required,
          Validators.min(1),
        ]),
      },
      { validators: confirmSecretValidator }
    );
  }
}