import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpStatusCode } from '../../models/response-models';


import { Role, RoleUserList, UserRole } from '../../models/security-models';

import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { Configuration } from 'src/app/configuration';


@Injectable()
export class RoleService {
    readonly rootURL = Configuration.REST_API_URL;

    constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
    }

    getAvailableRoles(): Observable<Role[]> {
        var URL = this.rootURL + '/Roles';
        return this.http.get<Role[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getUserRoleList(): Observable<Response> {
        var URL = this.rootURL + '/Roles/RetrieveRoleUserList';
        return this.http.get<Response>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    addUserToRole(userrole: UserRole): Observable<any> {
        const headers = { 'content-type': 'application/json' }
        const body = JSON.stringify({ 'adUserID': userrole.adUserID });
        var URL = this.rootURL + `/Roles/${userrole.roleID}/Users`;
        const errorCodeMapping: Map<HttpStatusCode, string> = new Map([[HttpStatusCode.CONFLICT, "User already has this role. Refresh page."]]);
        return this.http.post<UserRole>(URL, body, { 'headers': headers }).pipe(catchError(err => this.responseHandler.handleError(err, errorCodeMapping)));
    }

    deleteUserFromRole(userID: number, roleID: number): Observable<Response> {
        const headers = { 'content-type': 'application/json' }
        var URL = this.rootURL + `/Roles/${roleID}/Users/${userID}`;
        const errorCodeMapping: Map<HttpStatusCode, string> = new Map([[HttpStatusCode.NOT_FOUND, "User is not currently in that role. Refresh page."]]);
        return this.http.delete<Response>(URL, { 'headers': headers }).pipe(catchError(err => this.responseHandler.handleError(err, errorCodeMapping)));
    }

    getUsersByRole(roleName: string): Observable<RoleUserList> {
        var URL = this.rootURL + `/Roles/${roleName}/Users`;
        return this.http.get<RoleUserList>(URL).pipe(catchError(err => this.responseHandler.handleError(err)))
    }
}