import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-inventory-storage-location-change-modal',
  templateUrl: './inventory-storage-location-change-modal.component.html',
  styleUrls: ['./inventory-storage-location-change-modal.component.scss']
})
export class InventoryStorageLocationChangeModalComponent implements OnInit {

  inventoryLocationForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<InventoryStorageLocationChangeModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.buildForm(); 
  }

  onClose(): void {
    this.dialogRef.close({
      saveChanges: false,
    });
  }

  onSubmit(){
    this.dialogRef.close({
      saveChanges: true,
      data: this.inventoryLocationForm.value,
      locationLabel: this.data.locations.filter(l=>l.locationID == this.inventoryLocationForm.controls['locations'].value)?.[0].fullLocationName
    });
  }

  private buildForm() {

    this.inventoryLocationForm = new FormGroup({
      printers: new FormControl(null, Validators.required),
      locations: new FormControl(null, Validators.required)
    });

    this.inventoryLocationForm.patchValue({
      locations: this.data.selectedLocation,
    });
  }

}
