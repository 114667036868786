<div class="flex-grid">
    <div class="col">
        <h4>Manage Destination Guides</h4>
    </div>
</div>

<form [formGroup]="destinationGuideForm" (ngSubmit)="onSubmit(destinationGuideForm)">
    <div class="float-right">
        <button mat-raised-button color="cor-default" (click)="resetForm()" type="button" color="cor-primary"
            [disabled]="!destinationGuideForm.valid || this.destinationGuideForm.pristine"
            style="margin-right: 20px;">Cancel</button>
        <button mat-raised-button color="cor-default" type="submit" color="cor-primary"
            [disabled]="!destinationGuideForm.valid || this.destinationGuideForm.pristine">Save</button>
    </div>

    <div class="p-grid p-mt-3 p-mb-4">
        <div class="p-col-12">
            <div class="p-col-4" style="width: 26%;">
                <p-dropdown placeholder="Select Country" defaultLabel="Select Country" formControlName="countryID"
                    [filter]="true" [style]="{'width':'92%'}"
                    [options]="destinationGuideModel?.countries | sortBy:'asc': 'countryName'" optionLabel="countryName"
                    optionValue="countryID"
                    [disabled]="destinationGuideForm.valid && !this.destinationGuideForm.pristine">
                </p-dropdown>
                <div *ngIf="destinationGuideForm.controls['countryID'].invalid &&  destinationGuideForm.controls['countryID'].touched"
                    class="alert">
                    <div class="is-valid" *ngIf="destinationGuideForm.controls['countryID'].errors.required">
                        Field is required
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-lg-3">
            <div class="p-grid">
                <div class="p-col-4">
                    <label for="Status">Region: </label>
                </div>
                <div class="p-col">
                    <input class="invalid " type="text" pInputText formControlName="regionName" [readonly]="true">
                </div>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-4">
            <div class="p-grid">
                <div class="p-col-4">
                    <label for="Status">Export Tier: </label>
                </div>
                <div class="p-col">
                    <p-dropdown placeholder="Select Tier" defaultLabel="Select Tier"
                        formControlName="exportTierOptionID" [style]="{'width':'92%'}"
                        [options]="destinationGuideModel?.exportTiers" optionLabel="name" optionValue="optionID">
                    </p-dropdown>
                </div>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-4">
            <div class="p-grid">
                <div class="p-col-5">
                    <label for="Status">Single Use Plastic Tax: </label>
                </div>
                <div class="p-col">
                    <p-dropdown placeholder="Select Tax Option" defaultLabel="Select Tax Option"
                        formControlName="singleUsePlasticTaxOptionID" [style]="{'width':'92%'}"
                        [options]="destinationGuideModel?.singleUsePlasticTaxOptions" optionLabel="name"
                        optionValue="optionID">
                    </p-dropdown>
                </div>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-4"></div>

        <div class="p-col-12 p-md-6 p-lg-3">
            <div class="p-grid">
                <div class="p-col-4">
                    <label for="countries">Language: </label>
                </div>

                <div class="p-col">
                    <p-dropdown placeholder="Select Language" defaultLabel="Select Language" [style]="{'width':'92%'}"
                        formControlName="languageOptionID" [options]="destinationGuideModel?.languages"
                        optionLabel="name" optionValue="optionID">
                    </p-dropdown>
                    <div *ngIf="destinationGuideForm.controls['languageOptionID'].invalid &&  destinationGuideForm.controls['languageOptionID'].touched"
                        class="alert">
                        <div class="is-valid"
                            *ngIf="destinationGuideForm.controls['languageOptionID'].errors.required || destinationGuideForm.controls['languageOptionID'].errors.min">
                            Field is required
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-4">
            <div class="p-grid">
                <div class="p-col-4">
                    <label for="countries">Second Language: </label>
                </div>

                <div class="p-col">
                    <p-dropdown placeholder="Select Language" defaultLabel="Select Language" [style]="{'width':'92%'}"
                        formControlName="secondaryLanguageOptionID" [options]="destinationGuideModel?.languages"
                        optionLabel="name" optionValue="optionID">
                    </p-dropdown>
                    <div *ngIf="destinationGuideForm.controls['secondaryLanguageOptionID'].invalid &&  destinationGuideForm.controls['secondaryLanguageOptionID'].touched"
                        class="alert">
                        <div class="is-valid"
                            *ngIf="destinationGuideForm.controls['secondaryLanguageOptionID'].errors.required || destinationGuideForm.controls['secondaryLanguageOptionID'].errors.min">
                            Field is required
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-4">
            <div class="p-grid">
                <div class="p-col-4">
                    <label for="countries">Incoterms: </label>
                </div>

                <div class="p-col">
                    <p-dropdown placeholder="Select Incoterms" defaultLabel="Select Incoterms" [style]="{'width':'92%'}"
                        formControlName="incotermsOptionID" [options]="destinationGuideModel?.incoterms"
                        optionLabel="name" optionValue="optionID">
                    </p-dropdown>
                </div>

            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-3">
            <div class="p-grid">
                <div class="p-col-4">
                    <label for="Status">Label Info: </label>
                </div>
                <div class="p-col">
                    <p-multiSelect placeholder="Select Label Info" defaultLabel="Select Label Info"
                        formControlName="labelInfoOptions" [style]="{'width':'92%'}"
                        [options]="destinationGuideModel?.labelInfoOptions" optionLabel="name" optionValue="optionID">
                    </p-multiSelect>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-lg-4">
            <div class="p-grid">
                <div class="p-col-4">
                    <label for="requestor">Required Document: </label>
                </div>
                <div class="p-col">
                    <p-dropdown placeholder="Select Required Document" defaultLabel="Select Required Document"
                        [style]="{'width':'92%'}" formControlName="requiredDocumentOptionID"
                        [options]="destinationGuideModel?.requiredDocuments" optionLabel="name" optionValue="optionID">
                    </p-dropdown>
                    <div *ngIf="destinationGuideForm.controls['requiredDocumentOptionID'].invalid && destinationGuideForm.controls['requiredDocumentOptionID'].touched"
                        class="alert">
                        <div class="is-valid"
                            *ngIf="destinationGuideForm.controls['requiredDocumentOptionID'].errors.required || destinationGuideForm.controls['requiredDocumentOptionID'].errors.min">
                            Field is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-lg-4">
            <div class="p-grid">
                <div class="p-col-4">
                    <label for="requestor">Carrier: </label>
                </div>
                <div class="p-col">
                    <p-orderList (onReorder)="onReorder()" [value]="selectedShippers" [listStyle]="{ height: 'auto' }"
                        dragdrop="true">
                        <ng-template let-shipper pTemplate="item" style="max-height: 38px">
                            <div class="product-item">
                                <div class="product-list-detail">
                                    <input style="margin-right: 15px;" type="checkbox" [(ngModel)]="shipper.isSelected"
                                        [ngModelOptions]="{standalone: true}" (change)="onSelectionChange()" />
                                    <span class="mb-2">{{ shipper.shipperName }}</span>
                                </div>
                                <div class="product-list-action">
                                </div>
                                <div></div>
                            </div>
                        </ng-template>
                    </p-orderList>

                </div>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-3">
            <div class="p-grid">
                <div class="p-col-4">
                    <label for="Status">SDS Info: </label>
                </div>
                <div class="p-col">
                    <p-multiSelect placeholder="Select SDS Info" defaultLabel="Select SDS Info"
                        formControlName="sdsInfoOptions" [style]="{'width':'92%'}"
                        [options]="destinationGuideModel?.sdsInfoOptions" optionLabel="name" optionValue="optionID">
                    </p-multiSelect>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-lg-4">
            <div class="p-grid">
                <div class="p-col-4">
                    <label for="requestor">Special Documents: </label>
                </div>
                <div class="p-col-8">
                    <p-multiSelect placeholder="Select Special Documents" defaultLabel="Select Special Documents"
                        formControlName="specialDocuments" [style]="{'width':'92%'}"
                        [options]="destinationGuideModel?.specialDocuments" optionLabel="name" optionValue="optionID">
                    </p-multiSelect>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-lg-4">
            <div class="p-grid">
                <div class="p-col-4">
                    <label for="requestor">Green Light: </label>
                </div>
                <div class="p-col">
                    <p-dropdown placeholder="Select Green Light" defaultLabel="Select Green Light"
                        formControlName="greenLightOptionID" [style]="{'width':'92%'}"
                        [options]="destinationGuideModel?.greenLightOptions" optionLabel="name" optionValue="optionID">
                    </p-dropdown>
                </div>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-12">
            <div class="p-grid">
                <div class="p-col-12">
                    <label for="requestor">Country Specific Labels: </label>
                </div>
                <textarea class="form-control" id="countrySpecificLabels" rows="4"
                    formControlName="countrySpecificLabeling"></textarea>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-12">
                    <label for="requestor">Broker: </label>
                </div>
                <textarea class="form-control" id="broker" rows="4" formControlName="broker"></textarea>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-12">
                    <label for="requestor">Alternate Broker: </label>
                </div>
                <textarea class="form-control" id="alternateBroker" rows="4"
                    formControlName="alternateBroker"></textarea>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-12">
                    <label for="requestor">Importer: </label>
                </div>
                <textarea class="form-control" id="importer" rows="4" formControlName="importer"></textarea>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-12">
                    <label for="requestor">Shipment Approvals: </label>
                </div>
                <textarea class="form-control" id="shipmentApprovals" rows="4"
                    formControlName="shipmentApprovals"></textarea>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-12">
                    <label for="requestor">Contact: </label>
                </div>
                <textarea class="form-control" id="contact" rows="4" formControlName="contact"></textarea>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-12">
                    <label for="requestor">Notes: </label>
                </div>
                <textarea class="form-control" id="notes" rows="4" formControlName="notes"></textarea>
            </div>
        </div>

    </div>
    <div class="float-right">
        <button mat-raised-button color="cor-default" (click)="resetForm()" type="button" color="cor-primary"
            [disabled]="!destinationGuideForm.valid || this.destinationGuideForm.pristine"
            style="margin-right: 20px;">Cancel</button>
        <button mat-raised-button color="cor-default" type="submit" color="cor-primary"
            [disabled]="!destinationGuideForm.valid || this.destinationGuideForm.pristine">Save</button>
    </div>
</form>