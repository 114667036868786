import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/base-component';
import { sortOrdersByUpdatedDate } from 'src/app/helpers/filter-helpers';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { OrderItemModel } from 'src/app/models/order-item-model';
import { Order, OrderListRequest } from 'src/app/models/order-models';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';

interface OrderPickingDisplay {
  orderID: string;
  isOnHold: boolean;
  requestNumber: string;
  containers: Array<RowPickingDisplay>;
}

interface RowPickingDisplay {
  containerLabelFmt: string;
  assignedLotNumber: string;
  totalAmountRequested: number;
  totalAmountRequestedDisplay: string;
  unitOfMeasureDesc: string;
}

@Component({
  selector: 'app-picking',
  templateUrl: './picking.component.html',
  styleUrls: ['./picking.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class PickingComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('picksheet') private picksheetRef: ElementRef;

  private subscriptions = new Subscription();
  public displayOrders: OrderPickingDisplay[] = [];
  public columnDefs: any;

  public pickingOrders: Order[];
  public pickingOrdersLoaded: boolean;
  public picksheetInput: string;

  constructor(private router: Router, private responseHandler: ResponseHandlingService,
    private orderService: OrderService, private weightFormatKGPipe: WeightFormatKGPipe) {
    super();
    this.fetchOrders();
  }

  ngOnInit(): void {
  }

  fetchOrders() {
    const pickingOrdersRequest: OrderListRequest = { includeItems: true, orderStatusID: 5 }; // Picking
    this.subscriptions.add(this.orderService
      .retrieveList(pickingOrdersRequest).subscribe(
        data => {
          const sortedOrders = sortOrdersByUpdatedDate(data);
          this.pickingOrders = sortedOrders.reverse();
          this.createRowData(sortedOrders);
          this.pickingOrdersLoaded = true;
        }
      ));
  }

  private sumItemAmounts(item: OrderItemModel): number {
    let sum = 0;
    if (item && item.containers) {
      item.containers.forEach(container => {
        sum += container.requestedAmount;
      })
    }
    return sum;
  }

  createRowData(orders: Order[]) {
    const _data: OrderPickingDisplay[] = [];
    orders.forEach(order => {
      _data.push({
        orderID: order.orderID,
        isOnHold: order.isOnHold,
        requestNumber: order.requestNumber,
        containers: []
      });
      if (order.items.length) {
        order.items.forEach(item => {
          if (item.containers && item.containers.length > 0) {
            item.containers.forEach(container => {
              const currentOrder = _data.find(o => o.orderID === order.orderID);
              const match = currentOrder.containers.find(r => r.containerLabelFmt === item.containerLabelFmt && r.assignedLotNumber === container.assignedLotNumber);
              if (match) {
                match.totalAmountRequested += container.requestedAmount;
              }
              else {
                currentOrder.containers.push({
                  containerLabelFmt: item.containerLabelFmt,
                  totalAmountRequested: container.requestedAmount,
                  assignedLotNumber: container.assignedLotNumber,
                  unitOfMeasureDesc: item.unitOfMeasureDesc,
                  totalAmountRequestedDisplay: ''
                })
              }
            });
          }
        });
      }
    });
    _data.forEach(r => r.containers.forEach(c => c.totalAmountRequestedDisplay = `${this.weightFormatKGPipe.transform(c.totalAmountRequested)} ${c.unitOfMeasureDesc}`));
    this.displayOrders = _data;
  }

  onPicksheetInput(event) {
    const input = event.target.value.toLowerCase();
    if (input && input.length === 36) {
      if (this.verifyGuid(input) && this.isValidOrderID(input)) {
        this.moveOrderToDecant(input);
      } else {
        this.responseHandler.showError("Request ID Invalid");
        this.picksheetInput = "";
      }
    }
    else {
      if (this.picksheetInput && this.picksheetInput.length > 0) {
        this.responseHandler.showError("Request ID Invalid");
      }
      this.picksheetInput = "";
    }
  }

  moveOrderToDecant(orderID: string) {
    this.orderService.updateOrderStatus(orderID, 6).subscribe(response => {
      this.responseHandler.showSuccess('Updated the status to decant');
      this.picksheetRef.nativeElement.focus();
      this.picksheetInput = "";
      this.fetchOrders();
    })
  }

  sumOrderContainers(order: Order) {
    let sum = 0;
    order.items.forEach(item => {
      sum += item.containers.length
    });
    return sum;
  }

  isValidOrderID(orderID: string) {
    var orderMatch = this.pickingOrders.find(order => order.orderID === orderID);
    return orderMatch && orderMatch.orderID === orderID;
  }

  clearField(event) {
    if (event.target.id === "picksheetField") {
      this.picksheetInput = "";
    }
  }

  handleCellClicked(event) {
    if (event.column.colDef.headerName === "Request ID" && event.data.requestID) {
      let order = this.pickingOrders.filter(o => o.requestNumber === event.data.requestID);
      if (order.length > 0) {
        this.router.navigate([`picking/request/${order[0].orderID}`]);
      }
    }
  }

  verifyGuid(input: string): boolean {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(input);
  }

  ngAfterViewInit(): void {
    this.picksheetRef.nativeElement.focus();
  }
}

