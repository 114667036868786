import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-shipper-modal',
  templateUrl: './edit-shipper-modal.component.html',
  styleUrls: ['./edit-shipper-modal.component.scss']
})
export class EditShipperModalComponent implements OnInit {
  public shipperForm: FormGroup;
  public buttonLabel: string;

  constructor( public dialogRef: MatDialogRef<EditShipperModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.buttonLabel = "Edit";
  }

  ngOnInit(): void {
    this.buildForm();
    this.initData();
  }

  initData() {
    if (this.data == null) {
      var data = {
        shipperID: 0,
        shipperName: null,
        trackingURLFormat: "",
        sortOrder: null,
      };

      this.data = data;
      this.buttonLabel = "Add New";
    }

    this.patchForm();
  }

  onSubmit() {
    this.dialogRef.close(this.shipperForm.value);
  }

  private patchForm() {
    this.shipperForm.patchValue({
      shipperID: this.data.shipperID,
      shipperName: this.data.shipperName,
      trackingURLFormat: this.data.trackingURLFormat,
      sortOrder: this.data.sortOrder,
    });
  }

  private buildForm() {
    this.shipperForm = new FormGroup({
      shipperID: new FormControl(null),
      shipperName: new FormControl(null, [Validators.required]),
      trackingURLFormat: new FormControl(""),
      sortOrder: new FormControl(null, [Validators.required, Validators.min(1)]),
    });
  }
}
