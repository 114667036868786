<h4>
    {{title}}
</h4>

<p class="mat-body">{{message}}</p>

<div mat-dialog-actions style="float: right;">
    <button *ngIf="showCancel" mat-raised-button color="cor-default" (click)="onDismiss()"
        style="float: right;" id="btn-confirm-dialog-cancel">{{cancelButtonLabel}}</button>
    <button id="btn-confirm-danger" *ngIf="useDangerConfirm" mat-raised-button color="cor-danger" (click)="onConfirm()"
        style="float: right;" id="btn-confirm-dialog-confirm">{{confirmButtonLabel}}</button>
    <button  id="btn-confirm" *ngIf="!useDangerConfirm" mat-raised-button color="cor-primary" (click)="onConfirm()"
        style="float: right;">{{confirmButtonLabel}}</button>
</div>