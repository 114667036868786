import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { SelectItem } from 'primeng/api';

import { Role, User, AdUser } from '../../models/security-models';
import { RoleService } from '../../services/role-service/role-service.service';
import { UserService } from '../../services/user-service/user-service.service';
import { RoleGridComponent } from '../role-grid/role-grid.component'
import { MatDialog } from '@angular/material/dialog';
import { UserRoleModalComponent } from '../user-role-modal/user-role-modal.component';
import { PermissionService } from 'src/app/services/permission-service/permission-service';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/base-component';

@Component({
  selector: 'app-security-roles',
  templateUrl: './security-roles.component.html',
  styleUrls: ['./security-roles.component.scss']
})
export class SecurityRolesComponent extends BaseComponent implements OnInit {
  @ViewChild(RoleGridComponent) child: RoleGridComponent;
  roles: Role[];
  roleSelectList: SelectItem[];
  selectedRole: Role;
  users: AdUser[];
  userRoleList: any;
  rowData: User[];

  private subscriptions = new Subscription();

  constructor(private roleService: RoleService, private router: Router, private permissionService: PermissionService, private dialog: MatDialog, private userService: UserService) {
    super();
    this.selectedRole = { roleName: 'DefaultUser', roleID: 2, isLocked: null, isDefault: null, description: "", created: "", updated: "", deleted: "" };

    this.getAvailableRoles();
    this.getUserRoleList();
    this.getADGroupUsers();
  }

  ngOnInit(): void {
  }
  getAvailableRoles() {
    this.subscriptions.add(this.roleService.getAvailableRoles().subscribe(
      data => {
        this.roles = data;
        this.roleSelectList = this.createRoleList(this.roles);
      }
    ));
  }
  getUserRoleList() {
    this.subscriptions.add(this.roleService.getUserRoleList().subscribe(
      data => {
        this.userRoleList = data;
        this.updateRowDataForRole();
      }
    ));
  }

  getADGroupUsers() {
    this.subscriptions.add(this.userService.getADGroupUsers().subscribe(
      data => {
        this.users = data;
      }));
  }

  createRoleList(roles: Role[]): SelectItem[] {
    var selectList: SelectItem[] = [];
    for (const role of roles) {
      selectList.push({ label: role.roleName, value: role });
    }
    this.selectedRole = selectList[0].value;
    return selectList;
  };

  roleSelectChange() {
    this.getUserRoleList();
  }

  updateRowDataForRole() {
    let currentRole: any;
    this.userRoleList.map(role => {
      if (role.roleID === this.selectedRole.roleID) {
        currentRole = role;
        return true;
      }
    });
    if (currentRole) this.rowData = currentRole.users;
    else this.rowData = [];
  }

  addUserToRole() {
    const dialogRef = this.dialog.open(UserRoleModalComponent, {
      panelClass: 'userRoleDialog',
      data: {
        users: this.users,
        userRoleList: this.userRoleList
      }
    });
    this.subscriptions.add(dialogRef.componentInstance.userAdded.subscribe((result) => {
      this.handleUserAdded(result);
      this.refreshPermissions();
    }));
  }

  handleUserAdded(event) {
    this.getUserRoleList();
  }

  handleUserRemoved(event) {
    this.userRoleList = this.userRoleList.map(role => {
      if (role.roleID === event.roleID) {
        role.users = role.users.filter(function (user) {
          return user.userID !== event.userID;
        })
        return role;
      } else {
        return role;
      }
    });
    this.refreshPermissions();
  }

  refreshPermissions() {
    this.subscriptions.add(
      this.userService.login().subscribe(
        data => {
          localStorage.setItem("userPermissions", JSON.stringify(data));
          this.permissionService.updateUserPermissions();
        }
      )
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
