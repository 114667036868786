import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditPropertyModalData } from '../models/order-models';

@Component({
  selector: 'app-edit-property-modal',
  templateUrl: './edit-property-modal.component.html',
  styleUrls: ['./edit-property-modal.component.scss']
})
export class EditPropertyModalComponent implements OnInit {

  newValue = '';

  constructor(public dialogRef: MatDialogRef<EditPropertyModalComponent>, @Inject(MAT_DIALOG_DATA) public data: EditPropertyModalData) { }

  ngOnInit(): void {
  }

  onDialogClose() {
    this.dialogRef.close(this.newValue.trim());
  }

}
