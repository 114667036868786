import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RowNode } from 'ag-grid-community';

@Component({
  selector: 'app-file-name-renderer',
  templateUrl: './file-name-renderer.component.html',
  styleUrls: ['./file-name-renderer.component.scss']
})
export class FileNameRendererComponent implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;

  oldValue: number;
  nodes: RowNode[] = [];
  isLastRow: boolean = false;
  canDeleteOrDownload: boolean = false;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
    this.oldValue = params.data[params.colDef.field];

    this.gridApi.forEachNode(node => this.nodes.push(node));
    if (this.nodes && this.nodes.length
      && this.nodes[this.nodes.length - 1].rowIndex === this.params.rowIndex)
      this.isLastRow = true;
    else
      this.isLastRow = false;

    if (this.params.data.fileContents)
      this.canDeleteOrDownload = true;
  }

  onDelete() {
    this.gridApi.dispatchEvent({ type: 'deleteEvent', value: this.params });
  }

  onAdd() {
    this.gridApi.dispatchEvent({ type: 'addEvent', value: this.params });
  }

  refresh(): boolean {
    return false;
  }
}

