import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { PermissionService } from 'src/app/services/permission-service/permission-service';

@Component({
  selector: 'app-hide-show',
  templateUrl: './hide-show.component.html',
  styleUrls: ['./hide-show.component.scss']
})
export class HideShowComponent implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;

  value: string = 'Share';
  stateOptions: any[] = [{ label: 'Share', value: 'Share' }, { label: 'Hide', value: 'Hide' }];


  constructor(private permissionService: PermissionService) { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
  }

  onShare() {
    this.gridApi.dispatchEvent({ type: 'shareEvent', value: this.params?.data });
  }

  onHide() {
    this.gridApi.dispatchEvent({ type: 'hideEvent', value: this.params?.data });
  }

  refresh(): boolean {
    return false;
  }

  showHideShareButtons() {
    return this.params?.data.allowShareHide;
  }

  enableShareButton() {
    return !this.permissionService.canShareDocument;
  }

  enableHideButton() {
    return !this.permissionService.canHideDocument;
  }
}
