import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input, OnChanges, Optional, SimpleChanges } from '@angular/core';
import { FormControl, NgControl, NgModel } from '@angular/forms';

@Directive({
  selector: '[InputMask]'
})
export class InputMaskDirective implements OnChanges {

  @HostListener("keydown", ["$event"]) onKeyDown(keyboardEvent: any) {
    if (String.fromCharCode(keyboardEvent.keyCode).match(/(\w|\s)/g)) {
      //pressed key is a char
      if (
        keyboardEvent.target.value.length >= this.mask.length &&
        keyboardEvent.shiftKey === false &&
        keyboardEvent.ctrlKey === false &&
        keyboardEvent.altKey === false &&
        keyboardEvent.key !== "Tab") {
        keyboardEvent.preventDefault();
        return;
      }
    } else {
      //pressed key is a non-char
      //e.g. 'esc', 'backspace', 'up arrow'
    }
  }

  @HostListener("keyup", ["$event"]) onKeyUp(keyboardEvent: any) {
    const formattedValue = this.updateMask(this.inputControl.value, this.mask);
    this.inputControl.value = formattedValue;
  }

  @Input('mask') mask: string = '';

  private inputControl: HTMLInputElement;

  constructor(private el: ElementRef) {
    this.setup();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setup();
  }

  private setup() {
    if (!this.inputControl) {
      setTimeout(() => {
        this.inputControl = this.findInputControl(this.el);
      }, 1000);
    }
  }

  private findInputControl(el: ElementRef): HTMLInputElement {
    if (el.nativeElement instanceof HTMLInputElement && (el.nativeElement.type === 'text' || el.nativeElement.type === 'number' || el.nativeElement.type === 'email' || el.nativeElement.type === 'phone')) {
      return el.nativeElement as HTMLInputElement;
    } else {
      const inputControl = (el.nativeElement as HTMLInputElement).querySelector('input[type="text"]');
      if (!inputControl) {
        throw new Error('Cannot find any input control. Use this directive either on a Input[text] or parent element container Input[text]');
      }
      else {
        return inputControl as HTMLInputElement;
      }
    }
  }

  private updateMask(value: string, format: string): string {
    let valueToUpdate: string = '';
    //If not format is defined then return the actual value;
    if (format.length == 0) {
      return value;
    }

    const loop: boolean = true;
    let formatIndex: number = 0;
    let valueIndex: number = 0;
    while (loop) {
      const formatChar = format[formatIndex];
      const inputChar = value[valueIndex];
      if (!inputChar || !formatChar) {
        break;
      }

      if (!isNaN(parseInt(formatChar)) && !isNaN(parseInt(inputChar))) {
        valueToUpdate += inputChar;
      } else if (formatChar === inputChar && formatIndex === valueIndex) {
        valueToUpdate += inputChar;
      } else if (isNaN(parseInt(formatChar))) {
        valueToUpdate += formatChar;
        if (isNaN(parseInt(format[formatIndex + 1])) && format[formatIndex + 1]) {
          formatIndex++;
          continue;
        }

        if (!isNaN(parseInt(inputChar))) {
          valueToUpdate += inputChar;
        }
      }

      formatIndex++;
      valueIndex++;
    }
    return valueToUpdate;
  }
}
