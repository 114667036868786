<mat-expansion-panel class="p-mt-1" (opened)='onOpen()'>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <span class="p-p-2"> {{orderItem.containerLabelFmt}}</span>

            <span [ngClass]="isInventorySaved ? 'saved' : 'not-saved'" *ngIf="orderItem.isReachCompliant">
                <app-pill Text="{{isInventorySaved ? 'Saved' : 'Not Saved'}}" Type="REACHCompliantRequested">
                    <i [ngClass]="isInventorySaved ? 'pi pi-check p-pr-2 green' : 'pi pi-exclamation-triangle p-pr-2 yellow'"></i>
                </app-pill>
            </span>
        
            <span class="p-ml-2" *ngIf="orderItem.isReachCompliant">
                <app-pill Text="REACH Compliant Requested" Type="REACHCompliantRequested"></app-pill>
            </span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="p-grid">
            <div class="p-col-6">
                <div class="p-field p-grid p-ml-2">
                    <label class="p-col-fixed" style="width:160px">Requested Lot #:</label>
                    <div class="p-col">{{requestedLotNumber}}</div>
                </div>
                <div class="p-field p-grid p-ml-2">
                    <label class="p-col-fixed" style="width:160px">Number of Containers:</label>
                    <div class="p-col">{{numberOfContainers}}</div>
                </div>
                <div class="p-field p-grid p-ml-2">
                    <label class="p-col-fixed" style="width:160px">Total Amount:</label>
                    <div class="p-col"> {{totalAmount | weightKG}} {{orderItem.unitOfMeasureDesc}} </div>
                </div>
                <div class="p-grid p-ml-2">
                    <div class="p-col-6 p-pt-2">
                        <div class="border instr-box">
                            <span class="special-instr">Special Instructions</span>
                            <div *ngIf='orderItem?.specialInstructions' class="p-p-2 highlight-yellow">
                                <p>{{orderItem?.specialInstructions}}</p>
                            </div>
                            <div *ngIf='!orderItem?.specialInstructions' class="p-p-2">
                                <p>No Instructions</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-field p-mt-2">
                    <div class="p-col">
                        <label style="font-weight: 600;">Inventory</label>
                    </div>
                    <div class="p-grid" style="position: relative;">
                        <div class="p-col">
                            <ag-grid-angular #grid id="myGrid" style="width:100%; height:240px;" class="ag-theme-alpine"
                                suppressCellSelection="true" [suppressRowClickSelection]="true" [rowData]="rowData"
                                [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" rowSelection="multiple"
                                [gridOptions]='gridOptions' (gridSizeChanged)="resizeGrid($event)"
                                (selectionChanged)='onSelectionChanged($event)'>
                            </ag-grid-angular>
                        </div>
                        <div class="p-col-fixed" style="width:80px">
                            <button mat-raised-button color="cor-primary" style="position: absolute;bottom: 10px;"
                                [disabled]='disableSave' (click)="onSave()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-6">
                <ag-grid-angular #roleGrid style="width:99.8%;margin-top: 20px;margin-bottom: 20px"
                    class="ag-theme-alpine" domLayout="autoHeight" suppressHorizontalScroll="true"
                    [rowData]="reservationRowData" [columnDefs]="reservationColumnDefs"
                    (gridSizeChanged)="reservationResizeGrid($event)" (gridReady)="onReservationGridReady($event)">
                </ag-grid-angular>
            </div>
        </div>
        <app-attachments #itemAttachments [entity]='attachmentEntity' [isLoginUserCoordinator]="isLoginUserCoordinator"
            (updateOrderItemAttachment)="updateOrderItemAttachment($event)"
            (createOrderItemAttachment)="createOrderItemAttachment($event)"
            (removeOrderItemAttachment)="removeOrderItemAttachment($event)"
            (downloadOrderItemAttachment)="downloadAttachment($event)">
        </app-attachments>
        <div class="p-col-12">
            <ag-grid-angular #roleGrid
                style="width:99.8%;height: 300px;overflow: auto; margin-top: 20px;margin-bottom: 20px"
                class="ag-theme-alpine" suppressHorizontalScroll="true" [rowData]="earmarkRowData"
                [columnDefs]="earmarkColumnDefs" (gridSizeChanged)="earmarkResizeGrid($event)"
                (gridReady)="onEarmarkGridReady($event)">
            </ag-grid-angular>
        </div>
        <button mat-raised-button color="cor-primary" [disabled]='canDisableFlagLabel'
            (click)="onPrintGLPFlagLabel()">GLP
            Flag Label</button>
    </ng-template>
</mat-expansion-panel>