<div class="flex-grid">
    <div class="col">
        <h4>Edit User</h4>
    </div>
</div>
<p-confirmDialog [style]="{width: '20%'}"></p-confirmDialog>
<form *ngIf="!isLoading" [formGroup]="userManagerForm" (ngSubmit)="onSubmit(assignedRoles)">
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-6">
                    <label for="requestor">Selected User: </label>
                    <b  style="font-weight: 600;">  {{ user?.firstName }} {{user?.lastName}}</b>
                </div>
            </div>
        </div>
    </div>
    <div class="">
        <p-pickList [source]="availableRoles" [target]="assignedRoles" sourceHeader="Available Roles"
            targetHeader="Assigned Roles" [dragdrop]="true" [responsive]="true"
            [sourceStyle]="{ height: '25rem' }" [targetStyle]="{ height: '25rem' }" breakpoint="1400px">
            <ng-template let-permission pTemplate="item">
                <div class="flex flex-wrap p-2 align-items-center gap-3">
                    <div class="flex-1 flex flex-column gap-2">
                        <span class="font-bold">{{ permission.roleName }}</span>
                    </div>
                </div>
            </ng-template>
        </p-pickList>
    </div>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="float-right p-mt-3" style="margin-right: 2%;">
                <button mat-raised-button color="cor-default" (click)="onCancel()" type="button"
                    [disabled]="!userManagerForm.valid">Cancel</button>
                <button mat-raised-button color="cor-default" type="submit" color="cor-primary"
                    style="margin-left: 20px;">Save</button>
            </div>
        </div>
    </div>
</form>