<h5 id="assign-reassign-coordinator">
    {{title}}
</h5>

<div class="doublespacer"></div>

<span class="message">{{message}}</span>

<div class="spacer"></div>

<span class="message">{{secondaryMessage}}</span>

<div class="doublespacer"></div>

<p-dropdown #dropdown [options]="coordinatorSelectList" appendTo="body" [(ngModel)]="selectedCoordinator" name="selectedReason"
    panelStyleClass='assign-modal-minWidthOverride' (onShow)='onDropDownShow($event)' [style]="{'width':'100%'}"></p-dropdown>

<div class="spacer"></div>

<div mat-dialog-actions style="float: right;">
    <button mat-raised-button color="cor-default" (click)="onDismiss()" style="float: right;">Cancel</button>
    <button mat-raised-button color="cor-primary" (click)="onConfirm()" style="float: right;">Assign</button>
</div>