import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttachmentService } from 'src/app/core/modules/attachments/services/attachment.service';
import { Language } from 'src/app/models/language.model';
import { LabelService } from 'src/app/services/label-service/label-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { SharedDcumentService } from 'src/app/services/shared-document-service/shared-document-service.service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {


  types: any[] = [];
  languages: any[] = [];
  labelSizes: any[] = [];
  selectedType: any;
  selectedLanguage: any;
  selectedLabelSize: any;
  uploadedFile: File;
  documentTypes: any[] = [];

  constructor(public dialogRef: MatDialogRef<UploadDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private labelService: LabelService,
    private sharedDocumentService: SharedDcumentService,
    private responseHandlingService: ResponseHandlingService, private attachmentService: AttachmentService) { }

  ngOnInit(): void {
    const docTypes: DocumentType[] = JSON.parse(localStorage.getItem("documentTypes"));
    if (docTypes == null) {
      this.attachmentService.retrieveDocumentTypes()
        .subscribe(data => {
          this.documentTypes = data;
          localStorage.setItem('documentTypes', JSON.stringify(this.documentTypes));
        });
    }
    else {
      this.documentTypes = docTypes;
    }
    const languages: Language[] = JSON.parse(localStorage.getItem("languages"));
    if (languages == null) {
      this.attachmentService.retrieveLanguages()
        .subscribe(data => {
          this.languages = data;
          localStorage.setItem('languages', JSON.stringify(this.languages));
        });
    }
    else {
      this.languages = languages;
    }
    this.labelService.getLabelOptions().subscribe(response => {
      if (response) {
        this.labelSizes = response.sizes ? response.sizes : [];
        this.types = response.types ? response.types : [];
      }
    })
  }

  onFileUpload(event) {
    this.uploadedFile = event.target.files[0];
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    const reader = new FileReader();
    reader.readAsDataURL(this.uploadedFile);
    reader.onload = () => {
      const base64File = reader.result?.toString().split('base64,')[1];
      const payload = {
        "inventoryCatalogID": this.data.inventoryCatalogID,
        "documentTypeID": this.selectedType.documentTypeID,
        "languageID": this.selectedLanguage.languageID,
        "filename": this.uploadedFile.name,
        "base64FileContents": base64File
      }

      this.sharedDocumentService.createSharedDocument(payload).subscribe(result => {
        this.responseHandlingService.showSuccess("Created shared document successfully", 3000, 'Success');
        this.dialogRef.close(result);
      })
    }
  }

}

