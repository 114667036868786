import { Injectable } from '@angular/core';
import { UserPermission } from '../../models/security-models';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/configuration';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';


@Injectable({
  providedIn: 'root'
})

export class PermissionService {

  private userPermissions: UserPermission[];
  public isRequestCoordinator: boolean;
  public isAdministrator: boolean;
  public isDecant: boolean;
  public isReceiving: boolean;
  public isInventory: boolean;
  public isPacking: boolean;
  public isShipping: boolean;
  public isWarehousingDispose: boolean;
  public isRequestCoordinatorPrep: boolean;
  public isRequestCoordinatorWarehouse: boolean;
  public isRequestCoordinatorProcessed: boolean;
  public isRequestCoordinatorCompleted: boolean;
  public isRequestCoordinatorReprint: boolean;
  public isWarehousingPicking: boolean;
  public isDecantWarehousing: boolean;
  public isAdministratorScaleTest: boolean;
  public canPerformSearch: boolean;
  public canManageGSSWarehouse: boolean;
  public canManageLabels: boolean;
  public canManageCancellationReasons: boolean;
  public canManageLabelDesign: boolean;
  public isCurrentP2P: boolean;
  public isCompleteP2PSearch: boolean;
  public canManageAttachments: boolean;
  public canSupressRequestorEmails: boolean;
  public canManageDestinationGuide: boolean;
  public canRevertStatus: boolean;
  public canEditDensity: boolean;
  public canManagerContainerWeights: boolean;
  public canHideDocument: boolean;
  public canShareDocument: boolean;
  public canManageLabelPrinters: boolean;
  public canManageHoldReasons: boolean;
  public canManageShippers: boolean;
  public canManageSampleUses: boolean;
  public canManageStations: boolean;
  public canManageScales: boolean;

  constructor(private readonly http: HttpClient, private readonly responseHandler: ResponseHandlingService) {
    if (localStorage.getItem("userPermissions") != null && localStorage.getItem("userPermissions") != "undefined") {
      const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      if (userPermissions != null) {
        this.userPermissions = userPermissions;
      }
    }
  }

  getPermissions(): Observable<any[]> {
    var URL = `${Configuration.REST_API_URL}/Permissions`;
    return this.http.get<any[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to get list of stations at this time, please resubmit."]]), false)));
  }

  public validateUserPermission(permission: number) {
    if (!this.userPermissions || this.userPermissions.length < 1) {
      return false;
    }
    if (this.userPermissions.some(x => x.permissionID === permission)) {
      return true;
    }
    else {
      return false;
    }
  }

  public updateUserPermissions() {
    const userPermissions = JSON.parse(localStorage.getItem("userPermissions"))
    if (userPermissions != null) {
      this.userPermissions = userPermissions;
    }
    this.isRequestCoordinator = this.validateUserPermission(400);
    this.isRequestCoordinatorPrep = this.validateUserPermission(410);
    this.isRequestCoordinatorWarehouse = this.validateUserPermission(420);
    this.isRequestCoordinatorProcessed = this.validateUserPermission(430);
    this.isRequestCoordinatorCompleted = this.validateUserPermission(460);
    this.isRequestCoordinatorReprint = this.validateUserPermission(440);
    this.canPerformSearch = this.validateUserPermission(471);
    this.canManageGSSWarehouse = this.validateUserPermission(472);
    this.isReceiving = this.validateUserPermission(600);
    this.isWarehousingPicking = this.validateUserPermission(800);
    this.isWarehousingDispose = this.validateUserPermission(810);
    this.isDecant = this.validateUserPermission(500);
    this.isDecantWarehousing = this.validateUserPermission(520);
    this.isAdministrator = this.validateUserPermission(300);
    this.isAdministratorScaleTest = this.validateUserPermission(310);
    this.canManageLabels = this.validateUserPermission(320);
    this.canManageCancellationReasons = this.validateUserPermission(1100);
    this.canManageAttachments = this.validateUserPermission(452);
    this.isPacking = this.validateUserPermission(700);
    this.isShipping = this.validateUserPermission(710);
    this.isCurrentP2P = this.validateUserPermission(1000);
    this.isCompleteP2PSearch = this.validateUserPermission(1010);
    this.canSupressRequestorEmails = this.validateUserPermission(454);
    this.canManageDestinationGuide = this.validateUserPermission(330);
    this.isInventory = this.validateUserPermission(610);
    this.canRevertStatus = this.validateUserPermission(530);
    this.canEditDensity = this.validateUserPermission(1021);
    this.canManagerContainerWeights = this.validateUserPermission(1022);
    this.canHideDocument = this.validateUserPermission(1023);
    this.canShareDocument = this.validateUserPermission(1024);
    this.canManageLabelPrinters = this.validateUserPermission(1040);
    this.canManageHoldReasons = this.validateUserPermission(1050);
    this.canManageShippers = this.validateUserPermission(1060);
    this.canManageSampleUses = this.validateUserPermission(1080);
    this.canManageStations = this.validateUserPermission(1070);
    this.canManageScales = this.validateUserPermission(1090);
  }
}