<h5 class="p-mb-3">Cancel Request</h5>

<mat-dialog-content>
    <form [formGroup]="cancelOrder">
        <div class="p-grid">
            <div class="p-col-6 p-field">
                <div class="p-fluid">
                    <label class="p-text-bold" for="ddlReason">Reason</label>
                    <p-dropdown panelStyleClass='cancel-dropdown' [options]="cancelReasons" optionLabel="name" optionValue="name" inputId="ddlReason"
                        formControlName="reason" placeholder="Select Reason" (onChange)="onReasonChange()"></p-dropdown>
                </div>
                <div class="p-error" *ngIf="isFormSubmitted">
                    <small *ngIf="fc.reason.errors?.required">
                        Field is required
                    </small>
                </div>
            </div>
            <div class="p-col-12 p-field" *ngIf="fc.reason.value === 'Other'">
                <div class="p-fluid">
                    <label class="p-text-bold" for="comment">Comment</label>
                    <!-- <input pInputText formControlName="comment" id="comment" type="text" (blur)="setErrors()" /> -->
                    <textarea [rows]="5" [cols]="30" maxlength="255" pInputTextarea autoResize="autoResize" formControlName="comment" id="comment" (blur)="setErrors()"></textarea>
                </div>
                <div class="p-error" *ngIf="isFormSubmitted">
                    <small *ngIf="fc.comment.errors?.required"> Field is required</small>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions style="float: right; min-height: 80px;">
    <button mat-raised-button color="cor-default" mat-dialog-close>Close</button>
    <button mat-raised-button color="cor-danger" (click)="onCancel()"
        [disabled]="cancelOrder.invalid || (fc.reason.value === 'Other' && fc.comment.value.length === 0)">Cancel Request</button>
</mat-dialog-actions>