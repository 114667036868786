import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerWeights } from 'src/app/models/container-weights';
import { OrderItemContainerModel } from 'src/app/models/order-item-container-model';
import { Order } from 'src/app/models/order-models';
import { ContainerWeightsService } from 'src/app/services/container-weights/container-weights.service';
import { take } from 'rxjs/operators';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { OrderService } from 'src/app/services/order-service/order-service';

@Component({
  selector: 'app-packing-materials-modal',
  templateUrl: './packing-materials-modal.component.html',
  styleUrls: ['./packing-materials-modal.component.scss']
})
export class PackingMaterialsModalComponent implements OnInit {

  uuid: any;
  public weights: ContainerWeights[];
  public weightsCloned: ContainerWeights[];
  cumulativePlasticWeight: any = 0;
  cumulativeTotalWeight: any = 0;

  constructor(private route: ActivatedRoute, private readonly containerWeightService: ContainerWeightsService,
    private orderService: OrderService,
    private toastr: ResponseHandlingService, private router: Router, private dialog: MatDialog) {
    this.uuid = this.route.snapshot.paramMap.get("id");
    this.containerWeightService
      .getContainerWeightsByID(this.uuid)
      .pipe(take(1))
      .subscribe((weightsResponse) => {
        this.weights = weightsResponse;
        this.weightsCloned = JSON.parse(JSON.stringify(weightsResponse));
        this.onQuantityChange();
      });
  }

  ngOnInit(): void {
  }

  onQuantityChange() {
    let _cumulativePlasticWeight = 0;
    let _cumulativeTotalWeight = 0;
    this.weights?.filter(w => w.quantity).forEach(element => {
      _cumulativePlasticWeight += (element.plasticWeight * element.quantity) || 0;
      _cumulativeTotalWeight += (element?.weight * element.quantity) || 0;
    });
    this.cumulativePlasticWeight = _cumulativePlasticWeight ? _cumulativePlasticWeight.toFixed(7)?.toString() : 0;
    this.cumulativeTotalWeight = _cumulativeTotalWeight ? parseFloat(_cumulativeTotalWeight.toFixed(7))?.toString() : 0;
  }

  save() {
    let payload = this.weights?.filter(w => w.quantity);
    this.orderService.saveContainerWeights(this.uuid, payload)
      .pipe(take(1))
      .subscribe(res => {
        this.toastr.showSuccess('Saved successfully');
        this.router.navigate(['/requests/', this.uuid]);
      })
  }

  cancel() {
    if (this.weightsCloned.some((x, index) => x.quantity != this.weights[index].quantity)) {
      const dialogData =
        new ConfirmDialogModel("Confirm",
          `Your changes will not be saved. Are you sure?`,
          "Yes", false, false, "No");
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        disableClose: true,
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.router.navigate(['/requests/', this.uuid]);
        }
      });
    } else {
      this.router.navigate(['/requests/', this.uuid]);
    }
  }

}
