<h5 class="p-mb-3">{{buttonLabel}} Scale</h5>

<form [formGroup]="scaleForm" (submit)="onSubmit()">
    <div class="p-grid p-mt-3 p-mb-4">
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Name:</div>
                <input formControlName="name" pInputText type="text" [ngClass]="scaleForm.controls['name'].invalid && submitted ? 'error' : ''" style="width: 100%;" maxlength="80"/>
                <div *ngIf="scaleForm.controls['name'].invalid && submitted && (scaleForm.controls['name'].dirty || scaleForm.controls['name'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="scaleForm.controls.name.errors?.required">
                        Field required.
                    </small>                
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Station:</div>
                <p-dropdown id="stations" placeholder="Select a station" formControlName="stationID" [options]="stationList" [ngClass]="scaleForm.controls['stationID'].invalid && submitted ? 'error' : ''"
                    optionLabel="name" optionValue="stationID"
                    [style]="{ minWidth: '100%', width: '100%' }" autoWidth="false">
                </p-dropdown>
                <div *ngIf="scaleForm.controls['stationID'].invalid && submitted && (scaleForm.controls['stationID'].dirty || scaleForm.controls['stationID'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="scaleForm.controls.stationID.errors?.required">
                        Field required.
                    </small>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Scale Type:</div>
                <p-dropdown id="stations" placeholder="Select a type" formControlName="scaleType" [options]="scaleTypeList" [ngClass]="scaleForm.controls['scaleType'].invalid && submitted ? 'error' : ''"
                    optionLabel="typeName" optionValue="scaleType"
                    [style]="{ minWidth: '100%', width: '100%' }" autoWidth="false">
                </p-dropdown>
                <div *ngIf="scaleForm.controls['scaleType'].invalid && submitted && (scaleForm.controls['scaleType'].dirty || scaleForm.controls['scaleType'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="scaleForm.controls.scaleType.errors?.required">
                        Field required.
                    </small>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">COM Port:</div>
                <p-dropdown id="stations" placeholder="Select a type" formControlName="comPort" [options]="comPortList" [ngClass]="scaleForm.controls['comPort'].invalid && submitted ? 'error' : ''"
                    optionLabel="portName" optionValue="comPort"
                    [style]="{ minWidth: '100%', width: '100%' }" autoWidth="false">
                </p-dropdown>
                <div *ngIf="scaleForm.controls['comPort'].invalid && submitted && (scaleForm.controls['comPort'].dirty || scaleForm.controls['comPort'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="scaleForm.controls.comPort.errors?.required">
                        Field required.
                    </small>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Decimal Places:</div>
                <input numeric decimals="0" formControlName="decimalPlaces" pInputText type="text" [ngClass]="scaleForm.controls['decimalPlaces'].invalid && submitted ? 'error' : ''" style="width: 100%;" maxlength="1"/>
                <div *ngIf="scaleForm.controls['decimalPlaces'].invalid && submitted && (scaleForm.controls['decimalPlaces'].dirty || scaleForm.controls['decimalPlaces'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="scaleForm.controls.decimalPlaces.errors?.required">
                        Field required.
                    </small>
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="scaleForm.controls.decimalPlaces.errors?.max">
                        Value less or equal to 7.
                    </small>                   
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Maximum Weight:</div>
                <input numeric decimals="7" formControlName="maxWeightKG" pInputText type="text" [ngClass]="scaleForm.controls['maxWeightKG'].invalid && submitted ? 'error' : ''" style="width: 100%;"/>
                <div *ngIf="scaleForm.controls['maxWeightKG'].invalid && submitted && (scaleForm.controls['maxWeightKG'].dirty || scaleForm.controls['maxWeightKG'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="scaleForm.controls.maxWeightKG.errors?.required">
                        Field required.
                    </small> 
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="scaleForm.controls.maxWeightKG.errors?.greaterThanZeroValue">
                        Value greater than 0.
                    </small>                 
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <span>Is GLP?</span>
            <mat-slide-toggle [style]="{'margin-left':'10px'}" formControlName="isGLP">
            </mat-slide-toggle>
        </div>    
        <mat-dialog-actions style="float: right" class="actions">
            <button style="margin-right: 15px;" mat-raised-button color="cor-default" mat-dialog-close>Cancel</button>
            <button mat-raised-button color="cor-primary" type="submit">Save</button>
        </mat-dialog-actions>
    </div>
</form>
