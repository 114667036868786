export interface InventoryItem {
    id: number,
    itemID: number,
    catalogRecordID: number,
    locationID: number,
    locationName: string,
    fullLocationName: string,
    remainingAmount: number,
    lotNumber: string,
    expirationDate: string,
    containerCode: string,
    notes: string,
    catalogDescription: string,
    postDate: string,
    tareWeightKG?: number,
    manufacturerLotNumber: string,
    totalAssignedAmount?: any,
    isAssigned: boolean,
    selectedDisposalReason?: any,
    comments?: any,
    noDecantInventoryItemID: string
}

export interface Reservation {
    reservationAmountConverted: number,
    reservationType: string,
    lotNumber: string,
    reservationTypeID: number,
    isGLP: boolean
}

export interface RetrieveCatalogItemWithConversionResponse {
    remainingAmountConverted: number,
    reservations: Reservation[],
    items: any[],
    lots: Lot[],
    glpLots: Lot[],
    stockLots: Lot[],
    description: string
    // glpItemRanges: string[],
    // stockItemRanges: string[]
}

export interface RetrieveCatalogItemsResponse {
    isReachCompliant: any
    expirationDate: any
    certificationDate: any
    recertificationDate: any
    countryOfOrigin: any
    glpStatus: any
    sampleUse: any
    materialType: any
    manufacturerName: any
    manufacturerLotNumber: any
    manufactureDate: any
    physicalState: any
    parentCompounds: any
    vendorLotID: any
    tsnNumber: any
    id: any
    itemID: any
    catalogRecordID: any
    productNumber: any
    locationID: any
    parentLocationID: any
    locationName: any
    fullLocationName: any
    remainingAmount: any
    postDate: any
    modifiedDate: any
    customText3: any
    lotNumber: any
    notes: any
    catalogDescription: any
    tareWeightKG: any
    extraPPERequired: any
    comments: any
    storageConditions: any
    vendorTag: any
}

export enum ReservationTypes {
    IndyReserved = 1,
    SGSReserved = 2
}

export interface Lot {
    expirationDate: Date;
    lotNumber: string;
    remainingAmount: number;
    remainingAmountConverted: number;
    isReachCompliant: boolean;
    unreservedEarmarkedAmountConverted: number;
    reservedEarmarkedAmountConverted: number;
    netAmountAvailable: number;
    netAmountAvailableDisplay: string;
    manufacturerName: string;
    manufacturerLotNumber: string;
    manufactureDate: Date;
    certificationDate: Date;
    recertificationDate: Date;
    expiredAmount: number;
    expiredAmountConverted: number;
}

export interface NInventoryItems {
    inventoryItemID: number,
    inventoryCatalogID: number,
    catalogDescription: string,
    lotNumber: string,
    amountKg: number,
    locationID: number,
    fullLocationName: string
}

export interface NItemSearch {
    inventoryCatalogID: number,
    catalogDescription: string,
    amountKg: number,
    inventoryItems: NInventoryItems[]
}