import { Language } from './../../../../models/language.model';
import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Configuration } from 'src/app/configuration';
import { DocumentType } from 'src/app/models/document-type-model';
@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  private _documentTypes: BehaviorSubject<DocumentType[]> = new BehaviorSubject<DocumentType[]>([]);
  public documentTypes$ = this._documentTypes.asObservable();
  private _language: BehaviorSubject<Language[]> = new BehaviorSubject<Language[]>([]);
  public language$ = this._language.asObservable();
  constructor(private _http: HttpClient) { }

  public retrieveDocumentTypes(): Observable<DocumentType[]> {

    if (this._documentTypes.getValue().length == 0) {
      const url = Configuration.REST_API_URL + '/Orders/DocumentTypes';
      return this._http.get<DocumentType[]>(url).pipe(map(x => {
        this.setDocumentTypes(x);
        return x;
      }));
    } else {
      return this.documentTypes$
    }

  }

  public setDocumentTypes(documentTypes: DocumentType[]): void {
    this._documentTypes.next(documentTypes);
  }

  public retrieveLanguages(): Observable<Language[]> {
    if (this._language.getValue().length == 0) {
      const url = Configuration.REST_API_URL + '/Orders/Languages';
      return this._http.get<Language[]>(url).pipe(map(x => {
        this.setLanguages(x);
        return x;
      }));
    } else {
      return this.language$;
    }
  }

  public setLanguages(languages: Language[]): void {
    this._language.next(languages);
  }

  public download(fileName: string, fileContents: any) {
    var blob = this.dataURItoBlob(fileContents)
    var a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
  }

  private dataURItoBlob(dataURI) {
    var byteString = atob(dataURI);
    var mimeString = 'image/png';
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

}
