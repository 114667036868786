import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
    selector: 'move-button-cell',
    template: `<input type="checkbox" #input (change)="checkedHandler($event)"
    class="ag-input-field-input ag-text-field-input"
    [(ngModel)]="value" />`,
    styles: [],
  })
  
  export class CheckBoxEditorRenderer implements ICellRendererAngularComp {
      
private params: any;
  public value: number;

  @ViewChild('input') input: ElementRef;

  constructor() { }



  agInit(params: any): void {
      this.params = params;
      this.value = this.params.value;
  }

  getValue() {
    return this.value;
}
  // ngAfterViewInit() {
  // window.setTimeout(() => {
  //   this.input.nativeElement.focus();
  // });
  // }

  refresh(): boolean {
    return false;
  }
  checkedHandler(event) {
      let checked = event.target.checked;
      let colId = this.params.column.colId;
      this.params.node.setDataValue(colId, checked);
    //  this.params.node.setDataValue('isDecanted',true);
  }

  }