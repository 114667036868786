import { AddressViewModel } from "./address.model";
import { MaterialViewModel } from "./material.model";
import { SupplierViewModel } from "./supplier.model";
import { SessionContainerWeight } from "./weighing.model";

export interface SessionModel {
    sessionID: number;
    materialTypeId: number;
    isGLP: boolean;
    GLP?: string;
    materialId: string;
    materialName: string;
    alternateName: string;
    supplierId: string;
    isSdsavailable: boolean;
    isCoaavailable: boolean;
    receiptDate?: string;
    noOfContainers?: number;
    manufacturerLotId?: string;
    // colonyFormingUnits?: string;
    expirationDate?: string;
    countryOfOriginId?: number;
    chemicalStateID?: number;
    shippingConditions?: string;
    storageConditions?: string;
    sensitivities?: string;
    countryOfOriginName?: string;
    extraPperequired?: boolean;
    desiccant?: boolean;
    incomingContainerTypeId?: number;
    notes?: string;
    manufacturerDate?: string;
    supplierAddressID: number;
    sessionContainerWeights?: SessionContainerWeight[];
    cortevaLotID?: number;
    catalogRecordID?: number;
    statusType: StatusType;
    submittedWeight?: number;
    tsn?: string;
    molecularWeight?: number;
    certificationDate?: string;
    recertificationDate?: string;
    reachCompliant?: boolean;
    storageLocationId?: number;
    certifiedPurity?: string;
    supplierName?: string;
    lotNumber?: string;
    formulationType?: string;
    density?: number;
    unitOfMeasureId?: number;
    peroxideForming?: boolean;
    moleculeType?: string;
    stage?: string;
    specificGravity?: number;
    areLabelsPrinted?: boolean;
    largeDecantContainerTypeId?: number;
    lastDefectReasonId?: number;
    metadata?: { [key: string]: object }
    LastDefectResolutionDate?: string;
    warehouseID?: number;
    warehouseName?: string;
}

export interface SessionViewModel extends SessionModel {
    statusTypeDescription: string;
    lastDefectReason: string;
    lastDefectReasonId: number;
    lastDefectResolutionDate: string;
    showActionButtons: boolean;
}

export interface ReceivingStateViewModel {
    material: Partial<MaterialViewModel>;
    supplier: Partial<SupplierViewModel>;
    address: AddressViewModel
}

export enum StatusType {
    Draft = 0,
    Completed = 2,
    Defect = 1,
    LargeDecant = 3
}