import {Pipe, PipeTransform} from '@angular/core';
 
@Pipe({name: 'weightKG'})
export class WeightFormatKGPipe implements PipeTransform { 
    transform(weight, precision?): string { 
        if (weight) { 
            if (precision) { 
                return parseFloat(weight.toFixed(precision)).toString(); 
            } else { 
                var multiplier = Math.pow(10, 7); 
                var result = (Math.round(weight * multiplier) / multiplier); 
                return parseFloat(result.toFixed(7)).toString(); 
            } 
        } else { 
            return '0.000';
        } 
    } 
}