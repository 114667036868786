import * as moment from "moment";
export const FormulationLotPrefix: string = "F";
export const CompoundLotPrefix: string = "C";
export const BiologicalLotPrefix: string = "BL";
export const BiologicalActivePrefix: string = "BA";
// EAS 9/20/2023 BE is the new active prefix, per Duane Garman
export const BiologicalActivePrefix2: string = "BE";

export const receivingConstants = {
    Routes: {
        Search: "/receiving",
        MaterialSelection: "/receiving/material-selection",
        Validate: "/receiving/validation",
        DataEntry: "/receiving/data-entry",
        Weighing: "/receiving/weighing",
        DocUpload: "/receiving/doc-upload",
        Submit: "/receiving/submit",
        Label: "/receiving/label",
        PreviousSessions: "/receiving/previous-sessions",
        DataEntry_Id: "/receiving/data-entry/:id",
        Weighing_Id: "/receiving/weighing/:id",
        DocUpload_Id: "/receiving/doc-upload/:id",
        Label_Id: "/receiving/label/:id",
    },
    DateFormat: "MM/DD/YYYY",
    LookupTypes: {
        Countries: "Countries",
        Containers: "Containers",
        DocumentType: "DocumentType",
        Printers: "Printers",
        StorageLocations: "StorageLocations",
        UnitOfMeasures: "UnitOfMeasures",
        DefectReasons: "DefectReasons"
    },
    ScaleType: {
        Receiving: 2
    },
    BarcodeScanType: {
        Compound: `${CompoundLotPrefix}-`,
        Formulation: `${FormulationLotPrefix}-`,
        BiologicalLot: `${BiologicalLotPrefix}`,
        BiologicalActive: `${BiologicalActivePrefix}`,
        BiologicalActive2: `${BiologicalActivePrefix2}`
    },
    DefaultUnitOfMeasure: 'kg/l',
    DefaultUnitOfMeasureIdFormulation: 59,
    FormulationThirdPartyStage: 'THIRD_PARTY',
    DefaultReceiverRoleName: "GSS Receiver",
    DefaultGridPageSize: 30,
    CreateNewLotLabel: "Create New Lot",
    CortevaName: "Corteva Agriscience",
    SearchDefaultTimeout: 180000,    //this to match server's mnaterial httpclient timeout
    ServerDateFormat: "YYYY-MM-DD",
    DefaultYearsToExpiration: 2
}

//Angular Router Url should not starts with '/'
//Where as when using routerLink you need to provide '/' to make it relative url
export function GetRouterUrl(url: string): string {
    if (url != undefined && url.startsWith('/')) {
        return url.substring(1);
    }
    return url;
}

export const DATE_FORMATS = {
    parse: {
        dateInput: receivingConstants.DateFormat,
    },
    display: {
        dateInput: receivingConstants.DateFormat,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export function getDateFromDateTime(dateTime) {
    let result = dateTime;
    if (dateTime) {
        const val = moment(dateTime);
        if (val.isValid() && dateTime.indexOf("T")) {
            result = dateTime.split("T")[0];
            const dateArr = result.split("-");
            let year = dateArr[0];
            let month = dateArr[1];
            let date = dateArr[2];
            result = new Date(`${month}/${date}/${year}`);
            result = result.toLocaleDateString();
        }
    }
    return result;
}

export const SignalRServerTimeOut = 120000;