<div>
    <h5 class="p-mb-3">Packing Materials</h5>
    <div class="p-field p-mt-4">
        <div class="p-grid" *ngFor="let weight of weights">
            <div class="p-col-8 p-field">
                <label class="p-text-bold" for="containerNameTxt">{{weight?.name}}</label>
            </div>
            <div class="p-col-2 p-field">
                <input pInputText id="containerNameTxt" min="0" numeric type="number" (input)="onQuantityChange()"
                    [(ngModel)]="weight.quantity" />
            </div>
        </div>
    </div>
    <div class="p-field p-mt-3">
        <pre>Cumulative Plastic Weight : {{cumulativePlasticWeight}}    Cumulative Total Weight : {{cumulativeTotalWeight}}</pre>
    </div>
    <div class="p-grid">
        <div class="p-col-8">
        </div>
        <div class="p-col-2">
            <div class="actions flex-end" style="float: right;margin-right: 4rem;">
                <button mat-raised-button class="p-mr-3" color="cor-primary" (click)="save()">Save</button>
                <button mat-raised-button color="cor-default" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>
</div>