<!-- <div>
    <p class="message">{{order.requestNumber}} - 2</p>
   
    <div class="actions">
        <button mat-raised-button color="cor-default" (click)="cancel()">Cancel</button>
        <button mat-raised-button color="cor-primary" (click)="confirm()">Verify</button>
    </div>
</div> -->

<h5>Confirm Split</h5>

<p class="mat-body">
    The selected containers will be moved to a separate pending request assigned to the coordinator</p>

<div mat-dialog-actions style="float: right;">
    <button mat-raised-button color="cor-default" (click)="cancel()" style="float: right;">Cancel</button>
    <button mat-raised-button color="cor-primary" (click)="confirm()" style="float: right;">Confirm</button>
    <!-- <button *ngIf="!useDangerConfirm" mat-raised-button color="cor-primary" (click)="onConfirm()" style="float: right;">{{confirmButtonLabel}}</button> -->
</div>