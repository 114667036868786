import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DecantStation, Warehouse } from "src/app/models/decant-models";
import { DecantService } from "src/app/services/decant-service/decant-service";

@Component({
  selector: 'app-warehouse-station',
  templateUrl: './warehouse-station.component.html',
  styleUrls: ['./warehouse-station.component.scss'],
})

export class WarehouseStationComponent implements OnInit {
  @Output() selectedStation = new EventEmitter<DecantStation>();
  @Output() stationDropDownOpen = new EventEmitter<boolean>();
  @Output() onWarehouseSeleted = new EventEmitter<Warehouse>();

  public warehousesStationsRes: DecantStation[];
  public stationsRes: DecantStation[];
  public station: DecantStation;
  public selectedWarehouse: Warehouse;
  public warehousesRes: Warehouse[];
  warehouseStationForm: FormGroup;
  warehouseSelected = false;

  constructor(private decantService: DecantService, private router: Router) { }

  ngOnInit(): void {
    this.buildForm();
    this.getWarehouseList();
  }

  buildForm() {
    this.warehouseStationForm = new FormGroup({
      warehousectrl: new FormControl(null, Validators.required),
      stationctrl: new FormControl(null, Validators.required)
    });
  }

  getWarehouseList() {
    this.decantService.getWarehouses().subscribe({
      next: (res) => {
        this.warehousesRes = res
        const defaultWarehouseID = JSON.parse(localStorage.getItem("defaultWarehouseID"));
        if (defaultWarehouseID) {
          this.selectedWarehouse = this.warehousesRes.find(s => s.itemID === defaultWarehouseID);
          this.warehouseStationForm.patchValue({
            warehousectrl: this.selectedWarehouse
          });
          this.warehouseSelected = true;
          this.onWarehouseSeleted.emit(this.selectedWarehouse);
        }
        this.getStations();
      }
    })
  }

  getStations() {
    this.decantService.getDecantStations().subscribe({
      next: (res) => {
        this.warehousesStationsRes = res;
        if (this.selectedWarehouse) {
          this.stationsRes = this.warehousesStationsRes.filter(x => x.warehouseID === this.selectedWarehouse.itemID);
        }
        if (this.router.url === '/packing') {
          if (localStorage.getItem("packingStation")) {
            this.station = JSON.parse(localStorage.getItem("packingStation"));
            this.warehouseStationForm.patchValue({
              stationctrl: this.station
            });
          };
        } else if (this.router.url === '/decant') {
          if (localStorage.getItem("station")) {
            this.station = JSON.parse(localStorage.getItem("station"));
            this.warehouseStationForm.patchValue({
              stationctrl: this.station
            });
          };
        } else if (this.router.url === '/requests/reprint') {
          if (localStorage.getItem("reprintStation")) {
            this.station = JSON.parse(localStorage.getItem("reprintStation"));
            this.warehouseStationForm.patchValue({
              stationctrl: this.station
            });
          };
        } else if (this.router.url === '/decant/parentweight') {
          if (localStorage.getItem("reweighStation")) {
            this.station = JSON.parse(localStorage.getItem("reweighStation"));
            this.warehouseStationForm.patchValue({
              stationctrl: this.station
            });
          };
        }

      }
    })
  }

  onWareHouseChange(warehouse: Warehouse) {
    this.selectedWarehouse = warehouse;
    this.warehouseSelected = true;
    this.stationsRes = this.warehousesStationsRes.filter(x => x.warehouseID === warehouse.itemID);
    localStorage.setItem("station", null);
    this.selectedStation.emit(null);
    this.onWarehouseSeleted.emit(warehouse);
  }

  onStationChange(station: DecantStation) {
    this.station = station;
    this.selectedStation.emit(station);
  }

  getPlaceholder(): string {
    if (this.warehouseSelected) {
      return "Select a station";
    } else {
      return "Select warehouse first";
    }
  }

  onDropDownShow(event) {
    this.stationDropDownOpen.emit(event);
  }
}
