<div>
    <h5>{{ selectedRole.roleName }}</h5>
    <ag-grid-angular
        #roleGrid
        style="width:100%;max-width: 1000px;margin-bottom: 20px"
        class="ag-theme-alpine"
        domLayout="autoHeight"
        suppressHorizontalScroll="true"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [tooltipShowDelay]="1500"
        (gridReady)="resizeGrid($event)"
        (cellClicked)="handleDeleteCellClick($event)"
        (gridSizeChanged)="resizeGrid($event)"
        >
    </ag-grid-angular>
</div>