<h5 class="p-mb-3">{{buttonLabel}} Shipper</h5>

<form [formGroup]="shipperForm" (submit)="onSubmit()">
    <div class="p-grid p-mt-3 p-mb-4">
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Shipper Name:</div>
                <input formControlName="shipperName" pInputText type="text" style="width: 100%;" maxlength="80"/>
                <div *ngIf="shipperForm.controls['shipperName'].invalid && (shipperForm.controls['shipperName'].dirty || shipperForm.controls['shipperName'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="shipperForm.controls.shipperName.errors?.required">
                        Field required.
                    </small>                
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Tracking URL format: <span><i [matTooltip]="'Use {0} where the tracking number should be in the shipper-provided URL.'" class="pi pi-exclamation-circle" style="cursor:pointer;"></i></span></div>
                <input formControlName="trackingURLFormat" pInputText type="text" style="width: 100%;" maxlength="255"/>                             
            </div>
        </div>
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Sort Order:</div>
                <input formControlName="sortOrder" pInputText type="text" style="width: 100%;" numeric decimals="0" maxlength="5"/>
                <div *ngIf="shipperForm.controls['sortOrder'].invalid && (shipperForm.controls['sortOrder'].dirty || shipperForm.controls['sortOrder'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="shipperForm.controls.sortOrder.errors?.required">
                        Field required.
                    </small>                
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="shipperForm.controls.sortOrder.errors?.min">
                        Value greater than 0.
                    </small>
                </div>
            </div>       
        </div>
        <mat-dialog-actions style="float: right" class="actions">
            <button style="margin-right: 15px;" mat-raised-button color="cor-default" mat-dialog-close>Cancel</button>
            <button mat-raised-button color="cor-primary" type="submit"
                [disabled]="!shipperForm.valid">Save</button>
        </mat-dialog-actions>
    </div>
</form>
