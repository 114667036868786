import { BiologicalLotPrefix, BiologicalActivePrefix } from './../receiving.constants';
import { FeatureModel } from '../../models/feature.model';
import { FeatureService } from '../../services/feature-switch-service/feature.service';

import { FeatureType } from '../../models/feature-type.enum';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { NavigationService } from 'src/app/core/modules/navigate/services/navigation.service';
import { CompoundLotPrefix, FormulationLotPrefix, receivingConstants } from '../receiving.constants';
import { ReceivingStateService } from '../services/receiving-state.service';
import { ReceivingService } from '../services/receiving.service';
import { RepoViewModel } from './repo.model';
import { dataAdapterServiceFactory } from '../services/data-adapters/material-data-adapter.service-provider';
import { ExternalServiceType } from '../models/enumerations';
import { HttpStatusCode } from 'src/app/models/response-models';
import { HttpErrorResponse } from '@angular/common/http';
import { LookupModel } from '../models/lookup.model';
import { DecantService } from 'src/app/services/decant-service/decant-service';
import { Warehouse } from 'src/app/models/decant-models';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'receiving-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  searchForm: FormGroup;
  lookupValues: LookupModel[];
  warehousesRes: Warehouse[];
  selectedWarehouse: Warehouse;

  biologicalactiveModel: RepoViewModel = { id: ExternalServiceType.Bionexus, name: 'Biological Active', selected: false, external: false };
  $loading = false;
  reposvm: RepoViewModel[] = [
    { id: ExternalServiceType.Coformulant, name: 'Coformulant', selected: false, external: false },
    { id: ExternalServiceType.Formulation, name: 'Formulation', selected: false, external: false },
    { id: ExternalServiceType.Compound, name: 'Compound', selected: false, external: false }
  ];

  reposLot: { [key: string]: ExternalServiceType } = {
    [FormulationLotPrefix]: ExternalServiceType.Formulation,
    [CompoundLotPrefix]: ExternalServiceType.Compound,
    [BiologicalLotPrefix]: ExternalServiceType.Bionexus,
    [BiologicalActivePrefix]: ExternalServiceType.Bionexus
  }
  // routes = receivingConstants.Routes;
  constructor(
    private router: Router,
    private receivingService: ReceivingService,
    private featureService: FeatureService,
    private messageService: MessageService,
    private receivingStateService: ReceivingStateService,
    private navService: NavigationService,
    private loadingService: LoadingService,
    private fb: FormBuilder,
    private decantService: DecantService
    // private dataAdapterService: DataAdapterService
  ) { }

  ngOnInit(): void {
    this.getWarehouseList();
    localStorage.setItem('breadcrumbMetadata', null);
    if (localStorage.getItem("feature") === null) {
      this.featureService.retrieveFeatures().subscribe(resp => {
        localStorage.setItem('feature', JSON.stringify(resp));
        this.loadValuesOnInit(resp);
      });
    } else {
      const feature = JSON.parse(localStorage.getItem("feature"));
      this.loadValuesOnInit(feature);
    }
  }

  getWarehouseList() {
    this.decantService.getWarehouses().subscribe({
      next: (res) => {
        this.warehousesRes = res
        const defaultWarehouseID = JSON.parse(localStorage.getItem("defaultWarehouseID"));
        if (defaultWarehouseID) {
          this.selectedWarehouse = this.warehousesRes.find(s => s.itemID === defaultWarehouseID);
          this.searchForm.patchValue({
            warehouse: this.selectedWarehouse
          });
        }
      }
    })

    this.decantService.getWarehouses().pipe(
      take(1),
      map((warehouses)=>{
        this.warehousesRes = warehouses
      })
      
    ).subscribe()
  }

  onWareHouseChange(warehouse: Warehouse) {
    this.selectedWarehouse = warehouse;
  }

  loadValuesOnInit(featureList: FeatureModel[]) {
    if (featureList.some(x => x.isActive && x.featureID === FeatureType.BionexusIntegration)) {
      this.reposvm.push(this.biologicalactiveModel);
    }

    this.navService.setBackButton({ visible: false });

    const formGroups = this.reposvm.map(r => {
      var repo = this.fb.group({
        id: this.fb.control(r.id),
        selected: this.fb.control(r.selected),
        external: this.fb.control(r.external),
        name: this.fb.control(r.name)
      });

      repo.valueChanges.subscribe(v => {
        this.repos.controls.map(r => {
          const thisControl = r.get('id').value;
          if (v.selected && thisControl !== v.id) {
            r.get('selected').setValue(false, { emitEvent: false });
          }
          return r;
        })
      });
      return repo;
    });

    this.searchForm = this.fb.group({
      warehouse: this.fb.control('', [Validators.required]),
      searchKeyWord: this.fb.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      repos: this.fb.array(formGroups),
      selectedMaterial: this.fb.control(null, [Validators.required]),
      isTsnSeach: this.fb.control(false),
    });

    this.searchForm.get('repos').valueChanges.subscribe(r => {
      const selectedMaterialControl = this.repos.controls.find(c => c.value.selected == true)
      this.searchForm.get('selectedMaterial').setValue(selectedMaterialControl?.value);
      this.disableTSNCheckbox();
    });

    this.searchForm.get('searchKeyWord').valueChanges.subscribe(x => {
      const keyword = x as string;
      if (keyword && keyword.length >= 2) {
        let repoId = 0;
        if (keyword.toUpperCase().startsWith(receivingConstants.BarcodeScanType.Compound)) {
          repoId = ExternalServiceType.Compound;
        } else if (keyword.toUpperCase().startsWith(receivingConstants.BarcodeScanType.Formulation)) {
          repoId = ExternalServiceType.Formulation;
        } else if (keyword.toUpperCase().startsWith(receivingConstants.BarcodeScanType.BiologicalActive) || keyword.toUpperCase().startsWith(receivingConstants.BarcodeScanType.BiologicalLot)  || keyword.toUpperCase().startsWith(receivingConstants.BarcodeScanType.BiologicalActive2)) {
          repoId = ExternalServiceType.Bionexus;
        }

        if (repoId > 0) {
          var repoToSelect = this.repos.controls.find(x => x.get('id').value === repoId);
          if (repoToSelect) {
            repoToSelect.get('selected').setValue(true, { emitEvent: true });
          }
        }
      }
    });

    document.getElementById("txtMaterial").focus();
    this.receivingStateService.resetReceivingState();
    this.preloadLookupValues();
  }

  get repos(): FormArray {
    return this.searchForm.get('repos') as FormArray ?? this.fb.array([]);
  }

  onSubmit() {
    //Clear if there are any message from previous request.
    this.messageService.clear();

    if (this.searchForm.valid) {
      const isBiologicalActiveSearch = this.searchForm.value.repos.some(x => x.id === ExternalServiceType.Bionexus && x.selected === true);
      this.$loading = true;
      this.loadingService.show("Searching...");
      if (!isBiologicalActiveSearch) {
        if (!this.isLotSearch(this.searchForm.value.searchKeyWord, this.searchForm.value.isTsnSeach)) {
          this.searchMaterial();
        } else {
          this.searchLot();
        }
      }
      else {
        if (this.searchForm.value.searchKeyWord.startsWith(receivingConstants.BarcodeScanType.BiologicalActive) || this.searchForm.value.searchKeyWord.startsWith(receivingConstants.BarcodeScanType.BiologicalActive2)) {
          this.searchMaterial();
        } else if (this.searchForm.value.searchKeyWord.startsWith(receivingConstants.BarcodeScanType.BiologicalLot)) {
          this.searchLot();
        }
        else {
          // Search Synonym
          this.searchMaterial();
        }
      }
    }
  }

  onClickOfPreviousSessions() {
    this.router.navigate([receivingConstants.Routes.PreviousSessions]);
  }

  searchMaterial() {

    this.receivingService.searchMaterial(`${this.searchForm.value.searchKeyWord}`, this.searchForm.value.selectedMaterial.id).then(materialList => {
      if (materialList && materialList.length > 0) {
        this.receivingStateService.setMaterialList(materialList);
        this.router.navigate([receivingConstants.Routes.MaterialSelection]);
      } else {
        this.$loading = false;
        this.messageService.add({ key: 'searchkeyword', severity: 'warn', detail: `No result found with keyword "${this.searchForm.value.searchKeyWord}" .` });
      }
    }).finally(() => {
      this.$loading = false;
      this.loadingService.clearMessage();
      document.getElementById("txtMaterial")?.focus();
      localStorage.setItem("breadcrumbMetadata", JSON.stringify(this.selectedWarehouse));
    })
  }

  searchLot() {
    this.receivingService.searchLot(`${this.searchForm.value.searchKeyWord}`, this.searchForm.value.selectedMaterial.id, this.searchForm.value.isTsnSeach).then((lot) => {
      if (lot) {
        let dataAdapter = dataAdapterServiceFactory({ materialType: lot.materialTypeId }, this.receivingService, this.receivingStateService);
        this.receivingStateService.setReceivingStateModel({
          address: dataAdapter.toAddressViewModel(lot),
          material: dataAdapter.toMaterialViewModel(lot),
          supplier: dataAdapter.toSupplierViewModelFromLot(lot)
        });
        this.router.navigate([receivingConstants.Routes.Validate]);
      } else {
        this.messageService.add({ key: 'searchkeyword', severity: 'warn', detail: `No result found for Lot# "${this.searchForm.value.searchKeyWord}" .` });
      }
    }).catch((err: HttpErrorResponse) => {
      if (err.status == HttpStatusCode.NOT_FOUND)
        this.messageService.add({ key: 'searchkeyword', severity: 'warn', detail: `No result found with keyword "${this.searchForm.value.searchKeyWord}" .` });
      document.getElementById("txtMaterial").focus();
    }).finally(() => {
      this.$loading = false;
      this.loadingService.clearMessage();
      localStorage.setItem("breadcrumbMetadata", JSON.stringify(this.selectedWarehouse));
    })
  }

  isLotSearch(keyword: string, tsn?: boolean): boolean {
    let tokens = keyword.split('-');
    let prefix = tokens[0].toUpperCase();
    //only when there is a hyphen and the first char matches an material
    return (tokens.length > 1 && (this.reposLot[prefix] === this.searchForm.value.selectedMaterial.id) || tsn === true) //compound or formulation lot
  }

  private disableTSNCheckbox() {
    if (this.searchForm.value?.selectedMaterial?.id == ExternalServiceType.Coformulant) {
      this.searchForm.get('isTsnSeach').setValue(false);
      this.searchForm.get('isTsnSeach').disable();
    } else {
      this.searchForm.get('isTsnSeach').enable();
    }
  }

  async preloadLookupValues() {
    this.lookupValues = await this.receivingService.getLookupValues(`${receivingConstants.LookupTypes.Countries}`);
    this.receivingStateService.setCountries(this.lookupValues);
  }
}
