<h5>
    Documents for {{orderItem?.containerLabelFmt}}
</h5>

<div class="doublespacer"></div>

<div class="p-grid p-ml-1" style="align-items:center">
    <div class="p-col-6 p-md-6 p-lg-4" *ngIf="documentTypes && documentTypes.length > 0">
        <div class="p-grid" style="align-items:center">
            <div class="p-col-auto">
                <label for="documentType" class="p-text-bold">Document Type</label>
            </div>
            <div class="p-col">
                <p-dropdown placeholder="Select a Document Type" ngDefaultControl [(ngModel)]="selectedDocumentType"
                    [options]="documentTypes" optionLabel="name" [style]="{'width':'100%'}"></p-dropdown>
            </div>
        </div>
    </div>
    <div class="p-col-6 p-md-6 p-lg-4" *ngIf="languages && languages.length > 0">
        <div class="p-grid" style="align-items:center">
            <div class="p-col-auto">
                <label for="language" class="p-text-bold">Language</label>
            </div>
            <div class="p-col">
                <p-dropdown placeholder="Select a Language" ngDefaultControl [(ngModel)]="selectedLanguage"
                    [options]="languages" optionLabel="name" [style]="{'width':'100%'}"></p-dropdown>
            </div>
        </div>
    </div>
    <div class="p-col-auto">
        <button mat-raised-button type="submit" color="cor-primary" (click)="onFilter()">Filter</button>
    </div>
    <div class="p-col-auto p-ml-3">
        <button mat-raised-button type="submit" color="cor-secondary" (click)="resetData()">Clear</button>
    </div>
</div>
<mat-dialog-content>
    <ag-grid-angular #grid style="margin-top: 20px;margin-bottom: 20px; overflow-y: auto; max-height: 45vh; width: 99.8%"
        domLayout='autoHeight' class="ag-theme-alpine" tooltipShowDelay="0" suppressCellSelection="true"
        suppressRowClickSelection="true" suppressHorizontalScroll="true" [rowData]="rowData" [columnDefs]="columnDefs"
        [gridOptions]='gridOptions' (gridReady)="gridReady($event)" [frameworkComponents]="frameworkComponents"
        (gridSizeChanged)="resizeGrid($event)">
    </ag-grid-angular>
</mat-dialog-content>

<mat-dialog-actions style="float: right;">
    <button class="close" mat-raised-button color="cor-primary" (click)="close()">Close</button>
</mat-dialog-actions>