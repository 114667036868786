<div *ngIf="readyToDisplay">
    <h4 *ngIf='currentContainerCount < totalContainerCount'>Container Complete</h4>
    <h4 *ngIf='currentContainerCount === totalContainerCount'>Chemical Complete</h4>
    <div *ngIf="readyToDisplay && currentContainerCount < totalContainerCount" mat-dialog-content>
        <p class="mat-body">Please seal child container. Continue to the next container for this chemical.
        </p>
    </div>
    <div *ngIf="readyToDisplay && currentContainerCount === totalContainerCount" mat-dialog-content>
        <p class="mat-body">Please seal the last child container. Continue to reweigh the parent container.
        </p>
    </div>
    <div mat-dialog-actions class="float-right">
        <button mat-button mat-raised-button color="cor-default" class="float-right" cdkFocusInitial color="cor-primary"
            (click)="onContinue()" id="btn-continue-chemical-complete">Continue</button>
    </div>
</div>