import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'package-selection-cell',
  template: `<p-dropdown appendTo="body" panelStyleClass='selection-cell-minWidthOverride' [options]="params.data.packageCodes" [(ngModel)]="params.data.selectedPackageCode" optionLabel="packageCode" (onChange)="packageCodeChange()"></p-dropdown>`,
  styles: [],
})

export class PackageDropdownRenderer implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
  }

  refresh(): boolean {
    return false;
  }

  packageCodeChange(): void {
    this.gridApi.dispatchEvent({ type: 'packageChange', value: this.params.data });
  }
}