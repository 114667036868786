import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, first } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { MaterialDataAdapterService } from '../../services/data-adapters/material-data-adapter.service';
import { dataAdapterServiceFactory } from '../../services/data-adapters/material-data-adapter.service-provider';
import { ReceivingStateService } from '../../services/receiving-state.service';
import { ReceivingService } from '../../services/receiving.service';

@Component({
  selector: 'app-receiving-session-base',
  template: `no-content`,
  styleUrls: ['./receiving-session-base.component.scss']
})
export class ReceivingSessionBaseComponent implements OnInit {

  public sessionId: number;
  public isLoading = false;
  dataAdapter: MaterialDataAdapterService;

  constructor(
    private service: ReceivingService,
    private stateService: ReceivingStateService,
    private route: ActivatedRoute,
    public spinner: LoadingService
  ) {

    this.sessionId = +this.route?.snapshot?.params['id']
  }

  async ngOnInit() {
    if (this.sessionId > 0) {
      this.isLoading = true;
      this.spinner.show('Loading session...');
      await this.service.getSession(this.sessionId, true).then((session) => {
        console.log('retrieved session from base component');
        this.dataAdapter = dataAdapterServiceFactory({ materialType: session.materialTypeId }, this.service, this.stateService);
        const materialModel = this.dataAdapter.getSessionToMaterialModel(session);
        this.stateService.setReceivingStateModel({
          address: null,
          material: materialModel,
          supplier: null
        });
        this.stateService.setReceivingSession(session);
      })
        .catch(e => console.error(e))
        .finally(() => {
          this.isLoading = false;
          this.spinner.clearMessage();
        }
        );
    }
  }

}
