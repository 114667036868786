<div class="flex-grid">
    <div class="col">
        <h4>Request Coordination - Processed Requests</h4>
    </div>
    <div class="col" style="text-align: end">
        <mat-slide-toggle [checked]="gridMode" (change)="onViewModeChange($event)">
           Grid View
        </mat-slide-toggle>
    </div>
</div>

<div class="spacer"></div>

<div class="flex-grid">
    <div class="col">
        <app-order-filters #appFilterOrder (modelChange)="handleFilterUpdate($event)"></app-order-filters>
        <div class="p-grid p-justify-center p-mt-2">
            <button id="search-request-order-processed" class="submit p-mr-2" mat-raised-button color="cor-primary" [disabled]='hasFilterModelChanged'
                (click)='searchButtonClicked()'>Search Requests</button>
            <button id="clear-filters-order-processed" class="submit" mat-raised-button color="cor-primary" [disabled]="disableClearFilters"
                (click)='onClearFilters()'>Clear Filters</button>
        </div>
    </div>
</div>

<div class="spacer"></div>

<div class="flex-grid" *ngIf="gridMode">
    <div class="spacer"></div>
    
    <mat-card class="order-card-container">
    
        <div class="spacer"></div> 
    <app-ag-grid-custom-paginator (paginatorCommand)="handlePaginatorCommand($event)" [paginatorModel]="paginatorModel"
        [showPageSizes]="true">
    </app-ag-grid-custom-paginator>
    
        <div class="flex-grid">
            <ag-grid-angular id="myGrid" style="width:100%; height:540px;" class="ag-theme-alpine" rowModelType="infinite"
                domLayout="normal" tooltipShowDelay="0" suppressCellSelection="true" alwaysShowVerticalScroll="true"
                [pagination]="true" [suppressPaginationPanel]="true" [paginationPageSize]="25" [cacheOverflowSize]="2"
                [maxConcurrentDatasourceRequests]="2" [infiniteInitialRowCount]="1" [columnDefs]="columnDefs"
                [cacheBlockSize]="100" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                [frameworkComponents]="frameworkComponents"
                (gridReady)="onGridReady($event)" (gridSizeChanged)="resizeGrid($event)" [gridOptions]="gridOptions"
                (sortChanged)="updateCurrentPage()" (cellClicked)="handleCellClicked($event)">
            </ag-grid-angular>
        </div>
    
        <app-ag-grid-custom-paginator (paginatorCommand)="handlePaginatorCommand($event)" [paginatorModel]="paginatorModel"
            [showPageSizes]="true">
        </app-ag-grid-custom-paginator>
    
    </mat-card>
    
</div>

<div class="spacer"></div>

<div class="flex-grid" *ngIf="!gridMode">
    <div class="spacer"></div>
    <mat-card class="order-card-container">

        <div class="spacer"></div>

        <mat-card-content class="messageContainer" *ngIf="inProcessOrdersLoaded && !inProcessOrders.length">
            <div class="spacer"></div>
            <div class="cardListMessage">No matches for selected filter criteria</div>
        </mat-card-content>

        <mat-card-content *ngIf="inProcessOrdersLoaded">
            <app-order-card *ngFor="let order of inProcessOrders" [headerColor]="'lightBlue'" [order]="order">
            </app-order-card>
        </mat-card-content>

    </mat-card>
</div>