import { CancellationReasonsService } from './../../services/cancellation-reasons/cancellation-reasons.service';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { CancellationReasons } from './../../models/cancellation-reasons';
import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../shared/services/message-queue.service';
import { MatDialog } from '@angular/material/dialog';
import { EditCancellationReasonsComponent } from './edit-cancellation-reasons/edit-cancellation-reasons.component';
import { CancellationReasonsModalComponent } from '../cancellation-reasons-modal/cancellation-reasons-modal.component';

@Component({
  selector: 'app-manage-cancellation-reasons',
  templateUrl: './manage-cancellation-reasons.component.html',
  styleUrls: ['./manage-cancellation-reasons.component.scss']
})
export class ManageCancellationReasonsComponent implements OnInit {

  private gridApi;
  public columnDefs: any;
  public frameworkComponents: any;
  public gridOptions: GridOptions;
  public subscriptions: Subscription;
  public reasons: CancellationReasons[];
  public overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">No records to display.</span>';

  constructor(
    private readonly cancellationReasonsService: CancellationReasonsService,
    private readonly messageService: MessageService,
    private dialog: MatDialog) {

    this.subscriptions = new Subscription();
    this.frameworkComponents = {
      EditCancellationReasonRenderer: EditCancellationReasonsComponent
    };

    this.columnDefs = [
      { headerName: 'Name', field: 'name' },
      { headerName: '', cellRenderer: 'EditCancellationReasonRenderer', cellClass: 'button-edit', width: 50 }
    ];
  }

  ngOnInit(): void {
    this.fillGrid();
    this.subscriptions.add(
      this.messageService.message.subscribe((modelData) => {
        this.fillGrid();
      })
    );
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  public fillGrid() {
    this.cancellationReasonsService
      .getCancellationReasons()
      .subscribe((resp) => {
        this.reasons = resp;
      });
  }

  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  addNewReason(): void {
    this.dialog.open(CancellationReasonsModalComponent, {
      height: '410px',
      width: '750px',
      disableClose: true
    }).afterClosed().subscribe((res: boolean) => {
      if (!res) { return; }
      this.fillGrid();
    });
  }
}