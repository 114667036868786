import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, Form, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { filter, finalize, first, map } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { NavigationService } from 'src/app/core/modules/navigate/services/navigation.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ReceivingSessionBaseComponent } from '../core/components/receiving-session-base.component';
import { PrintState, PrintStatusModel } from '../models/print-status.model';
import { Printer, PrinterType } from '../models/printer.model';
import { SessionModel, StatusType } from '../models/session.model';
import { SignalREvent, SignalREventType } from '../models/signalR.model';
import { SessionContainerWeight, SessionContainerWeightViewModel } from '../models/weighing.model';
import { receivingConstants, SignalRServerTimeOut } from '../receiving.constants';
import { ReceivingStateService } from '../services/receiving-state.service';
import { ReceivingService } from '../services/receiving.service';
import { SignalRPrintServiceSubscription } from '../services/signalr-subscription.service';
import { HUB_SUBS_SERVICE_TOKEN, SignalRService } from '../services/signalr.service';
import { LabelPrinterModel } from '../models/label-printer-model';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  providers: [
    {
      provide: HUB_SUBS_SERVICE_TOKEN, useClass: SignalRPrintServiceSubscription,
    },
    SignalRService
  ]
})
export class LabelComponent extends ReceivingSessionBaseComponent implements OnInit, OnDestroy {
  @ViewChild('custom') customLabels: ElementRef;

  printers: LabelPrinterModel[];
  selectedPrinterId: string;
  labelsPrintedCorrectly: boolean = false;
  receivingSession: SessionModel;
  printersLoading: boolean = false;
  signalRServiceSubscription: Subscription;
  stateSubscription: Subscription;
  containerWeights: SessionContainerWeightViewModel[];
  isPrintingInProgress: boolean = false;
  isSessionComplete: boolean = false;
  interval;
  time: number;
  printStatus = new Map();
  printSettings: number = 0;
  printingForm: FormGroup;
  StatusType = StatusType;
  displayPrintLabel: boolean = false;
  labelsAttemptedToPrint: boolean = false;

  constructor(
    private receivingService: ReceivingService,
    private receivingStateService: ReceivingStateService,
    private router: Router,
    private navService: NavigationService,
    private loadingService: LoadingService,
    private signalRService: SignalRService,
    private confirmationService: ConfirmationService,
    private toastr: ResponseHandlingService,
    route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    super(receivingService, receivingStateService, route, loadingService);
    this.loadingService.setMessageDelay(0);
    this.navService.setBackButton({ visible: true, routerLink: this.sessionId ? receivingConstants.Routes.PreviousSessions : receivingConstants.Routes.DocUpload });
  }

  private loadPrinters() {
    this.printersLoading = true;
    this.receivingService.getLabelPrinters().then((labelPrinters) => {
      this.printers = labelPrinters.filter((printer) => printer.isReceivingPrinter);
      const defaultPrinter = this.printers[0];
      if (defaultPrinter) {
        this.selectedPrinterId = defaultPrinter.labelPrinterID.toString();
      }
    }).finally(() => {
      this.printersLoading = false;
    })
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.printingForm = this.initPrintForm();
    this.stateSubscription = this.receivingStateService
      .getReceivingSession()
      .subscribe(async session => {
        if (!session) {
          return;
        }
        this.isSessionComplete = session.statusType == StatusType.Completed
        this.loadPrinters();  //load printers here since printer selection depends on the session
        this.containerWeights = this.toContainerViewModel(session.sessionContainerWeights);
        this.receivingSession = session;
        this.labelsPrintedCorrectly = this.receivingSession.areLabelsPrinted;
        this.subscribeToPrintNotifcations();
      });
  }

  initPrintForm(): FormGroup {
    var f = this.fb.group({
      printType: [0, Validators.required],
      custom: ['']
    });

    let self = this;
    f.get('custom').setValidators(
      [
        Validators.pattern(/^[0-9]{0,10}(?:\-[0-9]{0,10})?(?:,\s?[0-9]{1,10}(?:\-[0-9]{0,10})?)*$/),
        this.requiredIfValidator(() => f.get('printType').value == 1),
        this.validRangeIfValueValidator(() => f.get('printType').value == 1, this.receivingSession?.sessionContainerWeights, { containerRange: 'Incorrect range' }).bind(self)
      ]);

    f.get('printType').valueChanges.subscribe(v => {
      f.get('custom').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });

    f.get('custom').valueChanges.subscribe(v => {
      if (v) {
        f.get('printType').setValue(1, { onlySelf: true, emitEvent: false })
        f.get('custom').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        f.get('custom').markAllAsTouched();
      }
    });

    return f;
  }

  private toContainerViewModel(weights: SessionContainerWeight[]): SessionContainerWeightViewModel[] {

    if (weights.length > 6) {
      return [].concat(weights.slice(0, 4)
        .map(scw => <SessionContainerWeightViewModel>{ catalogInventoryItemLabel: scw.catalogInventoryItemId.toString() }),
        [<SessionContainerWeightViewModel>{ catalogInventoryItemLabel: '...' }],
        [<SessionContainerWeightViewModel>{ catalogInventoryItemLabel: weights[weights.length - 1].catalogInventoryItemId.toString() }]);
    }
    return weights
      .map(scw => <SessionContainerWeightViewModel>{ catalogInventoryItemLabel: scw.catalogInventoryItemId.toString() });

  }

  private subscribeToPrintNotifcations(): void {
    this.signalRServiceSubscription = this.signalRService.events.subscribe(event => {
      if (event.type == SignalREventType.PrintStatus) {
        this.reportPrintStatus(event);
      } else {
        console.log(`Event from signalRService: event.type:${event.type}, event.data: ${event.data}`);
        switch (event.type) {
          case SignalREventType.Disconnected:
            if (this.isPrintingInProgress)
              console.warn(`SignalR has been disconnected. Service will attempt to reconnect...`);
            break;
          case SignalREventType.Connected:
            console.info(`SignalR has connected`);
            break;
          default:
            console.info(`SignalR event: ${event.type.toString()}`);
        }
      }
    });
  }

  async onCompleteReceiptClick() {
    this.loadingService.show(`Completing session...`);
    this.receivingService.patchSession({ sessionID: this.receivingSession.sessionID, statusType: StatusType.Completed })
      .then(s => {
        this.loadingService.clearMessage();
        localStorage.removeItem('breadcrumbMetadata');
        this.toastr.showSuccess('Session completed succesfully')
        this.router.navigate([receivingConstants.Routes.Search]);
      }, () => {
        this.loadingService.clearMessage();
      }
      );
  }

  UpdatePrintStatus() {
    this.receivingService.patchSession({ sessionID: this.receivingSession.sessionID, areLabelsPrinted: true })
      .then(s => {
      }, () => {
      }
      );
  }

  ngOnDestroy(): void {

    if (this.signalRServiceSubscription) {
      this.signalRService.stopConnection();
      this.signalRServiceSubscription.unsubscribe();
    }

    if (this.stateSubscription) this.stateSubscription.unsubscribe();

    this.endTimer();

  }

  private reportPrintStatus(event: SignalREvent) {

    const status: PrintStatusModel = event.data;
    console.log("Print Status: ", status);
    switch (status.printState) {
      case PrintState.Starting:
        this.isPrintingInProgress = true
        this.printStatus.set(PrintState.Starting, true);
        this.loadingService.show(`Starting printing ${status.totalLabels} label(s)`, true, `Taking too long? if your labels are printed you can:`, 'click to close', this.loaderCallback.bind(this));
        break;
      case PrintState.InProgress:
        if (this.isPrintingInProgress) {
          this.printStatus.set(PrintState.InProgress, true);
          this.loadingService.show(`Printing Label...${status.currentLabel} of ${status.totalLabels}`, true, `Taking too long? if your labels are printed you can:`, 'click to close', this.loaderCallback.bind(this));
        }
        break;
      case PrintState.Finished:
        this.printStatus.set(PrintState.Finished, true);
        this.loadingService.show(`Finished printing ${status.totalLabels} label(s)`);
        this.signalRService.stopConnection();
        this.printingEnded();
        this.confirmPrintingState();
        break;
      case PrintState.Aborted:
        this.printStatus.set(PrintState.Aborted, true);
        this.loadingService.show(`Unable to finish printing. Aborting`);
        this.signalRService.stopConnection();
        this.printingEnded();
        setTimeout(() => {
          this.loadingService.clearMessage();
        }, 2 * 1000);
        break;
    }
  }

  private confirmPrintingState() {
    this.loadingService.clearMessage();
    this.confirmationService.confirm({
      key: 'label',
      message: 'Did the labels print correctly?',
      header: 'Label Printing completed',
      icon: 'pi pi-info-circle',
      accept: async () => {
        this.labelsPrintedCorrectly = true;
        this.receivingSession.areLabelsPrinted = this.labelsPrintedCorrectly;
        this.UpdatePrintStatus();
      },
      reject: () => {
        this.labelsPrintedCorrectly = false;
        this.labelsPrintedCorrectly = this.receivingSession.areLabelsPrinted;
      }
    });
  }

  printLabels() {
    if (this.receivingSession.areLabelsPrinted || this.labelsAttemptedToPrint || this.sessionId) {
      this.printingForm = this.initPrintForm();
      this.displayPrintLabel = true;  //display print dialog
    }
    else {
      //print
      this.doPrint();
    }
  }

  printJobHasStarted: boolean;

  doPrint() {
    this.labelsAttemptedToPrint = true;
    this.closePrintDialog();
    this.loadingService.show('Preparing printing...', true, `Taking too long? if your labels are printed you can:`, 'click to close', this.loaderCallback.bind(this));
    this.printJobHasStarted = false;
    this.signalRService.startConnection();
    let signalRConnectionSubs = this.signalRService.events
      .pipe(
        filter(e => e.type == SignalREventType.ConnectionIdObtained),
        first()
      )
      .subscribe(() => {
        this.performPrint();
      });

    //if after sometime, signalr has not obtained a connection id, suspend and launch printing anyway (print status wont be there)
    setTimeout(() => {
      signalRConnectionSubs.unsubscribe();
      if (!this.printJobHasStarted) //force print
        this.performPrint();
    },
      5000 //five seconds grace
    )
  }

  private performPrint() {

    if (this.isPrintingInProgress) //just in case
      return;

    this.printJobHasStarted = true;
    this.loadingService.show('Printing Labels...', true, `Taking too long? if your labels are printed you can:`, 'click to close', this.loaderCallback.bind(this));
    this.isPrintingInProgress = true;
    //this.startTimer();
    this.printStatus.clear();
    //This API call will just invoke the print action
    this.receivingService.printLabel(this.receivingSession.sessionID, this.selectedPrinterId, this.printingForm?.value, this.signalRService.connectionId)
      .subscribe();
  }

  hasPrintingStatusBeingReported(): boolean {
    return this.printStatus.has(PrintState.InProgress) || this.printStatus.has(PrintState.Starting) || this.printStatus.has(PrintState.Finished) || this.printStatus.has(PrintState.Aborted)
  }

  printingEnded() {
    this.isPrintingInProgress = false;
    this.endTimer();
    this.signalRService.stopConnection();
  }

  startTimer() {
    console.log('Starting timer...');
    this.time = 0;
    this.interval = setInterval(() => {
      this.time += 1000;
      if (this.time > (SignalRServerTimeOut / 4)) {
        if (this.isPrintingInProgress) {
          console.log(`time is up... sending secondary message...`);
          this.loadingService.setSecondaryMessage(`Taking too long? if your labels are printed you can:`, 'click to close', this.loaderCallback.bind(this));
        }
        this.time = 0;
        this.endTimer();
      }
    }, 1000);
  }

  endTimer() {
    if (this.interval)
      clearInterval(this.interval);
  }

  loaderCallback = (): void => {
    this.loadingService.clearMessage();
    this.printingEnded();
    this.confirmPrintingState();
  }

  closePrintDialog() {
    this.displayPrintLabel = false;
  }

  focusCustomLabels() {
    this.customLabels?.nativeElement.focus();
    setTimeout(() => {
      this.customLabels?.nativeElement.select();
    }, 1);
  }

  private validRangeIfValueValidator(predicate: any, containers: SessionContainerWeight[], error: ValidationErrors) {
    return ((formControl: AbstractControl): { [key: string]: any } => {

      if (!formControl.value) {
        return null;
      }

      if (!predicate())
        return null;

      let value = formControl.value as string;
      let valid = true;

      if (value) {
        //check if range is actual in the defined set of containers
        containers = this.receivingSession.sessionContainerWeights;
        if (!containers || containers.length == 0)
          return error;

        let first = containers[0].catalogInventoryItemId;
        let last = containers[containers.length - 1].catalogInventoryItemId;

        let tokens = value.split(',');
        tokens.forEach(t => {
          var items = t.split('-');

          if (!isNaN(parseFloat(items[0]))) {
            if (+items[0] < first || +items[0] > last) {
              valid = false;
              return;
            }
          }

          if (!isNaN(parseFloat(items[1]))) {
            if (+items[1] > last || +items[1] < first) {
              valid = false;
              return;
            }
          }
        });
      }

      return valid ? null : error;

    })
  }

  private requiredIfValidator(predicate) {
    return (formControl => {
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    })
  }

}
