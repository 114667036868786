import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisposalReason } from '../../models/disposal-reason-model';
import { Subscription } from 'rxjs';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';

export interface IDisposeContainerConfirmModalResult {
  isConfirm: boolean;
  disposalReason?: string;
  inventoryItemNumber?: string;
  comments?: string
}

@Component({
  selector: 'app-dispose-container-confirm-modal',
  templateUrl: './dispose-container-confirm-modal.component.html',
  styleUrls: ['./dispose-container-confirm-modal.component.scss']
})
export class DisposeContainerConfirmModalComponent implements OnInit {
  private subscriptions = new Subscription();
  disposalReasons: DisposalReason[] = []
  selectedDisposalReason: string;
  comments: string;
  title: string;
  message: string;
  confirmButtonLabel: string;
  scanNewParent: boolean;
  containerInput = '';
  hideScan: boolean = false;

  constructor(public dialogRef: MatDialogRef<DisposeContainerConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DisposeContainerConfirmModal,
    private inventoryService: InventoryService) {
    this.title = data.title;
    this.message = data.message;
    this.confirmButtonLabel = data.confirmButtonLabel;
    this.scanNewParent = data.scanNewParent;
    this.hideScan = data.hideScan;
  }

  ngOnInit(): void {
    this.getDisposalReasons();
  }

  private getDisposalReasons() {
    this.subscriptions.add(this.inventoryService.getDisposalReasons()
      .subscribe((disposalReasons: DisposalReason[]) => {
        this.disposalReasons = disposalReasons;
      }));
  }

  onConfirm(): void {
    const result: IDisposeContainerConfirmModalResult = {
      isConfirm: true,
      disposalReason: this.selectedDisposalReason,
      comments: this.comments
    }
    if (this.scanNewParent) {
      result.inventoryItemNumber = this.containerInput;
    }
    this.dialogRef.close(result);
  }

  onDismiss(): void {
    const result: IDisposeContainerConfirmModalResult = {
      isConfirm: false
    }
    this.dialogRef.close(result);
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      setTimeout(() => this.subscriptions.unsubscribe(), 2000);
    }
  }
}


export class DisposeContainerConfirmModal {

  constructor(public title: string, public message: string, public confirmButtonLabel: string, public scanNewParent?: boolean, public hideScan?: boolean) {
  }
}