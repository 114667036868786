import { Component, OnInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-grid-lot-selection-numeric-editor',
  templateUrl: './ag-grid-lot-selection-numeric-editor.component.html',
  styleUrls: ['./ag-grid-lot-selection-numeric-editor.component.scss']
})
export class AgGridLotSelectionNumericEditorComponent implements ICellEditorAngularComp {

  min: number;
  max: number;
  params;
  gridApi: any;

  constructor() { }
  getValue() {
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.gridApi = params.api;
    this.min = params.data.min;
    this.max = params.data.max;
  }

  change() {
    if (this.params.data[this.params.colDef.field] > this.max) {
      this.params.data[this.params.colDef.field] = this.max;
    } else {
      this.gridApi.dispatchEvent({ type: 'inputChangeEvent' });
    }
  }

  add() {
    this.params.data[this.params.colDef.field]++;
    this.gridApi.dispatchEvent({ type: 'inputChangeEvent' });
  }

  subtract() {
    this.params.data[this.params.colDef.field]--;
    this.gridApi.dispatchEvent({ type: 'inputChangeEvent' });
  }

  ngOnInit(): void {
  }

}
