import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'shared-docs-action-renderer',
  templateUrl: './action-renderer.component.html',
  styleUrls: ['./action-renderer.component.scss']
})
export class ActionRendererComponent implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
  }

  onDelete() {
    this.gridApi.dispatchEvent({ type: 'deleteEvent', value: this.params });
  }

  onDownload() {
    this.gridApi.dispatchEvent({ type: 'downloadEvent', value: this.params });
  }

  refresh(): boolean {
    return false;
  }

}
