import { Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';

import { Configuration } from '../../configuration';

@Injectable()

export class AppInsightsService {
    private config: Microsoft.ApplicationInsights.IConfig = {
    instrumentationKey: Configuration.APP_INSIGHTS_KEY
};

constructor() {
    if (!AppInsights.config) {  
        AppInsights.downloadAndSetup(this.config);
    }
}

logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number) {
    AppInsights.trackPageView(name, url, properties, measurements, duration);
}

logEvent(name: string, properties?: any, measurements?: any) {
    AppInsights.trackEvent(name, properties, measurements);
}

logException(exception: Error, severityLevel?: number) {
    AppInsights.trackException(exception, null, null, null, severityLevel);
  }
}