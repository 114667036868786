import { AddressViewModel, Country } from '../../models/address.model';
import { ExternalServiceType } from '../../models/enumerations';
import { LookupModel, LookupValuesModel } from '../../models/lookup.model';
import { LotViewModel, MaterialModel, MaterialViewModel } from '../../models/material.model';
import { ReceivingStateViewModel, SessionModel } from '../../models/session.model';
import { SupplierModel, SupplierViewModel } from '../../models/supplier.model';
import { DataEntryViewModel } from '../../models/view-models/dataentry-view.model';
import { SDSConsumer } from '../../models/view-models/status-watcher.model';
import { ValidationViewModel } from '../../models/view-models/validation-view.model';
import { receivingConstants, getDateFromDateTime } from '../../receiving.constants';
import { ReceivingStateService } from '../receiving-state.service';
import { ReceivingService } from '../receiving.service';
import { IMaterialDataAdapter } from './material-data-adapter';

export class FormulationDataAdapter implements IMaterialDataAdapter {
  lookupValues: LookupModel[]

  constructor(private receivingService: ReceivingService, private receivingStateService: ReceivingStateService) {

  }
  toDataEntryViewModel(model: ReceivingStateViewModel): DataEntryViewModel {
    let defaultYears = receivingConstants.DefaultYearsToExpiration;
    if (!model.material.isGLP && !model.material.lotNumber && model.material.metadata?.ShelfLifeYears)
      defaultYears = +model.material.metadata?.ShelfLifeYears;

    return {
      disablePeroxideForming: false,
      yearsToExpiration: defaultYears
    }
  }

  toSupplierViewModelFromLot(model: MaterialModel): SupplierViewModel {
    return this.toSupplierViewModel(model);
  }

  toValidationViewModel(model: ReceivingStateViewModel): ValidationViewModel {
    return {
      materialName: model.material?.materialName,
      alternateName: model.material?.alternateName,
      lotNumber: model.material?.lotNumber,
      isGLP: model.material?.isGLP,
      SDS: model.material?.SDS,
      COA: model.material?.COA,
      checkForSDS: false,
      shouldDisableGLPControl: false,
      supplier: model.supplier?.IsCreateNewLot === true ? receivingConstants.CortevaName : model.supplier?.name,
      isLoading: false,
      address: model.address,
      sdsModel: null
    }
  }

  ToMaterialViewModelFromLot(model: MaterialViewModel): MaterialViewModel {
    let lotModel: LotViewModel = undefined;
    if (model.selectedSupplier) {
      lotModel = (model.selectedSupplier.metadata as LotViewModel);
    }
    if (lotModel) {
      let manufacturerDate = lotModel.glpCertificationDate ? lotModel.glpCertificationDate : lotModel.createDate;
      manufacturerDate = getDateFromDateTime(manufacturerDate?.toString());
      let expirationDate = lotModel.glpStatus?.toString() === "STOCK" ? lotModel.expirationDate?.toString() : lotModel.glpRecertificationDate?.toString();
      expirationDate = getDateFromDateTime(expirationDate?.toString());
      let countryOfOrigin = (this.loadCountryOfOrigin(lotModel.countryOfOrigin) !== null) ? (this.loadCountryOfOrigin(lotModel.countryOfOrigin)) : null;
      model.manufacturerDate = manufacturerDate?.toString();
      model.lotId = lotModel.lotId;
      model.lotNumber = lotModel.lotNumber;
      model.disableManufaturerLodIdControl = lotModel.lotNumber !== undefined;
      model.GLP = lotModel.glpStatus?.toString();
      model.certificationDate = getDateFromDateTime(lotModel.glpCertificationDate);
      model.recertificationDate = getDateFromDateTime(lotModel.glpRecertificationDate);
      model.reachComplaint = lotModel.reachApproved ? Boolean(lotModel.reachApproved) : false;
      model.supplierName = lotModel.preparer?.toString();
      model.isGLP = lotModel.glpStatus?.toString() === "STOCK" ? false : true;
      model.expirationDate = expirationDate;
      model.countryOfOrigin = (countryOfOrigin !== null) ? countryOfOrigin.description : null;
      model.countryOfOriginId = (countryOfOrigin !== null) ? countryOfOrigin.id : null;
      model.disableCountryOfOrigin = (lotModel.stage?.toString() !== receivingConstants.FormulationThirdPartyStage && countryOfOrigin != null) ? true : false;
      //when a lot is new take density from material
      if (model.selectedSupplier?.IsCreateNewLot)
        model.density = (model.metadata?.Density as any);
      else
        model.density = lotModel.density;
    } else {
      model.density = (model.metadata?.Density as any);
    }

    model.isGLP = model.selectedSupplier?.IsCreateNewLot === true ? false : model.isGLP;
    model.stage = model.metadata?.Stage?.toString();
    model.formulationType = (model.metadata?.FormulationConcept as any)?.formulationTypeCode?.toString();
    model.moleculeType = model.metadata?.MoleculeType?.toString() || null;
    model.COA = false;
    model.SDS = false;
    model.checkForSDS = false;
    model.unitOfMeasureId = (model.metadata?.UnitOfMeasureId as any) || receivingConstants.DefaultUnitOfMeasureIdFormulation;
    model.shelfLifeYears = +model.metadata?.ShelfLifeYears;

    // EAS 7-11-2023 Added fields for formulation biological data fields
    model.shippingConditions = model.metadata?.ShippingConditions?.toString() || null;
    model.storageConditions = model.metadata?.StorageConditions?.toString() || null;
    model.desiccant = Boolean(model.metadata?.Desiccant);

    return model;
  }

  toAddressViewModel(model: MaterialModel): AddressViewModel {
    const addressModel = model.metadata?.SupplierAddress as AddressViewModel;
    addressModel.isSelected = true;
    return addressModel;
  }
  toSupplierViewModel(model: MaterialModel): SupplierViewModel {
    return {
      address: null,
      name: model.metadata?.Preparer?.toString(), //TODO: Name to come from Api
      supplierId: "1",  //TODO: Supplier to come from Aoi  }
      materialId: model.materialId,
      selectedAddress: null,
      isSelected: true
    }
  }
  toMaterialViewModel(model: MaterialModel): Partial<MaterialViewModel> {
    const isGLP: boolean = model.metadata?.GlpStatus?.toString() === "STOCK" ? false : true;
    let manufacturerDate = model.metadata?.GlpCertificationDate ? model.metadata?.GlpCertificationDate : model.metadata?.CreateDate;
    manufacturerDate = getDateFromDateTime(manufacturerDate?.toString());
    let expirationDate = model.metadata?.GlpStatus?.toString() === "STOCK" ? model.metadata?.ExpirationDate : model.metadata?.GlpRecertificationDate;
    expirationDate = getDateFromDateTime(expirationDate?.toString());
    const countryOfOrigin = (model.metadata?.CountryOfOrigin !== null) ? model.metadata?.CountryOfOrigin as Country : null;

    return {
      alternateName: model.alternateName,
      lotNumber: model.lotNumber,
      materialId: model.materialId,
      materialName: model.materialName,
      materialTypeId: model.materialTypeId,
      metadata: model.metadata,
      supplierId: "1", //TODO: model.supplier?.supplierId ?? 1,
      COA: false,
      SDS: false,
      certificationDate: getDateFromDateTime(model.metadata?.GlpCertificationDate),
      recertificationDate: getDateFromDateTime(model.metadata?.GlpRecertificationDate),
      expirationDate: expirationDate?.toString(),
      manufacturerDate: manufacturerDate?.toString(),
      reachComplaint: model.metadata?.ReachApproved ? Boolean(model.metadata?.ReachApproved) : false,
      isGLP: isGLP,
      GLP: model.metadata?.GlpStatus?.toString(),
      disableManufaturerLodIdControl: model.lotNumber !== undefined,
      disableCountryOfOrigin: (model.metadata?.Stage?.toString() !== receivingConstants.FormulationThirdPartyStage && countryOfOrigin != null) ? true : false,
      supplierName: model.metadata?.Preparer?.toString(),
      lotId: model.lotId,
      formulationType: model.metadata?.FormulationType?.toString(),
      submittedWeight: +model.metadata?.SubmittedAmount,
      stage: model.metadata?.Stage?.toString(),
      density: +model.metadata?.Density?.toString() || null,
      countryOfOrigin: (countryOfOrigin !== null) ? countryOfOrigin.name : null,
      countryOfOriginId: (countryOfOrigin !== null) ? countryOfOrigin.id : null,
      unitOfMeasureId: (model.metadata?.UnitOfMeasureId as any) || receivingConstants.DefaultUnitOfMeasureIdFormulation,
      moleculeType: model.metadata?.MoleculeType?.toString() || null,
      tsn: model.metadata?.TsnNumber?.toString() || null,
      shippingConditions: model.metadata?.ShippingConditions?.toString(),
      storageConditions: model.metadata?.StorageConditions?.toString()
    };
  }

  private lotToSupplierViewModel(lot: LotViewModel, model: MaterialModel): SupplierViewModel {
    return {
      name: lot.preparer,
      supplierId: "1",
      address: [model.metadata.SupplierAddress],
      materialId: model.materialId,
      selectedAddress: null,
      isSelected: false,
      metadata: lot
    } as SupplierViewModel;
  }

  private createNewLotSupplierViewModel(model: MaterialModel): SupplierViewModel {
    return {
      name: receivingConstants.CreateNewLotLabel,
      supplierId: "1",
      address: [model.metadata.SupplierAddress],
      materialId: model.materialId,
      selectedAddress: null,
      isSelected: false,
      metadata: {},
      IsCreateNewLot: true
    } as SupplierViewModel;
  }

  lotsToSuppliersViewModel(model: MaterialModel): SupplierViewModel[] {
    const result: SupplierViewModel[] = [];
    (model.metadata.Lots as LotViewModel[]).forEach(lot => {
      const supplier = this.lotToSupplierViewModel(lot, model);
      result.push(supplier);
    });
    const createNewLotSupplier = this.createNewLotSupplierViewModel(model);
    result.push(createNewLotSupplier);
    return result;
  }

  loadSuppliers(model: MaterialModel): Promise<SupplierModel[]> {
    if (model.metadata?.Stage?.toString() !== receivingConstants.FormulationThirdPartyStage) {
      return Promise.resolve(this.lotsToSuppliersViewModel(model));
    } else {
      return this.receivingService.getSuppliersByMaterial(model.materialId, ExternalServiceType.Formulation);
    }
  }

  getSDSData(model: MaterialViewModel, statusWatcher: SDSConsumer): Promise<MaterialViewModel> {
    return Promise.resolve(model as MaterialViewModel);
  }

  loadCountryOfOrigin(countryOfOrigin: string) {
    this.receivingStateService.getCountries().subscribe(async lookupList => {
      this.lookupValues = lookupList as LookupModel[];
    });

    if (this.lookupValues.filter(X => X.lookupid == receivingConstants.LookupTypes.Countries).length > 0) {
      if (this.lookupValues.filter(X => X.lookupid == receivingConstants.LookupTypes.Countries)[0].values.filter(x => x.metadata?.CountryCode === countryOfOrigin).length > 0) {
        return this.lookupValues.filter(X => X.lookupid == receivingConstants.LookupTypes.Countries)[0].values.filter(x => x.metadata?.CountryCode === countryOfOrigin)[0] as LookupValuesModel;
      }
      else { return null; }
    }
    else { return null; }
  }

  getMaterial(model: MaterialViewModel): Promise<MaterialViewModel> {
    return Promise.resolve(model as MaterialViewModel);
  }

  getSessionToMaterialModel(model: SessionModel): Partial<MaterialViewModel> {
    return {
      alternateName: model.alternateName,
      lotNumber: model.lotNumber,
      materialId: model.materialId,
      materialName: model.materialName,
      materialTypeId: model.materialTypeId,
      supplierId: model.supplierId,
      COA: model.isCoaavailable,
      SDS: model.isSdsavailable,
      certificationDate: model.certificationDate,
      recertificationDate: model.recertificationDate,
      expirationDate: model.expirationDate,
      manufacturerDate: model.manufacturerDate,
      reachComplaint: model.reachCompliant,
      isGLP: model.isGLP,
      GLP: model.GLP,
      disableManufaturerLodIdControl: model.lotNumber?.length > 0,
      disableCountryOfOrigin: (model.stage !== receivingConstants.FormulationThirdPartyStage && model.metadata.CountryOfOrigin) ? true : false,
      supplierName: model.supplierName,
      formulationType: model.formulationType,
      stage: model.stage,
      density: model.density,
      countryOfOriginId: model.countryOfOriginId,
      unitOfMeasureId: model.unitOfMeasureId || receivingConstants.DefaultUnitOfMeasureIdFormulation,
      moleculeType: model.moleculeType,
      tsn: model.tsn,
      peroxideForming: model.peroxideForming,
      metadata: model.metadata
    };
  }
}