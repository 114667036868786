import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-manage-warehouse-action',
  templateUrl: './manage-warehouse-action.component.html',
  styleUrls: ['./manage-warehouse-action.component.scss']
})
export class ManageWarehouseActionComponent implements ICellRendererAngularComp {
  private params: any;

  constructor(
    private router: Router
  ) { }

  onEditStation() {
    this.router.navigate([`managewarehouses/${this.params.data.warehouseID}`]);
  }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
