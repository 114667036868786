import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { OrderService } from '../../services/order-service/order-service';
import { UserService } from '../../services/user-service/user-service.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { Order, OrderListRequest } from '../../models/order-models';
import { User } from '../../models/security-models';
import { sortData } from '../../helpers/grid-helpers';
import { AssignButtonRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/assign-button-renderer.component';
import { AgGridCustomPaginatorComponent, PaginatorModel } from '../../core/modules/ag-grid/components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';
import { OrderAssignModalComponent, OrderAssignModalModel } from '../order-assign-modal/order-assign-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { OrderFilterModel } from 'src/app/models/filter-models';
import { GridOptions } from 'ag-grid-community';
import { OrderFiltersComponent } from '../order-filters/order-filters.component';
import { BaseComponent } from '../../base-component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { publicDecrypt } from 'crypto';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';

@Component({
  selector: 'app-request',
  templateUrl: './order-coordination.component.html',
  styleUrls: ['./order-coordination.component.scss']
})

export class OrderCoordinationComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild(AgGridCustomPaginatorComponent) child: AgGridCustomPaginatorComponent;
  @ViewChild('appFilterOrder') appFilterOrderInstance: OrderFiltersComponent;

  private localStorageVersion: string = '/v3';
  private subscriptions = new Subscription();
  private gridApi;
  private orders: Order[];
  private gridOptions: GridOptions;
  public paginatorModel: PaginatorModel;
  public requestCoordinators: User[];
  public isLoaded: boolean;
  frameworkComponents: any;
  public filterModel: OrderFilterModel;
  public disableClearFilters: boolean = true;
  public hasFilterModelChanged: boolean = true;
  public overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">No records to display.</span>';

  constructor(private router: Router, private loadingService: LoadingService, private orderService: OrderService, private userService: UserService, private responseHandler: ResponseHandlingService, public dialog: MatDialog) {
    super();
    this.isLoaded = false;
    this.subscriptions.add(this.userService.getUsersByPermission(400).subscribe(
      data => {
        this.requestCoordinators = data;
        this.isLoaded = true;
      }
    ));
    this.frameworkComponents = {
      assignButtonRenderer: AssignButtonRenderer
    };
  }
  i = 0;
  ngOnInit(): void {
    this.paginatorModel = {
      totalRows: 0,
      totalPages: 0,
      pageSize: 25,
      currentPage: 1,
      leftArrowsDisabled: true,
      rightArrowsDisabled: true
    };
    this.gridOptions = {};
    this.setFilterModel();
  }

  columnDefs = [
    {
      headerName: 'Request ID',
      minWidth: 120,
      maxWidth: 120,
      field: 'requestNumber', headerTooltip: 'Request ID', tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.requestNumber;
        }
      }, sortable: true,
      suppressSizeToFit: false,
      cellRenderer: function (params) {
        if (params.data !== undefined) {
          var link = `<a href="${location.origin}/requests/${params.data.orderID}" rel="noopener" onclick="event.preventDefault()">` + params.data.requestNumber + '</a>';
          return link;
        }
      }
    },
    {
      headerName: 'R', field: 'useReservation', sortable: true,
      suppressSizeToFit: false,
      minWidth: 45,
      maxWidth: 45,
      valueGetter: (params) => {
        if (params.data !== undefined && params.data.useReservation) {
          return params.data.useReservation === true ? 'R' : '';
        }
      }
    },
    {
      headerName: 'Requestor',
      flex: 1,
      field: 'requestorName', headerTooltip: 'Requestor',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.requestorName;
        }
      }, sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Recipient',
      flex: 1,
      field: 'recipientName', headerTooltip: 'Recipient',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.recipient;
        }
      }, sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Materials Requested',
      flex: 1,
      headerTooltip: 'Materials Requested',
      suppressSizeToFit: false,
      valueGetter: (params) => {
        if (params.data !== undefined && params.data.chemicalsRequested) {
          return params.data.chemicalsRequested.replaceAll('##', ', ');
        }
      },
      // Tooltip display all chemicals in list
      // ## denotes a separate chemical coming from the stored proc
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.chemicalsRequested) {
          return !params.data.chemicalsRequested ? '' : params.data.chemicalsRequested.replaceAll('##', '\n');
        }
      }, sortable: false
    },
    {
      headerName: 'Ship By Date', field: 'shipByDate', sort: 'asc',
      headerTooltip: 'Ship By Date',
      minWidth: 110,
      maxWidth: 130,
      suppressSizeToFit: false,
      valueGetter: (params) => { if (params.data !== undefined && params.data.shipByDate) { return formatDate(params.data.shipByDate, 'd-MMM-yyyy', 'en-US') } },
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.shipByDate) {
          return formatDate(params.data.shipByDate, 'd-MMM-yyyy', 'en-US');
        }
      }, sortable: true
    },
    {
      headerName: 'Priority', headerTooltip: 'Priority',
      suppressSizeToFit: false,
      minWidth: 90,
      maxWidth: 90,
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.priorityName;
        }
      },
      field: 'priorityName', sortable: true
    },
    {
      headerName: 'Items', headerTooltip: 'Items',
      suppressSizeToFit: false,
      minWidth: 60,
      maxWidth: 60,
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.numberOfItems;
        }
      },
      field: 'numberOfItems', sortable: true
    },
    {
      headerName: 'Request Type', headerTooltip: 'Request Type',
      minWidth: 70,
      maxWidth: 70,
      suppressSizeToFit: false,
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.requestTypeDesc;
        }
      },
      field: 'requestTypeDesc', sortable: true
    },
    {
      headerName: 'Destination Country',
      flex: 1, headerTooltip: 'Destination Country', field: 'countryName', tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.countryName;
        }
      }, sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Warehouse',
      flex: 1, headerTooltip: 'Warehouse', field: 'warehouseName', tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.warehouseName;
        }
      }, sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Status',
      maxWidth: 120, headerTooltip: 'Status', field: 'orderStatusDesc',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.isStaged ? params.data.orderStatusDesc + " - Staging" : params.data.orderStatusDesc;
        }
      }, sortable: true
    },
    {
      headerName: 'Hold Reason',
      maxWidth: 150, headerTooltip: 'Hold Reason', field: 'holdReasons',
      sortable: true,
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.holdReasons) {
          return !params.data.holdReasons ? '' : params.data.holdReasons.replaceAll(',', '\n');
        }
      }
    },
    {
      headerName: 'Coordinator', headerTooltip: 'Coordinator',
      field: 'orderID', cellRenderer: 'assignButtonRenderer',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          var assignedToFullName = (params.data?.assignedToFirstName !== null ? params.data?.assignedToFirstName : '') + ' ' + (params.data?.assignedToLastName !== null ? params.data?.assignedToLastName : '');
          return assignedToFullName === ' ' ? null : assignedToFullName;
        }
      },
      suppressSizeToFit: false,
      minWidth: 110,
      maxWidth: 110
    }
  ];


  onGridReady(params) {
    this.gridApi = params.api;
    //this.setDataSource();
    this.gridApi.purgeInfiniteCache();
    this.filterOrders();
    this.gridApi.addEventListener('assignButtonClicked', (e) => {
      this.confirmAssignOrder(e);
    });
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  searchButtonClicked(): void {
    this.appFilterOrderInstance.saveFilterDetails();
    this.filterOrders();
  }

  onClearFilters() {
    this.appFilterOrderInstance.clearAllFilters();
    this.hasFilterModelChanged = false;
    this.disableClearFilters = true;
  }

  filterOrders() {
    this.hasFilterModelChanged = true;
    this.saveFilterDetails();

    var data = {};

    var countries = this.filterModel?.countries?.map(country => country.itemID);
    if (countries && countries.length > 0)
      data['countryIDs'] = countries;

    if (this.filterModel?.requestType?.itemID > 0)
      data['requestTypeID'] = this.filterModel.requestType.itemID;

    var requestors = this.filterModel?.requestors?.map(requestor => requestor.itemDesc);
    if (requestors && requestors.length > 0)
      data['requestorNames'] = requestors;

    var tags = this.filterModel?.tags?.map(tag => tag.itemID);
    if (tags && tags.length > 0)
      data['tagIDs'] = tags;

    if (this.filterModel?.shipByDateRange) {
      data['shipByStartDate'] = this.getFormattedDate(this.filterModel.shipByDateRange?.[0]);
      data['shipByEndDate'] = this.getFormattedDate(this.filterModel.shipByDateRange?.[1])
    }

    if (this.filterModel?.updatedDateRange) {
      data['updatedStartDate'] = this.getFormattedDate(this.filterModel.updatedDateRange?.[0]);
      data['updatedEndDate'] = this.getFormattedDate(this.filterModel.updatedDateRange?.[1])
    }

    var regions = this.filterModel?.regions?.map(region => region.itemID);
    if (regions && regions.length > 0)
      data['regionIDs'] = regions;

    var coordinators = this.filterModel?.coordinators?.map(coordinator => coordinator.itemID);
    if (coordinators && coordinators.length > 0)
      data['coordinatorIDs'] = coordinators;

    if (this.filterModel?.chemical) {
      data['chemicalsRequested'] = this.filterModel?.chemical;
    }

    if (this.filterModel?.requestNumber) {
      data['requestNumber'] = this.filterModel?.requestNumber;
    }
    if (this.filterModel?.lotNumber) {
      data['lotNumber'] = this.filterModel?.lotNumber;
    }
    var holdReasons = this.filterModel?.holdReasons?.map(s => s.itemID);
    if (holdReasons && holdReasons.length > 0) {
      data['holdReasons'] = holdReasons;
    }

    var orderStatuses = this.filterModel?.orderStatuses?.map(order => order.itemID);
    if (orderStatuses && orderStatuses.length > 0)
      data['orderStatuses'] = orderStatuses;

    if (this.filterModel?.reserved && this.filterModel?.reserved?.itemID > 0)
      data['useReservation'] = this.filterModel.reserved.itemDesc == "Yes" ? true : false;

    if (this.filterModel?.trackingNumber) {
      data['trackingNumber'] = this.filterModel.trackingNumber;
    }
    var warehouses = this.filterModel?.warehouses?.map(w => w.itemID);
    if (warehouses && warehouses.length > 0)
      data['warehouseIDs'] = warehouses;

    this.loadingService.show("Loading ...");
    this.orderService.getOrdersByFilterCriteria(data)
      .pipe(finalize(() => this.loadingService.clearMessage()))
      .subscribe(result => {
        if (result && result.length == 0) {
          this.gridOptions.suppressNoRowsOverlay = false;
          this.gridOptions.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No matches for selected filter criteria</span>';
        }
        this.bindGridData(result);
      });
  };

  saveFilterDetails() {
    if (!this.router.url) return;
    sessionStorage.setItem(this.router.url + this.localStorageVersion, JSON.stringify({
      filterModel: this.appFilterOrderInstance?.filterModel,
      enabledFilters: this.appFilterOrderInstance?.enabledFilters
    }));
  }

  getFormattedDate(date) {
    var params = date.toLocaleDateString().split('/');
    return [`${params[2]}`, `0${params[0]}`.substr(-2), `0${params[1]}`.substr(-2)].join('-');
  }

  bindGridData(data) {
    var dataSource = {
      rowCount: data?.totalItems,
      getRows: function (params) {
        setTimeout(function () {
          var dataAfterSorting = sortData(params.sortModel, data);
          var rowsThisPage = dataAfterSorting.slice(params.startRow, params.endRow);
          var lastRow = dataAfterSorting.length;
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      }
    }

    this.paginatorModel.totalRows = data.length;
    this.paginatorModel.totalPages = Math.ceil(data.length / this.paginatorModel.pageSize);

    if (this.paginatorModel.totalRows == 0) {
      this.gridApi.setDatasource(dataSource);
      this.gridApi.showNoRowsOverlay()
    }
    else {
      this.updateCurrentPage();
      this.gridApi.hideOverlay()
      this.gridApi.setDatasource(dataSource);
    }
  }

  confirmAssignOrder(event: any) {
    const dialogData =
      new OrderAssignModalModel(`Assign Coordinator`,
        `Select a Request Coordinator for request ${event.value.requestNumber}.`,
        this.requestCoordinators,
        `Assigning a coordinator will update the request status to Pending and move it to the Request Prep screen.`);
    const dialogRef = this.dialog.open(OrderAssignModalComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe((userResponse: User) => {
      if (userResponse) {
        // We are re-getting this order in case of stale data
        this.orderService.getOrderDetail(event.value.orderID).subscribe((orderDetailResponse: Order) => {
          if (orderDetailResponse.assignedToUserID) {
            const data: ConfirmDialogModel = {
              title: 'Confirm Re-Assignment', message: `Request is assigned to ${orderDetailResponse.assignedToFirstName} ${orderDetailResponse.assignedToLastName}. Do you want to reassign it to ${userResponse.firstName} ${userResponse.lastName}?`,
              useDangerConfirm: false, confirmButtonLabel: 'Confirm'
            }
            this.dialog.open(ConfirmDialogComponent, {
              disableClose: true, data
            }).afterClosed().subscribe(confirmResponse => {
              if (confirmResponse) {
                this.assignOrder(event.value, userResponse);
              }
              else {
                this.filterOrders();
              }
            });
          } else {
            this.assignOrder(event.value, userResponse);
          }
        });
      }
      else {
        this.filterOrders();
      }
    });
  }

  assignOrder(order: any, coordinator: User) {
    this.subscriptions.add(
      this.orderService.assignOrder(order.orderID, coordinator.userID).subscribe(
        data => {
          this.responseHandler.showSuccess(`Successfully assigned request ${order.requestNumber} to coordinator ${coordinator.firstName} ${coordinator.lastName}.`);
          this.filterOrders();
        }
      )
    );
  }

  // Handle events emitted from agGridCustomPaginator
  handlePaginatorCommand(event: number) {
    switch (event) {
      case 1:
        this.gridApi.paginationGoToFirstPage();
        break;
      case 2:
        this.gridApi.paginationGoToPreviousPage();
        break;
      case 3:
        this.gridApi.paginationGoToNextPage();
        break;
      case 4:
        this.gridApi.paginationGoToLastPage();
        break;
      default:
        this.gridApi.paginationSetPageSize(event);
        this.gridApi.paginationGoToFirstPage();
        break;
    }
  }

  updateCurrentPage() {
    this.paginatorModel.currentPage = 1;
    this.paginatorModel.leftArrowsDisabled = true;
    if (this.paginatorModel.totalPages > 1) {
      this.paginatorModel.rightArrowsDisabled = false;
    }
  }

  onFilterModelChange(event) {
    this.hasFilterModelChanged = false;
    this.filterModel = event.filterModel;
    this.handleClearFilters();
  }

  handleClearFilters(): void {
    var data = {};

    var orderStatuses = this.filterModel.orderStatuses.map(item => item.itemID);
    if (orderStatuses && orderStatuses.length > 0)
      data['orderStatuses'] = orderStatuses;

    var countries = this.filterModel?.countries?.map(country => country.itemID);
    if (countries && countries.length > 0)
      data['countryIDs'] = countries;

    if (this.filterModel?.requestType?.itemID > 0)
      data['requestTypeID'] = this.filterModel.requestType.itemID;

    var requestors = this.filterModel?.requestors?.map(requestor => requestor.itemDesc);
    if (requestors && requestors.length > 0)
      data['requestorNames'] = requestors;

    var tags = this.filterModel?.tags?.map(tag => tag.itemID);
    if (tags && tags.length > 0)
      data['tagIDs'] = tags;

    if (this.filterModel?.shipByDateRange) {
      data['shipByStartDate'] = this.getFormattedDate(this.filterModel?.shipByDateRange?.[0]);
      data['shipByEndDate'] = this.getFormattedDate(this.filterModel?.shipByDateRange?.[1])
    }

    if (this.filterModel?.updatedDateRange) {
      data['updatedStartDate'] = this.getFormattedDate(this.filterModel?.updatedDateRange?.[0]);
      data['updatedEndDate'] = this.getFormattedDate(this.filterModel?.updatedDateRange?.[1])
    }

    var regions = this.filterModel?.regions?.map(region => region.itemID);
    if (regions && regions.length > 0)
      data['regionIDs'] = regions;

    var coordinators = this.filterModel?.coordinators?.map(coordinator => coordinator.itemID);
    if (coordinators && coordinators.length > 0)
      data['coordinatorIDs'] = coordinators;

    if (this.filterModel?.chemical) {
      data['chemicalsRequested'] = this.filterModel?.chemical;
    }

    if (this.filterModel?.requestNumber) {
      data['requestNumber'] = this.filterModel?.requestNumber;
    }
    if (this.filterModel?.lotNumber) {
      data['lotNumber'] = this.filterModel?.lotNumber;
    }

    if (this.filterModel?.holdReasons && this.filterModel.holdReasons?.length > 0) {
      data['holdReasons'] = this.filterModel?.holdReasons;
    }

    if (this.filterModel?.reserved && this.filterModel?.reserved?.itemID > 0) {
      data['useReservation'] = this.filterModel?.reserved;
    }

    if (this.filterModel?.trackingNumber) {
      data['trackingNumber'] = this.filterModel?.trackingNumber;
    }
    var warehouses = this.filterModel?.warehouses?.map(w => w.itemID);
    if (warehouses && warehouses.length > 0)
      data['warehouseIDs'] = warehouses;

    if ((Object.keys(data).includes('orderStatuses'))
      && (Object.keys(data)?.length == 1) && this.filterModel?.orderStatuses?.length)
      this.disableClearFilters = !(Object.keys(data)?.length > 0);
    else
      this.disableClearFilters = !(Object.keys(data)?.length > 1);

  }

  setFilterModel() {
    if (this.router.url && sessionStorage.getItem(this.router.url + this.localStorageVersion))
      this.filterModel = JSON.parse(sessionStorage.getItem(this.router.url + this.localStorageVersion)).filterModel;
  }

  openOrderDetail(event) {
    this.router.navigate([`requests/${event.data.orderID}`]);
  }

  handleCellClicked(event) {
    if (event.column.colDef.headerName !== "Coordinator") {
      this.router.navigate([`requests/${event.data.orderID}`]);
    }
  }

  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
