<div class="flex-grid">
    <div class="col">
        <h4>Edit Warehouse</h4>
    </div>
</div>
<p-confirmDialog [style]="{width: '20%'}"></p-confirmDialog>
<form *ngIf="!isLoading" [formGroup]="warehouseManagerForm" (ngSubmit)="onSubmit(warehouseManagerForm)">

    <div class="p-grid p-mt-3">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label>Warehouse Name: </label>
                </div>
                <div class="p-col" style="font-weight: 600;">
                    {{selectedWarehouseOption?.itemDesc}}
                </div>
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-lg-12">
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Company Name: </label>
                </div>
                <div class="p-col ">
                    <input class="w-input" type="text" pInputText maxlength="80" formControlName="companyName">
                    <p [ngClass]="{'p-error': checkRequired('companyName')}" *ngIf="checkRequired('companyName')">
                        Field is required
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Name: </label>
                </div>
                <div class="p-col ">
                    <input class="w-input" maxlength="80" type="text" pInputText formControlName="name">
                    <p [ngClass]="{'p-error': checkRequired('name')}" *ngIf="checkRequired('name')">
                        Field is required
                    </p>
                </div>
            </div>
        </div>
    </div>


    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Address Line 1: </label>
                </div>
                <div class="p-col">
                    <input class="w-input" maxlength="255" type="text" pInputText formControlName="addressLine1">
                    <p [ngClass]="{'p-error': checkRequired('addressLine1')}" *ngIf="checkRequired('addressLine1')">
                        Field is required
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Address Line 2: </label>
                </div>
                <div class="p-col">
                    <input class="w-input" maxlength="255" type="text" pInputText formControlName="addressLine2">
                </div>
            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Address Line 3: </label>
                </div>
                <div class="p-col">
                    <input class="w-input" maxlength="255" type="text" pInputText formControlName="addressLine3">
                </div>
            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">City: </label>
                </div>
                <div class="p-col">
                    <input class="w-input" maxlength="50" type="text" (keypress)="restrictNumeric($event)" pInputText
                        formControlName="city">
                    <p [ngClass]="{'p-error': checkRequired('city')}" *ngIf="checkRequired('city')">
                        Field is required
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">State Province: </label>
                </div>
                <div class="p-col">
                    <input class="w-input" maxlength="50" (keypress)="restrictNumeric($event)" type="text" pInputText
                        formControlName="stateProvince">
                </div>
            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Postal Code: </label>
                </div>
                <div class="p-col">
                    <input class="w-input" maxlength="10" type="text" pInputText formControlName="postalCode">
                    <p [ngClass]="{'p-error': checkRequired('postalCode')}" *ngIf="checkRequired('postalCode')">
                        Field is required
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="countries">Country: </label>
                </div>
                <div class="p-col">
                    <p-dropdown placeholder="Select Country" defaultLabel="Select Country" formControlName="countryID"
                        [filter]="true" [style]="{'width':'92%'}"
                        [options]="destinationGuideModel?.countries | sortBy:'asc': 'countryName'"
                        optionLabel="countryName" optionValue="countryID">
                    </p-dropdown>
                    <p [ngClass]="{'p-error': checkRequired('countryID')}" *ngIf="checkRequired('countryID')">
                        Field is required
                    </p>
                </div>

            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="countries">Related Role: </label>
                </div>
                <div class="p-col">
                    <p-dropdown placeholder="Select Role" defaultLabel="Select Role" [style]="{'width':'92%'}"
                        formControlName="relatedRoleID" [options]="roles" optionLabel="roleName" optionValue="roleID">
                    </p-dropdown>
                    <p [ngClass]="{'p-error': checkRequired('relatedRoleID')}" *ngIf="checkRequired('relatedRoleID')">
                        Field is required
                    </p>
                </div>

            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Customs Invoice Export Statement: </label>
                </div>
                <div class="p-col">
                    <textarea class="form-control" id="notes" rows="8"
                        style="width: 92%;margin-left: 0;border:1px solid #a6a6a6"
                        formControlName="customsInvoiceExportStatement"></textarea>                  
                </div>
            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Sample Owner Text: </label>
                </div>
                <div class="p-col">
                    <input class="w-input" type="text" pInputText formControlName="sampleOwnerText">                   
                </div>
            </div>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="float-right" style="margin-right: 6%;">
                <button mat-raised-button color="cor-default" (click)="onCancel()" type="button" 
                [disabled]="!warehouseManagerForm.valid">Cancel</button>
                <button mat-raised-button color="cor-default" type="submit" color="cor-primary"
                    style="margin-left: 20px;">Save</button>
            </div>
        </div>
    </div>
</form>