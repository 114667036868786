import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Component, OnDestroy, OnInit, Inject } from "@angular/core";
import { DecantStation, Warehouse } from "src/app/models/decant-models";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DecantService } from "src/app/services/decant-service/decant-service";
import { ConfirmDialogComponent, ConfirmDialogModel } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { filter, take } from "rxjs/operators";

@Component({
  selector: 'app-change-warehouse-station',
  templateUrl: './change-warehouse-station.component.html',
  styleUrls: ['./change-warehouse-station.component.scss'],
})
export class ChangeWarehouseStationComponent implements OnInit {
  public warehousesStationsRes: DecantStation[];
  public stationsRes: DecantStation[];
  public station: DecantStation;
  public selectedWarehouse: Warehouse;
  public warehousesRes: Warehouse[];
  public warehouseStationForm: FormGroup;
  public warehouseSelected = false;

  constructor(
    public dialogRef: MatDialogRef<ChangeWarehouseStationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private decantService: DecantService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.getWarehouseList();
  }

  buildForm() {
    this.warehouseStationForm = new FormGroup({
      warehousectrl: new FormControl(null, Validators.required),
      reasonToChange: new FormControl(null, Validators.required),
    });
  }

  onClose() {
    const confirmDialog = new ConfirmDialogModel("Confirm Action",
      `Are you sure?`,
      "Confirm", false, true, "Cancel");
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      disableClose: true, data: confirmDialog
    })
      .afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.dialogRef.close({
            value: { ...this.warehouseStationForm.value, move: false }
          });
        }
      });
  }

  onMove() {
    this.dialogRef.close({
      value: { ...this.warehouseStationForm.value, move: true }
    });
  }

  private getWarehouseList() {
    this.decantService.getWarehouses().subscribe({
      next: (res) => {
        this.warehousesRes = res.filter(warehouse => warehouse.itemID != this.data.warehouseID);
      },
    });
  }
}
