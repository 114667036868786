import { Injectable } from '@angular/core';
import { EnvironmentDefinition } from "../../models/environment-models";
import { Environments } from "./environment";

@Injectable()
export class EnvironmentService {

    public currentEnvironment:EnvironmentDefinition;

    constructor()
    {       
        this.currentEnvironment = this.getValidEnvironment();
    }

    private getValidEnvironment(): EnvironmentDefinition {
         return this.determineEnvironmentFromPath();
    }

    private determineEnvironmentFromPath(): EnvironmentDefinition {
        var path = window.location.host.toUpperCase();
        return this.determineEnvironmentFromHost(path, window.location.origin);
    }
    
    private determineEnvironmentFromHost(host: string, origin:string): EnvironmentDefinition {
        for (var i = 0; i < this.environments.length; i++) {
            var env = this.environments[i];
            for (var j = 0; j < env.hosts.length; j++) {
                var envHost = env.hosts[j].trim().toUpperCase();
                if (host.indexOf(envHost) !== -1) {
                    env.ShellUIPath = origin;   //this would attempt to fix the case when there are are more than two urls to the same app (i.e. azure.website and domainname.com)
                    return env;
                }
            }
        }
    }

    private environments:EnvironmentDefinition[] = Environments.environments;
}