import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Breadcrumb } from 'primeng/breadcrumb';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BreadCrumbNavigationService } from '../services/bread-crumb-navigation.service';
import { Warehouse } from 'src/app/models/decant-models';
import { MessageService } from 'src/app/shared/services/message-queue.service';
import { Router, Event, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent extends Breadcrumb implements OnInit, OnDestroy {
  breadcrumbs: MenuItem[];
  breadcrumbMetadata: any;
  home = { icon: 'pi pi-home', routerLink: '/' };
  routerSubscription: Subscription;
  navigationSubscription: Subscription;
  messageServiceSubscription: Subscription;
  private subject = new BehaviorSubject<MenuItem[]>([]);
  breadcrumbs$: Observable<MenuItem[]> = this.subject.asObservable();

  constructor(private navigationService: BreadCrumbNavigationService, private messageService: MessageService, private router: Router) {
    super();

    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url == '/receiving' ||
          event.url == '/receiving/previous-sessions'
        ) {
          localStorage.removeItem('breadcrumbMetadata');
          this.breadcrumbMetadata =null;
        }
      }
    });
  }

  ngOnInit(): void {
    this.navigationService.init();
    this.breadcrumbMetadata = JSON.parse(localStorage.getItem('breadcrumbMetadata')) as Warehouse;

    this.navigationSubscription = this.navigationService
      .connect()
      .subscribe(breadcrumbs => {
        this.subject.next(breadcrumbs);
        this.model = breadcrumbs;
      });

      this.messageServiceSubscription = this.messageService.message.subscribe(session => {
        localStorage.removeItem('breadcrumbMetadata');
        localStorage.setItem('breadcrumbMetadata',  JSON.stringify({itemID: session.warehouseID, itemIDText:null,itemDesc: session.warehouseName}));
        this.breadcrumbMetadata = {itemID: session.warehouseID, itemIDText:null,itemDesc: session.warehouseName};
      })
  }

  ngOnDestroy() {
    if (this.navigationSubscription) 
      this.navigationSubscription.unsubscribe();

    this.navigationService.destroy();
    this.messageServiceSubscription.unsubscribe();
  }

  itemClick(event, item: MenuItem) {
    this.navigationService.removeRoute(item);
    super.itemClick(event, item);
  }

}
