import { Component } from '@angular/core';
import { MatExpansionPanelDescription } from '@angular/material/expansion';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';

@Component({
    selector: 'move-button-cell',
    template: `<button mat-raised-button color="cor-primary" class="mat-focus-indicator mat-raised-button mat-button-base mat-cor-primary" (click)="move()">Move</button>`,
    styles: [],
  })
  
  export class MoveButtonRenderer implements ICellRendererAngularComp {
    public params: any;
    private gridApi: any;
    isMoveDisable: boolean = false;

    constructor(private toastr: ResponseHandlingService) { }
  
    agInit(params: any): void {
      this.params = params;
      this.gridApi = params.api;
      this.isMoveDisable =params.data.isMoveDisable;
    }
  
    move() {
      let selectRecordInfo = this.params.data;
      if(!selectRecordInfo.moveAll)
      {

        if(selectRecordInfo.numbertoMove == 0)
        {
          selectRecordInfo.numbertoMove = selectRecordInfo.numberOfContainers;
        }
          if(selectRecordInfo.numberOfContainers >= selectRecordInfo.numbertoMove)
          {
            selectRecordInfo.pendingNoOfContainers = Number(selectRecordInfo.numberOfContainers) - Number(selectRecordInfo.numbertoMove);
           this.gridApi.dispatchEvent({ type: 'moveButtonClicked', value: this.params.data });
          } else{
            this.toastr.showError('Invalid number of containers to move');
          }
        
        // else{
        //   this.toastr.showError('Invalid number of containers to move');
        // }
      }
      if(selectRecordInfo.moveAll)
      {
          this.gridApi.dispatchEvent({ type: 'moveButtonClicked', value: this.params.data });
      }
    }
  
    refresh(): boolean {
      return false;
    }
  }
