import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MessageService } from '../../../shared/services/message-queue.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { EditDestinationGuideOptionsModalComponent } from '../edit-destination-guide-options-modal/edit-destination-guide-options-modal.component';

@Component({
  selector: 'app-manage-destination-guide-options-actions',
  templateUrl: './manage-destination-guide-options-actions.component.html',
  styleUrls: ['./manage-destination-guide-options-actions.component.scss'],
})
export class ManageDestinationGuideOptionsActionsComponent implements ICellRendererAngularComp {
  private params: any;

  constructor(
    private dialog: MatDialog,
    private readonly messageService: MessageService
  ) {}

  onEditOption(){
    this.dialog
      .open(EditDestinationGuideOptionsModalComponent, { width: '414px', data: this.params.data })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({data:'edit', reference:dialogResult});
        }
      });
  }

  onDeleteOption() {
    const confirmDialog = new ConfirmDialogModel(
      'Confirm Action',
      `This option will be deleted. Are you sure?`,
      'OK',
      false,
      true,
      'Cancel'
    );
    this.dialog
      .open(ConfirmDialogComponent, { maxWidth: '400px', data: confirmDialog })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({
            data: 'delete',
            reference: this.params.data,
          });
        }
      });
  }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
