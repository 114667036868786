import { Injectable, Inject } from '@angular/core';
import {
    AuthStatus,
    AuthService,
    AuthSubscriptionsStrategy,
    OnUserInfoUpdate,
    OnAuthStatusUpdate
} from '@corteva-research/ngx-components-core';
import { BroadcastService, MsalService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalAngularConfiguration } from '@azure/msal-angular';
import { Configuration } from 'msal';
import { HttpClient } from '@angular/common/http';
import { combineLatest, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MsalUserInfo, GraphMe } from '../../models/auth-models';
import jwt_decode from 'jwt-decode';

@Injectable()
export class MsalAuthService implements AuthService<MsalUserInfo> {

    /*
        MSAL Methods on the MsalService and BroadcastService

        loginRedirect()
        loginPopup()
        logOut()
        acquireTokenSilent() - This will try to acquire the token silently. If the scope is not already consented then user will get a
            callback at msal:acquireTokenFailure event. User can call either acquireTokenPopup() or acquireTokenRedirect() there to
            acquire the token interactively.
        acquireTokenPopup()
        acquireTokenRedirect()
        getUser()

        "msal:loginFailure"
        "msal:loginSuccess"
        "msal:acquireTokenSuccess"
        "msal:acquireTokenFailure"
    */


    // TODO - Ability to set a custom prefix for same domain (IE Localhost);
    private localStorageKey = 'authUserInfo';
    private _userInfo: MsalUserInfo = null;
    get userInfo(): MsalUserInfo {
        return this._userInfo;
    }

    private _authStatus: AuthStatus = AuthStatus.Authenticating;
    get authStatus(): AuthStatus {
        return this._authStatus;
    }

    public interactive = true;

    private authSubscriptionStrategies: AuthSubscriptionsStrategy<MsalUserInfo> = new AuthSubscriptionsStrategy(
        () => this.userInfo,
        () => this.authStatus
    );

    private autoRefreshTimer;
    private autoRefreshInterval;

    private graphUrl = 'https://graph.microsoft.com/v1.0';
    private defaultUserInfo: MsalUserInfo = {
        avatarBase64: null,
        expires: null,
        data: null,
        givenName: null,
        surName: null,
        id: null,
        email: null
    };

    constructor(
        private msalService: MsalService,
        private httpClient: HttpClient,
        @Inject(MSAL_CONFIG_ANGULAR) private msalAngularConfig: MsalAngularConfiguration,
        @Inject(MSAL_CONFIG) private msalConfig: Configuration,
        broadcastService: BroadcastService) {

        broadcastService.subscribe('msal:loginSuccess', this.loadUserDetails);
        broadcastService.subscribe('msal:loginFailure', this.handleLoginFailure);
        broadcastService.subscribe('msal:acquireTokenFailure', this.handleAquireTokenFailure);

        this._userInfo = this.getStoredUserInfo();
        if (!this._userInfo) {
            this.setAuthStatus(AuthStatus.Unauthenticated);
        } else {
            this.setAuthStatus(AuthStatus.Authenticated);
        }

        this.msalService.handleRedirectCallback((authError, response) => {
            if (authError) {
                console.error('Redirect Error: ', authError.errorMessage);
                return;
            }
            console.log('Redirect Success: ', response, this._authStatus);
        });
    }

    silentLogout() {
        clearInterval(this.autoRefreshInterval);
        let link = document.createElement('a');
        link.href = `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${this.msalService.getPostLogoutRedirectUri()}`;
        link.click();
        localStorage.clear();
    }

    logout = (): void => {
        clearInterval(this.autoRefreshInterval);
        this.msalService.logout();
        localStorage.clear();
    }

    loginPopup = (): void => {
        this.msalService.loginPopup({ scopes: this.msalAngularConfig.consentScopes });
    }

    login = (): void => {
        this.msalService.loginRedirect({ scopes: this.msalAngularConfig.consentScopes, loginHint: this.msalService.getAccount()?.accountIdentifier });
    }

    isLoggedIn = (): boolean => {
        return !!this.msalService.getAccount() && !!this.userInfo;
    }

    isAuthenticated() {
        if (!localStorage[this.localStorageKey]) return false;
        var authUserInfo = localStorage[this.localStorageKey];

        if (!authUserInfo) return false;
        return new Date(Date.now() - 10 * 60000).getTime() < new Date(JSON.parse(authUserInfo).expires).getTime(); //Prior to 10mins
    }

    getDisplayName = (): string => {
        if (!this.userInfo) {
            return null;
        }

        return `${this.userInfo.givenName} ${this.userInfo.surName}`;
    }

    getAvatar = (): string => {

        if (!this.userInfo || !this.userInfo.avatarBase64) {
            return null;
        }

        return this.userInfo.avatarBase64;

    }

    updateUserInfo = (userInfo: MsalUserInfo) => {
        this.updateStoredUserInfo(userInfo);
        this._userInfo = userInfo;
        this.authSubscriptionStrategies.notifyUserInfoChange();
    }

    /**
     * External Subscription Helpers
     */
    subscribeToAuthStatusChanges = (subscriber: OnAuthStatusUpdate): void => {
        this.authSubscriptionStrategies.subscribeToAuthStatusChanges(subscriber);
    }

    unsubscribeFomAuthStatusChanges = (subscriber: OnAuthStatusUpdate): void => {
        this.authSubscriptionStrategies.unsubscribeFomAuthStatusChanges(subscriber);
    }

    subscribeToUserInfoChanges = (subscriber: OnUserInfoUpdate<MsalUserInfo>): void => {
        this.authSubscriptionStrategies.subscribeToUserInfoChanges(subscriber);
    }

    unsubscribeFromUserInfoChanges = (subscriber: OnUserInfoUpdate<MsalUserInfo>): void => {
        this.authSubscriptionStrategies.unsubscribeFromUserInfoChanges(subscriber);
    }


    /**
     * MSAL Callbacks
     */
    private handleLoginFailure = (payload) => {
        console.warn('msal:handleLoginFailure', payload);
        this.logout();
    }

    private handleAquireTokenFailure = (payload) => {
        if (payload.errorDesc === 'consent_required') {
            this.msalService.acquireTokenRedirect({ scopes: this.msalAngularConfig.consentScopes });
        }
        else if (payload && (payload.errorCode === 'interaction_required'
            || payload.errorCode === 'login_required')) {
            console.error('Redirect Error: ', payload.errorMessage);
            localStorage.clear();
            this.login();
        } else {
            console.warn('acquire token failure', payload);
        }
    }


    /**
     * User Info Management
     */

    private updateStoredUserInfo = (userInfo: MsalUserInfo) => {

        const storage = this.getStorageMethod();

        if (userInfo) {
            storage.setItem(this.localStorageKey, JSON.stringify(userInfo));
        } else {
            storage.removeItem(this.localStorageKey);
        }
    }

    private getStoredUserInfo = (): MsalUserInfo => {
        let userInfo: MsalUserInfo = null;

        const storage = this.getStorageMethod();

        const userInfoRaw = storage.getItem(this.localStorageKey);
        if (userInfoRaw) {
            userInfo = JSON.parse(userInfoRaw);
            if (userInfo.expires < new Date()) {
                userInfo = null;
            }
        }

        return userInfo;
    }

    private loadUserDetails = () => {

        this.setAuthStatus(AuthStatus.Authenticating);

        const msalUser = this.msalService.getAccount();

        if (!!msalUser) {

            const uInfo = this.userInfo || { ...this.defaultUserInfo };

            const userPhotoObservable = this.httpClient.get(this.graphUrl + '/me/photo/$value', { responseType: 'blob' }).pipe(
                catchError(err => {
                    console.warn(err);
                    return of(null);
                })
            );
            const userDataObservable = this.httpClient.get<GraphMe>(this.graphUrl + '/me');

            combineLatest([userPhotoObservable, userDataObservable])
                .subscribe(([photoBlob, userData]) => {

                    if (!!photoBlob) {
                        this.createImageFromBlob(photoBlob);
                    }

                    uInfo.givenName = userData.givenName;
                    uInfo.surName = userData.surname;
                    uInfo.email = userData.mail;
                    uInfo.id = userData.id;
                    uInfo.expires = new Date(1000 * (<any>msalUser.idToken).exp);

                    this.updateUserInfo(uInfo);
                    this.setAuthStatus(AuthStatus.Authenticated);

                }, error => {
                    this.setAuthStatus(AuthStatus.Unauthenticated);
                    console.error('Http get request to MS Graph failed' + JSON.stringify(error));
                });

        } else {
            this.updateUserInfo(null);
            this.setAuthStatus(AuthStatus.Unauthenticated);
        }
    }

    private setAuthStatus = (authStatus: AuthStatus) => {
        this._authStatus = authStatus;
        this.authSubscriptionStrategies.notifyAuthStatusChange();
    }


    /**
     * Helper Methods
     */
    private getStorageMethod(): Storage {

        // tslint:disable-next-line:max-line-length
        const storage: Storage = this.msalConfig
            && this.msalConfig.cache
            && this.msalConfig.cache.cacheLocation
            && this.msalConfig.cache.cacheLocation.toLowerCase() === 'localstorage'
            ? localStorage
            : sessionStorage;

        return storage;
    }

    private createImageFromBlob = (blob: Blob) => {

        if (blob) {
            const reader = new FileReader();

            reader.readAsDataURL(blob);

            reader.onloadend = () => {
                const uInfo = this.userInfo || { ...this.defaultUserInfo };

                if (typeof reader.result === 'string') {
                    uInfo.avatarBase64 = reader.result;
                    this.updateUserInfo(uInfo);
                }
            };
        }
    }
}
