import { Warehouse } from 'src/app/models/decant-models';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DecantService } from 'src/app/services/decant-service/decant-service';

@Component({
  selector: 'app-edit-station-modal',
  templateUrl: './edit-station-modal.component.html',
  styleUrls: ['./edit-station-modal.component.scss'],
})
export class EditStationModalComponent implements OnInit {
  public stationForm: FormGroup;
  public selectedWarehouse: Warehouse;
  public warehousesRes: Warehouse[];
  public warehouseStationForm: FormGroup;
  public warehouseSelected = false;
  public buttonLabel: string;

  constructor(
    public dialogRef: MatDialogRef<EditStationModalComponent>,
    private decantService: DecantService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.buttonLabel = "Edit";
  }

  ngOnInit(): void {
    this.buildForm();
    this.initData();
    this.getWarehouseList();
  }

  initData() {
    if (this.data == null) {
      var data = {
        warehouseId: 0,
        name: null,
        machineName: null,
        isGLP: false,
      };

      this.data = data;
      this.buttonLabel = "Add New";
    }
  }

  onSubmit() {
    this.dialogRef.close(this.stationForm.value);
  }

  getWarehouseList() {
    this.decantService.getWarehouses().subscribe({
      next: (res) => {
        this.warehousesRes = res;
        this.selectedWarehouse = this.warehousesRes.find(
          (s) => s.itemID === this.data.warehouseID
        );
        this.patchForm();
      },
    });
  }

  private patchForm() {
    this.stationForm.patchValue({
      warehouseId: this.data.warehouseID,
      name: this.data.name,
      machineName: this.data.machineName,
      isGLP: this.data.isGLP,
    });
  }

  private buildForm() {
    this.stationForm = new FormGroup({
      warehouseId: new FormControl(null, Validators.required),
      name: new FormControl(null, [Validators.required, Validators.maxLength(40)]),
      machineName: new FormControl(null, [Validators.maxLength(80)]),
      isGLP: new FormControl(null)
    });
  }
}
