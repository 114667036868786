import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'request-number-cell',
  template: `<span style="cursor:pointer; color: blue" (click)="requestNumberClicked()">{{ params.data.requestNumber }}</span>`,
  styles: [],
})

export class RequestNumberClickableTextCellRenderer implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
  }

  requestNumberClicked() {
    this.gridApi.dispatchEvent({ type: 'requestNumberClicked', value: this.params.data });
  }

  refresh(): boolean {
    return false;
  }
}