import { AddressViewModel, Country } from '../../models/address.model';
import { ExternalServiceType } from '../../models/enumerations';
import { LookupModel, LookupValuesModel } from '../../models/lookup.model';
import { LotViewModel, MaterialModel, MaterialViewModel } from '../../models/material.model';
import { ReceivingStateViewModel, SessionModel } from '../../models/session.model';
import { SupplierModel, SupplierViewModel } from '../../models/supplier.model';
import { DataEntryViewModel } from '../../models/view-models/dataentry-view.model';
import { SDSConsumer } from '../../models/view-models/status-watcher.model';
import { ValidationViewModel } from '../../models/view-models/validation-view.model';
import { receivingConstants, getDateFromDateTime } from '../../receiving.constants';
import { ReceivingStateService } from '../receiving-state.service';
import { ReceivingService } from '../receiving.service';
import { IMaterialDataAdapter } from './material-data-adapter';
import { BionexusCertificationStatus } from '../../models/material/bionexus.enum';

export class BionexusDataAdapter implements IMaterialDataAdapter {
  lookupValues: LookupModel[]
  vendors: SupplierModel[] = [];

  constructor(private receivingService: ReceivingService, private receivingStateService: ReceivingStateService) {

  }

  toMaterialViewModel(model: MaterialModel): Partial<MaterialViewModel> {
    const isGLP: boolean = model.metadata?.GlpStatus?.toString().toUpperCase() === "STOCK" ? false : true;

    let manufacturerDate = model.metadata?.GlpCertificationDate ? model.metadata?.GlpCertificationDate : (model.metadata?.CertificationDate ? model.metadata?.CertificationDate : model.metadata?.ManufacturedDate);
    manufacturerDate = getDateFromDateTime(manufacturerDate?.toString());
    let expirationDate = model.metadata?.GlpStatus?.toString().toUpperCase() === "STOCK" ?  (+model.metadata?.CertificationStatusTypeId == BionexusCertificationStatus.Certified || +model.metadata?.CertificationStatusTypeId == BionexusCertificationStatus.Pending ||
      +model.metadata?.CertificationStatusTypeId == BionexusCertificationStatus.Intended)
      ? (model.metadata?.RecertificationDate?.toString()) : (model.metadata?.ExpirationDate?.toString()) : model.metadata?.GlpRecertificationDate;
    expirationDate = getDateFromDateTime(expirationDate?.toString());

    return {
      expirationDate: expirationDate?.toString(),
      manufacturerDate: manufacturerDate?.toString(),
      moleculeType: model.metadata?.MoleculeType?.toString(),
      alternateName: model.alternateName,
      lotNumber: model.metadata?.ManufacturerLot?.toString(),
      lotId: model.lotId,
      materialId: model.materialId,
      materialName: model.materialName,
      materialTypeId: model.materialTypeId,
      metadata: null,
      supplierId: null,
      supplierName: null,
      COA: false,
      SDS: false,
      isGLP: isGLP,
      GLP: model.metadata?.GlpStatus?.toString().toUpperCase(),
      certificationDate: getDateFromDateTime(model.metadata?.GlpCertificationDate),
      recertificationDate: getDateFromDateTime(model.metadata?.GlpRecertificationDate),
      tsn: null,
      molecularWeight: null,
      submittedWeight: null,
      displaySubmittedWeight: false,
      disableManufaturerLodIdControl: model.lotNumber !== undefined,
      shippingConditions: model.metadata?.ShippingConditions?.toString(),
      storageConditions: model.metadata?.StorageConditions?.toString(),
      desiccant: Boolean(model.metadata?.Desiccant),
      density: null,
      peroxideForming: false
    };
  }

  toSupplierViewModel(model: MaterialModel): SupplierViewModel {
    var ret = {
      address: null,
      name: model.metadata?.Manufacturer?.toString(),
      supplierId: "1",
      materialId: model.materialId,
      selectedAddress: null,
      isSelected: true
    }
    return ret;
  }

  toSupplierViewModelFromLot(model: MaterialModel): SupplierViewModel {
    var ret = {
      address: null,
      name: model.metadata?.Manufacturer?.toString(),
      supplierId: "1",
      materialId: model.materialId,
      selectedAddress: null,
      isSelected: true
    }
    return ret;
  }

  toAddressViewModel(model: MaterialModel): AddressViewModel {
    if (model.metadata?.SupplierAddress) {
      const addressModel = model.metadata?.SupplierAddress as AddressViewModel;
      addressModel.isSelected = true;
      return addressModel;
    }
    return { addressId: 0, streetAddress: null, locality: null, country: null, isSelected: false } as AddressViewModel;
  }

  ToMaterialViewModelFromLot(model: MaterialViewModel): MaterialViewModel {
    model.COA = false;
    model.SDS = false;
    model.checkForSDS = false;
    model.peroxideForming = Boolean(model.metadata?.IsPeroxideForming);
    model.moleculeType = model.metadata?.MoleculeType?.toString();
    model.molecularWeight = +model.metadata?.MolWeight;
    model.displaySubmittedWeight = false;

    // EAS 7-11-2023 Added missing fields from repository metadata response
    model.desiccant = Boolean(model.metadata?.Desiccant);
    model.storageConditions = model.metadata?.StorageConditions?.toString();
    model.shippingConditions = model.metadata?.ShippingConditions?.toString();

    // EAS 9-11-2023 Added missing GLP fields
    model.isGLP = model.metadata?.GlpStatus?.toString().toUpperCase() === "STOCK" ? false : true;
    model.GLP = model.metadata?.GlpStatus?.toString().toUpperCase();
    model.certificationDate = getDateFromDateTime(model.metadata?.GlpCertificationDate);
    model.recertificationDate = getDateFromDateTime(model.metadata?.GlpRecertificationDate);

    return model;
  }

  getSDSData(model: MaterialViewModel, statusWatcher: SDSConsumer): Promise<MaterialViewModel> {
    return Promise.resolve(model as MaterialViewModel);
  }

  getMaterial(model: MaterialViewModel): Promise<MaterialViewModel> {
    return Promise.resolve(model as MaterialViewModel);
  }

  toValidationViewModel(model: ReceivingStateViewModel): ValidationViewModel {
    return {
      materialName: model.material?.materialName,
      alternateName: model.material?.alternateName,
      lotNumber: model.material?.lotNumber,
      isGLP: model.material?.isGLP,
      SDS: model.material?.SDS,
      COA: model.material?.COA,
      checkForSDS: false,
      shouldDisableGLPControl: false,
      supplier: model.supplier?.name,
      isLoading: false,
      address: model.address,
      sdsModel: null
    }
  }
  toDataEntryViewModel(model: ReceivingStateViewModel): DataEntryViewModel {
    return {
      disablePeroxideForming: !!(model.material?.metadata?.IsPeroxideForming && model.material.peroxideForming),
      yearsToExpiration: receivingConstants.DefaultYearsToExpiration
    }
  }

  getSessionToMaterialModel(model: SessionModel): Partial<MaterialViewModel> {
    return {
      alternateName: model.alternateName,
      lotNumber: model.lotNumber,
      materialId: model.materialId,
      materialName: model.materialName,
      materialTypeId: model.materialTypeId,
      supplierId: model.supplierId,
      COA: model.isCoaavailable,
      SDS: model.isSdsavailable,
      certificationDate: model.certificationDate,
      recertificationDate: model.recertificationDate,
      expirationDate: model.expirationDate,
      manufacturerDate: model.manufacturerDate,
      reachComplaint: model.reachCompliant,
      isGLP: model.isGLP,
      GLP: model.GLP,
      disableManufaturerLodIdControl: model.lotNumber?.length > 0,
      supplierName: model.supplierName,
      formulationType: model.formulationType,
      stage: model.stage,
      density: model.density,
      countryOfOriginId: model.countryOfOriginId,
      moleculeType: model.moleculeType,
      tsn: model.tsn,
      peroxideForming: model.peroxideForming,
      metadata: model.metadata
    };
  }

  loadSuppliers(model: MaterialModel): Promise<SupplierModel[]> {
    if (this.vendors.length == 0) {
      return this.receivingService.getSuppliersByMaterial(model.materialId, ExternalServiceType.Bionexus)
        .then(data => {
          this.vendors = data;
          return Promise.resolve(this.vendors);
        });
    } else {
      return Promise.resolve(this.vendors);
    }
  }

  lotsToSuppliersViewModel(model: MaterialModel): SupplierViewModel[] {
    throw new Error('Method not implemented.');
  }
}