import { Component, OnInit } from '@angular/core';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor(private responseHandler: ResponseHandlingService) { }

  ngOnInit(): void {
    this.responseHandler.showError("Unauthorized Access.");
  }

}
