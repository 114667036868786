<h5>Edit Unassigned Containers</h5>
<ag-grid-angular class="ag-theme-alpine" style="height: 350px; margin-bottom: 12px;" suppressHorizontalScroll="true"
    [rowData]="rowData" [columnDefs]="columnDefinitions" [frameworkComponents]="frameworkComponents"
    (gridReady)="onGridReady($event)">
</ag-grid-angular>
<div class="totals">
    <div>
        <label>Total Amount</label>
        <span>{{ calculateRunningTotal() | weightKG : 7  }} {{ data.orderItem.unitOfMeasureDesc }}</span>
    </div>
    <div>
        <label>Target Amount</label>
        <span>{{ targetAmountRemaining | weightKG : 7 }} {{ data.orderItem.unitOfMeasureDesc }}
            ({{ formatAmount(targetAmountInKg) | weightKG : 7 }} {{targetAmountInKg < 1 ? 'g' : 'kg' }}) </span>
    </div>
</div>
<div class="actions">
    <button mat-raised-button color="cor-primary" (click)="addRow()">Add Container</button>
    <div>
        <button mat-raised-button color="cor-default" (click)="dialogRef.close()">Cancel</button>
        <button mat-raised-button color="cor-primary" class="save"
            [disabled]="calculateRunningTotal() !== targetAmountRemaining" (click)="save()">Save</button>
    </div>
</div>