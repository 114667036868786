<h5 class="p-mb-3">{{buttonLabel}} Sample Use</h5>

<form [formGroup]="sampleUseForm" (submit)="onSubmit()">
    <div class="p-grid p-mt-3 p-mb-4">
        <div class="p-col-12">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Sample Use:</div>
                <input formControlName="name" pInputText type="text" style="width: 100%;" maxlength="80"/>
                <div *ngIf="sampleUseForm.controls['name'].invalid && (sampleUseForm.controls['name'].dirty || sampleUseForm.controls['name'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="sampleUseForm.controls.name.errors?.required">
                        Field required.
                    </small>                
                </div>
            </div>
        </div>
        <mat-dialog-actions style="float: right" class="actions">
            <button style="margin-right: 15px;" mat-raised-button color="cor-default" mat-dialog-close>Cancel</button>
            <button mat-raised-button color="cor-primary" type="submit"
                [disabled]="!sampleUseForm.valid">Save</button>
        </mat-dialog-actions>
    </div>
</form>
