<div *ngIf="readyToDisplay">
  <div *ngIf="isReweighMode">
    <div class="scale-reweigh-ready" *ngIf="!reweighReady">
      <p>Remove all items and tare the scales to capture weight</p>
    </div>
  </div>
  <ng-container *ngFor="let scale of decantStation.scales">
    <div class="spacer"></div>
    <div class="border scale-box" style="margin-bottom: 30px;">
      <span class="special-instr">{{scale.name}}</span>
      <div class="progress-bar-container" [ngStyle]="{'width': '100%'}">
        <div class="progress-label">
          <h5>
            {{formatAmount(scale.weightKG) | weightKG : scale.decimalPlaces}} {{scale.weightKG < 1 ? 'g' : 'kg' }} </h5>
        </div>
        <div
          class="progress-bar {{setProgressBar(scale.weightKG, isTestMode ? scale.maxWeightKG : selectedContainer?.amount)}}"
          [ngStyle]="setProgressWidth(scale.weightKG, isTestMode ? scale.maxWeightKG : selectedContainer?.amount)">
        </div>
      </div>

      <div class="scale-control-group">
        <button class="large" mat-raised-button color="cor-primary" (click)="getStation.emit()"
          style="margin-right: 10px;">Get Scale Weight</button>
        <button class="large" mat-raised-button color="cor-primary" *ngIf="!isTestMode && !isReweighMode"
          [disabled]="isCaptureWeightDisabled(scale)" (click)="onCaptureClick(scale)" id="btn-capture-weight">Capture
          Weight</button>
        <button class="large" mat-raised-button color="cor-primary" *ngIf="!isTestMode && isReweighMode"
          [disabled]="(isReweighMode && !selectedInventoryItem) || !reweighReady" (click)="onCaptureClick(scale)"
          id="btn-capture-weight">Capture Weight</button>
        <div [hidden]="isReweighMode" class="scale-control-label float-right">
          <h6 *ngIf="!isTestMode">Target Weight {{formatAmount(selectedContainer?.amount) | weightKG :
            scale.decimalPlaces}} {{selectedContainer?.amount < 1 ? 'g' : 'kg' }}</h6>
              <h6 *ngIf="isTestMode">Maximum Weight {{scale.maxWeightKG | weightKG: scale.decimalPlaces}} kg</h6>
        </div>
      </div>
    </div>
  </ng-container>
</div>
