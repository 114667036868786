import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { NavigationService } from 'src/app/core/modules/navigate/services/navigation.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ReceivingStateViewModel, SessionModel, StatusType } from '../models/session.model';
import { ScalesModel } from '../models/scales.model';
import { ContainerWeightModel, ContainerWeights, DefectRequest, UpdateWeightRequest } from '../models/weighing.model';
import { receivingConstants } from '../receiving.constants';
import { ReceivingStateService } from '../services/receiving-state.service';
import { HUB_SUBS_SERVICE_TOKEN, SignalRService } from '../services/signalr.service';
import { ReceivingService } from '../services/receiving.service';
import { Subscription } from 'rxjs';
import { SignalREventType } from '../models/signalR.model';
import { Pipe, PipeTransform } from '@angular/core';
import { SignalRScaleServiceSubscription } from '../services/signalr-subscription.service';
import { LookupValuesModel } from '../models/lookup.model';
import { DirtyComponent } from '../guards/dirty-component';
import { ReceivingSessionBaseComponent } from '../core/components/receiving-session-base.component';
import { StationService } from '../../services/station/station.service';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-weighing',
  templateUrl: './weighing.component.html',
  styleUrls: ['./weighing.component.scss'],
  providers: [
    {
      provide: HUB_SUBS_SERVICE_TOKEN, useClass: SignalRScaleServiceSubscription
    },
    SignalRService
  ]
})
export class WeighingComponent extends ReceivingSessionBaseComponent implements OnInit, AfterViewInit, DirtyComponent {
  @ViewChild('knownweigh') knownweigh: ElementRef;
  @ViewChild('tareweigh') tareWeigh: ElementRef;
  @ViewChild('navigate') navigate: ElementRef;

  scalesList: ScalesModel[];
  selectedScale: ScalesModel;
  receivingSession: SessionModel = null
  scaleID: string;
  actualWeight: number = 0;
  tareWeight: number = 0;
  knownWeight: number = 0;
  currentContainerID = 0;
  private selectedMaterialSubscription: Subscription;
  private signalRServiceSubscription: Subscription;
  containerWeights: ContainerWeights[] = [];
  buttonText: string = "Submit";
  disableWeighingPage: boolean = false;
  unitOfMeasureCollection: LookupValuesModel[];
  receivingStateModel: ReceivingStateViewModel;
  isDirty = false;
  isActionButtonVisible: boolean = false;
  displayDefectDialog = false;
  defectActualWeight: string;
  defectAnticipatedTareWeight: string;
  isAcceptWeightDisabled = true;

  constructor(private readonly router: Router,
    private receivingStateService: ReceivingStateService,
    private navService: NavigationService,
    private loadingService: LoadingService,
    private toastr: ResponseHandlingService,
    private receivingService: ReceivingService,
    private signalRService: SignalRService,
    private changeDetectorRef: ChangeDetectorRef,
    private stationService: StationService,
    private activatedRoute: ActivatedRoute
  ) {
    super(receivingService, receivingStateService, activatedRoute, loadingService);
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.isActionButtonVisible = this.getActionButtonVisibility();
    });
  }

  ngAfterViewInit(): void {
    if (!this.disableWeighingPage) {
      //this.focusTareWeight();
      this.focusKnownWeight();
    }
  }

  canDeactivate() {
    return (this.isDirty && (!this.disableWeighingPage));
  }

  @HostListener('window:beforeunload', ['$event'])
  onbeforeunload(event) {
    if (this.containerWeights.length > 0 && (!this.disableWeighingPage)) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.navService.setBackButton({ visible: true, routerLink: this.sessionId ? `${receivingConstants.Routes.DataEntry}/${this.sessionId}` : receivingConstants.Routes.DataEntry });
    this.selectedMaterialSubscription = this.receivingStateService.getReceivingSession().subscribe(session => {
      if (session === null || session === undefined) {
        return;
      }
      this.receivingSession = session;
      this.checkForContainerId();

    });

    this.selectedMaterialSubscription = this.receivingStateService.getReceivingStateModel().subscribe(receivingStateModel => {
      if (receivingStateModel == null || receivingStateModel == undefined) {
        this.router.navigate([receivingConstants.Routes.Search]);
      } else {
        this.receivingStateModel = receivingStateModel;
      }
    });

    await this.loadInitialData();
    const lookupvalues = await this.receivingService.getLookupValues(`${receivingConstants.LookupTypes.UnitOfMeasures}`);
    this.unitOfMeasureCollection = this.receivingService.loadLookupValues(receivingConstants.LookupTypes.UnitOfMeasures, lookupvalues);

    // this.focusTareWeight();
    this.focusKnownWeight();
  }

  ngOnDestroy(): void {
    this.selectedMaterialSubscription.unsubscribe();
    this.signalRService.stopConnection();
    if (this.signalRServiceSubscription)
      this.signalRServiceSubscription.unsubscribe();
  }

  async loadInitialData() {
    this.scalesList = await this.receivingService.getScales(receivingConstants.ScaleType.Receiving);
    if (this.scalesList.length > 0) this.scaleID = this.scalesList.filter(X => X.isGLP == false)[0].scaleID;
    this.selectDefaultScale();

    this.signalRServiceSubscription = this.signalRService.events.subscribe(event => {
      if (event.type == SignalREventType.UpdateWeight) {
        const updateWeightRequest: UpdateWeightRequest = event.data;
        if (updateWeightRequest.scaleId === this.selectedScale.scaleID) {
          this.actualWeight = updateWeightRequest.weightKG;
          this.changeDetectorRef.detectChanges();
        }
        console.log("Reading from scale for scale is ", updateWeightRequest.scaleId, " with weight in KG is ", (+updateWeightRequest.weightKG).toFixed(7));
      } else {
        console.log(`Event from signalRService: event.type:${event.type}, event.data: ${event.data}`);
      }
    });
    this.signalRService.startConnection();
  }

  onScaleSelect(event, scale: ScalesModel) {
    if (this.selectedScale && this.selectedScale !== scale) {
      this.selectedScale.isSelected = false;
      this.selectedScale = undefined;
    }
    if (scale.isSelected === false) {
      scale.isSelected = false;
      this.selectDefaultScale();
    }
    else {
      scale.isSelected = true;
      this.selectedScale = scale;
    }
    this.actualWeight = 0;

    this.stationService.retrieveScale(this.selectedScale?.stationID, this.selectedScale?.scaleID).pipe(
      tap(scale => {
        this.actualWeight = scale.weightKG;
        this.selectedScale.weightKG = scale.weightKG;
        this.toastr.showSuccess(`Scale ${this.selectedScale.name} selected`, 3000, "Scale Selected");
        this.loadingService.clearMessage();
      }),
      take(1)
    ).subscribe();
  }

  selectDefaultScale() {
    this.scalesList.forEach(X => {
      if (X.scaleID === this.scaleID) {
        X.isSelected = true;
        this.selectedScale = X;
      }
    });

    this.stationService.retrieveScale(this.selectedScale?.stationID, this.selectedScale?.scaleID).pipe(
      tap(scale => {
        this.actualWeight = scale.weightKG;
        this.selectedScale.weightKG = scale.weightKG;
      }),
      take(1)
    ).subscribe();
  }

  async saveWeights() {
    if (this.receivingSession.catalogRecordID > 0 && this.disableWeighingPage === true) {
      this.isDirty = false;
      this.router.navigate([receivingConstants.Routes.DocUpload]);
    } else {
      this.loadingService.show("Saving weights & creating containers...");
      try {
        this.isDirty = false;
        const session = await this.receivingService.setContainerUpdate({ sessionID: this.receivingSession.sessionID, sessionContainerWeights: this.containerWeights, metadata: this.receivingStateModel.material.metadata } as ContainerWeightModel);
        this.receivingStateService.setReceivingSession(session);
        this.toastr.showSuccess('Container weight(s) added successfully');
        const nextUrl = this.sessionId ? `${receivingConstants.Routes.DocUpload}/${this.sessionId}` : receivingConstants.Routes.DocUpload
        this.router.navigate([nextUrl]);
      }
      catch (error) {
      }
      finally {
        this.loadingService.clearMessage();
      }
    }

  }
  validateKnownWeightAndCalculateTareWeight() {
    this.tareWeight = 0;
    this.isAcceptWeightDisabled = true;
    this.toastr.clearMessages();
    if (Number(this.knownWeight) >= this.actualWeight) {
      this.toastr.showError('Known weight must be less than Actual weight');
      return;
    }
    if (Number(this.knownWeight) > 0) {
      this.tareWeight = this.actualWeight - Number(this.knownWeight);
      this.tareWeight = Number(this.tareWeight.toFixed(8));
      this.isAcceptWeightDisabled = false;
    }
  }

  validateTareWeight() {
    this.isAcceptWeightDisabled = true;
    this.toastr.clearMessages();
    if (this.tareWeight < 0) {
      this.toastr.showError('Tare weight must be greater than 0');
      return;
    }
    if (this.tareWeight == 0) return;
    if (this.actualWeight <= 0) {
      this.toastr.showError('Actual weight must be greater than 0');
      return;
    }
    if (this.tareWeight >= this.actualWeight) {
      this.toastr.showError('Tare weight must be less than actual weight');
      return;
    }
    if (this.containerWeights.length === this.receivingSession.noOfContainers) {
      this.toastr.showError(`you can add up to ${this.receivingSession.noOfContainers}. Remove from accepted weight to add more`);
      return;
    }
    if (this.tareWeight.toString() == '.') return;
    this.isAcceptWeightDisabled = false;
  }

  acceptWeight() {
    this.containerWeights = this.containerWeights.concat([{ tareWeight: this.tareWeight, containerWeight: this.actualWeight, catalogInventoryItemId: 0 }]);
    this.tareWeight = 0;
    this.knownWeight = 0;
    this.isDirty = true;
    if (this.containerWeights.length < this.receivingSession.noOfContainers)
      // this.focusTareWeight();
      this.focusKnownWeight();
  }

  focusTareWeight() {
    this.tareWeigh?.nativeElement.focus();
    setTimeout(() => {
      this.tareWeigh?.nativeElement.select();
    }, 1);
  }

  focusKnownWeight() {
    this.knownweigh?.nativeElement.focus();
    setTimeout(() => {
      this.knownweigh?.nativeElement.select();
    }, 1);
  }

  logDefect() {
    if (this.receivingSession.isGLP) {
      this.displayDefectDialog = true;
    } else {
      this.toastr.showError('Cannot log defect for GLP material');
    }
  }

  deleteWeight(weight: ContainerWeights) {
    this.containerWeights.forEach((value, index) => {
      if (value === weight) this.containerWeights.splice(index, 1);
    });
    if (this.containerWeights.length == 0) {
      this.isDirty = false;
    }
  }

  allContainersAreWeighted() {
    if (this.containerWeights.length < this.receivingSession?.noOfContainers) {
      return false;
    }
    else {
      return true;
    }
  }

  enableSaveAndContinue() {
    if (this.containerWeights.length == this.receivingSession?.noOfContainers) {
      return false;
    }
    else {
      return true;
    }
  }

  onTareWeightChange(tareWeightval) {
    if (tareWeightval.charAt(0) === '.' && tareWeightval.length > 1) {
      this.tareWeight = (Number(tareWeightval) + 0);
    }
  }

  onKnownWeightChange(knownWeightval) {
    if (knownWeightval.charAt(0) === '.' && knownWeightval.length > 1) {
      this.knownWeight = knownWeightval + 0;
    }
  }
  checkForContainerId() {
    if (this.receivingSession.catalogRecordID > 0) {
      this.containerWeights = this.receivingSession.sessionContainerWeights;
      if (this.receivingSession.sessionContainerWeights.filter(X => X.catalogInventoryItemId == 0).length == 0) {
        this.buttonText = "Continue "
        this.disableWeighingPage = true;
      }
    }
    else if (this.receivingSession.sessionContainerWeights != null && this.receivingSession.sessionContainerWeights != undefined) {
      if (this.receivingSession.sessionContainerWeights.length > 0) {
        this.containerWeights = this.receivingSession.sessionContainerWeights;
      }
    }
  }

  getActionButtonVisibility(): boolean {
    const dimension: DOMRect = this.navigate?.nativeElement.getBoundingClientRect();
    if (!dimension)
      return;

    return (window as any).visualViewport.height >= (dimension.top + (dimension.height / 2));
  }

  async confirmDefect() {
    this.loadingService.show("Saving Defect...");
    let defectRequest: DefectRequest = {
      actualContainerWeight: this.defectActualWeight,
      anticipatedContainerWeight: this.defectAnticipatedTareWeight,
      sessionId: this.receivingSession.sessionID
    }
    this.isDirty = false;
    this.disableWeighingPage = true;
    await this.receivingService.CreateDefect(this.receivingSession.sessionID, defectRequest);
    this.toastr.showSuccess('Defect has been created.');
    this.router.navigate([receivingConstants.Routes.Search]);
    this.loadingService.clearMessage();
  }
}


@Pipe({
  name: 'indexOf'
})
export class IndexOfPipe implements PipeTransform {

  transform(items: any[], item: any): any {
    return items.indexOf(item);
  }

}