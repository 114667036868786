import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router"
import { BreadCrumbNavigationService } from '../../breadcrumb/services/bread-crumb-navigation.service';
import { NavigationState } from '../models/navigation-state.model';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-navigate',
  templateUrl: './navigate.component.html',
  styleUrls: ['./navigate.component.scss']
})
export class NavigateComponent implements OnInit {

  goNextState: NavigationState;
  goBackState: NavigationState;
  customButtons: NavigationState[] = [];


  constructor(private router: Router,
    private navService: NavigationService,
    private navigationService: BreadCrumbNavigationService) { }

  ngOnInit(): void {
    this.navService.backButtonSubscription.subscribe(state => {
      this.goBackState = state;
    });
  }

  onBackClick() {
    this.navigationService.navigateBack();
  }
}
