import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { DecantStation, Scale } from 'src/app/models/decant-models';
import { InventoryItem } from 'src/app/models/inventory-models';
import { OrderItemContainerModel } from 'src/app/models/order-item-container-model';
import { UpdateContainerRequest } from 'src/app/models/update-container-model';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';

@Component({
  selector: 'app-scale-control2',
  templateUrl: './scale-control2.component.html',
  styleUrls: ['./scale-control2.component.scss']
})
export class ScaleControl2Component implements OnInit, OnChanges {

  @Input() mode: string;
  @Input() decantStation: DecantStation;
  @Input() selectedContainer: OrderItemContainerModel;
  @Input() isReweighMode: boolean;
  @Input() selectedInventoryItem: InventoryItem;
  @Input() unitOfMeasure: string;
  @Output() weightCaptured: EventEmitter<boolean> = new EventEmitter();
  @Output() getStation = new EventEmitter();
  @Input() isTestMode: boolean = false;
  isCaptureClick: boolean = false;

  private subscriptions = new Subscription();

  public uom: string;
  public readyToDisplay: boolean;
  public overageAmount: number;
  public isFirstVisit: boolean = true;
  public reweighReady: boolean = false;

  constructor(
    private orderService: OrderService,
    private inventoryService: InventoryService,
    private responseHandler: ResponseHandlingService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.decantStation) {
      let weightSum = 0;
      this.decantStation.scales.forEach(scale => {
        weightSum += scale.weightKG;
      });
      this.isFirstVisit = (this.isFirstVisit && weightSum === 0) ?? false;
      this.reweighReady = ((this.isFirstVisit && weightSum === 0) || (!this.isFirstVisit)) ? true : false;
    }

    if (changes.selectedContainer) {
      this.overageAmount = (this.selectedContainer?.amount * 0.03) + this.selectedContainer?.amount;
    }
  }

  ngOnInit() {
    this.overageAmount = (this.selectedContainer?.amount * 0.03) + this.selectedContainer?.amount;
    this.readyToDisplay = true;
  }

  isCaptureWeightDisabled(scale: Scale): boolean {
    // Need to round all the numbers to a consistent number of decimal places
    // before determining if the capture button should be available. In watching
    // the scale data, there are cases in which we are getting a ridiculous number
    // of decimal places from the scale that could be causing the math to not work out.
    var roundingFactor: number = Math.pow(10, scale.decimalPlaces);
    var roundedWeight: number = Math.round(scale.weightKG * roundingFactor) / roundingFactor;
    var roundedContainerWeight: number = Math.round(this.selectedContainer?.amount * roundingFactor) / roundingFactor;
    var roundedOverageAmount: number = Math.round(this.overageAmount * roundingFactor) / roundingFactor;

    return (roundedWeight < roundedContainerWeight) || (roundedWeight > roundedOverageAmount) || this.isCaptureClick;
  }

  resetCaptureButton(): void {
    this.isCaptureClick = false;
  }

  onCaptureClick(scale: Scale) {
    this.isCaptureClick = true;
    if (this.isReweighMode) {
      this.subscriptions.add(this.inventoryService.updateInventoryItemForReweigh(this.selectedInventoryItem.itemID, scale.weightKG)
        .subscribe(result => {
          if (this.mode == 'Standalone') {
            this.isFirstVisit = true;
            this.reweighReady = false;
          }
          this.isCaptureClick = false;
          this.weightCaptured.emit(true);
          this.responseHandler.showSuccess(`Successfully Captured Weight`);
        },
          error => {
            this.isCaptureClick = false;
            this.responseHandler.showError(`Error: Bad Request. Weight: (` + scale.weightKG + `)`);
          }));

    } else {
      var request: UpdateContainerRequest = {
        actualInventoryCatalogID: this.selectedInventoryItem.catalogRecordID,
        actualLotNumber: this.selectedInventoryItem.lotNumber,
        actualAmount: scale.weightKG,
        actualExpirationDate: this.selectedInventoryItem.expirationDate,
        parentContainerID: this.selectedInventoryItem.itemID,
        actualReceivedDate: new Date(this.selectedInventoryItem.postDate),
        locationID: this.selectedInventoryItem.locationID,
        locationName: this.selectedInventoryItem.fullLocationName,
        actualManufacturerLotNumber: this.selectedInventoryItem.manufacturerLotNumber
      };
      this.subscriptions.add(this.orderService.updateContainer(this.selectedContainer.orderItemContainerID, request)
        .subscribe(result => {
          this.isCaptureClick = true;
          this.weightCaptured.emit(true);
          this.responseHandler.showSuccess(`Successfully Captured Weight`);
        },
          error => {
            this.isCaptureClick = false;
            this.responseHandler.showError(`Error: Bad Request`);

          }));
    }
  }

  private formatAmount(amount: number): number {
    return (amount < 1) ? (amount * 1000) : amount;
  }

  private setProgressBar(currentAmount: number, desiredAmount: number) {
    const progress = (this.isReweighMode) ? 100 : (currentAmount / desiredAmount) * 100;
    return (progress >= 103)
      ? 'red'
      : (progress < 103 && progress >= 100)
        ? 'green'
        : 'yellow'
  }

  private setProgressWidth(currentAmount: number, desiredAmount: number) {
    const progress = (this.isReweighMode) ? 100 : (currentAmount / desiredAmount) * 100;
    return (progress >= 100) ? { width: '100%' } : { width: progress + '%' };
  }
}
