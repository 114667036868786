<h5>Material Selection</h5>
<div>
    <div class="p-mb-2 p-text-right" *ngIf="materialList.length > 0">
        <p class="p-mb-0">Results : {{materialList.length}}</p>
    </div>

    <p-accordion (onOpen)="onMaterialTabOpen($event)" (onClose)="onMaterialTabClose()" [styleClass]="'p-mb-6'">
        <p-accordionTab header="{{material.materialName}}" *ngFor="let material of materialList;let materialIndex=index"
            [(selected)]="material.isSelected" [id]="'tabMaterial_' + material.materialId">
            <p-header style="width: 100%;">
                <div class="p-grid p-text-normal" style="margin-top: 0px;">
                    <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">{{material.materialName}}</div>
                    <div class="p-sm-hidden p-md-6 p-lg-6 p-xl-6">
                        <span *ngIf="material.metadata && material.metadata.AlternateName">
                            <small>{{material.metadata.AlternateName}}</small>
                        </span>
                    </div>
                    <div class="p-sm-hidden p-md-2 p-lg-2 p-xl-2">
                        <span *ngIf="material.metadata && material.metadata.Status">
                            <small>{{material.metadata.Status}}</small>
                        </span>
                    </div>
                </div>
            </p-header>
            <p-body>
                <div class="p-grid p-md-hidden p-lg-hidden p-xl-hidden">
                    <div class="p-col-12">
                        <span *ngIf="material.metadata && material.metadata.AlternateName">
                            <small><span class="p-text-bold">Common Name:
                                </span>{{material.metadata.AlternateName}}</small>
                        </span>
                    </div>
                    <div class="p-col-12">
                        <span *ngIf="material.metadata && material.metadata.Status">
                            <small><span class="p-text-bold">Status: </span>{{material.metadata.Status}}</small>
                        </span>
                    </div>
                </div>
                <ng-container [ngSwitch]="material.materialTypeId">
                    <ng-container *ngSwitchCase="materialTypeCompound">
                        <ng-container *ngIf="material.materialId == selectedMaterial?.materialId">
                            <ng-container *ngTemplateOutlet="spinnerTemplate; context: {material: material}">
                            </ng-container>
                            <receiving-compound-suppliers [suppliers]="material.suppliers" [material]="material"
                                [dataAdapter]="dataAdapter" *ngIf="!material.$isSupplierLoading">
                            </receiving-compound-suppliers>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="materialTypeBionexus">
                        <ng-container *ngIf="material.materialId == selectedMaterial?.materialId">
                            <ng-container *ngTemplateOutlet="spinnerTemplate; context: {material: material}">
                            </ng-container>
                            <receiving-compound-suppliers [suppliers]="material.suppliers" [material]="material"
                                [dataAdapter]="dataAdapter" *ngIf="!material.$isSupplierLoading">
                            </receiving-compound-suppliers>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <ng-container
                            *ngTemplateOutlet="defaultSupplierTemplate; context: {material:material, materialIndex : materialIndex}">
                        </ng-container>
                    </ng-container>
                </ng-container>


            </p-body>
        </p-accordionTab>
    </p-accordion>

    <ng-template #addressNotFound let-supplier="supplier">
        <span class="p-error">Address not found.</span>
        <ng-template pTemplate="footer">
            <ng-container *ngTemplateOutlet="continueButton; context: {supplier: supplier, address:undefined}">
            </ng-container>
        </ng-template>
    </ng-template>
    <ng-template #continueButton let-supplier="supplier" let-address="address">
        <div class="p-fluid p-text-right p-card-continue-button" *ngIf="supplier.isSelected">
            <button (click)="OnContinueClicked()" pButton pRipple type="button"
                [disabled]="!address || !address.isSelected" class="p-card-continue-button" icon="pi pi-angle-right"
                iconPos="right" label="Continue" class="p-text-normal"></button>
        </div>
    </ng-template>
    <app-navigate></app-navigate>
</div>

<ng-template #spinnerTemplate let-material="material">
    <p-progressSpinner *ngIf="material.$isSupplierLoading"></p-progressSpinner>
</ng-template>

<ng-template #defaultSupplierTemplate let-material="material" let-materialIndex="materialIndex">
    <ng-container *ngTemplateOutlet="spinnerTemplate; context: {material: material}">
    </ng-container>
    <div class="no-supplier-error p-error" *ngIf="supplierAddressErr">
        <div>Could not load suppliers</div>
        <!-- <div>
                        <button pButton class="p-button-text" icon="pi pi-refresh" iconPos="left"></button>                    
                    </div> -->
    </div>
    <div class="p-d-flex p-flex-column p-flex-md-row p-flex-wrap"
        *ngIf="!material.$isSupplierLoading && material.suppliers && material.suppliers.length > 0">
        <div class="p-col p-m-2" *ngFor="let supplier of material.suppliers">
            <p-card (click)="onSupplierSelect($event,materialIndex,supplier)" styleClass="p-supplier-card"
                [ngClass]="{'p-highlight': supplier.selectedAddress, 'p-selected': supplier.isSelected}">
                <ng-template pTemplate="header">
                    <div class="p-d-flex p-jc-between p-pl-3 p-pt-3 p-pr-3">
                        <div class="p-fluid">
                            <span [ngClass]="{'italic-font': supplier.IsCreateNewLot,'p-card-title': true}">{{supplier.name}}</span>
                        </div>
                        <div class="p-fluid" *ngIf="supplier.metadata?.manufacturerLotId">
                            <span class="p-card-title">{{supplier.metadata?.manufacturerLotId}}</span>
                        </div>
                        <div class="p-text-right p-card-selected-button" *ngIf="supplier.selectedAddress">
                            <button pTooltip="indicates selected supplier" pButton pRipple type="button"
                                icon="pi pi-check-square" class="p-button-text"></button>
                        </div>
                    </div>
                </ng-template>
                <ng-container *ngIf="!supplier.address || supplier.address.length == 0">
                    <ng-container *ngTemplateOutlet="addressNotFound; context:{supplier: supplier}">
                    </ng-container>
                </ng-container>
                <div *ngIf="supplier.address && supplier.address.length > 0">
                    <div *ngFor="let address of supplier.address;" class="p-grid app-supplier-address">
                        <div *ngIf="supplier.address.length > 1"
                            class="p-pr-5 p-justify-center p-justify-content-center p-p-2">
                            <p-inputSwitch [(ngModel)]="address.isSelected"
                                (onChange)="onAddressSelect($event, address, supplier, materialIndex)"
                                styleClass="p-mt-2" (click)="$event.stopPropagation()">
                            </p-inputSwitch>
                        </div>
                        <div class="p-col">
                            <p>
                                <span class="p-text-bold">Street Address:</span>
                                {{address.streetAddress}}
                            </p>
                            <p *ngIf="address.locality">
                                <span class="p-text-bold">Locality: </span>
                                {{address.locality}}
                            </p>
                            <p *ngIf="address.country">
                                <span class="p-text-bold">Country: </span>
                                {{address.country}}
                            </p>
                        </div>
                    </div>
                </div>
                <ng-template pTemplate="footer">
                    <ng-container
                        *ngTemplateOutlet="continueButton; context: {supplier: supplier, address: selectedAddress }">
                    </ng-container>
                </ng-template>
            </p-card>
        </div>
    </div>
    <div class="p-d-flex p-jc-center p-p-3 p-text-bold"
        *ngIf="!material.$isSupplierLoading && material.suppliers && material.suppliers.length == 0">
        No Supplier(s) found
    </div>
</ng-template>