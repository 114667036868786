import { AddressViewModel } from '../../models/address.model';
import { MaterialModel, MaterialViewModel } from '../../models/material.model';
import { SupplierModel, SupplierViewModel } from '../../models/supplier.model';
import { IMaterialDataAdapter } from './material-data-adapter';
import { CompoundCertificationStatus } from '../../models/material/compound.enums';
import { ReceivingService } from '../receiving.service';
import { ReceivingStateService } from '../receiving-state.service';
import { ValidationViewModel } from '../../models/view-models/validation-view.model';
import { ReceivingStateViewModel, SessionModel } from '../../models/session.model';
import { SDSConsumer } from '../../models/view-models/status-watcher.model';
import { ExternalServiceType } from '../../models/enumerations';
import { receivingConstants, getDateFromDateTime } from '../../receiving.constants';
import { DataEntryViewModel } from '../../models/view-models/dataentry-view.model';

export class CompoundDataAdapter implements IMaterialDataAdapter {
  vendors: SupplierModel[] = [];
  constructor(private receivingService: ReceivingService, private receivingStateService: ReceivingStateService) {

  }
  toDataEntryViewModel(model: ReceivingStateViewModel): DataEntryViewModel {
    return {
      disablePeroxideForming: !!(model.material?.metadata?.IsPeroxideForming && model.material.peroxideForming),
      yearsToExpiration: receivingConstants.DefaultYearsToExpiration
    }
  }

  toSupplierViewModelFromLot(model: MaterialModel): SupplierViewModel {
    var ret = {
      address: null,
      name: model.metadata?.PreparedBy?.toString(),
      supplierId: model.metadata?.PreparedById?.toString(),
      materialId: model.materialId,
      selectedAddress: null,
      isSelected: true
    }
    return ret;
  }

  toValidationViewModel(model: ReceivingStateViewModel): ValidationViewModel {
    return {
      materialName: model.material?.materialName,
      alternateName: model.material?.alternateName,
      lotNumber: model.material?.lotNumber,
      isGLP: model.material?.isGLP,
      SDS: model.material?.SDS,
      COA: model.material?.COA,
      checkForSDS: false,
      shouldDisableGLPControl: false,
      supplier: model.supplier?.name,
      isLoading: false,
      address: model.address,
      sdsModel: null
    }

  }

  ToMaterialViewModelFromLot(model: MaterialViewModel): MaterialViewModel {
    model.COA = false;
    model.SDS = false;
    model.checkForSDS = false;
    model.peroxideForming = Boolean(model.metadata?.IsPeroxideForming);
    model.moleculeType = model.metadata?.MoleculeType?.toString();
    model.molecularWeight = +model.metadata?.MolWeight;
    model.displaySubmittedWeight = false;

    // SM 07-25-2023 Added missing fields from repository metadata response
    model.desiccant = Boolean(model.metadata?.Desiccant);
    model.storageConditions = model.metadata?.StorageConditions?.toString();
    model.shippingConditions = model.metadata?.ShippingConditions?.toString();
    return model;
  }

  toAddressViewModel(model: MaterialModel): AddressViewModel {
    const addressModel = model.metadata?.SupplierAddress as AddressViewModel;
    addressModel.isSelected = true;
    return addressModel;
  }

  toSupplierViewModel(model: MaterialModel): SupplierViewModel {
    return {
      address: null,
      name: receivingConstants.CortevaName, //TODO: Name to come from Api for 'selected' vendor
      supplierId: "1",  //TODO: Id to come from Aoi for 'selected' Vendor
      materialId: model.materialId,
      selectedAddress: null,
      isSelected: true
    }
  }
  toMaterialViewModel(model: MaterialModel): Partial<MaterialViewModel> {
    let manufacturerDate = (model.metadata?.CertificationDate ? model.metadata?.CertificationDate : model.metadata?.PreparedDate);
    manufacturerDate = getDateFromDateTime(manufacturerDate?.toString());
    let expirationDate = (+model.metadata?.CertificationStatusTypeId == CompoundCertificationStatus.Certified || +model.metadata?.CertificationStatusTypeId == CompoundCertificationStatus.Pending ||
      +model.metadata?.CertificationStatusTypeId == CompoundCertificationStatus.Intended)
      ? (model.metadata?.RecertificationDate?.toString()) : (model.metadata?.ExpirationDate?.toString());

    expirationDate = getDateFromDateTime(expirationDate?.toString());
    return {
      alternateName: model.alternateName,
      lotNumber: model.lotNumber,
      lotId: model.lotId,
      materialId: model.materialId,
      materialName: model.materialName,
      materialTypeId: model.materialTypeId,
      metadata: model.metadata,
      supplierId: model.metadata?.PreparedById.toString(),
      supplierName: model.metadata?.PreparedBy.toString(),
      COA: false,
      SDS: false,
      isGLP: (+model.metadata?.CertificationStatusTypeId == CompoundCertificationStatus.Certified || +model.metadata?.CertificationStatusTypeId == CompoundCertificationStatus.Pending ||
        +model.metadata?.CertificationStatusTypeId == CompoundCertificationStatus.Intended)
        ? true : false,
      GLP: model.metadata?.CertificationStatusTypeId?.toString(),
      certificationDate: getDateFromDateTime(model.metadata?.CertificationDate),
      recertificationDate: getDateFromDateTime(model.metadata?.RecertificationDate),
      tsn: model.metadata?.TsnNumber?.toString(),
      molecularWeight: +model.metadata?.MolWeight,
      submittedWeight: +model.metadata?.SubmittedAmount,
      displaySubmittedWeight: false,
      expirationDate: expirationDate?.toString(),
      manufacturerDate: manufacturerDate?.toString(),
      disableManufaturerLodIdControl: model.lotNumber !== undefined,
      moleculeType: model.metadata?.MoleculeType?.toString(),
      density: model.metadata?.Density ? +model.metadata?.Density?.toString() : 1,
      peroxideForming: Boolean(model.metadata?.IsPeroxideForming),
      shippingConditions: model.metadata?.ShippingConditions?.toString(),
      storageConditions: model.metadata?.StorageConditions?.toString(),
      desiccant: Boolean(model.metadata?.Desiccant)
    };
  }

  loadSuppliers(model: MaterialModel): Promise<SupplierModel[]> {
    if (this.vendors.length == 0) {
      return this.receivingService.getSuppliersByMaterial(model.materialId, ExternalServiceType.Compound)
        .then(data => {
          this.vendors = data;
          return Promise.resolve(this.vendors);
        });
    } else {
      return Promise.resolve(this.vendors);
    }
  }

  getSDSData(model: MaterialViewModel, statusWatcher: SDSConsumer): Promise<MaterialViewModel> {
    return Promise.resolve(model as MaterialViewModel);
  }

  getMaterial(model: MaterialViewModel): Promise<MaterialViewModel> {
    return Promise.resolve(model as MaterialViewModel);
  }

  getSessionToMaterialModel(model: SessionModel): Partial<MaterialViewModel> {
    return {
      alternateName: model.alternateName,
      lotNumber: model.lotNumber,
      materialId: model.materialId,
      materialName: model.materialName,
      materialTypeId: model.materialTypeId,
      supplierId: model.supplierId,
      COA: model.isCoaavailable,
      SDS: model.isSdsavailable,
      certificationDate: model.certificationDate,
      recertificationDate: model.recertificationDate,
      expirationDate: model.expirationDate,
      manufacturerDate: model.manufacturerDate,
      reachComplaint: model.reachCompliant,
      isGLP: model.isGLP,
      GLP: model.GLP,
      disableManufaturerLodIdControl: model.lotNumber?.length > 0,
      supplierName: model.supplierName,
      formulationType: model.formulationType,
      stage: model.stage,
      density: model.density,
      countryOfOriginId: model.countryOfOriginId,
      moleculeType: model.moleculeType,
      tsn: model.tsn,
      peroxideForming: model.peroxideForming,
      metadata: model.metadata
    };
  }
}
