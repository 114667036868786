import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderAttachment, OrderItemAttachment, ViewAttachmentsModalData } from 'src/app/models/attachment-models';
import { DownloadAttachmentService } from 'src/app/services/download-attachment-service/download-attachment.service';
import { OrderItemAttachmentService } from 'src/app/services/order-item-attachments-service/order-tem-attachments.service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';

@Component({
  selector: 'app-view-attachments-modal',
  templateUrl: './view-attachments-modal.component.html',
  styleUrls: ['./view-attachments-modal.component.scss']
})
export class ViewAttachmentsModalComponent implements OnInit {

  documentTypes: any[] = [];

  constructor(public dialogRef: MatDialogRef<ViewAttachmentsModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ViewAttachmentsModalData, private responseHandler: ResponseHandlingService, private orderService: OrderService, private orderItemAttachmentService: OrderItemAttachmentService, private downloadAttachmentService: DownloadAttachmentService) { }

  ngOnInit(): void {
    const docTypes: DocumentType[] = JSON.parse(localStorage.getItem("documentTypes"));
    if (docTypes == null) {
      this.orderItemAttachmentService.retrieveDocumentTypes()
        .subscribe(data => {
          this.documentTypes = data;
          localStorage.setItem('documentTypes', JSON.stringify(this.documentTypes));
        });
    }
    else {
      this.documentTypes = docTypes;
    }
  }

  public getDocumentType(id: number): string {
    return this.documentTypes?.find(d => d.documentTypeID === id).name;
  }

  downloadOrderAttachment(val: OrderAttachment): void {
    this.orderService.getOrderAttachment(val.orderAttachmentID).subscribe(response => {
      this.downloadAttachmentService.download(val.filename, response.fileContents);
    },
      error => {
        this.responseHandler.showError(error.message, 5000);
      });
  }

  downloadOrderItemAttachment(val: OrderItemAttachment): void {
    this.orderItemAttachmentService.getOrderItemAttachmentList(val.orderItemAttachmentID).subscribe(response => {
      this.downloadAttachmentService.download(val.filename, response.fileContents);
    },
      error => {
        this.responseHandler.showError(error.message, 5000);
      });
  }

  close(): void {
    this.dialogRef.close();
  }

}
