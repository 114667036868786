import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateOrderAttachmentRequest } from 'src/app/models/attachment-models';
import { DocumentTypes } from 'src/app/models/order-models';
import { OrderItemAttachmentService } from 'src/app/services/order-item-attachments-service/order-tem-attachments.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-coa-modal',
  templateUrl: './coa-modal.component.html',
  styleUrls: ['./coa-modal.component.scss']
})
export class CoaModalComponent implements OnInit {

  coaData: any;
  orderItemID: any;
  pdfSrc: any;

  constructor(public dialogRef: MatDialogRef<CoaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
    private orderItemAttachmentService: OrderItemAttachmentService,
    private responseHandler: ResponseHandlingService) {
    this.coaData = this.data.coaData;
    this.orderItemID = this.data.orderItemID;
    if (this.coaData.originalFilename.endsWith('pdf')) {
      var blob = this.dataURItoBlob(`data:application/pdf;base64,${this.coaData.base64FileContents}`);
      this.pdfSrc = window.URL.createObjectURL(blob);
    }
  }

  ngOnInit(): void {
  }

  onDownload() {
    if (!this.coaData) return;

    var link = document.createElement('a');
    if (this.coaData.originalFilename.split('.').pop() === 'docx') {
      link.href = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${this.coaData.base64FileContents}`;
    }
    else if (this.coaData.originalFilename.split('.').pop() === 'pdf') {
      link.href = `data:application/pdf;base64,${this.coaData.base64FileContents}`;
    }
    if (!link.href) return;
    link.download = this.coaData.originalFilename;
    link.click();
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  attachToOrder() {
    let fileContents;
    if (this.coaData.originalFilename.split('.').pop() === 'docx') {
      fileContents = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${this.coaData.base64FileContents}`;
    }
    else if (this.coaData.originalFilename.split('.').pop() === 'pdf') {
      fileContents = `data:application/pdf;base64,${this.coaData.base64FileContents}`;
    }

    var blob = this.dataURItoBlob(fileContents);
    var file = new File([blob], this.coaData.originalFilename);
    const uploadedFiles = [file];
    const defaultLanguageID = JSON.parse(localStorage.getItem('languages'))?.find(l => l.isDefault).languageID;
    this.orderItemAttachmentService.saveRequestItemAttachment(uploadedFiles, this.orderItemID, DocumentTypes.COA, this.coaData.comments, defaultLanguageID).subscribe((result) => {
      this.responseHandler.showSuccess(`File "${this.coaData.originalFilename}" attached successfully`, 5000);
      if (!result)
        this.responseHandler.showError(`Failed to fetch order item attachment details`, 5000);
      this.dialogRef.close(result);
    },
      error => {
        this.responseHandler.showError(error.message, 5000);
      });
  }

  close() {
    const dialogData =
      new ConfirmDialogModel("Confirm",
        `Do you want to close without attaching to order?`,
        "Confirm", true);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      disableClose: true,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult)
        this.dialogRef.close();
    });
  }

}
