<div class="flex-grid">
    <div class="col">
        <h4>Disposal Log</h4>
    </div>
</div>
<div class="container">
    <form [formGroup]="searchCriteriaForm" (ngSubmit)="onSearch()">
        <input formControlName="searchCriteria" type="text" pInputText placeholder="Enter material name or number to search"   [ngClass]="searchCriteriaForm.controls['searchCriteria'].invalid && submitted ? 'error' : ''"/>
        <button class="submit p-mr-2" mat-raised-button color="cor-primary" type="submit">Search</button>
        <div *ngIf="searchCriteriaForm.controls['searchCriteria'].invalid && submitted && (searchCriteriaForm.controls['searchCriteria'].dirty || searchCriteriaForm.controls['searchCriteria'].touched)">
            <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="searchCriteriaForm.controls.searchCriteria.errors?.required">
                Field required.
            </small>
            <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="searchCriteriaForm.controls.searchCriteria.errors?.minlength">
                Enter minimum 3 characters to perform a search
            </small>                   
        </div>
    </form>
</div>
<div>
    <app-ag-grid-custom-paginator (paginatorCommand)="handlePaginatorCommand($event)" [paginatorModel]="paginatorModel"
        [showPageSizes]="true"></app-ag-grid-custom-paginator>

    <ag-grid-angular style="width:100%; height: calc(100vh - 415px);" class="ag-theme-alpine" rowModelType="infinite"
        domLayout="normal" tooltipShowDelay="0" suppressCellSelection="true" alwaysShowVerticalScroll="true"
        [pagination]="true" [suppressPaginationPanel]="true" [paginationPageSize]="25" [cacheOverflowSize]="2"
        [maxConcurrentDatasourceRequests]="2" [infiniteInitialRowCount]="1" [columnDefs]="columnDefs"
        [cacheBlockSize]="100"  [overlayNoRowsTemplate]="overlayNoRowsTemplate" (cellValueChanged)="setDataSource($event)"
        [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" (gridSizeChanged)="onResizeGrid($event)"
        (sortChanged)="updateCurrentPage()">
    </ag-grid-angular>

    <app-ag-grid-custom-paginator (paginatorCommand)="handlePaginatorCommand($event)" [paginatorModel]="paginatorModel"
        [showPageSizes]="false"></app-ag-grid-custom-paginator>
</div>