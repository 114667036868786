
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { PeerToPeerService } from 'src/app/peer-to-peer/services/peer-to-peer.service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';

@Component({
  selector: 'app-customs-invoice-modal',
  templateUrl: './customs-invoice-modal.component.html',
  styleUrls: ['./customs-invoice-modal.component.scss']
})
export class CustomsInvoiceModalComponent implements OnInit {

  order: any;
  isP2P: false;
  downloadedFileName: string = "";
  hideBrokerButtons: boolean;
  hideDetailedBrokerButtons: boolean;
  enableAlternateBroker = false;
  selectedInvoiceType: 'Basic' | 'Detailed';
  selectedBroker: 'Primary' | 'Alternate';

  constructor(public dialogRef: MatDialogRef<CustomsInvoiceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private orderService: OrderService, private responseHandler: ResponseHandlingService, private loadingService: LoadingService) {
    this.order = this.data?.order;
  }

  ngOnInit(): void {
    this.requestDestinationGuide();
    this.selectedInvoiceType = null;
    this.selectedBroker = null;
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(this.downloadedFileName);
  }

  toggleDisplayBrokerButtons() {
    this.hideBrokerButtons = true;
    this.hideDetailedBrokerButtons = false;
    this.selectedBroker = null;
    this.selectedInvoiceType = 'Basic';
  }

  toggleDisplayDetailedBrokerButtons() {
    this.hideDetailedBrokerButtons = true;
    this.hideBrokerButtons = false;
    this.selectedBroker = null;
    this.selectedInvoiceType = 'Detailed';
  }

  requestDestinationGuide() {
    this.orderService.getDestinationGuide(this.order.countryID).subscribe(destinationGuideData => {
      this.enableAlternateBroker = destinationGuideData?.alternateBroker && destinationGuideData?.alternateBroker?.length > 0;
    });
  }

  download(base64String, fileName) {
    const source = `data:application/msword;base64,${base64String}`;
    var link = document.createElement('a');
    link.href = source;
    link.download = fileName;
    link.click();
  }

  getColor(brokerType: string) {
    if (this.selectedBroker === brokerType)
      return 'cor-primary';
    else
      return 'cor-secondary';
  }

  getInvoiceColor(invoiceType: string) {
    if (this.selectedInvoiceType === invoiceType)
      return 'cor-primary';
    else
      return 'cor-secondary';
  }

  onGenerateDocument(invoiceType: string) {
    let usePrimary: boolean;
    let useBasic: boolean;

    if (invoiceType === 'PrimaryBasic') {
      usePrimary = true;
      useBasic = true;
      this.selectedBroker = 'Primary';
    }
    else if (invoiceType === 'AlternateBasic') {
      usePrimary = false;
      useBasic = true;
      this.selectedBroker = 'Alternate';
    }
    else if (invoiceType === 'PrimaryDetailed') {
      usePrimary = true;
      useBasic = false;
      this.selectedBroker = 'Primary';
    }
    else if (invoiceType === 'AlternateDetailed') {
      usePrimary = false;
      useBasic = false;
      this.selectedBroker = 'Alternate';
    }
    this.loadingService.show('Generating document..');
    this.orderService.getCustomerInvoice(this.order.orderID, usePrimary, useBasic).subscribe({
      next: (response) => {
        this.loadingService.clearMessage();
        this.downloadedFileName = invoiceType + " Customs Invoice";
        this.download(response, `Invoice_${this.order.requestNumber}.docx`);
        this.confirm();
      },
      error: () => this.responseHandler.showError('Error downloading customs invoice, please try again later', 5000)
    })
  }
}