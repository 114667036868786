<h4>
    {{title}}
</h4>
<div class="mat-body">
    <p>{{message}}</p>

    <div *ngIf="scanNewParent && !hideScan" class="new-container">
        <span>Scan New Parent Container:&nbsp;</span> <br />
        <input #containerInputField type="text" [(ngModel)]="containerInput" pInputText placeholder="Barcode Entry">
    </div>

    <div #disposeReasonsMain *ngIf="scanNewParent">
        <label class="header-wrapper" for="disposalReasonsSelectList">Disposal Reason: </label><br />
        <p-dropdown style="width: 100%;" id="disposalReasonsSelectList" [options]="disposalReasons"
            [(ngModel)]="selectedDisposalReason" [appendTo]="disposeReasonsMain" styleClass="dispose-reasons"
            placeholder="Select Disposal Reason" optionLabel="disposalReasonName"
            optionValue="disposalReasonID"></p-dropdown>
    </div>

    <div *ngIf="scanNewParent" class="new-container">
        <label class="header-wrapper" for="disposalReasonsSelectList">Comments:&nbsp; </label><br />
        <input [(ngModel)]="comments" maxlength="255" pInputText id="comment">
    </div>

</div>
<div mat-dialog-actions style="float: right;">
    <button mat-raised-button color="cor-default" (click)="onDismiss()" style="float: right;">Cancel</button>
    <button mat-raised-button color="cor-danger"
        [disabled]="(scanNewParent) && ((!hideScan && !containerInput.trim()) || !selectedDisposalReason)"
        (click)="onConfirm()" style="float: right;">{{confirmButtonLabel}}</button>
</div>