<h4>
    Special Instructions
</h4>

<div class="mat-body" style="overflow: auto;max-height: 300px;">
    <ul>
        <div *ngIf='order?.specialInstructions'>
            <li>Request</li>
            <div class="p-p-2">
                <p [ngClass]="order?.specialInstructions ? 'highlight-yellow':''">
                    {{order?.specialInstructions}}</p>
            </div>
        </div>
        <div *ngIf='selectedOrderItem?.specialInstructions'>
            <li>{{selectedOrderItem?.containerLabelFmt}} </li>
            <div class="p-p-2">
                <p [ngClass]="selectedOrderItem?.specialInstructions ? 'highlight-yellow':''">
                    {{selectedOrderItem?.specialInstructions}}</p>
            </div>
        </div>
    </ul>
</div>

<div mat-dialog-actions style="float: right;">
    <button mat-raised-button color="cor-primary" (click)="onConfirm()" style="float: right;">OK</button>
</div>