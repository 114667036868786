<mat-expansion-panel class="p-mt-1 chemicalPanel" [expanded]="expanded" #chemicalPanel (opened)="refreshChemicalData()">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <i [ngClass]="allContainersAssigned ? 'pi pi-check green' : 'pi pi-exclamation-triangle gold'"></i>
            <span class="p-p-2" *ngIf="orderItem.itemURL">
                <a href="{{orderItem.itemURL}}" target="_blank"
                    (click)="preventExpansionChanged($event)">{{orderItem.containerLabelFmt}}</a>
            </span>
            <span class="p-p-2" *ngIf="!orderItem.itemURL">
                {{orderItem.containerLabelFmt}}
            </span>
            <button *ngIf="chemicalPanel.expanded && isLoginUserCoordinator" mat-raised-button color="cor-primary"
                [disabled]="order?.orderStatusID !== orderStatuses.Pending"
                (click)="onEditLabelButtonClick($event)">Edit Label</button>
            <span *ngIf="orderItem.isReachCompliant && chemicalPanel.expanded" class="p-ml-2">
                <app-pill Text="REACH Compliant Requested" Type="REACHCompliantRequested"></app-pill>
            </span>
            <span *ngIf="orderItem.extraPPERequired && chemicalPanel.expanded" class="p-ml-2">
                <app-pill Text="Extra PPE Required" Type="ExtraPPERequired"></app-pill>
            </span>
            <span class="p-ml-2" *ngIf="order?.useReservation && orderItem?.reservationTypeDesc">
                <app-pill Text="{{orderItem.reservationTypeDesc}}"
                    Type="{{orderItem?.reservationTypeDesc === 'Indy Reserved' ? 'IndyReserved' : orderItem?.reservationTypeDesc === 'SGS Reserved' ? 'SgsReserved' : ''}}">
                </app-pill>
            </span>
        </mat-panel-title>
        <span *ngIf="!chemicalPanel.expanded" class="p-ml-2 center">
            {{materialNumberLabel}}
            Requested Lot: {{orderItem?.requestedLotNumber ? orderItem?.requestedLotNumber : 'Any'}}, {{
            totalNumberOfContainers }} {{
            totalNumberOfContainers > 1 ? 'Containers' : 'Container'}},
            Total Amount: {{ totalAmount | weightKG}} {{ orderItem?.unitOfMeasureDesc}}
        </span>
    </mat-expansion-panel-header>
    <div style="justify-content: end; align-items: center; display: flex; cursor: pointer; margin-bottom: 10px;">
        <span class="material-icons" (click)="refreshChemicalData()">refresh</span>
    </div>
    <div class="first-row">
        <div *ngIf="orderItem?.vertereProductNumber && orderItem?.vertereProductSourceSystem">
            <label>{{orderItem?.vertereProductSourceSystem}} ID</label>
            <span>{{orderItem?.vertereProductNumber}}</span>
        </div>
        <div>
            <label>Requested Lot #</label>
            <span *ngIf="orderItem.requestedLotNumber && orderItem.lotURLFormat">
                <a href="{{orderItem.lotURLFormat.replace('{0}',orderItem.requestedLotNumber)}}"
                    target="_blank">{{orderItem.requestedLotNumber}}</a>
            </span>
            <span *ngIf="orderItem.requestedLotNumber && !orderItem.lotURLFormat">
                {{orderItem.requestedLotNumber}}
            </span>
            <span *ngIf="!orderItem.requestedLotNumber">
                Any
            </span>
        </div>
        <div>
            <label>Number of Containers</label>
            <span>{{ totalNumberOfContainers}}</span>
        </div>
        <div>
            <label>Total Amount</label>
            <span> {{ totalAmount | weightKG}} {{ orderItem?.unitOfMeasureDesc}}</span>
        </div>
        <div>
            <label>Special Instructions</label>
            <span [ngClass]="orderItem.specialInstructions ? 'highlight-yellow':''">{{orderItem?.specialInstructions ?
                orderItem.specialInstructions : 'No Instructions'}}</span>
        </div>
        <div [ngClass]="orderItem?.forecastItemID ? '':'special-instructions'">
            <label>Requires Permit?</label>
            <span>{{orderItem?.requiresPermit ? 'Yes' : 'No'}}</span>
        </div>
        <div class="special-instructions" *ngIf="orderItem?.forecastItemID">
            <label>Forecast Status</label>
            <span>{{orderItem?.forecastStatus}}</span>
        </div>
        <div class="button-div">
            <button id="btn-m-other-request" mat-raised-button color="cor-primary" (click)="viewOtherRequests()">
                View Other Requests
            </button>
        </div>
        <div class="button-div" *ngIf="orderItem?.forecastURL">
            <button mat-raised-button color="cor-primary" (click)="openForecastUrl()">
                View Forecast
            </button>
        </div>
        <div>
            <button mat-raised-button color="cor-primary" *ngIf="isLoginUserCoordinator"
                [disabled]="order?.orderStatusID === orderStatuses.New || order?.orderStatusID === orderStatuses.SGSRequested || allContainersAssigned"
                (click)="openEditContainers()">
                Edit Unassigned Containers
            </button>
        </div>
    </div>
    <div class="first-row">
        <div>
            <label>Import Tariff Code</label>
            <span>{{orderItem?.importTariffCode ? orderItem.importTariffCode : ''}}</span>
        </div>
        <div>
            <label>Export Tariff Code</label>
            <span>{{orderItem?.exportTariffCode ? orderItem.exportTariffCode : ''}}</span>
        </div>
        <div>
            <label>ECCN</label>
            <span>{{orderItem?.eccn ? orderItem.eccn : ''}}</span>
        </div>
        <div>
            <label>Shipping Conditions</label>
            <span>{{orderItem?.shippingConditions ? orderItem.shippingConditions : ''}}</span>
        </div>
    </div>
    <div class="first-row">
        <div>
            <label>Shipping Notes</label>
            <span>{{orderItem?.shippingNotes ? orderItem.shippingNotes : ''}}</span>
        </div>
    </div>
    <div class="second-row">
        <h6>Chosen Lots</h6>
        <ag-grid-angular #choosenLotsGrid enableCellTextSelection=true ensureDomOrder=true style="width: 99.8%"
            class="ag-theme-alpine" domLayout="autoHeight" suppressHorizontalScroll="true" [rowData]="chosenLotsRowData"
            [columnDefs]="chosenLotsColumnDefinitions" [frameworkComponents]="chosenLotsFrameworkComponents"
            (gridReady)="onChosenLotsGridReady($event)" (gridSizeChanged)="resizeGrid($event)">
        </ag-grid-angular>
    </div>
    <ng-container
        *ngIf="!(order.orderStatusID === orderStatuses.Shipping || order.orderStatusID === orderStatuses.Complete || order.orderStatusID === orderStatuses.SGSComplete || order.orderStatusID === orderStatuses.Cancelled)">
        <div style="display: flex;justify-content: space-between; align-items: center;">
            <h6>Lot Selection
            </h6>
            <button style="margin-right: 24px;" mat-raised-button color="cor-primary" *ngIf="isLoginUserCoordinator"
                [disabled]="allContainersAssigned || !orderItem?.inventoryCatalogID" (click)="onNoDecant()">No
                Decant</button>
        </div>
        <ag-grid-angular #grid style="margin-top: 20px;margin-bottom: 20px; width: 99.8%" domLayout='autoHeight'
            class="ag-theme-alpine" tooltipShowDelay="0" suppressCellSelection="true" suppressRowClickSelection="true"
            suppressHorizontalScroll="true" [rowHeight]='50' suppressColumnMoveAnimation="true"
            [rowData]="lotSelectionRowData" [columnDefs]="lotSelectionColumnDefinitions" [gridOptions]="lotSelectionGridOptions"
            [frameworkComponents]="selectLotFrameworkComponents" (gridReady)="onSelectLotGridReady($event)"
            (gridSizeChanged)=" resizeGrid($event)" enableCellTextSelection=true ensureDomOrder=true
            suppressHorizontalScroll="true">
        </ag-grid-angular>
    </ng-container>
    <ng-container
        *ngIf="!(order.orderStatusID === orderStatuses.Shipping || order.orderStatusID === orderStatuses.Complete || order.orderStatusID === orderStatuses.SGSComplete || order.orderStatusID === orderStatuses.Cancelled)">
        <h6>Material Summary</h6>
        <ag-grid-angular style="margin-top: 20px;margin-bottom: 20px; width: 99.8%" class="ag-theme-alpine"
            domLayout="autoHeight" [rowHeight]='50' suppressHorizontalScroll="true" [rowData]="reservationRowData"
            [columnDefs]="reservationColumnDefinitions" (gridReady)="onMaterialSummaryGridReady($event)"
            (gridSizeChanged)="resizeGrid($event)">
        </ag-grid-angular>
    </ng-container>
    <h6>Attachments</h6>
    <app-attachments #itemAttachments *ngIf="!reload" [entity]='attachmentEntity' showSharedDocuments="true"
        [disableAttachment]='false' (updateOrderItemAttachment)="updateOrderItemAttachment($event)"
        (createOrderItemAttachment)="createOrderItemAttachment($event)"
        (removeOrderItemAttachment)="removeOrderItemAttachment($event)"
        (downloadOrderItemAttachment)="downloadAttachment($event)" (storedDocuments)="onStoredDocuments($event)"
        [orderItem]="orderItem" [coaStatus]="coaStatus" (retrieveCOA)="retrieveCOA()" [canDisableCOA]="canDisableCOA"
        [isLoginUserCoordinator]="isLoginUserCoordinator">
    </app-attachments>
</mat-expansion-panel>