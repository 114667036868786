import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, take, tap } from 'rxjs/operators'
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DestinationGuideOptions } from 'src/app/models/destination-guide-options';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { WarehouseManagerOptions, WarehouseModel } from 'src/app/models/warehouse-model';
import { Country } from 'src/app/models/country';
import { RoleService } from 'src/app/services/role-service/role-service.service';
import { Role } from 'src/app/models/security-models';
import { DestinationGuideService } from 'src/app/shared/services/destination-guide-service';
import { UserService } from 'src/app/services/user-service/user-service.service';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent
  implements OnInit, OnDestroy {
  allowRedirect: boolean;
  subscriptions: Subscription;
  public userManagerForm: FormGroup;
  warehouses: WarehouseManagerOptions[];
  roles: Role[];
  countries: Country[];
  destinationGuideModel: DestinationGuideOptions;
  userID: any;
  selectedWarehouseOption: any;
  isLoading:boolean = true;
  availableRoles: any[]=[];
  assignedRoles: any[]=[];
  tempAssignRoles:any[]=[];

  constructor(
    private messageService: MessageService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loader: LoadingService,
    private userService: UserService,
    private destinationGuideService: DestinationGuideService,
    private roleService: RoleService,
    private confirmationService: ConfirmationService
  ) { }

  @HostListener('window:beforeunload', ['$event'])
  canLeavePage(event: any) {
    event.preventDefault();
    event.returnValue = false;
  }

  ngOnInit(): void {
    this.userID = this.activatedRoute.snapshot.paramMap.get('id');
    this.init();
  }

  async onSubmit(data: any): Promise<boolean> {
    const payload = {
      userId:this.userManagerForm.value?.userID,
      roles:data.map(o => o.roleID)
    }
      this.loader.show();
      await this.userService
        .assignRoles(payload)
        .toPromise()
        .then(() => {
          this.loader.clearMessage();
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: "User's roles saved successfully.",
          });
          setTimeout(() => {
            this.router.navigate(['manageUser']);
          }, 100);
        });

      this.userManagerForm.markAsPristine();
      return true;
    
  }

  canDeactivate(): boolean {
    return this.userManagerForm.pristine;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onCancel() {

    if (JSON.stringify(this.assignedRoles) !== JSON.stringify(this.tempAssignRoles)) {
      this.confirmationService.confirm({
        message: 'You have unsaved changes. Are you sure you wish to leave?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.router.navigate(['manageUser']);
        },
      });
    } else {
      this.router.navigate(['manageUser']);
    }
  }

  private init() {
    this.buildForm();
    this.subscriptions = new Subscription();

    this.subscriptions.add(
      this.userService.getUser(this.userID)
      .subscribe((formData:any) => {
          this.patchForm(formData);
          this.updateFormValidators();
          this.loader.clearMessage();
          this.subscriptions.add(this.roleService.getAvailableRoles().subscribe(
            data => {
              this.roles = data;
              this.assignedRoles = formData.roles;
              this.tempAssignRoles = JSON.parse(JSON.stringify(this.assignedRoles));
              this.availableRoles = this.roles.filter(o => !formData.roles.some(e => e.roleName == o.roleName))
            }
          ));
        })
    );

    this.destinationGuideService
      .getDestinationGuideOptions()
      .pipe(
        tap(() => this.loader.show()),
        take(1)
      )
      .subscribe((options) => {
        this.destinationGuideModel = options;
        this.loader.clearMessage();
      });
  
  }
 
  onReorder() {
    this.userManagerForm.markAsDirty();
  }

  onSelectionChange() {
    this.userManagerForm.markAsDirty();
  }

  buildForm() {
    this.userManagerForm = new FormGroup({
      userID: new FormControl(null),
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
    });
    this.isLoading = false;
  }

  private patchForm(formData: any) {
    this.userManagerForm.patchValue({
      userID: this.userID,
      firstName: formData.firstName,
      lastName: formData.lastName,
   });
  }

  updateFormValidators() {
    this.userManagerForm.markAsPristine();
    this.userManagerForm.markAllAsTouched();
    this.userManagerForm.updateValueAndValidity({
      onlySelf: false,
      emitEvent: false,
    });
  }

  checkRequired(controlName: string) {
    const control = this.userManagerForm.get(controlName)
    return control?.errors?.required && control?.touched;
  }


  get user(){
    return this.userManagerForm?.value;
  }
 
 
 
  

}

