import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'edit-button-cell',
    template: `<button mat-raised-button color="cor-primary" (click)="onEdit()">Edit</button>`,
    styles: [],
  })
  
  export class EditButtonRenderer implements ICellRendererAngularComp {
    public params: any;
    private gridApi: any;
  
    constructor() { }
  
    agInit(params: any): void {
      this.params = params;
      this.gridApi = params.api;
    }
  
    onEdit() {
      this.gridApi.dispatchEvent({ type: 'editButtonClicked', value: this.params.data });
    }
  
    refresh(): boolean {
      return false;
    }
  }