import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Configuration } from '../../configuration';
import { ResponseHandlingService } from '../../services/response-handling-service/response-handling-service';
import { MaterialModel, SDSModel } from '../models/material.model';
import { SessionModel, SessionViewModel, StatusType } from '../models/session.model';
import { catchError, map } from 'rxjs/operators';
import { ExternalServiceType } from '../models/enumerations';
import { SupplierModel } from '../models/supplier.model';
import { LookupModel, LookupModelOfTKey } from '../models/lookup.model';
import { ScalesModel } from '../models/scales.model';
import { ContainerWeightModel, DefectRequest, DefectResponse } from '../models/weighing.model';
import { PrinterType, Printer } from '../models/printer.model';
import { documentTypes } from '../models/docupload.model';
import { HttpStatusCode } from 'src/app/models/response-models';
import { FilterRequest } from '../models/session-search-request.model';
import { Pagination, PaginationResponse } from '../models/pagination.model';
import { PrintLabelRequest } from '../models/print-label.model';
import { LabelPrinterModel } from '../models/label-printer-model';
import { receivingConstants } from '../receiving.constants';
import { url } from 'inspector';
@Injectable()
export class ReceivingService {

  readonly rootURL = `${Configuration.REST_API_URL}/Receiving/`;
  readonly genericErrorMessage = "Unable to complete your request at this time, please resubmit.";

  constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {

  }

  async searchMaterial(searchKeyword: string, externalServiceType: ExternalServiceType): Promise<MaterialModel[]> {

    //reset service type for formulation 3rd party (since its the same repo)
    if (externalServiceType == ExternalServiceType.FormulationThirdParty)
      externalServiceType = ExternalServiceType.Formulation;

    if (externalServiceType == ExternalServiceType.Coformulant || externalServiceType == ExternalServiceType.Formulation) {
      if (searchKeyword.length > 0 && searchKeyword[searchKeyword.length - 1] != "%") {
        searchKeyword += "%";
      }
    }

    searchKeyword = encodeURIComponent(searchKeyword);
    var URL = this.rootURL + `SearchMaterial/${externalServiceType}?searchQuery=${searchKeyword}`;
    return this.http.get<MaterialModel[]>(URL, { headers: new HttpHeaders({ timeout: `${receivingConstants.SearchDefaultTimeout}` }) })
      .pipe(
        catchError(err =>
          this.responseHandler.handleError(
            err,
            new Map([
              [err.status, "Unable to perfrom your search request at this time, please retry."],
              [HttpStatusCode.NOT_FOUND, err.error?.Title ?? "Unable to perform your search request at this time, please retry."]
            ]
            ), false))
      ).toPromise();
  }

  async searchLot(searchKeyword: string, externalServiceType: ExternalServiceType, isTsnSearch: boolean): Promise<MaterialModel> {
    searchKeyword = encodeURIComponent(searchKeyword);
    var URL = this.rootURL + `lot/${externalServiceType}/${searchKeyword}?tsn=${isTsnSearch}`;

    return this.http.get<MaterialModel>(URL)
      .pipe(
        catchError(err =>
          this.responseHandler.handleError(
            err,
            new Map(
              [
                [err.status, "Unable to perform your lot search request at this time, please retry."],
                [HttpStatusCode.BAD_REQUEST, err.error?.Title]
              ]
            ), false
            , [HttpStatusCode.NOT_FOUND]))
      ).toPromise();
  }

  async getSession(sessionId: number, refresh = false): Promise<SessionModel> {
    var URL = `${this.rootURL}${sessionId}?refresh=${refresh}`;
    return this.http.get<SessionModel>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, this.genericErrorMessage]]), false))).toPromise();
  }

  printLabel(sessionId: number, printerId: string, printRequest: PrintLabelRequest, connectionId: string): Observable<any> {
    var URL = this.rootURL + `Session/${sessionId}/PrintLabels/${printerId}?connectionId=${connectionId}`;
    return this.http.post<any>(URL, printRequest)
      .pipe(
        map(r => r),
        catchError(err => this.responseHandler.handleError(
          err,
          new Map([[err.status, "Unable to print at this time, please resubmit."]]
          ), false)
        )
      );
  }

  async getSuppliersByMaterial(materialId: string, externalServiceType: ExternalServiceType): Promise<SupplierModel[]> {
    var URL = this.rootURL + `Suppliers/${externalServiceType}?materialId=${materialId}`;
    return this.http.get<SupplierModel[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, this.genericErrorMessage]]), false))).toPromise();
  }

  async createDraftSession(session: SessionModel): Promise<SessionModel> {
    return this.http.post<SessionModel>(this.rootURL, session).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, this.genericErrorMessage]]), false))).toPromise();
  }

  async saveDataEntry(material: MaterialModel): Promise<MaterialModel> {
    return this.http.put<MaterialModel>(this.rootURL, material).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, this.genericErrorMessage]]), false))).toPromise();
  }

  async getLookupValues(lookupTypes: string): Promise<LookupModel[]> {
    var URL = this.rootURL + `LookupValues/${lookupTypes}`;
    return this.http.get<LookupModel[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, this.genericErrorMessage]]), false))).toPromise();
  }

  async getLookupValuesOfTKey<TKey>(lookupTypes: string): Promise<LookupModelOfTKey<TKey>[]> {
    var URL = this.rootURL + `LookupValues/${lookupTypes}`;
    return this.http.get<LookupModelOfTKey<TKey>[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, this.genericErrorMessage]]), false))).toPromise();
  }

  async updateSession(sesion: SessionModel): Promise<SessionModel> {
    const errorCodeMapping: Map<HttpStatusCode, string> = new Map([[HttpStatusCode.FORBIDDEN, this.genericErrorMessage]]);
    return this.http.put<SessionModel>(this.rootURL, sesion).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, this.genericErrorMessage]]), false))).toPromise();
  }

  async patchSession(sesion: Partial<SessionModel>): Promise<SessionModel> {
    return this.http.patch<SessionModel>(this.rootURL, sesion).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, this.genericErrorMessage]]), false))).toPromise();

  }

  async getScales(scalesByType: number): Promise<ScalesModel[]> {
    var URL = `${Configuration.REST_API_URL}/Stations/ScalesByType/${scalesByType}`;
    return this.http.get<ScalesModel[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, this.genericErrorMessage]]), false))).toPromise();
  }

  async setContainerUpdate(containerWeightModel: ContainerWeightModel): Promise<SessionModel> {
    var URL = this.rootURL + `Session/ContainerWeights`;
    return this.http.post<SessionModel>(URL, containerWeightModel).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, this.genericErrorMessage]]), false))).toPromise();
  }

  async getDocumentTypes(): Promise<documentTypes[]> {
    var URL = `${Configuration.REST_API_URL}/Orders/DocumentTypes`;
    return this.http.get<documentTypes[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Error while getting Document Types, please retry."]]), false))).toPromise();
  }

  uploadFile(file: any, documentTypeID: number, sessionID: number, metadata?: { [key: string]: any }): Promise<Object> {
    var URL = this.rootURL + `Session/CreateAttachments`;
    let formData: FormData = new FormData();
    if (file != null) {
      formData.append('file', file);
      formData.append('documentTypeID', documentTypeID.toString());
      formData.append('sessionID', sessionID.toString());
      formData.append('filename', file.name);
      if (metadata) {
        formData.append('metadata', JSON.stringify(metadata));
      }
    }
    return this.http.post(URL, formData).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Error while uploading documents, please resubmit."]]), false))).toPromise();
  }

  async getPrinters(printerType: PrinterType): Promise<Printer[]> {
    var URL = `${Configuration.REST_API_URL}/Configuration/Printers/${printerType}`;
    let printers = await this.http.get<Printer[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to get list of printers at this time, please resubmit."]]), false))).toPromise();
    printers = printers.sort((a, b) => {
      return b.printerType - a.printerType;
    });
    return printers;
  }

  async getLabelPrinters(): Promise<LabelPrinterModel[]> {
    var URL = `${Configuration.REST_API_URL}/LabelPrinter`;
    let printers = await this.http.get<LabelPrinterModel[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to get list of printers at this time, please resubmit."]]), false))).toPromise();
 
    return printers;
  }

  loadLookupValues(lookupTypes: string, items: LookupModel[]) {
    if (items.filter(X => X.lookupid == lookupTypes).length > 0) {
      return items.filter(X => X.lookupid == lookupTypes)[0].values;
    }
    else { return []; }
  }

  async getSDS(materialId: string, externalServiceType: ExternalServiceType): Promise<SDSModel> {
    var URL = this.rootURL + `Sds/${externalServiceType}/${materialId}`;
    return this.http.get<SDSModel>(URL)
      .pipe(
        catchError(err =>
          this.responseHandler.handleError(
            err,
            new Map(
              [
                [err.status, "Unable to perform SDS request at this time, please retry."],
                [HttpStatusCode.BAD_REQUEST, err.error?.Title]
              ],

            ), false, [HttpStatusCode.NOT_FOUND, HttpStatusCode.INTERNAL_SERVER_ERROR]))).toPromise();
  }

  async getMaterial(materialId: string, externalServiceType: ExternalServiceType): Promise<MaterialModel> {
    var URL = this.rootURL + `Material/${externalServiceType}/${materialId}`;
    return this.http.get<MaterialModel>(URL)
      .pipe(
        catchError(err =>
          this.responseHandler.handleError(
            err,
            new Map(
              [
                [err.status, this.genericErrorMessage],
                [HttpStatusCode.BAD_REQUEST, err.error?.Title]
              ],

            ), false, [HttpStatusCode.NOT_FOUND]))).toPromise();
  }

  searchSessions(filterRequest: FilterRequest): Observable<PaginationResponse<SessionViewModel>> {
    var URL = this.rootURL + `Session/Search`;
    var $paginationSubject = new BehaviorSubject<PaginationResponse<SessionViewModel>>(null);
    this.http.post<SessionViewModel[]>(URL, filterRequest, { observe: 'response' })
      .pipe(
        map((res) => {
          let paginationHeader = res.headers.get('x-pagination');
          const pagination: Pagination = JSON.parse(paginationHeader) as Pagination;
          let result: PaginationResponse<SessionViewModel> = {
            items: res.body,
            TotalCount: pagination.TotalCount,
            TotalPages: pagination.TotalPages
          }

          result.items.map(item => {
            switch (item.statusType) {
              case StatusType.Draft:
                item.statusTypeDescription = 'Draft';
                break;
              case StatusType.Completed:
                item.statusTypeDescription = 'Completed';
                break;
              case StatusType.Defect:
                item.statusTypeDescription = `Defect`;
                if (item.lastDefectReason) item.statusTypeDescription += ` - ${item.lastDefectReason}`;
                break;
              case StatusType.LargeDecant:
                item.statusTypeDescription = 'LargeDecant';
                break;
              default:
                break;
            }

            //Login for showing action buttons on the grid
            if (item.sessionContainerWeights && item.sessionContainerWeights.length > 0) {
              let countOfContainerIds = item.sessionContainerWeights.filter(x => x.catalogInventoryItemId > 0).length;
              if (countOfContainerIds == item.sessionContainerWeights.length) {
                item.showActionButtons = true;
              }
            }
          });

          $paginationSubject.next(result);
        }),
        catchError(err =>
          this.responseHandler.handleError(
            err,
            new Map(
              [
                [err.status, this.genericErrorMessage],
                [HttpStatusCode.BAD_REQUEST, err.error?.Title]
              ],

            ), false, [HttpStatusCode.NOT_FOUND]))).toPromise();

    return $paginationSubject.asObservable();
  }

  async CreateDefect(sessionId: number, reason: DefectRequest): Promise<DefectResponse> {
    var URL = this.rootURL + `Session/${sessionId}/CreateDefect`;
    return this.http.put<DefectResponse>(URL, reason)
      .pipe(
        catchError(err =>
          this.responseHandler.handleError(
            err,
            new Map(
              [
                [err.status, this.genericErrorMessage],
                [HttpStatusCode.BAD_REQUEST, err.error?.Title]
              ],

            ), false, [HttpStatusCode.NOT_FOUND]))).toPromise();
  }

  /** Download the GLP Receipt Form
  * @param sessionId
  * @param filename - Name of the the file to download
  */
  async DownloadGLPReceiptForm(sessionId: number, filename: string) {
    var url = this.rootURL + `Session/${sessionId}/GLPForm`;
    this.http.get(url, { responseType: 'blob' })
      .pipe(
        catchError(err =>
          this.responseHandler.handleError(
            err,
            new Map(
              [
                [err.status, this.genericErrorMessage],
                [HttpStatusCode.BAD_REQUEST, err.error?.Title]
              ],

            ), false, [HttpStatusCode.NOT_FOUND])),
        map(blob => URL.createObjectURL(blob)),
        map(fileUrl => {
          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = filename;

          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );

          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(fileUrl);
            link.remove();
          }, 100);
        })).subscribe();
  }

}
