<div class="container">
    <h5>{{ attachment ? 'Edit Attachment' : 'Add Attachment'}}</h5>
    <span class="message">{{ attachment ? 'Edit attachment on ' : 'Add attachment to ' }} {{ entityName }}.</span>
    <div class="content">
        <div *ngIf="documentTypes && documentTypes.length > 0" class="p-field p-fluid p-mb-2">
            <label for="documentTypes" class="p-text-bold">Document Type</label>
            <p-dropdown placeholder="Select a Document Type" [(ngModel)]="selectedDocumentType"
                [options]="documentTypes" optionLabel="name" panelStyleClass='attachment-modal-minWidthOverride'
                [style]="{'width':'100%'}"></p-dropdown>
        </div>
        <div *ngIf="languages && languages.length > 0" class="language p-field p-fluid p-mb-2">
            <label for="languages" class="p-text-bold">Select Language</label>
            <p-dropdown placeholder="Select a Language" [(ngModel)]="selectedLanguage" [options]="languages"
                optionLabel="name" panelStyleClass='attachment-modal-minWidthOverride' [style]="{'width':'100%'}">
            </p-dropdown>
        </div>
        <div class="p-field p-fluid">
            <label class="p-text-bold">Notes</label><br>
            <textarea pInputTextarea rows="4" maxlength="1000" [(ngModel)]="comments" style="width: 100%;"></textarea>
        </div>
        <ng-container *ngIf="!attachment">
            <div class="p-field">
                <span class="message">(Allowed file extensions are .pdf, .jpg, .png, .docx, .xlsx, .msg, .htm, .rtf, .mht)</span>
                <p-fileUpload mode="basic" name="uploadedFiles" accept='.pdf, .jpg, .png, .docx, .xlsx, .msg, .htm, .rtf, .mht'
                    customUpload="true" (onSelect)="onFileSelect($event)" (uploadHandler)="onUpload($event,form)"
                    [auto]="true" #form>
                </p-fileUpload>
            </div>
        </ng-container>
        <div class="p-field p-text-bold" style="height:10px">
            <label *ngIf="attachment">File Name:</label>
            <span [ngClass]="{ 'message' : attachment}">{{ selectedFileName }}</span>
        </div>
    </div>
    <div class="actions p-mb-2">
        <button id="cancelBtn" mat-raised-button color="cor-default" (click)="cancel()">Cancel</button>
        <button id="saveBtn" mat-raised-button color="cor-primary" [disabled]="!selectedDocumentType || (!event && !attachment)"
            (click)="save()">Save</button>
    </div>
</div>