import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../configuration';
import { DestinationGuide } from '../../models/destination-guide';
import { DestinationGuideOptions } from '../../models/destination-guide-options';
import { ResponseHandlingService } from '../../services/response-handling-service/response-handling-service';
import { ShipperRankingModel } from '../../models/shipper-ranks';

@Injectable({providedIn:'root'})
export class DestinationGuideService {
 
    constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
    }

    saveDestinationGuide(destinationGuide: DestinationGuide){
        const headers = { 'content-type': 'application/json' }
        var URL = Configuration.REST_API_URL + '/DestinationGuide';
        return this.http.post<any>(URL, JSON.stringify(destinationGuide), { 'headers': headers }).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getDestinationGuideOptions() {
        var URL = Configuration.REST_API_URL + `/DestinationGuide/Options`;
        return this.http.get<DestinationGuideOptions>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getDestinationGuide(countryId: number): Observable<DestinationGuide> {
        var URL = Configuration.REST_API_URL + `/DestinationGuide/${countryId}`;
        return this.http.get<any>(URL).pipe(
          switchMap((data) => {
            let shipperRankings: ShipperRankingModel[] = new Array(data?.shippers?.length);
            data.shippers?.forEach((rank, index) => {
              shipperRankings.push({ rankId: index + 1, shipperName: data?.shipperNames?.[index] });
            });
    
            data.shipperRankings = shipperRankings.filter(value => JSON.stringify(value) !== '{}');
            return of(data);
          }),
          catchError((err) => this.responseHandler.handleError(err))
        );
      }
}