import { LabelDesign } from './../../models/label-design';
import { LabelPrinter } from './../../models/label-printer';
import { Printer } from 'src/app/receiving/models/printer.model';
import { PrinterAssignment } from './../../models/printer-assignments';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../configuration';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';

@Injectable({ providedIn: 'root' })
export class PrinterAssignmentsService {

  constructor(private http: HttpClient, private responseHandler: ResponseHandlingService) {
  }

  getPrinterAssignments() {
    var URL = Configuration.REST_API_URL + `/LabelPrinter/Assignments`;
    return this.http.get<PrinterAssignment[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getLabelDesigns() {
    var URL = Configuration.REST_API_URL + `/LabelDesign`;
    return this.http.get<LabelDesign[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  getLabelPrinters() {
    var URL = Configuration.REST_API_URL + `/LabelPrinter`;
    return this.http.get<LabelPrinter[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }

  addPrinterAssignment(printerAssignment: PrinterAssignment) {
    var URL = Configuration.REST_API_URL + `/LabelPrinter/Assignment`;
    return this.http.post<PrinterAssignment>(URL, printerAssignment);
  }

  deletePrinterAssignment(printerId: number) {
    var URL = Configuration.REST_API_URL + `/LabelPrinter/Assignment/${printerId}`;
    return this.http.delete<PrinterAssignment[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
  }
}
