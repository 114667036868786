import { Component, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { InventoryItem } from 'src/app/models/inventory-models';
import { DisposeContainerConfirmModalComponent, IDisposeContainerConfirmModalResult, DisposeContainerConfirmModal } from '../../dispose-container-confirm-modal/dispose-container-confirm-modal.component';
import { BaseComponent } from 'src/app/base-component';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { DeleteContainerComponent } from '../delete-container/delete-container.component';
import { SaveContainerComponent } from '../save-container/save-container.component';
import { DisposalRendererComponent } from '../disposal-renderer/disposal-renderer.component';
import { DisposalReason } from 'src/app/models/disposal-reason-model';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { ValueSetterParams } from 'ag-grid-community';
import { DisposalCommentRendererComponent } from './disposal-comment-renderer/disposal-comment-renderer.component';

@Component({
  selector: 'app-dispose-container',
  templateUrl: './dispose-container.component.html',
  styleUrls: ['./dispose-container.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class DisposeContainerComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  private gridApi;

  columnDefs: any;
  gridData = [];
  containerInput: string;
  frameworkComponents: any;
  disposalReasons: DisposalReason[] = []
  containerList: InventoryItem[] = [];
  @ViewChild('disposeContainerInput') disposeContainerInput: ElementRef
  private subscriptions = new Subscription();
  disableDispose: boolean = true;

  constructor(private inventoryService: InventoryService, private loadingService: LoadingService,
    private responseHandler: ResponseHandlingService,
    private dialog: MatDialog, private weightFormatKGPipe: WeightFormatKGPipe) {
    super();

    this.frameworkComponents = {
      buttonRenderer: DeleteContainerComponent,
      disposalDropdownRenderer: DisposalRendererComponent,
      disposalCommentRenderer: DisposalCommentRendererComponent
    }

    this.columnDefs = [
      { headerName: 'Id', field: 'id' },
      { headerName: 'Lot Number', field: 'lotNumber' },
      { headerName: 'Chemical Name', field: 'chemical' },
      { headerName: 'Previous Amount', field: 'previousAmount' },
      {
        headerName: 'Assigned?', field: 'isAssigned',
        cellRenderer: (params) => {
          return !params?.data?.isAssigned ? 'No' : 'Yes'
        },
      },
      { headerName: 'Assigned Amount', field: 'totalAssignedAmount' },
      {
        headerName: 'Disposal Reason',
        field: '',
        width: 400,
        cellRenderer: 'disposalDropdownRenderer',
        cellRendererParams: {
          onClick: this.setDisableDispose.bind(this)
        }
      },
      {
        headerName: 'Comment',
        field: 'comments',
        editable: true,
        width: 500,
        suppressKeyboardEvent: true,
        cellRenderer: 'disposalCommentRenderer',
        cellRendererParams: {
        },
      },
      {
        headerName: '',
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.onBtnClick1.bind(this)
        },
        cellClass: 'button-edit'
      }
    ];
  }

  ngAfterViewInit(): void {
    this.setContainerInputFocus();
  }

  setContainerInputFocus() {
    setTimeout(() => {
      this.disposeContainerInput.nativeElement.focus();
    }, 0);
  }

  setDisableDispose() {
    let rowData = [];
    this.gridApi?.forEachNode(node => {
      rowData.push({
        inventoryItemID: node.data?.id,
        disposalReasonID: node.data?.selectedDisposalReason,
        comment: node.data?.comments
      })
    })
    if (rowData?.some(row => !row.disposalReasonID))
      this.disableDispose = true;
    else
      this.disableDispose = false;
  }

  createGridDataItem(inventoryItem: InventoryItem, isDelete: boolean = false) {
    this.gridData = [];
    let rowData: any[] = [];
    this.gridApi?.forEachNode(node => {
      rowData.push({
        id: node.data.id,
        lotNumber: node.data.lotNumber,
        chemical: node.data.catalogDescription,
        previousAmount: this.formatQuantity(node.data.remainingAmount),
        isAssigned: node.data.isAssigned,
        totalAssignedAmount: node.data.totalAssignedAmount,
        disposalReasons: this.disposalReasons,
        selectedDisposalReason: node.data.selectedDisposalReason,
        comments: node.data.comments
      })
    })
    var index = rowData.findIndex(cl => cl.id == inventoryItem.id);

    if (isDelete) {
      if (index != -1) {
        rowData.splice(index, 1);
        this.setDisableDispose();
      }
    }
    else {
      if (index == -1)
        rowData.push({
          ...inventoryItem,
          disposalReasons: this.disposalReasons,
          chemical: inventoryItem.catalogDescription,
          previousAmount: this.formatQuantity(inventoryItem.remainingAmount),
        });
    }

    this.gridData = rowData;
    setTimeout(() => {
      this.setDisableDispose();
    }, 100);
  }

  formatQuantity(quantity: number) {
    return `${this.weightFormatKGPipe.transform(quantity)} ${quantity < 1 ? 'g' : 'kg'}`
  }

  onGridReady(params) {
    this.gridApi = params.api;

    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onContainerInput({ target: { value } }) {
    this.containerInput = "";

    if (value != null && !isNaN(value) && value.length > 0) {
      this.loadingService.show("Loading...");
      this.subscriptions.add(forkJoin([this.inventoryService
        .getInventoryItem(value), this.inventoryService
          .getDisposalReasons()]).subscribe(
            (res: any[]) => {
              this.disposalReasons = res[1];
              if (res[0]) {
                this.createGridDataItem(res[0]);
              }
            }, (() => {
              this.setContainerInputFocus();
            }), () => this.loadingService.clearMessage()
          ));
    }
    else if (value != null && value.length > 0) {
      this.responseHandler.showError("Container ID Invalid");
    }
  }

  onDisposeContainerClick() {
    const dialogData = new DisposeContainerConfirmModal(
      "Dispose Container",
      "The containers will be permanently removed from inventory.",
      "Confirm");

    const dialogRef = this.dialog.open(DisposeContainerConfirmModalComponent, {
      width: "500px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe((dialogResult: IDisposeContainerConfirmModalResult) => {
      if (dialogResult && dialogResult.isConfirm) {
        let rowData: any[] = [];
        this.gridApi?.forEachNode(node => {
          rowData.push({
            inventoryItemID: node.data?.id,
            disposalReasonID: node.data?.selectedDisposalReason,
            comment: node.data?.comments
          })
        })
        this.loadingService.show("Loading...");
        this.subscriptions.add(this.inventoryService.disposeInventoryItems(rowData)
          .subscribe(data => {
            this.responseHandler.showSuccess("Containers disposed successfully");

            this.gridData = [];
            this.containerList = [];

            this.setContainerInputFocus();
          }, () => {
            this.responseHandler.showError("An error occurred while disposing the parent container");
          }, () => this.loadingService.clearMessage()
          ));
      }
    })
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      setTimeout(() => this.subscriptions.unsubscribe(), 2000);
    }
  }

  onBtnClick1(e) {
    this.createGridDataItem(e.rowData, true);
  }

  clearContainerField(): void {

  }

}
