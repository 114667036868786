<h5>{{ data.lotNumber }}</h5>
<ag-grid-angular style="width:100%; height: 250px; overflow: auto; margin-top: 20px;margin-bottom: 20px"
    class="ag-theme-alpine" suppressHorizontalScroll="true" [rowData]="rowData" [columnDefs]="columnDefs"
    [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)">
</ag-grid-angular>
<div class="totals">
    <div>
        <label>Total Selected</label>
        <span>{{ getTotalAmount() | weightKG }} {{ data.unitOfMeasureDesc }}</span>
    </div>
    <div>
        <label>Net Available</label>
        <span>{{ data.netAmountAvailableDisplay }}</span>
    </div>
</div>
<div class="actions">
    <button mat-raised-button color="cor-default" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="cor-primary" (click)="save()" *ngIf="!data.hasUserPermissionAndNoOrderReservation"
        [disabled]="data.netAmountAvailable < getTotalAmount() || getTotalAmount() === 0">Save</button>
    <button mat-raised-button color="cor-primary" (click)="getTotalAmount() <= data.netAmountAvailable ? save() : openConfirmDialog()"
        *ngIf="data.hasUserPermissionAndNoOrderReservation" [disabled]=" disableSaveButton()">Save</button>
</div>