<h4>Reprinting Request Details</h4>
<div class="vertical-spacing">
    <app-warehouse-station (selectedStation)="onStationChange($event)"></app-warehouse-station>
</div>
<div class="vertical-spacing">
    <label>Search for Request</label>
    <input #requestNumber id="requestID" type="text" [(ngModel)]="requestNumberInput" (click)="requestNumberInput = ''"
        (blur)="onRequestIdInput($event, requestNumber.value)" pInputText placeholder="Request ID" />
    <button mat-raised-button color="cor-primary"
        (click)="onRequestIdInput($event, requestNumber.value)">Search</button>
</div>

<div *ngIf="order">
    <div class="id-display">
        <label for="requestNumberDisplay">Request ID:</label>
        <span #requestNumberDisplay>{{order.requestNumber }}</span>
    </div>
    <div class="box-container">
        <div class="print-item-box">
            <span class="print-item-content" (click)="requestDocumentsModal()">Documents to Print</span>
            <button mat-raised-button color="cor-primary" (click)="openViewAttachments()" [disabled]="!hasAttachments">
                View Attachments
            </button>
            <button class="p-ml-2" mat-raised-button color="cor-primary" (click)="requestDocumentsModal()">
                Documents
            </button>
            <p *ngIf="!hasAttachments" class="empty-message">
                No Documents to Print
            </p>
        </div>
        <div class="border print-item-box">
            <span class="print-item-content">Shipping Information</span>
            <div>
                <div *ngIf="order" class="address">
                    <span>{{ order.recipientName }}</span>
                    <span>{{ order.recipientOrganizationName ? order.recipientOrganizationName : '' }}</span>
                    <span>{{ order.addressLine1 }}</span>
                    <span>{{ order.addressLine2 ? order.addressLine2 : '' }}</span>
                    <span>{{ order.addressLine3 ? order.addressLine3 : '' }}</span>
                    <div>
                        {{ order.city }}, {{ order.stateProvince ? order.stateProvince : '' }} {{ order.postalCode}}
                    </div>
                    <span>{{ order.countryName}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-mb-2">
        <h5>Packages</h5>
        <span *ngIf="pageLoaded && !packages || packages?.length === 0">No packages have been created yet.</span>
        <ag-grid-angular *ngIf="packages && packages.length > 0" [columnDefs]="packingColumnDefs"
            [frameworkComponents]="packageFrameworkComponents" [rowData]="packageRowData" [rowHeight]="44"
            (gridReady)="onPackageGridReady($event)" suppressHorizontalScroll="true"
            style="width: 700px; margin-top: 20px;margin-bottom: 20px" class="ag-theme-alpine" domLayout="autoHeight">
        </ag-grid-angular>
    </div>
    <h5>Child Containers</h5>
    <span *ngIf="pageLoaded && !allContainersDecanted">Not all containers have been decanted yet.</span>
    <div>
        <div *ngFor="let rd of rowData">
            <ag-grid-angular [columnDefs]="itemColumnDefs" [frameworkComponents]="frameworkComponents" [rowData]="rd"
                [rowHeight]="44" (gridReady)="onGridReady($event)" suppressHorizontalScroll="true"
                style="width: 1290px; margin-top: 20px;margin-bottom: 20px" class="ag-theme-alpine"
                domLayout="autoHeight">
            </ag-grid-angular>
        </div>
    </div>
</div>