import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { switchMap, finalize } from 'rxjs/operators';
import { DisposalLogModel } from 'src/app/models/disposal-log-model';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { GridOptions } from 'ag-grid-community';
import { PaginatorModel } from 'src/app/core/modules/ag-grid/components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';
import { sortData } from 'src/app/helpers/grid-helpers';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-disposal-log',
  templateUrl: './disposal-log.component.html',
  styleUrls: ['./disposal-log.component.scss']
})
export class DisposalLogComponent implements OnInit {

  public isValid;
  public submitted: boolean;
  isLoading: boolean = false;
  searchCriteriaForm: FormGroup;

  private gridApi;
  public gridOptions: GridOptions;
  public disableClearFilters: boolean = true;
  public paginatorModel: PaginatorModel;
  public hasFilterModelChanged: boolean = true;
  public frameworkComponents: any;
  public overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">No logs found.</span>';

  columnDefs = [
    {
      headerName: 'Date',
      field: 'created',
      headerTooltip: 'Date',
      valueGetter: (params) => { 
        if (params.data !== undefined && params.data.created) { 
          return formatDate(params.data.created, 'd-MMM-yyyy', 'en-US') 
        } 
      },
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.created) {
          return formatDate(params.data.created, 'd-MMM-yyyy', 'en-US');
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'User',
      field: 'createdByUserName',
      headerTooltip: 'User',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.createdByUserName;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Item ID',
      field: 'inventoryItemID',
      headerTooltip: 'Item ID',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.inventoryItemID;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Amount Disposed',
      field: 'amountDisposed',
      headerTooltip: 'Amount Disposed',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.amountDisposed;
        }
      },
      sortable: false,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Reason',
      field: 'reason',
      headerTooltip: 'Reason',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.reason;
        }
      },
      sortable: false,
      suppressSizeToFit: false,
    }
    ,
    {
      headerName: 'Comment',
      field: 'comment',
      headerTooltip: 'Comment',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.comment;
        }
      },
      sortable: false,
      suppressSizeToFit: false,
    }
  
  ];

  constructor(
    private readonly loadingService: LoadingService,
    private readonly inventoryService: InventoryService
  ) { 
    this.isValid = true;
    this.submitted = false;
  }

  ngOnInit(): void {
    this.paginatorModel = {
      totalRows: 0,
      totalPages: 0,
      pageSize: 25,
      currentPage: 1,
      leftArrowsDisabled: true,
      rightArrowsDisabled: true,
    };

    this.gridOptions = {};
    this.init();
  }

  onSearch() {

    this.submitted = true;
    this.isValid = this.searchCriteriaForm.valid && this.submitted

    if(this.searchCriteriaForm.valid && this.getSearchCriteria()) {
      this.onLoad();
    }
    else {
      this.searchCriteriaForm.markAllAsTouched();
      this.searchCriteriaForm.updateValueAndValidity();
    }
  }

  onLoad() {
    this.loadingService.show('Loading ...');
    this.inventoryService
      .getInventoryCatalogItems(this.getSearchCriteria())
      .pipe(
        switchMap(async (catalogItemsMatchs: any[]) => {
          let logs: DisposalLogModel[] = [];

          for (let i = 0; i < catalogItemsMatchs.length; i++){
            await this.inventoryService
            .getDisposalLogs(catalogItemsMatchs[i].itemID).toPromise().then(log=>{
              if (log) {
                logs.push(...log);
              }
            })           
          }

          return logs;
        }),
        finalize(() => {
          this.loadingService.clearMessage();
        })
      ).subscribe((logs)=>{
        this.setDataSource(logs);
      });
  }

  init(): void {
    this.buildForm();
  }

  getSearchCriteria(): string {
    return this.searchCriteriaForm.controls['searchCriteria'].value;
  }

  buildForm(): void {
    this.searchCriteriaForm = new FormGroup({
      searchCriteria: new FormControl('', [Validators.required, Validators.minLength(3)]),
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  onResizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  setDataSource(returnedData) {
    this.gridApi.purgeInfiniteCache();
    this.bindGridData(returnedData);
  }

  updateCurrentPage(): void {
    this.paginatorModel.currentPage = 1;
    this.paginatorModel.leftArrowsDisabled = true;
    if (this.paginatorModel.totalPages > 1) {
      this.paginatorModel.rightArrowsDisabled = false;
    }
  }

  handlePaginatorCommand(event: number): void {
    switch (event) {
      case 1:
        this.gridApi.paginationGoToFirstPage();
        break;
      case 2:
        this.gridApi.paginationGoToPreviousPage();
        break;
      case 3:
        this.gridApi.paginationGoToNextPage();
        break;
      case 4:
        this.gridApi.paginationGoToLastPage();
        break;
      default:
        this.gridApi.paginationSetPageSize(event);
        this.gridApi.paginationGoToFirstPage();
        break;
    }
  }

  private bindGridData(data): void {
    var dataSource = {
      rowCount: null,
      getRows: function (params) {
        setTimeout(function () {
          var dataAfterSorting = sortData(params.sortModel, data);
         
          var rowsThisPage = dataAfterSorting.slice(
            params.startRow,
            params.endRow
          );
          var lastRow = dataAfterSorting.length;
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      },
    };

    this.paginatorModel.totalRows = data?.length;
    this.paginatorModel.totalPages = Math.ceil(data.length / this.paginatorModel.pageSize);
    
    if (this.paginatorModel.totalRows == 0) {
      this.gridApi.showNoRowsOverlay();
      this.gridApi.setDatasource(dataSource);
    } else {
      this.updateCurrentPage();
      this.gridApi.hideOverlay();
      this.gridApi.setDatasource(dataSource);
    }
  }
}
