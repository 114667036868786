import { Order, OrderStatuses, RequestTypes } from '../../models/order-models';
import { formatDate } from '@angular/common';
import { OrderItemModel } from 'src/app/models/order-item-model';
import { OrderItemContainerAssignmentResponse, OrderItemContainerModel } from 'src/app/models/order-item-container-model';

export class PdfService {
    pdfMake: any;
    constructor() { }

    async loadPdfMaker() {
        if (!this.pdfMake) {
            const pdfMakeModule = await import('../../../../node_modules/pdfmake/build/pdfmake');
            const pdfFontsModule = await import('../../../../node_modules/pdfmake/build/vfs_fonts');
            this.pdfMake = pdfMakeModule.default;
            this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
        }
    }

    getRequestType(order: Order): any {
        const shipmentPlaceholder = 'SH-123';

        if (order.requestTypeID === RequestTypes.GLP)
            return {
                text: [{ text: `GLP`, fontSize: 16, bold: true }]
            }
        else if (order.requestTypeID === RequestTypes.Stock)
            return {
                text: [{ text: `Stock`, fontSize: 16, bold: true }]
            };
        else
            return {
                text: [{ text: `Kitting and Decant List for Shippment: `, fontSize: 16, bold: true },
                { text: `${shipmentPlaceholder}`, fontSize: 14, bold: false }]
            }
    }

    async generatePdfPicklist(order: Order) {
        await this.loadPdfMaker();
        const formattedDate = formatDate(order.shipByDate, 'd-MMM-yyyy', 'en-US');
        const containerCount = this.sumOrderContainers(order);
        const assignedCoordinator = order.assignedToUserID === null ? "Not assigned" : `${order.assignedToFirstName} ${order.assignedToLastName}`;

        var array = [];
        order.items.forEach(item => {
            item.containers.forEach(container => {
                if(container.noDecantInventoryItemID!=null && container.noDecantInventoryItemID!=0) {
                    array.push(item);
                }
            });
        });

        const a = {
            style: 'tableExample',
            table: {
                headerRows: 1,
                widths: ['*', 100, 100, 100, 140],
                body: this.createTableData(order.items, order.requestTypeID)
            },
            layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? null : '#CCCCCC';
                }
            }
        }

        const def = {
            pageOrientation: 'landscape',
            pageSize: 'LETTER',
            content: [
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 2,
                        widths: ['39%', '*', 55],
                        heights: [20, 18, 18, 1, 18],
                        body: [
                            [this.getRequestType(order), { text: `Ship To: `, fontSize: 10, bold: true },
                            {
                                text: `Request ID`,
                                border: [true, true, true, true],
                                fontSize: 10,
                                style: 'qrBorder'
                            }],
                            [{
                                text: [{ text: `Request: `, fontSize: 10, bold: true },
                                { text: `${order.requestNumber}`, fontSize: 10, bold: false }]
                            },
                            {
                                rowSpan: 4,
                                stack: [
                                    { text: `${order.recipientName ? order.recipientName : ''}`, fontSize: 10, bold: false },
                                    { text: `${order.recipientOrganizationName ? order.recipientOrganizationName : ''}` , fontSize: 10, bold: false },
                                    { text: `${order.addressLine1 ? order.addressLine1 : ''}`, fontSize: 10, bold: false },
                                    { text: `${order.addressLine2 ? order.addressLine2 : ''}`, fontSize: 10, bold: false },
                                    { text: `${order.addressLine3 ? order.addressLine3 : ''}`, fontSize: 10, bold: false },
                                    {
                                        text: [
                                            { text: `${order.city}, `, fontSize: 10, bold: false },
                                            {
                                                text: `${order.stateProvince ? order.stateProvince : ''} `,
                                                fontSize: 10, bold: false
                                            },
                                            { text: `${order.postalCode}`, fontSize: 10, bold: false },
                                        ]
                                    },
                                    {
                                        text: [{ text: `${order.countryName}`, fontSize: 10, bold: false },]
                                    }
                                ]
                            },
                            {
                                qr: `${order.orderID}`, fit: '75', border: [true, true, true, true],
                                style: 'qrBorder', rowSpan: 3
                            }],
                            [{
                                text: [{ text: `Coordinator: `, fontSize: 10, bold: true },
                                { text: assignedCoordinator, fontSize: 10, bold: false }]
                            }, {}, ''],
                            [{
                                text: [{ text: `Ship By Date: `, fontSize: 10, bold: true },
                                { text: `${formattedDate}`, fontSize: 10, bold: false }]
                            }, {}, ''],
                            [{}, {}, '']
                        ]
                    },
                    layout: {
                        defaultBorder: false,
                    }
                },
                {
                    style: 'table',
                    table: {
                        headerRows: 1,
                        widths: ['*', 100, 100, 100, 140],
                        body: this.createTableData(order.items, order.requestTypeID)
                    },
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {
                            return (rowIndex % 2 === 0) ? null : '#CCCCCC';
                        }
                    }
                },      
                { text: `Total Containers to be Decanted: ${containerCount}`, style: 'bottomLabel' },
                { text: 'Special Instructions', style: 'bottomLabel' },
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: false,
                    margin: [0, 0, 0, 10]
                },
                table: {
                    margin: [0, 5, 40, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                },
                bottomLabel: {
                    bold: true,
                    fontSize: 16,
                    margin: [0, 0, 40, 5]
                },
                leftRightMargin: {
                    margin: [0, 0, 40, 0]
                },
                qrBorder: {
                    fontSize: 14,
                    bold: true,
                    alignment: 'center'
                },
                topMargin: {
                    margin: [0, 5, 0, 0]
                },
                requestHeading: {
                    bold: true,
                    decoration: 'underline'
                },
                chemicalInstructions: {
                    bold: true,
                    margin: [0, 5, 0, 0],
                    decoration: 'underline'
                },
                footer: {
                    margin: [50, 0, 70, 0]
                }
            },
            footer: {
                columns: [{
                    text: [{ text: 'Request ID: ', alignment: 'right', fontSize: 10, bold: true },
                    { text: `${order.orderID}`, alignment: 'right', fontSize: 10, bold: false }], style: 'footer'
                }]
            }
        }

        if (!order.addressLine3 || order.addressLine3.trim() === '') {
            def.content[0].table.body.splice(8, 1);
        }
        if (!order.addressLine2 || order.addressLine2.trim() === '') {
            def.content[0].table.body.splice(7, 1);
        }
        if (!this.requestLevel(order) && !this.chemicalLevel(order)) {
            def.content.push({ text: 'No instructions.', style: 'leftRightMargin' });
        } else {
            if (this.requestLevel(order)) {
                def.content.push({ text: 'Request', style: 'requestHeading' });
                def.content.push({ text: order.specialInstructions, style: 'leftRightMargin' });
            }
            if (this.chemicalLevel(order)) {
                order.items.forEach(i => {
                    def.content.push({ text: i.containerLabelFmt, style: 'chemicalInstructions' },);
                    def.content.push({ text: i.specialInstructions, style: 'leftRightMargin' });
                });
            }
        }
        def.content.push({ text: `Coordinator Notes `, style: 'bottomLabel' });
        def.content.push({ text: `${order.coordinatorNotes ? order.coordinatorNotes : ''} `, style: 'leftRightMargin' });
        this.pdfMake.createPdf(def).open();
    }

    async generatePdfRequestOverview(order: Order) {
        await this.loadPdfMaker();
        const tableData = this.createRequestTableData(order);
        const completionDate = (order?.orderStatusID === OrderStatuses.Complete || order?.orderStatusID === OrderStatuses.SGSComplete) ? formatDate(order.updated, 'd-MMM-yyyy', 'en-US') : '';
        const assignedCoordinator = order.assignedToUserID === null ? "Not assigned" : `${order.assignedToFirstName} ${order.assignedToLastName}`;
        const def = {
            pageOrientation: 'landscape',
            pageSize: 'LETTER',
            content: [
                {
                    style: 'tableExample',
                    text: 'Request Overview',
                    bold: true,
                    fontSize: 20,
                    alignment: 'center',
                    margin: [0, 0, 0, 20]
                },
                {
                    columns: [
                        {
                            text: 'Request Details',
                            style: 'requestHeading'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            text: 'Request ID:',
                            bold: true
                        },
                        {
                            text: `${order.requestNumber}`
                        },
                        {
                            text: 'Request Date:',
                            bold: true
                        },
                        {
                            text: `${formatDate(order.created, 'd-MMM-yyyy', 'en-US')}`
                        }
                    ]
                },
                {
                    columns: [
                        {
                            text: 'Requestor:',
                            bold: true
                        },
                        {
                            text: `${order.requestorName}`
                        },
                        {
                            text: 'Completion Date:',
                            bold: true
                        },
                        {
                            text: `${completionDate}`
                        }
                    ]
                },
                {
                    columns: [
                        {},
                        {
                            text: `${order.requestorPhone}`
                        },
                        {
                            text: 'Coordinator Name:',
                            bold: true
                        },
                        {
                            text: `${assignedCoordinator}`
                        }
                    ]
                },
                {
                    columns: [
                        {},
                        {
                            text: `${order.requestorEmail}`
                        },
                        {}, {}
                    ]
                },
                {
                    columns: [
                        {
                            text: 'Recipient Details',
                            style: 'requestHeading'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            text: 'Recipient:',
                            bold: true
                        },
                        [
                            {
                                text: `${order.recipientName}`
                            },
                            {
                                text: `${order.recipientOrganizationName ? order.recipientOrganizationName :''}`
                            },
                            {
                                text: `${order.addressLine1 ? order.addressLine1 : ''}`
                            },
                            {
                                text: `${order.addressLine2 ? order.addressLine2 : ''}`
                            },
                            {
                                text: `${order.addressLine3 ? order.addressLine3 : ''}`
                            },
                            {
                                text: `${order.city} ${order.stateProvince ? order.stateProvince : ''} ${order.postalCode}`
                            },
                            {
                                text: `${order.primaryPhone}`
                            },
                            {
                                text: `${order.email}`
                            }
                        ],
                        {}, {}
                    ]
                },
                {
                    columns: [
                        {
                            text: 'Request Items',
                            style: 'requestHeading'
                        }
                    ]
                },
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        widths: ['*', 100, '*', 140],
                        body: tableData
                    },
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {
                            return (rowIndex % 2 === 0) ? null : '#CCCCCC';
                        }
                    }
                },
                { text: 'Special Instructions', style: 'requestHeading' },
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: false,
                    margin: [0, 0, 0, 10]
                },
                tableExample: {
                    margin: [0, 5, 40, 15]
                },
                tableHeader: {
                    bold: true,
                    color: 'black'
                },
                // bottomLabel: {
                //     bold: true,
                //     fontSize: 16,
                //     margin: [0, 0, 40, 5]
                // },
                leftRightMargin: {
                    margin: [0, 0, 40, 0]
                },
                topMargin: {
                    margin: [0, 5, 0, 0]
                },
                requestHeading: {
                    bold: true,
                    fontSize: 14,
                    decoration: 'underline',
                    margin: [0, 5, 0, 5]
                },
                chemicalInstructions: {
                    bold: true,
                    margin: [0, 5, 0, 0],
                    decoration: 'underline'
                },
                footer: {
                    margin: [50, 0, 70, 0]
                }
            }
        }

        if (!order.addressLine3 || order.addressLine3.trim() === '' || !order.addressLine2 || order.addressLine2.trim() === '') {
            def.content[7].columns[1] = Object.values(def.content[7].columns[1]).filter(v => v.text != 'null');
        }
        if (!this.requestLevel(order) && !this.chemicalLevel(order)) {
            def.content.push({ text: 'No instructions.', style: 'leftRightMargin' });
        } else {
            if (this.requestLevel(order)) {
                def.content.push({ text: 'Request', style: 'chemicalInstructions' });
                def.content.push({ text: order.specialInstructions, style: 'leftRightMargin' });
            }
            if (this.chemicalLevel(order)) {
                order.items.forEach(i => {
                    def.content.push({ text: i.containerLabelFmt, style: 'chemicalInstructions' },);
                    def.content.push({ text: i.specialInstructions, style: 'leftRightMargin' });
                });
            }
        }
        def.content.push({ text: `Coordinator Notes `, style: 'requestHeading' });
        def.content.push({ text: `${order.coordinatorNotes ? order.coordinatorNotes : ''} `, style: 'leftRightMargin' });
        this.pdfMake.createPdf(def).open();
    }

    private createRequestTableData(order: Order): any[] {
        let picklistTable: any = [];
        picklistTable.push([{ text: 'Chemical Name', style: 'tableHeader' },
        { text: 'Assigned Lot Number', style: 'tableHeader' },
        { text: 'Number of Containers', style: 'tableHeader' },
        { text: 'Total Amount Requested', style: 'tableHeader' }]);
        if (order.items) {
            order.items.forEach(item => {
                if (item.containers && item.containers.length > 0) {
                    let uniqueItems = [];
                    item.orderContainers.forEach(container => {
                        if (!uniqueItems.some(i => i.containerLabelFmt == item.containerLabelFmt
                            && i.lotNumber == container.assignedLotNumber)) {
                            picklistTable.push([`${item.containerLabelFmt} ${item.extraPPERequired ? '* Extra PPE Required *' : ''}`,
                            container.assignedLotNumber,
                            `${this.sumNumberOfContainers(item, container)}`,
                            `${this.formatWeight(this.sumOrderItemAmounts(item, container))} ${item.unitOfMeasureDesc}`]);
                            uniqueItems.push({ containerLabelFmt: item.containerLabelFmt, lotNumber: container.assignedLotNumber })
                        }
                    });
                }
            });
        }
        return picklistTable;
    }

    private createTableData(items: OrderItemModel[], requestTypeID: number): any[] {
        
        let picklistTable: any = [];
        picklistTable.push([
        { border: [false, false, false, false], text: 'Product Name', style: 'tableHeader' },
        { border: [false, false, false, false], text: 'Lot', style: 'tableHeader' },
        { border: [false, false, false, false], text: 'Location', style: 'tableHeader' },
        { border: [false, false, false, false], text: 'Ranges', style: 'tableHeader' },
        { border: [false, false, false, false], text: 'Total Amount Needed', style: 'tableHeader' }
        ]);

        if (items) {
          
            items.forEach(item => {
                if (item.containers && item.containers.length > 0) {
                    let uniqueItems = [];

                    var regularList = item.containers.filter(nd => nd.noDecantInventoryItemID == null || nd.noDecantInventoryItemID == 0);
                    var noDecantList = item.containers.filter(nd => nd.noDecantInventoryItemID != null && nd.noDecantInventoryItemID != 0);
                   
                    noDecantList.forEach(noDecant => {
                        picklistTable.push([
                            {
                                stack: [
                                    `${item.containerLabelFmt}`,
                                    {
                                        type: 'none',
                                        ul: [
                                            `${item.extraPPERequired ? '* Extra PPE Required *' : ''}`,
                                            '* No Decant *'
                                        ]
                                    }
                                ]
                            },
                            noDecant.assignedLotNumber,
                            noDecant.locationName,
                            noDecant.noDecantInventoryItemID,
                            `${this.formatWeight(noDecant.requestedAmount)} ${item.unitOfMeasureDesc}`
                        ]);
                    })

                    regularList.forEach(container => {
                        if (!uniqueItems.some(i => i.containerLabelFmt == item.containerLabelFmt && i.lotNumber == container.assignedLotNumber)) {
                            
                            picklistTable.push([
                                `${item.containerLabelFmt} ${item.extraPPERequired ? '* Extra PPE Required *' : ''}`,
                                container.assignedLotNumber,
                                `${this.getLocations(item, container.assignedLotNumber, requestTypeID)}`,
                                `${this.getRanges(item, container.assignedLotNumber, requestTypeID)}`,
                                `${this.formatWeight(this.sumItemAmounts(regularList,container))} ${item.unitOfMeasureDesc}`
                            ]);
                            uniqueItems.push({ containerLabelFmt: item.containerLabelFmt, lotNumber: container.assignedLotNumber })
                        }
                    });
                }
            });
        }

        // We sort the table by Location, then Ranges, then Product Name and finally by Lot
        let header = picklistTable.shift();

      
        picklistTable.sort((a, b) => {

            var nOne = a[3].toString().match(/\d+/)[0];
            var nTwo = b[3].toString().match(/\d+/)[0];

            if (a[2] < b[2]) {
                return -1;
            } else if (a[2] > b[2]) {
                return 1;
            } else if (+nOne < +nTwo) {
                return -1;
            } else if (+nOne > +nTwo) {
                return 1;
            } else if (a[0] < b[0]) {
                return -1;
            } else if (a[0] > b[0]) {
                return 1;
            } else if (a[1] < b[1]) {
                return -1;
            } else if (a[1] > b[1]) {
                return 1;
            }
        });
        picklistTable.unshift(header);
        return picklistTable;
    }

    private requestLevel(order: Order): boolean {
        if (order?.specialInstructions) return true;
        else return false;
    }

    private chemicalLevel(order: Order): boolean {
        let filteredChemicals = order?.items?.filter(i => !i.specialInstructions);
        if (filteredChemicals.length == order?.items.length) return false;
        else return true;
    }

    private getLocations(item: OrderItemModel, assignedLotNumber: string, requestTypeID: number): string {
        let locations;
        if (requestTypeID === RequestTypes.Stock) {
            locations = item.stockLots?.find(l => l.lotNumber === assignedLotNumber)?.locations;
        } else if (requestTypeID === RequestTypes.GLP) {
            locations = item.glpLots?.find(l => l.lotNumber === assignedLotNumber)?.locations;
        }
        if (locations && locations.length)
            return locations.join(', ');
        else
            return '';
    }

    private getRanges(item: OrderItemModel, assignedLotNumber: string, requestTypeID: number): string {
        let itemRanges;
        if (requestTypeID === RequestTypes.Stock) {
            itemRanges = item.stockLots?.find(l => l.lotNumber === assignedLotNumber)?.itemRanges;
        } else if (requestTypeID === RequestTypes.GLP) {
            itemRanges = item.glpLots?.find(l => l.lotNumber === assignedLotNumber)?.itemRanges;
        }
        if (itemRanges && itemRanges.length) {
            itemRanges.sort((a, b) => a - b);
            return itemRanges.join(', ');
        }
        else
            return '';
    }

    private sumItemAmounts(containersList: OrderItemContainerModel[], container: OrderItemContainerModel): number {
        let sum = 0;
        if (containersList) {
            containersList.filter(f => f.assignedLotNumber === container.assignedLotNumber)?.forEach(container => {
                sum += container.requestedAmount;
            })
        }
        return sum;
    }

    private sumNumberOfContainers(item: OrderItemModel, container: OrderItemContainerAssignmentResponse): number {
        let sum = 0;
        if (item && item.containers && container) {
            item.containers.filter(f => f.assignedLotNumber === container.assignedLotNumber)?.forEach(container => {
                sum += 1;
            })
        }
        return sum;
    }

    private sumOrderItemAmounts(item: OrderItemModel, container: OrderItemContainerAssignmentResponse): number {
        let sum = 0;
        if (item && item.containers) {
            item.containers.filter(f => f.assignedLotNumber === container.assignedLotNumber)?.forEach(container => {
                sum += container.requestedAmount;
            })
        }
        return sum;
    }

    private sumOrderContainers(order: Order): number {
        let sum = 0;
        if (order.items) {
            order.items.forEach(item => {
                sum += item.containers.length;
            });
        }
        return sum;
    }

    private formatWeight(weight): string {
        if (weight) {
            var multiplier = Math.pow(10, 3);
            var result = (Math.round(weight * multiplier) / multiplier);
            return parseFloat(result.toFixed(7)).toString();
        } else {
            return '0.000';
        }
    }
}

