import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelDesignService } from 'src/app/services/label-design-service/label-design-service';

@Component({
  selector: 'app-label-design-modal',
  templateUrl: './label-design-modal.component.html',
  styleUrls: ['./label-design-modal.component.scss']
})
export class LabelDesignModalComponent implements OnInit {
  public Form: FormGroup;

  constructor(public dialogRef: MatDialogRef<LabelDesignModalComponent>, @Inject(MAT_DIALOG_DATA) public data: LabelDesignModalComponent, private labelDesignService: LabelDesignService) { }

  ngOnInit(): void {
    this.buildForm();
  }

  close() {
    this.dialogRef.close(false);
  }


  buildForm() {
    this.Form = new FormGroup({
      labelDesignID: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required, Validators.maxLength(80)]),
      description: new FormControl(null),
      bartenderFilename: new FormControl(null, [Validators.required, Validators.maxLength(80)]),
    });
    this.Form.patchValue(this.data)
  }
  onSubmit(data: any) {

  }
  update() {
    if (this.Form.invalid) return;
    this.labelDesignService.updateLabelDesign(this.Form.value).subscribe((res: any) => {
      this.dialogRef.close(true);
    })
  }

  // checkRequired(controlName: string) {
  //   const control = this.Form.get(controlName)
  //   return control?.errors?.required && control?.touched;
  // }

}
