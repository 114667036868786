<h5 mat-dialog-title style="font-weight: 600;">{{title}}</h5>
<mat-dialog-content>
    <div *ngIf='orderHistoryData?.length > 0'>
        <div class="flex-direction-column" *ngFor='let item of orderHistoryData'>
            <div class="timestamp">{{formatDate(item.created)}}</div>
            <div class="details">{{item.details}}</div>
        </div>
    </div>
    <div *ngIf='!orderHistoryData?.length'>No data found</div>
</mat-dialog-content>
<mat-dialog-actions style="float: right;">
    <button mat-raised-button color="cor-primary" (click)="onDismiss()" style="float: right;">Close</button>
</mat-dialog-actions>