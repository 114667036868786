import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'choosen-lots-action-cell',
  template: `<button mat-raised-button color="cor-primary" *ngIf="!params.data.disableUnassigned" [disabled]="!params.data.assignedLotNumber || params.data.isDecanted" (click)="unassignClick()">Unassign</button>
    <button mat-raised-button color="cor-primary" class="p-ml-2" *ngIf="!params.data.disableUnassigned" [disabled]="canDisable" (click)="labelInfoclick()">Label Info</button>
    <button mat-raised-button color="cor-primary" class="p-ml-2" *ngIf="!params.data.disableUnassigned" [disabled]="canDisable" (click)="printLabelclick()">Print Labels</button>`,
  styles: [],
})

export class ChoosenLotsActionRenderer implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;

  constructor(private datePipe: DatePipe) { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
  }

  unassignClick() {
    this.gridApi.dispatchEvent({ type: 'unassignLotButtonClicked', value: this.params.data });
  }

  labelInfoclick() {
    this.gridApi.dispatchEvent({ type: 'labelInfoButtonClicked', value: this.params.data });
  }

  printLabelclick() {
    this.gridApi.dispatchEvent({ type: 'printLabelButtonClicked', value: this.params.data });
  }

  get canDisable(): boolean {
    if (!this.params || this.params.data?.isDecanted || this.params.data?.assignedLotNumber) {
      return false;
    }
    else {
      return true;
    }
  }

  refresh(): boolean {
    return false;
  }
}