import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class BreadCrumbNavigationService {
    private routes: MenuItem[] = [];
    private routerSubscription: Subscription;
    private $breabCrumbSubject = new BehaviorSubject<MenuItem[]>([]);
    breadCrumbSubscription = this.$breabCrumbSubject.asObservable();
    STORAGE_KEY: string = 'GSSRecevingNavigation';
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private location: Location) {

        if (sessionStorage.getItem(this.STORAGE_KEY)) {
            this.routes = JSON.parse(sessionStorage.getItem(this.STORAGE_KEY));
            this.notifySubscribers(this.routes);
        }

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                let route = this.activatedRoute.firstChild;
                let child = route;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                        route = child;
                    } else {
                        child = null;
                    }
                }

                route.data.subscribe(data => {
                    if (data['breadcrumb']) {
                        let bc = data['breadcrumb'];
                        let label = bc.label;
                        let url = event.url;
                        if (bc.reset === true) this.clearRoutes();
                        this.addRoute({ label: label, url: url });
                    } else {
                        this.clearRoutes();
                    }
                })

            });
    }

    init() {

    }

    addRoute(model: MenuItem) {
        const index = this.routes.findIndex(x => x.url == model.url);
        if (index < 0) {
            this.routes.push(model);
        } else {
            this.routes.length = index + 1;
        }
        this.notifySubscribers(this.routes);
    }

    removeRoute(model: MenuItem) {
        const index = this.routes.findIndex(x => x.url == model.url);
        if (index > -1) {
            this.location.go(model.url);
        }
        //this.notifySubscribers(this.routes);
    }

    clearRoutes() {
        this.routes = [];
        this.notifySubscribers([]);
    }

    navigateBack() {
        this.location.back();
    }

    connect(): Observable<MenuItem[]> {
        return this.breadCrumbSubscription;
    }

    notifySubscribers(breadCrumbs: MenuItem[]) {
        this.$breabCrumbSubject.next(breadCrumbs);
        sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(breadCrumbs));
    }

    destroy() {
        // this.$breabCrumbSubject.unsubscribe();
        // this.routerSubscription.unsubscribe();
    }
}