import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'print-button-cell',
    template: `<button mat-raised-button color="cor-primary" *ngIf="!params.data.disableUnassigned" (click)="selectLot()" [disabled]="params.data.disableSelectLot">{{params.data.buttonText}}</button>`,
    styles: [],
  })
  
  export class SelectLotButtonRenderer implements ICellRendererAngularComp {
    public params: any;
    private gridApi: any;
  
    constructor() { }
  
    agInit(params: any): void {
      this.params = params;
      this.gridApi = params.api;
    }
  
    selectLot() {
      this.gridApi.dispatchEvent({ type: 'selectLotButtonClicked', value: this.params.data });
    }
  
    refresh(): boolean {
      return false;
    }
  }