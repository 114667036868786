import { OrderHoldRequest } from './../../models/order-models';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderService } from 'src/app/services/order-service/order-service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { Observable } from 'rxjs';
import { PeerToPeerService } from 'src/app/peer-to-peer/services/peer-to-peer.service';
import { OnHoldReason, P2PUpdateRequest } from 'src/app/peer-to-peer/models/peer.models';

@Component({
  selector: 'app-hold-modal',
  templateUrl: './hold-modal.component.html',
  styleUrls: ['./hold-modal.component.scss']
})
export class HoldModalComponent implements OnInit {

  orderID: string;
  comment: string = "";
  errorMessage: string;
  onHoldReasons$: Observable<OnHoldReason[]>;
  onHoldReasons: OnHoldReason[];
  onHoldForm: FormGroup;
  selectedCancelReasonId: number;
  displayFormField: boolean = false;
  isp2p: boolean = false;
  displayErrorMessage: boolean = false;
  isFormSubmitted = false;
  showNotes = false;
  disableButton: boolean = false;

  constructor(public dialogRef: MatDialogRef<HoldModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: OrderService,
    private p2pService: PeerToPeerService,
    private toastr: ResponseHandlingService) {
    this.orderID = data.orderID;
    this.isp2p = data.isp2p;
  }

  ngOnInit(): void {

    if(this.isp2p) {
      this.onHoldReasons$ = this.p2pService.getOnHoldReasons();
      this.onHoldReasons$.subscribe(resp => this.onHoldReasons = resp);
      this.onHoldForm = new FormGroup({
        reason: new FormControl('', [Validators.required]),
        otherReasonComment: new FormControl('')
      });
    }
    else {
      this.onHoldReasons$ = this.orderService.getOnHoldReasons();
      this.onHoldReasons$.subscribe(resp => this.onHoldReasons = resp);
      this.onHoldForm = new FormGroup({
        reason: new FormControl('', [Validators.required]),
        otherReasonComment: new FormControl('')
      });
    }

  }

  get fc() {
    return this.onHoldForm.controls;
  }

  placeOnHold(): void {
    this.isFormSubmitted = true;
    if(this.isp2p) {
      const placeOnHoldRequest: Partial<P2PUpdateRequest> = {
        setHold: true,
        isOnHold: true,
        holdReasonIDs: this.fc.reason.value,
        holdNotes: this.fc.otherReasonComment.value?.trim()
      }
      this.p2pService.patchP2P(this.orderID, placeOnHoldRequest).subscribe(
        () => {
          this.dialogRef.close({
            isOnHold: true,
            holdNotes: this.fc.otherReasonComment.value?.trim(),
            holdReasons: this.onHoldReasons.filter(r => (this.fc.reason.value as any[]).includes(r.reasonID))?.map(x => x.name)?.join(', '),
            holdReasonID: this.fc.reason.value
          });
          this.toastr.showSuccess('Placed on Hold succesfully');
        },
        () => {
          this.dialogRef.close(this.dialogRef.close({ isOnHold: true, holdNotes: this.fc.otherReasonComment.value?.trim() }));
          this.toastr.showError('An error occured. Try again later');
        }
      );
    } else {
      const placeOnHoldRequest: OrderHoldRequest = {
        placeOnHold: true,
        holdReasonIDs: this.fc.reason.value,
        holdNotes: this.fc.otherReasonComment.value?.trim()
      }
      this.orderService.toggleHold(this.orderID, placeOnHoldRequest).subscribe(
        () => {
          this.dialogRef.close({
            isOnHold: true,
            holdNotes: this.fc.otherReasonComment.value?.trim(),
            holdReasons: this.onHoldReasons.filter(r => (this.fc.reason.value as any[]).includes(r.reasonID))?.map(x => x.name)?.join(', '),
            holdReasonID: this.fc.reason.value
          });
          this.toastr.showSuccess('Placed on Hold succesfully');
        },
        () => {
          this.dialogRef.close(this.dialogRef.close({ isOnHold: true, holdNotes: this.fc.otherReasonComment.value?.trim() }));
          this.toastr.showError('An error occured. Try again later');
        }
      );
    }
  }

  setErrors() {
    const obj = this.onHoldReasons.find(x => x.reasonID === this.onHoldForm.controls["reason"].value);
    this.isFormSubmitted = true;
    if (this.onHoldForm.controls["otherReasonComment"].value.length === 0 && obj.requireComment) {
      this.onHoldForm.controls["otherReasonComment"].setErrors({ 'required': 'true' });
      this.onHoldForm.updateValueAndValidity();
    }
  }

  removeHold() {
    if(this.isp2p) {
      const placeOnHoldRequest: Partial<P2PUpdateRequest> = {
        setHold: true,
        isOnHold: false,
        holdReasonIDs: [],
        holdNotes: ""
      }
      this.p2pService.patchP2P(this.orderID, placeOnHoldRequest).subscribe(
        () => {
          this.toastr.showSuccess("Successfully removed hold from request");
          this.dialogRef.close(true);
        },
        () => {
          this.toastr.showError("'An error occured. Try again later'");
          this.dialogRef.close(false);
        });
    } else {
      const removeHoldRequest: OrderHoldRequest = {
        placeOnHold: false,
        holdNotes: "",
        holdReasonIDs: []
      }
      this.orderService.toggleHold(this.orderID, removeHoldRequest).subscribe(
        () => {
          this.toastr.showSuccess("Successfully removed hold from request");
          this.dialogRef.close(true);
        },
        () => {
          this.toastr.showError("'An error occured. Try again later'");
          this.dialogRef.close(false);
        })
    }
  };

  onReasonChange() {
    const requireCommentReasonIDs = this.onHoldReasons.filter(x => x.requireComment)?.map(x => x.reasonID);
    const requireComment = requireCommentReasonIDs?.some(id => (this.onHoldForm.controls["reason"].value as any[]).includes(id));
    
    this.isFormSubmitted = true;
    if (requireComment!!) {
      this.showNotes = true;
      this.disableButton = true;
      if (this.onHoldForm.controls["otherReasonComment"].value.length === 0) {
        this.onHoldForm.controls["otherReasonComment"].setErrors({ 'required': 'true' });
      }
    }
    else{
      this.disableButton = false;
      this.onHoldForm.controls["otherReasonComment"].setErrors(null);
    }
    this.onHoldForm.updateValueAndValidity();
  }

}
