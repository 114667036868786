import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { of } from 'rxjs';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { InventoryStorageLocationChangeModalComponent } from '../inventory-storage-location-change-modal/inventory-storage-location-change-modal.component';
import { MessageService } from '../../../shared/services/message-queue.service'
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { LabelPrinterService } from 'src/app/services/label-printer-service/label-printer-service.service';

@Component({
  selector: 'app-storage-location-change',
  templateUrl: './storage-location-change.component.html',
  styleUrls: ['./storage-location-change.component.scss'],
})
export class StorageLocationChangeComponent implements ICellRendererAngularComp
{
  private params: any;

  constructor(
    private dialog: MatDialog,
    private readonly labelPrinterService: LabelPrinterService,
    private readonly messageService: MessageService,
    private readonly inventoryService: InventoryService,
    private toastr: ResponseHandlingService,
    private loadingService: LoadingService
  ) {}

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  async onLocationChange() {
    await this.labelPrinterService.getLabelPrinters().toPromise().then((printers)=>{

      this.inventoryService.getLocations().pipe(
        take(1),
        switchMap((locations)=>{
          return of({
            lotNumber: this.params.data.lotNumber,
            containerId: this.params.data.inventoryItemID,
            printers: printers.filter(printer => printer.isReceivingPrinter),
            locations: locations,
            selectedLocation:this.params.data.locationID
          })
        }),
        switchMap((data)=>{
          return this.dialog.open(InventoryStorageLocationChangeModalComponent, { width: "550px", height: "450px", data: { ...data  } }).afterClosed();
        }),
        switchMap((modalData)=>{
           if(modalData.saveChanges){
            this.loadingService.show('Updating...');
            return of(
              this.inventoryService.updateInventoryItemLocations(this.params.data.inventoryItemID, modalData.data.locations,modalData.data.printers)
              .pipe(
                catchError(err=>{
                  this.loadingService.clearMessage();
                  this.toastr.showError('Could not update storage location');
                  return(err);
                }),
                switchMap(err=>{
                   if(!err){
                    this.toastr.showSuccess('Successfully updated storage location');
                    this.messageService.message.next({data:modalData, reference:this.params.data});
                  }

                  return of(err);
                }),
                finalize(() => this.loadingService.clearMessage())
              ).subscribe())
          }
        })
      ).subscribe()
    })
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}
}
