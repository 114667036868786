import { EnvironmentService } from "../app/services/environment-service/environment-service";

export class Configuration {
    private static environmentService = new EnvironmentService();

    public static REST_API_URL: string = Configuration.environmentService.currentEnvironment.ShellAPIPath;
    public static CURRENT_ENVIRONMENT_NAME: string = Configuration.environmentService.currentEnvironment.name;
    public static APP_UI_URL: string = Configuration.environmentService.currentEnvironment.ShellUIPath;
    public static CLIENT_ID: string = Configuration.environmentService.currentEnvironment.clientID;
    public static AUTHORITY: string = Configuration.environmentService.currentEnvironment.authority;
    public static APP_INSIGHTS_KEY: string = Configuration.environmentService.currentEnvironment.appInsightsKey;
    public static READ_SCOPE: string = Configuration.environmentService.currentEnvironment.readScope;
    public static WRITE_SCOPE: string = Configuration.environmentService.currentEnvironment.writeScope;

}