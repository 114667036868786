import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, take } from 'rxjs/operators';
import { DecantStation } from 'src/app/models/decant-models';
import { StationService } from 'src/app/services/station/station.service';
import { GreaterThanZeroValidator } from 'src/app/shared/validators/greater-than-zero.validator';

@Component({
  selector: 'app-edit-scale-modal',
  templateUrl: './edit-scale-modal.component.html',
  styleUrls: ['./edit-scale-modal.component.scss'],
})
export class EditScaleModalComponent implements OnInit {
  public isValid;
  public submitted: boolean;
  public scaleForm: FormGroup;
  public buttonLabel: string;
  public stationList: DecantStation[];
  public scaleTypeList: { scaleType: number; typeName: string }[];
  public comPortList: { comPort: string; portName: string }[];

  constructor(private readonly stationService: StationService,public dialogRef: MatDialogRef<EditScaleModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isValid = true;
    this.submitted = false;
    this.buttonLabel = 'Edit';
  }

  ngOnInit(): void {
    this.buildForm();
    this.initData();
  }

  initData() {
   
    this.stationService
      .getStations()
      .pipe(
        take(1),
        map((stations) => (this.stationList = stations))
      )
      .subscribe();

    this.scaleTypeList = [
      { scaleType: 1, typeName: 'Decanting' },
      { scaleType: 2, typeName: 'Receiving' },
    ];

    this.comPortList = [
      { comPort: "COM1", portName: 'COM1' },
      { comPort: "COM2", portName: 'COM2' },
      { comPort: "COM3", portName: 'COM3' },
      { comPort: "COM4", portName: 'COM4' },
      { comPort: "COM5", portName: 'COM5' },
      { comPort: "COM6", portName: 'COM6' },
      { comPort: "COM7", portName: 'COM7' },
      { comPort: "COM8", portName: 'COM8' }
    ];

    if (this.data == null) {
      var data = {
        scaleID: null,
        name: '',
        stationID: '',
        scaleType: null,
        isGLP: false,
        comPort: '',
        decimalPlaces: "",
        maxWeightKG: "",
      };

      this.data = data;
      this.buttonLabel = 'Add New';
    }

    this.patchForm();
  }

  onSubmit() {
    this.submitted = true;
    this.isValid = this.scaleForm.valid && this.submitted

    if(this.scaleForm.valid) {
      this.dialogRef.close(this.scaleForm.value);
    }
    else {
      this.scaleForm.markAllAsTouched();
      this.scaleForm.updateValueAndValidity();
    }
  }

  private patchForm() {
    this.scaleForm.patchValue({
      scaleID: this.data.scaleID,
      name: this.data.name,
      stationID: this.data.stationID,
      scaleType: this.data.scaleType,
      isGLP: this.data.isGLP,
      comPort: this.data.comPort,
      decimalPlaces: this.data.decimalPlaces,
      maxWeightKG: this.data.maxWeightKG,
    });
  }

  private buildForm() {
    this.scaleForm = new FormGroup({
      scaleID: new FormControl(null),
      name: new FormControl(null, Validators.required),
      stationID: new FormControl(null, Validators.required),
      scaleType: new FormControl(null, Validators.required),
      isGLP: new FormControl(null),
      comPort: new FormControl(null, Validators.required),
      decimalPlaces: new FormControl(null, [Validators.required, Validators.max(7)]),
      maxWeightKG: new FormControl(null, [Validators.required, GreaterThanZeroValidator]),
    });
  }
}
