import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../configuration';
import { LabelPrinterModel } from 'src/app/receiving/models/label-printer-model';
import { ResponseHandlingService } from '../../services/response-handling-service/response-handling-service';

@Injectable({providedIn: 'root'})
export class LabelPrinterService {

  constructor(private readonly http: HttpClient, private readonly responseHandler: ResponseHandlingService) {
  }

  getLabelPrinters(): Observable<LabelPrinterModel[]> {
    var URL = `${Configuration.REST_API_URL}/LabelPrinter`;
    return this.http.get<LabelPrinterModel[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to get list of printers at this time, please resubmit."]]), false)));
  }

  deleteLabelPrinters(printerId: number): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/LabelPrinter/${printerId}`;
    return this.http.delete<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to delete the printer at this time, please resubmit."]]), false)));
  }

  editLabelPrinters(printer: LabelPrinterModel): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/LabelPrinter`;
    return this.http.post<LabelPrinterModel>(URL,printer).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable save printers at this time, please resubmit."]]), false)));
  }
}
