import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderPackageRequest } from 'src/app/models/order-models';

@Component({
  selector: 'app-shipping-info-modal',
  templateUrl: './shipping-info-modal.component.html',
  styleUrls: ['./shipping-info-modal.component.scss']
})
export class ShippingInfoModalComponent implements OnInit {

  selectedShipper;
  newTrackingNumber;
  isMaster: boolean;

  constructor(public dialogRef: MatDialogRef<ShippingInfoModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.newTrackingNumber = this.data.packageGrouping.trackingNumber;
    this.selectedShipper = this.data.carriers.find(c => c.shipperID === this.data.packageGrouping.shipperID);
    this.isMaster = data.isMaster;
  }

  ngOnInit(): void { }

  disableSave() {
    if (this.selectedShipper && !this.selectedShipper.trackingURLFormat && ((this.selectedShipper?.shipperID !== this.data.packageGrouping.shipperID) || this.isMaster !== this.data.packageGrouping.isMaster)) { return false; }
    else {
      return !this.selectedShipper || !this.newTrackingNumber || (this.selectedShipper?.shipperID === this.data.packageGrouping.shipperID && this.newTrackingNumber === this.data.packageGrouping.trackingNumber && this.isMaster === this.data.packageGrouping.isMaster);
    }
  }

  onCarrierChange(): void {
    if (!this.selectedShipper.trackingURLFormat) {
      this.newTrackingNumber = null;
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  save() {
    const payload: OrderPackageRequest = { shipperID: this.selectedShipper.shipperID, trackingNumber: this.newTrackingNumber, isMaster: this.isMaster };
    this.dialogRef.close(payload);
  }
}
