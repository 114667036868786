import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { MsalAngularConfiguration, MsalService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';
import { Configuration } from 'msal';
import { finalize, timeout } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { Router } from '@angular/router';


@Injectable()
export class authHeaderInterceptor implements HttpInterceptor {

    requestsCount = 0;

    constructor(private msalService: MsalService, private loadingService: LoadingService, private router: Router,
        @Inject(MSAL_CONFIG_ANGULAR) private msalAngularConfig: MsalAngularConfiguration,
        @Inject(MSAL_CONFIG) private msalConfig: Configuration) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes('graph'))
            return next.handle(request);

        var addedHeaders = request.headers; //bearer token seems to be coming in alredy... sometimes
        if (!addedHeaders.has('Authorization')) {
            return from(this.msalService.acquireTokenSilent({
                scopes: this.msalAngularConfig.consentScopes,
                authority: this.msalConfig.auth.authority,
                loginHint: this.msalService.getAccount()?.accountIdentifier
            }).then(response => {
                addedHeaders = request.headers.append('Authorization', `Bearer ${response.accessToken}`);
                return next.handle(request).pipe(finalize(() => this.loadingService.clearMessage())).toPromise();
            }).catch((err) => {
                console.error(err);
                return Promise.reject(err);
            }).finally(() => {
                request = this.AppendContentType(request, addedHeaders);
                return next.handle(request).toPromise();
            })
            );
        } else {
            if (this.router.url.includes('requests')) {
                this.loadingService.show("Loading Data...");
                this.requestsCount++;
            }
            request = this.AppendContentType(request, addedHeaders);
            return next.handle(request).pipe(finalize(() => {
                if (this.router.url.includes('requests')) {
                    this.requestsCount--;
                    if (this.requestsCount == 0)
                        this.loadingService.clearMessage()
                }
            }));
        }
    }

    private AppendContentType(request: HttpRequest<any>, addedHeaders) {
        if (!(request.url.toLowerCase().includes('attachment') && request.method == "POST"))
            if (request.headers.get('Content-Type') == null)
                addedHeaders = addedHeaders.append('Content-Type', 'application/json');

        return request.clone(
            {
                headers: addedHeaders
            });
    }
}