import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CompleteDecantContainerDialogData {
  totalContainerCount: number,
  currentContainerCount: number
}

@Component({
  selector: 'app-complete-decant-container-modal',
  templateUrl: './complete-decant-container-modal.component.html',
  styleUrls: ['./complete-decant-container-modal.component.scss']
})
export class CompleteDecantContainerModalComponent implements OnInit {
  public totalContainerCount: number;
  public currentContainerCount: number;
  public readyToDisplay;
  constructor(
    public dialogRef: MatDialogRef<CompleteDecantContainerModalComponent>,

    @Inject(MAT_DIALOG_DATA) public data: CompleteDecantContainerDialogData) {

    this.totalContainerCount = data.totalContainerCount;
    this.currentContainerCount = data.currentContainerCount;
  }

  onContinue(): void {
    if (this.currentContainerCount === this.totalContainerCount)
      this.dialogRef.close('reweigh');
    else
      this.dialogRef.close('next');
  }

  ngOnInit(): void {
    this.readyToDisplay = true;
  }

}
