<div class="p-grid">
    <div class="p-col-12">
        <div class="col">
            <h4>Request Details</h4>
        </div>
    </div>
    <div class="p-grid p-col-12 card-container">
        <mat-card class="p-col-12">
            <mat-card-header>
                <mat-card-title>{{ order?.requestNumber }}</mat-card-title>
                <div class="order-status">{{order?.isStaged ? orderStatus + " - Staging" : orderStatus}}</div>
                <span style="cursor: pointer;" (click)="scrollToHoldNotes()" class="p-ml-3" *ngIf="order?.isOnHold">
                    <app-pill Text="On Hold" Type="OnHold" HasPointer="true"></app-pill>
                </span>
                <span *ngIf="showSGSPill">
                    <app-pill Text="SGS Reserved" Type="SgsReserved"></app-pill>
                </span>
                <span *ngIf="showIndyPill">
                    <app-pill Text="Indy Reserved" Type="IndyReserved"></app-pill>
                </span>
                <span *ngIf="order?.isOnSiteOrder">
                    <app-pill [Text]="order?.isOnSiteText" Type="OnSiteActive"></app-pill>
                </span>
                <div class="col" style="text-align: end; margin-right: 15px;"
                    *ngIf="permissionService.canSupressRequestorEmails">
                    <mat-slide-toggle [checked]='isSuppressed' (change)="onSuppressEmailChange($event)">
                        Suppress Requester Emails
                    </mat-slide-toggle>
                </div>
            </mat-card-header>
            <mat-card-content class="p-grid">
                <div class="p-col-1">
                    <span class="label">Requestor</span>
                    <br />
                    <br />
                    <br />
                    <br />
                    <span class="label">Recipient</span>
                </div>
                <div class="p-col-3">
                    <span>{{ order?.requestorName }}</span>
                    <br />
                    <span>{{ order?.requestorPhone }}</span>
                    <br />
                    <span>{{ order?.requestorEmail }}</span>
                    <br />
                    <br />
                    <span>{{ order?.recipientName }}</span>
                    <br />
                    <ng-container *ngIf="order?.recipientOrganizationName">
                        <span>{{ order?.recipientOrganizationName }}</span>
                        <br />
                    </ng-container>
                    <span>{{ order?.primaryPhone }}</span>
                    <br />
                    <span>{{ order?.email }}</span>
                    <br />
                    <span>{{ order?.addressLine1 }}</span>
                    <br />
                    <ng-container *ngIf="order?.addressLine2 && order?.addressLine2.trim() !== ''">
                        <span>{{ order?.addressLine2 }}</span>
                        <br />
                    </ng-container>
                    <ng-container *ngIf="order?.addressLine3 && order?.addressLine3.trim() !== ''">
                        <span>{{ order?.addressLine3 }}</span>
                        <br />
                    </ng-container>
                    <span>
                        {{ order?.city}}, {{order?.stateProvince ? order?.stateProvince : ''}} {{
                        order?.postalCode}}
                    </span>
                    <span>{{order?.countryName}}</span>
                </div>
                <div class="p-col-2">
                    <span class="label">Warehouse</span>
                    <br />
                    <span class="label">Coordinator Name</span>
                    <br />
                    <span class="label">Request Date</span>
                    <br />
                    <span class="label">Ship By Date</span>
                    <br />
                    <span class="label">Priority</span>
                    <br />
                    <span class="label">Request Type</span>
                    <br />
                    <span class="label">Number of Chemicals</span>
                    <br />
                    <span class="label">Total Number of Containers</span>
                    <br />
                    <span class="label">Split Requests</span>
                    <br />
                </div>
                <div class="p-col-2">
                    <span>{{ order?.warehouseName }}</span>
                    <br />
                    <span>{{ order?.assignedToFirstName }} {{ order?.assignedToLastName }}</span>
                    <br />
                    <span>{{ order?.created | standardDateFormat }}</span>
                    <br />
                    <span>{{ order?.shipByDate | standardDateFormat }}</span>
                    <br />
                    <span>{{ order?.priorityName }}</span>
                    <br />
                    <span>{{ order?.requestTypeDesc }}</span>
                    <br />
                    <span>{{ order?.numberOfItems }}</span>
                    <br />
                    <span *ngIf="order">{{ getTotalNumberOfChemicals() }}</span>
                    <br />
                    <span *ngIf="order && !order?.relatedOrders || order?.relatedOrders?.length === 0">No split
                        requests</span>
                    <ng-container *ngIf="order && order.relatedOrders && order.relatedOrders.length > 0">
                        <span class="split-request-link" *ngFor="let relatedOrder of order.relatedOrders"
                            (click)="openSplitRequestInNewTab(relatedOrder.orderID)">{{ relatedOrder.requestNumber
                            }}</span>
                    </ng-container>
                </div>
                <div class="p-col-2">
                    <div>
                        <button mat-raised-button class="p-m-1 button-width" *ngIf="showNonSGSButton()"
                            color="cor-primary" [disabled]="disableSendToPicking || disableSendToPickingButton()"
                            [ngClass]="order.orderStatusID == 2 ? 'success' : ''" (click)="onSendToPicking()">
                            Send to Picking
                        </button>
                        <button mat-raised-button class="p-m-1 button-width"
                            *ngIf='(order?.orderStatusID === OrderStatuses.SGSRequested || order?.orderStatusID === OrderStatuses.SGSComplete) && isLoginUserCoordinator && isSGSavailable'
                            [disabled]="disableSGSCompleteRequestButton()" (click)='onSGSCompleted()'
                            color="cor-primary">
                            SGS Completed Request
                        </button>
                        <button *ngIf="showNonSGSButton() && isSGSavailable" mat-raised-button color="cor-primary"
                            class="p-m-1 button-width" (click)="requestTransferModal()"
                            [disabled]="disableTransferToSGSButton()">
                            Transfer Request to SGS
                        </button>
                        <button mat-raised-button class="p-m-1 button-width"
                            *ngIf='showTransferIndyButton() && isSGSavailable'
                            [disabled]="disableTransferToIndyButton()" (click)='onTransferToIndy()' color="cor-primary">
                            Transfer to Indy
                        </button>
                        <button mat-raised-button class="p-m-1 button-width" color="cor-primary"
                            *ngIf="showReprintPicklist()" [disabled]="disableReprintPicklistButton()"
                            (click)="onPrintPicklist()">
                            Print Picklist
                        </button>
                        <button mat-raised-button color="cor-primary" class="p-m-1 button-width"
                            [disabled]="disableDocumentsButton()" (click)="requestDocumentsModal()">
                            Documents
                        </button>
                        <button mat-raised-button class="p-m-1 button-width" *ngIf='isLoginUserCoordinator'
                            (click)="order?.isOnHold ? RemoveHold(): placeHold()" [disabled]="disableHoldButton()"
                            color="cor-primary">{{
                            order?.isOnHold ? 'Remove Hold' : 'Place on Hold' }}
                        </button>
                        <button mat-raised-button color="cor-primary" class="p-m-1 button-width"
                            (click)="onChangeWarehouse()" *ngIf="useMultipleWarehouses"
                            [disabled]="!(order?.orderStatusID === OrderStatuses.New || order?.orderStatusID === OrderStatuses.Pending)">
                            Change Warehouse
                        </button>
                    </div>
                </div>
                <div class="p-col-2">
                    <div>
                        <button mat-raised-button color="cor-primary" class="p-m-1 button-width"
                            *ngIf='isLoginUserCoordinator' (click)="splitRequestModal()"
                            [disabled]="disableSplitButton()">Split Request
                        </button>
                        <button mat-raised-button color="cor-primary" class="p-m-1 button-width"
                            (click)="requestOverView()">Request Overview
                        </button>
                        <button mat-raised-button color="cor-primary" class="p-m-1 button-width"
                            (click)="requestDestinationGuide()">
                            Destination Guide
                        </button>
                        <button mat-raised-button color="cor-primary" class="p-m-1 button-width"
                            (click)="onViewHistory()">
                            View History
                        </button>
                        <button mat-raised-button color="cor-primary" class="p-m-1 button-width"
                            *ngIf='isLoginUserCoordinator' (click)="packingMaterialsModal()"
                            [disabled]="disableSplitButton()">Packing Materials
                        </button>
                        <button mat-raised-button color="cor-primary" class="p-m-1 button-width"
                            (click)="reassignCoordinatorModal()" [disabled]="disableAssignButton()">
                            {{(order?.assignedToFirstName && order?.assignedToFirstName !=='') ? 'Reassign' : 'Assign'}}
                        </button>
                        <button mat-raised-button class="p-m-1 button-width" color="cor-primary"
                            *ngIf="isLoginUserCoordinator" [disabled]="disableCancelRequest"
                            (click)="onCancelOrder(order?.orderID)">
                            Cancel Request
                        </button>
                        <app-change-status *ngIf="permissionService.canRevertStatus" [order]="order"
                            (onStatusChange)="onStatusChange($event)"></app-change-status>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<mat-accordion #onHoldAccordion *ngIf="order?.isOnHold">
    <mat-expansion-panel #holdNotesPanel class="p-mt-1" expanded="true">
        <mat-expansion-panel-header>
            <mat-panel-title style="padding-top: 5px;">Hold Notes</mat-panel-title>
            <span *ngIf="!holdNotesPanel.expanded" class="ellipsis">
                <span *ngIf="order?.holdReasons">{{order?.holdReasons}} </span>
                <span *ngIf="order?.holdNotes"> - {{ order?.holdNotes }}</span>
            </span>
        </mat-expansion-panel-header>
        <div class="p-pb-3" *ngIf="holdNotesPanel.expanded">
            <mat-label>Hold Reason <span style="color: red;">*</span></mat-label>
            <ng-template #showMultiselect>
                <p-multiSelect #reasonSelect placeholder="Select Reason" appendTo="body" defaultLabel="Select Reason"
                    id="hold-reason" [ngModel]="selectedReason" [options]="reasons" optionLabel="name"
                    (onChange)="onReasonChange($event)" optionValue="reasonID" [disabled]="disableReasons"
                    class="reasons-dropdown order-detail" [filter]="true" selectedItemsLabel="{0} Reasons Selected">
                </p-multiSelect>
            </ng-template>
            <span class="p-ml-3" *ngIf="disableReasons;else showMultiselect">{{order?.holdReasons}}</span>
        </div>
        <text-inline-edit #textInlineEl [Comments]="order?.holdNotes" [disableSave]="disableSave"
            (saveNotes)='saveHoldNotes($event)' (cancelEmitter)="onCancel()" (editEmitter)="onEdit()"
            [disableTextEdit]="!isLoginUserCoordinator">
        </text-inline-edit>
    </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
    <mat-expansion-panel #coordinatorNotesPanel class="p-mt-1" expanded="true">
        <mat-expansion-panel-header>
            <mat-panel-title style="padding-top: 5px;">Coordinator Notes</mat-panel-title>
            <span *ngIf="!coordinatorNotesPanel.expanded" class="ellipsis">{{ order?.coordinatorNotes }}</span>
        </mat-expansion-panel-header>
        <text-inline-edit [Comments]="order?.coordinatorNotes" (saveNotes)='saveNotes($event)'
            [disableTextEdit]="!canEditCoordinatorNotes">
        </text-inline-edit>
    </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="order?.specialInstructions">
    <mat-expansion-panel #specialInstructionsPanel class="p-mt-1" expanded="true">
        <mat-expansion-panel-header>
            <mat-panel-title>Special Instructions</mat-panel-title>
            <span *ngIf="!specialInstructionsPanel.expanded" class="ellipsis">{{order?.specialInstructions}}</span>
        </mat-expansion-panel-header>
        <p class="highlight-yellow" *ngIf='order?.specialInstructions'>{{order?.specialInstructions}}</p>
        <p *ngIf='!order?.specialInstructions'>No Instructions</p>
    </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
    <mat-expansion-panel #shippingPanel class="p-mt-1">
        <mat-expansion-panel-header>
            <mat-panel-title>Shipping Information</mat-panel-title>
            <ng-container *ngIf="!shippingPanel.expanded && packages && packages?.length > 0">
                <span *ngFor="let package of packages; let i = index" [ngClass]="{'panel-link': package.trackingNumber}"
                    (click)="onOpenTracking(package, $event)">{{ package.shipperName }} {{ package.trackingNumber }}{{ i
                    < packages.length - 1 ? ',' : '' }}&nbsp;</span>
            </ng-container>
        </mat-expansion-panel-header>
        <div>
            <div>
                <div class="p-grid" *ngIf='isLoginUserCoordinator'>
                    <div class='p-col-fixed' style="width: 192px;">
                        <label for="carrier">Carrier</label>
                        <p-dropdown class="carrier" appendTo="body" placeholder="Select a Carrier"
                            panelStyleClass='carrier-dropdown' [(ngModel)]="carrier" (ngModelChange)="onCarrierChange()"
                            [options]="carriers" optionLabel="shipperName">
                        </p-dropdown>
                    </div>
                    <div class='p-col-fixed p-pl-2' style="width: 192px;">
                        <label>Tracking Number</label>
                        <input [disabled]="!carrier?.trackingURLFormat" [(ngModel)]='trackingNumbers'
                            placeholder="Enter Tracking Number" type="text" pInputText>
                    </div>
                    <div class='p-col-fixed p-pl-2'>
                        <label for="master-checkbox" class="master-package-label">Master</label>
                        <input [(ngModel)]="isMasterPackage" type="checkbox" id="master-checkbox" class="checkbox" />
                    </div>
                    <div class='p-col-fixed p-pl-2' style="width: 76.8px;">
                        <button mat-raised-button
                            [disabled]='!carrier || (carrier && carrier.trackingURLFormat && !trackingNumbers)'
                            class="align-left" style="margin-top: 24px;" (click)='onAddPackage()'
                            color="cor-primary">Add</button>
                    </div>
                </div>
            </div>
            <div class="p-grid packages-column-headers">
                <span class="p-col-1">Package Code</span>
                <span class="p-col-1">Carrier</span>
                <span class="p-col-3">Tracking Numbers</span>
                <span class="p-col-3">Tracking Status</span>
                <span class="p-col-1">Last Update</span>
                <span class="p-col-2"></span>
            </div>

            <div *ngIf="!packages || packages?.length === 0" class="empty-message">
                No Packages.
            </div>

            <div class="p-col-12 packages-container" *ngIf='packages?.length > 0'>
                <div class="p-grid packages-list" *ngFor="let package of packages;let index = index">
                    <div class="p-col-1">
                        <span>{{ package.packageCode }}</span>
                    </div>
                    <div class="p-col-1">
                        <span>{{package.shipperName}}</span>
                    </div>
                    <div class="p-col-3">
                        <a (click)='onOpenTracking(package)'>{{ package.trackingNumber }}</a>
                    </div>
                    <div class="p-col-3">
                        <span>{{ package.lastTrackingEvent }}</span>
                    </div>
                    <div class="p-col-1">
                        <span>{{ package.lastTrackingUpdate | standardDateFormat }}</span>
                    </div>
                    <div class="p-col-1">
                        <span *ngIf="package.isMaster">
                            <app-pill Text="Master" Type="Master"></app-pill>
                        </span>
                    </div>
                    <div class="p-col-2" style="display: flex; justify-content: flex-end">
                        <button pButton icon="pi pi-globe" class="info" color="cor-primary" tooltipPosition="left" pTooltip="Tracking Details" (click)="onTrackDetails(package)" style="margin-right: 5px;"></button>
                        <button *ngIf='isLoginUserCoordinator' pButton icon="pi pi-pencil" class="info" color="cor-primary"  tooltipPosition="left" pTooltip="Edit Shipping Info" (click)="onEditShipping(package)" style="margin-right: 5px;"></button >
                        <button *ngIf='isLoginUserCoordinator' pButton icon="pi pi-trash" class="delete"  tooltipPosition="left" pTooltip="Delete" severity="danger" [disabled]='(package.containers && package.containers.length > 0)' (click)="deletePackage(package)"></button>
                    </div>             
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
    <mat-expansion-panel #tagsPanel class="p-mt-1">
        <mat-expansion-panel-header>
            <mat-panel-title>Tags</mat-panel-title>
            <ng-container *ngIf="!tagsPanel.expanded">
                <span *ngFor="let tag of order?.tags; let i = index">{{ tag.tagName }}{{ i < order?.tags.length - 1
                        ? ',' : '' }}&nbsp;</span>
            </ng-container>
        </mat-expansion-panel-header>
        <app-tags [tags]='tags' [suggestions]="suggestions" (tagsSaved)="onTagsSaved($event)"
            [disableTags]="!isLoginUserCoordinator"></app-tags>
    </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
    <mat-expansion-panel #attachmentsPanel class="p-mt-1">
        <mat-expansion-panel-header>
            <mat-panel-title>{{ attachmentPanelTitle }}</mat-panel-title>
            <ng-container *ngIf="!attachmentsPanel.expanded">
                <!-- <span *ngFor="let attachment of order?.attachments; let i = index" class="panel-link"
                    (click)="downloadAttachmentFromTitle($event, attachment)">{{ attachment.filename }}{{ i <
                        order?.attachments.length - 1 ? ',' : '' }}&nbsp;</span> -->
            </ng-container>
        </mat-expansion-panel-header>
        <app-attachments #itemAttachments [entity]="attachmentEntity" [isOrder]="true"
            [isLoginUserCoordinator]="isLoginUserCoordinator" [disableAttachment]='true'
            (createOrderAttachment)="createOrderAttachment($event)"
            (updateOrderAttachment)="updateOrderAttachment($event)"
            (removeOrderAttachment)="removeOrderAttachment($event)"
            (downloadOrderAttachment)="downloadAttachment($event)">
        </app-attachments>
    </mat-expansion-panel>
</mat-accordion>

<div class="chemicals-header">
    <h5>Materials</h5>
    <button mat-raised-button color="cor-primary" (click)="expandOrCollapseChemicals()">{{isAllExpanded? 'Collapse' :
        'Expand'}}
        All</button>
</div>

<div class="p-mt-4 order">
    <app-order-item-details #orderItemDetails [order]='order' [useMultipleWarehouses]="useMultipleWarehouses"
        (validateButtonsEE)="validateButtons()">
    </app-order-item-details>
</div>