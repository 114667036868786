import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Observable, timer, Subject, pipe } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap, share, retry, takeUntil, tap } from 'rxjs/operators';
import { DecantStation } from '../../models/decant-models';
import { Configuration } from '../../configuration';

@Injectable()
export class ScalePollingService implements OnDestroy, OnInit {

  private station$: Observable<DecantStation>;
  private selectedStation: DecantStation;
  private stopPolling = new Subject();

  constructor(private http: HttpClient) {
    this.selectedStation = JSON.parse(localStorage.getItem("station"));
    const rootURL = Configuration.REST_API_URL;
    this.station$ = timer(1, 2000).pipe(
      switchMap(() => http.get<DecantStation>(rootURL + `/Stations/${this.getStationID()}`)),
      retry(2),
      share(),
      takeUntil(this.stopPolling)
    );
  }

  ngOnInit() {

  }

  getDecantStation(): Observable<DecantStation> {
    return this.station$;
  }

  getStationID(): string {
    this.selectedStation = JSON.parse(localStorage.getItem("station"));
    return this.selectedStation.stationID;
  }

  ngOnDestroy() {
      this.stopPolling.next();
  }
}