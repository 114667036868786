import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { AutoComplete } from 'primeng/autocomplete';
import { Subject, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/base-component';
import { FilterOption } from 'src/app/models/filter-models';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { LabelService } from 'src/app/services/label-service/label-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { Label } from 'src/app/models/label.model';
import { ActionsCellRendererComponent } from './actions-cell-renderer/actions-cell-renderer.component';
import { debounceTime } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { FileNameRendererComponent } from './file-name-renderer/file-name-renderer.component';
import { AddLabelComponent } from './add-label/add-label.component';
import { GridOptions } from "ag-grid-community";

@Component({
  selector: 'app-manage-labels',
  templateUrl: './manage-labels.component.html',
  styleUrls: ['./manage-labels.component.scss']
})
export class ManageLabelsComponent extends BaseComponent implements OnInit {

  @ViewChild('grid') grid: AgGridAngular;
  rowData: Label[] = [];
  columnDefs: any[];
  isInitialLoad: boolean = true;
  canAddLabel: boolean = false;
  overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">No data found</span>';
  gridOptions: GridOptions = <GridOptions>{};
  private gridApi: any;
  private subscriptions = new Subscription();
  option: FilterOption;
  options: FilterOption[];
  frameworkComponents: any;
  modelChanged = new Subject<string>();

  @ViewChild('autocomplete') autocomplete: AutoComplete;

  ngOnInit(): void {
  }

  constructor(private responseHandler: ResponseHandlingService,
    private router: Router, private dialog: MatDialog,
    private inventoryService: InventoryService,
    private labelService: LabelService) {
    super();
    this.frameworkComponents = {
      actionsRenderer: ActionsCellRendererComponent,
      fileNameRenderer: FileNameRendererComponent
    };
    this.columnDefs = [
      {
        headerName: 'Type',
        field: 'typeName',
        tooltipField: 'typeName',
        width: 300,
      },
      {
        headerName: 'Size',
        field: 'sizeName',
        tooltipField: 'sizeName',
        width: 300
      },
      {
        headerName: 'Language',
        field: 'languageName',
        tooltipField: 'languageName',
        width: 300,
      },
      {
        headerName: 'File Name', field: 'filename',
        cellRenderer: 'fileNameRenderer',
        tooltipValueGetter: (params) => {
          return params.data.filename;
        },
        width: 350
      },
      {
        headerName: 'Last Updated',
        field: 'lastUpdated',
        valueGetter: (params) => {
          if (params.data !== undefined && params.data.lastUpdated) {
            return formatDate(params.data.lastUpdated, 'd-MMM-yyyy', 'en-US');
          }
        },
        tooltipValueGetter: (params) => {
          if (params.data !== undefined && params.data.lastUpdated) {
            return formatDate(params.data.lastUpdated, 'd-MMM-yyyy', 'en-US');
          }
        },
        width: 300
      },
      {
        headerName: '',
        width: 240,
        cellRenderer: 'actionsRenderer'
      }
    ];
    this.modelChanged.pipe(debounceTime(1000)).subscribe((event: any) => {
      this.subscriptions.add(this.inventoryService.getInventoryCatalogItems(event.query)
        .subscribe(response => {
          this.options = response;
        }));
    })
  }

  selectedOptionDesc(option: FilterOption) {
    return option?.itemDesc;
  }

  resizeGrid(params) {
    this.gridApi.sizeColumnsToFit();
  }

  search() {
    if (!this.option) return;
    this.labelService.getLabelList(this.option.itemID).subscribe(response => {
      if (response && response.length) {
        response.forEach(element => {
          element['materialName'] = this.option?.itemDesc;
        });
        this.rowData = response;
      }
      else
        this.rowData = [];
      this.isInitialLoad = false;
    })
  }

  changed(event) {
    this.modelChanged.next(event);
  }

  canSkip: boolean = false;

  onSelect(event: any) {
    this.canSkip = true;
    this.canAddLabel = true;
    this.search();
    setTimeout(() => {
      this.autocomplete?.inputEL?.nativeElement?.blur();
      this.canSkip = false;
    }, 100);
  }

  onFocus() {
    if (this.canSkip) return;
    this.autocomplete.inputEL.nativeElement.value = '';
  }

  onAddLabelTemplate() {
    let _rowData = [];
    this.grid?.api.forEachNode(node => _rowData.push(node.data))
    this.dialog.open(AddLabelComponent, {
      minHeight: '350px',
      width: '400px',
      disableClose: true,
      autoFocus: false,
      data: {
        materialName: this.option?.itemDesc,
        inventoryCatalogID: this.option.itemID,
        rowData: _rowData
      }
    }).afterClosed().subscribe(data => {
      if (data)
        this.gridApi.applyTransaction({ add: [data] });
    })

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.addEventListener('downloadEvent', (e) => {
      this.handleDownloadClick(e);
    });
    this.gridApi.addEventListener('deleteEvent', (e) => {
      this.handleDeleteClick(e);
    });
  }

  handleDownloadClick(event) {
    this.labelService.getLabelById(event.value.data.labelID).subscribe(response => {
      if (!response) return;
      var link = document.createElement('a');
      link.href = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${response.fileContents}`;
      link.download = response.filename;
      link.click();
    })
  }

  handleDeleteClick(event) {
    this.confirmDeleteLabel(event);
  }

  confirmDeleteLabel(event) {
    const dialogData =
      new ConfirmDialogModel("Confirm",
        `Are you sure you want to delete the loaded files ?`,
        "Remove", true);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      disableClose: true,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.deleteLabel(event);
      }
    });
  }

  deleteLabel(event) {
    if (!event?.value?.data?.labelID) return;
    this.subscriptions.add(this.labelService
      .deleteLabel(event.value.data.labelID)
      .subscribe(
        data => {
          this.responseHandler.showSuccess(`Successfully removed label.`);
          this.gridApi.applyTransaction({ remove: [event.value.node.data] });
        }
      ))
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
