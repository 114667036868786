<div class="flex-grid">
    <div class="col">
        <h4>Request Coordination - Warehouse Queue</h4>
    </div>
</div>

<div class="spacer"></div>

<div class="flex-grid">
    <div class="col">
        <app-order-filters #appFilterOrder (modelChange)="handleFilterUpdate($event)"></app-order-filters>
        <div class="p-grid p-justify-center p-mt-2">
            <button id="search-request-order-warehouse" class="submit p-mr-2" mat-raised-button color="cor-primary" [disabled]='hasFilterModelChanged'
                (click)='searchButtonClicked()'>Search Requests</button>
            <button id="clear-filters-order-warehouse" class="submit" mat-raised-button color="cor-primary" [disabled]="disableClearFilters"
                (click)='onClearFilters()'>Clear Filters</button>
        </div>
    </div>
</div>

<div class="spacer"></div>
<div class="spacer"></div>

<div class="flex-grid">
    <mat-card class="order-card-container">

        <mat-card-header class="order-card-container">
            <mat-card-title>Ready to Process</mat-card-title>
        </mat-card-header>

        <div class="spacer"></div>

        <mat-card-content class="messageContainer" *ngIf="warehouseQueueOrdersLoaded && !warehouseQueueOrders.length">
            <div class="spacer"></div>
            <div class="cardListMessage">No matches for selected filter criteria</div>
        </mat-card-content>

        <mat-card-content *ngIf="warehouseQueueOrdersLoaded && warehouseQueueOrders.length">
            <app-order-card *ngFor="let order of warehouseQueueOrders" [headerColor]="'grey'" [order]="order"
                (orderMoved)="handleOrderMoved($event)"></app-order-card>
        </mat-card-content>

    </mat-card>
</div>