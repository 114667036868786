import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GreaterThanZeroValidator } from '../../../shared/validators/greater-than-zero.validator';

@Component({
  selector: 'app-edit-density-modal',
  templateUrl: './edit-density-modal.component.html',
  styleUrls: ['./edit-density-modal.component.scss']
})
export class EditDensityModalComponent implements OnInit {

  editDensityForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<EditDensityModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    var density = new Number(this.data.density);
    this.data.density = parseFloat(density.toFixed(7))?.toString();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close({
      saveChanges: true,
      data: this.editDensityForm.value,
    });
  }

  public buildForm() {

    this.editDensityForm = new FormGroup({
      materialId: new FormControl(null),
      materialName: new FormControl(null),
      density: new FormControl(null, [Validators.required, GreaterThanZeroValidator]),
    });

    this.editDensityForm.patchValue({
      materialId: this.data.catalogID
    });
  }

}
