export function sortData(sortModel, data) {
  var sortPresent = sortModel && sortModel.length > 0;
  if (!sortPresent) {
    return data;
  }
  var resultOfSort = data.slice();
  resultOfSort.sort(function (a, b) {
    for (var k = 0; k < sortModel.length; k++) {
      var sortColModel = sortModel[k];
      var valueA = a[sortColModel.colId];
      var valueB = b[sortColModel.colId];
      if (valueA == valueB) {
        continue;
      }
      var sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
      if ((valueA === null || valueA === undefined) && (valueB === null || valueA === undefined)) {
        return sortDirection * -1;
      }
      if (valueA === null || valueA === undefined) {
        return sortDirection * -1;
      }
      if (valueB === null || valueA === undefined) {
        if (sortDirection == 1) {
          return sortDirection * -1;
        }
        return sortDirection;
      }
      if (valueA > valueB) {
        return sortDirection;
      } else {
        return sortDirection * -1;
      }
    }
    return 0;
  });
  return resultOfSort;
};

export function valueFormatter(value: any) {
  return value ? parseFloat(value)?.toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 7 }) : value;
}