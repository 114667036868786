<h4>Dispose Containers</h4>
<div class="ui-grid">
    <div class="ui-grid-col-12">
        <label for="containerInput">Scan Container</label>
        <input #disposeContainerInput id="containerInput" type="text" [(ngModel)]="containerInput" pInputText autofocus
            class="m-l-1" style="margin-left: 10px;" (blur)="onContainerInput($event)" placeholder="Barcode Entry">
    </div>
</div>
<div class="ui-grid">
    <div class="ui-grid-col-8">
        <ag-grid-angular #roleGrid style="width:89.8%;margin-top: 20px;margin-bottom: 20px" class="ag-theme-alpine dispose-grid"
            domLayout="autoHeight" suppressHorizontalScroll="true" [rowData]="gridData" [columnDefs]="columnDefs"
            [frameworkComponents]="frameworkComponents" [rowHeight]="60" [tooltipShowDelay]="1500"
            (gridSizeChanged)="resizeGrid($event)" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</div>

<div class="ui-grid">
    <div class="ui-grid-col-12">
        <button class="large" mat-raised-button color="cor-danger"
            [disabled]="disableDispose || !gridData.length" (click)="onDisposeContainerClick()">Dispose
            Containers</button>
    </div>
</div>