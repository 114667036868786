import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridOptions } from 'ag-grid-community';
import { MessageService } from 'src/app/shared/services/message-queue.service';
import { PaginatorModel } from 'src/app/core/modules/ag-grid/components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { LabelPrinterModel } from 'src/app/receiving/models/label-printer-model';
import { sortData } from 'src/app/helpers/grid-helpers';
import { map, switchMap, take, finalize } from 'rxjs/operators';
import { ManageRolesService } from 'src/app/services/manage-roles-service/manage-roles.service';
import { ManageRoleActionsComponent } from './manage-role-actions/manage-roles-action.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'manage-roles',
  templateUrl: './manage-roles.component.html',
  styleUrls: ['./manage-roles.component.scss']
})
export class ManageRolesComponent implements OnInit, OnDestroy {
  private gridApi;
  private roles: LabelPrinterModel[];

  public gridOptions: GridOptions;
  public disableClearFilters: boolean = true;
  public paginatorModel: PaginatorModel;
  public hasFilterModelChanged: boolean = true;
  public frameworkComponents: any;
  public overlayNoRowsTemplate: string =
    '<span class="ag-overlay-loading-center">No roles found.</span>';
  private subscriptions = new Subscription();

  constructor(
    private dialog: MatDialog,
    private readonly messageService: MessageService,
    private loadingService: LoadingService,
    private readonly manageRolesService: ManageRolesService,
    private router: Router
  ) {
    this.frameworkComponents = {
      actionsRendered: ManageRoleActionsComponent,
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  columnDefs = [
    {
      headerName: 'Name',
      field: 'roleName',
      headerTooltip: 'Name',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.roleName;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Description',
      field: 'description',
      headerTooltip: 'Description',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.description;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      width: 100,
      cellRenderer: 'actionsRendered',
      cellClass: ['button-edit', 'display-end'],
      cellRendererParams: { data: this },
    },
  ];

  ngOnInit(): void {
    this.paginatorModel = {
      totalRows: 0,
      totalPages: 0,
      pageSize: 25,
      currentPage: 1,
      leftArrowsDisabled: true,
      rightArrowsDisabled: true,
    };
    this.gridOptions = {};

    this.subscriptions.add(this.messageService.message
      .pipe(
        switchMap((data) => {
          if (data.data == 'delete') {
            this.loadingService.show("Loading Roles...");
            return this.manageRolesService.deleteRoles(
              data.roleID
            );
          }
        }),
        switchMap(() => {
          return this.manageRolesService.getRoles();
        }),
        map((roles) => {
          this.bindGridData(roles);
        })
      )
      .subscribe(() => {
        this.loadingService.clearMessage();
      }))
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    this.loadingService.show("Loading Roles...");
    this.manageRolesService
      .getRoles()
      .pipe(
        take(1),
        map((roles) => {
          this.bindGridData(roles);
        }),
        finalize(() => {
          this.loadingService.clearMessage();
        })
      )
      .subscribe();
  }

  onResizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  updateCurrentPage(): void {
    this.paginatorModel.currentPage = 1;
    this.paginatorModel.leftArrowsDisabled = true;
    if (this.paginatorModel.totalPages > 1) {
      this.paginatorModel.rightArrowsDisabled = false;
    }
  }

  handlePaginatorCommand(event: number): void {
    switch (event) {
      case 1:
        this.gridApi.paginationGoToFirstPage();
        break;
      case 2:
        this.gridApi.paginationGoToPreviousPage();
        break;
      case 3:
        this.gridApi.paginationGoToNextPage();
        break;
      case 4:
        this.gridApi.paginationGoToLastPage();
        break;
      default:
        this.gridApi.paginationSetPageSize(event);
        this.gridApi.paginationGoToFirstPage();
        break;
    }
  }

  setDataSource(returnedData) {
    this.gridApi.purgeInfiniteCache();
    this.bindGridData(returnedData);
  }

  onAddRole() {
    this.router.navigate(['roles/new']);
  }

  private bindGridData(data): void {
    var dataSource = {
      rowCount: null,
      getRows: function (params) {
        setTimeout(function () {
          var dataAfterSorting = sortData(params.sortModel, data);
          var rowsThisPage = dataAfterSorting.slice(
            params.startRow,
            params.endRow
          );
          var lastRow = dataAfterSorting.length;
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      },
    };

    this.paginatorModel.totalRows = data.length;
    this.paginatorModel.totalPages = Math.ceil(
      data.length / this.paginatorModel.pageSize
    );

    if (this.paginatorModel.totalRows == 0) {
      this.gridApi.setDatasource(dataSource);
      this.gridApi.showNoRowsOverlay();
    } else {
      this.updateCurrentPage();
      this.gridApi.hideOverlay();
      this.gridApi.setDatasource(dataSource);
    }
  }
}
