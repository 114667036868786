import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/core/modules/navigate/services/navigation.service';
import { receivingConstants } from '../receiving.constants';
import { ReceivingService } from '../services/receiving.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MaxDateValidator } from 'src/app/core/validators/max-date.validator';
import { DateValidator } from 'src/app/core/validators/date.validator';
import { DateCompareOperatorType, DateCompareValidator } from 'src/app/core/validators/dependent-field.validator';
import * as moment from 'moment';
import { ReceivingStateService } from '../services/receiving-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialViewModel } from '../models/material.model';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { SessionModel, StatusType, } from '../models/session.model';
import { LookupValuesModel, LookupModel } from '../models/lookup.model';
import { Subscription } from 'rxjs';
import { DirtyComponent } from '../guards/dirty-component';
import { ReceivingSessionBaseComponent } from '../core/components/receiving-session-base.component';
import { DataEntryViewModel } from '../models/view-models/dataentry-view.model';
import { dataAdapterServiceFactory } from '../services/data-adapters/material-data-adapter.service-provider';
import { InventoryService } from '../../services/inventory-service/inventory-service';
import { LocationModel } from '../models/location.model';
import { Warehouse } from 'src/app/models/decant-models';
import { MessageService } from 'src/app/shared/services/message-queue.service';

@Component({
  selector: 'app-data-entry',
  templateUrl: './data-entry.component.html',
  styleUrls: ['./data-entry.component.scss']
})
export class DataEntryComponent extends ReceivingSessionBaseComponent implements OnInit, OnDestroy, DirtyComponent {

  dataEntryForm: FormGroup = undefined;
  lookupValues: LookupModel[];
  countries: LookupValuesModel[];
  chemicalState: LookupValuesModel[];
  shippingConditions: LookupValuesModel[];
  sensitivities: LookupValuesModel[];
  incomingContainers: LookupValuesModel[];
  storageLocations: LocationModel[];
  unitOfMeasures: LookupValuesModel[];
  selectedCity1: any;
  selectedMaterial: Partial<MaterialViewModel> = null;
  receivingSession: SessionModel = null;
  buttonText: string = "Submit";
  private $receivingStateModelSubscription: Subscription;
  private $receivingSessionSubscription: Subscription;
  isDirty = false;
  viewModel: DataEntryViewModel;

  constructor(
    private receivingService: ReceivingService,
    private receivingStateService: ReceivingStateService,
    private inventoryService: InventoryService,
    private router: Router,
    private navService: NavigationService,
    private responseHandlingService: ResponseHandlingService,
    private loadingService: LoadingService,
    private messageService: MessageService,
    route: ActivatedRoute) {
    //Setting the back button to be visible
    super(receivingService, receivingStateService, route, loadingService);
    this.loadingService.show();
  }

  canDeactivate() {
    return this.isDirty;
  }

  @HostListener('window:beforeunload', ['$event'])
  onbeforeunload(event) {
    if (this.isDirty) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  ngOnDestroy(): void {
    if (this.$receivingSessionSubscription) this.$receivingSessionSubscription.unsubscribe();
    if (this.$receivingStateModelSubscription) this.$receivingStateModelSubscription.unsubscribe();
  }

  private async loadInitialData() {
    this.chemicalState = [{ id: 75, description: 'Liquid' }, { id: 77, description: 'Solid' }];
    this.shippingConditions = [{ id: 1, description: 'Ambient' }, { id: 2, description: 'Dry Ice' }, { id: 3, description: 'Cold Pack' }];
    this.sensitivities = [{ id: 1, description: '1' }, { id: 2, description: '2' }, { id: 3, description: '3' }, { id: 4, description: '4' }];
    this.countries = this.receivingService.loadLookupValues(`${receivingConstants.LookupTypes.Countries}`, this.lookupValues);
    this.incomingContainers = this.receivingService.loadLookupValues(`${receivingConstants.LookupTypes.Containers}`, this.lookupValues);
    this.unitOfMeasures = this.receivingService.loadLookupValues(`${receivingConstants.LookupTypes.UnitOfMeasures}`, this.lookupValues);
    this.loadingService.clearMessage();
  }


  async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.navService.setBackButton({ visible: true, routerLink: this.sessionId ? receivingConstants.Routes.PreviousSessions : receivingConstants.Routes.Validate });
    this.lookupValues = await this.receivingService.getLookupValues(`${receivingConstants.LookupTypes.Countries},${receivingConstants.LookupTypes.Containers},${receivingConstants.LookupTypes.StorageLocations},${receivingConstants.LookupTypes.UnitOfMeasures}`);
    await this.loadInitialData();
    this.dataEntryForm = this.initForm();
    this.initReceivingSession();
    this.dataEntryForm.valueChanges.subscribe(e => this.isDirty = true);
  }

  initReceivingSession() {
    this.$receivingSessionSubscription = this.receivingStateService.getReceivingSession().subscribe(session => {
      if (session === null || session === undefined) {
        //If material is not available then return to search page
        this.router.navigate([receivingConstants.Routes.Search]);
        this.loadingService.clearMessage();
        return;
      }

      this.messageService.message.next(session);
      this.receivingSession = session;
      this.CheckForContainerID();
      //defaults country
      this.setDefaultCountry(session.countryOfOriginId);
      this.populateStorageLocations();
      //If receiptDate is not null meaning data-entry has been saved once and populate the existing data
      if (session.receiptDate) {
        const model = Object.assign({}, this.receivingSession) as any;
        model.receiptDate = new Date(session.receiptDate);
        model.manufacturerDate = new Date(session.manufacturerDate);
        model.expirationDate = new Date(session.expirationDate);
        this.dataEntryForm.patchValue(model);
      }

      this.initReceivingStateModel();
    });
  }


  initReceivingStateModel() {
   
    this.$receivingStateModelSubscription = this.receivingStateService.getReceivingStateModel().subscribe(receivingStateModel => {
      if (receivingStateModel) {
        if (!this.sessionId)
          this.dataAdapter = dataAdapterServiceFactory({ materialType: receivingStateModel.material.materialTypeId }, this.receivingService, this.receivingStateService);

      
        this.selectedMaterial = receivingStateModel.material;
        this.viewModel = this.dataAdapter.toDataEntryViewModel(receivingStateModel);
        if (!this.receivingSession.receiptDate) {
          this.dataEntryForm.get('manufacturerLotId').setValue(this.selectedMaterial.lotNumber);
          if (this.selectedMaterial.expirationDate && moment(this.selectedMaterial.expirationDate).isValid()) {
            const expirationDate = new Date(new Date(this.selectedMaterial.expirationDate).toDateString());
            this.dataEntryForm.get('expirationDate').setValue(expirationDate);
          }

          if (this.selectedMaterial.manufacturerDate && moment(this.selectedMaterial.manufacturerDate).isValid()) {
            const manufacturerDate = new Date(new Date(this.selectedMaterial.manufacturerDate).toDateString());
            this.dataEntryForm.get('manufacturerDate').setValue(manufacturerDate);
            this.onManufacturerDateChange(manufacturerDate);
          }

          if (this.selectedMaterial.reachComplaint !== undefined) {
            this.dataEntryForm.get('reachCompliant').setValue(this.selectedMaterial.reachComplaint);
          }

          if (this.selectedMaterial?.density !== undefined) {
            this.dataEntryForm.get('density').setValue(this.selectedMaterial.density);
          }
        }

        if (this.selectedMaterial?.desiccant !== undefined) {
          this.dataEntryForm.get('desiccant').setValue(this.selectedMaterial.desiccant);
        }
        if (this.selectedMaterial?.shippingConditions !== undefined) {
          this.dataEntryForm.get('shippingConditions').setValue(this.selectedMaterial.shippingConditions);
        }

        if (this.selectedMaterial?.storageConditions == "" || this.selectedMaterial?.storageConditions == undefined) {
          this.dataEntryForm.get('storageConditions').setValue("None Provided");
        } else {
          this.dataEntryForm.get('storageConditions').setValue(this.selectedMaterial.storageConditions);
        }

        if (this.selectedMaterial.disableManufaturerLodIdControl === true) {
          this.dataEntryForm.get('manufacturerLotId').disable();
        }

        if (this.selectedMaterial.disableCountryOfOrigin === true) {
          this.dataEntryForm.get('countryOfOriginId').disable();
        }

        if (this.selectedMaterial.peroxideForming === true) {
          this.dataEntryForm.get('peroxideForming').setValue(this.selectedMaterial.peroxideForming);
          if (this.viewModel.disablePeroxideForming) this.dataEntryForm.get('peroxideForming').disable();
        }

      }

      let defaultUoM: LookupValuesModel;
      if (this.selectedMaterial && this.selectedMaterial.unitOfMeasureId) {
        defaultUoM = this.unitOfMeasures.find(x => x.id === this.selectedMaterial.unitOfMeasureId);
      } else if (this.receivingSession && this.receivingSession.unitOfMeasureId) {
        defaultUoM = this.unitOfMeasures.find(x => x.id === this.receivingSession.unitOfMeasureId);
      }
      else {
        defaultUoM = this.unitOfMeasures.find(x => x.description.toLocaleLowerCase() === receivingConstants.DefaultUnitOfMeasure);
      }

      if (defaultUoM) this.dataEntryForm.get('unitOfMeasureId').setValue(defaultUoM.id);
    });
  }

  initForm(): FormGroup {

    return new FormGroup({
      receiptDate: new FormControl(new Date(new Date().toDateString()),
        [
          Validators.required,
          MaxDateValidator(receivingConstants.DateFormat, new Date()),
          DateValidator(receivingConstants.DateFormat),
          DateCompareValidator(DateCompareOperatorType.GreaterThanEqual, 'manufacturerDate')
        ]),
      noOfContainers: new FormControl('',
        [
          Validators.required,
          Validators.maxLength(3),
          Validators.min(1),
          Validators.pattern("^[0-9]*$")
        ]),
      manufacturerLotId: new FormControl('',
        [
          Validators.required,
          Validators.maxLength(24)
        ]),
      // colonyFormingUnits: new FormControl('',
      // [
      //   Validators.maxLength(24),
      //   Validators.pattern("^[0-9]*$")
      // ]),
      manufacturerDate: new FormControl('',
        [
          Validators.required,
          MaxDateValidator(receivingConstants.DateFormat, new Date()),
          DateValidator(receivingConstants.DateFormat),
          DateCompareValidator(DateCompareOperatorType.LessThanEqual, 'receiptDate'),
          DateCompareValidator(DateCompareOperatorType.LessThan, 'expirationDate')
        ]),
      expirationDate: new FormControl('',
        [
          Validators.required,
          DateValidator(receivingConstants.DateFormat),
          DateCompareValidator(DateCompareOperatorType.GreaterThan, 'manufacturerDate')
        ]),
      countryOfOriginId: new FormControl('',
        [
          Validators.required
        ]),
      chemicalStateID: new FormControl('',
        [
          Validators.required
        ]),
      shippingConditions: new FormControl('', []),
      sensitivities: new FormControl('', []),
      extraPperequired: new FormControl(false),
      desiccant: new FormControl(false),
      incomingContainerTypeId: new FormControl('',
        [
          Validators.required
        ]),
      storageLocationId: new FormControl('',
        [
          Validators.required
        ]),
      reachCompliant: new FormControl(false),
      notes: new FormControl(''),
      certifiedPurity: new FormControl('',
        [
          Validators.maxLength(10)
        ]),
      density: new FormControl(null,
        [
          Validators.max(9999999999),
          Validators.min(0.001)
        ]),
      unitOfMeasureId: new FormControl('', []),
      peroxideForming: new FormControl(false),
      storageConditions: new FormControl('')
    });


  }

  onSelectManufacturerDate(value: Date) {
    this.onManufacturerDateChange(value);
  }

  onBlurManufacturerDate(event: any) {
    const manufacturerControl = this.dataEntryForm.get('manufacturerDate');
    this.onManufacturerDateChange(manufacturerControl.value as Date);
  }

  onManufacturerDateChange(value: Date) {
    const manufacturerControl = this.dataEntryForm.get('manufacturerDate');
    const expirationDateControl = this.dataEntryForm.get('expirationDate');
    const manufacturerDate = moment(value, receivingConstants.DateFormat, true);
    if (manufacturerDate.isValid() && manufacturerControl.valid === true && (!expirationDateControl.value)) {
      let expirationDate: moment.Moment = manufacturerDate.add(this.viewModel.yearsToExpiration, 'years');
      this.dataEntryForm.get('expirationDate').setValue(expirationDate.toDate());
    }
  }

  // validateFormingUnits(){
  //   const val = this.dataEntryForm.get('colonyFormingUnits').value;
  //   if(!(val > 0 && val <= 2000000000)){
  //     this.dataEntryForm.get('colonyFormingUnits').setErrors({'pattern': true});
  //   }
  // }

  async onSubmit() {
    this.dataEntryForm.updateValueAndValidity();
    if (this.dataEntryForm.disabled && this.receivingSession.catalogRecordID > 0) {
      this.isDirty = false;
      this.router.navigate([receivingConstants.Routes.Weighing]);
    } else if (this.dataEntryForm.valid) {
      this.loadingService.show('Updating session...');
      const model = Object.assign({}, this.receivingSession);
      const formData = this.dataEntryForm.getRawValue();
      model.receiptDate = formData['receiptDate'].toDateString();
      model.noOfContainers = formData['noOfContainers'];
      model.manufacturerLotId = formData['manufacturerLotId'];
      // model.colonyFormingUnits = formData['colonyFormingUnits'];
      model.manufacturerDate = formData['manufacturerDate'].toDateString();
      model.expirationDate = formData['expirationDate'].toDateString();
      model.countryOfOriginId = formData['countryOfOriginId'];
      model.extraPperequired = formData['extraPperequired'];
      model.desiccant = formData['desiccant'];
      model.chemicalStateID = formData['chemicalStateID'];
      model.shippingConditions = formData['shippingConditions'];
      model.sensitivities = formData['sensitivities'];
      model.incomingContainerTypeId = formData['incomingContainerTypeId'];
      model.notes = formData['notes'];
      model.reachCompliant = formData['reachCompliant'];
      model.storageLocationId = formData['storageLocationId'];
      model.certifiedPurity = formData['certifiedPurity'];
      model.unitOfMeasureId = formData['unitOfMeasureId'];
      model.density = formData['density'];
      model.peroxideForming = formData['peroxideForming'];
      if (model.statusType == StatusType.LargeDecant) {
        model.statusType = StatusType.Draft;
      }
      else if (model.statusType == StatusType.Defect) {
        model.statusType = StatusType.Draft;
      }

      try {

        await this.receivingService.updateSession(model);
        this.receivingStateService.setReceivingSession(model);
        this.responseHandlingService.showSuccess("Session successfully updated.");
        this.isDirty = false;
        this.router.navigate([this.sessionId ? `${receivingConstants.Routes.Weighing}/${this.sessionId}` : receivingConstants.Routes.Weighing]);
      } catch {
      }
      finally {
        this.loadingService.clearMessage();
      }

    } else {
      this.responseHandlingService.showWarning("Form is not valid.");
    }
  }

  setDefaultCountry(countryId: number) {
    if (countryId > 0) {
      var defaultCountry = this.countries.find(x => x.id === countryId);
      if (defaultCountry) {
        this.dataEntryForm.get('countryOfOriginId').setValue(defaultCountry.id);
      }
    }
  }

  CheckForContainerID() {
    if (this.receivingSession.catalogRecordID > 0) {
      this.isDirty = false;
      this.buttonText = "Continue "
      this.dataEntryForm.disable();
    }
  }

  populateStorageLocations() {
    var wareHouse = JSON.parse(localStorage.getItem('breadcrumbMetadata')) as Warehouse;
    this.inventoryService.getLocations().subscribe((locations) => {
      if (this.receivingSession.isGLP) {
        var glpParentLocation = locations.filter(x => x.isGLP && x.parentLocationID == wareHouse.itemID)[0];
        this.storageLocations = locations.filter(x => x.parentLocationID == glpParentLocation.locationID);
      } else {
        var stockParentLocation = locations.filter(x => x.isStock && x.parentLocationID == wareHouse.itemID)[0];
        this.storageLocations = locations.filter(x => x.parentLocationID == stockParentLocation.locationID);
      }
    });
  }
}
