import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PrintStatusModel } from '../models/print-status.model';
import { SignalREvent, SignalREventType } from '../models/signalR.model';
import { UpdateWeightRequest } from '../models/weighing.model';

@Injectable()
export class SignalRScaleServiceSubscription implements ISignalRSubscriptionService {
    public signalREventsSubscription = new BehaviorSubject<SignalREvent>({ type: SignalREventType.Initialized });
    constructor() {

    }
    method: string = 'UpdateWeight';
    hub: string = 'scales';
    onNotification= (scaleId, updateWeightRequest) => {
      this.signalREventsSubscription.next({ type: SignalREventType.UpdateWeight, data: { scaleId: scaleId, weightKG: updateWeightRequest.weightKG } as UpdateWeightRequest });
    }
}

@Injectable()
export class SignalRPrintServiceSubscription implements ISignalRSubscriptionService {
    public signalREventsSubscription = new BehaviorSubject<SignalREvent>({ type: SignalREventType.Initialized });

    constructor() {

    }
    method: string = 'UpdateStatus';
    hub: string = 'printing';
    onNotification = (printStatus: PrintStatusModel) =>{
        this.signalREventsSubscription.next({ type: SignalREventType.PrintStatus, data: printStatus });
    }
}

export interface ISignalRSubscriptionService {
    signalREventsSubscription: BehaviorSubject<SignalREvent>;
    onNotification:(...args: any[])=>any;
    hub: string;
    method: string;
}
