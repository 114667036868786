import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReceivingService } from '../services/receiving.service';
import { receivingConstants } from '../receiving.constants';
import { Router } from "@angular/router"
import { Subscription } from 'rxjs';
import { MaterialViewModel } from '../models/material.model';
import { ReceivingStateService } from '../services/receiving-state.service';
import { ConfirmationService } from 'primeng/api';
import { NavigationService } from 'src/app/core/modules/navigate/services/navigation.service';
import { ReceivingStateViewModel, SessionModel, StatusType } from '../models/session.model';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ExternalServiceType } from '../models/enumerations';
import { MaterialDataAdapterService } from '../services/data-adapters/material-data-adapter.service';
import { dataAdapterServiceFactory } from '../services/data-adapters/material-data-adapter.service-provider';
import { ValidationViewModel } from '../models/view-models/validation-view.model';
import { HttpStatusCode } from 'src/app/models/response-models';
import { LookupModel, LookupValuesModel } from '../models/lookup.model';
import { first } from 'rxjs/operators';
import { Warehouse } from 'src/app/models/decant-models';


@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit, OnDestroy {
  seceivingStateServiceSubscription: Subscription;
  receivingStateModel: ReceivingStateViewModel;
  session: SessionModel;
  dataAdapter: MaterialDataAdapterService;
  viewModel: ValidationViewModel;
  displayLargeDecantDialog: boolean = false;
  largeDecantContainerTypeId?: number = null;
  displayDefectDialog: boolean = false;
  defectReasonId?: number = null;
  private selectedMaterialSubscription: Subscription;
  incomingContainers: LookupValuesModel[];
  defectReasons: LookupValuesModel[];
  lookupValues: LookupModel[];

  selectedContainerId?: number = null;
  selectedDefectReasonsId?: number = null;

  constructor(private readonly router: Router,
    private receivingStateService: ReceivingStateService,
    private confirmationService: ConfirmationService,
    private navService: NavigationService,
    private receiving: ReceivingService,
    private loadingService: LoadingService,
    private toastr: ResponseHandlingService,
    private receivingService: ReceivingService) {
  }

  async ngOnInit(): Promise<void> {
    this.navService.setBackButton({ visible: true, routerLink: receivingConstants.Routes.MaterialSelection });

    this.receivingStateService.getReceivingStateModel().pipe(first()).subscribe(receivingStateModel => {
      //On page refresh or direct url hit return to the search page
      if (receivingStateModel == null || receivingStateModel == undefined) {
        this.router.navigate([receivingConstants.Routes.Search]);
      } else {
        this.receivingStateModel = receivingStateModel;
        this.dataAdapter = dataAdapterServiceFactory({ materialType: this.receivingStateModel.material.materialTypeId }, this.receiving, this.receivingStateService);
        this.viewModel = this.dataAdapter.toValidationViewModel(receivingStateModel);
        this.dataAdapter.getSDSData(this.receivingStateModel.material as MaterialViewModel, this.viewModel)
          .then(material => {
            this.receivingStateService.updateMaterialModel(material as MaterialViewModel);
            this.viewModel.sdsModel = material.sdsModel;
          })
          .catch(error => {
            //We only gonna get error for 404/Not Found... and for 500
            if (error.status === HttpStatusCode.NOT_FOUND) this.toastr.showWarning("SDS Information not found");
            if (error.status === HttpStatusCode.INTERNAL_SERVER_ERROR) this.toastr.showWarning("SDS check cannot be completed at this time");
          });
        this.dataAdapter.getMaterial(this.receivingStateModel.material as MaterialViewModel)
          .then(material => {
            this.receivingStateService.updateMaterialModel(material as MaterialViewModel);
          })
          .catch(error => {
            //We only gonna get error for 404/Not Found
            this.toastr.showWarning("Material Information not found");
          });
      }
    });
    this.lookupValues = await this.receivingService.getLookupValues(`${receivingConstants.LookupTypes.Containers},${receivingConstants.LookupTypes.DefectReasons}`);
    await this.loadInitialData();
  }

  private async loadInitialData() {
    const incomingContainers = this.receivingService.loadLookupValues(`${receivingConstants.LookupTypes.Containers}`, this.lookupValues);
    this.incomingContainers = incomingContainers.filter(x => x.metadata?.IsLargeDecant === true);
    this.defectReasons = this.receivingService.loadLookupValues(`${receivingConstants.LookupTypes.DefectReasons}`, this.lookupValues);
    this.defectReasons = this.defectReasons.filter(x => x.metadata.Selectable == true);
    this.loadingService.clearMessage();
  }


  ngOnDestroy(): void {

  }

  confirm() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to log a defect?',
      header: 'Alert',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        this.receivingStateService.setReceivingStateModel(this.receivingStateModel);
        await this.createDraftSession(true, false);
      },
      reject: () => {
        this.receivingStateService.setReceivingStateModel(this.receivingStateModel);
      }
    });
  }

  confirmLargeDecant() {
    if (this.selectedContainerId !== null) {
      this.closeLargeDecantDialog();
      this.createDraftSession(false, true);
    } else {
      this.toastr.showWarning("Please select Container.");
    }
  }

  showLargeDecantDialog() {
    this.displayLargeDecantDialog = true;
    this.selectedContainerId = null;
  }

  closeLargeDecantDialog() {
    this.displayLargeDecantDialog = false;
  }

  confirmDefect() {
    if (this.selectedDefectReasonsId !== null) {
      this.closeDefectDialog();
      this.createDraftSession(true, false);
    } else {
      this.toastr.showWarning("Please select Defect.");
    }
  }

  showDefectDialog() {
    this.displayDefectDialog = true;
    this.selectedDefectReasonsId = null;
  }

  closeDefectDialog() {
    this.displayDefectDialog = false;
  }

  async createDraftSession(hasDefect: boolean, hasLargeDecant: boolean) {

    this.loadingService.show(hasDefect ? 'Creating defect' : ((hasLargeDecant === true) ? 'Creating Large Decant.' : 'Creating session.'));
    this.session = this.setSessionFromState(hasDefect, hasLargeDecant)

    try {
      // Call create seesion API
      const session = await this.receiving.createDraftSession(this.session);
      if (hasDefect || hasLargeDecant) {
        if (hasDefect) {
          this.toastr.showSuccess("Defect has been created.", 3000, "Success");
        } else {
          this.toastr.showSuccess("Large Decant has been created.", 3000, "Success");
        }
        this.receivingStateService.setReceivingSession(session);
        this.router.navigate([receivingConstants.Routes.Search]);
      } else {
        this.toastr.showSuccess("Session has been created", 3000, "Session Created");
        this.receivingStateService.setReceivingSession(session);
        this.router.navigate([receivingConstants.Routes.DataEntry]);
      }
    } catch (error) {

    }
    this.loadingService.clearMessage();
  }
  private setSessionFromState(hasDefect: boolean, hasLargeDecant: boolean): SessionModel {
    var wareHouse = {} as Warehouse;
    var warehouseStored = localStorage.getItem('breadcrumbMetadata');
    if(warehouseStored)
      wareHouse = JSON.parse(localStorage.getItem('breadcrumbMetadata')) as Warehouse;
    else
      wareHouse = null

    //put back view model (possibly) modified values into selected material first
    this.refreshMaterialWithViewModel();

    const selectedMaterial = this.receivingStateModel.material;
    const selectedAddress = this.receivingStateModel.address;
    const selectedSupplier = this.receivingStateModel.supplier;

    var session = {
      sessionID: 0,
      materialName: selectedMaterial.materialName,
      alternateName: selectedMaterial.alternateName,
      materialId: selectedMaterial.materialId,
      materialTypeId: selectedMaterial.materialTypeId,
      supplierId: selectedSupplier.supplierId,
      isCoaavailable: selectedMaterial.COA,
      isSdsavailable: selectedMaterial.SDS,
      supplierAddressID: selectedAddress.addressId,
      countryOfOriginName: selectedMaterial.countryOfOrigin || selectedAddress.country,
      countryOfOriginId: selectedMaterial.countryOfOriginId,
      isGLP: selectedMaterial.isGLP,
      GLP: selectedMaterial.GLP,
      statusType: hasDefect === true ? StatusType.Defect : ((hasLargeDecant === true) ? StatusType.LargeDecant : StatusType.Draft),
      submittedWeight: selectedMaterial.submittedWeight,
      tsn: selectedMaterial.tsn,
      molecularWeight: selectedMaterial.molecularWeight,
      certificationDate: selectedMaterial.certificationDate,
      recertificationDate: selectedMaterial.recertificationDate,
      cortevaLotID: +selectedMaterial.lotId,
      manufacturerDate: selectedMaterial.manufacturerDate,
      lotNumber: selectedMaterial.lotNumber,
      reachCompliant: selectedMaterial.reachComplaint,
      supplierName: selectedSupplier.name,
      density: selectedMaterial.density,
      formulationType: selectedMaterial.formulationType,
      moleculeType: selectedMaterial.moleculeType,
      stage: selectedMaterial.stage,
      specificGravity: selectedMaterial.specificGravity,
      unitOfMeasureId: selectedMaterial.unitOfMeasureId,
      largeDecantContainerTypeId: this.selectedContainerId,
      manufacturerLotId: selectedMaterial.lotNumber,
      expirationDate: selectedMaterial.expirationDate,
      peroxideForming: selectedMaterial.peroxideForming,
      lastDefectReasonId: this.selectedDefectReasonsId,
      desiccant: Boolean(selectedMaterial.desiccant),
      shippingConditions: JSON.stringify(selectedMaterial.shippingConditions),
      storageConditions: selectedMaterial?.storageConditions
    } as SessionModel;

    if(wareHouse)
      return {... session, warehouseID: wareHouse.itemID, warehouseName: wareHouse.itemDesc };

    return session;
  }

  private refreshMaterialWithViewModel() {

    if (!this.receivingStateModel?.material)
      throw new Error('Cannot refresh to a non-existing state model');

    this.receivingStateModel.material.isGLP = this.viewModel.isGLP;
    this.receivingStateModel.material.COA = this.viewModel.COA;
    this.receivingStateModel.material.SDS = this.viewModel.SDS;
  }

  async onAcceptButtonClick() {
    if (this.viewModel.isLoading) return;
    await this.createDraftSession(false, false);
  }

  isGLPControlDisabled(): boolean {
    if (this.receivingStateModel.material.materialTypeId === ExternalServiceType.Coformulant) {
      this.receivingStateModel.material.isGLP = false;
      return true;
    }

    return false;
  }
}
