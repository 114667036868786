<div>
  <h4>Packing & Shipping</h4>
  <div class="spacer"></div>
  <app-warehouse-station (selectedStation)="onStationChange($event)"></app-warehouse-station>
  <div class="spacer"></div>
  <label for="picksheetField">Scan Picklist to Start Packing</label>
  <input #picksheet id="picksheetField" type="text" [(ngModel)]="picksheetInput"
    style="margin-left:10px;margin-right:10px;" pInputText (blur)="onPicksheetInput($event)"
    (click)="clearField($event)" placeholder="Scan Picklist" [disabled]="!selectedStation">
  <div class="spacer"></div>
  <h6>Requests to Pack</h6>

  <div class="wrapper" *ngIf="packingOrdersLoaded">
    <div class="table" *ngFor="let row of displayOrders">
      <div class="row header">
        <div class="cell">
          <a (click)="openPackingDetail(row.orderID)">Request ID: {{ row.requestNumber }}</a>
          <span class="p-ml-3" *ngIf="row?.isOnSiteOrder">
            <app-pill [Text]="row?.isOnSiteText" Type="OnSiteActive"></app-pill>
          </span>
        </div>
        <span class="cell" *ngIf="row.isOnHold">
          <app-pill Text="On Hold" Type="OnHold"></app-pill>
        </span>
      </div>
      <div class="row">
        <div class="cell left bolder" data-title="Chemical">
          Chemical
        </div>
        <div class="cell bolder" data-title="Chemical">
          Lot Number
        </div>
        <div class="cell right bolder" data-title="SpecialInstructions">
          Special Instructions
        </div>
        <div class="cell right bolder" data-title="NumberofContainers">
          Number of Containers
        </div>
      </div>
      <div class="row" *ngFor="let container of row.containers">
        <div class="cell left" data-title="">
          {{ container.requestedContainerLabel }}
        </div>
        <div class="cell">
          {{ container.assignedLotNumber }}
        </div>
        <div class="cell align-right" data-title="SpecialInstructions">
          <span *ngIf="container.isCold">
            <app-pill Text="Cold" Type="Cold"></app-pill>
          </span>
          <span *ngIf="container.isDesiccant">
            <app-pill Text="Desiccant" Type="Desiccant"></app-pill>
          </span>
        </div>
        <div class="cell right" data-title="NumberofContainers">
          {{ container.numberOfContainers }}
        </div>
      </div>
      <div class="row">
        <div class="cell left bolder" data-title="Chemical">
          Total Containers for Request
        </div>
        <div class="cell right bolder" data-title="NumberofContainers">
          {{ sumOrderContainers(row) }}
        </div>
      </div>
    </div>
  </div>

</div>