<div class="flex-grid">
    <div class="col p-mb-3">
        <h4>Manage Users</h4>
        <p>Users need to be added to the <span class="f-bold">GSS_Alchemist</span> group in Azure Active Directory in order to use the application. Once they are in the group, roles can be assigned here.</p>
    </div>
</div>

<app-ag-grid-custom-paginator (paginatorCommand)="handlePaginatorCommand($event)" [paginatorModel]="paginatorModel"
    [showPageSizes]="true"></app-ag-grid-custom-paginator>

<ag-grid-angular style="width:100%; height: calc(100vh - 415px);" class="ag-theme-alpine" rowModelType="infinite"
    domLayout="normal" tooltipShowDelay="0" suppressCellSelection="true" alwaysShowVerticalScroll="true"
    [pagination]="true" [suppressPaginationPanel]="true" [paginationPageSize]="25" [cacheOverflowSize]="2"
    [maxConcurrentDatasourceRequests]="2" [infiniteInitialRowCount]="1" [columnDefs]="columnDefs" [cacheBlockSize]="100"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate" [frameworkComponents]="frameworkComponents"
    (cellValueChanged)="setDataSource($event)" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"
    (gridSizeChanged)="onResizeGrid($event)" (sortChanged)="updateCurrentPage()">
</ag-grid-angular>

<app-ag-grid-custom-paginator (paginatorCommand)="handlePaginatorCommand($event)" [paginatorModel]="paginatorModel"
    [showPageSizes]="false"></app-ag-grid-custom-paginator>
    