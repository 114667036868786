<div>
    <h5 class="p-mb-3">Select Invoice type</h5>
    <div class="p-grid p-p-2" style="justify-content: space-between;">
        <button mat-raised-button [color]="getInvoiceColor('Basic')" id='basicBtn' class="p-mr-1"
            (click)="toggleDisplayBrokerButtons()">Basic
            customs
            invoice</button>
        <button mat-raised-button [color]="getInvoiceColor('Detailed')" id='detailedBtn'
            (click)="toggleDisplayDetailedBrokerButtons()">Detailed
            customs
            invoice</button>
    </div>
    <div *ngIf="hideBrokerButtons" class="p-p-2">
        <hr>
        <h6>Select Broker</h6>
        <button mat-raised-button [color]="getColor('Primary')" class="p-mr-2" id="primaryBtn"
            (click)="onGenerateDocument('PrimaryBasic')">Primary
            Broker</button>
        <button mat-raised-button [color]="getColor('Alternate')" [disabled]="!enableAlternateBroker" id="alternateBtn"
            (click)="onGenerateDocument('AlternateBasic')">Alternate Broker</button>
    </div>
    <div *ngIf="hideDetailedBrokerButtons" class="p-p-2">
        <hr>
        <h6>Select Broker</h6>
        <button mat-raised-button [color]="getColor('Primary')" class="p-mr-2" id="primaryBtn"
            (click)="onGenerateDocument('PrimaryDetailed')">Primary
            Broker</button>
        <button mat-raised-button [color]="getColor('Alternate')" [disabled]="!enableAlternateBroker" id="alternateBtn"
            (click)="onGenerateDocument('AlternateDetailed')">Alternate Broker</button>
    </div>
    <div class="actions flex-end p-mt-3">
        <button mat-raised-button color="cor-default" (click)="cancel()">Close</button>
    </div>
</div>