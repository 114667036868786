import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MessageService } from '../../../shared/services/message-queue.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { EditLabelPrinterModalComponent } from '../edit-label-printer-modal/edit-label-printer-modal.component';

@Component({
  selector: 'app-manage-label-printer-actions',
  templateUrl: './manage-label-printer-actions.component.html',
  styleUrls: ['./manage-label-printer-actions.component.scss'],
})
export class ManageLabelPrinterActionsComponent implements ICellRendererAngularComp {
  private params: any;

  constructor(
    private dialog: MatDialog,
    private readonly messageService: MessageService,
    private toastr: ResponseHandlingService
  ) { }

  onEditPrinter() {
    this.dialog
      .open(EditLabelPrinterModalComponent, {
        data: this.params.data,
        autoFocus: false,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal'
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({ data: 'edit', reference: dialogResult });
        }
      });
  }

  onDeletePrinter() {
    const confirmDialog = new ConfirmDialogModel(
      'Confirm Action',
      `This record will be deleted. Are you sure?`,
      'OK',
      false,
      true,
      'Cancel'
    );
    this.dialog
      .open(ConfirmDialogComponent, { maxWidth: '400px', data: confirmDialog })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageService.message.next({ data: 'delete', reference: this.params.data });
        }
      });
  }

  canBeDeleted(): boolean {
    return this.params.data.allowDelete;
  }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
