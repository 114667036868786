import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BreadCrumbService } from './services/bread-crumb.service';
import { BreadcrumbComponent } from './components/breadcrumb.component';
import { BreadCrumbFlow } from './models/bread-crumb-flow.model';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [
    CommonModule,
    BreadcrumbModule
  ],
  exports: [BreadcrumbComponent],
})
export class BreadCrumbModule {

  constructor(@Optional() @SkipSelf() parentModule?: BreadCrumbModule) { }

  static forRoot(flow?: BreadCrumbFlow): ModuleWithProviders<BreadCrumbModule> {

    return {
      ngModule: BreadCrumbModule,
      providers: [
        { provide: 'IBreadCrumbService', useClass: BreadCrumbService },
        {
          provide: 'flow',
          useValue: flow || {} as BreadCrumbFlow,
          multi: true
        }
      ]
    };
  }

  static forChild(flow: BreadCrumbFlow): ModuleWithProviders<BreadCrumbModule> {
    return {
      ngModule: BreadCrumbModule,
      providers: [
        {
          provide: 'flow',
          useValue: flow,
          multi: true
        }
      ]
    };
  }
}
