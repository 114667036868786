import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, Renderer2, ViewChild } from '@angular/core';
import { User } from 'src/app/models/security-models';
import { SelectItem } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { prependOnceListener } from 'process';

@Component({
  selector: 'app-order-assign-modal',
  templateUrl: './order-assign-modal.component.html',
  styleUrls: ['./order-assign-modal.component.scss']
})
export class OrderAssignModalComponent {
  public selectedCoordinator: User;
  title: string;
  message: string;
  secondaryMessage: string;
  coordinators: User[];
  coordinatorSelectList: SelectItem[];

  @ViewChild('dropdown') dropdown: Dropdown;

  constructor(public dialogRef: MatDialogRef<OrderAssignModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderAssignModalModel,
    private renderer: Renderer2) {
    const loggedInUser = JSON.parse(localStorage.getItem("authUserInfo"));
    this.title = data.title;
    this.message = data.message;
    this.coordinators = data.coordinators;
    this.secondaryMessage = data.secondaryMessage;
    this.coordinatorSelectList = [];

    this.coordinators.forEach(coordinator => {
      const currentCoordinator = { label: `${coordinator.firstName} ${coordinator.lastName}`, value: coordinator };
      if (coordinator.email == loggedInUser.email) {
        this.selectedCoordinator = coordinator;
        this.coordinatorSelectList.unshift(currentCoordinator);
      } else {
        this.coordinatorSelectList.push(currentCoordinator);
      }
    });
  }

  onConfirm(): void {
    this.dialogRef.close(this.selectedCoordinator);
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  onDropDownShow(event) {
    setTimeout(() => {
      let panel: HTMLElement = document.querySelector('.p-dropdown-panel');
      if (panel) {
        let scrollY = Math.abs(document.querySelector('body').getBoundingClientRect().y);
        let yPosition = this.dropdown.el.nativeElement.getBoundingClientRect().y;
        yPosition += scrollY + 30;
        panel.style.top = '+' + yPosition + 'px';
      }
    }, 1);
  }

}

export class OrderAssignModalModel {

  constructor(public title: string, public message: string, public coordinators: User[], public secondaryMessage: string = "") {
  }
}