<div class="card">
    <app-pageheader></app-pageheader>
    <div class="p-d-flex p-grid p-jc-start p-jc-lg-center p-jc-xl-center">
        <form [formGroup]="dataEntryForm" class="p-mt-3 p-lg-8 p-mt-3 p-sm-12 p-lg-6 submit-form"
            (ngSubmit)="onSubmit()" *ngIf="dataEntryForm">
            <div class="p-fluid p-grid p-formgrid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="calendarRecieptDate" class="p-required">
                        <span class="asterisk">*</span>
                        Receipt Date:
                    </label>
                    <p-calendar InputMask mask="99/99/9999" placeholder="MM/DD/YYYY" [keepInvalid]="true"
                        formControlName="receiptDate" [showIcon]="true" [inputId]="txtRecieptDate" [required]="true"
                        [selectOtherMonths]="true" [showOnFocus]="false">
                    </p-calendar>
                    <div [ngClass]="{'p-error': dataEntryForm.get('receiptDate').invalid}"
                        *ngIf="dataEntryForm.get('receiptDate').touched">
                        <small *ngIf="dataEntryForm.get('receiptDate').errors?.invalidMaxDate">
                            Receipt date cannot be a future date</small>
                        <small *ngIf="dataEntryForm.get('receiptDate').errors?.invalidDate">
                            A valid receipt date is required</small>
                        <small *ngIf="dataEntryForm.get('receiptDate').errors?.dateCompareInvalid_manufacturerDate">
                            Receipt date must be greater than or equal to manufacturer date</small>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="txtNoOfContainers" class="p-required">
                        <span class="asterisk">*</span>
                        Number of Containers:
                    </label>
                    <input id="txtNoOfContainers" type="text" maxlength="3" pInputText class="p-lg-10"
                        formControlName="noOfContainers">
                    <div [ngClass]="{'p-error': dataEntryForm.get('noOfContainers').invalid}"
                        *ngIf="dataEntryForm.get('noOfContainers').touched">
                        <small *ngIf="dataEntryForm.get('noOfContainers').errors?.required">
                            Number of containers is required.</small>
                        <small
                            *ngIf="dataEntryForm.get('noOfContainers').errors?.pattern || dataEntryForm.get('noOfContainers').errors?.min">
                            Only whole numbers are allowed, and value must be greater than 0.</small>
                        <small
                            *ngIf="dataEntryForm.get('noOfContainers').errors?.maxlength && !dataEntryForm.get('noOfContainers').errors?.pattern">
                            Only 3 digits allowed</small>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="txtManufaturerLotId" class="p-required">
                        <span class="asterisk">*</span>
                        Lot Number:
                    </label>
                    <input type="text" id="txtManufaturerLotId" pInputText class="p-lg-10"
                        formControlName="manufacturerLotId" maxlength="24">
                    <div [ngClass]="{'p-error': dataEntryForm.get('manufacturerLotId').invalid}"
                        *ngIf="dataEntryForm.get('manufacturerLotId').touched">
                        <small *ngIf="dataEntryForm.get('manufacturerLotId').errors?.maxlength">
                            Only {{dataEntryForm.get('manufacturerLotId').errors?.maxlength.requiredLength}}
                            characters allowed</small>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="calendarmanufacturerDate" class="p-required">
                        <span class="asterisk">*</span>
                        Manufacturer Date:
                    </label>
                    <p-calendar InputMask mask="99/99/9999" placeholder="MM/DD/YYYY" [keepInvalid]="true"
                        formControlName="manufacturerDate" [showIcon]="true" [inputId]="txtManufacturerDate"
                        (onSelect)="onSelectManufacturerDate($event)" (onBlur)="onBlurManufacturerDate($event)"
                        [selectOtherMonths]="true" [showOnFocus]="false">
                    </p-calendar>
                    <div [ngClass]="{'p-error': dataEntryForm.get('manufacturerDate').invalid}"
                        *ngIf="dataEntryForm.get('manufacturerDate').touched">
                        <small *ngIf="dataEntryForm.get('manufacturerDate').errors?.invalidMaxDate">
                            Manufacturer date cannot be a future date</small>
                        <small *ngIf="dataEntryForm.get('manufacturerDate').errors?.invalidDate">
                            A valid manufacturer date is required</small>
                        <small *ngIf="dataEntryForm.get('manufacturerDate').errors?.dateCompareInvalid_receiptDate">
                            Manufacturer date must be less than or equal to receipt date</small>
                        <small *ngIf="dataEntryForm.get('manufacturerDate').errors?.dateCompareInvalid_expirationDate">
                            Manufacturer date must be less than expiration date</small>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="calendarExpirationDate" class="p-required">
                        <span class="asterisk">*</span>
                        Expiration Date:
                    </label>
                    <p-calendar InputMask mask="99/99/9999" placeholder="MM/DD/YYYY" [keepInvalid]="true"
                        formControlName="expirationDate" [showIcon]="true" [inputId]="txtExpirationDate"
                        [selectOtherMonths]="true" [showOnFocus]="false">
                    </p-calendar>
                    <div [ngClass]="{'p-error': dataEntryForm.get('expirationDate').invalid}"
                        *ngIf="dataEntryForm.get('expirationDate').touched">
                        <small *ngIf="dataEntryForm.get('expirationDate').errors?.invalidDate">
                            A valid expiration date is required</small>
                        <small *ngIf="dataEntryForm.get('expirationDate').errors?.dateCompareInvalid_manufacturerDate">
                            Expiration date must be greater than manufacturer date</small>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="ddlIncomingContainers" class="p-required">
                        <span class="asterisk">*</span>
                        Country of Origin:
                    </label>
                    <p-dropdown [filter]="true" filterBy="description" [inputId]="ddlCountryOfOrigin"
                        [options]="countries" formControlName="countryOfOriginId" placeholder="Select a Country"
                        optionLabel="description" optionValue="id" [resetFilterOnHide]="true">
                    </p-dropdown>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="txtStorageConditionsId" class="p-required">
                        Storage Conditions:
                    </label>
                    <input type="text" id="txtStorageConditionsId" pInputText class="p-lg-10"
                        formControlName="storageConditions" disabled>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="ddlStorageLocations" class="p-required">
                        <span class="asterisk">*</span>
                        Storage Locations:
                    </label>
                    <p-dropdown [filter]="true" filterBy="description" [inputId]="ddlStorageLocations"
                        [options]="storageLocations" placeholder="Select a Storage Location"
                        formControlName="storageLocationId" optionLabel="locationName" optionValue="locationID"
                        [resetFilterOnHide]="true">
                    </p-dropdown>
                    <div [ngClass]="{'p-error': dataEntryForm.get('storageLocationId').invalid}"
                        *ngIf="dataEntryForm.get('storageLocationId').touched">
                        <small *ngIf="dataEntryForm.get('storageLocationId').errors">
                            A storage location must be selected</small>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="ddlIncomingContainers" class="p-required">
                        <span class="asterisk">*</span>
                        Incoming Container:
                    </label>
                    <p-dropdown [filter]="true" filterBy="description" [inputId]="ddlIncomingContainers"
                        [ngClass]="{'p-ml-1':true}" [options]="incomingContainers" placeholder="Select a Container"
                        formControlName="incomingContainerTypeId" optionLabel="description" optionValue="id"
                        [resetFilterOnHide]="true">
                    </p-dropdown>
                    <div [ngClass]="{'p-error': dataEntryForm.get('incomingContainerTypeId').invalid}"
                        *ngIf="dataEntryForm.get('incomingContainerTypeId').touched">
                        <small *ngIf="dataEntryForm.get('incomingContainerTypeId').errors">
                            A container must be selected</small>
                    </div>
                </div>
                <div class="p-col-12 p-field p-md-6">
                    <label for="txtDensity" class="p-required">
                        Density:
                    </label>
                    <div class="p-inputgroup" style="align-items: center;">
                        <input id="txtDensity" mask="separator.3" thousandSeparator="" maxlength="10"
                            [dropSpecialCharacters]="false" pInputText formControlName="density"
                            style="margin-right: 10px">
                        <label for="unitOfMeasure">
                            <span>kg/L</span>
                        </label>
                    </div>
                    <div [ngClass]="{'p-error': dataEntryForm.get('density').invalid}"
                        *ngIf="dataEntryForm.get('density').touched">
                        <small *ngIf="dataEntryForm.get('density').errors">
                            Density must be greater than 0.</small>
                    </div>
                </div>
                <div class="p-col-12 p-field p-md-6">
                    <label for="ddlIncomingContainers" class="p-required">
                        <span class="asterisk">*</span>
                        Chemical State:
                    </label>
                    <p-dropdown [filter]="true" filterBy="description" [inputId]="ddlChemicalState"
                        [options]="chemicalState" formControlName="chemicalStateID"
                        placeholder="Select a Chemical State" optionLabel="description" optionValue="id"
                        [resetFilterOnHide]="true">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-field p-md-6">
                    <label for="ddlShippingConditions" class="p-required">
                        Shipping Conditions:
                    </label>
                    <p-dropdown [filter]="true" filterBy="description" [inputId]="ddlShippingConditions"
                        [options]="shippingConditions" formControlName="shippingConditions"
                        placeholder="Select a Shipping Condition" optionLabel="description" optionValue="description"
                        [resetFilterOnHide]="true">
                    </p-dropdown>
                </div>
                <!-- <div class="p-col-12 p-field p-md-6">
                    <label for="txtColonyFormingUnits">
                        CFUs:
                    </label>
                    <input type="text" id="txtColonyFormingUnits" pInputText class="p-lg-10"
                        formControlName="colonyFormingUnits" maxlength="10" (blur)="validateFormingUnits()">
                        <div [ngClass]="{'p-error': dataEntryForm.get('colonyFormingUnits').invalid}"
                        *ngIf="dataEntryForm.get('colonyFormingUnits').touched">
                        <small
                            *ngIf="dataEntryForm.get('colonyFormingUnits').errors?.pattern">
                            Only whole numbers are allowed, and value must be greater than 0 and less than 2,000,000,000.</small>
                    </div>
                </div> -->
                <div class="p-col-12 p-field p-md-6">
                    <label for="ddlSensitivities" class="p-required">
                        Sensitivities:
                    </label>
                    <p-dropdown [filter]="true" filterBy="description" [inputId]="ddlSensitivities"
                        [options]="sensitivities" formControlName="sensitivities" placeholder="Select Sensitivities"
                        optionLabel="description" optionValue="description" [resetFilterOnHide]="true">
                    </p-dropdown>
                </div>              
                <div class="p-col-12 p-d-flex p-field p-flex-column p-flex-md-row p-jc-between">
                    <div class="p-field-checkbox p-mb-1">
                        <p-inputSwitch inputId="peroxideForming" formControlName="peroxideForming"></p-inputSwitch>
                        <label for="peroxideForming">
                            Peroxide Forming
                        </label>
                    </div>
                    <div class="p-field-checkbox p-mb-1">
                        <p-inputSwitch inputId="reachCompliant" formControlName="reachCompliant"></p-inputSwitch>
                        <label for="reachCompliant">
                            REACH Compliant
                        </label>
                    </div>
                    <div class="p-field-checkbox p-mb-1">
                        <p-inputSwitch inputId="extraPperequired" formControlName="extraPperequired"></p-inputSwitch>
                        <label for="extraPperequired">
                            Extra PPE required
                        </label>
                    </div>
                    <div class="p-field-checkbox p-mb-1">
                        <p-inputSwitch inputId="desiccant" formControlName="desiccant"></p-inputSwitch>
                        <label for="desiccant">
                            Desiccant
                        </label>
                    </div>
                </div>
                <div class="p-field p-col-12 p-hidden">
                    <div class="p-grid">
                        <div class="p-field p-col-12 p-md-6">
                            <label for="ddlIncomingContainers">
                                Certified Purity:
                            </label>
                            <input type="text" id="txtCertifiedPurity" pInputText class="p-lg-10"
                                formControlName="certifiedPurity" maxlength="10">
                        </div>
                    </div>
                </div>
                <div class="p-col-12">
                    <label for="checkboxPPEReuired">
                        Notes:
                    </label>
                    <textarea type="text" rows="4" pInputTextarea class="p-fullwidth" formControlName="notes"
                        maxlength="250"></textarea>
                </div>
            </div>
            <app-navigate>
                <div class="p-text-right p-col">
                    <button id="btnSearchMaterial" class="p-mb-2 p-as-center" [disabled]="dataEntryForm.invalid" pButton
                        pRipple type="submit" label={{buttonText}} icon="pi pi-angle-right" iconPos="right">
                    </button>
                </div>
            </app-navigate>
        </form>
    </div>
</div>