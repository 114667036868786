import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp, ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-grid-numeric-editor',
  templateUrl: './ag-grid-numeric-editor.component.html',
  styleUrls: ['./ag-grid-numeric-editor.component.scss']
 // encapsulation: ViewEncapsulation.None
})

  export class AgGridNumericEditorComponent implements ICellRendererAngularComp {

    public params: any;
   // public value: any;
   private gridApi: any;

    public cancelBeforeStart: boolean = false;
    public ismoveAll: boolean=false;
    public _value: number = 0;
    _step: number = 1;
    _min: number = 1;
    _max: number = Infinity;
    _wrap: boolean = false;
    color: string = 'default';

    @ViewChild('input') input: ElementRef;

   

    constructor() { }

    agInit(params: any): void {
   //   alert( params.data.moveAll);
      this.gridApi = params.api;
        this.params = params;
        this._min=1;
        this.ismoveAll = params.data.moveAll;
        this._value =  params.data.pendingNoOfContainers;
        this._max = params.data.pendingNoOfContainers;
        if(params.data.pendingNoOfContainers === 1)
        {
        this.params.node.setDataValue('numbertoMove',params.data.pendingNoOfContainers);
        }

        if (Number(this.params.value) > 0)
            this._value = this.params.value;

        if (params.keyPress == 8 || params.keyPress == 46) {     // "Backspace" || "Delete"
            this._value = 1;
        } else {
            this.cancelBeforeStart = params.charPress && ('1234567890.'.indexOf(params.charPress) < 0);
            if (params.charPress && !this.cancelBeforeStart) {
                this._value = params.charPress;
            }
        }
    }

    getValue(): any {
        return this._value;
    }
    refresh(): boolean {
      return false;
    }
    ngAfterViewInit() {

    }

    focusout(event){
      let entervalue = event.target.value;
      if(event.target.value == '' || entervalue == 0){
        this._value = this._min;
        this.params.node.setDataValue('numbertoMove',  this._value);
      }
    }
    onKeyUp(event): void {
      let enterValue=  event.target.value;
      if(enterValue  > this._max)
      {
        this._value = this._max;
        this.params.node.setDataValue('numbertoMove',  this._value);
      } else
      {
      this.params.node.setDataValue('numbertoMove',  enterValue);
      }
     
    }

    private parseNumber(num: any): number {
      return +num;
    }
  
    private parseBoolean(bool: any): boolean {
      return !!bool;
    }
  
    setColor(color: string): void {
      this.color = color;
    }
  
    getColor(): string {
      return this.color
    }
  
    incrementValue(step: number = 1): void {
      let inputValue = this._value + step;
  
      if (this._wrap) {
        inputValue = this.wrappedValue(inputValue);
      }
  
      this._value = inputValue;
      this.params.node.setDataValue('numbertoMove',  this._value);
    }
  
    private wrappedValue(inputValue): number {
      if (inputValue > this._max) {
        return this._min + inputValue - this._max;
      }
  
      if (inputValue < this._min) {
  
        if (this._max === Infinity) {
          return 0;
        }
  
        return this._max + inputValue;
      }
  
      return inputValue;
    }
  
    shouldDisableDecrement(inputValue: number): boolean {
      return !this._wrap && inputValue <= this._min;
    }
  
    shouldDisableIncrement(inputValue: number): boolean {
      return !this._wrap && inputValue >= this._max;
    }
  
    
}
