import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsService } from './app-insights-service/app-insights-service';
import { EnvironmentService } from './environment-service/environment-service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private loggingService: AppInsightsService, private env: EnvironmentService) {
        super();
    }

    handleError(error: Error) {
        console.error(`ErrorHandlingService.handlerError:\n${error}`)
        if (this.env.currentEnvironment.name != 'LOCAL')
            this.loggingService.logException(error); // Manually log exception
    }
}