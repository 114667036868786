import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-hold-reason-modal',
  templateUrl: './hold-reason-modal.component.html',
  styleUrls: ['./hold-reason-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HoldReasonModalComponent implements OnInit {

  public holdReasonForm: FormGroup;
  public buttonLabel: string;
  public reason: string = '';
  public reasonID: number;
  public isEditing: boolean;
  public holdReasonLength = new BehaviorSubject(0);

  constructor(
    public dialogRef: MatDialogRef<HoldReasonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loader: LoadingService,
    private dialog: MatDialog,
    public orderService: OrderService) {
    if (typeof data !== 'undefined' && data) {
      this.buttonLabel = "Edit";
      this.reasonID = this.data.reasonID;
      this.holdReasonLength.next(this.data.name.length)
      this.isEditing = true;
    } else {
      this.buttonLabel = "Add";
      this.reasonID = 0;
    }
  }

  ngOnInit(): void {
    this.buildForm();
    this.holdReasonForm.get("holdReason").valueChanges.subscribe((v) => this.holdReasonLength.next(v.length));
  }

  private buildForm() {
    this.holdReasonForm = new FormGroup({
      holdReason: new FormControl(null, [Validators.required, Validators.maxLength(80)]),
      requireComment: new FormControl(false)
    });
    if (this.isEditing)
      this.patchForm();
  }

  get fc() {
    return this.holdReasonForm.controls;
  }

  get fv() {
    return this.holdReasonForm.value;
  }

  save() {
    const data = {
      reasonID: this.reasonID,
      name: this.holdReasonForm.value.holdReason,
      requireComment: this.holdReasonForm.value.requireComment
    }
    this.dialogRef.close(data);
  }

  cancel() {
    if (this.holdReasonForm.pristine) {
      this.dialogRef.close(false);
    } else {
      const dialogData = new ConfirmDialogModel("Unsaved Data", `There are changes that are not saved.  Do you wish to proceed?`, "Cancel", false, true, "Discard Changes");
      this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        disableClose: true,
        data: dialogData
      }).afterClosed().subscribe(dialogResult => {
        if (!dialogResult)
          this.dialogRef.close(false);
      });
    }
  }

  private patchForm() {
    this.holdReasonForm.patchValue({
      holdReason: this.data.name,
      requireComment: this.data.requireComment,
    });
  }
}