<h4>Decanting</h4>
<div class="p-grid">

  <div class="p-col-6">
    <app-warehouse-station (selectedStation)="onStationChange($event)"></app-warehouse-station>
    <div class="spacer"></div>
    <label for="picksheetField">Scan Request ID from Pick Sheet</label>
    <!-- <input #picksheet id="picksheetField" type="text" [(ngModel)]="picksheetInput"
      style="margin-left:10px;margin-right:10px;" pInputText (blur)="onPicksheetInput($event)"
      (click)="clearField($event)" placeholder="Barcode Entry" [disabled]="!selectedStation"> -->
    <input #picksheet id="picksheetField" type="text" [(ngModel)]="picksheetInput"
      style="margin-left:10px;margin-right:10px;" pInputText (blur)="onPicksheetInput($event)"
      (click)="clearField($event)" placeholder="Barcode Entry" [disabled]="!selectedStation">
    <div class="spacer"></div>
  </div>
  <div class="p-col-6">
    <mat-button-toggle-group #group="matButtonToggleGroup" [value]="selectedVal" (change)="onValChange(group.value)">
      <mat-button-toggle value="1">
        Stock
      </mat-button-toggle>
      <mat-button-toggle value="2">
        GLP
      </mat-button-toggle>
      <mat-button-toggle value="3">
        View All
      </mat-button-toggle>
    </mat-button-toggle-group>


  </div>
</div>
<h6>Requests to Decant</h6>

<div tabindex="0" *ngIf="decantOrdersLoaded">

  <div class="table" *ngFor="let row of displayOrders">
    <div class="row header">
      <div class="cell">
        <a (click)="handleOrderClicked(row)">Request ID: {{ row.requestNumber }}</a>
      </div>
      <div class="cell">
        <span *ngIf="row?.isOnSiteOrder">
          <app-pill [Text]="row?.isOnSiteText" Type="OnSiteActive"></app-pill>
        </span>
      </div>
      <div class="cell">
        <span style="cursor: pointer;" class="p-ml-3 cell" *ngIf="row.requestTypeID === RequestTypes.GLP">
          <app-pill Text="GLP" Type="GLP"></app-pill>
        </span>
      </div>
      <div class="cell">
        <span style="cursor: pointer;" class="p-ml-3 cell" *ngIf="On Hold">
          <app-pill Text="On Hold" Type="OnHold"></app-pill>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="cell left bolder" data-title="Chemical">
        Chemical
      </div>
      <div class="cell bolder" data-title="LotNumber" style="font-weight: bolder">
        Lot Number
      </div>
      <div class="cell center bolder" data-title="TotalAmountRequest">
        Total Amount Requested
      </div>
      <div class="cell right bolder" data-title="NumberofContainers">
        Number of Containers
      </div>
      <div class="cell right bolder" data-title="SpecialInstructions">
        Special Instructions
      </div>
    </div>
    <div class="row" *ngFor="let container of row.containers">
      <div class="cell left" data-title="" pTooltip="{{container.containerLabelFmt}}" tooltipPosition="top"
        showDelay="1500">
        {{ container.containerLabelFmt }}
      </div>
      <div class="cell">
        {{ container.assignedLotNumber }}
      </div>
      <div class="cell center" data-title="TotalAmountRequest">
        {{ container.totalAmountRequested | weightKG }} {{ container.unitOfMeasureDesc }}
      </div>
      <div class="cell right" data-title="NumberofContainers">
        {{ container.numberOfContainers }}
      </div>
      <div class="cell right" data-title="SpecialInstructions">
        <span *ngIf="container.isCold">
          <app-pill Text="Cold" Type="Cold"></app-pill>
        </span>
        <span *ngIf="container.isDesiccant">
          <app-pill Text="Desiccant" Type="Desiccant"></app-pill>
        </span>
        <span *ngIf="container.extraPPERequired">
          <app-pill Text="Extra PPE Required" Type="ExtraPPERequired"></app-pill>
        </span>
        <span *ngIf="container.isNoDecantContainer">
          <app-pill Text="No Decant" Type="NoDecant"></app-pill>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="cell left bolder" data-title="Chemical">
        Total Containers for Request
      </div>
      <div class="cell"></div>
      <div class="cell"></div>
      <div class="cell right bolder">
        {{ sumOrderContainers(row) }}
      </div>
    </div>
  </div>
</div>
