<div class="flex-grid">
    <div class="col">
        <h4>Request Coordination - Request Prep</h4>
    </div>
    <div class="col" style="text-align: end">
        <mat-slide-toggle [checked]="gridMode" (change)="onViewModeChange($event)">
           Grid View
        </mat-slide-toggle>
    </div>
</div>

<div class="spacer"></div>

<div class="flex-grid">
    <div class="col">
        <app-order-filters #appFilterOrder (modelChange)="handleFilterUpdate($event)">
        </app-order-filters>
        <div class="p-grid p-justify-center p-mt-2">
            <button id="search-request-order-prep" class="submit p-mr-2" mat-raised-button color="cor-primary" [disabled]='hasFilterModelChanged'
                (click)='searchButtonClicked()'>Search Requests</button>
            <button id="clear-filters-order-prep" class="submit" mat-raised-button color="cor-primary" [disabled]="disableClearFilters"
                (click)='onClearFilters()'>Clear Filters</button>
        </div>
    </div>
</div>

<div class="spacer"></div>

<div class="flex-grid" *ngIf="gridMode">
    <div class="spacer"></div>
    
    <mat-card class="order-card-container">
    
        <div class="spacer"></div>
    
        <app-ag-grid-custom-paginator (paginatorCommand)="handlePaginatorCommand($event)" [paginatorModel]="paginatorModel"
            [showPageSizes]="true">
        </app-ag-grid-custom-paginator>
    
        <div class="flex-grid">
            <ag-grid-angular id="myGrid" style="width:100%; height:540px;" class="ag-theme-alpine" rowModelType="infinite"
                domLayout="normal" tooltipShowDelay="0" suppressCellSelection="true" alwaysShowVerticalScroll="true"
                [pagination]="true" [suppressPaginationPanel]="true" [paginationPageSize]="25" [cacheOverflowSize]="2"
                [maxConcurrentDatasourceRequests]="2" [infiniteInitialRowCount]="1" [columnDefs]="columnDefs"
                [cacheBlockSize]="100" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                [frameworkComponents]="frameworkComponents" [gridOptions]='gridOptions'
                (gridReady)="onGridReady($event)" (gridSizeChanged)="resizeGrid($event)"
                (sortChanged)="updateCurrentPage()" (cellClicked)="handleCellClicked($event)">
            </ag-grid-angular>
        </div>
    
        <app-ag-grid-custom-paginator (paginatorCommand)="handlePaginatorCommand($event)" [paginatorModel]="paginatorModel"
            [showPageSizes]="true">
        </app-ag-grid-custom-paginator>
    
    </mat-card>
    
</div>


<div class="spacer"></div>

<div class="flex-grid" *ngIf="!gridMode">
    <mat-card class="order-card-container">

        <mat-card-header class="order-card-container">
            <mat-card-title>On Hold</mat-card-title>
        </mat-card-header>

        <div class="spacer"></div>

        <mat-card-content class="messageContainer" *ngIf="onHoldOrdersLoaded && !onHoldOrders.length">
            <div class="spacer"></div>
            <div class="cardListMessage">No matches for selected filter criteria</div>
        </mat-card-content>

        <mat-card-content *ngIf="onHoldOrdersLoaded">
            <app-order-card *ngFor="let order of onHoldOrders" [headerColor]="'yellow'" [order]="order"
                (orderMoved)="handleOrderMoved($event)"></app-order-card>
        </mat-card-content>

    </mat-card>
</div>

<div class="spacer"></div>

<div class="flex-grid" *ngIf="!gridMode">
    <mat-card class="order-card-container">

        <mat-card-header class="order-card-container">
            <mat-card-title>Pending</mat-card-title>
        </mat-card-header>

        <div class="spacer"></div>

        <mat-card-content class="messageContainer" *ngIf="pendingOrdersLoaded && !pendingOrders.length">
            <div class="spacer"></div>
            <div class="cardListMessage">No matches for selected filter criteria. Please check the New Requests page for
                additional requests.</div>
        </mat-card-content>

        <mat-card-content *ngIf="pendingOrdersLoaded">
            <app-order-card *ngFor="let order of pendingOrders" [headerColor]="'yellow'" [order]="order"
                (orderMoved)="handleOrderMoved($event)"></app-order-card>
        </mat-card-content>

    </mat-card>
</div>

<div class="spacer"></div>

<div class="flex-grid" *ngIf="!gridMode">
    <mat-card class="order-card-container">

        <mat-card-header class="order-card-container">
            <mat-card-title>Other</mat-card-title>
        </mat-card-header>

        <div class="spacer"></div>

        <mat-card-content class="messageContainer" *ngIf="onOtherOrdersLoaded && !otherOrders.length">
            <div class="spacer"></div>
            <div class="cardListMessage">No matches for selected filter criteria.</div>
        </mat-card-content>

        <mat-card-content *ngIf="onOtherOrdersLoaded">
            <app-order-card *ngFor="let order of otherOrders" [headerColor]="'yellow'" [order]="order"
                (orderMoved)="handleOrderMoved($event)"></app-order-card>
        </mat-card-content>

    </mat-card>
</div>