import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DecantStation } from 'src/app/models/decant-models';
import { ScalePollingService } from 'src/app/services/scale-polling-service/scale-polling-service';
import { Observable, timer } from 'rxjs';

@Component({
  selector: 'app-scale-poller',
  templateUrl: './scale-poller.component.html',
  styleUrls: ['./scale-poller.component.scss']
})
export class ScalePollerComponent implements OnInit, OnDestroy {

  @Output() scaleUpdate = new EventEmitter();

  public scaleInfo$: Observable<DecantStation>;
  private subscription: any;
  private timerSource: any;

  constructor(private pollingService: ScalePollingService, private changeDetector: ChangeDetectorRef) {
    this.scaleInfo$ = this.pollingService.getDecantStation();
  }

  ngOnInit(): void {
    this.timerSource = timer(1000, 3000);
    this.subscription = this.timerSource.subscribe(() => this.scaleUpdate.emit(this.scaleInfo$));
  }

  emitUpdate() {
    this.changeDetector.detectChanges(); 
    this.scaleUpdate.emit(this.scaleInfo$);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
