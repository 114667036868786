import { finalize, take } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocationTreeModel } from 'src/app/models/locations-tree-model';
import { DecantStation, Warehouse } from 'src/app/models/decant-models';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { MessageService as  MessageServiceQueue} from 'src/app/shared/services/message-queue.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-location-barcode-labels',
  templateUrl: './location-barcode-labels.component.html',
  styleUrls: ['./location-barcode-labels.component.scss']
})
export class LocationBarcodeLabelsComponent implements OnInit {

  selectedWarehouse: Warehouse;
  selectedStation: DecantStation;
  selectedLocation: LocationTreeModel[];

  constructor(
    private readonly dialog: MatDialog,
    private readonly messageService: MessageService,
    private readonly inventoryService: InventoryService,
    private readonly messageServiceQueue: MessageServiceQueue,
  ) { 
    this.selectedLocation = [];
  }

  ngOnInit(): void {
  }

  onClear() {
    this.selectedLocation = [];
    this.messageServiceQueue.message.next({action: 'clear'});
  }

  onLocationSelected($event){
    this.selectedLocation = $event;
  }

  onSelectedStation($event) {
    this.selectedStation = $event;
  }

  onWarehouseSeleted($event) {
    this.onClear();
    this.selectedWarehouse = $event;
  }

  onPrintLabels(){
    var locationIds = this.selectedLocation.filter(l=>l.children.length==0).map(l=>l.key);
    const confirmDialog = new ConfirmDialogModel(
      'Confirm Action',
      `The system will print ${locationIds.length} location labels based on your selections. Are you sure?`,
      'OK',
      false,
      true,
      'Cancel'
    );
    this.dialog
      .open(ConfirmDialogComponent, { maxWidth: '400px', data: confirmDialog })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.inventoryService.printLocationBarcodeLabels(locationIds, this.selectedStation.stationID).pipe(
            take(1),
            finalize(() => {
              this.onClear();
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Your print request has been submitted.',
              });
            })
          ).subscribe();
        }
      });
  }

}
