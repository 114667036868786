import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RowNode } from 'ag-grid-community';

@Component({
  selector: 'actions-cell-renderer',
  templateUrl: './actions-cell-renderer.component.html',
  styleUrls: ['./actions-cell-renderer.component.scss']
})
export class ActionsCellRendererComponent implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;

  oldValue: number;
  nodes: RowNode[] = [];
  isLastRow: boolean = false;
  canDownload: boolean = false;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
    this.oldValue = params.data[params.colDef.field];

    this.gridApi.forEachNode(node => this.nodes.push(node));
    if (this.nodes && this.nodes.length
      && this.nodes[this.nodes.length - 1].rowIndex === this.params.rowIndex)
      this.isLastRow = true;
    else
      this.isLastRow = false;

    if (this.params.data.fileContents)
      this.canDownload = true;
  }

  onDownload() {
    this.gridApi.dispatchEvent({ type: 'downloadEvent', value: this.params });
  }

  refresh(): boolean {
    return false;
  }
}
