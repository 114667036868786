<div class="container">
    <h5>Add Label</h5>
    <hr />
    <span class="message">{{data?.materialName}}</span>
    <div class="add-icon p-mt-2"><i (click)="fileUpload.click()" style="cursor: pointer;"
            class="pi pi-plus-circle p-mr-2"></i><span (click)="fileUpload.click()" style="cursor: pointer;">Add Word
            File (.doc/.docx) </span></div>
    <input hidden #fileUpload type="file"
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        (change)="onFileUpload($event)">
    <p>{{uploadedFile?.name}}</p>
    <div class="content p-mt-3">
        <table class="p-mt-2">
            <tr>
                <td><label for="type" class="p-pr-3">Type</label></td>
                <td>
                    <p-dropdown id="type" placeholder="Select Type" [(ngModel)]="selectedType" [options]="types"
                        optionLabel="name" panelStyleClass='attachment-modal-minWidthOverride'
                        [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
            </tr>
            <tr class="p-mt-2">
                <td><label for="language" class="p-pr-3">Language</label></td>
                <td>
                    <p-dropdown id="language" placeholder="Select Language" [(ngModel)]="selectedLanguage" (onShow)="onLanguageShow()" (onHide)="onLanguageHide()"
                        [options]="languages" optionLabel="name" panelStyleClass='attachment-modal-minWidthOverride'
                        [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
            </tr>
            <tr class="p-mt-2">
                <td><label for="labelSize" class="p-pr-3">Size</label></td>
                <td>
                    <p-dropdown id="labelSize" placeholder="Select Label Size" [(ngModel)]="selectedLabelSize"
                        [options]="labelSizes" optionLabel="name" panelStyleClass='attachment-modal-minWidthOverride'
                        [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
            </tr>
        </table>
    </div>
    <div class="actions p-mt-3">
        <button mat-raised-button class="p-mr-4" color="cor-default" (click)="cancel()">Cancel</button>
        <button mat-raised-button color="cor-primary"
            [disabled]="!(uploadedFile && selectedLanguage && selectedLabelSize && selectedType && isValidExtension)"
            (click)="save()">Save</button>
    </div>
</div>