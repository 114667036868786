import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { OrderFilterData, OrderFilterModel } from 'src/app/models/filter-models';
import { User } from 'src/app/models/security-models';
import { OrderService } from 'src/app/services/order-service/order-service';
import { FeatureService } from '../../services/feature-switch-service/feature.service';
import { FeatureType } from 'src/app/models/feature-type.enum';

@Component({
  selector: 'app-order-filters',
  templateUrl: './order-filters.component.html',
  styleUrls: ['./order-filters.component.scss']
})
export class OrderFiltersComponent implements OnInit, OnDestroy {

  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() defaultCoordinatorSet: EventEmitter<any> = new EventEmitter();
  @Output() autoSearch: EventEmitter<any> = new EventEmitter();

  private currentUrl: string;
  private subscriptions = new Subscription();
  private currentUser: User;
  private localStorageVersion: string = '/v3';

  public filterData: OrderFilterData;
  public filterModel: OrderFilterModel;
  public enabledFilters: any[] = [];
  public filters: any[] = [];
  public chemicalFilterUpdate = new Subject<string>();
  public requestNumberFilterUpdate = new Subject<string>();
  public lotNumberFilterUpdate = new Subject<string>();
  public isSGSavailable: boolean;

  constructor(private orderService: OrderService,public readonly featureSwitchService: FeatureService, private router: Router) {

    this.featureSwitchService.retrieveFeatures().toPromise().then(features =>{
      this.isSGSavailable = features.some((feature)=>feature.featureID==FeatureType.IsSGSAvailable && feature.isActive);
    })


    this.chemicalFilterUpdate.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.emitFilterUpdate('Material Requested');
      });
    this.requestNumberFilterUpdate.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.emitFilterUpdate('Request Number');
      });
    this.lotNumberFilterUpdate.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.emitFilterUpdate('Lot Number');
      });

    this.enabledFilters = [
      { label: 'Warehouse' },
      { label: 'Country' },
      { label: 'Tags' },
      { label: 'Requestor Name' },
      { label: 'Request Type' },
      { label: 'Ship By Date Range' },
      { label: 'Updated Date Range' },
      { label: 'Material Requested' },
      { label: 'Region' },
      { label: 'Request ID' },
      { label: 'Lot Number' },
      { label: 'Reserved' },
      { label: 'Status' },
      { label: 'Hold Reasons' },
      { label: 'Coordinator'},
      { label: 'Tracking Number'}
    ];

    this.filters = [
      { label: 'Warehouse' },
      { label: 'Material Requested' },
      { label: 'Country' },
      { label: 'Request Type' },
      { label: 'Requestor Name' },
      { label: 'Ship By Date Range' },
      { label: 'Tags' },
      { label: 'Updated Date Range' },
      { label: 'Region' },
      { label: 'Request ID' },
      { label: 'Lot Number' },
      { label: 'Reserved' },
      { label: 'Status' },
      { label: 'Hold Reasons' },
      { label: 'Coordinator'},
      { label: 'Tracking Number'}
    ];

    this.filterModel = {
      orderStatusID: 0,
      countries: [],
      requestType: { itemID: 0, itemDesc: '', itemIDText: '' },
      requestors: [],
      tags: [],
      shipByDateRange: '',
      updatedDateRange: '',
      chemical: '',
      orderStatuses: [],
      coordinators: [],
      regions: [],
      requestNumber: '',
      lotNumber: '',
      reserved: { itemID: 0, itemDesc: '', itemIDText: '' },
      holdReasons:[],
      trackingNumber:'',
      warehouses: []
    };
    this.subscriptions.add(
      this.orderService.getOrderFilters().subscribe(
        data => {
          this.filterData = data;
          this.addReservedFilterdata();
          this.sortHoldReasons();
          if (this.filterData && this.filterData.requestors.length) {
            let uniqueRequestors = [];
            this.filterData.requestors.forEach((item) => {
              if (!uniqueRequestors.some(i => i.itemDesc == item.itemDesc))
                uniqueRequestors.push(item);
            });
            this.filterData.requestors = uniqueRequestors;
          }
          if (this.filterData && this.filterData.orderStatuses.length){
            
            this.filterData.orderStatuses = this.filterData.orderStatuses.filter(
              s => s.itemDesc?.toLowerCase() === 'new'
                || s.itemDesc?.toLowerCase() === 'pending'
                || s.itemDesc?.toLowerCase() === 'pick'
                || s.itemDesc?.toLowerCase() === 'decant'
                || s.itemDesc?.toLowerCase() === 'shipping'
                || s.itemDesc?.toLowerCase() === 'complete'
                || (s.itemDesc?.toLowerCase() === 'sgs requested' && this.isSGSavailable)
                || (s.itemDesc?.toLowerCase() === 'sgs complete' && this.isSGSavailable)
                || s.itemDesc?.toLowerCase() === 'cancelled'
              );

            if (this.router.url === '/requests/processed') {
              this.filterModel.orderStatuses = this.filterData.orderStatuses.filter(
                s => s.itemDesc?.toLowerCase() == "pick"
                || s.itemDesc?.toLowerCase() === 'decant'
                || s.itemDesc?.toLowerCase() === 'shipping'
                || (s.itemDesc?.toLowerCase() === 'sgs requested')
              )
            }
            else if (this.router.url === '/requests/complete' || this.router.url === '/requests/prep') {
             
              if (this.router.url === '/requests/complete') {
                this.filterModel.orderStatuses = this.filterData.orderStatuses.filter(
                  s => s.itemDesc?.toLowerCase() == "complete"
                    || s.itemDesc?.toLowerCase() == "sgs complete"
                    || s.itemDesc?.toLowerCase() == "cancelled");
              }

              if (this.router.url === '/requests/prep') {
                this.filterModel.orderStatuses = this.filterData.orderStatuses.filter(
                  s => s.itemDesc?.toLowerCase() == "pending"
                    || s.itemDesc?.toLowerCase() == "pick"
                    || s.itemDesc?.toLowerCase() == "decant"
                    || (s.itemDesc?.toLowerCase() === 'sgs requested')
                    || s.itemDesc?.toLowerCase() == "shipping");
              }
            }
            if (this.router.url === '/requests/new') {        
                  this.filterModel.orderStatuses = this.filterData.orderStatuses.filter(s => s.itemDesc?.toLowerCase() == "new");
            }

            this.loadDefaultWarehouse();
          }
          if (this.router.url === '/requests/prep') {
            if (sessionStorage.getItem(this.router.url + this.localStorageVersion)) {
              //this.filterModel = JSON.parse(sessionStorage.getItem(this.router.url + this.localStorageVersion)).filterModel;
              //this.autoSearch.emit();
            }
          }
          this.setDefaultCoordinator();
          this.emitFilterUpdate('Get Order filters loaded');
          this.resetNonSticky();
        }
      )
    );
  }

  addReservedFilterdata() {
    this.filterData.reserved = [{ itemID: 1, itemDesc: 'Yes', itemIDText: 'Yes' }, { itemID: 2, itemDesc: 'No', itemIDText: 'No' }]
  }

  sortHoldReasons() {
    this.filterData.holdReasons.sort((a, b) => a.itemDesc > b.itemDesc ? 1 : -1);
  }

  isFilterEnabled(label: string) {
    return this.enabledFilters.some(filter => filter.label === label);
  }

  handleDateRangeSelection(filter: string) {
    if (filter == 'Ship By Date Range') {
      if (this.filterModel.shipByDateRange[0] !== null && this.filterModel.shipByDateRange[1] !== null) {
        this.emitFilterUpdate('Ship By Date Range');
      }
    }
    if (filter == 'Updated Date Range') {
      if (this.filterModel.updatedDateRange[0] !== null && this.filterModel.updatedDateRange[1] !== null) {
        this.emitFilterUpdate('Updated Date Range');
      }
    }
  }

  onDateClose(event) {
    if (!this.filterModel.shipByDateRange[0] || !this.filterModel.shipByDateRange[1])
      this.filterModel.shipByDateRange = '';

    if (!this.filterModel.updatedDateRange[0] || !this.filterModel.updatedDateRange[1])
      this.filterModel.updatedDateRange = '';
  }

  emitFilterUpdate(param): void {
    this.modelChange.emit({
      filterModel: this.filterModel,
      reason: param
    })
  }

  resetFilter(filterName: string) {
    if (filterName == 'Country') {
      this.filterModel.countries = [];
    }
    if (filterName == 'Requestor Name') {
      this.filterModel.requestors = [];
    }
    if (filterName == 'Request Type') {
      this.filterModel.requestType = { itemID: 0, itemDesc: '', itemIDText: '' };
    }
    if (filterName == 'Tags') {
      this.filterModel.tags = [];
    }
    if (filterName == 'Ship By Date Range') {
      this.filterModel.shipByDateRange = '';
    }
    if (filterName == 'Updated Date Range') {
      this.filterModel.updatedDateRange = '';
    }
    if (filterName == 'Material Requested') {
      this.filterModel.chemical = '';
    }
    if (filterName == 'Coordinator') {
      this.filterModel.coordinators = [];
    }
    if (filterName == 'Region') {
      this.filterModel.regions = [];
    }
    if (filterName == 'Status') {
      this.filterModel.orderStatuses = [];
    }
    if (filterName == 'Request Number') {
      this.filterModel.requestNumber = ''
    }
    if (filterName == 'Lot Number') {
      this.filterModel.lotNumber = ''
    }
    if (filterName == 'Reserved') {
      this.filterModel.reserved = { itemID: 0, itemDesc: '', itemIDText: '' };
    }
    if (filterName == 'Hold Reasons') {
      this.filterModel.holdReasons = [];
    }
    if (filterName == 'Tracking Number') {
      this.filterModel.trackingNumber = '';
    }
    if (filterName == 'Warehouse') {
      this.filterModel.warehouses = [];
    }
    this.emitFilterUpdate(filterName);
  }

  resetNonSticky(): void {
    const localStorageKey = this.router.url + this.localStorageVersion;
    if (sessionStorage.getItem(localStorageKey)) {
      this.filterModel = this.getFormattedFilterModel(JSON.parse(sessionStorage.getItem(localStorageKey)).filterModel);
      this.enabledFilters = this.getFormattedFilterModel(JSON.parse(sessionStorage.getItem(localStorageKey)).enabledFilters);

      //Section to reset all the non sticky fields
      this.filterModel.trackingNumber = '';
      this.loadDefaultWarehouse();
      //End of section 

      sessionStorage.removeItem(localStorageKey);
      sessionStorage.setItem(localStorageKey, JSON.stringify({
        filterModel: this.filterModel,
        enabledFilters: this.enabledFilters
      }));
    }
  }

  private loadDefaultWarehouse() {
    const warehouseDefaultId = JSON.parse(localStorage.getItem('defaultWarehouseID'));
    if (warehouseDefaultId) {
      this.filterModel.warehouses = this.filterData.warehouses.filter(w => w.itemID == warehouseDefaultId);
    }
    else {
      this.filterModel.warehouses = [];
    }
  }

  clearAllFilters() {
    this.filterModel.countries = [];
    this.filterModel.requestors = [];
    this.filterModel.requestType = { itemID: 0, itemDesc: '', itemIDText: '' };
    this.filterModel.tags = [];
    this.filterModel.shipByDateRange = '';
    this.filterModel.updatedDateRange = '';
    this.filterModel.chemical = '';
    this.filterModel.coordinators = [];
    this.filterModel.regions = [];
    this.filterModel.orderStatuses = [];
    this.filterModel.requestNumber = '';
    this.filterModel.lotNumber = '';
    this.filterModel.reserved = { itemID: 0, itemDesc: '', itemIDText: '' };
    this.filterModel.holdReasons = [];
    this.filterModel.trackingNumber = '';
    this.filterModel.warehouses = [];
  }

  disableRemovedFilters(event): void {
    const enabledFilters = event;
    if (!enabledFilters.some(filter => filter.label === 'Country')) {
      this.filterModel.countries = [];
    }
    if (!enabledFilters.some(filter => filter.label === 'Requestor Name')) {
      this.filterModel.requestors = [];
    }
    if (!enabledFilters.some(filter => filter.label === 'Request Type')) {
      this.filterModel.requestType = { itemID: 0, itemDesc: '', itemIDText: '' };
    }
    if (!enabledFilters.some(filter => filter.label === 'Tags')) {
      this.filterModel.tags = [];
    }
    if (!enabledFilters.some(filter => filter.label === 'Ship By Date Range')) {
      this.filterModel.shipByDateRange = '';
    }
    if (!enabledFilters.some(filter => filter.label === 'Updated Date Range')) {
      this.filterModel.updatedDateRange = '';
    }
    if (!enabledFilters.some(filter => filter.label === 'Material Requested')) {
      this.filterModel.chemical = '';
    }
    if (!enabledFilters.some(filter => filter.label === 'Request ID')) {
      this.filterModel.requestNumber = '';
    }
    if (!enabledFilters.some(filter => filter.label === 'Lot Number')) {
      this.filterModel.lotNumber = '';
    }
    if (!enabledFilters.some(filter => filter.label === 'Status')) {
      this.filterModel.orderStatuses = [];
    }
    if (!enabledFilters.some(filter => filter.label === 'Coordinator')) {
      this.filterModel.coordinators = [];
    }
    if (!enabledFilters.some(filter => filter.label === 'Region')) {
      this.filterModel.regions = [];
    }
    if (!enabledFilters.some(filter => filter.label === 'Reserved')) {
      this.filterModel.reserved = { itemID: 0, itemDesc: '', itemIDText: '' };
    }
    if (!enabledFilters.some(filter => filter.label === 'Hold Reasons')) {
      this.filterModel.holdReasons = [];
    }
    if (!enabledFilters.some(filter => filter.label === 'Tracking Number')) {
      this.filterModel.trackingNumber = '';
    }

    this.emitFilterUpdate('Removed Filter');
  }

  ngOnInit(): void {

    this.bindFilterDetails();
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) this.currentUrl = this.router.url;
    })

  }

  bindFilterDetails() {


    if (!this.router.url) return;
    this.filters.sort((a, b) => a.label < b.label ? -1 : 1);
    this.enabledFilters.sort((a, b) => a.label < b.label ? -1 : 1);
    if (!sessionStorage.getItem(this.router.url + this.localStorageVersion)) return;
    this.filterModel = this.getFormattedFilterModel(JSON.parse(sessionStorage.getItem(this.router.url + this.localStorageVersion)).filterModel);
    this.enabledFilters = JSON.parse(sessionStorage.getItem(this.router.url + this.localStorageVersion)).enabledFilters;
    if ((this.router.url && this.router.url === '/requests/new' || this.router.url == '/requests/complete')
      && this.enabledFilters.filter(x => x.label === 'Status').length === 0) {
      this.enabledFilters.push({ label: 'Status' });
    }

    if (this.router.url && (this.router.url == '/requests/processed' || this.router.url == '/requests/prep' || this.router.url === '/requests/complete') && this.enabledFilters.filter(x => x.label === 'Status').length === 0) {
      this.enabledFilters.push({ label: 'Status' });
    }

    if (this.router.url && (this.router.url == '/requests/prep') && this.enabledFilters.filter(x => x.label === 'Coordinator').length === 0) {
      this.enabledFilters.push({ label: 'Coordinator' });
    }

    this.disableRemovedFilters(this.enabledFilters);
  }

  getFormattedFilterModel(filterModel) {
    if (filterModel.shipByDateRange) {
      filterModel.shipByDateRange[0] = new Date(filterModel.shipByDateRange[0]);
      filterModel.shipByDateRange[1] = new Date(filterModel.shipByDateRange[1]);
    }
    if (filterModel.updatedDateRange) {
      filterModel.updatedDateRange[0] = new Date(filterModel.updatedDateRange[0]);
      filterModel.updatedDateRange[1] = new Date(filterModel.updatedDateRange[1]);
    }
    return filterModel;
  }

  saveFilterDetails(hardSave: boolean = false) {
    if (!hardSave && (!this.router.url || (this.router.url && this.router.url == '/requests/new'))) return;
    sessionStorage.setItem(this.router.url + this.localStorageVersion, JSON.stringify({
      filterModel: this.filterModel,
      enabledFilters: this.enabledFilters
    }));
  }

  private setDefaultCoordinator() {
    if (!this.filterData || !this.currentUser || this.router.url !== '/requests/prep') { return; }
    this.filterModel.coordinators = [this.filterData.coordinators.find(c => c.itemID === this.currentUser.userID)];
    this.emitFilterUpdate('Default Coordinator');
    this.defaultCoordinatorSet.emit();
  }

  setCurrentUser(user: User) {
    this.currentUser = user;
    this.setDefaultCoordinator();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
