
<div class="col">
    <h4>Print Location Barcode Labels</h4>
</div>
<div class="container">
    <div class="stations">
        <app-warehouse-station (onWarehouseSeleted)="onWarehouseSeleted($event)" (selectedStation)="onSelectedStation($event)"></app-warehouse-station>
        <div class="actions">
            <button class="submit p-mr-2" mat-raised-button color="cor-default" (click)="onClear()">Clear Selection</button>
            <button class="submit p-mr-2" mat-raised-button color="cor-primary" (click)="onPrintLabels()" [disabled]="selectedStation==null || selectedLocation?.length == 0">Print Labels</button>
        </div>
    </div>
    <div class="tree">
        <app-locations-tree-view [selectedWarehouse]="selectedWarehouse" (onLocationSelected)="onLocationSelected($event)"></app-locations-tree-view>
    </div>
</div>