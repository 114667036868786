<div class="container">
    <h5>{{!isEditing ? 'Add Cancellation Reason' : 'Edit Cancellation Reason' }}</h5>
    <div class="content">
        <form [formGroup]="cancellationReasonForm">
            <div class="p-field p-fluid p-mb-2 p-mt-">
                <label class="p-text-bold" for="cancellationReason" id="cancellationReason">Name</label>
                <input formControlName="cancellationReason" pInputText type="text" />
                <div *ngIf="fc.cancellationReason.errors?.maxlength"
                    [ngClass]="{'p-error': fc.cancellationReason.invalid}">
                    <small> Max length is 255 characters. </small>
                </div>
            </div>
            <div [ngClass]="{'p-error': fc.cancellationReason.invalid}" *ngIf="fc.cancellationReason.touched">
                <small *ngIf="fc.cancellationReason.errors?.required">
                    Field is required.
                </small>
            </div>
            <div class="p-field p-fluid p-mb-2 p-mt-">
                <div style="font-weight: bold; margin-bottom: 10px;" for="message" id="message">Message Text: <span><i [matTooltip]="messageTexttooltiptext()" class="pi pi-exclamation-circle" style="cursor:pointer;"></i></span></div>
                <textarea rows="5" cols="30" formControlName="message" pInputTextarea type="text"></textarea>
            </div>
            <div [ngClass]="{'p-error': fc.message.invalid}" *ngIf="fc.message.touched">
                <small *ngIf="fc.message.errors?.required">
                    Field is required.
                </small>
            </div>
        </form>
    </div>
    <div class="p-text-right p-mt-4">
        <button mat-raised-button color="cor-default" (click)="cancel()" class="p-mr-2">Cancel</button>
        <button mat-raised-button color="cor-primary"
            [disabled]="cancellationReasonForm.invalid || ((isEditing && data.name == fc.cancellationReason.value) && (isEditing && data.messageText == fc.message.value))"
            (click)="saveOrUpdate()">{{isEditing ? 'Update' : 'Save'}}</button>
    </div>
</div>

<style>
    ::ng-deep .mat-tooltip {
  white-space: pre-line;
}
</style>