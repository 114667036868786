<div class="tags-container">
    <div class="tag-control">
        <mat-form-field class="tag-chip-list">
            <mat-chip-list #chipList>
                <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(tag)" [disabled]='disableTags'>
                    {{tag.tagName}}
                    <span matChipRemove class="discard" *ngIf="removable"><i class="pi pi-times"></i></span>
                </mat-chip>
                <input #tagInput [formControl]="tagCtrl" maxlength="40" [matAutocomplete]="auto"
                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addTag($event)" [disabled]='disableTags' />
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                    {{ tag.tagName }}
                </mat-option>
                <mat-option *ngIf="showNewTag && tagInput.value != ''"> {{tagInput.value}} (New Tag)</mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div class="tag-save" *ngIf='!disableTags'>
        <button mat-raised-button class="ml-10" [disabled]='isDisabled' (click)='onSaveTags()' color="cor-primary">Save
            Tags</button>
    </div>
</div>