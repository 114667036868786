import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DecantStation } from 'src/app/models/decant-models';
import { InventoryItem } from 'src/app/models/inventory-models';
import { OrderItemContainerModel } from 'src/app/models/order-item-container-model';
import { SignalREventType } from 'src/app/receiving/models/signalR.model';
import { UpdateWeightRequest } from 'src/app/receiving/models/weighing.model';
import { SignalRScaleServiceSubscription } from 'src/app/receiving/services/signalr-subscription.service';
import { HUB_SUBS_SERVICE_TOKEN, SignalRService } from 'src/app/receiving/services/signalr.service';
import { StationService } from './../../services/station/station.service';

@Component({
  selector: 'app-scale-test2',
  templateUrl: './scale-test2.component.html',
  styleUrls: ['./scale-test2.component.scss'],
  providers: [
    {
      provide: HUB_SUBS_SERVICE_TOKEN, useClass: SignalRScaleServiceSubscription
    },
    SignalRService
  ]
})
export class ScaleTest2Component implements OnInit, OnDestroy {

  station: DecantStation;
  scalesReady: boolean = true;

  selectedInventoryItem: InventoryItem;
  selectedContainer: OrderItemContainerModel = {
    actualAmount: 1,
    amount: 1,
    orderItemContainerID: "2",
    orderItemID: "",
    containerNumber: 2,
    containerLabel: "",
    containerCode: "",
    requestedAmount: 1,
    actualInventoryCatalogID: 94,
    actualLotNumber: "",
    actualExpirationDate: "",
    shipped: "",
    noDecantInventoryItemID: 0
  };

  signalRServiceSubscription: Subscription;
  subscriptions = new Subscription();

  constructor(
    private signalRService: SignalRService,
    private stationService: StationService,
    private ngZone: NgZone) { }

  ngOnInit(): void {
    this.signalRServiceSubscription = this.signalRService.events.subscribe(event => {
      if (event.type == SignalREventType.UpdateWeight) {
        this.ngZone.run(() => {
          const updateWeightRequest: UpdateWeightRequest = event.data;
          console.log("Reading from scale for scale is ", updateWeightRequest.scaleId, " with weight in KG is ", (+updateWeightRequest.weightKG).toFixed(7));
          const updatedScale = this.station.scales.find(scale => scale.scaleID === updateWeightRequest.scaleId);
          updatedScale.weightKG = updateWeightRequest.weightKG;
        });
      } else {
        console.log(`Event from signalRService: event.type:${event.type}, event.data: ${event.data}`);
      }
    });
    this.signalRService.startConnection();
  }

  ngOnDestroy(): void {
    this.signalRService.stopConnection();
    if (this.signalRServiceSubscription) { this.signalRServiceSubscription.unsubscribe(); }
    this.subscriptions.unsubscribe();
  }

  onStationChange(station: DecantStation) {
    console.log('Station Equals: ' + (this.station === station))
    this.station = station;
    console.log(this.station);
  }

  getStation() {
    this.stationService.retrieveStation(this.station.stationID).pipe(take(1)).subscribe(station => {
      console.log('Station Equals: ' + (this.station === station))
      this.station = station;
      console.log(this.station);
    });
  }

}
