import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base-component';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { DecantStation } from 'src/app/models/decant-models';
import { InventoryItem } from 'src/app/models/inventory-models';
import { OrderItemContainerModel } from 'src/app/models/order-item-container-model';
import { OrderItemLotResponse } from 'src/app/models/order-item-model';
import { Order, RequestTypes } from 'src/app/models/order-models';
import { SignalREventType } from 'src/app/receiving/models/signalR.model';
import { UpdateWeightRequest } from 'src/app/receiving/models/weighing.model';
import { SignalRScaleServiceSubscription } from 'src/app/receiving/services/signalr-subscription.service';
import { HUB_SUBS_SERVICE_TOKEN, SignalRService } from 'src/app/receiving/services/signalr.service';
import { DecantService } from 'src/app/services/decant-service/decant-service';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { StationService } from 'src/app/services/station/station.service';
import { PillRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/pill.render.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { HoldModalComponent } from './../hold-modal/hold-modal.component';

@Component({
  selector: 'app-decant-detail',
  templateUrl: './decant-detail.component.html',
  styleUrls: ['./decant-detail.component.scss'],
  providers: [
    {
      provide: HUB_SUBS_SERVICE_TOKEN, useClass: SignalRScaleServiceSubscription
    },
    SignalRService,
    WeightFormatKGPipe
  ]
})
export class DecantDetailComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("barcodeField") barcodeField: ElementRef;

  signalRServiceSubscription: Subscription;
  private subscriptions = new Subscription();
  public selectedStation: DecantStation;
  public decantOrderLoaded: boolean;
  public columnDefs: any;
  public rowData: any;
  public order: Order;
  public decantStep: number;
  public containerId: number;
  public selectedInventoryItem: InventoryItem;
  public selectedContainer: OrderItemContainerModel;
  public containersFilled: boolean;
  public barcodeInput: string;
  private focusBarcode = true;
  public isGLPRequest: boolean;
  frameworkComponents: any;
  rowHeight = 50;
  navigatedFrom: any;
  assignedLotNumber: string = '';
  totalNumberOfContainers = 0;

  constructor(private orderService: OrderService,
    public router: Router,
    private route: ActivatedRoute,
    private responseHandler: ResponseHandlingService,
    private inventoryService: InventoryService,
    private decantService: DecantService,
    public dialog: MatDialog,
    private weightFormatKGPipe: WeightFormatKGPipe,
    private loader: LoadingService,
    private signalRService: SignalRService,
    private stationService: StationService,
    private ngZone: NgZone) {

    super();
    this.rowData = [];

    // Decant Steps
    // 0 : Decant Request Details
    // 1 : Decant Container Details
    // 2 : Decant Capture Remaining Source
    this.decantStep = 0;

    this.selectedStation = JSON.parse(localStorage.getItem("station"));
    if (!this.selectedStation) {
      responseHandler.showError("No Decant Station selected. Redirecting to Decant overview.");
      setTimeout(() => router.navigateByUrl('decant'), 2000);
    }

    this.fetchData();

    this.frameworkComponents = {
      pillRenderer: PillRenderer
    };

    this.columnDefs = [
      {
        headerName: 'Complete',
        field: 'complete',
        width: 120,
        cellRenderer: params => {
          return `${params.value ? '<i class="pi pi-check green-check"></i>' : ''}`;
        }
      },
      {
        headerName: 'Chemical',
        field: 'containerLabelFmt',
        tooltipValueGetter: (params) => params.data.containerLabelFmt
      },
      { headerName: 'Lot Number(s)', field: 'assignedLotNumber' },
      { headerName: 'Number of Containers', field: 'numberOfContainers' },
      {
        headerName: 'Amount Per Container', field: 'amountPerContainer', flex: 1, width: 150,
        valueGetter: (params) => {
          if (params.data) {
            return this.weightFormatKGPipe.transform(params.data.amountPerContainer) + ' ' + params.data.unitOfMeasureDesc;
          }
        },
      },
      {
        headerName: 'Total Amount Requested',
        field: 'amountRequested',
        flex: 1,
        valueGetter: (params) => {
          if (params.data) {
            return this.weightFormatKGPipe.transform(params.data.numberOfContainers * params.data.amountPerContainer) + ' ' + params.data.unitOfMeasureDesc;
          }
        },
        width: 150,
      },
      {
        headerName: 'Special Instructions',
        cellRenderer: 'pillRenderer',
      }
    ];
  }

  ngOnInit(): void {
    this.decantService.getNavigationFrom$.subscribe((param) => {
      this.navigatedFrom = param;
    });

    this.signalRServiceSubscription = this.signalRService.events.subscribe(event => {
      if (event.type == SignalREventType.UpdateWeight) {
        const updateWeightRequest: UpdateWeightRequest = event.data;
        const updatedScale = this.selectedStation.scales.find(scale => scale.scaleID === updateWeightRequest.scaleId);
        if (updatedScale !== undefined && updatedScale !== null) {
          console.log("Reading from scale for scale is ", updateWeightRequest.scaleId, " with weight in KG is ", (+updateWeightRequest.weightKG).toFixed(7));
          this.ngZone.run(() => {
            updatedScale.weightKG = updateWeightRequest.weightKG;
          });
        }
      } else {
        console.log(`Event from signalRService: event.type:${event.type}, event.data: ${event.data}`);
      }
    });
    this.signalRService.startConnection();
  }

  ngAfterViewInit(): void {
    document.getElementById('barcodeField').focus();
  }

  ngOnDestroy(): void {
    this.signalRService.stopConnection();
    if (this.signalRServiceSubscription) { this.signalRServiceSubscription.unsubscribe(); }
    this.subscriptions.unsubscribe();
  }

  getStation() {
    this.stationService.retrieveStation(this.selectedStation.stationID).pipe(take(1)).subscribe(station => {
      this.selectedStation = station;
    });
  }

  fetchData() {
    this.loader.show();
    this.decantOrderLoaded = false;
    this.rowData = []
    let orderID = this.route.snapshot.paramMap.get('id');

    this.subscriptions.add(this.orderService
      .getOrderDetail(orderID).subscribe(
        data => {
          this.order = data;
          if (document.referrer == '') {
            this.validateRequestIdCheck(orderID, data);
          }
          this.isGLPRequest = this.order.requestTypeID === RequestTypes.GLP;
          this.containersFilled = this.isOrderDecanted(data);
          this.createRowData(data);
          this.decantOrderLoaded = true;
        },
        () => { this.loader.clearMessage() },
        () => { this.loader.clearMessage() }
      ));
  }

  createRowData(order: Order): void {
    if (order && order.items.length) {
      this.loader.show();

      let containers = [];
      let calls = [];
      order.items.forEach(item => {
        calls.push(this.orderService.getOrderItemContainerAssignments(item.orderItemID))
      })

      forkJoin(calls).subscribe(results => {
        results.forEach((result, index) => {
          (result as any[]).forEach(el => {
            el['containerLabelFmt'] = order.items[index].containerLabelFmt;
            el['extraPPERequired'] = order.items[index].extraPPERequired;
            el['stockLots'] = order.items[index].stockLots;
            el['glpLots'] = order.items[index].glpLots;
          });
          containers = containers.concat(result);
        });

        let _rowData = [];
        for (const row of containers) {
          let lotLocationObj: OrderItemLotResponse;
          if (order.requestTypeID === RequestTypes.Stock) {
            lotLocationObj = row.stockLots?.find(l => l.lotNumber === row.assignedLotNumber);
          } else if (order.requestTypeID === RequestTypes.GLP) {
            lotLocationObj = row.glpLots?.find(l => l.lotNumber === row.assignedLotNumber);
          }
          const isCold = lotLocationObj?.locations?.some(l => l.toLowerCase().includes('refrigerated') || l.toLowerCase().includes('freezer'));
          const isDesiccant = lotLocationObj?.locations?.some(l => l.toLowerCase().includes('desiccant'));

          _rowData.push({
            containerLabelFmt: row.containerLabelFmt,
            assignedLotNumber: row.assignedLotNumber,
            isNoDecantContainer: row?.isNoDecantContainer,
            numberOfContainers: row.numberOfContainers,
            amountPerContainer: row.requestedAmount,
            assignedInventoryCatalogID: row.assignedInventoryCatalogID,
            unitOfMeasureDesc: row.unitOfMeasureDesc,
            complete: !!row.isDecantedInt,
            completeInt: row.isDecantedInt,
            extraPPERequired: row.extraPPERequired,
            isDesiccant,
            isCold,
            isNoDecantContainerInt: row.isNoDecantContainerInt
          });

          this.totalNumberOfContainers += row.numberOfContainers;
          this.assignedLotNumber = row.assignedLotNumber;
        }

        let sortedRowData = _rowData.sort((a, b) =>
          a.completeInt - b.completeInt ||
          a.containerLabelFmt?.localeCompare(b.containerLabelFmt) ||
          a.assignedLotNumber?.localeCompare(b.assignedLotNumber) ||
          a.amountPerContainer - b.amountPerContainer ||
          a.isNoDecantContainerInt - b.isNoDecantContainerInt
        );
        this.rowHeight = 50;

        if (sortedRowData.some(r => r.isDesiccant))
          this.rowHeight += 25;
        if (sortedRowData.some(r => r.extraPPERequired))
          this.rowHeight += 25;
        if (sortedRowData.some(r => r.isCold))
          this.rowHeight += 25;
        if (sortedRowData.some(r => r.isNoDecantContainer))
          this.rowHeight += 25;

        this.rowData = sortedRowData;
      },
        () => { this.loader.clearMessage() },
        () => { this.loader.clearMessage() })
    }
    else {
      this.responseHandler.showError("Failed to load request information. Redirecting to Decant overview.");
      setTimeout(() => this.router.navigateByUrl('decant'), 2000);
    }
  }

  onBarcodeInput(event) {
    // We need the timeout to allow other click events occur before the blur event
    // We don't want to refocus the barcode field if the user is trying to to put the order on hold or edit hold notes
    setTimeout(() => {
      const input = event.target.value;
      if (input != null && input.length > 0 && this.order.items && this.order.items.length > 0) {
        // First check is for a valid user input
        if (isNaN(input)) {
          this.responseHandler.showError("Inventory Item ID Invalid");
          this.barcodeInput = "";
          document.getElementById('barcodeField').focus();
        } else {
          this.inventoryService.getInventoryItem(input).subscribe((inventoryItem: InventoryItem) => {
            // Second - we ensure the location matches the request type
            if (this.order.requestTypeID === RequestTypes.GLP && !inventoryItem.fullLocationName.toLowerCase().includes('glp')
              || (this.order.requestTypeID === RequestTypes.Stock &&
                inventoryItem.fullLocationName.toLowerCase().includes('glp'))) {
              const lotType = this.order.requestTypeDesc;
              this.responseHandler.showError(`Need ${lotType} lot for request`);
              this.barcodeInput = '';
              document.getElementById('barcodeField').focus();
            } else {
              const itemsWithMatchingLots_RangeContainers = this.order.items.some(i =>
                i.containers.some(c => ((c.assignedLotNumber === inventoryItem.lotNumber)
                  && !c.decanted && !c.noDecantInventoryItemID && c.assignedInventoryCatalogID === inventoryItem.catalogRecordID)
                ));

              const itemsWithMatchingLots_SpecificContainers = this.order.items.some(i =>
                i.containers.some(c => ((c.assignedLotNumber === inventoryItem.lotNumber)
                  && !c.decanted && c.noDecantInventoryItemID === inventoryItem.itemID)
                ));

              if (itemsWithMatchingLots_RangeContainers || itemsWithMatchingLots_SpecificContainers) {
                this.containerId = Number.parseInt(input);
                this.decantStep = 1;
                this.barcodeInput = '';
              }
              else if (this.order.items.some(i => i.containers.some(c =>
                (c.decanted && !c.noDecantInventoryItemID
                  && c.assignedInventoryCatalogID === inventoryItem.catalogRecordID
                  && c.assignedLotNumber === inventoryItem.lotNumber)
                || (c.decanted && c.noDecantInventoryItemID == inventoryItem.itemID)
              ))) {
                this.responseHandler.showError('All child containers are filled for this parent.')
                this.barcodeInput = '';
                document.getElementById('barcodeField').focus();
              }
              else {
                this.responseHandler.showError('Parent container not needed for this request.')
                this.barcodeInput = '';
                document.getElementById('barcodeField').focus();
              }
            }
          }, error => {
            this.barcodeInput = '';
            document.getElementById('barcodeField').focus();
          });
        }
      }
    }, 100);

  }

  verifyGuid(input: string): boolean {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(input);
  }

  printTestSubstanceDistributionCertificate(): void {
    this.orderService.getSubstanceDistribution(this.order.orderID).subscribe(response => {
      if (response) {
        this.download(response, `TestSubstanceDistributionCertificate_${this.order.requestNumber}.docx`);
      }
    })
  }

  download(base64String, fileName): void {
    const source = `data:application/msword;base64,${base64String}`;
    var link = document.createElement('a');
    link.href = source;
    link.download = fileName;
    link.click();
  }

  confirmMoveToShipping(): void {
    const dialogData =
      new ConfirmDialogModel("Confirm Move",
        `Are you sure you want to move request ${this.order.requestNumber} to Shipping?`,
        "Move", false, true, "Cancel");
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      height: "160px",
      disableClose: true,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.advanceOrderToShipping();
      }
    });
  }

  advanceOrderToShipping(): void {
    this.subscriptions.add(
      this.orderService
        .updateOrderStatus(this.order.orderID, 7).subscribe(
          data => {
            this.responseHandler.showSuccess(`Successfully moved request ${this.order.requestNumber} to Shipping.`);
            setTimeout(() => this.router.navigateByUrl('decant'), 2000);
          }
        )
    );
  }

  isOrderDecanted(order: Order): boolean {
    let result = true;
    order.items.forEach(item => {
      item.containers.forEach(container => {
        if (!container.decanted) {
          result = false;
        }
      });
    });
    return result;
  }

  onCaptureDecanting(event: { selectedInventoryItem: InventoryItem; selectedContainer: OrderItemContainerModel; }) {
    this.selectedInventoryItem = event.selectedInventoryItem;
    this.selectedContainer = event.selectedContainer;
    this.decantStep = 2;
  }

  onCaptureComplete() {
    this.fetchData();
    this.decantStep = 0;
    setTimeout(() => {
      document.getElementById('barcodeField').focus();
    }, 300);
  }

  placeHold(): void {
    this.focusBarcode = false;
    this.dialog.open(HoldModalComponent, { width: "500px", height: "412px", data: { orderID: this.order.orderID, holdNotes: this.order.holdNotes, isOnHold: this.order?.isOnHold, isplaceOnHold: true, isp2p: false } }).afterClosed().subscribe((res: any) => {
      if (res) {
        this.order.isOnHold = res.isOnHold;
        this.order.holdNotes = res.holdNotes;
        this.order.holdReasons = res.holdReasons;
      }
    });
  }

  RemoveHold(): void {
    this.focusBarcode = false;
    this.dialog.open(HoldModalComponent, { width: "300px", height: "210px", data: { orderID: this.order.orderID, holdNotes: this.order.holdNotes, isOnHold: this.order?.isOnHold, isplaceOnHold: false, isp2p: false } }).afterClosed().subscribe((res: any) => {
      if (res) {
        this.order.isOnHold = false;
      }
    });
  }

  saveHoldNotes(holdNotes: string): void {
    this.focusBarcode = true;
    document.getElementById('barcodeField').focus();
    this.orderService.updateHoldNotes(this.order.orderID, { holdNotes }).subscribe(res => {
      this.order.holdNotes = holdNotes;
      this.responseHandler.showSuccess(`Successfully saved hold notes for the request ${this.order.requestNumber}.`);
    })
  }

  onEditNotesClick() {
    this.focusBarcode = false;
  }

  onCancelNotesEdit() {
    this.focusBarcode = true;
    document.getElementById('barcodeField').focus();
  }

  //Validate Decent Request Component here:
  isGlpRequestScanned(orderID: string, order: Order) {
    var orderMatch = ((order.orderID === orderID) && (this.order.requestTypeID === Number(RequestTypes.GLP)));
    return orderMatch;
  }

  isStockRequestScanned(orderID: string, order: Order) {
    var orderMatch = ((order.orderID === orderID) && (this.order.requestTypeID === Number(RequestTypes.Stock)));
    return orderMatch;
  }

  GLPConform(input: any): void {
    const dialogData =
      new ConfirmDialogModel("Confirm Stock Decant",
        `This is a Stock Request and you're at a GLP station.  Do you wish to proceed?`,
        "Proceed", false);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "600px",
      disableClose: true,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (!dialogResult) {
        this.router.navigateByUrl(`decant`);
      }
    });
  }

  validateRequestIdCheck(input: string, order: Order) {
    if (input.length === 36) {
      if (this.verifyGuid(input) && order.orderID === input.toLowerCase()) {
        // EAS Story 25000 - no longer validating station vs. order type.
        //If GLP Station is selected and Stock Request ID is scanned then show confirmation modal
        // if (this.selectedStation.isGLP && this.isStockRequestScanned(input, order) &&
        //   this.navigatedFrom != 'decant') {
        //   this.GLPConform(input);
        // }
        //If Stock Station is selected and GLP request scanned display error toaster
        // else if (!this.selectedStation.isGLP && this.isGlpRequestScanned(input, order)) {
        //   this.responseHandler.showError("GLP Requests cannot be decanted using Stock stations");
        //   setTimeout(() => this.router.navigateByUrl('decant'), 2000);
        // }
      } else {
        this.responseHandler.showError("Request ID Invalid");
      }
    }
    else {
      this.responseHandler.showError("Request ID Invalid");
    }
  }

  getCordinatorNotes(coordinatorNotes) {
    return coordinatorNotes?.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

}
