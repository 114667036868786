<h5 class="p-mb-3">{{buttonLabel}} Role</h5>

<form [formGroup]="rolesForm" (submit)="onSubmit()">
    <div class="p-grid p-mt-3 p-mb-4">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-6">
                    <div style="margin-bottom: 10px;">
                        <div style="font-weight: bold;">Name:</div>
                        <input formControlName="roleName" pInputText [style]="{ minWidth: '100%', width: '100%' }"
                            type="text" />
                        <div
                            *ngIf="rolesForm.controls['roleName'].invalid && (rolesForm.controls['roleName'].dirty || rolesForm.controls['roleName'].touched)">
                            <small style="margin-left: 5px; color: red; font-size: 12px;"
                                *ngIf="rolesForm.controls.roleName.errors?.required">
                                Field required.
                            </small><small style="margin-left: 5px; color: red; font-size: 12px;"
                                *ngIf="rolesForm.controls.roleName.errors?.maxlength">
                                Field cannot exceed 80 characters
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-6">
            <div style="margin-bottom: 10px;">
                <div style="font-weight: bold;">Description:</div>
                <textarea rows="6" cols="47" style="width: 100%;" formControlName="description"
                    [ngClass]="rolesForm.controls.description.hasError('maxlength') ? 'error' : ''"></textarea>
                <div
                    *ngIf="rolesForm.controls['description'].invalid && (rolesForm.controls['description'].dirty || rolesForm.controls['description'].touched)">
                    <small style="margin-left: 5px; color: red; font-size: 12px;"
                        *ngIf="rolesForm.controls.description.hasError('maxlength')">
                        Value can not exceed 255 characters
                    </small>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <p-pickList [source]="sourcePermissions" [target]="targetPermissions" sourceHeader="Available Permissions"
                targetHeader="Selected Permissions" [dragdrop]="true" [responsive]="true"
                [sourceStyle]="{ height: '25rem' }" [targetStyle]="{ height: '25rem' }" breakpoint="1400px">
                <ng-template let-permission pTemplate="item">
                    <div class="flex flex-wrap p-2 align-items-center gap-3">
                        <div class="flex-1 flex flex-column gap-2">
                            <span class="font-bold">{{ permission.permissionName }}</span>
                        </div>
                    </div>
                </ng-template>
            </p-pickList>
        </div>
        <mat-dialog-actions style="float: right" class="actions">
            <button style="margin-right: 15px;" mat-raised-button color="cor-default"
                (click)="onCancel()">Cancel</button>
            <button mat-raised-button color="cor-primary" type="submit" [disabled]="!rolesForm.valid">Save</button>
        </mat-dialog-actions>
    </div>
</form>