<form [formGroup]="changeStatusForm" (ngSubmit)="onSubmit()">
    <div style="position:relative">
        <div class="container">
            <div>
                <div style="margin-bottom: 25px;">
                    <span style="font-weight: 700;">Current Status:</span>
                    <span class="space-10">{{data.order.orderStatusDesc}}</span>
                </div>
                <div>
                    <span style="font-weight: 700;">Change Status:</span>
                    <p-dropdown [style]="{'margin-left':'10px'}" [options]="navigationStatuses"
                        placeholder="Select Status" defaultLabel="Select Status" optionLabel="Description"
                        optionValue="OrderStatusID" formControlName="statusId" [disabled]="isDisable">
                    </p-dropdown>
                </div>
                <div style="margin-top: 25px;" *ngIf="hasOverridenPermission">
                    <span style="font-weight: 700;"> Override Rules:</span>
                    <mat-slide-toggle [style]="{'margin-left':'10px'}" formControlName="overrideStatusRules"
                        (change)="onSelectionChange($event)">
                    </mat-slide-toggle>
                </div>
            </div>
            <div>
                <textarea rows="6" cols="50" formControlName="reasonToChange" placeholder="Reason for Status Change"
                    [readonly]="isDisable">Change Status:</textarea>
            </div>
        </div>
        <div [ngClass]="{'no-display': !isDisable}" class="container">
            <p style="color: red; font-style: italic;"> Orders that have already been decanted cannot be moved backwards
                in
                the workflow.
                If this order should not be sent out to the destination location,
                it should be cancelled and the child container received back into inventory.
            </p>
        </div>

        <mat-dialog-actions style="float: right; min-height: 80px;" class="actions">
            <button style="margin-right: 15px;" mat-raised-button color="cor-default" mat-dialog-close>Close</button>
            <button mat-raised-button color="cor-primary" type="submit"
                [disabled]="isDisable || !changeStatusForm.valid">Save</button>
        </mat-dialog-actions>
    </div>
</form>