import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy, OnChanges {

  private loaderSubscription: Subscription;

  @Input() public imageSrc: string;
  @Input() public debounceTime = 300;
  public message = '';
  public secondaryMessage = '';
  public actionMessage = '';
  actionCallBack;

  constructor(
    private loader: LoadingService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.loader.setMessageDelay(this.debounceTime);

    this.loaderSubscription = this.loader
      .onMessageUpdate()
      .subscribe(msg => {
        this.message = msg?.message;
        if (msg?.secondaryMessage) {
          this.secondaryMessage = msg?.secondaryMessage;
          this.actionMessage = msg?.actionMessage;
          this.actionCallBack = msg?.callback;
        }

        if (this.message == null || !this.message) {
          this.resetMessage();
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
      this.loaderSubscription = null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.debounceTime) {
      this.loader.setMessageDelay(changes.debounceTime.currentValue);
    }
  }

  callActionCallBack(e: Event) {
    e.preventDefault();
    console.log('action callback just got clicked')
    if (this.actionCallBack)
      this.actionCallBack();
  }

  resetMessage() {
    this.message = undefined;
    this.secondaryMessage = undefined;
    this.actionCallBack = undefined;
    this.actionMessage = undefined;
  }
}
