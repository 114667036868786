import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService } from 'src/app/services/label-service/label-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { Label } from 'src/app/models/label.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-print-label-modal',
  templateUrl: './print-label-modal.component.html',
  styleUrls: ['./print-label-modal.component.scss']
})
export class PrintLabelModalComponent implements OnInit {

  labels: Label[] = [];
  labelInfoData: any;

  constructor(public dialogRef: MatDialogRef<PrintLabelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private labelService: LabelService,
    private responseHandlingService: ResponseHandlingService, private datePipe: DatePipe) {
    this.labelInfoData = this.data.labelInfoData;
  }

  ngOnInit(): void {
    this.labelService.getLabelList(this.data.inventoryCatalogID).subscribe(response => {
      if (response && response.length) {
        this.labels = response;
      }
    })
  }

  onClose() {
    this.dialogRef.close();
  }

  onDownload(label: Label) {
    this.labelService.getLabel(label.labelID, this.data.containerId).subscribe(response => {
      if (!response) return;
      var link = document.createElement('a');
      link.href = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${response.fileContents}`;
      link.download = this.getFileName(label);
      link.click();
    })
  }

  getFileName(label: Label): string {
    let fileName = `${label.typeName}`;
    var requestedContainerLabel = this.data.labelInfoData.requestedContainerLabel?.replace(/[|&;$%@"<>()+,]/g, "");
    var lotNumber = this.data.labelInfoData.lotNumber?.replace(/[|&;$%@"<>()+,]/g, "");

    if (requestedContainerLabel)
      fileName += `_${requestedContainerLabel}`;

    if (lotNumber)
      fileName += `_${lotNumber}`;

    if (this.data.labelInfoData.netContents && this.data.labelInfoData.unitOfMeasureDesc)
      fileName += `_${this.data.labelInfoData.netContents.toFixed(7)?.toString()}${this.data.labelInfoData.unitOfMeasureDesc}`;

    if (this.data.labelInfoData.requestNumber)
      fileName += `_${this.data.labelInfoData.requestNumber}`;

    return `${fileName}.docx`;
  }

  formatDate(value): string {
    if (!value) return ''
    return this.datePipe.transform(value, 'd-MMM-yyyy');
  }

  formatDecimal(value): string {
    if (!value) return ''
    return parseFloat(value.toFixed(7))?.toString();
  }

}
