import { FeatureService } from '../services/feature-switch-service/feature.service';
import { DecimalNumberDirective } from './shared/directives/DecimalNumber.directive';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReceivingRoutingModule } from './receiving-routing.module';
import { SearchComponent } from './search/search.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { ValidationComponent } from './validation/validation.component';
import { DataEntryComponent } from './data-entry/data-entry.component';
import { WeighingComponent } from './weighing/weighing.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { ReceivingService } from './services/receiving.service';
import { MessagesModule } from 'primeng/messages';
import { AccordionModule } from 'primeng/accordion';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CardModule } from 'primeng/card';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { NavigateComponent } from '../core/modules/navigate/components/navigate.component';
import { ReceivingComponent } from './receiving.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { OrderListModule } from 'primeng/orderlist';
import { InputNumberModule } from 'primeng/inputnumber';
import { FileUploadModule } from 'primeng/fileupload';
import { DocUploadComponent } from './doc-upload/doc-upload.component';
import { LabelComponent } from './label/label.component';
import { TooltipModule } from 'primeng/tooltip';
import { PreviousSessionsComponent } from './previous-sessions/previous-sessions.component';
import { ChipModule } from 'primeng/chip';
import { TagModule } from 'primeng/tag';
import { InputMaskModule } from '../core/modules/input-mask/input-mask.module';
import { PageheaderComponent } from './shared/pageheader/pageheader.component';
import { ArrayFindItemPipe } from '../helpers/array-find.pipe';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CompoundSuppliersComponent } from './search-results/compound-suppliers/compound-suppliers.component';
import { DirtyCheckGuard } from './guards/dirty-check-guard';
import { FieldsetModule } from 'primeng/fieldset';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollToTopModule } from '../core/modules/scroll-to-top/scroll-to-top.module';
import { MsalAuthService } from '../services/auth-service/auth-service.service';
import { ReceivingSessionBaseComponent } from './core/components/receiving-session-base.component';
import { DialogModule } from 'primeng/dialog';
import { IConfig, NgxMaskModule } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    SearchComponent,
    SearchResultsComponent,
    ValidationComponent,
    DataEntryComponent,
    WeighingComponent,
    NavigateComponent,
    ReceivingComponent,
    DocUploadComponent,
    LabelComponent,
    PreviousSessionsComponent,
    PageheaderComponent,
    ArrayFindItemPipe,
    CompoundSuppliersComponent,
    ReceivingSessionBaseComponent,
    DecimalNumberDirective
  ],
  imports: [
    CommonModule,
    ReceivingRoutingModule,
    InputTextModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    RadioButtonModule,
    DropdownModule,
    InputTextareaModule,
    TabViewModule,
    MessagesModule,
    AccordionModule,
    ProgressSpinnerModule,
    ListboxModule,
    TableModule,
    ToggleButtonModule,
    CardModule,
    BreadcrumbModule,
    InputSwitchModule,
    ConfirmDialogModule,
    DialogModule,
    CalendarModule,
    OrderListModule,
    InputNumberModule,
    FileUploadModule,
    TooltipModule,
    ChipModule,
    TagModule,
    InputMaskModule,
    AutoCompleteModule,
    FieldsetModule,
    InfiniteScrollModule,
    ScrollToTopModule,
    NgxMaskModule.forRoot(),
  ],
  providers:
    [
      ReceivingService,
      DecimalPipe,
      ArrayFindItemPipe,
      DirtyCheckGuard,
      MsalAuthService
    ]
})
export class ReceivingModule { }
