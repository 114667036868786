import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { InputCellRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/input-cell-renderer.component';
import { AgGridLotSelectionNumericEditorComponent } from '../../core/modules/ag-grid/components/ag-grid-lot-selection-numeric-editor/ag-grid-lot-selection-numeric-editor.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-lot-selection-modal',
  templateUrl: './lot-selection-modal.component.html',
  styleUrls: ['./lot-selection-modal.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class LotSelectionModalComponent implements OnInit {

  columnDefs;

  rowData = [];
  public frameworkComponents = {
    inputCellRenderer: InputCellRenderer,
    lotSelectionNumericEditor: AgGridLotSelectionNumericEditorComponent
  }
  params: any;
  gridApi: any;

  constructor(private dialogRef: MatDialogRef<LotSelectionModalComponent>,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data, private weightFormatKGPipe: WeightFormatKGPipe) {
    this.createColumnDefs();
    this.createRowData();
  }

  ngOnInit(): void {
  }

  private createColumnDefs(): void {
    const that = this;
    this.columnDefs = [
      {
        headerName: '# of Containers',
        field: 'numberOfContainers',
        cellRenderer: 'lotSelectionNumericEditor'
      },
      {
        headerName: 'Amount Per Container',
        field: 'amountPerContainer',
        valueGetter: (params) => {
          if (!params) { return; }
          return that.weightFormatKGPipe.transform(params.data.amountPerContainer) + ' ' + that.data.unitOfMeasureDesc;
        }
      },
      {
        headerName: 'Total',
        valueGetter: (params) => {
          if (!params) { return; }
          return that.weightFormatKGPipe.transform(params.data.numberOfContainers * params.data.amountPerContainer) + ' ' + that.data.unitOfMeasureDesc;
        }
      },
    ];
  }

  public openConfirmDialog() {
    const dialogData =
        new ConfirmDialogModel("Confirm Net Available Override",
          `Warning: The selected lot does not have sufficient inventory to fulfill these unreserved containers.  Proceeding with this assignment may cause reserved requests to have insufficient inventory for completion.  Do you wish to proceed?`,
          "Confirm", true);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "500px",
        disableClose: true,
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.save();
        }
      });
  }

  public disableSaveButton(): boolean {
    if (this.data.calculatedAmountAvailable < this.getTotalAmount() || this.getTotalAmount() == 0) {
      return true;
    }
    return false;
  }

  private createRowData(): void {
    const _rowData = [];
    for (const row of this.data.orderItemContainerAssignmentResponse) {
      _rowData.push({ 
        assignedLotNumber: row.assignedLotNumber,
        manufacturerLotNumber: this.data.manufacturerLotNumber, 
        numberOfContainers: row.numberOfContainers, 
        amountPerContainer: row.requestedAmount, min: 0,
        max: row.numberOfContainers });
    }
    this.rowData = _rowData
  }

  onGridReady(params) {
    this.gridApi = params.api;
    params.api.addEventListener('inputChangeEvent', (e) => {
      this.handleInputChange(e);
    });
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  handleInputChange(e) {
    this.gridApi.refreshCells();
  }

  getTotalAmount(): number {
    let total = 0;
    this.rowData.forEach(row => total += row.amountPerContainer * row.numberOfContainers);
    // EAS 4-8-2022 Getting some extended decimal places added to the math, so
    // a quick rounding to 7 decimal places trims them off.
    return Number(total.toFixed(7));
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.rowData);
  }

}
