import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { Configuration } from '../../configuration';
import { DecantStation, Warehouse } from '../../models/decant-models';

@Injectable()
export class DecantService {
    readonly rootURL = Configuration.REST_API_URL;
    private setNavigationFrom = new BehaviorSubject('initial subject');
    public getNavigationFrom$  = this.setNavigationFrom.asObservable();

    constructor(private http: HttpClient, private responseHandler: ResponseHandlingService, ) {
    }

    getWarehouses(): Observable<Warehouse[]> {
        var URL = this.rootURL + `/Warehouse/List`;
        return this.http.get<Warehouse[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getDecantStations(): Observable<DecantStation[]> {
        var URL = this.rootURL + `/Stations`;
        return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    getDecantStation(stationID: string): Observable<DecantStation> {
        var URL = this.rootURL + `/Stations/${stationID}`;
        return this.http.get<any>(URL).pipe(catchError(err => this.responseHandler.handleError(err)));
    }

    passNavigation(data: any): any {
        this.setNavigationFrom.next(data);
    }
}