import { PermissionService } from 'src/app/services/permission-service/permission-service';
import { DatePipe, formatDate } from '@angular/common';
import { Component, Input, OnInit, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import { ConfirmationService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { EditPropertyModalComponent } from 'src/app/edit-property-modal/edit-property-modal.component';
import { WeightFormatKGPipe } from 'src/app/helpers/weight-format-kg-pipe';
import { AttachmentEntity, CreateOrderItemAttachmentRequest, OrderItemAttachment, UpdateOrderItemAttachmentRequest } from 'src/app/models/attachment-models';
import { Reservation, ReservationTypes, RetrieveCatalogItemWithConversionResponse } from 'src/app/models/inventory-models';
import { OrderItemContainerAssignmentResponse, OrderItemContainerAssignRequest, OrderItemContainerChangeRequest } from 'src/app/models/order-item-container-model';
import { OrderItemModel } from 'src/app/models/order-item-model';
import { EditPropertyModalData, Order, OrderItemEarmarksByCatalogIDResponse, OrderStatuses, RequestTypes, UpdateRequestedContainerLabelRequest } from 'src/app/models/order-models';
import { DownloadAttachmentService } from 'src/app/services/download-attachment-service/download-attachment.service';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { OrderItemAttachmentService } from 'src/app/services/order-item-attachments-service/order-tem-attachments.service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { SelectLotButtonRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/select-lot-button-renderer.component';
import { ChoosenLotsActionRenderer } from '../../core/modules/ag-grid/components/ag-grid-cell-renderers/choosen-lots-action-renderer.component';
import { EditContainersModalComponent } from '../edit-containers-modal/edit-containers-modal.component';
import { LotSelectionModalComponent } from '../lot-selection-modal/lot-selection-modal.component';
import { UnassignLotsModalComponent } from '../unassign-lots-modal/unassign-lots-modal.component';
import { ViewOtherRequestsModalComponent } from '../view-other-requests-modal/view-other-requests-modal.component';
import { WarehouseHeaderActionComponent } from './header-action/header-action.component';
import { LabelInfoModalComponent } from '../label-info-modal/label-info-modal.component';
import { PrintLabelModalComponent } from '../print-label-modal/print-label-modal.component';
import { CoaModalComponent } from '../coa-modal/coa-modal.component';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { StoredDocsModalComponent } from '../stored-docs-modal/stored-docs-modal.component';
import { finalize, take } from 'rxjs/operators';
import { OrderItemAttachmentsComponent } from '../attachments/attachments.component';
import { NoDecantModalComponent } from '../no-decant-modal/no-decant-modal.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { PillRenderer } from 'src/app/core/modules/ag-grid/components/ag-grid-cell-renderers/pill.render.component';
import { WarehouseSelectLotCheckComponent } from './select-lot-check/select-lot-check.component';
import { valueFormatter } from 'src/app/helpers/grid-helpers';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'material-data-warehouse-detail',
  templateUrl: './material-data-warehouse-detail.component.html',
  styleUrls: ['./material-data-warehouse-detail.component.scss'],
  providers: [WeightFormatKGPipe]
})
export class MaterialDataWarehouseDetailComponent implements OnInit, OnChanges {

  materialNumberLabel: any;
  containersFilled: boolean;
  unassignedOrderItemContainers: OrderItemContainerAssignmentResponse[];
  fullOrderItemContainerResponse: OrderItemContainerAssignmentResponse[];
  totalAmount = 0;
  allContainersAssigned = false;
  totalNumberOfContainers = 0;
  orderStatuses = OrderStatuses;
  reload: boolean = false;
  expanded = false;
  coaStatus: string = '';

  private inventoryDetails: RetrieveCatalogItemWithConversionResponse;
  assignedLotNumber: string = '';
  loggedInUser: any;
  isLoginUserCoordinator: boolean = false;
  @ViewChild('grid') grid: AgGridAngular;
  @ViewChild('chemicalPanel') ePanel: MatExpansionPanel;
  @ViewChild('choosenLotsGrid') choosenLotsGrid: AgGridAngular;
  @ViewChild('itemAttachments') itemAttachments: OrderItemAttachmentsComponent;

  @Output()
  validateButtons: EventEmitter<any> = new EventEmitter();

  private _order: Order;
  @Input()
  public set order(v: Order) {
    this._order = v;
    if (v) {
      this.containersFilled = this.isOrderDecanted(v);
      this.setReservationColumns(v);
      this.setLotSelectionColumnDefs(v);
    }
  }
  public get order(): Order {
    return this._order;
  }

  @Input() set orderItem(val: OrderItemModel) {
    this._orderItem = val;
    this.attachmentEntity = {
      id: val.orderItemID,
      name: val.containerLabelFmt,
      attachments: val.attachments ? val.attachments : [],
    }
    this.totalNumberOfContainers = val?.containers?.length;
    this.allContainersAssigned = val?.containers.every(_ => _.isContainerAssigned);
    if (this._orderItem.vertereProductNumber && this._orderItem.vertereProductSourceSystem) {
      this.materialNumberLabel = `${this._orderItem.vertereProductSourceSystem} ID: ${this._orderItem.vertereProductNumber}, `;
    }
    //this.fetchData();
    //this.getEarmarkData();
    this.calculateTotalAmountRequested();
  }

  private _orderItem: OrderItemModel;
  get orderItem(): OrderItemModel {
    return this._orderItem;
  }

  isInventorySaved = false;
  attachmentEntity: AttachmentEntity;

  setReservationColumns(order: Order) {
    this.reservationColumnDefinitions = [
      {
        headerName: 'Location',
        field: 'location',
        width: 100
      },
      {
        headerName: `Physical Inventory`,
        field: 'indyPhysicalInventory',
        valueFormatter: params => {
          return `${valueFormatter(params.data.indyPhysicalInventory)} ${this.orderItem?.unitOfMeasureDesc}`;
        }
      },
      {
        headerName: `Reserved`,
        field: 'indyReserved',
        valueFormatter: params => {
          return `${valueFormatter(params.data.indyReserved)} ${this.orderItem?.unitOfMeasureDesc}`;
        }
      },
      {
        headerName: 'Pending Decant (Reserved)',
        field: 'amountPendingDecant',
        valueFormatter: params => {
          return `${valueFormatter(params.data.reservedAmountPendingDecant)} ${this.orderItem?.unitOfMeasureDesc}`;
        }
      },
      {
        headerName: 'Pending Decant (Unreserved)',
        field: 'amountPendingDecant',
        valueFormatter: params => {
          return `${valueFormatter(params.data.unreservedAmountPendingDecant)} ${this.orderItem?.unitOfMeasureDesc}`;
        }
      },
      {
        headerName: 'Expired',
        field: 'expiredAmount',
        valueFormatter: params => {
          return `${valueFormatter(params.data.expiredAmount)} ${this.orderItem?.unitOfMeasureDesc}`;
        }
      },
      {
        headerName: 'Net Amount Available',
        field: 'netAmountAvailable',
        valueFormatter: params => {
          return `${valueFormatter(params.data.netAmountAvailable)} ${this.orderItem?.unitOfMeasureDesc}`;
        }
      },
    ]
  }

  reservationColumnDefinitions = []

  lotSelectionColumnDefinitions;

  chosenLotsColumnDefinitions;

  chosenLotsFrameworkComponents = {
    choosenLotsAction: ChoosenLotsActionRenderer,
    pillRenderer: PillRenderer
  };

  lotSelectionGridOptions: GridOptions = {
    getRowStyle: (params) => {
      if (params?.data && params?.data?.storageLocations && params?.data?.storageLocations?.length > 0) {
        if (params.data.storageLocations.some((x: string) => x.toLowerCase().includes('quarantine'))) {
          return { 'color': 'red' };
        }
      } else if (params.data.storageLocations.toLowerCase().includes('quarantine')) {
        return { 'color': 'red' };
      }
    }
  };

  earmarkRowData = [];
  reservationRowData = [];
  lotSelectionRowData = [];
  chosenLotsRowData = [];
  selectLotFrameworkComponents = {
    selectLotButtonRenderer: SelectLotButtonRenderer
  };

  constructor(private dialog: MatDialog, private orderService: OrderService, private inventoryService: InventoryService, private orderItemAttachmentService: OrderItemAttachmentService, private responseHandler: ResponseHandlingService, private downloadAttachmentService: DownloadAttachmentService, private confirmationService: ConfirmationService, private weightFormatKGPipe: WeightFormatKGPipe, private loadingService: LoadingService, private datePipe: DatePipe, private permissionService: PermissionService) {
    this.setChosenLotsColumnDefs();
  }

  ngOnInit(): void {
    localStorage.setItem('coaCheckInitiated', JSON.stringify(false));
    this.loggedInUser = JSON.parse(localStorage.getItem("authUserInfo"));
    this.isLoginUserCoordinator = this.loggedInUser.givenName === this.order?.assignedToFirstName && this.loggedInUser.surName === this.order?.assignedToLastName;
    if (this.orderItem.coaChecked) this.coaStatus = `Last Checked<br/>${this.formatDate(this.orderItem.coaChecked)}`;
  }

  ngOnChanges(): void {
    //this.fetchData();
  }

  private fetchData() {
    if (this.orderItem.inventoryCatalogID) {
      forkJoin([
        this.orderService.getOrderItemContainerAssignments(this.orderItem.orderItemID),
        this.inventoryService.getCatalogItemByWarehouse(this.order.warehouseID, this.orderItem.inventoryCatalogID, this.orderItem.unitOfMeasureDesc)
      ])
        .pipe(take(1))
        .subscribe(result => {
          this.bindData(result[0], result[1]);
        });
    }
    else {
      this.orderService.getOrderItemContainerAssignments(this.orderItem.orderItemID).subscribe(result => {
        this.bindData(result);
      })
    }
  }

  onNoDecant() {
    this.dialog.open(NoDecantModalComponent, {
      minWidth: '70vw',
      autoFocus: false,
      data: { orderItem: this.orderItem, order: this.order }
    }).afterClosed().subscribe(result => {
      if (result) this.refreshChemicalData();
    })
  }

  bindData(itemContainerAssignments, catalogItemWithConversion = null) {
    this.createChosenLotsData(itemContainerAssignments);
    this.createReservationData(catalogItemWithConversion);

    var selectedGlp = this.lotSelectionRowData
      .filter(ls => ls.lotNumber === this._orderItem.requestedLotNumber
        && ls.storageLocations.some(el => el.includes('GLP')))[0];

    var selectedStock = this.lotSelectionRowData
      .filter(ls => ls.lotNumber === this._orderItem.requestedLotNumber
        && ls.storageLocations.some(el => el.includes('Stock')))[0];

    this.lotSelectionRowData = this.lotSelectionRowData.filter(ls => ls.lotNumber != this._orderItem.requestedLotNumber);

    var glp = this.lotSelectionRowData.filter(ls => ls.storageLocations.some(el => el.includes('GLP')));
    glp?.forEach(item => item['expRecertDate'] = item.recertificationDate ? item.recertificationDate :
      (item.expirationDate ? item.expirationDate : null));
    glp = glp?.sort((lot1, lot2) => {
      if (lot1.expRecertDate === null) {
        return 1;
      }
      else if (lot2.expRecertDate === null) {
        return -1;
      }
      else {
        const dateOne = new Date(lot1.expRecertDate);
        const dateTwo = new Date(lot2.expRecertDate);
        return dateOne.getTime() - dateTwo.getTime();
      }
    });

    var stock = this.lotSelectionRowData.filter(ls => ls.storageLocations.some(el => el.includes('Stock')));
    stock?.forEach(item => item['expRecertDate'] = item.recertificationDate ? item.recertificationDate :
      (item.expirationDate ? item.expirationDate : null));
    stock = stock?.sort((lot1, lot2) => {
      if (lot1.expRecertDate === null) {
        return 1;
      }
      else if (lot2.expRecertDate === null) {
        return -1;
      }
      else {
        const dateOne = new Date(lot1.expRecertDate?.split('T')[0]);
        const dateTwo = new Date(lot2.expRecertDate?.split('T')[0]);
        return dateOne.getTime() - dateTwo.getTime();
      }
    });

    this.lotSelectionRowData = [];

    if (this.order.requestTypeID === Number(RequestTypes.Stock)) {
      this.insertMatchesItems(selectedStock, stock, selectedGlp, glp);
      stock.push(...glp);
      this.lotSelectionRowData = stock;
    }
    else if (this.order.requestTypeID === Number(RequestTypes.GLP)) {
      this.insertMatchesItems(selectedStock, stock, selectedGlp, glp);
      glp.push(...stock);
      this.lotSelectionRowData = glp;
    }
  }

  private insertMatchesItems(selectedStock: any, stock: any[], selectedGlp: any, glp: any[]) {
    if (selectedStock)
      stock.unshift(selectedStock);

    if (selectedGlp)
      glp.unshift(selectedGlp);
  }

  private setChosenLotsColumnDefs(): void {
    const that = this;
    this.chosenLotsColumnDefinitions = [
      {
        headerName: 'Selected Lot',
        field: 'assignedLotNumber',
        valueGetter: (params) => {
          if (!params) { return ''; }
          return params.data.assignedLotNumber ? params.data.assignedLotNumber : '--';
        },
        width: 150,
      },
      {
        headerName: '',
        field: 'isNoDecantContainer',
        cellRenderer: 'pillRenderer',
        width: 150
      },
      {
        headerName: 'Container Status',
        valueGetter: (params) => {
          if (!params) { return ''; }
          else if (params.data.isDecanted) {
            return 'Decanted';
          } else if (params.data.assignedLotNumber) {
            return 'Assigned';
          } else {
            return 'Unassigned';
          }
        },
        width: 150
      },
      {
        headerName: '# of Containers',
        field: 'numberOfContainers',
        width: 100,
      },
      {
        headerName: 'Amount Per Container',
        field: 'amountPerContainer',
        width: 145,
        valueGetter: function (params) {
          if (params.data !== undefined && that.orderItem.unitOfMeasureDesc) {
            return that.weightFormatKGPipe.transform(params.data.amountPerContainer) + ' ' + that.orderItem.unitOfMeasureDesc;
          }
        }
      },
      {
        headerName: 'Amount Requested',
        field: 'amountRequested',
        valueGetter: (params) => {
          if (params.data !== undefined && that.orderItem.unitOfMeasureDesc) {
            return this.weightFormatKGPipe.transform(params.data.numberOfContainers * params.data.amountPerContainer) + ' ' + that.orderItem.unitOfMeasureDesc;
          }
        },
        width: 125,
      },
      {
        field: 'actions',
        headerName: '',
        cellRenderer: 'choosenLotsAction',
        width: 380
      }
    ];
  }

  private setLotSelectionColumnDefs(order: Order): void {
    const that = this;
    this.lotSelectionColumnDefinitions =
      [
        {
          headerName: 'Lot Number',
          headerTooltip: 'Lot Number',
          field: 'lotNumber',
          tooltipValueGetter: (params) => {
            if (params.data !== undefined && params.data.lotNumber) {
              return params.data.lotNumber;
            }
          },
        },
        {
          headerComponentParams: (params) => {
            let template = `<span class="custom-header">Exp Date/<b style="font-weight: bold">Recert Date</b></span>`
            return { template }
          },
          headerTooltip: 'Exp Date/Recert Date',
          field: 'exp_recert_date',
          cellRenderer: (params) => {
            if (params.data && params.data.recertificationDate) {
              return `<b class="bold">${params.data['exp_recert_date']}</b>`;
            }
            else {
              return params.data['exp_recert_date'];
            }
          },
          valueGetter: (params) => {
            if (params.data && params.data.recertificationDate) {
              params.data['exp_recert_date'] = formatDate(params.data.recertificationDate, 'd-MMM-yyyy', 'en-US');
            }
            else if (params.data && params.data.expirationDate) {
              params.data['exp_recert_date'] = formatDate(params.data.expirationDate, 'd-MMM-yyyy', 'en-US');
            }
            else {
              params.data['exp_recert_date'] = '--';
            }
          },
          tooltipValueGetter: (params) => {
            return params.data['exp_recert_date']
          },
        },
        {
          headerName: 'Inventory Amount',
          headerTooltip: 'Inventory Amount',
          field: 'remainingAmountConverted',
          valueGetter: (params) => {
            if (params.data !== undefined && that.orderItem.unitOfMeasureDesc) {
              const val = that.weightFormatKGPipe.transform(params.data.remainingAmountConverted);
              return `${valueFormatter(val)} ${this.orderItem?.unitOfMeasureDesc}`;
            }
          },
          tooltipValueGetter: (params) => {
            if (params.data !== undefined && that.orderItem.unitOfMeasureDesc) {
              const val = that.weightFormatKGPipe.transform(params.data.remainingAmountConverted);
              return `${valueFormatter(val)} ${this.orderItem?.unitOfMeasureDesc}`;
            }
          },
        },
        {
          headerName: `${order?.warehouseName} Reserved Amount`,
          headerTooltip: `${order?.warehouseName} Reserved Amount`,
          field: 'indyReservedAmount',
          hide: true,
          valueGetter: (params) => {
            let indyAmount = 0;
            let storageLocation = params.data.storageLocations.join(',').toLowerCase();
            this.inventoryDetails.reservations.forEach((reservation) => {
              if (params.data.lotNumber == reservation.lotNumber
                && (reservation.isGLP === storageLocation.includes('glp') || reservation.isGLP === null && !storageLocation.includes('glp'))) {
                indyAmount += reservation.reservationAmountConverted;
              }
            })
            const val = that.weightFormatKGPipe.transform(indyAmount);
            return `${valueFormatter(val)} ${this.orderItem?.unitOfMeasureDesc}`;
          },
          tooltipValueGetter: (params) => {
            let indyAmount = 0;
            let storageLocation = params.data.storageLocations.join(',').toLowerCase();
            this.inventoryDetails.reservations.forEach((reservation) => {
              if (params.data.lotNumber == reservation.lotNumber
                && (reservation.isGLP === storageLocation.includes('glp') || reservation.isGLP === null && !storageLocation.includes('glp'))) {
                indyAmount += reservation.reservationAmountConverted;
              }
            })
            const val = that.weightFormatKGPipe.transform(indyAmount);
            return `${valueFormatter(val)} ${this.orderItem?.unitOfMeasureDesc}`;
          },
        },
        {
          headerName: 'Pending Decant (Reserved)',
          headerTooltip: 'Pending Decant (Reserved)',
          field: 'reservedEarmarkedAmountConverted',
          hide: true,
          valueGetter: function (params) {
            if (params.data !== undefined && that.orderItem.unitOfMeasureDesc) {
              const val = that.weightFormatKGPipe.transform(params.data.reservedEarmarkedAmountConverted);
              return `${valueFormatter(val)} ${this.orderItem?.unitOfMeasureDesc}`;
            }
          },
          tooltipValueGetter: (params) => {
            if (params.data !== undefined && that.orderItem.unitOfMeasureDesc) {
              const val = that.weightFormatKGPipe.transform(params.data.reservedEarmarkedAmountConverted);
              return `${valueFormatter(val)} ${this.orderItem?.unitOfMeasureDesc}`;
            }
          },
        },
        {
          headerName: 'Pending Decant (Unreserved)',
          headerTooltip: 'Pending Decant (Unreserved)',
          field: 'unReservedEarmarkedAmountConverted',
          hide: true,
          valueGetter: function (params) {
            if (params.data !== undefined && that.orderItem.unitOfMeasureDesc) {
              const val = that.weightFormatKGPipe.transform(params.data.unreservedEarmarkedAmountConverted);
              return `${valueFormatter(val)} ${this.orderItem?.unitOfMeasureDesc}`;
            }
          },
          tooltipValueGetter: (params) => {
            if (params.data !== undefined && that.orderItem.unitOfMeasureDesc) {
              const val = that.weightFormatKGPipe.transform(params.data.unreservedEarmarkedAmountConverted);
              return `${valueFormatter(val)} ${this.orderItem?.unitOfMeasureDesc}`;
            }
          },
        },
        {
          headerName: 'Net Amount Available',
          headerTooltip: 'Net Amount Available',
          field: 'netAmountAvailableDisplay',
          tooltipValueGetter: (params) => {
            if (params.data !== undefined && that.orderItem.unitOfMeasureDesc) {
              return params.data.netAmountAvailableDisplay ? valueFormatter(params.data.netAmountAvailableDisplay.split(' ')[0]) + ' ' + that.orderItem.unitOfMeasureDesc : params.data.netAmountAvailableDisplay;
            }
          },
          valueGetter: (params) => {
            if (params.data !== undefined && that.orderItem.unitOfMeasureDesc) {
              return params.data?.netAmountAvailableDisplay ? valueFormatter(params.data.netAmountAvailableDisplay.split(' ')[0]) + ' ' + that.orderItem.unitOfMeasureDesc : params.data.netAmountAvailableDisplay;
            }
          },
        },
        {
          headerName: 'Manufacturer',
          headerTooltip: 'Manufacturer',
          field: 'manufacturerName',
          hide: true,
          valueGetter: (params) => {
            if (params.data == undefined || !params.data.manufacturerName) { return '--'; }
            return params.data.manufacturerName;
          },
          tooltipValueGetter: (params) => {
            if (params.data !== undefined && params.data.manufacturerName) {
              return params.data.manufacturerName;
            }
          },
        },
        {
          headerName: 'Manufacturer Lot',
          headerTooltip: 'Manufacturer Lot',
          field: 'manufacturerLotNumber',
          hide: true,
          valueGetter: (params) => {
            if (params.data == undefined || !params.data.manufacturerLotNumber) { return '--'; }
            return params.data.manufacturerLotNumber;
          },
          tooltipValueGetter: (params) => {
            if (params.data !== undefined && params.data.manufacturerLotNumber) {
              return params.data.manufacturerLotNumber;
            }
          },
        },
        {
          headerName: 'Storage Locations',
          headerTooltip: 'Storage Locations',
          field: 'storageLocations',
          hide: true,
          valueGetter: function (params) {
            if (params.data && params.data.storageLocations && params.data.storageLocations.length > 0) {
              return params.data.storageLocations.join(',');
            } else {
              return '--';
            }
          },
          tooltipValueGetter: (params) => {
            if (params.data && params.data.storageLocations && params.data.storageLocations.length > 0) {
              return params.data.storageLocations.join(',');
            }
          },
        },
        {
          headerName: 'Country of Origin',
          headerTooltip: 'Country of Origin',
          field: 'countryOfOrigin',
          hide: true,
          valueGetter: (params) => {
            if (params.data == undefined || !params.data.countryOfOrigin) { return '--'; }
            return params.data.countryOfOrigin;
          },
          tooltipValueGetter: (params) => {
            if (params.data !== undefined && params.data.countryOfOrigin) {
              return params.data.countryOfOrigin;
            }
          },
        },
        {
          headerName: 'Reach Compliant',
          headerTooltip: 'Reach Compliant',
          hide: true,
          field: 'isReachApproved', valueGetter: (params) => {
            if (!params) { return ''; }
            return params.data.isReachCompliant ? 'Yes' : 'No';
          },
          tooltipValueGetter: (params) => {
            if (!params) { return ''; }
            return params.data.isReachCompliant ? 'Yes' : 'No';
          }
        },
        {
          headerName: 'GLP Status',
          headerTooltip: 'GLP Status',
          field: 'glpStatus',
          hide: true,
          valueGetter: (params) => {
            if (params.data == undefined || !params.data.glpStatus) { return '--'; }
            return params.data.glpStatus;
          },
          tooltipValueGetter: (params) => {
            if (params.data !== undefined && params.data.glpStatus) {
              return params.data.glpStatus;
            }
          },
        },
        {
          headerComponentFramework: WarehouseHeaderActionComponent,
          cellRenderer: 'selectLotButtonRenderer', minWidth: 132, maxWidth: 132, width: 132
        }
      ];
  }

  private createChosenLotsData(res: OrderItemContainerAssignmentResponse[]) {
    const _rowData = [];
    this.fullOrderItemContainerResponse = res;
    this.unassignedOrderItemContainers = res.filter(r => !r.isDecanted && !r.assignedLotNumber);

    this.totalNumberOfContainers = 0;

    this.choosenLotsGrid?.columnApi.setColumnVisible('isNoDecantContainer', res?.some(r => r?.isNoDecantContainer));

    for (const row of res) {
      _rowData.push({
        assignedLotNumber: row.assignedLotNumber,
        isDecanted: row?.isDecanted,
        isNoDecantContainer: row?.isNoDecantContainer,
        numberOfContainers: row.numberOfContainers,
        amountPerContainer: row.requestedAmount,
        assignedInventoryCatalogID: row.assignedInventoryCatalogID,
        unitOfMeasureDesc: row.unitOfMeasureDesc,
        disableUnassigned: !this.isLoginUserCoordinator
      });

      this.totalNumberOfContainers += row.numberOfContainers;
      this.assignedLotNumber = row.assignedLotNumber;
    }

    const unassignedRows = _rowData.filter(r => !r.assignedLotNumber);
    const assignedRows = _rowData.filter(r => r.assignedLotNumber && !r.isDecanted).sort((a, b) => a.assignedLotNumber > b.assignedLotNumber ? 1 : -1);
    const decantedRows = _rowData.filter(r => r.isDecanted).sort((a, b) => a.assignedLotNumber > b.assignedLotNumber ? 1 : -1);

    const sortedRowData = [];
    unassignedRows.forEach(row => sortedRowData.push(row));
    assignedRows.forEach(row => sortedRowData.push(row));
    decantedRows.forEach(row => sortedRowData.push(row));

    this.chosenLotsRowData = sortedRowData;
    //this.allContainersAssigned = this.chosenLotsRowData.length > 0 && !this.chosenLotsRowData.some(row => !row.assignedLotNumber);

    // update at the order item level to enable/disable Advance button at the Order Level
    this.orderItem.allContainersAssigned = this.allContainersAssigned;

    this.validateButtons.emit();
  }

  private createReservationData(res: RetrieveCatalogItemWithConversionResponse) {
    if (res) {
      let glpIndyReserved = 0, stockIndyReserved = 0, glpSGSReserved = 0, stockSGSReserved = 0;
      if (res.reservations) {
        res.reservations.forEach((reservation: Reservation) => {
          if (reservation.isGLP) {
            glpIndyReserved += reservation.reservationAmountConverted;
          }
          else if (!reservation.isGLP) {
            stockIndyReserved += reservation.reservationAmountConverted;
          }
        });
        let glpUnreservedAmountPendingDecant = 0;
        let glpReservedAmountPendingDecant = 0;
        let indyPhysicalInventory = 0;
        let glpExpiredAmount = 0
        res.glpLots.forEach(lot => {
          glpExpiredAmount += lot.expiredAmountConverted;
          glpUnreservedAmountPendingDecant += lot.unreservedEarmarkedAmountConverted;
          glpReservedAmountPendingDecant += lot.reservedEarmarkedAmountConverted;
          indyPhysicalInventory += lot.remainingAmountConverted;
        });

        let stockUnreservedAmountPendingDecant = 0;
        let stockReservedAmountPendingDecant = 0;
        let stockIndyPhysicalInventory = 0;
        let stockExpiredAmount = 0
        res.stockLots.forEach(lot => {
          stockExpiredAmount += lot.expiredAmountConverted;
          stockUnreservedAmountPendingDecant += lot.unreservedEarmarkedAmountConverted;
          stockReservedAmountPendingDecant += lot.reservedEarmarkedAmountConverted;
          stockIndyPhysicalInventory += lot.remainingAmountConverted;
        });

        let glpNetAmountAvailable = 0;
        if (this.order.useReservation) {
          glpNetAmountAvailable = indyPhysicalInventory - glpUnreservedAmountPendingDecant - glpReservedAmountPendingDecant - glpExpiredAmount;
        } else {
          glpNetAmountAvailable = indyPhysicalInventory - Math.max(glpIndyReserved, glpReservedAmountPendingDecant) - glpUnreservedAmountPendingDecant - glpExpiredAmount;
        }

        let stockNetAmountAvailable = 0;
        if (this.order.useReservation) {
          stockNetAmountAvailable = stockIndyPhysicalInventory - stockUnreservedAmountPendingDecant - stockReservedAmountPendingDecant - stockExpiredAmount;
        } else {
          stockNetAmountAvailable = stockIndyPhysicalInventory - Math.max(stockIndyReserved, stockReservedAmountPendingDecant) - stockUnreservedAmountPendingDecant - stockExpiredAmount;
        }
        const reservationRowData = [{
          location: 'GLP',
          indyPhysicalInventory: indyPhysicalInventory,
          indyReserved: glpIndyReserved,
          unreservedAmountPendingDecant: glpUnreservedAmountPendingDecant,
          reservedAmountPendingDecant: glpReservedAmountPendingDecant,
          netAmountAvailable: glpNetAmountAvailable,
          expiredAmount: glpExpiredAmount
        }, {
          location: 'Stock',
          indyPhysicalInventory: stockIndyPhysicalInventory,
          indyReserved: stockIndyReserved,
          unreservedAmountPendingDecant: stockUnreservedAmountPendingDecant,
          reservedAmountPendingDecant: stockReservedAmountPendingDecant,
          netAmountAvailable: stockNetAmountAvailable,
          expiredAmount: stockExpiredAmount
        }];

        this.reservationRowData = [...reservationRowData];
        this.inventoryDetails = res;
        const assignedRows = res.lots.filter(r => r.lotNumber == this.assignedLotNumber);
        const unassignedRows = res.lots.filter(r => r.lotNumber != this.assignedLotNumber);
        const sortedRowData = [];
        assignedRows.forEach(row => sortedRowData.push(row));
        unassignedRows.forEach(row => sortedRowData.push(row));
        //decantedRows.forEach(row => sortedRowData.push(row));
        //this.chosenLotsRowData = sortedRowData;
        this.lotSelectionRowData = sortedRowData;
        this.lotSelectionRowData.forEach(row => {
          let storageLocation = row.storageLocations.join(',').toLowerCase();
          row.disableUnassigned = !this.isLoginUserCoordinator;
          if (this.order.useReservation) {
            row.netAmountAvailable = row.remainingAmountConverted - row.unreservedEarmarkedAmountConverted - row.reservedEarmarkedAmountConverted;
            row.netAmountAvailableDisplay = this.weightFormatKGPipe.transform(row.remainingAmountConverted - row.unreservedEarmarkedAmountConverted - row.reservedEarmarkedAmountConverted) + ' ' + this.orderItem.unitOfMeasureDesc;
          } else {
            let indyAmount = 0;
            this.inventoryDetails.reservations.forEach((reservation) => {
              if (row.lotNumber == reservation.lotNumber && (reservation.isGLP === storageLocation.includes('glp') || reservation.isGLP === null && !storageLocation.includes('glp'))) {
                indyAmount += reservation.reservationAmountConverted;
              }
            });

            row.netAmountAvailable = row.remainingAmountConverted - Math.max(indyAmount, row.reservedEarmarkedAmountConverted) - row.unreservedEarmarkedAmountConverted;
            row.netAmountAvailableDisplay = this.weightFormatKGPipe.transform(row.netAmountAvailable) + ' ' + this.orderItem.unitOfMeasureDesc;
          }
          row.calculatedAmountAvailable = row.remainingAmountConverted - row.unreservedEarmarkedAmountConverted - row.reservedEarmarkedAmountConverted;

          if ((
            (this.order.requestTypeID === Number(RequestTypes.Stock) && storageLocation.includes('glp'))
            // need to include a space before 'stock' to avoid accidentally matching on 'Overstock'
            || (this.order.requestTypeID === Number(RequestTypes.GLP) && storageLocation.includes(' stock'))
          )
            ||
            (this.order.orderStatusID === OrderStatuses.New || this.order.orderStatusID === OrderStatuses.SGSRequested || this.allContainersAssigned || (row.netAmountAvailable <= 0 && !this.permissionService.validateUserPermission(453)) || (this.permissionService.validateUserPermission(453) && row.calculatedAmountAvailable <= 0)
              || !this.isLoginUserCoordinator)) {
            row.disableSelectLot = true;
            row.buttonText = storageLocation.includes('glp') ? "GLP Lot" : "Stock Lot";
          }
          else {
            row.disableSelectLot = false;
            row.buttonText = "Select Lot";
          }

          row.hasUserPermissionAndNoOrderReservation = false;
          if (this.permissionService.validateUserPermission(453) && !this.order.useReservation) {
            row.hasUserPermissionAndNoOrderReservation = true;
          }
        });
      }
    }
  }


  public onStoredDocuments(e) {
    this.dialog.open(StoredDocsModalComponent, {
      minWidth: '70vw',
      autoFocus: false,
      data: { orderItem: this.orderItem }
    }).afterClosed().subscribe(result => {
      this.getAttachmentListByOrderItemID();
    })
  }
  public getAttachmentListByOrderItemID() {
    this.orderItemAttachmentService.getAttachmentListByOrderItemID(this.orderItem.orderItemID).subscribe(response => {
      this.attachmentEntity = {
        id: this.orderItem.orderItemID,
        name: this.orderItem.containerLabelFmt,
        attachments: response ? response : [],
      }
      // this.reload = true;
      // setTimeout(() => { this.reload = false; }, 0);
    })
  }

  public onEditLabelButtonClick(e) {
    e.stopPropagation();
    const dialogData: EditPropertyModalData = {
      propertyDisplayName: 'Requested Container Label',
      oldValue: this.orderItem.requestedContainerLabel
    };
    this.dialog.open(EditPropertyModalComponent, { data: dialogData, height: '240px' }).afterClosed().subscribe((dialogResponse: string) => {
      if (!dialogResponse) { return; }
      const payload: UpdateRequestedContainerLabelRequest = {
        requestedContainerLabel: dialogResponse
      };
      this.orderService.updateRequestedContainerLabel(this.orderItem.orderItemID, payload).subscribe(res => {
        // updating item properties in memory so we don't need to make another API call
        let newFormattedLabel = ` ${this.orderItem.vertereCatalogDescription} (${dialogResponse})`;
        newFormattedLabel += ' *';
        this.orderItem.containerLabelFmt = newFormattedLabel;
        this.orderItem.requestedContainerLabel = dialogResponse;
      });
    });
  }

  public resetButtons() {
    this.isLoginUserCoordinator = this.loggedInUser.givenName === this.order?.assignedToFirstName && this.loggedInUser.surName === this.order?.assignedToLastName;
    this.lotSelectionRowData.forEach(row => {
      let storageLocation = row.storageLocations.join(',').toLowerCase();
      row.disableUnassigned = !this.isLoginUserCoordinator
      if (((this.order.requestTypeID === Number(RequestTypes.Stock) && storageLocation.includes('glp'))
        || (this.order.requestTypeID === Number(RequestTypes.GLP) && storageLocation.includes('stock'))
      )
        ||
        (this.order.orderStatusID === OrderStatuses.New || this.order.orderStatusID === OrderStatuses.SGSRequested || this.allContainersAssigned || row.netAmountAvailable <= 0)
      ) {
        row.disabledSelectLot = true;
        row.buttonText = storageLocation.includes('glp') ? "GLP Lot" : "Stock Lot";
      } else {
        row.disableSelectLot = false;
        row.buttonText = "Select Lot";
      }
    });

    this.chosenLotsRowData.forEach(row => {
      row.disableUnassigned = !this.isLoginUserCoordinator;
    });
  }

  isOrderDecanted(order: Order): boolean {
    let result = true;
    order.items.forEach(item => {
      item.containers.forEach(container => {
        if (!container.decanted) {
          result = false;
        }
      });
    });
    return result;
  }

  private calculateTotalAmountRequested() {

    this.totalAmount = 0;
    for (const container of this.orderItem.containers) {
      this.totalAmount += container.requestedAmount;
    }
  }

  public getEarmarkData(): void {
    if (!this.orderItem.inventoryCatalogID) { return; }
    this.orderService.getEarmarksByCatalogID(this.orderItem.inventoryCatalogID, this.orderItem.unitOfMeasureDesc).subscribe((res: OrderItemEarmarksByCatalogIDResponse[]) => {
      this.earmarkRowData = res;
    });
  }

  public viewOtherRequests(): void {
    const data = { rowData: this.earmarkRowData, orderItem: this.orderItem }
    this.dialog.open(ViewOtherRequestsModalComponent, { data, width: '100%' });
  }

  onChosenLotsGridReady(params) {
    params.api.addEventListener('unassignLotButtonClicked', (e) => {
      this.unassignLots(e);
    });
    params.api.addEventListener('labelInfoButtonClicked', (e) => {
      this.labelInfo(e);
    });
    params.api.addEventListener('printLabelButtonClicked', (e) => {
      this.printLabel(e);
    });
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  onMaterialSummaryGridReady(params) {
    params.api.sizeColumnsToFit();
  }

  onSelectLotGridReady(params) {
    params.api.addEventListener('selectLotButtonClicked', (e) => {
      if (e?.value?.exp_recert_date) {
        const dateString = formatDate(new Date(), 'd-MMM-yyyy', 'en-US');
        const currDate = new Date(dateString);
        const expRecertDate = new Date(e?.value?.exp_recert_date);
        if (currDate > expRecertDate) {
          this.dialog.open(WarehouseSelectLotCheckComponent).afterClosed().subscribe(() => {
            this.openLotModal(params, e);
          });
        }
        else {
          this.openLotModal(params, e);
        }
      }
      else {
        this.openLotModal(params, e);
      }
    });
  }

  openLotModal(params, e) {
    this.openLotSelectionModal(e);
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  formatDate(value): string {
    return this.datePipe.transform(value, 'd-MMM-yyyy');
  }

  openForecastUrl() {
    window.open(this.orderItem?.forecastURL, '_blank');
  }

  retrieveCOA() {
    this.loadingService.show('Loading...');
    localStorage.setItem('coaCheckInitiated', JSON.stringify(true));
    this.orderService.retrieveCOA(this.orderItem.orderItemID).subscribe(response => {
      localStorage.setItem('coaCheckInitiated', JSON.stringify(false));
      this.orderItem.coaChecked = new Date();
      this.coaStatus = `Last Checked < br /> ${this.formatDate(this.orderItem.coaChecked)} `;
      this.loadingService.clearMessage();
      this.openAttachToOrderModal(response);
    }, err => {
      localStorage.setItem('coaCheckInitiated', JSON.stringify(false));
      this.orderItem.coaChecked = '';
      this.coaStatus = 'No COA found';
      this.loadingService.clearMessage();
      this.responseHandler.showError('No COA was found for requested lot', 5000);
    });
  }
  get canDisableCOA(): boolean {
    if (this.order.requestTypeID != Number(RequestTypes.Stock)) {
      return false;
    }
    else {
      return true;
    }
  }

  openAttachToOrderModal(response) {
    this.dialog.open(CoaModalComponent, {
      disableClose: true,
      autoFocus: false,
      width: response.originalFilename.split('.').pop() === 'pdf' ? '50vw' : 'auto',
      data: { coaData: response, orderItemID: this.orderItem.orderItemID }
    }).afterClosed().subscribe(result => {
      if (result)
        this.orderItem.attachments.push(result);
    })
  }

  resizeGrid(params) {
    params.api.sizeColumnsToFit();
  }

  private printLabel(e) {
    if (!e.value.assignedInventoryCatalogID) return;
    let inventory = this.inventoryDetails?.lots.find(l => l.lotNumber === e.value.assignedLotNumber);
    if (!inventory) return;
    const labelInfoData = {
      isGLPRequest: this.order.requestTypeID === RequestTypes.GLP,
      netContents: e.value?.amountPerContainer,
      unitOfMeasureDesc: e.value?.unitOfMeasureDesc,
      manufacturerLotNumber: inventory.manufacturerLotNumber,
      lotNumber: inventory.lotNumber,
      manufactureDate: inventory.manufactureDate,
      expirationDate: inventory.expirationDate,
      recertificationDate: inventory.recertificationDate,
      requestNumber: this.order.requestNumber,
      requestedContainerLabel: this.orderItem.requestedContainerLabel
    }

    var container = this.orderItem?.containers.find(c => c.assignedLotNumber == inventory.lotNumber
      && c.requestedAmount == e.value?.amountPerContainer)

    this.dialog.open(PrintLabelModalComponent, {
      minHeight: '200px',
      maxHeight: '450px',
      disableClose: true,
      autoFocus: false,
      data: {
        materialName: this.inventoryDetails?.description,
        inventoryCatalogID: e.value.assignedInventoryCatalogID,
        labelInfoData: labelInfoData,
        containerId: container?.orderItemContainerID
      }
    })
  }

  private labelInfo(e) {
    if (!e.value.assignedInventoryCatalogID) return;
    let inventory = this.inventoryDetails?.lots.find(l => l.lotNumber === e.value.assignedLotNumber);
    if (!inventory) return;
    const labelInfoData = {
      isGLPRequest: this.order.requestTypeID === RequestTypes.GLP,
      netContents: e.value?.amountPerContainer,
      unitOfMeasureDesc: e.value?.unitOfMeasureDesc,
      manufacturerLotNumber: inventory.manufacturerLotNumber,
      lotNumber: inventory.lotNumber,
      manufactureDate: inventory.manufactureDate,
      expirationDate: inventory.expirationDate,
      recertificationDate: inventory.recertificationDate,
      requestNumber: this.order.requestNumber,
      requestedContainerLabel: this.orderItem.requestedContainerLabel
    }
    this.dialog.open(LabelInfoModalComponent, { data: labelInfoData })
      .afterClosed().subscribe((res) => {
      })
  }

  private unassignLots(e) {
    this.dialog.open(UnassignLotsModalComponent, { data: { lotNumber: e.value.assignedLotNumber, numberOfContainers: e.value.numberOfContainers, amountPerContainer: e.value.amountPerContainer, unitOfMeasureDesc: this.orderItem.unitOfMeasureDesc }, width: '800px' })
      .afterClosed().subscribe((res: number) => {
        if (!res) { return; }
        const payload: OrderItemContainerAssignRequest = {
          orderItemID: this.orderItem.orderItemID,
          numberOfContainers: res,
          requestedAmount: e.value.amountPerContainer,
          assignedInventoryCatalogID: this.orderItem.inventoryCatalogID,
          assignedLotNumber: e.value.assignedLotNumber,
          assignedManufacturerLotNumber: e.value.manufacturerLotNumber
        };
        this.loadingService.show('Loading...');
        this.orderService.unAssignOrderItemContainers(payload)
          .subscribe(res => {
            setTimeout(() => { this.loadingService.show('Loading...') }, 0);
            this.orderService.getOrderDetail(this.order.orderID)
              .subscribe(result => {
                this.loadingService.clearMessage()
                this.orderItem.containers = result.items.find(i => i.orderItemID == this.orderItem.orderItemID)?.containers;
                this.orderService.getOrderItemContainerAssignments(this.orderItem.orderItemID).subscribe(res => {
                  this.allContainersAssigned = res.every(_ => _.assignedLotNumber);
                  this.fetchData();
                })
              })
          });
      });
  }

  private openLotSelectionModal(e) {
    this.dialog.open(LotSelectionModalComponent,
      {
        width: '800px',
        data: {
          lotNumber: e.value.lotNumber,
          netAmountAvailable: e.value.netAmountAvailable,
          netAmountAvailableDisplay: e.value.netAmountAvailableDisplay,
          unitOfMeasureDesc: this.orderItem.unitOfMeasureDesc,
          orderItemContainerAssignmentResponse: this.unassignedOrderItemContainers,
          calculatedAmountAvailable: e.value.calculatedAmountAvailable,
          hasUserPermissionAndNoOrderReservation: e.value.hasUserPermissionAndNoOrderReservation,
          manufacturerLotNumber: e.value.manufacturerLotNumber
        }
      })
      .afterClosed().subscribe(res => {
        if (!res || res.length === 0) { return; }
        const requests: OrderItemContainerAssignRequest[] = [];
        res.forEach(r => {
          if (r.numberOfContainers === 0) { return; }
          const payload: OrderItemContainerAssignRequest = {
            orderItemID: this.orderItem.orderItemID,
            assignedInventoryCatalogID: this.orderItem.inventoryCatalogID,
            assignedLotNumber: e.value.lotNumber,
            numberOfContainers: r.numberOfContainers,
            requestedAmount: r.amountPerContainer,
            assignedManufacturerLotNumber: r.manufacturerLotNumber
          };
          requests.push(payload);
        });
        this.loadingService.show("Please wait...");
        this.orderService.assignOrderItemContainers(requests)
          .subscribe(res => {
            this.orderService.getOrderDetail(this.order.orderID)
              .subscribe(result => {
                this.loadingService.clearMessage();
                this.orderItem.containers = result.items.find(i => i.orderItemID == this.orderItem.orderItemID)?.containers;
                this.allContainersAssigned = this.orderItem.containers.every(_ => _.assignedLotNumber);
                this.fetchData();
              })
          });

      });
  }

  public openEditContainers(): void {
    this.dialog.open(EditContainersModalComponent, { data: { orderItem: this.orderItem, containers: this.fullOrderItemContainerResponse }, width: '800px', height: '540px' }).afterClosed().subscribe(res => {
      if (!res) { return; }

      const requests = [];

      const unassignedContainers = this.chosenLotsRowData.filter(r => !r.assignedLotNumber && !r.isDecanted);

      // Manage old containers
      unassignedContainers.forEach(row => {
        // check to see if there is an exact match of unassigned containers coming back from the dialog
        // we do not need to add/create containers if the row is an exact match
        const matchedAmountRequested = res.find(r => r.amountPerContainer === row.amountPerContainer);
        if (matchedAmountRequested) {
          const exactMatch = matchedAmountRequested.numberOfContainers === row.numberOfContainers;
          if (!exactMatch) {
            const difference = matchedAmountRequested.numberOfContainers - row.numberOfContainers;
            if (difference > 0) {
              // Create additional containers with this amount
              const payload: OrderItemContainerChangeRequest = {
                orderItemID: this.orderItem.orderItemID,
                numberOfContainers: difference,
                requestedAmount: matchedAmountRequested.amountPerContainer
              };
              requests.push(this.orderService.createOrderItemContainers(payload));
            }
            else {
              // Remove certain number of containers with this amount
              const payload: OrderItemContainerChangeRequest = {
                orderItemID: this.orderItem.orderItemID,
                numberOfContainers: Math.abs(difference),
                requestedAmount: matchedAmountRequested.amountPerContainer
              };
              requests.push(this.orderService.deleteOrderItemContainers(payload));
            }
          }
        } else {
          // delete all old containers with this amount if none come back from the dialog
          const payload: OrderItemContainerChangeRequest = {
            orderItemID: this.orderItem.orderItemID,
            numberOfContainers: row.numberOfContainers,
            requestedAmount: row.amountPerContainer
          };
          requests.push(this.orderService.deleteOrderItemContainers(payload));
        }
      });

      // Manage new containers from response
      res.forEach(row => {
        // We have already handled the returned row if there is are existing containers with the same amount
        // We only need to add new containers created from the dialog
        const matchedAmountRequested = unassignedContainers.find(r => r.amountPerContainer === row.amountPerContainer);
        if (!matchedAmountRequested) {
          // add all containers in response row
          const payload: OrderItemContainerChangeRequest = {
            orderItemID: this.orderItem.orderItemID,
            numberOfContainers: row.numberOfContainers,
            requestedAmount: row.amountPerContainer
          };
          requests.push(this.orderService.createOrderItemContainers(payload));
        }
      });
      this.loadingService.show('Loading..')
      forkJoin(requests)
        .pipe(finalize(() => this.loadingService.clearMessage()))
        .subscribe(() => {
          this.fetchData();
        });
    });

  }

  public onPrintGLPFlagLabel() {
    this.orderService.printGLPFlagLabel(this.orderItem.orderItemID).subscribe(() => {
      this.responseHandler.showSuccess('Label sent to printer', 5000);
    },
      error => {
        this.responseHandler.showError('Your print request could not be completed. Please contact support for assistance', 5000);
      });
  }

  // ATTACHMENT METHODS

  public createOrderItemAttachment(val: CreateOrderItemAttachmentRequest): void {
    this.orderItemAttachmentService.saveRequestItemAttachment(val.uploadedFiles, this.orderItem.orderItemID, val.documentTypeID, val.comments, val.languageID).subscribe((result) => {
      this.responseHandler.showSuccess(`File ${val.uploadedFiles[0].name} Uploaded successfully`, 5000);
      if (result) {
        this.attachmentEntity.attachments.push(result);
      }
      else
        this.responseHandler.showError(`Failed to fetch order item attachment details`, 5000);
    },
      error => {
        this.responseHandler.showError(error.message, 5000);
      });
  }

  public updateOrderItemAttachment(val: UpdateOrderItemAttachmentRequest): void {
    this.orderItemAttachmentService.updateOrderItemAttachment(val.orderItemAttachmentID, val).subscribe((result: OrderItemAttachment) => {
      const index = this.attachmentEntity.attachments.findIndex(a => a.orderItemAttachmentID === result.orderItemAttachmentID);
      this.attachmentEntity.attachments.splice(index, 1);
      this.attachmentEntity.attachments.push(result);
      this.attachmentEntity.attachments.sort((a, b) => a.filename > b.filename ? 1 : -1);
    },
      error => {
        this.confirmationService.close();
        this.responseHandler.showError(error.message, 5000);
      },
      () => {
        this.responseHandler.showSuccess(`Attachment ${val.filename} updated successfully`, 5000);
      });
  }

  public removeOrderItemAttachment(val): void {
    this.orderItemAttachmentService.deleteAttachment(val.attachment.orderItemAttachmentID).subscribe(res => {
      const index = this.attachmentEntity.attachments.findIndex(a => a.orderItemAttachmentID === val.attachment.orderItemAttachmentID);
      this.attachmentEntity.attachments.splice(index, 1);
    },
      error => this.responseHandler.showError(error.message, 5000),
      () => this.responseHandler.showSuccess(`Attachment ${val.attachment.filename} successfully removed`, 5000));
  }

  public downloadAttachment(val: OrderItemAttachment): void {
    this.orderItemAttachmentService.getOrderItemAttachmentList(val.orderItemAttachmentID).subscribe(response => {
      this.downloadAttachmentService.download(val.filename, response.fileContents);
    },
      error => {
        this.responseHandler.showError(error.message, 5000);
      });
  }

  refreshChemicalData() {
    this.orderService.getOrderDetail(this.order.orderID).pipe(take(1)).subscribe(data => {
      this._orderItem = data.items.filter(item => item.orderItemID == this.orderItem.orderItemID)[0];
      this.allContainersAssigned = this._orderItem.containers.every(_ => _.assignedLotNumber);
      this.totalAmount = 0;
      this.calculateTotalAmountRequested();
      this.getAttachmentListByOrderItemID();
      this.fetchData();
      this.getEarmarkData();
    });
  }

  preventExpansionChanged($event) {
    this.ePanel.expanded = !this.ePanel.expanded;
  }
}
