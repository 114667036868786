import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Tag } from 'src/app/models/tag';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { SPACE, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  private _tags: Tag[];
  @Input()
  public get tags(): Tag[] {
    return this._tags;
  }
  public set tags(v: Tag[]) {
    this._tags = v;
    this.clonedTags = JSON.parse(JSON.stringify(v));
  }

  clonedTags: Tag[];
  @Input() suggestions: Tag[];
  @Input() disableTags = false;
  tagCtrl = new FormControl();
  @ViewChild('tagInput', { static: true }) tagInput: ElementRef;
  @Output() tagsSaved = new EventEmitter<any>();
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;
  showNewTag: boolean = true;
  isDisabled: boolean = true;

  private changesSub: Subscription;

  constructor(private responseHandler: ResponseHandlingService) { }

  filteredTags: Observable<any[]>;
  separatorKeysCodes = [ENTER, SPACE];
  ngOnInit() {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => value ? this.filter(value) : this.suggestions.slice()));
  }

  filter(value: any) {
    if (!value) return;

    if (value instanceof Object)
      return this.suggestions.filter(tag =>
        tag.tagName.toLowerCase().includes(value.tagName.toLowerCase()));
    else {
      this.canShowNewTag(value);
      return this.suggestions.filter(tag =>
        tag.tagName.toLowerCase().includes(value.toLowerCase()));
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (this.canAddTag(value)) {
      this.tags.push({ tagID: 0, tagName: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = "";
      this.tagInput.nativeElement.value = "";
    }

    this.tagCtrl.setValue(null);
    this.controlSaveTags();
  }

  controlSaveTags() {
    if (this.clonedTags.some(t => !this.tags.find(ct => ct.tagID == t.tagID)) ||
      this.tags.some(t => !this.clonedTags.find(ct => ct.tagID == t.tagID)))
      this.isDisabled = false;
    else this.isDisabled = true;
  }

  canAddTag(tagName) {
    if (!tagName) return false;
    var found = this.tags.filter(x => x.tagName.toLowerCase() === tagName.toLowerCase());
    if (found && found.length > 0) return false;
    else return true;
  }

  remove(tag: any): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
    this.controlSaveTags();
  }

  ngOnDestroy() {
    if (this.changesSub) {
      this.changesSub.unsubscribe();
    }
  }

  canShowNewTag(input) {
    var listOfTagNames = this.suggestions.map(tag => tag.tagName);
    if (!listOfTagNames || !input) return;
    var fullMatchList = listOfTagNames.filter(name => name.toLowerCase() == input.toLowerCase());
    if (fullMatchList && fullMatchList.length > 0) this.showNewTag = false;
    else this.showNewTag = true;
  }

  onSaveTags() {
    this.isDisabled = true;
    this.tagsSaved.emit();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let tagName = '', isNewTag = false;
    if (event.option.viewValue.includes('New Tag')) {
      isNewTag = true;
      tagName = event.option.viewValue.replace('(New Tag)', '').trim();
    }
    else
      tagName = event.option.value.tagName;

    if (this.canAddTag(tagName)) {
      if (isNewTag) this.tags.push({ tagID: 0, tagName: tagName });
      else this.tags.push(event.option.value);
    }

    this.tagInput.nativeElement.value = "";
    this.tagCtrl.setValue(null);
    this.controlSaveTags();
  }

}
