import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { NavigationState } from '../models/navigation-state.model';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private $backButtonState: BehaviorSubject<NavigationState> = new BehaviorSubject({ visible: false, label: 'Back' });
  backButtonSubscription = this.$backButtonState.asObservable();
  public previousRoutePath = new BehaviorSubject<string>('');

  constructor(
    private router: Router,
    private location: Location
  ) {

    // ..initial prvious route will be the current path for now
    this.previousRoutePath.next(this.location.path());

    // on every route change take the two events of two routes changed(using pairwise)
    // and save the old one in a behavious subject to access it in another component
    // we can use if another component like intro-advertise need the previous route
    // because he need to redirect the user to where he did came from.
    this.router.events.pipe(
      filter(e => e instanceof RoutesRecognized),
      pairwise(),
    ).subscribe((event: any[]) => {
        this.previousRoutePath.next(event[0].urlAfterRedirects);
      });
  }
  setBackButton(state: NavigationState) {
    if (state != undefined) {
      state.label = state.label || 'Back';
      state.enabled = state.enabled != undefined ? state.enabled : true;
      state.visible = state.visible != undefined ? state.visible : false;
    }
    this.$backButtonState.next(state);
  }
}
