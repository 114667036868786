import { FormControl } from "@angular/forms";
import * as moment from "moment";

export enum DateCompareOperatorType {
    GreaterThan = 1,
    LessThan = 2,
    GreaterThanEqual = 3,
    LessThanEqual = 4
}

export function DateCompareValidator(operator: DateCompareOperatorType, fieldToCompareName: string): any {
    return (control: FormControl): { [key: string]: any } => {
        if (!control.parent) return null;
        const allControls = control.parent.controls;
        const fieldName = getControlName(control);
        if (allControls[fieldToCompareName]) {
            const val = moment(control.value, true);
            const compareValue = moment(allControls[fieldToCompareName].value, true);

            if (!val.isValid() || !compareValue.isValid()) return null;

            let compareResult: boolean = false;
            switch (operator) {
                case DateCompareOperatorType.GreaterThan:
                    compareResult = val.isAfter(compareValue);
                    break;
                case DateCompareOperatorType.LessThan:
                    compareResult = val.isBefore(compareValue);
                    break;
                case DateCompareOperatorType.GreaterThanEqual:
                    compareResult = val.isSameOrAfter(compareValue);
                    break;
                case DateCompareOperatorType.LessThanEqual:
                    compareResult = val.isSameOrBefore(compareValue);
                    break;
                default:
                    break;
            }

            if (!compareResult) {
                const errorPropertyName = `dateCompareInvalid_${fieldToCompareName}`;
                let error = {};
                error[errorPropertyName] = true;
                return error;
            } else {
                const errorFieldName = `dateCompareInvalid_${fieldName}`;
                if (allControls[fieldToCompareName].errors && allControls[fieldToCompareName].errors[errorFieldName]) {
                    const compareFieldError = allControls[fieldToCompareName].errors;
                    delete compareFieldError[errorFieldName];
                    if (Object.keys(compareFieldError).length === 0) {
                        allControls[fieldToCompareName].setErrors(null);
                    } else {
                        allControls[fieldToCompareName].setErrors(compareFieldError);
                    }
                }
            }




        } else {
            throw new Error(`${fieldToCompareName} is not a property of formgroup`);
        }

        return null;
    };
}

export const getControlName = (control: FormControl) => {
    var controlName = null;
    Object.keys(control.parent.controls).forEach((key: string) => {
        if (control === control.parent.controls[key]) {
            controlName = key;
        }
    });
    // we either found a name or simply return null
    return controlName;
}