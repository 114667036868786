import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-disposal-renderer',
  templateUrl: './disposal-renderer.component.html',
  styleUrls: ['./disposal-renderer.component.scss']
})
export class DisposalRendererComponent implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
  }

  refresh(): boolean {
    return false;
  }

  disposalReasonChange(e): void {
    this.params.node.setData({ ...this.params.node.data, selectedDisposalReason: e.value });
    this.params.onClick()
  }
}
