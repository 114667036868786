import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'move-button-cell',
  template: `<input type="number" step="0.0000001" min="0" style="width: 100%;" (blur)="valueChange($event)" [(ngModel)]="params.data[params.colDef.field]" />`,
  styles: [],
})


export class InputCellRenderer implements ICellRendererAngularComp {
  public params: any;
  private gridApi: any;

  oldValue: number;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
    this.oldValue = params.data[params.colDef.field];
  }

  valueChange(value: number) {
    const emitObject = {
      field: this.params.colDef.field,
      value
    };
    this.gridApi.dispatchEvent({ type: 'inputChangeEvent', value: emitObject });
  }

  refresh(): boolean {
    return false;
  }
}