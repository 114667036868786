import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Order, OrderStatuses } from 'src/app/models/order-models';
import * as navigation from '../data/navigation-status.json';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PermissionService } from 'src/app/services/permission-service/permission-service';

export enum Permissions {
  OverrideStatusRules = 1030
}

@Component({
  selector: 'app-change-status-modal',
  templateUrl: './change-status-modal.component.html',
  styleUrls: ['./change-status-modal.component.scss']
})
export class ChangeStatusModalComponent implements OnInit {

  isDisable: boolean = false;
  orderStatuses: any[] = [];
  navigationStatuses: any;
  navigationMatrix: any = (navigation as any).default;
  hasOverridenPermission: boolean = true;
  changeStatusForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ChangeStatusModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private permissionService: PermissionService) {
    if (data.order.orderStatusID == 5 || data.order.orderStatusID == 2) {
      this.navigationStatuses = this.navigationMatrix.navigation.find(nm => nm.name == data.order.orderStatusDesc).statuses;
    }
    else {
      this.isDisable = true;
    }
    let orderStatuses = []
    Object.keys(OrderStatuses).filter(key => !isNaN(Number(OrderStatuses[key])))?.forEach(key => {
      if (OrderStatuses[key] != OrderStatuses.Ready && OrderStatuses[key] != OrderStatuses.Warehouse && OrderStatuses[key] != OrderStatuses.Cancelled)
        orderStatuses.push({ Description: key, OrderStatusID: OrderStatuses[key] })
    })
    this.orderStatuses = orderStatuses;
  }

  ngOnInit(): void {
    this.buildForm();
    this.hasOverridenPermission = this.permissionService.validateUserPermission(Permissions.OverrideStatusRules);
  }

  onSelectionChange(event) {
    if (this.changeStatusForm.controls.overrideStatusRules.value || this.data.order.orderStatusID == 5 || this.data.order.orderStatusID == 2) {
      this.isDisable = false;
    } else {
      this.isDisable = true;
    }

    if (this.changeStatusForm.controls.overrideStatusRules.value)
      this.navigationStatuses = this.orderStatuses?.filter(nm => nm.Description != this.data.order.orderStatusDesc);
    else {
      this.changeStatusForm.controls.statusId.reset(null);
      this.navigationStatuses = this.navigationMatrix.navigation.find(nm => nm.name == this.data.order.orderStatusDesc).statuses;
      this.changeStatusForm.controls.statusId.setErrors(null);
    }

  }

  onSubmit(): void {
    this.dialogRef.close(this.changeStatusForm.value);
  }

  private buildForm() {
    this.changeStatusForm = new FormGroup({
      orderId: new FormControl(this.data.order.orderID),
      statusId: new FormControl(null, Validators.required),
      reasonToChange: new FormControl(null, Validators.required),
      overrideStatusRules: new FormControl(false)
    });
  }

}
