import { GridApi } from 'ag-grid-community';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateFormatPipe } from 'src/app/helpers/date-format-pipe';

@Component({
  selector: 'app-tracking-details-modal',
  templateUrl: './tracking-details-modal.component.html',
  styleUrls: ['./tracking-details-modal.component.scss'],
})
export class TrackingDetailsModalComponent {
  public gridData;
  public columnDefs;
  private gridApi: GridApi;

  constructor(public dialogRef: MatDialogRef<TrackingDetailsModalComponent>, @Inject(MAT_DIALOG_DATA) public data, public dateFormat: DateFormatPipe) {
    this.columnDefs = [
      {
        headerName: 'Date',
        minWidth: 200,
        maxWidth: 200,
        cellRenderer: (data) => {
          return dateFormat.transform(data.data.logDateTime)
        }
      },
      {
        headerName: 'Description',
        field: 'description',  
      },
      {
        headerName: 'Location',
        field: 'location',
        minWidth: 250,
        maxWidth: 250
      }
    ];

    this.gridData = data.data;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onResizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onTrackPackage(): void {
    if (this.data.package.trackingNumber) {
      let url = this.data.package.trackingURLFormat.replace('{0}', this.data.package.trackingNumber);
      window.open(url, '_blank');
    }
    if (event) {
      event.stopPropagation();
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
