import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/base-component';
import { sortOrdersByUpdatedDate } from 'src/app/helpers/filter-helpers';
import { DecantStation } from 'src/app/models/decant-models';
import { Order, OrderListRequest, OrderStatuses } from 'src/app/models/order-models';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';

interface OrderPackingDisplay {
  orderID: string;
  isOnHold: boolean;
  requestNumber: string;
  containers: Array<RowPackingDisplay>;
  isOnSiteOrder: boolean;
  isOnSiteText: string;
}

interface RowPackingDisplay {
  requestedContainerLabel: string;
  assignedLotNumber: string;
  numberOfContainers: number;
  isCold: boolean;
  isDesiccant: boolean;
}

@Component({
  selector: 'app-packing',
  templateUrl: './packing.component.html',
  styleUrls: ['./packing.component.scss']
})
export class PackingComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild('picksheet') private picksheetRef: ElementRef;
  public packingOrders: Order[];
  public packingOrdersLoaded: boolean;
  public picksheetInput: string;
  public stations: DecantStation[];
  public selectedStation: DecantStation;
  displayOrders: OrderPackingDisplay[] = [];

  constructor(private router: Router,
    private responseHandler: ResponseHandlingService,
    private orderService: OrderService) {
    super();
    const packingOrdersRequest: OrderListRequest = { includeItems: true, orderStatusID: OrderStatuses.Shipping };
    this.orderService.retrieveList(packingOrdersRequest).subscribe((data: Order[]) => {
      const sortedOrders = sortOrdersByUpdatedDate(data);
      this.packingOrders = sortedOrders.reverse();
      this.packingOrdersLoaded = true;
      this.buildDisplayData();
    });
    if (localStorage.getItem("packingStation")) {
      this.selectedStation = JSON.parse(localStorage.getItem("packingStation"));
      const defaultWarehouseID = JSON.parse(localStorage.getItem("defaultWarehouseID"));
      if (this.selectedStation && this.selectedStation?.warehouseID !== defaultWarehouseID) {
        this.selectedStation = null;
        localStorage.setItem("packingStation", null);
      }
    };
  }

  ngOnInit(): void { }

  buildDisplayData(): void {
    const _orders: OrderPackingDisplay[] = [];
    for (const order of this.packingOrders) {
      _orders.push({
        orderID: order.orderID,
        isOnHold: order.isOnHold,
        requestNumber: order.requestNumber,
        containers: [],
        isOnSiteOrder: order?.isOnSiteOrder,
        isOnSiteText: order?.isOnSiteText
      });

      for (const item of order.items) {
        for (const container of item.containers) {
          const currentOrder = _orders.find(o => o.orderID === order.orderID);
          const matchingContainer = currentOrder.containers.find(d => d.requestedContainerLabel === item.requestedContainerLabel && d.assignedLotNumber === container.assignedLotNumber);
          if (matchingContainer) {
            matchingContainer.numberOfContainers++;
            if (!matchingContainer.isCold) {
              matchingContainer.isCold = container.locationName?.toLowerCase().includes('refrigerated') || container.locationName?.toLowerCase().includes('freezer');
            }
            if (!matchingContainer.isDesiccant) {
              matchingContainer.isDesiccant = container.locationName?.toLowerCase().includes('desiccant');
            }
          } else {
            currentOrder.containers.push({
              requestedContainerLabel: item.requestedContainerLabel,
              assignedLotNumber: container.assignedLotNumber,
              numberOfContainers: 1,
              isCold: container.locationName?.toLowerCase().includes('refrigerated') || container.locationName?.toLowerCase().includes('freezer'),
              isDesiccant: container.locationName?.toLowerCase().includes('desiccant')
            });
          }
        }
      }
    }
    this.displayOrders = _orders;
  }

  onStationChange(station: DecantStation): void {
    if (station) {
      localStorage.setItem('packingStation', JSON.stringify(station));
      this.selectedStation = station;
      setTimeout(() => {
        document.getElementById('picksheetField').focus();
      }, 100);
    } else {
      this.selectedStation = null;
    }
  }

  onPicksheetInput(event) {
    const input = event.target.value.toLowerCase();
    if (input && input.length === 36) {
      if (this.verifyGuid(input) && this.isValidOrderID(input)) {
        this.openPackingDetail(input);
      } else {
        this.responseHandler.showError("Request ID Invalid");
        this.picksheetInput = "";
      }
    }
    else {
      if (this.picksheetInput && this.picksheetInput.length > 0) {
        this.responseHandler.showError("Request ID Invalid");
      }
      this.picksheetInput = "";
    }
  }

  openPackingDetail(orderID: string) {
    if (this.selectedStation) {
      this.router.navigateByUrl(`packing/request/${orderID}`);
    } else {
      this.responseHandler.showError("Please select warehouse and station before selecting a request.");
    }
  }

  sumOrderContainers(order: OrderPackingDisplay) {
    let sum = 0;
    order.containers.forEach(c => sum += c.numberOfContainers);
    return sum;
  }

  isValidOrderID(orderID: string) {
    var orderMatch = this.packingOrders.find(order => order.orderID === orderID);
    return orderMatch && orderMatch.orderID === orderID;
  }

  clearField(event) {
    if (event.target.id === "picksheetField") {
      this.picksheetInput = "";
    }
  }

  handleCellClicked(event) {
    if (event.column.colDef.headerName === "Request ID" && event.data.requestID) {
      let order = this.packingOrders.filter(o => o.requestNumber === event.data.requestID);
      if (order.length > 0) {
        this.router.navigate([`packing/request/${order[0].orderID}`]);
      }
    }
  }

  verifyGuid(input: string): boolean {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(input);
  }

  ngAfterViewInit(): void {
    this.picksheetRef.nativeElement.focus();
  }
}
