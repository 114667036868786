import { WarehouseStationComponent } from './components/warehouse-station/warehouse-station.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PillComponent } from '../shared/components/pill/pill.component'
import { MessageService } from '../shared/services/message-queue.service';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ChangeWarehouseStationComponent } from './components/change-warehouse-station/change-warehouse-station.component';
import { DestinationGuideInfoModalComponent } from './components/destination-guide-info-modal/destination-guide-info-modal.component';
import { MatCardModule } from '@angular/material/card';
import { TreeModule } from 'primeng/tree';
import { LocationsTreeViewComponent } from './components/locations-tree-view/locations-tree-view.component';

@NgModule({
  declarations: [
    PillComponent,
    WarehouseStationComponent,
    ChangeWarehouseStationComponent,
    DestinationGuideInfoModalComponent,
    LocationsTreeViewComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    TreeModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    PillComponent,
    WarehouseStationComponent,
    LocationsTreeViewComponent
  ],
  providers: [
    MessageService
  ]

})
export class SharedModule { }
