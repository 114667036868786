import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CancelOrder, CancelReason } from 'src/app/models/order-models';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-confirm-cancel-order-modal',
  templateUrl: './confirm-cancel-order-modal.component.html',
  styleUrls: ['./confirm-cancel-order-modal.component.scss']
})
export class ConfirmCancelOrderModalComponent implements OnInit {

  comment: string = "";
  orderID: string;
  errorMessage: string;
  cancelOrder: FormGroup;
  selectedCancelReasonId: number;
  displayFormField: boolean = false;
  displayErrorMessage: boolean = false;
  cancelReasons: CancelReason[];
  isFormSubmitted = false;

  constructor(public dialogRef: MatDialogRef<ConfirmCancelOrderModalComponent>, private orderService: OrderService, @Inject(MAT_DIALOG_DATA) public data: any, private toastr: ResponseHandlingService, private router: Router) {
    this.orderID = data.orderID;
  }

  ngOnInit(): void {
    this.orderService.getCancellationReasons().subscribe(resp => this.cancelReasons = resp);
    this.cancelOrder = new FormGroup({
      reason: new FormControl('', [Validators.required]),
      comment: new FormControl('')
    });
  }

  get fc() {
    return this.cancelOrder.controls;
  }

  onCancel(): void {
    this.isFormSubmitted = true;
    const cancelOrder: CancelOrder = {
      cancelReasonID: this.getCancelReasonID(this.cancelOrder.controls["reason"].value),
      comment: this.cancelOrder.controls["reason"].value != "Other" ? null : this.cancelOrder.controls["comment"].value
    }
    this.orderService.cancelOrder(this.orderID, cancelOrder).subscribe(
      () => {
        this.dialogRef.close(true);
        this.router.navigate(['requests/prep']);
        this.toastr.showSuccess('Order is cancelled');
      },
      () => {
        this.toastr.showError('An error occured. Try again later');
      }
    );
  }

  setErrors() {
    this.isFormSubmitted = true;
    if (this.cancelOrder.controls["comment"].value.length === 0 && this.cancelOrder.controls["reason"].value === "Other") {
      this.cancelOrder.controls["comment"].setErrors({ 'required': 'true' });
      this.cancelOrder.updateValueAndValidity();
    }
  }

  onReasonChange() {
    if (this.cancelOrder.controls["reason"].value != "Other") {
      this.cancelOrder.controls["comment"].patchValue(" ");
      this.cancelOrder.updateValueAndValidity();
    } else {
      this.cancelOrder.controls["comment"].patchValue("");
      this.cancelOrder.controls["comment"].setErrors({ 'required': 'true' });
      this.cancelOrder.updateValueAndValidity();

    }
  }

  getCancelReasonID(reason: string): number {
    return this.cancelReasons.find(x => x.name === reason).reasonID;
  }
}
