<div>
    <h4>Pick Verification</h4>
    <div class="spacer"></div>
    <label for="picksheetField">Scan Picklist barcode when picking is complete</label>
    <input #picksheet id="picksheetField" type="text" [(ngModel)]="picksheetInput"
        style="margin-left:10px;margin-right:10px;" pInputText (blur)="onPicksheetInput($event)"
        (click)="clearField($event)" placeholder="Scan Picklist">
    <div class="spacer"></div>

    <div class="wrapper" *ngIf="pickingOrdersLoaded">
        <div class="table" *ngFor="let order of displayOrders">
            <div class="row header">
                <div class="cell">
                    <span>Request ID: {{ order.requestNumber }}</span>
                </div>
                <div class="cell">
                </div>
                <div class="cell on-hold">
                    <span style="cursor: pointer;" class="p-ml-3 cell" *ngIf="order?.isOnHold">
                        <app-pill Text="On Hold" Type="OnHold"></app-pill>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="cell left bolder" data-title="Chemical" style="font-weight: bolder">
                    Chemical
                </div>
                <div class="cell left bolder" data-title="AssignedLotNumber" style="font-weight: bolder">
                    Lot Number
                </div>
                <div class="cell right bolder" data-title="TotalAmountNeeded" style="font-weight: bolder">
                    Total Amount Requested
                </div>
            </div>
            <div class="row" *ngFor="let container of order.containers">
                <div class="cell left" data-title="">
                    {{ container.containerLabelFmt }}
                </div>
                <div class="cell left" data-title="AssignedLotNumber">
                    {{ container.assignedLotNumber }}
                </div>
                <div class="cell right" data-title="TotalAmountNeeded">
                    {{ container.totalAmountRequestedDisplay }}
                </div>
            </div>
        </div>
    </div>

</div>