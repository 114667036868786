import { P2PResponse } from './../../peer-to-peer/models/peer.models';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Order, RequestTypes } from 'src/app/models/order-models';
import { OrderService } from 'src/app/services/order-service/order-service';
import { PeerToPeerService } from 'src/app/peer-to-peer/services/peer-to-peer.service';
import { CustomsInvoiceModalComponent } from '../customs-invoice-modal/customs-invoice-modal.component';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';

@Component({
  selector: 'app-request-documents-modal',
  templateUrl: './request-documents-modal.component.html',
  styleUrls: ['./request-documents-modal.component.scss']
})
export class RequestDocumentsModalComponent implements OnInit {

  order: any;
  isP2P: false;
  downloadedFileName: string = "";
  documents: any[];
  documentsOrder: any[] = [
    { name: 'Packing List' },
    { name: 'Customs Invoice' },
    { name: 'Test Substance Distribution Certificate' },
    { name: 'Test Substance Tracking Form' }
  ]
  documentsP2P: any[] = [
    { name: 'Packing List' },
    { name: 'Customs Invoice' }
  ]
  constructor(public dialogRef: MatDialogRef<RequestDocumentsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private orderService: OrderService, private peerToPeerService: PeerToPeerService, private dialog: MatDialog, private loadingService: LoadingService) {
    this.order = this.data?.order;
    this.isP2P = this.data?.isP2P;
    if (this.isP2P)
      this.documents = this.documentsP2P
    else
      this.documents = this.documentsOrder;
  }

  ngOnInit(): void {
  }

  confirm(): void {
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close(this.downloadedFileName);
  }

  canDisable(document: { name: string; }) {
    if (document.name === 'Test Substance Distribution Certificate')
      return !(this.isOrderDecanted(this.order) && this.order.requestTypeID === RequestTypes.GLP)
    else if (document.name === 'Test Substance Tracking Form')
      return this.order.requestTypeID != RequestTypes.GLP
    else
      return false;
  }

  isOrderDecanted(order: Order): boolean {
    let result = true;
    order.items.forEach(item => {
      item.containers.forEach(container => {
        if (!container.decanted) {
          result = false;
        }
      });
    });
    return result;
  }

  onGenerateDocument(document: { name: string; }) {
    if (!this.order) return;

    if (document.name === 'Packing List' && !this.isP2P) {
      this.loadingService.show('Generating document...');
      this.orderService.getPackingListContent(this.order.orderID).subscribe(response => {
        if (response) {
          this.loadingService.clearMessage();
          this.downloadedFileName = document.name;
          this.download(response, `PackingList_${this.order.requestNumber}.docx`);
        }
      })
    }
    else if (document.name === 'Packing List' && this.isP2P) {
      this.loadingService.show('Generating document...');
      this.peerToPeerService.getPackingListContent(this.order.requestID).subscribe(response => {
        if (response) {
          this.loadingService.clearMessage();
          this.downloadedFileName = document.name;
          this.download(response, `PackingList_${this.order.sampleShopRequestNumber}.docx`);
        }
      })
    } else if (document.name === 'Test Substance Tracking Form') {
      this.loadingService.show('Generating document...');
      this.orderService.getTestSubstanceTrackingContent(this.order.orderID).subscribe(response => {
        if (response) {
          this.loadingService.clearMessage();
          this.downloadedFileName = document.name;
          this.download(response, `TestSubstanceTrackingForm_${this.order.requestNumber}.docx`);
        }
      })
    } else if (document.name === 'Customs Invoice' && !this.isP2P) {
      this.dialog.open(CustomsInvoiceModalComponent, {
        data: { order: this.order },
        disableClose: true,
        autoFocus: false
      }).afterClosed().subscribe((res) => {
        if (res) {
          this.confirm();
        }
      })
    } else if (document.name === 'Customs Invoice' && this.isP2P) {
      this.loadingService.show('Generating document...');
      this.peerToPeerService.getCustomInvoice(this.order.requestID).subscribe(response => {
        if (response) {
          this.loadingService.clearMessage();
          this.downloadedFileName = document.name;
          this.download(response, `Invoice_${this.order.sampleShopRequestNumber}.docx`);
        }
      })
    } else if (document.name === 'Test Substance Distribution Certificate') {
      this.loadingService.show('Generating document...');
      this.orderService.getSubstanceDistribution(this.order.orderID).subscribe(response => {
        if (response) {
          this.loadingService.clearMessage();
          this.downloadedFileName = document.name;
          this.download(response, `TestSubstanceDistributionCertificate_${this.order.requestNumber}.docx`);
        }
      })
    }
  }

  download(base64String, fileName) {
    const source = `data:application/msword;base64,${base64String}`;
    var link = document.createElement('a');
    link.href = source;
    link.download = fileName;
    link.click();
  }

}
