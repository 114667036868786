import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BreadCrumbModule } from '../core/modules/breadcrumb/bread-crumb.module';
import { DataEntryComponent } from './data-entry/data-entry.component';
import { ReceivingComponent } from './receiving.component';
import { receivingConstants, GetRouterUrl } from './receiving.constants';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchComponent } from './search/search.component';
import { ValidationComponent } from './validation/validation.component';
import { WeighingComponent } from './weighing/weighing.component';
import { DocUploadComponent } from './doc-upload/doc-upload.component';
import { LabelComponent } from './label/label.component';
import { PreviousSessionsComponent } from './previous-sessions/previous-sessions.component';
import { DirtyCheckGuard } from './guards/dirty-check-guard';
export const routes: Routes = [
  {
    path: '', component: ReceivingComponent,
    children: [
      {
        path: '', component: SearchComponent,
        data: {
          title: 'Receiving',
          breadcrumb: {
            reset: true,
            label: 'Search'
          }
        }
      },
      {
        path: GetRouterUrl(receivingConstants.Routes.MaterialSelection), component: SearchResultsComponent,
        data: {
          title: 'Receiving',
          breadcrumb: {
            label: 'Material Selection'
          }
        }
      },
      {
        path: GetRouterUrl(receivingConstants.Routes.Validate), component: ValidationComponent,
        data: {
          title: 'Receiving',
          breadcrumb: {
            label: 'Validate'
          }
        }
      },
      {
        path: GetRouterUrl(receivingConstants.Routes.DataEntry), component: DataEntryComponent, canDeactivate: [DirtyCheckGuard],
        data: {
          title: 'Receiving',
          breadcrumb: {
            label: 'Data Entry'
          }
        }
      },
      {
        path: `${GetRouterUrl(receivingConstants.Routes.DataEntry)}/:id`, component: DataEntryComponent, canDeactivate: [DirtyCheckGuard],
        data: {
          title: 'Receiving',
          breadcrumb: {
            label: 'Data Entry'
          }
        }
      },
      {
        path: GetRouterUrl(receivingConstants.Routes.Weighing), component: WeighingComponent, canDeactivate: [DirtyCheckGuard],
        data: {
          title: 'Receiving',
          breadcrumb: {
            label: 'Weighing'
          }
        }
      },
      {
        path: `${GetRouterUrl(receivingConstants.Routes.Weighing)}/:id`, component: WeighingComponent, canDeactivate: [DirtyCheckGuard],
        data: {
          title: 'Receiving',
          breadcrumb: {
            label: 'Weighing'
          }
        }
      },
      {
        path: GetRouterUrl(receivingConstants.Routes.DocUpload), component: DocUploadComponent, canDeactivate: [DirtyCheckGuard],
        data: {
          title: 'Receiving',
          breadcrumb: {
            label: 'Doc Upload'
          }
        }
      },
      {
        path: `${GetRouterUrl(receivingConstants.Routes.DocUpload)}/:id`, component: DocUploadComponent, canDeactivate: [DirtyCheckGuard],
        data: {
          title: 'Receiving',
          breadcrumb: {
            label: 'Doc Upload'
          }
        }
      },
      {
        path: GetRouterUrl(receivingConstants.Routes.Submit), component: LabelComponent,
        data: {
          title: 'Receiving',
          breadcrumb: {
            label: 'Submit'
          }
        }
      },
      {
        path: `${GetRouterUrl(receivingConstants.Routes.Label)}/:id`, component: LabelComponent,
        data: {
          title: 'Receiving',
          breadcrumb: {
            label: 'Label'
          }
        }
      },
      {
        path: GetRouterUrl(receivingConstants.Routes.PreviousSessions), component: PreviousSessionsComponent,
        data: {
          title: 'Previous Receiving Sessions',
          breadcrumb: {
            reset: true,
            label: 'Previous Receiving Session'
          }
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule,
    BreadCrumbModule
  ]
})
export class ReceivingRoutingModule { }
