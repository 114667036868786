<h4>Reweigh Parent Container</h4>
<div class="p-grid">
    <div class="p-col-6">
        <app-warehouse-station (selectedStation)="onStationChange($event)"></app-warehouse-station>
        <div class="spacing">
            <label for="barcodeField">Scan Parent Container</label>
            <input id="barcodeField" type="text" class="p-ml-2" placeholder="Barcode Entry" [(ngModel)]="barcodeInput"
                pInputText autofocus (blur)="onBarcodeInput($event)" (click)="resetInput()">
        </div>
        <!-- this extra div allows us to keep focus within the document after tabbing out of the barcode field  -  allowing us to refocus in the input if needed -->
        <div tabindex="0"></div>
        <ag-grid-angular *ngIf="inventoryItem" #roleGrid style="width:99.8%;margin-top: 20px;margin-bottom: 20px"
            class="ag-theme-alpine" domLayout="autoHeight" suppressHorizontalScroll="true" [rowData]="rowData"
            [columnDefs]="columnDefs" [tooltipShowDelay]="1500" (gridSizeChanged)="resizeGrid($event)"
            (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>

    <div class="p-col-6">
        <ng-container *ngIf="(view$ | async) as viewDetails">
            <pre> {{ viewDetails.currentStation | json }} </pre>
            <app-scale-control
                [decantStation]="viewDetails.currentStation"
                [selectedInventoryItem]="viewDetails.inventoryItem"
                [selectedStation]="viewDetails.currentStation"
                [isReweighMode]="true"
                [unitOfMeasure]="viewDetails.inventoryItem?.unitOfMeasureDesc"
                [mode]="viewDetails.scaleMode"
                (weightCaptured)="handleWeightCaptured()">
            </app-scale-control>
        </ng-container>
      <button class="large" mat-raised-button (click)="getStation()" color="cor-primary">Get Scale Weights</button>
    </div>
</div>
