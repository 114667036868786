import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss']
})
export class PillComponent implements OnInit {

  @Input('Text') text: string;
  @Input('Type') type: string;
  @Input('HasPointer') hasPointer: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
