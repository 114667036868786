<div class="flex-grid">
  <div class="col">
      <h4>Scale Test Utility</h4>
  </div>
</div>

<app-warehouse-station (selectedStation)="onStationChange($event)"></app-warehouse-station>

<div *ngIf="station" style="margin-top: 40px;">
  <div *ngIf="scalesReady && selectedContainer">
      <app-scale-control2 [decantStation]="station" [selectedContainer]="selectedContainer"
          [selectedInventoryItem]="selectedInventoryItem" [isReweighMode]="false" [unitOfMeasure]="'kg'"
          (weightCaptured)="handleWeightCaptured($event)" (getStation)="getStation()" [isTestMode]="true">
      </app-scale-control2>
  </div>
</div>
