import { GridOptions } from 'ag-grid-community';
import { Component, OnInit } from '@angular/core';
import { sortData } from 'src/app/helpers/grid-helpers';
import { map, switchMap, take, finalize } from 'rxjs/operators';
import { ScalesModel } from 'src/app/receiving/models/scales.model';
import { ScaleService } from '../../services/scale-service/scale.service';
import { PaginatorModel } from 'src/app/core/modules/ag-grid/components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';
import { MessageService as MessageServiceQueue} from 'src/app/shared/services/message-queue.service';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { ManageScalesActionsComponent } from './manage-scales-actions/manage-scales-actions.component';
import { EditScaleModalComponent } from './edit-scale-modal/edit-scale-modal.component';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';

@Component({
  selector: 'app-manage-scales',
  templateUrl: './manage-scales.component.html',
  styleUrls: ['./manage-scales.component.scss']
})
export class ManageScalesComponent implements OnInit {
  private gridApi;
  private scales: ScalesModel[];

  public gridOptions: GridOptions;
  public disableClearFilters: boolean = true;
  public paginatorModel: PaginatorModel;
  public hasFilterModelChanged: boolean = true;
  public frameworkComponents: any;
  public overlayNoRowsTemplate: string =
    '<span class="ag-overlay-loading-center">No scales found.</span>';

  constructor(
    private dialog: MatDialog,
    private readonly scaleService: ScaleService,
    private readonly loadingService: LoadingService,
    private readonly messageService: MessageService,
    private readonly messageServiceQueue: MessageServiceQueue,

  ) {
    this.frameworkComponents = {
      manageScaleActions: ManageScalesActionsComponent,
    };
  }

  columnDefs = [
    {
      headerName: 'Station',
      field: 'stationName',
      headerTooltip: 'Station',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.stationName;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Name',
      field: 'name',
      headerTooltip: 'Name',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.name;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      width: 100,
      cellRenderer: 'manageScaleActions',
      cellClass: ['button-edit', 'display-end'],
      cellRendererParams: { data: this },
    },
  ];

  ngOnInit(): void {
    this.paginatorModel = {
      totalRows: 0,
      totalPages: 0,
      pageSize: 25,
      currentPage: 1,
      leftArrowsDisabled: true,
      rightArrowsDisabled: true,
    };
    this.gridOptions = {};

    this.messageServiceQueue.message
      .pipe(
        switchMap((data) => {
          if (data.data=='delete') {
            this.loadingService.show("Loading Scales...");
            return this.scaleService.deleteScale(
              data.reference.scaleID
            );
          }
          else if (data.data=='edit') {
            this.loadingService.show("Loading Scales...");
            return this.scaleService.editScale(
              data.reference
            );
          }
        }),
        switchMap((data) => {
          if(data=="error")
            return of(null);

          if(data == null) {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Scale deleted successfully',
            });
          }
          else {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Scale saved successfully',
            });
          }

          return this.scaleService.getScales()
        }),
        map((scales) => {
          this.bindGridData(scales);
        }),
        finalize(() => {
          this.loadingService.clearMessage();
        })
      )
      .subscribe((data)=> {  
        this.loadingService.clearMessage()
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    this.loadingService.show("Loading Scales...");
    this.scaleService
      .getScales()
      .pipe(
        take(1),
        map((scales) => {
          this.bindGridData(scales);
        }),
        finalize(() => {
          this.loadingService.clearMessage();
        })
      )
      .subscribe();
  }

  onResizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  updateCurrentPage(): void {
    this.paginatorModel.currentPage = 1;
    this.paginatorModel.leftArrowsDisabled = true;
    if (this.paginatorModel.totalPages > 1) {
      this.paginatorModel.rightArrowsDisabled = false;
    }
  }

  handlePaginatorCommand(event: number): void {
    switch (event) {
      case 1:
        this.gridApi.paginationGoToFirstPage();
        break;
      case 2:
        this.gridApi.paginationGoToPreviousPage();
        break;
      case 3:
        this.gridApi.paginationGoToNextPage();
        break;
      case 4:
        this.gridApi.paginationGoToLastPage();
        break;
      default:
        this.gridApi.paginationSetPageSize(event);
        this.gridApi.paginationGoToFirstPage();
        break;
    }
  }

  setDataSource(returnedData) {
    this.gridApi.purgeInfiniteCache();
    this.bindGridData(returnedData);
  }

  onAddScale(){
    this.dialog
      .open(EditScaleModalComponent, { width: '414px', data: null })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.loadingService.show("Loading Scales...");
          delete dialogResult.scaleID;
          this.scaleService.editScale(dialogResult).pipe(
            switchMap((data) => {
              if(data=="error")
                return of(null);
    
              if(data != null) {             
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Scale saved successfully',
                });
              }
    
              return this.scaleService.getScales()
            }),
            map((scales) => {
              this.bindGridData(scales);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          ).subscribe(_=>{
            this.loadingService.clearMessage();
          });
        }
      });
  }

  private bindGridData(data): void {
    var dataSource = {
      rowCount: null,
      getRows: function (params) {
        setTimeout(function () {
          var dataAfterSorting = sortData(params.sortModel, data);
          var rowsThisPage = dataAfterSorting.slice(
            params.startRow,
            params.endRow
          );
          var lastRow = dataAfterSorting.length;
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      },
    };

    this.paginatorModel.totalRows = data.length;
    this.paginatorModel.totalPages = Math.ceil(
      data.length / this.paginatorModel.pageSize
    );

    if (this.paginatorModel.totalRows == 0) {
      this.gridApi.setDatasource(dataSource);
      this.gridApi.showNoRowsOverlay();
    } else {
      this.updateCurrentPage();
      this.gridApi.hideOverlay();
      this.gridApi.setDatasource(dataSource);
    }
  }
}
