import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TriggerService {
    message$ = new Subject<Trigger>();
    
    sendConfirmation(messageData: Trigger) {
        this.message$.next(messageData);
    }
}

export interface Trigger{
    route:string;
    confirm: boolean;
}