import { finalize, map, switchMap, take, tap } from 'rxjs/operators'
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { GridOptions } from 'ag-grid-community';
import { PaginatorModel } from 'src/app/core/modules/ag-grid/components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';
import { MessageService } from 'src/app/shared/services/message-queue.service';
import { sortData } from 'src/app/helpers/grid-helpers';
import { ManageUserActionComponent } from './manage-user-action/manage-user-action.component';
import { UserService } from 'src/app/services/user-service/user-service.service';

@Component({
  selector: 'app-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.scss']
})
export class UserManagerComponent implements OnInit {
  private gridApi;
  public gridOptions: GridOptions;
  public disableClearFilters: boolean = true;
  public paginatorModel: PaginatorModel;
  public hasFilterModelChanged: boolean = true;
  public frameworkComponents: any;
  public overlayNoRowsTemplate: string =
    '<span class="ag-overlay-loading-center">No users found.</span>';

  constructor(
    private dialog: MatDialog,
    private readonly messageService: MessageService,
    private loadingService: LoadingService,
    private readonly userService: UserService
  ) {
    this.frameworkComponents = {
      editUsersAction: ManageUserActionComponent,
    };
  }

  columnDefs = [
    {
      headerName: 'Name',
      field: 'firstName',
      headerTooltip: 'First Name',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return `${ params.data.lastName }, ${ params.data.firstName}`;
        }
      },
      valueGetter: function(params){
        if (params.data !== undefined) {
          return `${ params.data.lastName }, ${ params.data.firstName}`;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Email',
      field: 'email',
      headerTooltip: 'Email',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.email;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Job Title',
      field: 'jobTitle',
      headerTooltip: 'Job Title',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.jobTitle;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      width: 100,
      cellRenderer: 'editUsersAction',
      cellClass: ['button-edit', 'display-end'],
      cellRendererParams: { data: this },
    },
  ];

  ngOnInit(): void {
    this.paginatorModel = {
      totalRows: 0,
      totalPages: 0,
      pageSize: 25,
      currentPage: 1,
      leftArrowsDisabled: true,
      rightArrowsDisabled: true,
    };
    this.gridOptions = {};

    this.messageService.message
      .pipe(
        switchMap(() => {
          return this.userService.getUsers();
        }),
        map((users) => {
          this.bindGridData(users);
        })
      )
      .subscribe(() => {
        this.loadingService.clearMessage();
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    this.loadingService.show("Loading Users...");
    this.userService
      .getUsers()
      .pipe(
        take(1),
        map((users) => {
          this.bindGridData(users);
        }),
        finalize(() => {
          this.loadingService.clearMessage();
        })
      )
      .subscribe();
  }

  onResizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  updateCurrentPage(): void {
    this.paginatorModel.currentPage = 1;
    this.paginatorModel.leftArrowsDisabled = true;
    if (this.paginatorModel.totalPages > 1) {
      this.paginatorModel.rightArrowsDisabled = false;
    }
  }

  handlePaginatorCommand(event: number): void {
    switch (event) {
      case 1:
        this.gridApi.paginationGoToFirstPage();
        break;
      case 2:
        this.gridApi.paginationGoToPreviousPage();
        break;
      case 3:
        this.gridApi.paginationGoToNextPage();
        break;
      case 4:
        this.gridApi.paginationGoToLastPage();
        break;
      default:
        this.gridApi.paginationSetPageSize(event);
        this.gridApi.paginationGoToFirstPage();
        break;
    }
  }

  setDataSource(returnedData) {
    this.gridApi.purgeInfiniteCache();
    this.bindGridData(returnedData);
  }

  private bindGridData(data): void {
    var dataSource = {
      rowCount: null,
      getRows: function (params) {
        setTimeout(function () {
          var dataAfterSorting = sortData(params.sortModel, data);
          var rowsThisPage = dataAfterSorting.slice(
            params.startRow,
            params.endRow
          );
          var lastRow = dataAfterSorting.length;
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      },
    };

    this.paginatorModel.totalRows = data.length;
    this.paginatorModel.totalPages = Math.ceil(
      data.length / this.paginatorModel.pageSize
    );

    if (this.paginatorModel.totalRows == 0) {
      this.gridApi.setDatasource(dataSource);
      this.gridApi.showNoRowsOverlay();
    } else {
      this.updateCurrentPage();
      this.gridApi.hideOverlay();
      this.gridApi.setDatasource(dataSource);
    }
  }
}
