import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { OrderItemModel } from 'src/app/models/order-item-model';
import { Order } from 'src/app/models/order-models';
import { MaterialDataDetailComponent } from '../material-data-detail/material-data-detail.component';

@Component({
  selector: 'app-order-item-details',
  templateUrl: './order-item-details.component.html',
  styleUrls: ['./order-item-details.component.scss']
})
export class OrderItemDetailsComponent implements OnInit {

  @ViewChildren('newOrderItemDetail') newOrderItemDetailInstances: MaterialDataDetailComponent[];

  @Output()
  validateButtonsEE: EventEmitter<any> = new EventEmitter();

  @Input()
  useMultipleWarehouses: boolean = false;

  public orderItems: OrderItemModel[] = [];
  private _order: Order;

  @Input()
  public set order(value: Order) {
    this._order = value;
    if (value) {
      this.orderItems = value.items;
    }
  }
  public get order(): Order {
    return this._order;
  }

  public updateOrderItems(order: Order) {
    order.items.forEach(item => {
      this.newOrderItemDetailInstances.find(x => x.orderItem.orderItemID === item.orderItemID).orderItem = item;
    })
  }

  constructor(private loadingService: LoadingService) {
  }

  validateButtons() {
    this.validateButtonsEE.emit();
  }

  ngOnInit(): void {
  }

  resetOrderItemDetailButtons() {
    this.newOrderItemDetailInstances.forEach(instance => {
      instance.resetButtons();
    });
  }

  expandOrCollapse(expandAll: boolean): void {
    this.newOrderItemDetailInstances.forEach(instance => instance.expanded = expandAll);
  }

}
