import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { HoldReason } from 'src/app/models/order-models';
import { ManageHoldReasonActionsComponent } from './manage-hold-reason-actions/manage-hold-reason-actions.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../../shared/services/message-queue.service';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { take } from 'rxjs/operators';
import { HoldReasonModalComponent } from './hold-reason-modal/hold-reason-modal.component';

@Component({
  selector: 'app-hold-reasons',
  templateUrl: './hold-reasons.component.html',
  styleUrls: ['./hold-reasons.component.scss']
})
export class HoldReasonsComponent implements OnInit {
  serachCriteriaForm: FormGroup;
  private gridApi;
  public columnDefs: any;
  public frameworkComponents: any;
  public gridOptions: GridOptions;
  public subscriptions: Subscription;
  public holdReasons: HoldReason[];
  public holdReasonsBackup: HoldReason[];
  public filteredResultsCount: number;
  public overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">No records to display.</span>';

  constructor(
    private readonly orderService: OrderService,
    private readonly messageService: MessageService,
    private dialog: MatDialog,
    private toastr: ResponseHandlingService,
    private loader: LoadingService) {
    this.subscriptions = new Subscription();
    this.frameworkComponents = {
      editdeleteHoldReasonRendered: ManageHoldReasonActionsComponent,
    };

    this.columnDefs = [
      { minWidth: 1000, headerName: 'Hold Reason', field: 'name', sortable: true },
      {
        minWidth: 100, cellRenderer: 'editdeleteHoldReasonRendered', cellClass: ['button-edit', 'display-end'], cellRendererParams: { data: this },
      }
    ];
  }

  ngOnInit(): void {
    this.fillGrid();
    this.buildForm();

    this.messageService.message.subscribe({
      next: resp => {
        if (resp.data == 'delete') {
          this.loader.show();
          this.orderService.deleteHoldReason(resp.reference.reasonID).subscribe(
            {
              next: () => {
                this.toastr.showSuccess('Hold reason is deleted.')
                this.fillGrid();
              },
              error: () => this.toastr.showError('An error occured. Try again later.'),
              complete: () => this.loader.clearMessage()
            }
          )
        } else if (resp.data == 'edit') {
          const holdReasonExists = this.holdReasons.some(x => x.name.toLowerCase() === resp.reference.name.toLowerCase());
          const holdReasonExistingReasonID = this.holdReasons.find(x => x.name.toLowerCase() == resp.reference.name.toLowerCase())?.reasonID;
          const reasonID = this.holdReasons.find(x => x.reasonID == resp.reference.reasonID).reasonID;
          const requireCommentChanged = this.holdReasons.some(x => x.requireComment !== resp.reference.requireComment);

          if (!holdReasonExists || (holdReasonExists && requireCommentChanged && holdReasonExistingReasonID && reasonID == holdReasonExistingReasonID)) {
            this.loader.show();
            this.orderService.addOrEditHoldReason(resp.reference).subscribe(
              {
                next: () => {
                  this.toastr.showSuccess(`Hold reason is updated`);
                  this.fillGrid();
                },
                error: () => this.toastr.showError('An error occured. Try again later.'),
                complete: () => this.loader.clearMessage()
              });
          } else { this.toastr.showError("Duplicate Hold reason found.", 2000); return; }
        }
      }
    })
  }

  private buildForm(): void {
    this.serachCriteriaForm = new FormGroup({
      searchCriteria: new FormControl(''),
    });

    this.serachCriteriaForm.controls["searchCriteria"].valueChanges.subscribe(value => {
      this.filteredResultsCount = 0;
      this.filterResults(value.toString());
    })
  }

  filterResults(value: string) {
    this.holdReasons = this.holdReasonsBackup;
    if (value.length >= 2) {
      this.holdReasons = this.holdReasons.filter(x => x.name.toLowerCase().toString().includes(value.toLowerCase()) || x.name.toLowerCase().toString().includes(value.toLowerCase()));
      this.filteredResultsCount = this.holdReasons.length;
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  public fillGrid() {
    this.orderService
      .getHoldReasonList()
      .pipe(take(1))
      .subscribe((holdReasonsResponse) => {
        this.holdReasons = holdReasonsResponse;
        this.holdReasonsBackup = holdReasonsResponse;
      });
  }

  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  openHoldReasonModal(): void {
    this.dialog.open(HoldReasonModalComponent, {
      height: '300px',
      width: '500px',
      data: null,
      disableClose: true
    }).afterClosed().subscribe((res: any) => {
      if (!res) return;
      const holdReasonExists = this.holdReasons.some(x => x.name.toLowerCase() == res.name.toLowerCase())
      if (holdReasonExists) { this.toastr.showError("Duplicate Hold reason found.", 2000); return; }
      this.loader.show();
      this.orderService.addOrEditHoldReason(res).subscribe(
        {
          next: () => {
            this.toastr.showSuccess(`Hold reason is added`);
            this.fillGrid();
          },
          error: () => this.toastr.showError('An error occured. Try again later.'),
          complete: () => this.loader.clearMessage()
        });
    });
  }
}