import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { Component, OnInit } from '@angular/core';
import { sortData } from 'src/app/helpers/grid-helpers';
import { map, take, finalize } from 'rxjs/operators';
import { ShipperService } from '../../services/shippers-service/shipper.service';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { ManageShipperActionsComponent } from './manage-shipper-actions/manage-shipper-actions.component';
import { PaginatorModel } from 'src/app/core/modules/ag-grid/components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';

@Component({
  selector: 'app-manage-shippers',
  templateUrl: './manage-shippers.component.html',
  styleUrls: ['./manage-shippers.component.scss']
})
export class ManageShippersComponent implements OnInit {

  private gridApi;

  public gridOptions: GridOptions;
  public disableClearFilters: boolean = true;
  public paginatorModel: PaginatorModel;
  public hasFilterModelChanged: boolean = true;
  public frameworkComponents: any;
  public overlayNoRowsTemplate: string =
    '<span class="ag-overlay-loading-center">No shippers found.</span>';

  constructor(
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly shipperService: ShipperService
  ) {
    this.frameworkComponents = {
      manageShippersActions: ManageShipperActionsComponent,
    };
  }

  columnDefs = [
    {
      headerName: 'Shipper Name',
      field: 'shipperName',
      headerTooltip: 'Shipper Name',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.shipperName;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      width: 100,
      cellRenderer: 'manageShippersActions',
      cellClass: ['button-edit', 'display-end'],
      cellRendererParams: { data: this },
    },
  ];

  ngOnInit(): void {
    this.paginatorModel = {
      totalRows: 0,
      totalPages: 0,
      pageSize: 25,
      currentPage: 1,
      leftArrowsDisabled: true,
      rightArrowsDisabled: true,
    };
    
    this.gridOptions = {};
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    this.loadingService.show("Loading Shippers...");
    this.shipperService
      .getShippers()
      .pipe(
        take(1),
        map((shippers) => {
          this.bindGridData(shippers);
        }),
        finalize(() => {
          this.loadingService.clearMessage();
        })
      )
      .subscribe();
  }

  onResizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  updateCurrentPage(): void {
    this.paginatorModel.currentPage = 1;
    this.paginatorModel.leftArrowsDisabled = true;
    if (this.paginatorModel.totalPages > 1) {
      this.paginatorModel.rightArrowsDisabled = false;
    }
  }

  handlePaginatorCommand(event: number): void {
    switch (event) {
      case 1:
        this.gridApi.paginationGoToFirstPage();
        break;
      case 2:
        this.gridApi.paginationGoToPreviousPage();
        break;
      case 3:
        this.gridApi.paginationGoToNextPage();
        break;
      case 4:
        this.gridApi.paginationGoToLastPage();
        break;
      default:
        this.gridApi.paginationSetPageSize(event);
        this.gridApi.paginationGoToFirstPage();
        break;
    }
  }

  setDataSource(returnedData) {
    this.gridApi.purgeInfiniteCache();
    this.bindGridData(returnedData);
  }

  onAddShipper(){
    this.router.navigate(['manageshippers/new']);
  }

  private bindGridData(data): void {
    var dataSource = {
      rowCount: null,
      getRows: function (params) {
        setTimeout(function () {
          var dataAfterSorting = sortData(params.sortModel, data);
          var rowsThisPage = dataAfterSorting.slice(
            params.startRow,
            params.endRow
          );
          var lastRow = dataAfterSorting.length;
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      },
    };

    this.paginatorModel.totalRows = data.length;
    this.paginatorModel.totalPages = Math.ceil(
      data.length / this.paginatorModel.pageSize
    );

    if (this.paginatorModel.totalRows == 0) {
      this.gridApi.setDatasource(dataSource);
      this.gridApi.showNoRowsOverlay();
    } else {
      this.updateCurrentPage();
      this.gridApi.hideOverlay();
      this.gridApi.setDatasource(dataSource);
    }
  }
}
