<h5 style="padding-bottom: 15px">Edit Density </h5>
<form [formGroup]="editDensityForm" (ngSubmit)="onSubmit()">
    <div class="p-grid">
        <div class="p-col-4">
            <label>Material  Id: </label>
        </div>
        <div class="p-col-8">
          <span> {{data.catalogID}}</span>
        </div>
        <div class="p-col-4">
            <label>Material Name: </label>
        </div>
        <div class="p-col-8">
          <span> {{data.description}}</span>
        </div>
        <div class="p-col-4">
            <label>Current Density: </label>
        </div>
        <div class="p-col-8">
            <span> {{data.density}}</span>
        </div>
        <div class="p-col-4">
            <label>New Density: </label>
        </div>
        <div class="p-col-8">
            <input id="density" type="text" class="p-ml-2" formControlName="density" pInputText numeric decimals="7">
            <div *ngIf="editDensityForm.controls['density'].invalid && (editDensityForm.controls['density'].dirty || editDensityForm.controls['density'].touched)">
            <div class="error" *ngIf="editDensityForm.controls['density'].errors.required">
                Please enter density
            </div>
            <div class="error" *ngIf="editDensityForm.controls['density'].errors.greaterThanZeroValue">
                Value must be greater than zero
            </div>
        </div>
        </div>
        
    </div>

    <div class="flex-end">
        <button type="button" style="margin-right: 10px;" mat-raised-button color="cor-default" mat-dialog-close>Cancel</button>
        <button type="submit" mat-raised-button color="cor-default" color="cor-primary" [disabled]="!editDensityForm.valid">Save</button>
    </div>
</form>
