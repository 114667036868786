import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order, OrderStatuses } from 'src/app/models/order-models';
@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss']
})
export class OrderCardComponent implements OnInit {

  @Output() orderMoved: EventEmitter<Order> = new EventEmitter();

  @Input() headerColor: string;
  @Input() order: Order;

  constructor() { }

  confirmMoveToWarehouseQueue(): void {
    this.orderMoved.emit(this.order);
  }

  confirmMoveToPick(): void {
    this.orderMoved.emit(this.order);
  }

  ngOnInit(): void {
  }

  get OrderStatuses(): typeof OrderStatuses {
    return OrderStatuses;
  }

}
