import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Configuration } from 'src/app/configuration';
import { HttpClient } from '@angular/common/http';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html'
})
export class ErrorModalComponent implements OnInit {

  errorRequestForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private toastr: ResponseHandlingService,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.errorRequestForm = new FormGroup({
      comments: new FormControl('', [Validators.required]),
    });
  }

  get fc() {
    return this.errorRequestForm.controls;
  }

  get fv() {
    return this.errorRequestForm.value;
  }

  submitToSupport() {
    this.loadingService.show('Submitting error...');
    const payload = {
      "location": this.data.error.url,
      "description": this.fv.comments,
      "errorMessage": this.data.error?.error?.Detail ? this.data.error.error.Detail : "500: Internal server error"
    }
    var URL = Configuration.REST_API_URL + `/Telemetry/Error`;
    this.http.post<any>(URL, payload).subscribe({
      next: () => this.toastr.showSuccess('Error submitted successfully.'),
      error: () => this.toastr.showError('Could not submit error. Try again later!'),
      complete: () => { this.loadingService.clearMessage(); this.dialogRef.close(); }
    })
  }
}