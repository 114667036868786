import { ErrorDialogService } from './services/error-dialog-service/error-dialog.service';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { ContainerWeightsModalComponent } from './components/container-weights-modal/container-weights-modal.component';
import { CustomsInvoiceModalComponent } from './components/customs-invoice-modal/customs-invoice-modal.component';
import { PeerToPeerService } from 'src/app/peer-to-peer/services/peer-to-peer.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalModule, BroadcastService, MsalInterceptor } from '@azure/msal-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RoleService } from './services/role-service/role-service.service';
import { UserService } from './services/user-service/user-service.service';
import { MsalAuthService } from './services/auth-service/auth-service.service';
import { PermissionService } from './services/permission-service/permission-service';
import { RouteGuardService } from './services/route-guard-service/route-guard.service';
import { authHeaderInterceptor } from './services/auth-service/auth-headers.interceptor';
import { ResponseHandlingService } from './services/response-handling-service/response-handling-service';
import { EnvironmentService } from './services/environment-service/environment-service';
import { InventoryService } from './services/inventory-service/inventory-service';
import { OrderService } from './services/order-service/order-service';
import { StationService } from '../app/services/station/station.service'
import { OrderItemAttachmentService } from './services/order-item-attachments-service/order-tem-attachments.service';
import { PdfService } from './services/pdf-service/pdf-service';
import { DecantService } from './services/decant-service/decant-service';
import { ScalePollingService } from './services/scale-polling-service/scale-polling-service';
import { AppInsightsService } from './services/app-insights-service/app-insights-service';
import { ResComponentsCoreModule, AUTH_SERVICE_TOKEN } from '@corteva-research/ngx-components-core';
import { SecurityRolesComponent } from './components/security-roles/security-roles.component';
import { RoleGridComponent } from './components/role-grid/role-grid.component';
import { UserRoleModalComponent } from './components/user-role-modal/user-role-modal.component';
import { HomeComponent } from './components/home/home.component';
import { Configuration } from './configuration';
import { ChemicalTooltipPipe } from './helpers/chemical-tooltip-pipe';
import { ChemicalListPipe } from './helpers/chemical-list-pipe';
import { BaseComponent } from './base-component';
import { OrderCoordinationComponent } from './components/order-coordination/order-coordination.component';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { OrderPrepComponent } from './components/order-prep/order-prep.component';
import { OrderCardComponent } from './components/order-card/order-card.component';
import { TagsComponent } from './components/tags/tags.component';
import { OrderAssignModalComponent } from './components/order-assign-modal/order-assign-modal.component';
import { OrderFiltersComponent } from './components/order-filters/order-filters.component';
import { OrderWarehouseComponent } from './components/order-warehouse/order-warehouse.component';
import { OrderProcessedComponent } from './components/order-processed/order-processed.component';
import { OrderItemDetailsComponent } from './components/order-item-details/order-item-details.component';
import { OrderItemAttachmentsComponent } from './components/attachments/attachments.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TextInlineEditComponent } from './components/text-inline-edit/text-inline-edit.component';
import { DecantComponent } from './components/decant/decant.component';
import { DecantDetailComponent } from './components/decant-detail/decant-detail.component';
import { DecantContainerDetailsComponent } from './components/decant-container-details/decant-container-details.component';

import { OrderItemDetailComponent } from './components/order-item-detail/order-item-detail.component';
import { DecantingCaptureComponent } from './components/decanting-capture/decanting-capture.component';
import { ScaleControlComponent } from './components/scale-control/scale-control.component';
import { ScaleProgressBarComponent } from './components/scale-progress-bar/scale-progress-bar.component';
import { ScalePollerComponent } from './components/scale-poller/scale-poller.component';
import { CompleteDecantContainerModalComponent } from './components/decant-container-details/complete-decant-container-modal/complete-decant-container-modal.component';
import { DisposeContainerComponent } from './components/warehousing/dispose-container/dispose-container.component';
import { ReceivingModule } from './receiving/receiving.module';
import { PackingComponent } from './packing-and-shipping/packing/packing.component';
import { PackingDetailComponent } from './packing-and-shipping/packing/packing-detail/packing-detail.component';
import { LoadingModule } from './core/modules/loading/loading.module';
import { DisposeContainerConfirmModalComponent } from './components/dispose-container-confirm-modal/dispose-container-confirm-modal.component';
import { AttachmentModalComponent } from './components/attachment-modal/attachment-modal.component';
import { ViewAttachmentsModalComponent } from './components/view-attachments-modal/view-attachments-modal.component';
import { PickingComponent } from './components/warehousing/picking/picking.component';
import { RequestTransferModalComponent } from './components/request-transfer-modal/request-transfer-modal.component'
import { ScaleTestComponent } from './components/scale-test/scale-test.component';
import { ReweighParentContainerComponent } from './components/reweigh-parent-container/reweigh-parent-container.component'
import { RequestDocumentsModalComponent } from './components/request-documents-modal/request-documents-modal.component';
import { ReprintingComponent } from './components/reprinting/reprinting.component';
import { SplitRequestModalComponent } from './components/split-request-modal/split-request-modal.component'
import { HoldModalComponent } from './components/hold-modal/hold-modal.component';
import { OrderCompleteComponent } from './components/order-complete/order-complete.component'

import { ConfirmMovingOrderModalComponent } from './components/confirm-moving-order-modal/confirm-moving-order-modal.component'
import { ViewHistoryModalComponent } from './components/view-history-modal/view-history-modal.component';
import { ShippingInfoModalComponent } from './packing-and-shipping/shipping-info-modal/shipping-info-modal.component'

import { MaterialDataDetailComponent } from './components/material-data-detail/material-data-detail.component';

import { ViewOtherRequestsModalComponent } from './components/view-other-requests-modal/view-other-requests-modal.component';
import { LotSelectionModalComponent } from './components/lot-selection-modal/lot-selection-modal.component';
import { EditContainersModalComponent } from './components/edit-containers-modal/edit-containers-modal.component';

import { UnassignLotsModalComponent } from './components/unassign-lots-modal/unassign-lots-modal.component';
import { EditPropertyModalComponent } from './edit-property-modal/edit-property-modal.component';
import { ErrorHandlerService } from './services/error-handler.service';
import { HeaderActionComponent } from './components/material-data-detail/header-action/header-action.component';
import { LabelInfoModalComponent } from './components/label-info-modal/label-info-modal.component';

import { CommonModule, DatePipe } from '@angular/common';
import { ClipboardModule } from 'ngx-clipboard';

import { ConfigurationService } from './services/configuration-service/configuration.service';
import { ManageLabelsComponent } from './components/manage-labels/manage-labels.component';
import { LabelService } from './services/label-service/label-service';
import { LabelDesignService } from './services/label-design-service/label-design-service';
import { SharedDcumentService } from './services/shared-document-service/shared-document-service.service';
import { ActionsCellRendererComponent } from './components/manage-labels/actions-cell-renderer/actions-cell-renderer.component';
import { FileNameRendererComponent } from './components/manage-labels/file-name-renderer/file-name-renderer.component';
import { AddLabelComponent } from './components/manage-labels/add-label/add-label.component';
import { PrintLabelModalComponent } from './components/print-label-modal/print-label-modal.component';
import { SharedAgGridModule } from './core/modules/ag-grid/ag-grid.module';

import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PickListModule } from 'primeng/picklist';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { FieldsetModule } from 'primeng/fieldset';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { FileUploadModule } from 'primeng/fileupload';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AppDirectiveModule } from './helpers/app-directive.module';
import { CoaModalComponent } from './components/coa-modal/coa-modal.component';
import { StoredDocsModalComponent } from './components/stored-docs-modal/stored-docs-modal.component';
import { ActionRendererComponent } from './components/stored-docs-modal/action-renderer/action-renderer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmCancelOrderModalComponent } from './components/confirm-cancel-order-modal/confirm-cancel-order-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CustomHeaderComponent } from './components/material-data-detail/custom-header/custom-header.component';
import { WarehouseCustomHeaderComponent } from './components/material-data-warehouse-detail/custom-header/custom-header.component';
import { WarehouseHeaderActionComponent } from './components/material-data-warehouse-detail/header-action/header-action.component';
import { WarehouseSelectLotCheckComponent } from './components/material-data-warehouse-detail/select-lot-check/select-lot-check.component';
import { MaterialDataWarehouseDetailComponent } from './components/material-data-warehouse-detail/material-data-warehouse-detail.component';
import { NoDecantModalComponent } from './components/no-decant-modal/no-decant-modal.component';
import { SpecialInstructionsDialogComponent } from './components/special-instructions-dialog/special-instructions-dialog.component';
import { DestinationGuideInfoComponent } from './components/destination-guide-info/destination-guide-info.component';
import { SortByPipe } from './core/pipes/sort-by.pipe';
import { SharedModule } from './shared/shared.module';
import { OrderListModule } from 'primeng/orderlist';
import { InventoryComponent } from './components/inventory/inventory.component';
import { EditDensityComponent } from './components/edit-density/edit-density.component';
import { InventoryItemComponent } from './components/inventory/inventory-item/inventory-item.component';
import { StorageLocationChangeComponent } from './components/inventory/storage-location-change/storage-location-change.component';
import { InventoryStorageLocationChangeModalComponent } from './components/inventory/inventory-storage-location-change-modal/inventory-storage-location-change-modal.component';
import { DeleteContainerComponent } from './components/warehousing/delete-container/delete-container.component';
import { SharedDocumentsComponent } from './components/shared-documents/shared-documents.component';
import { UploadDocumentComponent } from './components/shared-documents/upload-document/upload-document.component';
import { ChangeStatusComponent } from './components/change-status/change-status.component';
import { ChangeStatusModalComponent } from './components/change-status/change-status-modal/change-status-modal.component';
import { EditDensityModalComponent } from './components/edit-density/edit-density-modal/edit-density-modal.component';
import { NumericDirective } from './shared/directives/numeric.directive';
import { SelectLotCheckComponent } from './components/material-data-detail/select-lot-check/select-lot-check.component';
import { ShippingConditionsModalComponent } from './components/shipping-conditions-modal/shipping-conditions-modal.component';
import { ShippingNotesModalComponent } from './components/shipping-notes-modal/shipping-notes-modal.component';
import { ContainerWeightComponent } from './components/container-weight/container-weight.component';
import { DeleteContainerWeightComponent } from './components/container-weight/delete-container-weight/delete-container-weight.component';
import { EditContainerWeightComponent } from './components/container-weight/edit-container-weight/edit-container-weight.component';
import { PackingMaterialsModalComponent } from './components/packing-materials-modal/packing-materials-modal.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { HideShowComponent } from './components/stored-docs-modal/hide-show/hide-show.component';
import { WarehouseManagerComponent } from './components/warehouse-manager/warehouse-manager.component';
import { EditWarehouseComponent } from './components/warehouse-manager/edit-warehouse/edit-warehouse.component';
import { MatMenuModule } from '@angular/material/menu';
import { DateFormatPipe } from './helpers/date-format-pipe';
import { LabelDesignComponent } from './components/label-design/label-design.component';
import { LabelDesignModalComponent } from './components/label-design-modal/label-design-modal.component';
import { ManageLabelPrinterActionsComponent } from './components/manage-label-printers/manage-label-printer-actions/manage-label-printer-actions.component';
import { EditLabelPrinterModalComponent } from './components/manage-label-printers/edit-label-printer-modal/edit-label-printer-modal.component';
import { ManageLabelPrintersComponent } from './components/manage-label-printers/manage-label-printers.component';
import { PrinterAssignmentsComponent } from './components/printer-assignments/printer-assignments.component';
import { PrinterAssignmentsModalComponent } from './components/printer-assignments-modal/printer-assignments-modal.component';
import { DeletePrinterAssignmentComponent } from './components/printer-assignments/delete-printer-assignment/delete-printer-assignment.component';
import { HoldReasonsComponent } from './components/hold-reasons/hold-reasons.component';
import { ManageHoldReasonActionsComponent } from './components/hold-reasons/manage-hold-reason-actions/manage-hold-reason-actions.component';
import { HoldReasonModalComponent } from './components/hold-reasons/hold-reason-modal/hold-reason-modal.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ManageDestinationGuideOptionsComponent } from './components/manage-destination-guide-options/manage-destination-guide-options.component';
import { ManageDestinationGuideOptionsActionsComponent } from './components/manage-destination-guide-options/manage-destination-guide-options-actions/manage-destination-guide-options-actions.component';
import { EditDestinationGuideOptionsModalComponent } from './components/manage-destination-guide-options/edit-destination-guide-options-modal/edit-destination-guide-options-modal.component';
import { ManageShippersComponent } from './components/manage-shippers/manage-shippers.component';
import { ManageShipperActionsComponent } from './components/manage-shippers/manage-shipper-actions/manage-shipper-actions.component';
import { EditShipperModalComponent } from './components/manage-shippers/edit-shipper-modal/edit-shipper-modal.component';
import { ManageStationsComponent } from './components/manage-stations/manage-stations.component';
import { ManageStationActionsComponent } from './components/manage-stations/manage-station-actions/manage-station-actions.component';
import { EditStationModalComponent } from './components/manage-stations/edit-station-modal/edit-station-modal.component';
import { ManageSampleusesComponent } from './components/manage-sampleuses/manage-sampleuses.component';
import { ManageSampleusesActionsComponent } from './components/manage-sampleuses/manage-sampleuses-actions/manage-sampleuses-actions.component';
import { EditSampleusesModalComponent } from './components/manage-sampleuses/edit-sampleuses-modal/edit-sampleuses-modal.component';
import { LocationBarcodeLabelsComponent } from './components/location-barcode-labels/location-barcode-labels.component';
import { ManageRolesComponent } from './components/manage-roles/manage-roles.component';
import { ManageRolesService } from './services/manage-roles-service/manage-roles.service'
import { ManageRoleActionsComponent } from './components/manage-roles/manage-role-actions/manage-roles-action.component'
import { AddEditRoleComponent } from './components/manage-roles/edit-roles-modal/add-edit-role.component';
import { ManageScalesComponent } from './components/manage-scales/manage-scales.component';
import { ManageScalesActionsComponent } from './components/manage-scales/manage-scales-actions/manage-scales-actions.component';
import { EditScaleModalComponent } from './components/manage-scales/edit-scale-modal/edit-scale-modal.component';
import { DisposalLogComponent } from './components/disposal-log/disposal-log.component';
import { SaveContainerComponent } from './components/warehousing/save-container/save-container.component';
import { DisposalRendererComponent } from './components/warehousing/disposal-renderer/disposal-renderer.component';
import { EditShipperComponent } from './components/manage-shippers/edit-shipper/edit-shipper.component';
import { DisposalCommentRendererComponent } from './components/warehousing/dispose-container/disposal-comment-renderer/disposal-comment-renderer.component';
import { ManageUserActionComponent } from './components/user-manager/manage-user-action/manage-user-action.component'
import { UserManagerComponent } from './components/user-manager/user-manager.component'
import { EditUserComponent } from './components/user-manager/edit-user/edit-user.component'
import { ManageWarehouseActionComponent } from './components/warehouse-manager/manage-warehouse-action/manage-warehouse-action.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { EditDensityActionsComponent } from './components/edit-density/edit-density-actions/edit-density-actions.component';
import { TrackingDetailsModalComponent } from './components/tracking-details-modal/tracking-details-modal.component';
import { ManageCancellationReasonsComponent } from './components/manage-cancellation-reasons/manage-cancellation-reasons.component';
import { EditCancellationReasonsComponent } from './components/manage-cancellation-reasons/edit-cancellation-reasons/edit-cancellation-reasons.component';
import { CancellationReasonsModalComponent } from './components/cancellation-reasons-modal/cancellation-reasons-modal.component';
import { ScaleTest2Component } from './components/scale-test2/scale-test2.component';
import { ScaleControl2Component } from './components/scale-control2/scale-control2.component';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    EditUserComponent,
    NumericDirective,
    AppComponent,
    SecurityRolesComponent,
    RoleGridComponent,
    UserRoleModalComponent,
    HomeComponent,
    OrderCoordinationComponent,
    OrderDetailComponent,
    ConfirmDialogComponent,
    UnauthorizedComponent,
    ChemicalTooltipPipe,
    ChemicalListPipe,
    OrderPrepComponent,
    OrderCardComponent,
    TagsComponent,
    OrderAssignModalComponent,
    OrderFiltersComponent,
    OrderWarehouseComponent,
    OrderProcessedComponent,
    OrderItemDetailsComponent,
    OrderItemAttachmentsComponent,
    TextInlineEditComponent,
    DecantComponent,
    DecantDetailComponent,
    DecantContainerDetailsComponent,
    OrderItemDetailComponent,
    DecantingCaptureComponent,
    CompleteDecantContainerModalComponent,
    ScaleControlComponent,
    ScaleProgressBarComponent,
    ScalePollerComponent,
    DisposeContainerComponent,
    PackingComponent,
    PackingDetailComponent,
    DisposeContainerConfirmModalComponent,
    BaseComponent,
    AttachmentModalComponent,
    ViewAttachmentsModalComponent,
    PickingComponent,
    RequestTransferModalComponent,
    ScaleTestComponent,
    ReweighParentContainerComponent,
    RequestDocumentsModalComponent,
    CustomsInvoiceModalComponent,
    ReprintingComponent,
    SplitRequestModalComponent,
    HoldModalComponent,
    OrderCompleteComponent,
    ViewHistoryModalComponent,
    ConfirmMovingOrderModalComponent,
    ViewHistoryModalComponent,
    ShippingInfoModalComponent,
    MaterialDataDetailComponent,
    MaterialDataWarehouseDetailComponent,
    ViewOtherRequestsModalComponent,
    LotSelectionModalComponent,
    EditContainersModalComponent,
    UnassignLotsModalComponent,
    HeaderActionComponent,
    EditPropertyModalComponent,
    LabelInfoModalComponent,
    ManageLabelsComponent,
    ActionsCellRendererComponent,
    FileNameRendererComponent,
    AddLabelComponent,
    PrintLabelModalComponent,
    CoaModalComponent,
    StoredDocsModalComponent,
    ActionRendererComponent,
    ConfirmCancelOrderModalComponent,
    CustomHeaderComponent,
    WarehouseCustomHeaderComponent,
    WarehouseHeaderActionComponent,
    WarehouseSelectLotCheckComponent,
    WarehouseSelectLotCheckComponent,
    NoDecantModalComponent,
    SpecialInstructionsDialogComponent,
    DestinationGuideInfoComponent,
    SortByPipe,
    InventoryComponent,
    InventoryItemComponent,
    StorageLocationChangeComponent,
    InventoryStorageLocationChangeModalComponent,
    DeleteContainerComponent,
    SharedDocumentsComponent,
    UploadDocumentComponent,
    ChangeStatusComponent,
    ChangeStatusModalComponent,
    EditDensityComponent,
    EditDensityModalComponent,
    SelectLotCheckComponent,
    ShippingConditionsModalComponent,
    ShippingNotesModalComponent,
    ContainerWeightComponent,
    DeleteContainerWeightComponent,
    EditContainerWeightComponent,
    ContainerWeightsModalComponent,
    PackingMaterialsModalComponent,
    HideShowComponent,
    WarehouseManagerComponent,
    EditWarehouseComponent,
    LabelDesignComponent,
    LabelDesignModalComponent,
    ManageLabelPrintersComponent,
    ManageLabelPrinterActionsComponent,
    EditLabelPrinterModalComponent,
    PrinterAssignmentsComponent,
    PrinterAssignmentsModalComponent,
    DeletePrinterAssignmentComponent,
    HoldReasonsComponent,
    ManageHoldReasonActionsComponent,
    HoldReasonModalComponent,
    ManageDestinationGuideOptionsComponent,
    ManageDestinationGuideOptionsActionsComponent,
    EditDestinationGuideOptionsModalComponent,
    ManageRoleActionsComponent,
    ManageShippersComponent,
    ManageShipperActionsComponent,
    EditShipperModalComponent,
    ManageStationsComponent,
    ManageStationActionsComponent,
    ManageSampleusesComponent,
    ManageSampleusesActionsComponent,
    EditSampleusesModalComponent,
    LocationBarcodeLabelsComponent,
    ManageRolesComponent,
    AddEditRoleComponent,
    EditStationModalComponent,
    ManageScalesComponent,
    ManageScalesActionsComponent,
    EditScaleModalComponent,
    DisposalLogComponent,
    SaveContainerComponent,
    DisposalRendererComponent,
    EditShipperComponent,
    DisposalCommentRendererComponent,
    ManageWarehouseActionComponent,
    ManageUserActionComponent,
    UserManagerComponent,
    ErrorModalComponent,
    EditDensityActionsComponent,
    TrackingDetailsModalComponent,
    ManageCancellationReasonsComponent,
    EditCancellationReasonsComponent,
    CancellationReasonsModalComponent,
    ScaleTest2Component,
    ScaleControl2Component
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTooltipModule,
    MatIconModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatButtonToggleModule,
    RadioButtonModule,
    PickListModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    DialogModule,
    MultiSelectModule,
    FieldsetModule,
    AccordionModule,
    FileUploadModule,
    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    ToastModule,
    TooltipModule,
    AutoCompleteModule,
    HttpClientModule,
    ReceivingModule,
    LoadingModule,
    ClipboardModule,
    OrderListModule,
    SplitButtonModule,
    SelectButtonModule,
    MatMenuModule,
    InputSwitchModule,
    ResComponentsCoreModule.forRoot(),
    MsalModule.forRoot({
      auth: {
        clientId: Configuration.CLIENT_ID,
        authority: Configuration.AUTHORITY,
        redirectUri: Configuration.APP_UI_URL,
        postLogoutRedirectUri: Configuration.APP_UI_URL,
        validateAuthority: true,
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
      system: {
        loadFrameTimeout: 30000
      },
    },
      {
        popUp: false,
        consentScopes: [
          'user.read',
          'openid',
          'profile',
          Configuration.READ_SCOPE,
          Configuration.WRITE_SCOPE
        ],
        unprotectedResources: ['https://www.microsoft.com/en-us/'],
        protectedResourceMap: [
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
          [Configuration.REST_API_URL, [Configuration.WRITE_SCOPE, Configuration.READ_SCOPE]]
        ],
        extraQueryParameters: {}
      }),
    SharedAgGridModule,
    AppDirectiveModule,
    PdfViewerModule,
    InputTextareaModule,
    SharedModule
  ],
  providers: [
    UserService,
    RoleService,
    ResponseHandlingService,
    BroadcastService,
    PermissionService,
    RouteGuardService,
    EnvironmentService,
    OrderService,
    StationService,
    PeerToPeerService,
    MessageService,
    ConfirmationService,
    OrderItemAttachmentService,
    PdfService,
    DecantService,
    InventoryService,
    ScalePollingService,
    ConfigurationService,
    AppInsightsService,
    LabelService,
    LabelDesignService,
    SharedDcumentService,
    ManageRolesService,
    ErrorDialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: authHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: AUTH_SERVICE_TOKEN,
      useClass: MsalAuthService
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    DatePipe,
    DateFormatPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
