import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  ICellRendererParams,
  IAfterGuiAttachedParams,
} from 'ag-grid-community';
import { of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MessageService } from '../../../shared/services/message-queue.service';
import { ContainerWeightsService } from '../../../services/container-weights/container-weights.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';

@Component({
  selector: 'app-delete-container-weight',
  templateUrl: './delete-container-weight.component.html',
  styleUrls: ['./delete-container-weight.component.scss']
})
export class DeleteContainerWeightComponent implements ICellRendererAngularComp
{
  private params: any;

  constructor(
    private dialog: MatDialog,
    private readonly containerWeightService: ContainerWeightsService,
    private readonly messageService: MessageService,
    private toastr: ResponseHandlingService,
    private loadingService: LoadingService
  ) {}

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  async onDelete() {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '325px',
        height: '180px',
        data: {
          title: 'Delete',
          message:
            'Are you sure you want to delete this item? This action cannot be undone.',
          useDangerConfirm: true,
          cancelButtonLabel: 'Cancel',
          confirmButtonLabel: 'Delete',
          showCancel: true,
        },
      })
      .afterClosed().pipe(
        tap((a) =>{
          console.log(a)
        }),
        switchMap(isSuccess =>{
          if(isSuccess){
            this.loadingService.show('Deleting...');
            return this.containerWeightService.deleteContainerWeights(this.params.data.containerWeightID);
          }
          else
            return of(null); 
        }),
        catchError(err=>{
          this.loadingService.clearMessage();
          this.toastr.showError('Could not delete container weights');
          return(err);
        }),
        switchMap(err=>{
          if(err && err =='Container deleted successfully.'){
           this.toastr.showSuccess('Container deleted successfully.');
          } 

          this.messageService.message.next({});
          return of(err);
       }),
       finalize(() => this.loadingService.clearMessage())
     ).subscribe();
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}
}
