<form [formGroup]="warehouseStationForm">
    <div style="margin-bottom: 10px;">
        <label for="warehouses">Warehouse</label>
        <p-dropdown id="warehouses" placeholder="Select a warehouse" formControlName="warehousectrl"
            [(ngModel)]="selectedWarehouse" [options]="warehousesRes" (onChange)="onWareHouseChange($event.value)"
            optionLabel="itemDesc" class="warehouses-dropdown" panelStyleClass="station-selection-minWidthOverride">
        </p-dropdown>
    </div>
    <div>
        <label for="stations" style="margin-right: 21px;">Stations</label>
        <p-dropdown id="stations" [placeholder]="getPlaceholder()" formControlName="stationctrl" [(ngModel)]="station"
            [options]="stationsRes" (onChange)="onStationChange($event.value)" optionLabel="name" (onShow)="onDropDownShow(true)"
            (onHide)="onDropDownShow(false)"
            class="stations-dropdown" panelStyleClass="station-selection-minWidthOverride" [disabled]="!warehouseSelected">
        </p-dropdown>
    </div>
</form>