import { AttachmentService } from './../../core/modules/attachments/services/attachment.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast/toast';
import { take } from 'rxjs/operators';
import { DownloadAttachmentService } from 'src/app/services/download-attachment-service/download-attachment.service';
import { OrderItemAttachmentService } from 'src/app/services/order-item-attachments-service/order-tem-attachments.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { OrderItemModel } from '../../models/order-item-model';
import { AttachmentEntity, AttachmentResponseObject, CreateOrderAttachmentRequest, CreateOrderItemAttachmentRequest, OrderAttachment, UpdateOrderAttachmentRequest, UpdateOrderItemAttachmentRequest } from '../../models/attachment-models';
import { AttachmentModalComponent } from '../attachment-modal/attachment-modal.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { OrderItemAttachment } from 'src/app/models/attachment-models';
import { DocumentType } from 'src/app/models/document-type-model';
import { Language } from 'src/app/models/language.model';
import { PermissionService } from 'src/app/services/permission-service/permission-service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDcumentService } from 'src/app/services/shared-document-service/shared-document-service.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class OrderItemAttachmentsComponent implements OnInit {

  @Input() isOrder = false;
  @Input() disableAttachment = false;

  private _isLoginUserCoordinator: boolean;
  @Input()
  public set isLoginUserCoordinator(v: boolean) {
    this._isLoginUserCoordinator = v;
    this.checkPermission();
  }
  public get isLoginUserCoordinator(): boolean {
    return this._isLoginUserCoordinator;
  }

  @Input() canDisableCOA;
  @Input() coaStatus: string = '';
  @Input() orderItem: OrderItemModel;
  @Input() showSharedDocuments: boolean = false;

  @Output()
  retrieveCOA: EventEmitter<any> = new EventEmitter();

  @Output()
  storedDocuments: EventEmitter<any> = new EventEmitter();

  hasPermission: boolean = false;

  private _entity: AttachmentEntity;
  get entity() {
    return this._entity;
  }
  @Input() set entity(val: AttachmentEntity) {
    if (!val) { return; }
    this._entity = val;
    this.editOrderItemModel.attachments = this.entity.attachments ? this.entity.attachments : [];
  };
  editOrderItemModel = new OrderItemModel();

  @Output() createOrderItemAttachment: EventEmitter<CreateOrderItemAttachmentRequest> = new EventEmitter();
  @Output() updateOrderItemAttachment: EventEmitter<UpdateOrderItemAttachmentRequest> = new EventEmitter();
  @Output() removeOrderItemAttachment: EventEmitter<any> = new EventEmitter();
  @Output() downloadOrderItemAttachment: EventEmitter<OrderItemAttachment> = new EventEmitter();

  @Output() createOrderAttachment: EventEmitter<CreateOrderAttachmentRequest> = new EventEmitter();
  @Output() updateOrderAttachment: EventEmitter<UpdateOrderAttachmentRequest> = new EventEmitter();
  @Output() removeOrderAttachment: EventEmitter<any> = new EventEmitter();
  @Output() downloadOrderAttachment: EventEmitter<OrderAttachment> = new EventEmitter();

  growlMsgs: ToastModule[] = [];
  uploadedFiles: any[] = [];
  exists: boolean;
  isAllowed: boolean;
  numberOfFileInvalid: number = 0;
  numberOfFileValid: number = 0;
  orderItemAttachmentId: number = 0;
  serviceResponse: any;
  alreadyExistsFileName: string[] = [];
  documentTypes: DocumentType[] = [];
  languages: Language[] = [];
  orderID: string;
  validFileExtensions = ["jpg", "png", "pdf", "docx", "xlsx", "msg", "rtf", "mht", "htm"];

  constructor(private messageService: MessageService, private activatedRoute: ActivatedRoute, private orderService: OrderService, private responseHandlingService: ResponseHandlingService,
    private dialog: MatDialog, private confirmationService: ConfirmationService,
    private downloadAttachmentService: DownloadAttachmentService,
    private attachmentService: AttachmentService, private permissionService: PermissionService,
    private sharedDcumentService: SharedDcumentService) {
    this.orderID = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    const docTypes: DocumentType[] = JSON.parse(localStorage.getItem("documentTypes"));
    this.hasPermission = this.permissionService.validateUserPermission(452) || this.isLoginUserCoordinator;
    if (docTypes == null) {
      this.attachmentService.retrieveDocumentTypes()
        .subscribe(data => {
          this.documentTypes = data;
          localStorage.setItem('documentTypes', JSON.stringify(this.documentTypes));
        });
    }
    else {
      this.documentTypes = docTypes;
    }
    const languages: Language[] = JSON.parse(localStorage.getItem("languages"));
    if (languages == null) {
      this.attachmentService.retrieveLanguages()
        .subscribe(data => {
          this.languages = data;
          localStorage.setItem('languages', JSON.stringify(this.languages));
        });
    }
    else {
      this.languages = languages;
    }
  }

  public clearMessages() {

  }

  checkPermission() {
    this.hasPermission = this.permissionService.validateUserPermission(452) || this.isLoginUserCoordinator;
  }

  onAddSharedDocuments() {
    this.sharedDcumentService.addSharedDocuments(this.orderItem.orderItemID).subscribe((res: any) => { });

  }

  onRetrieveCOA() {
    this.retrieveCOA.emit();
  }

  onStoredDocuments(event) {
    this.storedDocuments.emit(event);
  }

  openAttachmentDialog(attachment?: OrderItemAttachment): void {
    this.dialog.open(AttachmentModalComponent, {
      height: '550px',
      width: '500px',
      data: { documentTypes: this.documentTypes, languages: this.languages, entityName: this.entity.name, attachment },
    }).afterClosed().subscribe((res: AttachmentResponseObject) => {
      if (!res) { return; }
      // if there is an attachment object coming back from the modal we edit the existing attachment, otherwise we create a new one
      else if (res.attachment) {
        this.updateAttachment(res.attachment, res.documentType.documentTypeID, res.language.languageID, res.comments);
      } else {
        this.onUpload(res);
      }
    });
  }


  //------------------------------------------
  // To attach file to the request item
  //------------------------------------------
  public onUpload(obj: AttachmentResponseObject) {

    if (this.editOrderItemModel.attachments.length > 0) {
      this.orderItemAttachmentId = this.editOrderItemModel.attachments[0].orderItemAttachmentID;
    }

    this.isAllowed = true;
    this.uploadedFiles = [];
    this.exists = false;
    this.numberOfFileInvalid = 0;
    var fileUploadSummaryTable = '';

    if (this.isAllowed) {
      for (let file of obj.event.files) {
        // File types validation. Allowed files type are png,jpg,pdf,docx,xlsx
        let incomingFileExtension = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
        if (!this.validFileExtensions.includes(incomingFileExtension)) {
          this.isAllowed = false;
          this.responseHandlingService.showError('File extension invalid');
          obj.form.clear();
          return;
        }

        // Files size validation. Max allowed file size is 10 MB.
        if (file.size > "10485760") {
          this.isAllowed = false;
          this.responseHandlingService.showError('File size cannot exceed 10 MB.');
          obj.form.clear();
          return;
        }
      }
    }

    for (let file of obj.event.files) {
      this.uploadedFiles.push(file);
    }

    obj.form.clear();
    this.alreadyExistsFileName = [];
    this.editOrderItemModel.attachments.forEach(element => {
      this.uploadedFiles.forEach(r => {
        if (element.filename == r.name) {
          this.exists = true;
          this.alreadyExistsFileName.push(r.name);
        }
      })
    });

    // Duplicate file validation
    if (this.exists) {
      this.isAllowed = false;
      obj.form.clear();
      this.responseHandlingService.showError('File already exists.');
      return;
    }

    if (this.isAllowed) {
      if (this.isOrder) {
        const request: CreateOrderAttachmentRequest = {
          uploadedFiles: this.uploadedFiles,
          documentTypeID: obj.documentType.documentTypeID,
          languageID: obj.language.languageID,
          comments: obj.comments,
          orderID: this.entity.id,
        }
        this.createOrderAttachment.emit(request)
      } else {

        this.numberOfFileInvalid = 0;
        this.numberOfFileValid = this.uploadedFiles.length;
        const request: CreateOrderItemAttachmentRequest = {
          uploadedFiles: this.uploadedFiles,
          documentTypeID: obj.documentType.documentTypeID,
          comments: obj.comments,
          orderItemID: this.entity.id,
          languageID: obj.language.languageID
        }
        this.createOrderItemAttachment.emit(request);
      }

    }
    else {
      var totalFiles = 0;
      if (obj.event.files.length + this.editOrderItemModel.attachments.length > 10) {
        totalFiles = obj.event.files.length + this.editOrderItemModel.attachments.length;
        this.numberOfFileValid = 0;
        this.numberOfFileInvalid = 0;
      }
      else {
        totalFiles = obj.event.files.length;
        this.numberOfFileValid = totalFiles - this.numberOfFileInvalid;

      }
    }
  }

  public updateAttachment(attachment, updatedDocumentTypeID: number, updatedLanguageID: number, updatedComments = ''): void {
    if (this.isOrder) {
      const payload: UpdateOrderAttachmentRequest = { documentTypeID: updatedDocumentTypeID, languageID: updatedLanguageID, comments: updatedComments, orderAttachmentID: attachment.orderAttachmentID, filename: attachment.filename }
      this.updateOrderAttachment.emit(payload);
    } else {
      const payload: UpdateOrderItemAttachmentRequest = { documentTypeID: updatedDocumentTypeID, languageID: updatedLanguageID, comments: updatedComments, orderItemAttachmentID: attachment.orderItemAttachmentID, filename: attachment.filename }
      this.updateOrderItemAttachment.emit(payload);
    }
  }

  //------------------------------------------
  // To download the attached file
  //------------------------------------------
  public downloadAttachment(attachment: OrderAttachment | OrderItemAttachment) {
    if (this.isOrder) {
      this.downloadOrderAttachment.emit(attachment);
    } else {
      this.downloadOrderItemAttachment.emit(attachment);
    }
  }


  public getDocumentType(id: number): string {
    return this.documentTypes.length > 0 ? this.documentTypes.find(d => d.documentTypeID === id).name : '';
  }

  //------------------------------------
  // Remove attachment from request.
  //------------------------------------
  public removeAttachment(entity: AttachmentEntity, attachment: OrderAttachment | OrderItemAttachment) {
    const dialogData =
      new ConfirmDialogModel("Confirm",
        `Are you sure you want to remove attachment ${attachment.filename}?`,
        "Confirm", true);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      disableClose: true,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (this.isOrder) {
          this.removeOrderAttachment.emit({ entity, attachment });
        } else {
          this.removeOrderItemAttachment.emit({ entity, attachment });
        }
      }
    });
  }
}