<div class="flex-grid">
    <div class="col">
        <h4>Manage GLP/Stock Barcode Label Designs </h4>
        <p>New label designs are added only when new features are added to the application.</p>
    </div>
</div>

<ag-grid-angular class="ag-theme-alpine" style="width:100%; height: calc(100vh - 415px);" domLayout="normal" [rowData]="rowData"
    suppressHorizontalScroll="true" [columnDefs]="columnDefs" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [tooltipShowDelay]="1500"
    (gridSizeChanged)="resizeGrid($event)" (gridReady)="onGridReady($event)"
    (gridReady)="resizeGrid($event)" [frameworkComponents]="frameworkComponents">
</ag-grid-angular>