<h5>
    {{title}}
</h5>

<div class="doublespacer"></div>
<hr />

<div mat-dialog-content>

    <pre id='vLabel' *ngIf="labelInfoData.isGLPRequest;else vtemplate"
        style="font-family: sans-serif;">Net Contents: {{formatDecimal(labelInfoData.netContents)}} {{labelInfoData?.unitOfMeasureDesc}}<br /><br />Lot ID: {{labelInfoData.lotNumber}}<br /><br />DOM: {{formatDate(labelInfoData.manufactureDate)}}<br /><br />Recert. Date: {{formatDate(labelInfoData.recertificationDate)}}</pre>

    <ng-template #vtemplate>
        <pre id='vLabel'
            style="font-family: sans-serif;">Net Contents: {{formatDecimal(labelInfoData.netContents)}} {{labelInfoData?.unitOfMeasureDesc}}<br /><br />Lot: {{labelInfoData.manufacturerLotNumber}}<br /><br />DOM: {{formatDate(labelInfoData.manufactureDate)}}<br /><br />Exp Date: {{formatDate(labelInfoData.expirationDate)}}</pre>
    </ng-template>

    <div class="doublespacer"></div>

    <button mat-raised-button color="cor-primary" (click)="onCopyToClipboard('vLabel')">
        Copy to clipboard
    </button>

    <div class="doublespacer"></div>
    <hr />

    <pre id='hLabel' *ngIf="labelInfoData.isGLPRequest;else hTemplate"
        style="font-family: sans-serif;">Net Contents: {{formatDecimal(labelInfoData.netContents)}} {{labelInfoData?.unitOfMeasureDesc}}    Lot ID: {{labelInfoData.lotNumber}}    DOM: {{formatDate(labelInfoData.manufactureDate)}}    Recert. Date: {{formatDate(labelInfoData.recertificationDate)}}</pre>

    <ng-template #hTemplate>
        <pre id='hLabel'
            style="font-family: sans-serif;">Net Contents: {{formatDecimal(labelInfoData.netContents)}} {{labelInfoData?.unitOfMeasureDesc}}    Lot: {{labelInfoData.manufacturerLotNumber}}    DOM: {{formatDate(labelInfoData.manufactureDate)}}    Exp Date: {{formatDate(labelInfoData.expirationDate)}}</pre>
    </ng-template>

    <div class="doublespacer"></div>

    <button mat-raised-button color="cor-primary" (click)="onCopyToClipboard('hLabel')">
        Copy to clipboard
    </button>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button color="cor-primary" (click)="close()">
        Close
    </button>
</div>