import { Injectable } from '@angular/core';
import { ResponseHandlingService } from '../response-handling-service/response-handling-service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Configuration } from 'src/app/configuration';

@Injectable({
  providedIn: 'root'
})
export class ScaleService {

  constructor(private readonly http: HttpClient, private readonly responseHandler: ResponseHandlingService) {
  }

  getScales(): Observable<any[]> {
    var URL = `${Configuration.REST_API_URL}/Scales`;
    return this.http.get<any[]>(URL).pipe(catchError(err => this.responseHandler.handleError(err, new Map([[err.status, "Unable to get list of scales. Please contact support."]]), false)));
  }

  deleteScale(scaleId: number): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/Scales/${scaleId}`;
    return this.http.delete<any>(URL).pipe(
      catchError(err => {
        this.responseHandler.showMessage("The system was unable to save your changes. Please contact support.",'error', "Error",3000, false);
        return of("error");
      })
    );
  }

  editScale(scale: any): Observable<any> {
    var URL = `${Configuration.REST_API_URL}/Scales`;
    return this.http.post<any>(URL, scale).pipe(
      catchError(err => {
        this.responseHandler.showMessage("The system was unable to save your changes. Please contact support.",'error', "Error",3000, false);
        return of("error");
      })
    );
  }
}
