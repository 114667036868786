import { MessageService } from 'primeng/api';
import { GridOptions } from 'ag-grid-community';
import { map, switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { MessageService as MessageServiceQueue} from 'src/app/shared/services/message-queue.service';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { PaginatorModel } from 'src/app/core/modules/ag-grid/components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';
import { sortData } from 'src/app/helpers/grid-helpers';
import { EditDensityActionsComponent } from './edit-density-actions/edit-density-actions.component';

@Component({
  selector: 'app-edit-density',
  templateUrl: './edit-density.component.html',
  styleUrls: ['./edit-density.component.scss'],
})
export class EditDensityComponent implements OnInit {
  materials: any[] = [];
  
  private gridApi;
  public gridOptions: GridOptions;
  public disableClearFilters: boolean = true;
  public paginatorModel: PaginatorModel;
  public hasFilterModelChanged: boolean = true;
  public frameworkComponents: any;
  public overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">No materials found.</span>';

  isLoading: boolean = false;
  searchCriteriaForm: FormGroup;

  constructor(
    private messageService: MessageService,
    private messageServiceQueue: MessageServiceQueue,
    private readonly loadingService: LoadingService,
    private readonly inventoryService: InventoryService,
  ) { 
    this.paginatorModel = {
      totalRows: 0,
      totalPages: 0,
      pageSize: 25,
      currentPage: 1,
      leftArrowsDisabled: true,
      rightArrowsDisabled: true,
    };

    this.gridOptions = {};
    this.frameworkComponents = {
      manageActions: EditDensityActionsComponent,
    };

    this.messageServiceQueue.message
    .pipe(
      switchMap((data) => {
        if (data.data=='editDensity') {
          this.loadingService.show('Loading...');
          return this.inventoryService.updateDensity(
            data.reference.data.materialId,
            +data.reference.data.density
          );
        }
      }),
      map((_) => {
        this.onSubmit();
      })
    )
    .subscribe(()=>{
      this.loadingService.clearMessage();
    });
  }

  columnDefs = [
    {
      headerName: 'Name',
      field: 'description',
      headerTooltip: 'Name',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.description;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Density',
      field: 'density',
      headerTooltip: 'Density',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.density;
        }
      },
      sortable: true,
      suppressSizeToFit: false,
    },
    {
      width: 100,
      cellRenderer: 'manageActions',
      cellClass: ['button-edit', 'display-end'],
      cellRendererParams: { data: this },
      autoHeight: true
    },
  ];

  ngOnInit(): void {
    this.init();
  }

  async onSubmit() {
    if (!this.getSearchCriteria()) 
      return;

    this.materials = await this.onLoad();
    this.bindGridData(this.materials);
    if(this.materials.length == 0){
      this.messageService.add({
        severity: 'error',
        summary: 'Info',
        detail: 'No material found matching your search criteria',
      });
    }
      
  }

  async onLoad(): Promise<any[]> {
    let materials: any[] = [];
    this.loadingService.show('Loading...');

    await this.inventoryService.getInventoryCatalogItems(this.getSearchCriteria()).toPromise().then(async catalogItemsMatchs =>{
      for (let i = 0; i < catalogItemsMatchs.length; i++) {
        await this.inventoryService.getInventoryDetails(catalogItemsMatchs[i].itemID).toPromise().then(material =>{
          if (material) {
            var density = new Number(material.density);
            material.density = parseFloat(density.toFixed(7))?.toString();
            materials.push(material);
          }
        })
      }
    });

    this.loadingService.clearMessage();
    return materials;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  onResizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  updateCurrentPage(): void {
    this.paginatorModel.currentPage = 1;
    this.paginatorModel.leftArrowsDisabled = true;
    if (this.paginatorModel.totalPages > 1) {
      this.paginatorModel.rightArrowsDisabled = false;
    }
  }

  handlePaginatorCommand(event: number): void {
    switch (event) {
      case 1:
        this.gridApi.paginationGoToFirstPage();
        break;
      case 2:
        this.gridApi.paginationGoToPreviousPage();
        break;
      case 3:
        this.gridApi.paginationGoToNextPage();
        break;
      case 4:
        this.gridApi.paginationGoToLastPage();
        break;
      default:
        this.gridApi.paginationSetPageSize(event);
        this.gridApi.paginationGoToFirstPage();
        break;
    }
  }

  setDataSource(returnedData) {
    this.gridApi.purgeInfiniteCache();
    this.bindGridData(returnedData);
  }

  private bindGridData(data): void {
    var dataSource = {
      rowCount: null,
      getRows: function (params) {
        setTimeout(function () {
          var dataAfterSorting = sortData(params.sortModel, data);
          var rowsThisPage = dataAfterSorting.slice(
            params.startRow,
            params.endRow
          );
          var lastRow = dataAfterSorting.length;
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      },
    };

    this.paginatorModel.totalRows = data.length;
    this.paginatorModel.totalPages = Math.ceil(
      data.length / this.paginatorModel.pageSize
    );

    if (this.paginatorModel.totalRows == 0) {
      this.gridApi.setDatasource(dataSource);
      this.gridApi.showNoRowsOverlay();
    } else {
      this.updateCurrentPage();
      this.gridApi.hideOverlay();
      this.gridApi.setDatasource(dataSource);
    }
  }

  // onEditDensity(material: any) {
  //   return this.dialog
  //     .open(EditDensityModalComponent, {
  //       width: '550px',
  //       height: '360px',
  //       data: { ...material },
  //     })
  //     .afterClosed()
  //     .pipe(
  //       tap(() => {
  //         this.loadingService.show('Loading ...');
  //       }),
  //       switchMap((dialogResult) => {
  //         return this.inventoryService.updateDensity(
  //           dialogResult.data.materialId,
  //           +dialogResult.data.density
  //         );
  //       }),
  //       catchError((error) => {
  //         return throwError(() => new Error(''));
  //       })
  //     )
  //     .subscribe(
  //       (response) => {
  //         this.onLoad()
  //           .pipe(take(1))
  //           .subscribe((response) => {
  //             this.materials = response;
  //             this.messageService.add({
  //               severity: 'success',
  //               summary: 'Success',
  //               detail: 'Density successfully updated.',
  //             });
  //           });
  //       },
  //       (err) => {
  //         this.loadingService.clearMessage();
  //       }
  //     );
  // }

  init(): void {
    this.buildForm();
  }

  getSearchCriteria(): string {
    return this.searchCriteriaForm.controls['searchCriteria'].value;
  }

  buildForm(): void {
    this.searchCriteriaForm = new FormGroup({
      searchCriteria: new FormControl(''),
    });
  }
}
