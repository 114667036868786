<h5>Validate</h5>
<div class="p-mb-2">
    <ng-template #addressNotFound>
        <span class="p-error">Address not found.</span>
    </ng-template>
    <ng-template #nameNotFound>
        <span class="p-error">Name not found.</span>
    </ng-template>
</div>
<p-confirmDialog header="Alert" [style]="{width: '50vw'}" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="p-d-flex p-flex-column" *ngIf="viewModel">
    <div class="p-fluid p-as-center">
        <div class="p-field p-grid p-ai-start vertical-container">
            <div id="lblMaterialName" class="p-col-12 p-md-9 p-md-offset-3  p-text-bold nameFont p-mb-2">
                <div>
                    {{viewModel.alternateName}}
                </div>
            </div>

            <div id="lblMaterialID" class="p-col-12 p-md-9 p-md-offset-3  p-text-bold nameFont p-mb-2">
                <div>
                    {{viewModel?.materialName}}
                </div>
            </div>
            <label id="lblCosupplierAddress" for="firstname"
                class="p-col-12  p-md-3 p-md-offset-3 p-mb-md-0 p-mb-2">Supplier</label>
            <div id="lblCosupplierAddressValue" class="p-col-12 p-md-6 p-mb-2">
                <ng-container *ngIf="viewModel.supplier; else nameNotFound">
                    <div *ngIf="viewModel.supplier; else nameNotFound">
                        <p>
                            {{viewModel.supplier}}
                        </p>
                    </div>
                    <div *ngIf="viewModel.address; else addressNotFound">
                        <p>
                            {{viewModel.address?.streetAddress}}
                        </p>
                        <p *ngIf="viewModel.address?.locality">
                            {{viewModel.address?.locality}}
                        </p>
                        <p *ngIf="viewModel.address?.country">
                            {{viewModel.address?.country}}
                        </p>
                    </div>
                </ng-container>
            </div>
            <label id="lblGLP" class="p-col-12 p-md-3 p-md-offset-3 p-mb-2">GLP:</label>
            <div id="lblGLPValue" class="p-col-12 p-md-6 p-mb-2">
                <p-inputSwitch id="btnGLPavailable" [disabled]="viewModel.shouldDisableGLPControl"
                    [(ngModel)]="viewModel.isGLP"></p-inputSwitch>
            </div>
            <label id="lblLotNumber" class="p-col-12 p-md-3 p-md-offset-3 p-mb-2">Lot Number:</label>
            <div id="lblLotNumberValue" class="p-col-12 p-md-6 p-mb-2">
                {{viewModel?.lotNumber}}
            </div>
            <label id="lblSDSavailable" class="p-col-12 p-md-3 p-md-offset-3 p-mb-2">SDS available:</label>
            <div *ngIf="!viewModel?.isLoading;else sdsLoadingContainer" id="lblSDSavailableValue"
                class="p-col-12 p-md-6 p-mb-2 p-d-flex">
                <ng-container *ngIf="!viewModel.checkForSDS; else sdsContainer">
                    <p-inputSwitch id="btnSDSavailable" [(ngModel)]="viewModel.SDS"></p-inputSwitch>
                </ng-container>
            </div>
            <label id="lblCOAavailable" class="p-col-12 p-md-3 p-md-offset-3 p-mb-4">COA available:</label>
            <div id="lblSDSavailableValue" class="p-col-12 p-md-6 p-mb-2 p-field-radiobutton">
                <p-inputSwitch id="btnCOAavailable" [(ngModel)]="viewModel.COA"></p-inputSwitch>
            </div>
        </div>
    </div>
</div>

<app-navigate>
    <div class="p-col p-mb-6 p-text-right">
        <div class="p-grid vertical-container">
            <button id="btnDefect" class="p-as-center p-col-12 p-md-2 p-md-offset-3 p-mr-4 p-button-danger"
                (click)="showDefectDialog()" label="Defect" pButton pRipple type="submit"> </button>
            <button id="btnLargeDecant" class="p-as-center p-col-12 p-md-3 p-mr-4 p-button-secondary"
                (click)="showLargeDecantDialog()" label="Large Decant" pButton pRipple type="submit"> </button>
            <button id="btnAccept" [disabled]="viewModel?.isLoading" class="p-as-center p-col-12 p-md-2" label="Accept"
                pButton pRipple type="button" (click)="onAcceptButtonClick()" icon="pi pi-angle-right" iconPos="right">
            </button>
        </div>
    </div>
</app-navigate>

<ng-template #sdsLoadingContainer>
    <div class="p-col-12 p-md-6 p-mb-2 p-d-flex">
        <p-progressSpinner [style]="{width: '25px', height: '25px'}" strokeWidth="5"></p-progressSpinner>
        <span class="p-pl-2">checking SDS...</span>
    </div>
</ng-template>

<ng-template #sdsContainer>
    <p-inputSwitch id="btnSDSavailable" [(ngModel)]="viewModel.SDS" [disabled]="viewModel.sdsModel?.elink?.length > 0">
    </p-inputSwitch>
    <label class="p-ml-4">
        <a [href]="viewModel.sdsModel?.elink" *ngIf="viewModel.sdsModel?.elink; else NoSds" target="_blank">View
            SDS</a>
    </label>
</ng-template>

<ng-template #NoSds>
    <span>
        Email New SDS to <a href="mailto:updates@verisk3e.com">updates@verisk3e.com</a>
    </span>
</ng-template>

<p-dialog [(visible)]="displayLargeDecantDialog" [modal]="true">

    <ng-template pTemplate="header">
        Large Decant Incoming Container
    </ng-template>
    <div class="p-fluid p-formgrid p-grid p-p-3">
        <ng-container *ngTemplateOutlet="container; context:{ddvalue:'body'}"></ng-container>
    </div>
    <ng-template pTemplate="footer" class="p-button-secondary p-px-5 " >
        <button pButton pRipple type="button" label="Cancel" class="p-button-secondary p-px-3 p-m-2"
            (click)="closeLargeDecantDialog()"></button>
        <button pButton pRipple type="submit" label="Confirm Large Decant" class="p-text-bold p-px-3 p-m-2" [disabled]="!selectedContainerId"
            (click)="confirmLargeDecant()"></button>
    </ng-template>
</p-dialog>



<ng-template let-ddvalue="ddvalue" #container>
    <div class="p-field p-col-12">
        <label class="p-required">
            <span class="asterisk">*</span>
            Container:
        </label>
    </div>
    <div class="p-field p-col-12">
        <p-dropdown [filter]="true" filterBy="description" [(ngModel)]="selectedContainerId" [options]="incomingContainers" [appendTo]="ddvalue"
            optionLabel="description" optionValue="id" [resetFilterOnHide]="true" placeholder="Select a Container">
            <ng-template pTemplate="selectedItem" let-container>
                <div>{{container.description}}</div>
            </ng-template>
        </p-dropdown>
    </div>
</ng-template>

<p-dialog [(visible)]="displayDefectDialog" [modal]="true">
    <ng-template pTemplate="header">
        Defect Type
    </ng-template>
    <div class="p-fluid p-formgrid p-grid p-p-3">
        <ng-container *ngTemplateOutlet="containerdefectvalue; context:{dddefectvalue:'body'}"></ng-container>
    </div>
    <ng-template pTemplate="footer" class="p-button-secondary p-px-5 " >
        <button pButton pRipple type="button" label="Cancel" class="p-button-secondary p-px-3 p-m-2"
            (click)="closeDefectDialog()"></button>
        <button pButton pRipple type="submit" label="Confirm Defect" class="p-text-bold p-px-3 p-m-2"  [disabled]="!selectedDefectReasonsId"
            (click)="confirmDefect()"></button>
    </ng-template>
</p-dialog>


<ng-template let-dddefectvalue="dddefectvalue" #containerdefectvalue>
    <div class="p-field p-col-12">
        <label class="p-required">
            <span class="asterisk">*</span>
            Defect Type:
        </label>
    </div>
    <div class="p-field p-col-12">
        <p-dropdown [filter]="true" filterBy="description" [(ngModel)]="selectedDefectReasonsId" [options]="defectReasons" [appendTo]="dddefectvalue"
            optionLabel="description" optionValue="id" [resetFilterOnHide]="true" placeholder="Select a Defect">
            <ng-template pTemplate="selectedItem" let-container>
                <div>{{container.description}}</div>
            </ng-template>
        </p-dropdown>
    </div>
</ng-template>
