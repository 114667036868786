import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Role, User, UserRole } from '../../models/security-models';
import { Subscription } from 'rxjs';

import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { RoleService } from '../../services/role-service/role-service.service';
import { ResponseHandlingService } from '../../services/response-handling-service/response-handling-service'

@Component({
  selector: 'app-role-grid',
  templateUrl: './role-grid.component.html',
  styleUrls: ['./role-grid.component.scss']
})
export class RoleGridComponent implements OnDestroy {

  @Output() userRemoved: EventEmitter<UserRole> = new EventEmitter();
  @Input() role: string;
  @Input() selectedRole: Role;
  @Input() rowData: User[];
  columnDefs: any; 
  overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">No users assigned to this role</span>';
  
  private gridApi: any;
  private subscriptions = new Subscription();

  constructor(private roleService: RoleService, private responseHandler: ResponseHandlingService, public dialog: MatDialog) {
    this.columnDefs = [
      {headerName: 'LastName', field: 'lastName', hide: true},
      {headerName: 'FirstName', field: 'firstName', hide: true},
      {headerName: 'Name', colId: 'lastName&firstName', floatingFilter: true, filter:true, resizable:true, valueGetter: function(params) {
        return params.data.lastName + ', ' + params.data.firstName
      }, tooltipValueGetter: function(params) {
        return params.data.lastName + ', ' + params.data.firstName;
      }},
      {headerName: 'Username', field: 'userName', tooltipField:'userName', resizable: true, width: 300, tooltip: (params) => {params.data.userName}},
      {headerName: 'Email', field: 'email', tooltipField:'email', resizable: true, width: 300, tooltip: (params) => {params.data.email}},
      {headerName: '', field: 'userID', width: 60, cellRenderer: function() {
        return '<span><i class="pi pi-trash" style="cursor:pointer;"></i></span>'}}
    ];
  }

  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  addUserToGrid(data) {
    this.gridApi.applyTransaction({ add: [data] });
  }

  handleDeleteCellClick(event) {
    if (event.colDef.field === "userID") {
      this.confirmDeleteUserFromRole(event);
    }
  }

  confirmDeleteUserFromRole(event) {
    const dialogData =
      new ConfirmDialogModel("Confirm",
      `Are you sure you want to delete this user ${event.data.userName} from role ${this.selectedRole.roleName}?`,
      "Remove", true);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      disableClose: true,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.deleteUserFromRole(event);
      }
    });
  }

  deleteUserFromRole(event) {
    this.subscriptions.add(this.roleService
      .deleteUserFromRole(event.data.userID, this.selectedRole.roleID)
      .subscribe(
        data => {
          if (!data) {
            this.responseHandler.showSuccess(`Successfully removed user ${event.data.userName} from role ${this.selectedRole.roleName}.`);
            this.userRemoved.emit({userRoleID: 0, userID: event.data.userID, roleID: this.selectedRole.roleID});
            this.gridApi.applyTransaction({ remove: [ event.node.data ] });
          }
        }
      ))
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
