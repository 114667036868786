import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttachmentModalData, AttachmentResponseObject } from 'src/app/models/attachment-models';
import { OrderItemAttachment } from 'src/app/models/attachment-models';
import { Language } from 'src/app/models/language.model';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { DocumentType } from '../../models/document-type-model';

@Component({
  selector: 'app-attachment-modal',
  templateUrl: './attachment-modal.component.html',
  styleUrls: ['./attachment-modal.component.scss']
})
export class AttachmentModalComponent implements OnInit {

  entityName = '';
  selectedFileName = '';
  documentTypes: DocumentType[] = [];
  selectedDocumentType: DocumentType;
  languages: Language[] = [];
  selectedLanguage: Language;
  comments = '';
  attachment: OrderItemAttachment = null;
  validFileExtensions = ["jpg", "png", "pdf", "docx", "xlsx", "msg", "rtf", "mht", "htm"];

  // TODO: Add proper types to these variables
  event = null;
  form = null;

  constructor(public dialogRef: MatDialogRef<AttachmentModalComponent>, @Inject(MAT_DIALOG_DATA) public data: AttachmentModalData, private responseHandlingService: ResponseHandlingService) { }

  ngOnInit(): void {
    this.documentTypes = this.data.documentTypes;
    this.languages = this.data.languages;
    this.entityName = this.data.entityName;
    if (this.data.attachment) {
      this.attachment = this.data.attachment;
      this.selectedDocumentType = this.documentTypes.find(t => t.documentTypeID === this.data.attachment.documentTypeID);
      this.comments = this.data.attachment.comments;
      this.selectedFileName = this.data.attachment.filename;
    }
    this.setSelectedLanguage();
  }

  setSelectedLanguage(): void{
    if (this.attachment?.languageID) {
      this.selectedLanguage = this.languages.find(x => x.languageID == this.attachment?.languageID);
    }
    else{
      this.selectedLanguage = this.languages.find(x => x.isDefault == true);
    }
  };

  onFileSelect(event): void {
    for (let file of event.files) {
      // File types validation. Allowed files type are png,jpg,pdf,docx,xlsx,test
      let incomingFileExtension = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if(!this.validFileExtensions.includes(incomingFileExtension)) {
        this.responseHandlingService.showError('File extension invalid');
      }
    }
  }

  onUpload(event, form) {
    this.selectedFileName = event.files[0].name;
    this.event = event;
    this.form = form;
  }

  changeDocumentType(type): void {
    this.selectedDocumentType = this.documentTypes.find(t => t.documentTypeID === type.value);
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    const res: AttachmentResponseObject = { event: this.event, form: this.form, documentType: this.selectedDocumentType, comments: this.comments, attachment: this.attachment, language: this.selectedLanguage };
    this.dialogRef.close(res);
    
  }

}
