import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HubConnectionState, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Configuration } from 'src/app/configuration';
import { SignalREventType } from '../models/signalR.model';
import { SignalRServerTimeOut } from '../receiving.constants';
import { ISignalRSubscriptionService } from './signalr-subscription.service';
import { filter, map, tap } from 'rxjs/operators';
import { UpdateWeightRequest } from '../models/weighing.model';

export const HUB_SUBS_SERVICE_TOKEN = new InjectionToken<ISignalRSubscriptionService>('SignalRSS');

@Injectable({
  providedIn: 'any'
})
export class SignalRService {
  readonly signalRUrl = `http://localhost:5000/hubs/${this._signalRSub.hub}`;
  private hubConnection: HubConnection;

  events = this._signalRSub.signalREventsSubscription.asObservable();

  eventSubject = this._signalRSub.signalREventsSubscription;

  updateWeightEvents$ = this.events
    .pipe(
      filter(event => event.type == SignalREventType.UpdateWeight),
      tap(event => console.log(this.signalRUrl)),
      map(event => {
        let updateWeightRequest: UpdateWeightRequest = event.data;
        return updateWeightRequest;
      }));

  public connectionId: string;
  public status :SignalREventType = SignalREventType.Disconnected;
  constructor(@Inject(HUB_SUBS_SERVICE_TOKEN) private _signalRSub: ISignalRSubscriptionService) {
  }

  public startConnection = () => {
    //If connection already started then return
    if (this.hubConnection && this.hubConnection.state == HubConnectionState.Connected) {
      return;
    }

    //Setup the connection
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.signalRUrl) //, { accessTokenFactory: () => localStorage["msal.access_token"] }
      .withAutomaticReconnect([0,2,10,30,60,120])
      .build();

    this.hubConnection.serverTimeoutInMilliseconds = SignalRServerTimeOut;

    this.hubConnection.start()
      .then(() => this.getUserData())
      .then(() => {
        this.status = SignalREventType.Connected;
        this._signalRSub.signalREventsSubscription.next({ type: SignalREventType.Connected });
        this.activateListeners(this._signalRSub.method, this._signalRSub.onNotification.bind(this._signalRSub));
      })
      .catch(this.onError.bind(this));

    this.hubConnection.onclose(error => {
      let msg = 'SignalR got disconnected...';
      if (error){
        throw error;
      }
      else
        console.log(msg);

      this._signalRSub.signalREventsSubscription.next({ type: SignalREventType.Disconnected });
    });

    this.hubConnection.onreconnecting(err => {
      console.log(`SignalRService: trying to reconnect...`)
    })

    this.hubConnection.onreconnected(connectionId => {
      this._signalRSub.signalREventsSubscription.next({ type: SignalREventType.ConnectionIdObtained });
    });
  }

  public isConnected(){
    return this.hubConnection && this.hubConnection.state == HubConnectionState.Connected;
  }

  private getUserData() {
    this.hubConnection.invoke('getconnectionid').then(
      (data) => {
        this.connectionId = data;
        console.log(`connection id:${data}`);
        this._signalRSub.signalREventsSubscription.next({ type: SignalREventType.ConnectionIdObtained });
      }, ()=> console.warn('no connection id method available on hub'));
  }

  public stopConnection() {
    if (this.hubConnection && this.hubConnection !== null && this.hubConnection.state == HubConnectionState.Connected) {
      this.hubConnection.stop()
        .then(() => {
          this.status = SignalREventType.Disconnected;
          this._signalRSub.signalREventsSubscription.next({ type: SignalREventType.Disconnected });
        })
        .catch(this.onError.bind(this));
    }
  }

  //Write all the methods to receive message from connected SignalR Hub.
  private activateListeners = (method: string, handler) => {
    this.hubConnection.on(method, handler);
  }

  private onError(err: any) {
    let error = `Error on hub connection: ${err}`;

    this._signalRSub.signalREventsSubscription.next({ type: SignalREventType.Error, data: err });
    // throwError(err);
    throw err;
  }
}
