<h5>Previous Receiving Sessions</h5>
<p-fieldset [toggleable]="true">
    <ng-template pTemplate="header">Filters &nbsp;<i class="pi pi-filter"></i></ng-template>
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
        <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-3">
                <span class="p-float-label">
                    <input type="text" id="txtMaterialName" pInputText formControlName="MaterialName">
                    <label for="txtMaterialName">Material Name</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <span class="p-float-label">
                    <p-dropdown [options]="statusTypes" formControlName="StatusType" placeholder="&nbsp;"
                        optionLabel="description" optionValue="id" inputId="ddlStatusType" [showClear]="true">
                    </p-dropdown>
                    <label for="ddlStatusType">Status</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <span class="p-float-label">
                    <p-dropdown [options]="defectReasons" formControlName="defectReasonId" placeholder="&nbsp;"
                        optionLabel="description" optionValue="id" inputId="ddlDefectReason" [showClear]="true">
                    </p-dropdown>
                    <label for="ddlDefectReason">Defect Reason</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <span class="p-float-label">
                    <p-dropdown [options]="receivers" formControlName="Receiver" placeholder="&nbsp;"
                        optionValue="userID" inputId="ddlReceiver" [showClear]="true">
                        <ng-template let-receiver pTemplate="selectedItem">
                            <div>
                                <div>{{receiver.firstName}} {{receiver.lastName}} ({{receiver.email}})</div>
                            </div>
                        </ng-template>
                        <ng-template let-receiver pTemplate="item">
                            <div>{{receiver.firstName}} {{receiver.lastName}} ({{receiver.email}})</div>
                        </ng-template>
                    </p-dropdown>
                    <label for="ddlReceiver">Receiver</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <div class="p-grid">
                    <div class="p-col-12 p-md-6">
                        <span class="p-float-label">
                            <p-calendar InputMask mask="99/99/9999" [keepInvalid]="false"
                                formControlName="ReceivedStartDate" [showIcon]="true" inputId="txtReceivedStartDate"
                                [selectOtherMonths]="true" [showOnFocus]="false" (onBlur)="onFromDateBlur()">
                            </p-calendar>
                            <label for="txtReceivedStartDate">Date From (MM/DD/YYYY)</label>
                            <div [ngClass]="{'p-error': searchForm.get('ReceivedStartDate').invalid}"
                                *ngIf="searchForm.get('ReceivedStartDate').touched">
                                <small *ngIf="searchForm.get('ReceivedStartDate').errors?.invalidMaxDate">
                                    Date cannot be in the future</small>
                                <small
                                    *ngIf="searchForm.get('ReceivedStartDate').errors?.dateCompareInvalid_ReceivedEndDate && !searchForm.get('ReceivedStartDate').errors?.invalidMaxDate">
                                    Start Date must be less than End Date</small>
                            </div>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <span class="p-float-label">
                            <p-calendar InputMask mask="99/99/9999" [keepInvalid]="false"
                                formControlName="ReceivedEndDate" [showIcon]="true" inputId="txtReceivedEndDate"
                                [selectOtherMonths]="true" [showOnFocus]="false" (onBlur)="onToDateBlur()">
                            </p-calendar>
                            <label for="txtReceivedEndDate">Date To (MM/DD/YYYY)</label>
                            <div [ngClass]="{'p-error': searchForm.get('ReceivedEndDate').invalid}"
                                *ngIf="searchForm.get('ReceivedEndDate').touched">
                                <small *ngIf="searchForm.get('ReceivedEndDate').errors?.invalidMaxDate">
                                    Date cannot be in the future</small>
                                <small
                                    *ngIf="searchForm.get('ReceivedEndDate').errors?.dateCompareInvalid_ReceivedStartDate && !searchForm.get('ReceivedEndDate').errors?.invalidMaxDate">
                                    End Date must be greater than Start Date</small>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <span class="p-float-label">
                    <input type="text" id="txtReceivingSession" maxlength="18" pInputText
                        formControlName="SessionContainerId">
                    <label for="txtReceivingSession">Session Container Id</label>
                    <div [ngClass]="{'p-error': searchForm.get('SessionContainerId').invalid}"
                        *ngIf="searchForm.get('SessionContainerId').touched">
                        <small
                            *ngIf="searchForm.get('SessionContainerId').errors?.pattern || searchForm.get('SessionContainerId').errors?.min">
                            Only whole numbers are allowed, and value must be greater than 0.</small>
                    </div>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <span class="p-float-label">
                    <input type="text" id="txtLotNumber" pInputText formControlName="LotNumber">
                    <label for="txtLotNumber">Lot Number</label>
                </span>
            </div>
        </div>
        <app-navigate>
            <div class="p-text-right p-col p-grid p-jc-between p-jc-lg-end p-p-2">
                <button id="btnSearchMaterial" class="p-col-md-2 p-col-sm-6 p-mr-md-2 p-button-secondary" pButton
                    pRipple type="button" label="Clear All" icon="pi pi-filter-slash" iconPos="right"
                    (click)="resetForm()">
                </button>
                <button id="btnSearchMaterial" class="p-col-md-2 p-col-sm-6" pButton pRipple type="submit" label="Apply"
                    icon="pi pi-filter" iconPos="right">
                </button>
            </div>
        </app-navigate>
    </form>
</p-fieldset>
<div class="p-text-bold p-mt-2 records-count-container">
    <ng-container *ngIf="totalRecords > 0; else noRecordsFound">
        Records : {{sessions.length}} of {{totalRecords}}
    </ng-container>

</div>
<div infiniteScroll [infiniteScrollDistance]="2" (scrolled)="loadMoreSessions()">
    <p-table styleClass="p-sm-hidden" #sessionTable [value]="sessions" (sortFunction)="onGridSortIconClicked($event)"
        [customSort]="true" sortMode="multiple">
        <ng-template pTemplate="header" let-session>
            <tr>
                <th pSortableColumn="sessionID" style="width: 5%;">Id <p-sortIcon field="sessionID">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="materialName" style="width: 19%;">Material Name <p-sortIcon field="materialName">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="date" style="width: 10%;">Date<p-sortIcon field="date">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="statusType" style="width: 10%;">Status<p-sortIcon field="statusTypeDescription">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="manufacturerLotId" style="width: 20%;">Manufacturer Lot Number<p-sortIcon
                        field="manufacturerLotId">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="lotNumber" style="width: 15%;">Corteva Lot Number<p-sortIcon field="lotNumber">
                    </p-sortIcon>
                </th>
                <th style="width: 10%;">Receiver
                </th>
                <th style="width: 10%;">Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-session>
            <tr>
                <td>
                    <span> {{session.sessionID}}</span>
                </td>
                <td>
                    <ng-container
                        *ngIf="session.statusType == statusTypeEnum.Completed || session.showActionButtons;else materialLinkContent">
                        <span> {{session.materialName}}</span>
                    </ng-container>

                    <ng-template #materialLinkContent>
                        <a [routerLink]="'/receiving/data-entry/' + session.sessionID">
                            <span> {{session.materialName}}</span>
                        </a>
                    </ng-template>
                </td>
                <td>
                    <span>
                        <ng-container *ngIf="session.updated">
                            {{session.updated | date : "MM/dd/yyyy"}}
                        </ng-container>
                        <ng-container *ngIf="!session.updated">
                            {{session.created | date : "MM/dd/yyyy"}}
                        </ng-container>
                    </span>
                </td>
                <td>
                    <span>{{session.statusTypeDescription}}</span>
                </td>
                <td>
                    <span>{{session.manufacturerLotId}}</span>
                </td>
                <td>
                    <span>{{session.lotNumber}}</span>
                </td>
                <td>
                    <span>
                        <ng-container *ngIf="session.updatedBy">
                            {{session.updatedBy?.firstName}} {{session.updatedBy?.lastName}}
                        </ng-container>
                        <ng-container *ngIf="!session.updatedBy">
                            {{session.createdBy?.firstName}} {{session.createdBy?.lastName}}
                        </ng-container>
                    </span>
                </td>
                <td>
                    <span class="p-buttonset" *ngIf="session.showActionButtons">
                        <button pButton type="button" icon="pi pi-print"
                            [routerLink]="['../label/' + session.sessionID]" pTooltip="Print Labels"
                            class="p-mr-2"></button>
                        <button pButton type="button" icon="pi pi-upload"
                            [routerLink]="['../doc-upload/' + session.sessionID]" styleClass="p-button-warning"
                            pTooltip="Document Upload">
                        </button>
                    </span>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="p-lg-hidden p-md-hidden p-xl-hidden">
        <ng-container *ngFor="let session of sessions">
            <p-card styleClass="p-mb-3">
                <div>
                    <span class="p-text-bold">Session Id : </span>
                    <span> {{session.sessionID}}</span>
                </div>
                <div>
                    <span class="p-text-bold">Material Name : </span>
                    <ng-container
                        *ngIf="session.statusType == statusTypeEnum.Completed || session.showActionButtons;else materialLinkContent">
                        <span> {{session.materialName}}</span>
                    </ng-container>

                    <ng-template #materialLinkContent>
                        <a [routerLink]="'/receiving/data-entry/' + session.sessionID">
                            <span> {{session.materialName}}</span>
                        </a>
                    </ng-template>
                </div>
                <div>
                    <span class="p-text-bold">Date : </span>
                    <ng-container *ngIf="session.updated">
                        {{session.updated | date : "MM/dd/yyyy"}}
                    </ng-container>
                    <ng-container *ngIf="!session.updated">
                        {{session.created | date : "MM/dd/yyyy"}}
                    </ng-container>
                </div>
                <div>
                    <span class="p-text-bold">Status : </span>
                    <span>{{session.statusTypeDescription}}</span>
                </div>
                <div>
                    <span class="p-text-bold">Manufacturer Lot Number : </span>
                    <span>{{session.manufacturerLotId}}</span>
                </div>
                <div>
                    <span class="p-text-bold">Corteva Lot Number : </span>
                    <span>{{session.lotNumber}}</span>
                </div>
                <div class="p-mt-3"
                    *ngIf="session.statusType == statusTypeEnum.Completed || session.sessionContainerWeights.length > 0">
                    <div class="p-d-flex p-justify-between">
                        <button pButton type="button" label="Print Label" icon="pi pi-print"
                            [routerLink]="['../label/' + session.sessionID]" pTooltip="Print" class="p-mr-2"></button>
                        <button pButton type="button" icon="pi pi-upload"
                            [routerLink]="['../doc-upload/' + session.sessionID]" styleClass="p-button-warning"
                            pTooltip="Document Upload" label="Upload Docs">
                        </button>
                    </div>
                </div>
            </p-card>
        </ng-container>

    </div>

</div>

<app-scroll-to-top></app-scroll-to-top>
<ng-template #noRecordsFound>
    <div class="p-text-uppercase">No Records Found.</div>
</ng-template>