import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUpload } from 'primeng/fileupload';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { NavigationService } from 'src/app/core/modules/navigate/services/navigation.service';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { SessionModel } from '../models/session.model';
import { documentTypes, docuploadModel } from '../models/docupload.model';
import { receivingConstants } from '../receiving.constants';
import { ReceivingStateService } from '../services/receiving-state.service';
import { ReceivingService } from '../services/receiving.service';
import { ConfirmationService } from 'primeng/api';
import { DirtyComponent } from '../guards/dirty-component';
import { ReceivingSessionBaseComponent } from '../core/components/receiving-session-base.component';
@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.scss']
})
export class DocUploadComponent extends ReceivingSessionBaseComponent implements OnInit, DirtyComponent {
  $loading = false;
  receivingSession: SessionModel = null;
  documentTypes: documentTypes[];
  uploadedFiles: docuploadModel[] = [];
  selecteddocumentTypeID: number = 0;
  file: File;
  @ViewChild('fileInput') fileInput: FileUpload;
  isDirty = false;
  private selectedMaterialSubscription: Subscription;
  constructor(private readonly router: Router,
    private receivingStateService: ReceivingStateService,
    private navService: NavigationService,
    private loadingService: LoadingService,
    private toastr: ResponseHandlingService,
    private rececivingService: ReceivingService,
    private confirmationService: ConfirmationService,
    activatedRoute: ActivatedRoute
  ) {
    super(rececivingService, receivingStateService, activatedRoute, loadingService);
    this.navService.setBackButton({ visible: true, routerLink: this.sessionId ? receivingConstants.Routes.PreviousSessions : receivingConstants.Routes.Weighing });
  }

  canDeactivate() {
    return (this.isDirty);
  }

  @HostListener('window:beforeunload', ['$event'])
  onbeforeunload(event) {
    if (this.isDirty) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  private async loadInitialData() {
    this.documentTypes = await this.rececivingService.getDocumentTypes();
    this.loadingService.clearMessage();
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.selectedMaterialSubscription = this.receivingStateService.getReceivingSession()
      .pipe().subscribe(async session => {
        console.log("Docuupload Session", session);
        if (!session) {
          this.router.navigate([receivingConstants.Routes.Search]);
          this.loadingService.clearMessage();
          return;
        }
        this.receivingSession = session;
      });
    this.loadInitialData();
  }

  ngOnDestroy(): void {
    this.selectedMaterialSubscription.unsubscribe();
  }

  onNoDocumentUpload() {
    if (this.fileInput.files.length > 0) {
      this.confirmationService.confirm({
        message: 'You have attachments, do you want to discard them?',
        header: 'Alert',
        key: 'upload',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          this.isDirty = false;
          this.router.navigate([receivingConstants.Routes.Submit]);
        },
        reject: () => {
        }
      });
    }
    else {
      this.isDirty = false;
      this.router.navigate([receivingConstants.Routes.Submit]);
    }
  }

  onSubmitButtonClick(): Promise<void> {
    this.$loading = true;
    return this.uploadFiles(this.fileInput.files)
      .then(data => {
        this.$loading = false;
        this.loadingService.clearMessage();
        this.toastr.showSuccess("Documents uploaded.");
        this.router.navigate([receivingConstants.Routes.Submit]);
      }, (error) => {
        console.log(error);
      });
  }

  remove(event, file, filename) {
    let existingFileList = this.fileInput.files.filter(f => f.name === filename);
    this.fileInput.files.forEach((value, index) => {
      if (existingFileList[0] === value) this.fileInput.files.splice(index, 1);
    });
    let existingFiletoDocumentType = this.uploadedFiles.filter(f => f.filename === filename);
    if (existingFiletoDocumentType.length > 0) {
      this.uploadedFiles.forEach((value, index) => {
        if (existingFiletoDocumentType[0] === value) this.uploadedFiles.splice(index, 1);
      });
    }

    if (this.uploadedFiles.length == 0) {
      this.isDirty = false;
    }
  }

  showSubmitButton() {
    if (this.uploadedFiles.length > 0) {
      if (this.fileInput.files.length === this.uploadedFiles.length) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }


  onChange(filename: string, documenttypeid: number) {
    let existingFiletoDocumentType = this.uploadedFiles.filter(f => f.filename === filename);
    this.isDirty = true;

    if (existingFiletoDocumentType.length > 0) {
      let existingFile = existingFiletoDocumentType[0];
      existingFile.documenttypeid = documenttypeid;
    }
    else {
      this.uploadedFiles.push({ documenttypeid: documenttypeid, filename: filename });
    }
  }


  onClear(event: any) {
    this.isDirty = false;

    this.fileInput.files.forEach((value, index) => {
      this.fileInput.files.splice(index, 1);
    });
    this.uploadedFiles.splice(0, this.uploadedFiles.length);
  }

  niceBytes(x) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }

  uploadFiles(files: File[]): Promise<any[]> {
    this.isDirty = false;
    this.loadingService.show("Uploading Documents");
    const promiseList = [];
    files.forEach(file => {
      let existingFiletoDocumentType = this.uploadedFiles.filter(f => f.filename === file.name);
      if ((existingFiletoDocumentType.length > 0) && (this.receivingSession !== null && this.receivingSession !== undefined)) {
        let productSourceSystem = '';
        if (this.receivingSession.materialTypeId == 1) {
          productSourceSystem = 'COFORM'
        }
        else if (this.receivingSession.materialTypeId == 3) {
          productSourceSystem = 'CORE'
        }
        else {
          productSourceSystem = 'FACTS'
        }
        const metadata = {
          LanguageID: 1, //Hardcoded id for now. if you want the list of language checkout //Orders/Languages API endpoint
          ProductSourceSystem: productSourceSystem,
          ProductIdentifier: this.receivingSession.materialId,
          ProductLotNumber: this.receivingSession.lotNumber || this.receivingSession.manufacturerLotId
        }
        promiseList.push(this.rececivingService.uploadFile(file, existingFiletoDocumentType[0].documenttypeid, this.receivingSession.sessionID, metadata));
      }
    });

    return Promise.all(promiseList);
  }

  onFileSelect(event) {
    this.isDirty = true;
  }

  onGLPReceiptClicked() {
    this.rececivingService.DownloadGLPReceiptForm(this.receivingSession.sessionID, `GLP Receipt-${this.receivingSession.lotNumber}.docx`);
  }
}

