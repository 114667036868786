import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ContainerWeightValidator {

    public ValidateContainerWeight(): ValidatorFn {
        return (formGroup: FormGroup) => {
            const containerWeightControl = formGroup.get('containerWeight')?.value.toString().replace(".", "");
            const containerWeightControlValue = formGroup.get('containerWeight')?.value.toString();
            const plasticWeightControl = formGroup.get('plasticWeight')?.value.toString().replace(".", "");
            const plasticWeightControlValue = formGroup.get('plasticWeight')?.value.toString();
            const WEIGHT_REGEXP = /(\d*)(\.\d{0,7})?$/;
            const WEIGHT_ALPHA_REGEXP = /^[0-9]+$/;

            if (plasticWeightControl == "") {
                formGroup.controls['plasticWeight'].setErrors({ required: true });
            } else if (plasticWeightControl !== "" && (plasticWeightControl.match(WEIGHT_REGEXP)[0] == "" || plasticWeightControl.match(WEIGHT_ALPHA_REGEXP) == null)) {
                formGroup.controls['plasticWeight'].setErrors({ pattern: true });
            } else if (plasticWeightControlValue.includes(".") && plasticWeightControlValue.split(".")[1].length > 7) {
                formGroup.controls['plasticWeight'].setErrors({ pattern: true });
            } else {
                formGroup.controls['plasticWeight'].setErrors(null);
            }

            if (containerWeightControl && containerWeightControl !== "" && Number(containerWeightControlValue) < Number(plasticWeightControlValue)) {
                formGroup.controls['containerWeight'].setErrors({ Invalid: true });
                return { invalidContainerWeight: true }
            } else if (containerWeightControl !== "" && (containerWeightControl.match(WEIGHT_REGEXP)[0] == "" || containerWeightControl.match(WEIGHT_ALPHA_REGEXP) == null)) {
                formGroup.controls['containerWeight'].setErrors({ pattern: true });
            } else if (containerWeightControlValue.includes(".") && containerWeightControlValue.split(".")[1].length > 7) {
                formGroup.controls['containerWeight'].setErrors({ pattern: true });
            } else {
                formGroup.controls['containerWeight'].setErrors(null);
            }
        }
    }

}