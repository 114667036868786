import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SecurityRolesComponent } from './components/security-roles/security-roles.component';
import { HomeComponent } from './components/home/home.component';
import { OrderCoordinationComponent } from './components/order-coordination/order-coordination.component';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { OrderPrepComponent } from './components/order-prep/order-prep.component';
import { OrderProcessedComponent } from './components/order-processed/order-processed.component';
import { DecantComponent } from './components/decant/decant.component';

import { MsalGuard } from '@azure/msal-angular';
import { RouteGuardService as RouteGuard } from './services/route-guard-service/route-guard.service';
import { DecantDetailComponent } from './components/decant-detail/decant-detail.component';
import { DisposeContainerComponent } from './components/warehousing/dispose-container/dispose-container.component';
import { PackingComponent } from './packing-and-shipping/packing/packing.component';
import { PackingDetailComponent } from './packing-and-shipping/packing/packing-detail/packing-detail.component';
import { BreadCrumbModule } from './core/modules/breadcrumb/bread-crumb.module';
import { PickingComponent } from './components/warehousing/picking/picking.component';
import { ScaleTestComponent } from './components/scale-test/scale-test.component'
import { ReprintingComponent } from './components/reprinting/reprinting.component';
import { OrderCompleteComponent } from './components/order-complete/order-complete.component';
import { ManageLabelsComponent } from './components/manage-labels/manage-labels.component';
import { DestinationGuideInfoComponent } from './components/destination-guide-info/destination-guide-info.component';
import { ReweighParentContainerComponent } from './components/reweigh-parent-container/reweigh-parent-container.component';
import { CanDeativateGuardService as CanDeativateGuard } from './services/route-guard-service/can-deactivate-guard.service';
import { InventoryComponent } from './components/inventory/inventory.component';
import { SharedDocumentsComponent } from './components/shared-documents/shared-documents.component';
import { EditDensityComponent } from './components/edit-density/edit-density.component';
import { ContainerWeightComponent } from './components/container-weight/container-weight.component';
import { PackingMaterialsModalComponent } from './components/packing-materials-modal/packing-materials-modal.component';
import { WarehouseManagerComponent } from './components/warehouse-manager/warehouse-manager.component';
import { LabelDesignComponent } from './components/label-design/label-design.component';
import { ManageLabelPrintersComponent } from './components/manage-label-printers/manage-label-printers.component';
import { PrinterAssignmentsComponent } from './components/printer-assignments/printer-assignments.component';
import { ManageDestinationGuideOptionsComponent } from './components/manage-destination-guide-options/manage-destination-guide-options.component';
import { HoldReasonsComponent } from './components/hold-reasons/hold-reasons.component';
import { ManageShippersComponent } from './components/manage-shippers/manage-shippers.component';
import { ManageStationsComponent } from './components/manage-stations/manage-stations.component';
import { ManageSampleusesComponent } from './components/manage-sampleuses/manage-sampleuses.component';
import { LocationBarcodeLabelsComponent } from './components/location-barcode-labels/location-barcode-labels.component';
import { ManageRolesComponent } from './components/manage-roles/manage-roles.component';
import { ManageScalesComponent } from './components/manage-scales/manage-scales.component';
import { AddEditRoleComponent } from './components/manage-roles/edit-roles-modal/add-edit-role.component';
import { DisposalLogComponent } from './components/disposal-log/disposal-log.component';
import { EditShipperComponent } from './components/manage-shippers/edit-shipper/edit-shipper.component';
import { EditWarehouseComponent } from './components/warehouse-manager/edit-warehouse/edit-warehouse.component';
import { UserManagerComponent } from './components/user-manager/user-manager.component';
import { EditUserComponent } from './components/user-manager/edit-user/edit-user.component';
import { ManageCancellationReasonsComponent } from './components/manage-cancellation-reasons/manage-cancellation-reasons.component';
import { ScaleTest2Component } from './components/scale-test2/scale-test2.component';
const routes: Routes = [
  { path: 'home', component: HomeComponent, data: { title: '' } },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'decant', component: DecantComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Decanting', expectedPermission: 500 } }, // Decant
  { path: 'decant/request/:id', component: DecantDetailComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Decanting', expectedPermission: 510 } }, // Decant
  { path: 'decant/parentweight', component: ReweighParentContainerComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Reweigh Parent Container', expectedPermission: 520 } }, // Decant/Warehousing
  { path: 'packing', component: PackingComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Packing & Shipping', expectedPermission: 700 } }, // Packing
  { path: 'packing/request/:id', component: PackingDetailComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Packing', expectedPermission: 710 } }, // Packing
  { path: 'requests/prep', component: OrderPrepComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Request Coordination', expectedPermission: 410 } }, // RequestCoordination
  { path: 'labels', component: ManageLabelsComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Compliance Labels', expectedPermission: 320 } },//Manage Labels
  { path: 'cancellationreasons', component: ManageCancellationReasonsComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Cancellation Reasons', expectedPermission: 320 } },//Manage Cancellation Reasons
  { path: 'labelprinters', component: ManageLabelPrintersComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Label Printers', expectedPermission: 1040 } },//Request Coordination
  { path: 'documents', component: SharedDocumentsComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Shared Documents', expectedPermission: 320 } },//Request Coordination
  { path: 'requests/processed', component: OrderProcessedComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Request Coordination', expectedPermission: 430 } }, // RequestCoordination
  { path: 'requests/complete', component: OrderCompleteComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Request Coordination', expectedPermission: 460 } }, // RequestCoordination
  { path: 'requests/reprint', component: ReprintingComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Request Coordination', expectedPermission: 440 } }, // Request Coordination/Packing/Decant
  { path: 'requests/new', component: OrderCoordinationComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Request Coordination', expectedPermission: 400 } }, // RequestCoordination
  { path: 'requests/:id', component: OrderDetailComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Request Coordination', expectedPermission: 450 } }, // RequestCoordination
  { path: 'usersandroles', component: SecurityRolesComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Users & Roles', expectedPermission: 300 } },//Admin
  { path: 'labelDesigns', component: LabelDesignComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Label Designs', expectedPermission: 300 } },//Admin
  { path: 'destinationguide', component: DestinationGuideInfoComponent, canActivate: [MsalGuard, RouteGuard], canDeactivate: [CanDeativateGuard], data: { title: 'Manage Destination Guides', expectedPermission: 330 } },//Admin
  { path: 'destinationguideoptions', component: ManageDestinationGuideOptionsComponent, canActivate: [MsalGuard, RouteGuard], canDeactivate: [CanDeativateGuard], data: { title: 'Manage Destination Guide Options', expectedPermission: 330 } },
  { path: 'managewarehouses', component: WarehouseManagerComponent, canActivate: [MsalGuard, RouteGuard], canDeactivate: [CanDeativateGuard], data: { title: 'Manage Warehouses', expectedPermission: 472 } },//Manage GSS Warehouses
  { path: 'managewarehouses/:id', component: EditWarehouseComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Warehouses', expectedPermission: 472 } },//Edit GSS Warehouses
  { path: 'scaletest', component: ScaleTestComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Scale Test Utility', expectedPermission: 310 } },//Admin
  { path: 'scaletest2', component: ScaleTest2Component, canActivate: [MsalGuard, RouteGuard], data: { title: 'Scale Test Utility2', expectedPermission: 310 } },//Admin
  { path: 'warehousing/picking', component: PickingComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Picking', expectedPermission: 800 } }, // Warehousing/picking
  { path: 'warehousing/dispose', component: DisposeContainerComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Dispose', expectedPermission: 810 } }, // Warehousing/Dispose
  { path: 'warehousing/locationbarcodelabels', component: LocationBarcodeLabelsComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Location Barcode Labels', expectedPermission: 610 } },
  { path: 'inventory', component: InventoryComponent, canActivate: [MsalGuard, RouteGuard], canDeactivate: [CanDeativateGuard], data: { title: 'Update Inventory Locations', expectedPermission: 610 } },
  { path: 'editdensity', component: EditDensityComponent, canActivate: [MsalGuard, RouteGuard], canDeactivate: [CanDeativateGuard], data: { title: 'Edit Material Density', expectedPermission: 1021 } },
  { path: 'receiving', loadChildren: () => import('./receiving/receiving.module').then((comp: any) => comp.ReceivingModule), canActivate: [MsalGuard, RouteGuard], data: { title: 'Receiving', expectedPermission: 600 } },
  { path: 'p2p', loadChildren: () => import('./peer-to-peer/peer-to-peer.module').then((comp: any) => comp.PeerToPeerPModule), canActivate: [MsalGuard], data: { title: 'P2P' } },
  { path: 'containerweights', component: ContainerWeightComponent, canActivate: [MsalGuard, RouteGuard], canDeactivate: [CanDeativateGuard], data: { title: 'Manage Container Weights', expectedPermission: 1022 } },//Admin
  { path: 'printerassignments', component: PrinterAssignmentsComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Printer Assignments', expectedPermission: 1040 } },//Admin
  { path: 'packing/materials/:id', component: PackingMaterialsModalComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Packing', expectedPermission: 440 } }, // RequestCoordination
  { path: 'holdreasons', component: HoldReasonsComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Hold Reasons', expectedPermission: 1040 } },//Admin
  { path: 'manageShippers', component: ManageShippersComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Shippers', expectedPermission: 1060 } },
  { path: 'manageshippers/new', component: EditShipperComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Shippers', expectedPermission: 1060 } },
  { path: 'manageshippers/:id', component: EditShipperComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Shippers', expectedPermission: 1060 } },
  { path: 'managestations', component: ManageStationsComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Stations', expectedPermission: 1040 } },//Manage Stations
  { path: 'sampleUses', component: ManageSampleusesComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Sample Uses', expectedPermission: 1080 } },
  { path: 'manageroles', component: ManageRolesComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Roles', expectedPermission: 310 } },
  { path: 'roles/new', component: AddEditRoleComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Roles', expectedPermission: 310 } },
  { path: 'roles/:id', component: AddEditRoleComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Roles', expectedPermission: 310 } },
  { path: 'scales', component: ManageScalesComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Scales', expectedPermission: 1090 } },
  { path: 'sampleUses', component: ManageSampleusesComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Sample Uses', expectedPermission: 1080 } },
  { path: 'scales', component: ManageScalesComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Scales', expectedPermission: 1090 } },
  { path: 'warehousing/disposallogs', component: DisposalLogComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Disposal Log', expectedPermission: 810 } },
  { path: 'manageUser', component: UserManagerComponent, canActivate: [MsalGuard, RouteGuard], canDeactivate: [CanDeativateGuard], data: { title: 'Manage Users', expectedPermission: 472 } },
  { path: 'manageUser/:id', component: EditUserComponent, canActivate: [MsalGuard, RouteGuard], data: { title: 'Manage Users', expectedPermission: 472 } },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: isInIframe() ? 'disabled' : 'enabled' }),
    BreadCrumbModule.forRoot()],
  exports: [RouterModule, BreadCrumbModule]
})
export class AppRoutingModule { }

export function isInIframe() {
  return window !== window.parent && !window.opener;
}
