import { MatDialog } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs/internal/Subscription';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { sortData } from 'src/app/helpers/grid-helpers';
import { OrderFilterModel } from 'src/app/models/filter-models';
import { Order, OrderListResponse } from 'src/app/models/order-models';
import { OrderService } from 'src/app/services/order-service/order-service';
import { PaginatorModel } from '../../core/modules/ag-grid/components/ag-grid-custom-paginator/ag-grid-custom-paginator.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { OrderFiltersComponent } from '../order-filters/order-filters.component';
import { OrderAssignModalComponent, OrderAssignModalModel } from '../order-assign-modal/order-assign-modal.component';
import { User } from 'src/app/models/security-models';
import { ResponseHandlingService } from 'src/app/services/response-handling-service/response-handling-service';
import { AssignButtonRenderer } from 'src/app/core/modules/ag-grid/components/ag-grid-cell-renderers/assign-button-renderer.component';
import { UserService } from 'src/app/services/user-service/user-service.service';

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.scss']
})
export class OrderCompleteComponent implements OnInit {

  @ViewChild('appFilterOrder') appFilterOrderInstance: OrderFiltersComponent;

  private gridApi;
  public gridOptions: GridOptions;
  private orders: Order[];
  public disableClearFilters: boolean = true;
  private subscriptions = new Subscription();
  private localStorageVersion: string = '/v3';
  public requestCoordinators: User[];
  public isLoaded: boolean;
  public overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">Please enter search criteria and perform a search.</span>';

  completeOrders;
  public filterModel: OrderFilterModel;
  public paginatorModel: PaginatorModel;
  public hasFilterModelChanged: boolean = true;
  frameworkComponents: any;

  constructor(private router: Router, private loadingService: LoadingService, private orderService: OrderService, public dialog: MatDialog, private responseHandler: ResponseHandlingService, private userService: UserService) {
    this.isLoaded = false;
    this.subscriptions.add(this.userService.getUsersByPermission(400).subscribe(
      data => {
        this.requestCoordinators = data;
        this.isLoaded = true;
      }
    ));
    this.frameworkComponents = {
      assignButtonRenderer: AssignButtonRenderer
    };
  }

  ngOnInit(): void {
    this.paginatorModel = {
      totalRows: 0,
      totalPages: 0,
      pageSize: 25,
      currentPage: 1,
      leftArrowsDisabled: true,
      rightArrowsDisabled: true
    };
    this.gridOptions = {};
    this.setFilterModel();
  };

  columnDefs = [
    {
      headerName: 'Request ID',
      minWidth: 120,
      maxWidth: 120,
      field: 'requestNumber', headerTooltip: 'Request ID', tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.requestNumber;
        }
      }, sortable: true,
      suppressSizeToFit: false,
      cellRenderer: function (params) {
        if (params.data !== undefined) {
          var link = `<a href="${location.origin}/requests/${params.data.orderID}" rel="noopener" onclick="event.preventDefault()">` + params.data.requestNumber + '</a>';
          return link;
        }
      }
    },
    {
      headerName: 'R', field: 'useReservation', sortable: true,
      suppressSizeToFit: false,
      minWidth: 45,
      maxWidth: 45,
      valueGetter: (params) => {
        if (params.data !== undefined && params.data.useReservation) {
          return params.data.useReservation === true ? 'R' : '';
        }
      }
    },
    {
      headerName: 'Requestor', flex: 1, field: 'requestorName', headerTooltip: 'Requestor', suppressSizeToFit: false,
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.requestorName;
        }
      }, sortable: true
    },
    {
      headerName: 'Recipient',
      flex: 1,
      field: 'recipientName', headerTooltip: 'Recipient',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.recipient;
        }
      }, sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Materials Requested',
      flex: 1,
      headerTooltip: 'Materials Requested',
      suppressSizeToFit: false,
      valueGetter: (params) => {
        if (params.data !== undefined && params.data.chemicalsRequested) {
          return params.data.chemicalsRequested.replaceAll('##', ', ');
        }
      },
      // Tooltip display all chemicals in list
      // ## denotes a separate chemical coming from the stored proc
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.chemicalsRequested) {
          return !params.data.chemicalsRequested ? '' : params.data.chemicalsRequested.replaceAll('##', '\n');
        }
      }, sortable: false
    },
    {
      headerName: 'Ship By Date', field: 'shipByDate', sort: 'asc',
      headerTooltip: 'Ship By Date',
      minWidth: 110,
      maxWidth: 130,
      suppressSizeToFit: false,
      valueGetter: (params) => { if (params.data !== undefined && params.data.shipByDate) { return formatDate(params.data.shipByDate, 'd-MMM-yyyy', 'en-US') } },
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.shipByDate) {
          return formatDate(params.data.shipByDate, 'd-MMM-yyyy', 'en-US');
        }
      }, sortable: true
    },
    {
      headerName: 'Priority', headerTooltip: 'Priority',
      suppressSizeToFit: false,
      minWidth: 90,
      maxWidth: 90,
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.priorityName;
        }
      },
      field: 'priorityName', sortable: true
    },
    {
      headerName: 'Items', headerTooltip: 'Items',
      suppressSizeToFit: false,
      minWidth: 60,
      maxWidth: 60,
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.numberOfItems;
        }
      },
      field: 'numberOfItems', sortable: true
    },
    {
      headerName: 'Request Type', headerTooltip: 'Request Type',
      minWidth: 70,
      maxWidth: 70,
      suppressSizeToFit: false,
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.requestTypeDesc;
        }
      },
      field: 'requestTypeDesc', sortable: true
    },
    {
      headerName: 'Destination Country', headerTooltip: 'Destination Country', field: 'countryName', tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.countryName;
        }
      }, sortable: true
    },
    {
      headerName: 'Warehouse',
      flex: 1, headerTooltip: 'Warehouse', field: 'warehouseName', tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.warehouseName;
        }
      }, sortable: true,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Status',
      maxWidth: 120, headerTooltip: 'Status', field: 'orderStatusDesc',
      tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          return params.data.isStaged ? params.data.orderStatusDesc + " - Staging" : params.data.orderStatusDesc;
        }
      }, sortable: true
    },
    {
      headerName: 'Hold Reason',
      maxWidth: 150, headerTooltip: 'Hold Reason', field: 'holdReasons',
      sortable: true,
      tooltipValueGetter: (params) => {
        if (params.data !== undefined && params.data.holdReasons) {
          return !params.data.holdReasons ? '' : params.data.holdReasons.replaceAll(',', '\n');
        }
      }
    },
    {
      headerName: 'Coordinator', headerTooltip: 'Coordinator',
      field: 'orderID', cellRenderer: 'assignButtonRenderer', tooltipValueGetter: (params) => {
        if (params.data !== undefined) {
          var assignedToFullName = (params.data?.assignedToFirstName !== null ? params.data?.assignedToFirstName : '') + ' ' + (params.data?.assignedToLastName !== null ? params.data?.assignedToLastName : '');
          return assignedToFullName === ' ' ? null : assignedToFullName;
        }
      },
      suppressSizeToFit: false,
      minWidth: 110,
      maxWidth: 110
    }
  ];

  handleCellClicked(event) {
    if (event.column.colDef.headerName !== "Coordinator") {
      this.router.navigate([`requests/${event.data.orderID}`]);
    }
  };

  setFilterModel(): void {
    if (this.router.url && sessionStorage.getItem(this.router.url + this.localStorageVersion))
      this.filterModel = JSON.parse(sessionStorage.getItem(this.router.url + this.localStorageVersion)).filterModel;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.addEventListener('assignButtonClicked', (e) => {
      this.confirmAssignOrder(e);
    });
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    //this.gridOptions.api.showNoRowsOverlay();
  }

  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  searchButtonClicked(): void {
    this.appFilterOrderInstance.saveFilterDetails();
    this.hasFilterModelChanged = true;
    var data = { orderStatuses: [] };

    if (this.filterModel) {

      var countries = this.filterModel?.countries?.map(country => country.itemID);
      if (countries && countries.length > 0)
        data['countryIDs'] = countries;

      if (this.filterModel.requestType.itemID > 0)
        data['requestTypeID'] = this.filterModel.requestType.itemID;

      var requestors = this.filterModel.requestors.map(requestor => requestor.itemDesc);
      if (requestors && requestors.length > 0)
        data['requestorNames'] = requestors;

      var tags = this.filterModel.tags.map(tag => tag.itemID);
      if (tags && tags.length > 0)
        data['tagIDs'] = tags;

      if (this.filterModel.shipByDateRange) {
        data['shipByStartDate'] = this.getFormattedDate(this.filterModel.shipByDateRange[0]);
        data['shipByEndDate'] = this.getFormattedDate(this.filterModel.shipByDateRange[1])
      }

      if (this.filterModel.updatedDateRange) {
        data['updatedStartDate'] = this.getFormattedDate(this.filterModel.updatedDateRange[0]);
        data['updatedEndDate'] = this.getFormattedDate(this.filterModel.updatedDateRange[1])
      }

      var regions = this.filterModel.regions.map(region => region.itemID);
      if (regions && regions.length > 0)
        data['regionIDs'] = regions;

      var coordinators = this.filterModel.coordinators.map(coordinator => coordinator.itemID);
      if (coordinators && coordinators.length > 0)
        data['coordinatorIDs'] = coordinators;

      var statuses = this.filterModel.orderStatuses.map(s => s.itemID);
      if (statuses && statuses.length > 0)
        data['orderStatuses'] = statuses;

      if (this.filterModel.requestNumber) {
        data['requestNumber'] = this.filterModel.requestNumber;
      }
      if (this.filterModel.lotNumber) {
        data['lotNumber'] = this.filterModel.lotNumber;
      }
      if (this.filterModel.chemical) {
        data['chemicalsRequested'] = this.filterModel.chemical;
      }
      var holdReasons = this.filterModel.holdReasons.map(s => s.itemID);
      if (holdReasons && holdReasons.length > 0) {
        data['holdReasons'] = holdReasons;
      }

      if (this.filterModel.reserved && this.filterModel.reserved.itemID > 0)
        data['useReservation'] = this.filterModel.reserved.itemDesc == "Yes" ? true : false;

      if (this.filterModel.trackingNumber) {
        data['trackingNumber'] = this.filterModel.trackingNumber;
      }
      var warehouses = this.filterModel?.warehouses?.map(w => w.itemID);
      if (warehouses && warehouses.length > 0)
        data['warehouseIDs'] = warehouses;
    }

    this.loadingService.show("Loading ...");
    this.orderService.getOrdersByFilterCriteria(data)
      .pipe(finalize(() => this.loadingService.clearMessage()))
      .subscribe(res => {
        if (res && res.length == 0) {
          this.gridOptions.suppressNoRowsOverlay = false;
          this.gridOptions.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No matches for selected filter criteria</span>';
        }
        this.setDataSource(res);
      });
  }
  confirmAssignOrder(event: any) {
    const dialogData =
      new OrderAssignModalModel(`Assign Coordinator`,
        `Select a Request Coordinator for request ${event.value.requestNumber}.`,
        this.requestCoordinators,
        `Assigning a coordinator will update the request status to Pending and move it to the Request Prep screen.`);
    const dialogRef = this.dialog.open(OrderAssignModalComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe((userResponse: User) => {
      if (userResponse) {
        // We are re-getting this order in case of stale data
        this.orderService.getOrderDetail(event.value.orderID).subscribe((orderDetailResponse: Order) => {
          if (orderDetailResponse.assignedToUserID) {
            const data: ConfirmDialogModel = {
              title: 'Confirm Re-Assignment', message: `Request is assigned to ${orderDetailResponse.assignedToFirstName} ${orderDetailResponse.assignedToLastName}. Do you want to reassign it to ${userResponse.firstName} ${userResponse.lastName}?`,
              useDangerConfirm: false, confirmButtonLabel: 'Confirm'
            }
            this.dialog.open(ConfirmDialogComponent, {
              disableClose: true, data
            }).afterClosed().subscribe(confirmResponse => {
              if (confirmResponse) {
                this.assignOrder(event.value, userResponse);
              }
              else {
                this.searchButtonClicked();
              }
            });
          } else {
            this.assignOrder(event.value, userResponse);
          }
        });
      }
      else {
        this.searchButtonClicked();
      }
    });
  }

  assignOrder(order: any, coordinator: User) {
    this.subscriptions.add(
      this.orderService.assignOrder(order.orderID, coordinator.userID).subscribe(
        data => {
          this.responseHandler.showSuccess(`Successfully assigned request ${order.requestNumber} to coordinator ${coordinator.firstName} ${coordinator.lastName}.`);
          this.searchButtonClicked();
        }
      )
    );
  }

  private getFormattedDate(date): string {
    var params = date.toLocaleDateString().split('/');
    return [`${params[2]}`, `0${params[0]}`.substr(-2), `0${params[1]}`.substr(-2)].join('-');
  }

  setDataSource(returnedData) {
    this.gridApi.purgeInfiniteCache();
    this.completeOrders = returnedData;
    this.bindGridData(returnedData);
  }

  private bindGridData(data): void {

    var dataSource = {
      rowCount: null,
      getRows: function (params) {
        setTimeout(function () {
          var dataAfterSorting = sortData(params.sortModel, data);
          var rowsThisPage = dataAfterSorting.slice(params.startRow, params.endRow);
          var lastRow = dataAfterSorting.length;
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      }
    }

    this.paginatorModel.totalRows = data.length;
    this.paginatorModel.totalPages = Math.ceil(data.length / this.paginatorModel.pageSize);

    if (this.paginatorModel.totalRows == 0) {
      this.gridApi.setDatasource(dataSource);
      this.gridApi.showNoRowsOverlay()
    }
    else {
      this.updateCurrentPage();
      this.gridApi.hideOverlay()
      this.gridApi.setDatasource(dataSource);

      console.log(this.paginatorModel)
    }
  }

  updateCurrentPage(): void {
    this.paginatorModel.currentPage = 1;
    this.paginatorModel.leftArrowsDisabled = true;
    if (this.paginatorModel.totalPages > 1) {
      this.paginatorModel.rightArrowsDisabled = false;
    }
  }

  onClearFilters() {
    this.appFilterOrderInstance.clearAllFilters();
    this.hasFilterModelChanged = false;
    this.disableClearFilters = true;
  }

  handleFilterUpdate(event): void {
    this.filterModel = event.filterModel;
    this.hasFilterModelChanged = false;
    this.handleClearFilters();
  }

  handleClearFilters(): void {
    var data = { orderStatuses: [] };

    if (this.filterModel) {
      var countries = this.filterModel?.countries?.map(country => country.itemID);
      if (countries && countries.length > 0)
        data['countryIDs'] = countries;

      if (this.filterModel.requestType.itemID > 0)
        data['requestTypeID'] = this.filterModel.requestType.itemID;

      var requestors = this.filterModel.requestors.map(requestor => requestor.itemDesc);
      if (requestors && requestors.length > 0)
        data['requestorNames'] = requestors;

      var tags = this.filterModel.tags.map(tag => tag.itemID);
      if (tags && tags.length > 0)
        data['tagIDs'] = tags;

      if (this.filterModel.shipByDateRange) {
        data['shipByStartDate'] = this.getFormattedDate(this.filterModel.shipByDateRange[0]);
        data['shipByEndDate'] = this.getFormattedDate(this.filterModel.shipByDateRange[1])
      }

      if (this.filterModel.updatedDateRange) {
        data['updatedStartDate'] = this.getFormattedDate(this.filterModel.updatedDateRange[0]);
        data['updatedEndDate'] = this.getFormattedDate(this.filterModel.updatedDateRange[1])
      }

      var regions = this.filterModel.regions.map(region => region.itemID);
      if (regions && regions.length > 0)
        data['regionIDs'] = regions;

      var coordinators = this.filterModel.coordinators.map(coordinator => coordinator.itemID);
      if (coordinators && coordinators.length > 0)
        data['coordinatorIDs'] = coordinators;

      var statuses = this.filterModel.orderStatuses.map(s => s.itemID);
      if (statuses && statuses.length > 0)
        data['orderStatuses'] = statuses;

      if (this.filterModel.requestNumber) {
        data['requestNumber'] = this.filterModel.requestNumber;
      }
      if (this.filterModel.lotNumber) {
        data['lotNumber'] = this.filterModel.lotNumber;
      }
      if (this.filterModel.chemical) {
        data['chemicalsRequested'] = this.filterModel.chemical;
      }

      if (this.filterModel.holdReasons && this.filterModel.holdReasons.length > 0) {
        data['holdReasons'] = this.filterModel.holdReasons;
      }

      if (this.filterModel.reserved && this.filterModel.reserved.itemID > 0) {
        data['useReservation'] = this.filterModel.reserved;
      }

      if (this.filterModel.trackingNumber) {
        data['trackingNumber'] = this.filterModel.trackingNumber;
      }
      var warehouses = this.filterModel.warehouses.map(w => w.itemID);
      if (warehouses && warehouses.length > 0)
        data['warehouseIDs'] = warehouses;
    }

    if ((Object.keys(data).includes('orderStatuses'))
      && (Object.keys(data)?.length == 1) && this.filterModel?.orderStatuses?.length)
      this.disableClearFilters = !(Object.keys(data)?.length > 0);
    else
      this.disableClearFilters = !(Object.keys(data)?.length > 1);
  }

  // Handle events emitted from agGridCustomPaginator
  handlePaginatorCommand(event: number): void {
    switch (event) {
      case 1:
        this.gridApi.paginationGoToFirstPage();
        break;
      case 2:
        this.gridApi.paginationGoToPreviousPage();
        break;
      case 3:
        this.gridApi.paginationGoToNextPage();
        break;
      case 4:
        this.gridApi.paginationGoToLastPage();
        break;
      default:
        this.gridApi.paginationSetPageSize(event);
        this.gridApi.paginationGoToFirstPage();
        break;
    }
  }

}
