import { Warehouse } from 'src/app/models/decant-models';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DecantService } from 'src/app/services/decant-service/decant-service';

@Component({
  selector: 'app-edit-label-printer-modal',
  templateUrl: './edit-label-printer-modal.component.html',
  styleUrls: ['./edit-label-printer-modal.component.scss'],
})
export class EditLabelPrinterModalComponent implements OnInit {
  public printerLabelForm: FormGroup;
  public selectedWarehouse: Warehouse;
  public warehousesRes: Warehouse[];
  public warehouseStationForm: FormGroup;
  public warehouseSelected = false;
  public isSubmitClicked = false;
  public buttonLabel: string;

  constructor(
    public dialogRef: MatDialogRef<EditLabelPrinterModalComponent>,
    private decantService: DecantService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.buttonLabel = "Edit";
  }

  ngOnInit(): void {
    this.buildForm();
    this.initData();
    this.getWarehouseList();
  }

  get fc() {
    return this.printerLabelForm.controls;
  }

  get fv() {
    return this.printerLabelForm.value;
  }

  initData() {
    if (this.data == null) {
      var data = {
        labelPrinterId: 0,
        warehouseId: 0,
        displayName: null,
        bartenderName: null,
        dnsName: null,
        printerModel: null,
        description: null,
        labelStockHeight: null,
        labelStockWidth: null,
        isReceivingPrinter: false,
      };

      this.data = data;
      this.buttonLabel = "Add";
    }
  }

  onSubmit() {
    this.isSubmitClicked = true;
    if (this.printerLabelForm.valid) {
      this.dialogRef.close(this.printerLabelForm.value);
    }
    else {
      if (this.fv.warehouseId === undefined) {
        this.fc['warehouseId'].setErrors({ 'required': true });
      }
      if (this.fv.displayName === null) {
        this.fc['displayName'].setErrors({ 'required': true });
      }
      if (this.fv.bartenderName === null) {
        this.fc['bartenderName'].setErrors({ 'required': true });
      }
      this.printerLabelForm.updateValueAndValidity();
    }
  }

  getWarehouseList() {
    this.decantService.getWarehouses().subscribe({
      next: (res) => {
        this.warehousesRes = res;
        this.selectedWarehouse = this.warehousesRes.find(
          (s) => s.itemID === this.data.warehouseID
        );
        this.patchForm();
      },
    });
  }

  private patchForm() {
    this.printerLabelForm.patchValue({
      labelPrinterId: this.data.labelPrinterID,
      warehouseId: this.data.warehouseID,
      displayName: this.data.displayName,
      bartenderName: this.data.bartenderName,
      dnsName: this.data.dnsName,
      printerModel: this.data.printerModel,
      description: this.data.description,
      labelStockHeight: this.data.labelStockHeight,
      labelStockWidth: this.data.labelStockWidth,
      isReceivingPrinter: this.data.isReceivingPrinter,
    });
  }

  private buildForm() {
    this.printerLabelForm = new FormGroup({
      warehouseId: new FormControl(null, Validators.required),
      labelPrinterId: new FormControl(),
      displayName: new FormControl(null, Validators.required),
      bartenderName: new FormControl(null, Validators.required),
      dnsName: new FormControl(null),
      printerModel: new FormControl(null),
      description: new FormControl(null, Validators.maxLength(200)),
      labelStockHeight: new FormControl(null, Validators.min(0)),
      labelStockWidth: new FormControl(null, Validators.min(0)),
      isReceivingPrinter: new FormControl(null),
    });
  }
}
