<p-card styleClass="p-compound-supplier-card">
    <div class="p-d-flex p-flex-column p-flex-md-column p-flex-sm-row">
        <div class="p-as-center" style="width:90%">
            <p-autoComplete [emptyMessage]="'No supplier(s) found'" [autofocus]="true" [(ngModel)]="selectedSupplier"
                [suggestions]="filteredSuppliers" [forceSelection]="true" field="name"
                (completeMethod)="filteredSupplier($event)" [dropdown]="true" [autoHighlight]="true" class="p-fluid"
                placeholder='Vendor Name'>
            </p-autoComplete>
            <small>
                Type in a vendor's name on the box or select from the dropdown
            </small>
        </div>
        <div class="p-mt-sm-0 p-mt-md-6 p-as-center" style="width:90%">
            <div class="p-card-continue-button p-text-right p-mt-1" pTooltip="Select a supplier"
                [tooltipDisabled]="selectedSupplier != undefined">
                <button (click)="OnContinueClicked()" pButton pRipple type="button" [disabled]="!selectedSupplier"
                    class="p-card-continue-button" icon="pi pi-angle-right" iconPos="right" label="Continue"
                    class="p-text-normal" id="btnCompoundSupplierContinue"></button>
            </div>
        </div>
    </div>
</p-card>