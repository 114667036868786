import { of, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  catchError,
  finalize,
  map,
} from 'rxjs/operators';
import { InventoryService } from 'src/app/services/inventory-service/inventory-service';
import { NItemSearch } from 'src/app/models/inventory-models';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent implements OnInit {
  serachCriteriaForm: FormGroup;

  itemSearch: NItemSearch[];
  isLoading: boolean = false;

  constructor(
    private messageService: MessageService,
    private readonly inventoryService: InventoryService,
    private loadingService: LoadingService
  ) {}
  
  ngOnInit(): void {
    this.init();
  }
  onSubmit() {
    this.load();
  }

  private load(): any {
    this.loadingService.show('Loading ...');
    return this.inventoryService
      .getItemSearch(this.getSearchCriteria())
      .pipe(
        catchError(() => {
          return of([]);
        }),
        map((itemSearchResult) => {
          if(itemSearchResult.length == 0){
            this.messageService.add({
              severity: 'error',
              summary: 'Info',
              detail: 'No inventory found matching your search criteria',
            });
          }
          
          this.itemSearch = itemSearchResult;
        }),
        finalize(() => this.loadingService.clearMessage())
      )
      .subscribe();
  }

  private init(): void {
    this.buildForm();
  }

  private getSearchCriteria(): string {
    return this.serachCriteriaForm.controls['searchCriteria'].value;
  }

  private buildForm(): void {
    this.serachCriteriaForm = new FormGroup({
      searchCriteria: new FormControl(''),
    });
  }
}
