import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ContainerWeightsModalComponent } from './../container-weights-modal/container-weights-modal.component';
import { GridOptions } from 'ag-grid-community';
import { Component, OnInit } from '@angular/core';
import { ContainerWeights } from '../../models/container-weights';
import { MessageService } from '../../shared/services/message-queue.service';
import { ContainerWeightsService } from '../../services/container-weights/container-weights.service';
import { DeleteContainerWeightComponent } from './delete-container-weight/delete-container-weight.component';
import { EditContainerWeightComponent } from './edit-container-weight/edit-container-weight.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-container-weight',
  templateUrl: './container-weight.component.html',
  styleUrls: ['./container-weight.component.scss'],
})
export class ContainerWeightComponent implements OnInit {
  private gridApi;
  public columnDefs: any;
  public frameworkComponents: any;
  public gridOptions: GridOptions;
  public subscriptions: Subscription;
  public weights: ContainerWeights[];
  public overlayNoRowsTemplate: string = '<span class="ag-overlay-loading-center">No records to display.</span>';

  constructor(
    private readonly containerWeightService: ContainerWeightsService,
    private readonly messageService: MessageService,
    private dialog: MatDialog) {

    this.subscriptions = new Subscription();
    this.frameworkComponents = {
      EditContainerRenderer: EditContainerWeightComponent,
      DeleteContainerRenderer: DeleteContainerWeightComponent,
    };

    this.columnDefs = [
      { headerName: 'Id', field: 'containerWeightID', hide: true },
      { headerName: 'Container Name', field: 'name' },
      { headerName: 'Plastic Weight (kg)', field: 'plasticWeight' },
      { headerName: 'Container Weight (kg)', field: 'weight' },
      { headerName: '', cellRenderer: 'EditContainerRenderer', cellClass: 'button-edit', width: 50 },
      { headerName: '', cellRenderer: 'DeleteContainerRenderer', cellClass: 'button-edit', width: 50 },
    ];
  }

  ngOnInit(): void {
    this.fillGrid();
    this.subscriptions.add(
      this.messageService.message.subscribe((modelData) => {
        this.fillGrid();
      })
    );
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.purgeInfiniteCache();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  public fillGrid() {
    this.containerWeightService
      .getContainerWeights()
      .pipe(take(1))
      .subscribe((weightsResponse) => {
        weightsResponse.forEach((weight) => {
          if (weight.weight !== null) {
            weight.weight = Number(parseFloat(weight.weight.toFixed(7))?.toString());
          } else {
            weight.weight = null;
          }
          weight.plasticWeight = Number(parseFloat(weight.plasticWeight.toFixed(7))?.toString());
        });

        this.weights = weightsResponse;
      });
  }

  resizeGrid(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  addNewContainer(): void {
    this.dialog.open(ContainerWeightsModalComponent, {
      height: '510px',
      width: '500px',
      data: {},
      disableClose: true
    }).afterClosed().subscribe((res: boolean) => {
      if (!res) { return; }
      this.fillGrid();
    });
  }
}
