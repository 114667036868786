import { Inject, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpStatusCode } from '../../models/response-models';
import { Router } from '@angular/router';
import { MsalAuthService } from '../auth-service/auth-service.service';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/core/modules/loading/services/loading.service';
import { AppInsightsService } from '../app-insights-service/app-insights-service';
import { AUTH_SERVICE_TOKEN } from '@corteva-research/ngx-components-core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ResponseHandlingService {
    constructor(
        private toastr: MessageService,
        private router: Router,
        @Inject(AUTH_SERVICE_TOKEN) private authService: MsalAuthService,
        private loadingService: LoadingService,
        private appInsightsService: AppInsightsService) {
    }

    showError(errorMessage: string, timeOut?: number, title?: string, sticky: boolean = false) {
        this.showMessage(errorMessage, 'error', title || 'Error', timeOut, sticky);
    }

    showSuccess(successMessage: string, timeOut?: number, title?: string, sticky: boolean = false) {
        this.showMessage(successMessage, 'success', title || 'Success', timeOut, sticky);
    }

    showWarning(warnMessage: string, timeOut?: number, title?: string, sticky: boolean = false) {
        this.showMessage(warnMessage, 'warn', title || 'Warning', timeOut, sticky);
    }

    showInfo(infoMessage: string, timeOut?: number, title?: string, sticky: boolean = false) {
        this.showMessage(infoMessage, 'info', title || 'Information', timeOut, sticky);
    }

    showMessage(message: string, severity: string, title: string, timeOut: number, sticky: boolean) {
        this.toastr.add({ summary: title, severity: severity, detail: message, life: timeOut ? timeOut : 3000, sticky: sticky })
    }

    clearMessages() {
        this.toastr.clear();
    }

    showAPIError(errorMessage: string) {
        this.showError(errorMessage);
        return throwError(errorMessage);
    }

    handleError(error, errorCodeMapping?: Map<HttpStatusCode, string>, redirect: boolean = true, excludedCodes: HttpStatusCode[] = []) {

        let logError: Error;

        if (error.error instanceof ErrorEvent) {
            let err = error as ErrorEvent;
            logError = {
                message: err.message,
                name: err.type
            }
        } else {
            let err = error as HttpErrorResponse;
            logError = {
                message: err.message,
                name: err.name
            }
        }

        this.appInsightsService.logException(logError);

        //Lets hide the spinner in case of error spinner not handled.
        this.loadingService.clearMessage();
        let errorMessage = '';

        var authErrorCodes = ["consent_required", "interaction_required", "login_required"];

        //dont handle if explicitely asked to be excluded (requestor would handle it)
        if (error.status && excludedCodes.find(st => st == error.status))
            return throwError(error);

        if (errorCodeMapping && errorCodeMapping.has(error.status) && !redirect) {
            let err = errorCodeMapping.get(error.status);
            errorMessage = err ? `Error: ${err}` : 'Unable to determine error';
            this.showError(errorMessage);
            return throwError(errorMessage);
        }

        if (error.status === HttpStatusCode.UNAUTHORIZED) {
            this.router.navigate(['unauthorized']);
            return;
        }

        if (error?.url?.toLowerCase().includes('print')) {
            let errorMessage = 'Your print request could not be completed. Please contact support for assistance'
            return throwError(errorMessage);
        }

        if (error.status === HttpStatusCode.NOT_FOUND) {
            let x = JSON.parse(localStorage.getItem('coaCheckInitiated'))
            if (JSON.parse(localStorage.getItem('coaCheckInitiated'))) return throwError(error);

            if (!this.router.url.includes('warehousing/dispose')) {
                errorMessage = `Error: Request not found`;
                if (this.router.url.includes('decant/request') || this.router.url.includes('decant/parentweight')) {
                    errorMessage = 'Error: Inventory item not found.';
                }
                else if (this.router.url.includes('reprint')) {
                    errorMessage = 'Error: Request not found';
                }
                else
                    this.router.navigate(['/home']);
            }
            else {
                errorMessage = "Container ID Invalid";
            }
        }
        else if (error.status === HttpStatusCode.BAD_REQUEST) {
            errorMessage = `Error: Bad Request`;
            if (error?.error?.Title && (error?.error?.Title?.includes('Unable to change status at this time')
                || error?.error?.Title?.includes('Order status is invalid')
                || error?.error?.Title?.includes('Order status was not changed')))
                errorMessage = error?.error?.Title;
            else if (error?.error?.Title && error?.error?.Title.includes('Insufficient SGS reservation'))
                errorMessage = `Insufficient Reservation Amount`;
            else if (error?.error?.Title && error?.error?.Title.includes('No SGS reservation found'))
                errorMessage = `No SGS reservation found`;
            else if (error?.error?.Title && error?.error?.Title.includes('Duplicate package')) {
                errorMessage = 'Error: Duplicate shipper and tracking number';
            }
            else if (this.router.url.includes('decant/request'))
                this.router.navigate(['/decant']);
            else
                this.router.navigate(['/home']);
        }
        else if (error.code && authErrorCodes.indexOf(error.errorCode) > 0
            || (error.error && error.error.text && error.error.text.includes('AuthenticationFailed'))) {
            errorMessage = `The previous session has expired. Please log in again.`;
            this.authService.silentLogout();
        }
        else if (errorCodeMapping && errorCodeMapping.get(error.status)) {
            errorMessage = `Error: ${errorCodeMapping.get(error.status)}`;
        }
        else if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
        }
        else {
            errorMessage = `Server error`;
            if (error instanceof Object)
                console.error(JSON.stringify(error));
        }

        this.showError(errorMessage);
        return throwError(errorMessage);
    }
}