import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MessageService as MessageServiceQueue } from 'src/app/shared/services/message-queue.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { EditScaleModalComponent } from '../edit-scale-modal/edit-scale-modal.component';

@Component({
  selector: 'app-manage-scales-actions',
  templateUrl: './manage-scales-actions.component.html',
  styleUrls: ['./manage-scales-actions.component.scss']
})
export class ManageScalesActionsComponent  implements ICellRendererAngularComp {
  private params: any;

  constructor(
    private dialog: MatDialog,
    private readonly messageServiceQueue: MessageServiceQueue
  ) {}

  onEditScale(){
    this.dialog
      .open(EditScaleModalComponent, { width: '414px', data: this.params.data })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageServiceQueue.message.next({data:'edit', reference:dialogResult});
        }
      });
  }
  
  onDeleteScale() {
    const confirmDialog = new ConfirmDialogModel(
      'Confirm Action',
      `This scale will be deleted. Are you sure?`,
      'OK',
      false,
      true,
      'Cancel'
    );
    this.dialog
      .open(ConfirmDialogComponent, { maxWidth: '400px', data: confirmDialog })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.messageServiceQueue.message.next({data:'delete', reference:this.params.data});
        }
      });
  }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  
}
