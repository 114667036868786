<h5 class="p-mb-3">{{buttonLabel}} Shipper</h5>
<p-confirmDialog [style]="{width: '20%'}"></p-confirmDialog>
<form [formGroup]="shipperForm" (submit)="onSubmit()">
 
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Shipper Name: </label>
                </div>
                <div class="p-col ">
                    <input formControlName="shipperName" pInputText type="text" style="width: 100%;" [ngClass]="shipperForm.controls['shipperName'].invalid && submitted ? 'error' : ''" maxlength="80"/>
                    <div *ngIf="shipperForm.controls['shipperName'].invalid  && submitted &&  (shipperForm.controls['shipperName'].dirty || shipperForm.controls['shipperName'].touched)">
                        <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="shipperForm.controls.shipperName.errors?.required">
                            Field required.
                        </small>                
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Tracking URL format: <span><i [matTooltip]="'Use {0} where the tracking number should be in the shipper-provided URL.'" class="pi pi-exclamation-circle" style="cursor:pointer;"></i></span></label>
                </div>
                <div class="p-col ">
                    <input formControlName="trackingURLFormat" pInputText type="text" style="width: 100%;" maxlength="255"/> 
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Sort Order: </label>
                </div>
                <div class="p-col">
                    <input formControlName="sortOrder" pInputText type="text" style="width: 100%;" [ngClass]="shipperForm.controls['sortOrder'].invalid && submitted ? 'error' : ''" numeric decimals="0" maxlength="5"/>
                    <div *ngIf="shipperForm.controls['sortOrder'].invalid  && submitted && (shipperForm.controls['sortOrder'].dirty || shipperForm.controls['sortOrder'].touched)">
                        <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="shipperForm.controls.sortOrder.errors?.required">
                            Field required.
                        </small>                
                        <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="shipperForm.controls.sortOrder.errors?.min">
                            Value must be greater than zero.
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Base API URL: </label>
                </div>
                <div class="p-col">
                    <input formControlName="baseAPIURL" pInputText type="text" style="width: 100%;" maxlength="255"/>   
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Client ID: </label>
                </div>
                <div class="p-col">
                    <input formControlName="clientID" pInputText type="text" style="width: 100%;" maxlength="255"/>     
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Client Secret: </label>
                </div>
                <div class="p-col">
                    <input formControlName="clientSecret" pInputText type="text" style="width: 100%;" maxlength="255"/>           
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Client Secret Confirmation: </label>
                </div>
                <div class="p-col">
                    <input formControlName="clientSecretConfirm" pInputText type="text" style="width: 100%;" maxlength="255"/>
                    <div *ngIf="submitted">
                        <small style="margin-left: 5px; color: red; font-size: 12px;" *ngIf="shipperForm.errors?.secretsNoMatch">
                            The client secret values you entered do not match.
                        </small>                 
                    </div>                 
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="requestor">Update Frequency (hours): </label>
                </div>
                <div class="p-col">
                    <input formControlName="frequencyInHours" pInputText type="text" style="width: 100%;"
                        [ngClass]="shipperForm.controls['frequencyInHours'].invalid && submitted ? 'error' : ''" numeric decimals="0"
                        maxlength="2" />
                    <div
                        *ngIf="shipperForm.controls['frequencyInHours'].invalid  && submitted && (shipperForm.controls['frequencyInHours'].dirty || shipperForm.controls['frequencyInHours'].touched)">
                        <small style="margin-left: 5px; color: red; font-size: 12px;"
                            *ngIf="shipperForm.controls.frequencyInHours.errors?.min">
                            Value must be greater than zero.
                        </small>
                        <small style="margin-left: 5px; color: red; font-size: 12px;"
                            *ngIf="shipperForm.controls.frequencyInHours.errors?.max">
                            Value must be less or equal to 24.
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="p-grid">
                <div class="p-col-3 m-auto">
                    <label for="countries">Maximum Request Count: </label>
                </div>
                <div class="p-col">
                    <input formControlName="maximumRequestCount" pInputText type="text" style="width: 100%;"
                        [ngClass]="shipperForm.controls['maximumRequestCount'].invalid && submitted ? 'error' : ''" numeric
                        decimals="0" maxlength="5" />
                    <div
                        *ngIf="shipperForm.controls['maximumRequestCount'].invalid  && submitted && (shipperForm.controls['maximumRequestCount'].dirty || shipperForm.controls['maximumRequestCount'].touched)">
                        <small style="margin-left: 5px; color: red; font-size: 12px;"
                            *ngIf="shipperForm.controls.maximumRequestCount.errors?.required">
                            This field is not required, but if it's entered, it must be greater than zero.
                        </small>
                        <small style="margin-left: 5px; color: red; font-size: 12px;"
                            *ngIf="shipperForm.controls.maximumRequestCount.errors?.min">
                            Value must be greater than zero.
                        </small>
                    </div>
                </div>
    
            </div>
    
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <div class="float-right">
                <button style="margin-right: 15px;" mat-raised-button color="cor-default" mat-dialog-close (click)="onCancel()">Cancel</button>
                <button mat-raised-button color="cor-primary" type="submit">Save</button>
            </div>
        </div>
    </div>


</form>
