import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'move-button-cell',
    template: `<i class="pi pi-trash" style="cursor: pointer; color: #C8102E;" (click)="click()"></i>`,
    styles: [],
  })
  
 
  export class DeleteButtonRenderer implements ICellRendererAngularComp {
    public params: any;
    private gridApi: any;
  
    constructor() { }
  
    agInit(params: any): void {
      this.params = params;
      this.gridApi = params.api;
    }
  
    click() {
      this.gridApi.dispatchEvent({ type: 'deleteButtonClicked', value: this.params.rowIndex });
    }
  
    refresh(): boolean {
      return false;
    }
  }