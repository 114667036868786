<div class="container">
    <h5 *ngIf="!isEditing">Add New Reason</h5>
    <h5 *ngIf="isEditing">Edit Hold Reason</h5>
    <div class="content">
        <form [formGroup]="holdReasonForm">
            <div class="p-field p-fluid p-mb-2 p-mt-">
                <label class="p-text-bold" for="holdReason" id="holdReason" #holdReason>Hold Reason:</label>
                <input formControlName="holdReason" pInputText type="text" />
                <div style="display: flex; justify-content: end;">
                    <span style="font-size: smaller;">{{ (80 - holdReasonLength.value) >= 0 ? 80 -
                        holdReasonLength.value : 0}} characters remaining</span>
                </div>
                <div *ngIf="fc.holdReason.errors?.maxlength" [ngClass]="{'p-error': fc.holdReason.invalid}">
                    <small> Max length is 80 characters. </small>
                </div>
            </div>
            <div [ngClass]="{'p-error': fc.holdReason.invalid}" *ngIf="fc.holdReason.touched">
                <small *ngIf="fc.holdReason.errors?.required">
                    Field is required.
                </small>
            </div>
            <div class="p-field p-fluid p-mb-2" style="display: flex;">
                <label for="requireComment" id="requireComment" class="p-mr-2">Requires Comment</label>
                <p-inputSwitch formControlName="requireComment"></p-inputSwitch>
            </div>
        </form>
    </div>
    <div class="p-text-right p-mt-4">
        <button mat-raised-button color="cor-default" (click)="cancel()" class="p-mr-2">Cancel</button>
        <button mat-raised-button color="cor-primary"
            [disabled]="holdReasonForm.invalid || ((isEditing && data.name == fc.holdReason.value) && (isEditing && data.requireComment == fc.requireComment.value))"
            (click)="save()">Save</button>
    </div>
</div>