<div class="p-grid">
  <div class="p-col-6">
      <h4>Decanting - Capture Remaining Source</h4>
      <ag-grid-angular #roleGrid style="width:99.8%;margin-top: 20px;margin-bottom: 20px" class="ag-theme-alpine"
          domLayout="autoHeight" suppressHorizontalScroll="true" [rowData]="rowData" [columnDefs]="columnDefs"
          [tooltipShowDelay]="1500" (gridSizeChanged)="resizeGrid($event)" (gridReady)="onGridReady($event)">
      </ag-grid-angular>

      <div class="ui-grid m-t-2">
          <div class="ui-grid-col-12">
              <button class="large" mat-raised-button (click)='onComplete()' [disabled]='!weightCaptured'
                  color="cor-primary" id="btn-return-to-request">Return to Request</button>
          </div>
      </div>
      <div class="ui-grid m-t-2">
          <div class="ui-grid-col-12">
              <button class="large" mat-raised-button color="cor-danger" (click)="onDisposeContainer()" id="btn-dispose-container">Dispose
                  Container</button>
          </div>
      </div>
  </div>
  <div class="p-col-6 p-l-2">
      <div *ngIf="selectedContainer">
          <div *ngIf="selectedStation">
              <div *ngIf="scalesReady && selectedContainer">
                <div>
                  <app-scale-control [decantStation]="selectedStation" [selectedContainer]="selectedContainer"
                      [selectedInventoryItem]="selectedInventoryItem" [isReweighMode]="true"
                      [unitOfMeasure]="unitOfMeasure" (weightCaptured)="handleWeightCaptured($event)">
                  </app-scale-control>
                </div>
                <button class="large" mat-raised-button (click)="getStation()" color="cor-primary" style="margin-top: -30px;">Get
                  Scale Weights</button>
              </div>
          </div>
      </div>
  </div>
</div>
